import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MemberSelectedFilterType } from 'pages/Member/types';
import { MessageTargetFormType } from 'pages/MoreDetails/Message/types';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { RecoilState, useRecoilValue } from 'recoil';
import filters from 'utils/filters';

type Props<T extends MemberSelectedFilterType> = {
  filterAtom: RecoilState<T>;
};
const Counts = <T extends MemberSelectedFilterType>({ filterAtom }: Props<T>) => {
  const { control, setValue } = useFormContext<MessageTargetFormType>();
  const total = useWatch({ control, name: 'total' });
  const selectedMember = useWatch({ control, name: 'selected' });
  const isAllSelected = useWatch({ control, name: 'isAllSelected' });

  const selectedFilter = useRecoilValue(filterAtom);

  const toggleAllSelected = () => {
    if (total) {
      setValue('isAllSelected', !isAllSelected);
    }
    if (isAllSelected) {
      setValue('selected', []);
    }
  };

  useEffect(() => {
    setValue('isAllSelected', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <>
      <Button
        textColor={isAllSelected ? 'primary' : 'gray2'}
        fontSize={14}
        fontWeight={isAllSelected ? 500 : 400}
        leftIcon={<Icon name="selectCheck" size={16} color={isAllSelected ? 'primary' : 'gray4'} />}
        gap={4}
        textOpacity={isAllSelected ? 1 : 0.88}
        onClick={toggleAllSelected}>
        필터된 회원 {total ? `${filters.numberComma(total)}명` : ''} 전체선택
      </Button>
      {!isAllSelected ? (
        <StyledTypography textColor="gray2" opacity={0.88}>
          선택된 회원 <b>{filters.numberComma(selectedMember.length)}</b>명
        </StyledTypography>
      ) : null}
    </>
  );
};

export default Counts;

const StyledTypography = styled(Typography)`
  b {
    color: ${theme.color.primary};
    font-weight: 500;
    opacity: 1;
  }
  position: relative;
  padding-left: 13px;

  :before {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 6px;
    width: 1px;

    background-color: ${theme.color.gray4};
  }
`;

import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';

type Props = {
  onClose: () => void;
  onClick: () => void;
  initCustomArray: BookingCommonFormType['repeatCustomWeekday'];
  initRepeat: BookingCommonFormType['repeat'];
};

/** 수업 수정 폼에서 반복설정 변경 시 노출되는 확인 다이얼로그 */
const ChangeRepeatDialog = ({ initCustomArray, initRepeat, onClose, onClick }: Props) => {
  const { setValue, getValues } = useFormContext<BookingCommonFormType>();

  // 선택한 룸이 있는 경우에만 반복설정 변경 시 룸 설정 초기화 dialog 노출
  const resetRoom = () => {
    const hasSelectedRoom = getValues('roomId') !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value && !getValues('selectedRoom');
    if (hasSelectedRoom) {
      setValue('needResetRoom', 'repeat', { shouldDirty: true });
      setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value, { shouldDirty: true });
      setValue('selectedRoom', undefined, { shouldDirty: true });
    }
  };

  const clickPositiveAction = () => {
    setValue('isChangedRepeat', true);
    onClick();
    onClose();
    resetRoom();
  };

  const clickNegativeAction = () => {
    setValue('repeat', initRepeat);
    setValue('repeatCustomWeekday', initCustomArray);
    setValue('tempRepeatCustomWeekday', initCustomArray);
    onClose();
  };

  return (
    <Dialog
      onClose={clickNegativeAction}
      positiveAction={{
        text: BOOKING_COMMON_FORM_TEXT.repeat.dialog.positiveText,
        onClick: clickPositiveAction,
      }}
      negativeAction={{
        text: BOOKING_COMMON_FORM_TEXT.repeat.dialog.negativeText,
        onClick: clickNegativeAction,
        style: 'outlined',
      }}>
      <DialogDescription tag="pre">{BOOKING_COMMON_FORM_TEXT.repeat.dialog.description}</DialogDescription>
    </Dialog>
  );
};

export default ChangeRepeatDialog;

import { bookingParamsAtom } from 'recoil/moreDetail';

import { DEFAULT_BOOKING_FILTER_OPTIONS, DEFAULT_BOOKING_FILTER_TABS, DEFAULT_SCHEDULE_FILTER } from '../../constant';
import ScheduleCommonFilter from '../ScheduleCommonFilter';
import BookingDrawerFilterOptions from './BookingDrawerFilterOptions';
import BookingWholeFilterOptions from './BookingWholeFilterOptions';

const BookingFilter = () => {
  return (
    <ScheduleCommonFilter
      filterAtom={bookingParamsAtom}
      filters={DEFAULT_BOOKING_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_BOOKING_FILTER_TABS}
      defaultFilter={DEFAULT_SCHEDULE_FILTER}
      renderDrawerOptions={BookingDrawerFilterOptions}
      renderWholeFilterPopupOptions={BookingWholeFilterOptions}
    />
  );
};

export default BookingFilter;

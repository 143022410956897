import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 취소된 수업 예약 내역 삭제
 */
const useDeleteBooking = (bookingId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'delete',
    url: `/api/booking/${bookingId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['booking'],
      });
    },
  });
};

export default useDeleteBooking;

export const ACCOUNT_TEXT = {
  title: '내 계정 정보',
  name: '이름',
  phone: '휴대폰 번호',
  passwordReset: '비밀번호 재설정',
};

export const ACCOUNT_MENU = {
  changeName: 'name',
  changePhone: 'phone',
  changePassword: 'password',
  withdraw: 'withdraw',
};

import styled from '@emotion/styled';
import SubTitle from 'components/SubTitle';
import { isArray } from 'lodash';
import { ReactNode } from 'react';

type Props = {
  title: string[] | ReactNode;
};

const TwoLinedTitle = ({ title }: Props) => {
  return <Container>{isArray(title) ? <SubTitle title={title} /> : title}</Container>;
};

export default TwoLinedTitle;

const Container = styled.div`
  padding: 16px 20px 12px;
`;

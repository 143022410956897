import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useMemo } from 'react';

import { FilterCountProps } from './types';

const FilterCount = ({ selectedCount, onClick }: FilterCountProps) => {
  const iconProps = useMemo(() => {
    if (!selectedCount) {
      return {
        fillColor: theme.color.gray2,
        strokeColor: theme.color.gray2,
      };
    }

    return {
      fillColor: theme.color.white,
      strokeColor: theme.color.white,
    };
  }, [selectedCount]);

  return (
    <Container hasCount={!!selectedCount} onClick={onClick}>
      <Icon name="filterBold" size={16} {...iconProps} />
      {!!selectedCount && (
        <Typography span size={13} weight={600} textColor="white" lineHeight={16}>
          {selectedCount}
        </Typography>
      )}
    </Container>
  );
};

export default FilterCount;

const Container = styled.div<{ hasCount: boolean }>`
  ${theme.flex('', '', '', 2)};
  position: relative;
  z-index: 3;
  padding: 8px 10px;
  border-radius: 999px;
  background-color: ${({ hasCount }) => (hasCount ? theme.color.primary : theme.color.gray7)};

  &:after {
    content: '';
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    right: -6px;
    background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  }
`;

import { PERMISSION } from 'constants/permission';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import usePermission from 'hooks/usePermission';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType, BookingType } from 'pages/Booking/types';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import StaffLinkButton from './StaffLinkButton';

type Props = {
  bookingType: BookingType;
  /**
   * 수업 생성: 현재 로그인 한 강사
   * 수업 수정: 해당 수업의 담당 강사
   */
  currentStaff: {
    id: number;
    name: string;
    image: {
      path: string;
      name: string;
      extension: string;
    } | null;
    color: string | number;
  };
};

const Staffs = ({ bookingType, currentStaff }: Props) => {
  const { data: staffs } = useGetFilterStaffs();

  const [isOpen, setIsOpen] = useState(false);
  const loginStaffId = useRecoilValue(staffIdAtom);

  const { hasPermission } = usePermission();
  const isMinePermission = hasPermission(PERMISSION.lecture.mine.create[bookingType]);

  const { control, setValue } = useFormContext<BookingCommonFormType>();
  const selectedStaffId = useWatch({ control, name: 'selectedStaffId' });

  const selectStaff = staffs.find(({ id }) => id === Number(selectedStaffId));

  const data = useMemo(() => {
    const currentStaffs = staffs.map(({ id, avatar, name }) => {
      const staffId = String(id);
      return {
        id: staffId,
        label: (
          <AvatarLabel key={id} id={staffId} selectStaffId={String(selectedStaffId)} imgUrl={avatar} label={name} showLabelAll />
        ),
        value: staffId,
      };
    });
    const result = isMinePermission ? currentStaffs : currentStaffs.filter(staff => String(staff.id) !== String(loginStaffId));
    return result;
  }, [selectedStaffId, staffs, isMinePermission, loginStaffId]);

  const staffInfo = useMemo(() => {
    if (selectStaff) {
      if (!isMinePermission && selectStaff.id === loginStaffId) {
        return {
          imgUrl: null,
          name: '',
          color: '',
        };
      } else {
        return {
          imgUrl: selectStaff.avatar,
          name: selectStaff.name,
          color: selectStaff.color,
        };
      }
    } else if (currentStaff) {
      return {
        imgUrl: currentStaff.image,
        name: currentStaff.name,
        color: currentStaff.color,
      };
    } else {
      return {
        imgUrl: null,
        name: BOOKING_COMMON_FORM_TEXT.staff.placeholder,
        color: '',
      };
    }
  }, [currentStaff, selectStaff, isMinePermission, loginStaffId]);

  useEffect(() => {
    const staff = selectStaff || {
      id: currentStaff.id,
      avatar: currentStaff.image,
      name: currentStaff.name,
      color: currentStaff.color,
    };
    setValue('selectedStaff', staff);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStaff, selectedStaffId]);

  return (
    <>
      <StaffLinkButton staffInfo={staffInfo} onClick={() => setIsOpen(true)} />

      <CheckRadioDrawer
        header={<OneLinedTitle title="담당강사 선택" />}
        control={control}
        data={data}
        name="selectedStaffId"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default Staffs;

import { useIsFetching } from '@tanstack/react-query';
import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import FormMain from './FormMain';

/** 그룹 수업 1개 수정 */
const BookingGroupUpdate = () => {
  const currentLectureId = useParamsId();
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <FormMain currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingGroupUpdate;

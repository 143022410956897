import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostAvatar = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AvatarCreateParams, AvatarCreateResponse>({
    method: 'post',
    url: '/api/avatar',
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostAvatar;

type AvatarCreateParams = {
  file: File;
};

type AvatarCreateResponse = {
  image: string | null;
};

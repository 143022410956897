import useQueryString from 'hooks/useQueryString';
import FallbackStaffField from 'pages/Booking/components/SelectStaff/FallbackStaffField';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormField from 'sharedComponents/FormField';

import CheckPermissionSelectStaff from './CheckPermissionSelectStaff';

const SelectStaffsAndColor = () => {
  const { getSearchParams } = useQueryString();
  const mode = getSearchParams('mode') as string;

  return (
    <ApiBoundary
      fallback={
        <>
          <FallbackStaffField placeholder="강사선택" />
          <FormField iconName="colorChips" iconColor="gray3">
            대표 컬러
          </FormField>
        </>
      }>
      <CheckPermissionSelectStaff pageMode={mode ? 'update' : 'create'} />
    </ApiBoundary>
  );
};

export default SelectStaffsAndColor;

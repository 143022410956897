import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { array, object, string } from 'yup';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';
import { ProfileStateType, ProfileUpdateFormType } from '../types';
import UpdateForm from './components/StaffUpdateForm';

const yupSchema = object({
  profile: object({
    self_introduction: string().notRequired(),
  }),
  careers: array().of(
    object({
      career: string(),
    }),
  ),
});

const MoreDetailsStaffProfileUpdateForm = () => {
  const { state }: ProfileStateType = useLocation();

  const defaultValues = state?.defaultInfo || {};

  const methods = useForm<ProfileUpdateFormType>({
    resolver: yupResolver(yupSchema),
    defaultValues,
  });

  return (
    <MainLayout header={{ title: STAFF_PROFILE_FORM_TEXT.headerTitle.profile }}>
      {/* TODO: 로딩 처리 */}
      <ApiBoundary fallback={<div>loading..</div>}>
        <FormProvider {...methods}>
          <UpdateForm />
        </FormProvider>
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsStaffProfileUpdateForm;

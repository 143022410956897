import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useDrag } from '@use-gesture/react';
import { theme } from 'assets/styles';
import SubTitle, { TitleType } from 'components/SubTitle';
import { Z_INDEX } from 'constants/zIndex';
import { ReactNode, useEffect, useState } from 'react';
import { Color } from 'utils/getColor';

import DrawerPortal from './DrawerPortal';

export type DrawerProps = {
  /** Drawer 안의 내용 */
  children: ReactNode;
  /** true 일 때, Drawer 열림 */
  isOpen: boolean;
  /** Drawer 닫힘 함수 */
  onClose: () => void;
  /** 머릿말 텍스트 <SubTitle /> 컴포넌트 사용 */
  headerText?: TitleType;
  /** Drawer가 나타날 방향 - 기본값은 "bottom" */
  direction?: 'top' | 'bottom';
  /** 핸들 숨김여부 - direction bottom일 때 보이는 게 기본 */
  hideHandle?: boolean;
  /** z-index 값 */
  zIndex?: number;
  /** 커스텀 백그라운드 컬러(ex: 일정탭 상단) */
  backgroundColor?: Color | null;
  /** 내용이 길지 않아도 내부 높이를 최대로 적용시킬 건지 */
  isHeightMax?: boolean;
};

const Drawer = ({
  isOpen,
  onClose,
  headerText,
  direction = 'bottom',
  children,
  zIndex,
  backgroundColor,
  isHeightMax,
  hideHandle = false,
}: DrawerProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const bind = useDrag(({ down, movement: [, my] }) => {
    if (down && my > 0) onClose();
  });

  useEffect(() => {
    const body = document.body;
    if (isOpen) body.classList.add('hidden');
    return () => body.classList.remove('hidden');
  }, [isOpen]);

  /** DOM에 없다가 생기는 경우 처음 오픈될 때 transition 되지 않는 것 방지 */
  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisible(true);
    });

    return () => {
      setIsVisible(false);
    };
  }, []);

  const hasHandle = direction === 'bottom' && !hideHandle;

  return (
    <DrawerPortal>
      <Container className="drawer" isOpen={isVisible && isOpen} zIndex={zIndex}>
        <Overlay onClick={onClose} />
        <DrawerWrapper
          className="drawer-wrapper"
          isOpen={isVisible && isOpen}
          onClick={onClose}
          direction={direction}
          bgColor={backgroundColor}
          isHeightMax={isHeightMax}>
          <div {...bind()}>
            {hasHandle && <DragHandle />}
            {headerText && (
              <HeaderText onClick={e => e.stopPropagation()} hasHandle={hasHandle}>
                <SubTitle title={headerText} />
              </HeaderText>
            )}
          </div>
          <Content className="drawer-content" onClick={e => e.stopPropagation()}>
            {children}
          </Content>
        </DrawerWrapper>
      </Container>
    </DrawerPortal>
  );
};

type drawerTypePick = Pick<DrawerProps, 'isOpen' | 'direction' | 'zIndex' | 'isHeightMax'> & { bgColor?: Color | null };

const Container = styled.div<drawerTypePick>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex || Z_INDEX.drawer};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: ${({ isOpen }) =>
    isOpen ? 'opacity 0.2s ease-in, visibility 0s linear 0s' : 'opacity 0.2s ease-out, visibility 0s linear 0.2s'};
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

const DrawerWrapper = styled.div<drawerTypePick>`
  ${theme.flex('column', '', '')};
  position: fixed;
  width: 100%;
  max-height: 90vh;
  min-height: 100px;
  height: ${({ isHeightMax }) => (isHeightMax ? '100%' : 'auto')};
  background-color: ${({ bgColor }) => {
    if (bgColor === null) return;
    return bgColor ? theme.color[bgColor] : 'white';
  }};
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  ${({ direction, isOpen }) => {
    switch (direction) {
      case 'top':
        return css`
          top: 0;
          transform: translateY(${isOpen ? '0' : '-100%'});
        `;
      default:
        return css`
          bottom: 0;
          transform: translateY(${isOpen ? '0' : '100%'});
          border-top-right-radius: 24px;
          border-top-left-radius: 24px;
        `;
    }
  }}
`;

const HeaderText = styled.div<{ hasHandle: boolean }>`
  padding: ${({ hasHandle }) => (hasHandle ? '16px' : '24px')} 20px 0;
`;

const Content = styled.div`
  overflow-y: auto;
`;

const DragHandle = styled.div`
  height: 3px;
  width: 48px;
  border-radius: 999px;
  margin: 5px auto 0;
  cursor: grab;
  background-color: ${theme.color.gray5};
`;

export default Drawer;

import useParamsId from 'hooks/useParamsId';
import { useLocation } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import QnaDetail from './components/QnaDetail';
import SkeletonDetail from './components/SkeletonDetail';
import { QNA_DETAIL_TEXT } from './constants';

const MoreDetailsQnaDetail = () => {
  const id = useParamsId();

  const { state } = useLocation();
  const permissionDenied: boolean = state?.permissionDenied ?? false;

  return (
    <MainLayout header={{ title: QNA_DETAIL_TEXT.title }}>
      <ApiBoundary fallback={<SkeletonDetail />}>
        <QnaDetail id={id} permissionDenied={permissionDenied} />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsQnaDetail;

import styled from '@emotion/styled';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';

import { ONBOARDING_CONTENT } from '../constant';
import { FooterButtonType } from '../type';

type Props = FooterButtonType;

const OnboardingFooterButton = ({ page, clickNext, clickPrev }: Props) => {
  return (
    <Container>
      <ButtonGroup leftButtonWidth={115}>
        {page > 0 ? (
          <Button size="large56" variant="outlined" onClick={clickPrev}>
            이전
          </Button>
        ) : null}
        <Button size="large56" variant="contained" fullWidth color="point" onClick={clickNext}>
          {page === ONBOARDING_CONTENT.length - 1 ? '시작하기' : '다음'}
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default OnboardingFooterButton;

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
`;

import { useIsFetching } from '@tanstack/react-query';
import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import Main from './Main';

const BookingMember = () => {
  const currentLectureId = useParamsId();
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
      <Main currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingMember;

import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  message: string[];
};

const SettingInfoTooltip = ({ isOpen, setIsOpen, message }: Props) => {
  return (
    <Tooltip className="test" showCloseButton isOpen={isOpen} onClose={() => setIsOpen(false)} message={message} position="left">
      <IconButton
        onClick={e => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}>
        <Icon name="information" size={14} fillColor={theme.color.gray3} />
      </IconButton>
    </Tooltip>
  );
};

export default SettingInfoTooltip;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';
import { InfoTypography } from 'sharedComponents/InfoTypography';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

import MessageLabel from './MessageLabel';

type Props = {
  to: string;
  send_on: string;
  message_type?: string;
  send_person: string;
  all_count: number;
  title: string;
  status: string;
};

const MessageListItem = ({ to, send_on, message_type, send_person, all_count, status, title }: Props) => {
  return (
    <StyledLink to={to}>
      <Typography size={15} weight={600} className="date">
        {filters.dateTimeWithWeekday(send_on)}
      </Typography>
      <ArrangeTypographyWrapper>
        <InfoTypography size={14}>{all_count > 1 ? `${send_person} ${all_count - 1}명` : send_person}</InfoTypography>
        {message_type ? <InfoTypography size={14}>{message_type}</InfoTypography> : null}
      </ArrangeTypographyWrapper>
      <StyledLabel status={status} width={54} />
      <Divider thin />
      <MessageSummary textColor="gray2" ellipsis={1} className="message">
        {title}
      </MessageSummary>
    </StyledLink>
  );
};

export default MessageListItem;

const StyledLink = styled(Link)`
  background-color: ${theme.color.white};
  padding: 18px 20px;
  border-radius: 12px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  position: relative;
  hr {
    margin: 16px 0 14px;
  }
  .date {
    margin-bottom: 2px;
  }
`;

const StyledLabel = styled(MessageLabel)`
  position: absolute;
  top: 18px;
  right: 20px;
`;

const MessageSummary = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

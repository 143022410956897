import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Tooltip, { ITooltipProps } from 'components/Tooltip';
import Typography, { TypographyProps } from 'components/Typography';
import React, { ReactNode, useMemo, useState } from 'react';
interface ISwitchProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  /** switch input의 id */
  id: string;
  /** 메인 텍스트 (description이 있을 때, fontSize는 small) */
  label?: string;
  /** label 하단 부가 설명 */
  description?: string;
  /** true 일 때, switch 상태 변경 불가*/
  disabled?: boolean;
  /** switch의 사이즈 */
  switchSize?: 'small' | 'medium';
  /** label의 글자 크기 medium = 14px small = 12px */
  fontSize?: TypographyProps['size'];
  /** label의 글자 굵기  */
  fontWeight?: TypographyProps['weight'];
  /** label 텍스트 컬러 */
  textColor?: TypographyProps['textColor'];
  /** label 왼쪽의 아이콘 */
  leftIcon?: ReactNode;
  /** 왼쪽 아이콘과 label 사이의 gap */
  gap?: number;
  /** label 오른편 부가 정보 타입이 dialog일 때, 노출 버튼 클릭 이벤트 */
  onClickInfo?: () => void;
  /** label 오른편 부가 정보 타입이 tooltip일 때, 필요한 tooltip 속성들 */
  tooltipProps?: Pick<ITooltipProps, 'message' | 'position' | 'bottom'>;
  className?: string;
}

const Switch = React.forwardRef<HTMLInputElement, ISwitchProps>(
  (
    {
      id,
      label,
      description,
      switchSize = 'small',
      fontSize = 15,
      fontWeight = 500,
      textColor,
      leftIcon,
      gap,
      onClickInfo,
      tooltipProps,
      className,
      ...props
    },
    ref,
  ) => {
    const [isOpenTooltip, setIsOpenTooltip] = useState(false);

    const InfoElement = useMemo(() => {
      if (tooltipProps) {
        return (
          <Tooltip isOpen={isOpenTooltip} showCloseButton={true} onClose={() => setIsOpenTooltip(false)} {...tooltipProps}>
            <IconButton onClick={() => setIsOpenTooltip(true)}>
              <Icon name="information" size={14} fillColor={theme.color.gray3} />
            </IconButton>
          </Tooltip>
        );
      }
      if (onClickInfo) {
        return (
          <IconButton onClick={onClickInfo}>
            <Icon name="information" size={14} fillColor={theme.color.gray3} />
          </IconButton>
        );
      }
      return null;
    }, [isOpenTooltip, onClickInfo, tooltipProps]);

    return (
      <Container className={`switch ${className}`} gap={gap}>
        <div className="switch-contents-wrapper">
          {leftIcon && leftIcon}
          {label && (
            <LabelWrapper className="switch-label-wrapper" hasInformation={!!tooltipProps || !!onClickInfo}>
              <div>
                <label htmlFor={id}>
                  <Typography size={fontSize} textColor={textColor} weight={fontWeight}>
                    {label}
                  </Typography>
                </label>
                {description && (
                  <Typography textColor="gray3" size={12}>
                    {description}
                  </Typography>
                )}
              </div>
              {InfoElement}
            </LabelWrapper>
          )}
          <StyledInput id={id} ref={ref} type="checkbox" switchSize={switchSize} {...props} />
        </div>
      </Container>
    );
  },
);

const Container = styled.div<Pick<ISwitchProps, 'gap'>>`
  width: 100%;

  svg {
    display: block;
  }

  .switch-contents-wrapper {
    ${({ gap }) => theme.flex('', 'center', 'space-between', gap)};
    flex: 1;
  }
`;

const LabelWrapper = styled.div<{ hasInformation: boolean }>`
  flex: 1;
  ${({ hasInformation }) => hasInformation && theme.flex('', 'center', '', 4)};
`;

const StyledInput = styled.input<Pick<ISwitchProps, 'switchSize'>>`
  position: relative;
  width: ${({ switchSize }) => (switchSize === 'medium' ? '60px' : '48px')};
  height: ${({ switchSize }) => (switchSize === 'medium' ? '32px' : '24px')};
  border-radius: 17.5px;
  background-color: ${theme.color.gray5};
  transition: all 0.2s ease-in-out;

  :before {
    content: '';
    width: ${({ switchSize }) => (switchSize === 'medium' ? '26px' : '18px')};
    height: ${({ switchSize }) => (switchSize === 'medium' ? '26px' : '18px')};
    border-radius: 50%;
    background-color: ${theme.color.white};
    position: absolute;
    top: 3px;
    left: 3px;
    transition: all 0.2s ease-in-out;
  }

  :checked {
    background-color: ${theme.color.primary};
    :before {
      transform: ${({ switchSize }) => (switchSize === 'medium' ? 'translate(28px, 0)' : 'translate(24px, 0)')};
    }
  }

  &:disabled {
    cursor: not-allowed;
    &:before {
      background-color: ${theme.color.gray7};
    }
    &:checked {
      background-color: ${theme.color.gray5};
    }
  }
`;

Switch.displayName = 'Switch';
export default Switch;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import useDeleteBoardQna from 'hooks/service/mutations/useDeleteBoardQna';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useParamsId from 'hooks/useParamsId';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';

import { QNA_DETAIL_TEXT } from '../constants';
import { QnaCommentType } from '../types';
import CommentEditPopup from './CommentEditPopup';

type Props = {
  className?: string;
  comment: QnaCommentType;
  canEditMine: boolean;
  canDeleteOthers: boolean;
  isMine?: boolean;
};

const CommentMenu = ({ className, comment, canEditMine, canDeleteOthers, isMine }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const questionId = useParamsId();
  const { setToast } = useToast();
  const { mutate: deleteCommentMutate } = useDeleteBoardQna(questionId, comment.id);
  const { setPopup } = usePopup();

  const { checkPermission } = useGetPermissionDoHavePermission();
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const editComment = () => {
    checkPermission(PERMISSION.board.qna.comment.id, {
      onSuccess: () => setPopup(<CommentEditPopup comment={comment} />),
      onError: () =>
        setErrorBackStep({
          to: `/more-details/qna/${comment.board_id}`,
          options: { replace: true, state: { permissionDenied: true } },
        }),
    });
  };

  const openDeleteDialog = () => {
    const requiredPermission = isMine ? PERMISSION.board.qna.comment : PERMISSION.board.qna.deleteOthers;
    checkPermission(requiredPermission.id, {
      onSuccess: () => setIsDeleteDialogOpen(true),
      onError: () =>
        setErrorBackStep({
          to: `/more-details/qna/${comment.board_id}`,
          options: { replace: true, state: { permissionDenied: true } },
        }),
    });
  };

  const deleteComment = () => {
    deleteCommentMutate(undefined, {
      onSuccess: () => setToast({ type: 'success', message: QNA_DETAIL_TEXT.deleteSuccess }),
      onError: () =>
        setErrorBackStep({
          to: `/more-details/qna/${comment.board_id}`,
          options: { replace: true, state: { permissionDenied: true } },
        }),
      onSettled: () => setIsDeleteDialogOpen(false),
    });
  };

  if (!canEditMine && !canDeleteOthers) return null;
  return (
    <DropDown
      position="right"
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      content={
        <ButtonsWithBorder>
          {canEditMine && (
            <Button
              size="medium48"
              fontSize={15}
              fontWeight={500}
              padding={{ left: 16, right: 12 }}
              gap={32}
              textColor="gray2"
              rightIcon={<Icon name="writing2" size={16} color="gray2" />}
              onClick={editComment}>
              {QNA_DETAIL_TEXT.edit}
            </Button>
          )}
          {(canEditMine || canDeleteOthers) && (
            <Button
              size="medium48"
              fontSize={15}
              fontWeight={500}
              padding={{ left: 16, right: 12 }}
              gap={32}
              textColor="red0"
              rightIcon={<Icon name="delete" size={16} color="red0" />}
              onClick={openDeleteDialog}>
              {QNA_DETAIL_TEXT.delete}
            </Button>
          )}
          {isDeleteDialogOpen && (
            <Dialog
              onClose={() => setIsDeleteDialogOpen(false)}
              positiveAction={{
                text: QNA_DETAIL_TEXT.deleteButton,
                onClick: deleteComment,
              }}
              negativeAction={{ text: QNA_DETAIL_TEXT.deleteCancel, onClick: () => setIsDeleteDialogOpen(false) }}>
              <DialogDescription>{QNA_DETAIL_TEXT.deleteConfirm}</DialogDescription>
            </Dialog>
          )}
        </ButtonsWithBorder>
      }
      className={className}>
      <IconButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <Icon name="more" color="gray3" />
      </IconButton>
    </DropDown>
  );
};

export default CommentMenu;

const ButtonsWithBorder = styled.div`
  flex-direction: column;
  gap: 0;
  button:first-of-type {
    border-bottom: 1px solid ${theme.color.gray7};
  }
`;

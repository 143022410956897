import { every, includes, isEmpty, some } from 'lodash';
import { useCallback } from 'react';
import { PermissionType } from 'types/permissionTypes';

import useGetRolePermissions from './service/queries/useGetRolePermissions';
import useGetStaffMe from './service/queries/useGetStaffMe';

const usePermission = () => {
  const { data: me } = useGetStaffMe();
  const { data: myPermissions } = useGetRolePermissions(me.roles[0]?.id);

  const checkPermission = useCallback(
    (target: PermissionType) => {
      // 상위 권한이 있을 때, 내 권한목록에 상위 권한 없을 경우 false
      if (!isEmpty(target.super) && !some(myPermissions, permission => includes(target.super, permission.id))) {
        return false;
      }
      // 해당 권한 확인
      return some(myPermissions, permission => permission.id === target.id);
    },
    [myPermissions],
  );

  /**
   * 사용자의 롤에 따라 특정 권한을 확인
   *
   * @example
   * const canView = hasPermission(PERMISSION.lecture.others.view.group);
   * @param 확인하고자 하는 특정 권한
   */

  const hasPermission = useCallback(
    (target: PermissionType) => {
      if (!me.roles || !me.roles[0]?.id || !me.roles[0]?.display_name) return false; // role 오류 예외처리

      // 스튜디오 오너일 경우 모든 권한 허용
      const isStudioOwner = me.roles[0].display_name === '스튜디오 오너';
      if (isStudioOwner) return true;

      return checkPermission(target);
    },
    [checkPermission, me.roles],
  );

  /**
   * 권한 목록 모두 충족해야 true
   */
  const everyPermissions = useCallback(
    (targets: PermissionType[]) => {
      if (!me.roles || !me.roles[0]?.id || !me.roles[0]?.display_name) return false; // role 오류 예외처리

      // 스튜디오 오너일 경우 모든 권한 허용
      const isStudioOwner = me.roles[0].display_name === '스튜디오 오너';
      if (isStudioOwner) return true;

      return every(targets, target => checkPermission(target));
    },
    [checkPermission, me.roles],
  );

  /**
   * 권한 목록 중 하나라도 충족하면 true
   */
  const somePermissions = useCallback(
    (targets: PermissionType[]) => {
      if (!me.roles || !me.roles[0]?.id || !me.roles[0]?.display_name) return false; // role 오류 예외처리

      // 스튜디오 오너일 경우 모든 권한 허용
      const isStudioOwner = me.roles[0].display_name === '스튜디오 오너';
      if (isStudioOwner) return true;

      return some(targets, target => checkPermission(target));
    },
    [checkPermission, me.roles],
  );

  return { hasPermission, everyPermissions, somePermissions };
};

export default usePermission;

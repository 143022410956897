import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import {
  MainBackgroundColorWrapper,
  MainContentsBorderWrapper,
  MainStickyTopWrapper,
} from 'sharedComponents/MainLayout/StyledComponents';

export const Container = styled(MainBackgroundColorWrapper)`
  ${theme.flex('column', '', '')};
  height: 100%;
`;

export const ContentsContainer = styled(MainContentsBorderWrapper)`
  flex: 1;
  ${theme.flex('column', '', '')};
`;

export const ContentsWrapper = styled.div`
  padding: 0 20px 24px;
  background-color: ${theme.color.gray6};
  flex: 1;
  position: relative;
`;

export const StickyWrapper = styled(MainStickyTopWrapper)`
  border-bottom: 1px solid ${theme.color.gray6};
`;

import { MbscDatepickerOptions } from '@mobiscroll/react';
import dayjs from 'dayjs';
import DateCalendarPickerDrawer from 'designedComponents/Pickers/DateCalendarPicker/DateCalendarPickerDrawer';
import MonthPickerDrawer from 'designedComponents/Pickers/MonthPicker/MonthPickerDrawer';
import useToast from 'hooks/useToast';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import filters from 'utils/filters';

type ReportPeriodType = {
  period?: {
    type: string;
    start: string;
    end: string;
  };
};

type Props = {
  tempPeriod: ReportPeriodType;
  isOpenCalender: boolean;
  closeCalendar: () => void;
  setTempPeriod: React.Dispatch<React.SetStateAction<ReportPeriodType>>;
} & MbscDatepickerOptions;

const ReportCalendar = ({ tempPeriod, isOpenCalender, closeCalendar, setTempPeriod, ...props }: Props) => {
  const { setToast } = useToast();
  const [tempDate, setTempDate] = useState([tempPeriod.period?.start, tempPeriod.period?.end]);

  const changeDate = useCallback(
    (e: { value: string[] }) => {
      let newDate: string[];

      if (_.isArray(e.value)) {
        newDate = e.value;
      } else {
        newDate = [e.value, e.value];
      }

      if (tempPeriod.period?.type === 'month' && !_.isArray(e.value)) {
        const startDate = dayjs(e.value).startOf('month');
        const endDate = dayjs(e.value).endOf('month');
        newDate = [filters.dateDash(startDate), filters.dateDash(endDate)];
      }
      if (!_.isEqual(tempDate, newDate)) {
        setTempDate(newDate);
      }
    },
    [tempDate, tempPeriod.period?.type],
  );

  const saveDate = useCallback(() => {
    const isIncompleteSelection = tempDate.length < 2 || tempDate.some(date => !date);
    if (isIncompleteSelection) {
      setToast({ message: '날짜를 선택해 주세요.', bottom: 92 });
      return true;
    }

    if (dayjs(tempDate[0]).isBefore(dayjs(tempDate[1]).subtract(5, 'month'))) {
      setToast({ message: '최대 5개월까지 선택 가능합니다.', bottom: 92 });
      setTempDate([tempPeriod.period?.start, tempPeriod.period?.end]); // 기간선택 초기화
      return true;
    }
    setTempPeriod({
      period: {
        type: tempPeriod.period?.type || 'day',
        start: filters.dateDash(tempDate?.[0]),
        end: filters.dateDash(tempDate?.[1]),
      },
    });
    closeCalendar();
  }, [tempDate, closeCalendar]);

  const selectType = useMemo(() => {
    switch (tempPeriod.period?.type) {
      case 'isoWeek':
        return 'preset-range';
      case 'custom':
        return 'range';
      case 'month':
        return 'preset-range';
      default:
        return 'date';
    }
  }, [tempPeriod.period?.type]);

  const selectSize = useMemo(() => {
    switch (tempPeriod.period?.type) {
      case 'day':
        return 1;
      case 'isoWeek':
        return 7;
      case 'month':
        return 30;
      default:
        return undefined;
    }
  }, [tempPeriod.period?.type]);

  const maxDate = useMemo(() => {
    switch (tempPeriod.period?.type) {
      case 'isoWeek':
        return tempPeriod.period.end;
      default:
        return dayjs().toString();
    }
  }, [tempPeriod.period?.type]);

  useEffect(() => {
    setTempDate([tempPeriod.period?.start, tempPeriod.period?.end]);
  }, [tempPeriod]);

  return (
    <>
      {tempPeriod.period?.type === 'month' ? (
        <MonthPickerDrawer
          zIndex={6010}
          isOpen={isOpenCalender}
          onClose={closeCalendar}
          value={tempDate}
          onChange={changeDate}
          onSave={saveDate}
        />
      ) : (
        <DateCalendarPickerDrawer
          zIndex={6010}
          isOpen={isOpenCalender}
          onClose={closeCalendar}
          onChange={changeDate}
          value={tempDate}
          onSave={saveDate}
          select={selectType}
          selectSize={selectSize}
          max={maxDate}
          firstSelectDay={1}
        />
      )}
    </>
  );
};

export default ReportCalendar;

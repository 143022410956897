import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useGetStudiomateNoticeRecent from 'hooks/service/queries/useGetStudiomateNoticeRecent';
import { Link } from 'react-router-dom';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { HELP_NOTICE_SUMMARY_LIMIT } from '../constants';

const NoticeSummary = () => {
  const { data: notices } = useGetStudiomateNoticeRecent();

  return (
    <StyledSwiper
      pagination
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 4 * 1000, // 4초마다 넘어감
      }}
      speed={0.5 * 1000} // 기본값 0.3초 -> 넘어갈때 느리게
      loop={true} // 무한반복, 슬라이드 끝에서 다시 처음으로 돌아갈 때 같은 방향으로
    >
      {notices?.slice(0, HELP_NOTICE_SUMMARY_LIMIT)?.map(notice => (
        <SwiperSlide key={notice.id}>
          <Notice to={`/more-details/settings/notice/${notice.id}`}>
            <IconWrapper>
              <Icon name="notice_fill" size={16} color="secondary1" />
            </IconWrapper>
            <Typography ellipsis={1}>{notice.title}</Typography>
          </Notice>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
};

export default NoticeSummary;

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    margin-top: 12px;
    ${theme.flex('row', 'center', 'center', 5)}
  }
  .swiper-pagination-bullet {
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${theme.color.gray4};
  }
  .swiper-pagination-bullet-active {
    background-color: ${theme.color.gray2};
  }
`;

const Notice = styled(Link)`
  margin: 0 20px;
  padding: 11px 16px;
  border-radius: 8px;
  background-color: ${theme.color.gray7};
  ${theme.flex('row', 'center', '', 12)}
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${theme.color.gray5};
  flex-shrink: 0;
  ${theme.flex()}
`;

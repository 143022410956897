import Button from 'components/Button';
import { useFormContext, useWatch } from 'react-hook-form';
import { CommonProfileFormType, SubmitButtonProps } from 'sharedComponents/ProfileField/types';

const SubmitButton = ({ onClick }: SubmitButtonProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CommonProfileFormType>();
  const profileName = useWatch({ control, name: 'profile.name' });

  return (
    <Button
      fullWidth
      color="point"
      size="large56"
      variant="contained"
      disabled={!profileName || !!errors.profile}
      onClick={onClick}>
      완료
    </Button>
  );
};

export default SubmitButton;

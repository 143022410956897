import { css, Global } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css');

      * {
        font-family:
          Pretendard,
          -apple-system,
          BlinkMacSystemFont,
          system-ui,
          Roboto,
          'Helvetica Neue',
          'Segoe UI',
          'Apple SD Gothic Neo',
          'Noto Sans KR',
          'Malgun Gothic',
          'Apple Color Emoji',
          'Segoe UI Emoji',
          'Segoe UI Symbol',
          sans-serif;
        box-sizing: border-box;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-smooth: never;
      }

      p {
        word-break: break-all;
      }

      html {
        font-size: 10px;
      }

      html,
      body {
        touch-action: none;
        -webkit-overflow-scrolling: none;
        /* 이외의 브라우저 */
        overscroll-behavior: none;
        position: relative;
      }

      body {
        max-width: 100%;
        overflow-x: hidden;
        -webkit-text-size-adjust: none;
        background-color: white;
      }

      body.hidden {
        overflow: hidden;
      }

      blockquote,
      body,
      button,
      code,
      dd,
      div,
      dl,
      dt,
      fieldset,
      form,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      input,
      legend,
      li,
      ol,
      p,
      pre,
      select,
      td,
      textarea,
      th,
      ul {
        margin: 0;
        padding: 0;
      }
      fieldset,
      img {
        border: 0;
      }
      dl,
      li,
      menu,
      ol,
      ul {
        list-style: none;
      }
      blockquote,
      q {
        quotes: none;
      }
      blockquote:after,
      blockquote:before,
      q:after,
      q:before {
        content: '';
        content: none;
      }
      button,
      input,
      select,
      textarea {
        vertical-align: middle;
      }
      button {
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }
      input[type='checkbox'] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        outline: 0;
      }
      input[type='checkbox']:checked::after {
        display: block;
      }
      html input[type='button'],
      input[type='email'],
      input[type='password'],
      input[type='reset'],
      input[type='search'],
      input[type='submit'],
      input[type='tel'],
      input[type='text'],
      input[type='number'] {
        -webkit-appearance: none;
        border: 0;
        border-radius: 0;
        outline: 0;
      }
      input[type='search']::-webkit-search-cancel-button,
      progress {
        -webkit-appearance: none;
      }
      hr {
        margin: 0;
        border: 0;
      }
      a,
      a:active,
      a:hover {
        text-decoration: none;
      }
      address,
      caption,
      cite,
      code,
      dfn,
      em,
      var {
        font-style: normal;
        font-weight: 400;
      }
      input,
      textarea,
      button,
      select,
      label,
      a {
        -webkit-tap-highlight-color: transparent;
      }
      b {
        font-weight: 600;
      }
      /** input[type="number"]일 때, 웹에서 input 오른편에 arrow button 생기는 것 제거 */
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    `}
  />
);

export default GlobalStyles;

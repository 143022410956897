import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { PushFilterType, PushItemType } from 'pages/Notification/types';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityStaffNoticeCommon = (sort?: PushFilterType) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<StaffNoticeCommonResponse, StaffNoticeCommonParams>({
    url: `/api/staff/notice/common`,
    params: {
      sort_type: sort,
      paginate_type: 'simple',
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['staff/notice', 'common', sort],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityStaffNoticeCommon;

type StaffNoticeCommonParams = {
  sort_type?: PushFilterType;
  paginate_type: 'simple' | 'detail';
};

type StaffNoticeCommonResponse = PushItemType;

import Button from 'components/Button';
import React from 'react';

import { NOTIFICATION_TEXT } from '../constants';
import { HeaderWrapper } from './NotificationHeader';
import NotificationTabs from './NotificationTabs';

const SkeletonHeader = () => {
  return (
    <HeaderWrapper>
      <NotificationTabs />
      <Button fontSize={13} textColor="gray2">
        {NOTIFICATION_TEXT.allRead}
      </Button>
    </HeaderWrapper>
  );
};

export default SkeletonHeader;

import styled from '@emotion/styled';
import Icon from 'components/Icon';
import { PointSelectedFilterType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterProps } from 'sharedComponents/Filters/types';
import convertTargetsText from 'utils/convertTargetsText';

import StaffSelectDrawer from './StaffSelectDrawer';

const StaffSelectFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const previousOptions = selectedOption as PointSelectedFilterType['selectedStaffs'];
  const staff = previousOptions?.map(({ label }) => ({ name: label }));

  return (
    <>
      <Container>
        <FilterTitle title={currentFilter.title} />
        <StaffSelectButton icon={<Icon name="arrowBottom" color="gray2" />} textColor="gray1" onClick={() => setIsOpen(true)}>
          {convertTargetsText(staff, '전체')}
        </StaffSelectButton>
      </Container>

      <ApiBoundary>
        <StaffSelectDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          previousOptions={previousOptions}
          changeOptions={changeOptions}
        />
      </ApiBoundary>
    </>
  );
};

export default StaffSelectFilter;

const Container = styled.div`
  padding: 20px 20px 24px;
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;

import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { PropsWithChildren } from 'react';

// 전체 회원 어느 액션을 하든 무조건 권한체크를 하도록 강제
const MemberPermissionGuard = ({ children }: PropsWithChildren) => {
  const { checkPermission } = useGetPermissionDoHavePermission();

  return (
    <div
      onClick={() => {
        checkPermission(PERMISSION.member.view.id, {});
      }}>
      {children}
    </div>
  );
};

export default MemberPermissionGuard;

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType, BookingCommonFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

import BookingResetConfirmDialog from '../BookingResetConfirmDialog';
import RoomSettingDialog from '../Room/RoomSettingDialog';

type Props = {
  /** 수정, 이후 모든 수정 폼에서 룸설정 초기화 용도 (update) */
  pageMode: AllPageModeType;
};

const BookingRangeTime = ({ pageMode }: Props) => {
  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<BookingCommonFormType>();
  const startTime = useWatch({ control, name: 'startTime' });
  const endTime = useWatch({ control, name: 'endTime' });
  const endDate = useWatch({ control, name: 'endDate' });
  const isSelectedFutureTime = useWatch({ control, name: 'isSelectedFutureTime' });
  const [prevTime, setPrevTime] = useState([startTime, endTime]);

  // 생성 시 선택한 룸이 있는 경우에만 시간 변경 시 룸 설정 초기화 dialog 노출
  const resetRoom = () => {
    const hasSelectedRoom = getValues('roomId') !== BOOKING_COMMON_FORM_TEXT.room.notSelected.value && !getValues('selectedRoom');
    if ((pageMode === 'update' || pageMode === 'updateAll') && hasSelectedRoom) {
      setValue('needResetRoom', 'time', { shouldDirty: true });
      setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value, { shouldDirty: true });
      setValue('selectedRoom', undefined, { shouldDirty: true });
    }
  };

  /** 시작시간이 종료시간 보다 늦거나 시작시간과 종료시간이 같은 경우 */
  const add50min = (value: [Date, Date]) => {
    if (dayjs(value[0]).isAfter(value[1]) || dayjs(value[0]).isSame(value[1])) {
      const add50Min = dayjs(value[0]).add(50, 'minute').toDate();
      setValue('endTime', add50Min);
    }
  };

  const cancelDialog = () => {
    if (!getValues('currentLecture')) return;
    const lectureStartTime = dayjs(getValues('currentLecture.start_on')).toDate();
    const lectureEndTime = dayjs(getValues('currentLecture.end_on')).toDate();
    const isDifferent = lectureStartTime !== prevTime[0];
    setValue('startTime', isDifferent ? prevTime[0] : lectureStartTime);
    setValue('endTime', isDifferent ? prevTime[1] : lectureEndTime);
    setValue('isSelectedFutureTime', false);
  };

  const continueDialog = () => {
    setValue('isSelectedFutureTime', false, { shouldDirty: true });
    resetRoom();
  };

  /**
   * 1개 수정 시 기존 수업 시작시간 보다 이후를 선택하는 경우,
   * 회원 예약 취소됨 다이얼로그 노출
   */
  const showResetBookingDialog = (value: [Date, Date]) => {
    setPrevTime([startTime, endTime]);
    setValue('startTime', value[0]);
    add50min(value);
    setValue('isSelectedFutureTime', true);
    return;
  };

  const changeTime = (value: [Date, Date]) => {
    const selectedDateTime = `${endDate} ${filters.timeSecondZero(value[0])}`; // 바꾸려는 시간
    const currentLectureStartOn = getValues('currentLecture.start_on');
    const currentTime = filters.dateDashTimeSecond();
    const isSelectedFutureTimeFirst =
      pageMode === 'update' &&
      dayjs(currentLectureStartOn).isBefore(currentTime) &&
      dayjs(selectedDateTime).isAfter(currentTime) &&
      !dirtyFields.isSelectedFutureDate &&
      !dirtyFields.isSelectedFutureTime;

    if (isSelectedFutureTimeFirst) {
      showResetBookingDialog(value);
    } else {
      setValue('startTime', value[0], { shouldDirty: true });
      setValue('endTime', value[1], { shouldDirty: true });
      resetRoom();
      add50min(value);
    }
  };

  return (
    <>
      <StyledAccordion iconName="time" header={filters.timePeriod(startTime, endTime)} hasValue={!!startTime}>
        <RangeTimePicker showAddTimeButton stepMinute={5} timeRange={[startTime, endTime]} onChange={changeTime} />
      </StyledAccordion>
      {isSelectedFutureTime && <BookingResetConfirmDialog target="시간" onCancel={cancelDialog} onContinue={continueDialog} />}
      {(pageMode === 'update' || pageMode === 'updateAll') && <RoomSettingDialog />}
    </>
  );
};

const StyledAccordion = styled(FormFieldAccordion)`
  .range-time-picker {
    padding-bottom: 24px;
  }
`;

export default BookingRangeTime;

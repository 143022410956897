import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import FilterTabs from 'components/Tabs/FilterTabs';
import { TicketsParams } from 'hooks/service/queries/useInfinityTickets';
import useTab from 'hooks/useTab';
import { ITEM_TAB_DATA, TICKET_TAB_DATA } from 'pages/ProductForm/constants';
import { SelectTicketFormType } from 'pages/ProductForm/types';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import NextButton from './NextButton';
import Search from './Search';
import TicketList from './TicketList';
import Title from './Title';

type Props = {
  productType: TicketsParams['product_type'];
};

const SelectTicket = ({ productType }: Props) => {
  const { setValue } = useFormContext<SelectTicketFormType>();

  const tabData = useMemo(() => (productType === 'userTicket' ? TICKET_TAB_DATA : ITEM_TAB_DATA), [productType]);

  const { currentTab, onChange } = useTab(tabData);

  const cancelSearch = useCallback(() => {
    setValue('search', undefined);
    onChange(tabData[0]);
  }, [onChange, setValue, tabData]);

  return (
    <Container>
      <Title productType={productType} />
      <Search productType={productType} onCancel={cancelSearch} />

      <FilterTabs
        data={tabData}
        value={currentTab.value}
        onChange={onChange}
        selectedColor="point"
        padding={{ left: 16, right: 16 }}
        gap={6}
      />

      <ApiBoundary>
        <TicketList productType={productType} tabData={tabData} currentTabValue={currentTab.value} />
      </ApiBoundary>

      <NextButton />
    </Container>
  );
};

export default SelectTicket;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  position: relative;
  height: 100%;
  padding-bottom: 76px;

  .tabs {
    padding: 16px 20px 18px;
  }
`;

import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { FormProvider, useForm } from 'react-hook-form';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import getDaysDiff from 'utils/getDaysDiff';
import getImageUrl from 'utils/getImageUrl';

import { ProductUpdateFormType } from '../types';
import CommonProductForm from './CommonProductForm';

type Props = {
  userTicket: UserTicketResponse;
  onSubmit: (values: ProductUpdateFormType) => void;
};

const ProductUpdateForm = ({ userTicket, onSubmit }: Props) => {
  const {
    ticket_id,
    is_show_cancel_count,
    max_coupon,
    remaining_cancel,
    remaining_coupon,
    availability_start_at,
    expire_at,
    ticket: { type, available_class_type },
    staffs,
    is_shared,
    payments: {
      current_point,
      last_payment: {
        id: paymentId,
        amount,
        card_amount,
        cash_amount,
        wiretransfer_amount,
        unpaid_amount,
        point_amount,
        reward_point,
        settlement_at,
        installment_period,
      },
      original_price,
    },
  } = userTicket;

  const methods = useForm<ProductUpdateFormType>({
    defaultValues: {
      userTicket,

      installment: installment_period < 2 ? 'single' : 'split',
      isCheckedCard: !!card_amount,
      isCheckedCash: !!cash_amount,
      isCheckedWireTransfer: !!wiretransfer_amount,
      isCheckedPoint: !!point_amount,
      salePrice: original_price,
      remainingPoint: current_point,
      originAvailablePeriod: getDaysDiff(expire_at, availability_start_at) + 1,

      paymentId,
      paymentStatus: 'purchase',

      payment: {
        amount,
        card_amount,
        cash_amount,
        wiretransfer_amount,
        point_amount,
        reward_point,
        installment_period: installment_period < 2 ? 2 : installment_period,
        discount: 0,
        unpaid_amount,
        settlement_at,
      },
      count: {
        max_coupon,
        remaining_cancel,
        remaining_coupon,
      },
      period: {
        availability_start_at,
        expire_at,
      },
      detail: {
        is_shared,
        is_show_cancel_count,
        staffs: staffs.map(({ id, name, image }) => ({ id, name, imgUrl: getImageUrl(image) })),
        tempStaffs: staffs.map(({ id, name, image }) => ({ id, name, imgUrl: getImageUrl(image) })),
        ticket_id,
      },
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <CommonProductForm
        pageMode="update"
        ticket={formatSmallUserTicketCard(userTicket)}
        isShowCancelCount={!!is_show_cancel_count}
        type={type}
        isItem={available_class_type === 'I'}
        onSubmit={handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

export default ProductUpdateForm;

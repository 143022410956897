import { AtomEffect, DefaultValue } from 'recoil';

import localStorage from './localStorage';

const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  key =>
  ({ setSelf, onSet }) => {
    const value = localStorage.get(key);

    setSelf(value || new DefaultValue());

    onSet((newValue, _, isReset) => {
      isReset ? localStorage.remove(key) : localStorage.set(key, newValue);
    });
  };

export default localStorageEffect;

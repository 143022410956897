import { useSetRecoilState } from 'recoil';
import { errorDialogAtom } from 'recoil/dialog';
import { CustomError } from 'types/errorTypes';

export type CustomErrorType = CustomError | string;

const useErrorDialog = () => {
  const setIsErrorDialogOpen = useSetRecoilState(errorDialogAtom);

  const open = (error: any, customClose?: () => void, buttonText?: string) => {
    setIsErrorDialogOpen({ show: true, error, customClose, buttonText });
  };

  const close = () => {
    setIsErrorDialogOpen(prev => {
      if (prev.customClose) {
        prev.customClose();
      }

      return { ...prev, show: false, customClose: undefined, buttonText: undefined };
    });
  };

  return { open, close };
};

export default useErrorDialog;

import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useMessage from 'hooks/useMessage';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import filters from 'utils/filters';

import { APP_PUSH_FORM_TEXT } from '../constants';
import { AppPushFormType } from '../types';

type Props = {
  onConfirm: () => void;
};

const AppPushConfirmButton = ({ onConfirm }: Props) => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const { getValues, watch, formState } = useFormContext<AppPushFormType>();

  const isReserved = watch('isReserve');
  const reservationDate = watch('reservationDate');

  // 필수값 미입력 혹은 예약 선택 후 예약 날짜가 없으면 버튼 비활성화
  const isRequirementsFilled = watch('target') && watch('title') && watch('message');
  const isValidReservation = !isReserved || reservationDate; // 예약 선택 후 예약 날짜가 없으면 false
  const disabled = !isRequirementsFilled || !isValidReservation || !!formState.errors.title || !!formState.errors.message;

  const mode: 'create' | 'update' = getValues('mode');
  const count = watch('users').length;
  const type = isReserved ? 'reserve' : 'now';

  const { checkReservationTime } = useMessage();

  const openConfirmDialog = () => {
    if (isReserved && !checkReservationTime(reservationDate)) return;
    setIsDialogOpened(true);
  };

  const confirm = () => {
    setIsDialogOpened(false);
    if (isReserved && !checkReservationTime(reservationDate)) return;
    onConfirm();
  };

  return (
    <>
      <Button variant="contained" color="point" size="large56" onClick={openConfirmDialog} disabled={disabled}>
        {APP_PUSH_FORM_TEXT[mode].confirmButton}
      </Button>
      {isDialogOpened && (
        <Dialog
          onClose={() => setIsDialogOpened(false)}
          negativeAction={{ text: '취소', onClick: () => setIsDialogOpened(false), style: 'outlined' }}
          positiveAction={{ text: APP_PUSH_FORM_TEXT.dialogButton[type], onClick: confirm }}>
          <DialogDescription tag="pre">
            <b>{filters.numberComma(count)}건</b>
            {`의 앱 푸시 메시지를\n${APP_PUSH_FORM_TEXT.dialogDescription[type]}`}
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default AppPushConfirmButton;

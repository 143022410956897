import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import EventWorkTimeCard from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/components/EventWorkTimeCard';
import { CommonProps } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';

type Props = CommonProps;

const EventScheduleUpdateFormSingle = ({
  workTimeField,
  setWorkTimeField,
  isFieldClosedDay,
  setIsFieldClosedDay,
  isAccordionOpen,
  setIsAccordionOpen,
}: Props) => {
  return (
    <Container>
      <Form id="event-time-single-form">
        <EventWorkTimeCard
          workTimeField={workTimeField}
          setWorkTimeField={setWorkTimeField}
          isFieldClosedDay={isFieldClosedDay}
          setIsFieldClosedDay={setIsFieldClosedDay}
          isAccordionOpen={isAccordionOpen}
          setIsAccordionOpen={setIsAccordionOpen}
        />
      </Form>
    </Container>
  );
};

export default EventScheduleUpdateFormSingle;

const Container = styled.div`
  ${theme.flex('column')};
  min-height: 100%;

  #event-time-single-form {
    ${theme.flex('column', 'center', 'flex-start')};
    width: 100%;
    background-color: ${theme.color.white};
    flex: 1;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Typography from 'components/Typography';

type Props = {
  onClick?: () => void;
  label: string;
  value?: string;
};

const SettingItem = ({ onClick, label, value }: Props) => {
  return (
    <StyledLinkButton onClick={onClick} showArrow={false} showBorder size="large60">
      <Typography size={15} weight={600}>
        {label}
      </Typography>
      {value && (
        <Typography size={15} weight={500} textColor="gray2">
          {value}
        </Typography>
      )}
    </StyledLinkButton>
  );
};

export default SettingItem;

const StyledLinkButton = styled(LinkButton)`
  .link-button-contents-wrapper {
    ${theme.flex('row', 'center', 'space-between')};
  }
`;

import { delay, http, HttpResponseResolver, RequestHandler } from 'msw';

const DELAY = 0.5 * 1000; // 0.5초 지연, jest 환경상 5초 이상 지연시 타임아웃 오류 발생
const BASE_URL = 'https://api.manager.qa.studiomate.kr/api';

export const api = {} as {
  [key in Method]: (path: string, resolver: HttpResponseResolver) => RequestHandler;
};
for (const method in http) {
  api[method as Method] = (path, resolver) => http[method as Method](`${BASE_URL}${path}`, withDelay(resolver));
}

type Method = keyof typeof http;

function withDelay(resolver: HttpResponseResolver): HttpResponseResolver {
  return async (...args) => {
    await delay(DELAY);
    return resolver(...args);
  };
}

import styled from '@emotion/styled';
import handImg from 'assets/images/tutorial2_hand.webp';
import Typography from 'components/Typography';

const TutorialSecond = () => {
  return (
    <Container>
      <ContentWrapper>
        <HandWrapper>
          <img src={handImg} alt="좌우스크린이동가능" />
        </HandWrapper>
        <TextWrapper>
          <Typography span textColor="white" size={19} weight={600}>
            화면을 &nbsp;
          </Typography>
          <span className="highlight-color">좌우로 밀어서</span>
          <Typography textColor="white" size={19} weight={600}>
            날짜를 이동할 수 있습니다.
          </Typography>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default TutorialSecond;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-25%, -40%);
`;

const HandWrapper = styled.div`
  position: absolute;
  width: 186px;
  height: 82px;
  margin-bottom: 16px;
  padding: 0px 7px;
  left: 50%;
  transform: translateX(-50%);

  img {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  width: 20rem;
  top: 9.5rem;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  .highlight-color {
    font-size: 19px;
    font-weight: 600;
    color: #2cead7;
  }
`;

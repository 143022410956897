import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { isAxiosError } from 'axios';
import usePatchUserTicketUpgrade from 'hooks/service/mutations/usePatchUserTicketUpgrade';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useToast from 'hooks/useToast';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { TICKET_UPGRADE_TEXT } from 'pages/TicketDetail/constants';
import { TicketUpgradeFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';
import getPaymentFormParams from 'utils/getPaymentFormParams';

import PaymentInfo from './PaymentInfo';
import SelectUpgradeTicket from './SelectUpgradeTicket';
import TitleWithButton from './SelectUpgradeTicket/TitleWithButton';
import UpgradeInfo from './UpgradeInfo';

type Props = UserTicketProps & {
  step: number;
  onClickNextPage: () => void;
};

const TicketUpgrade = ({ userTicket, step, onClickNextPage }: Props) => {
  const methods = useForm<TicketUpgradeFormType>({
    defaultValues: {
      search: '',
      tempSearchKeyword: '',
      selectedTicket: null,

      installment: 'single',
      isCheckedCard: true,
      isCheckedCash: false,
      isCheckedWireTransfer: false,
      isCheckedPoint: false,
      isAutoWriteUnpaid: true, // 미수금 자동입력 설정하지 않아도 업그레이드에서는 결제 합이 0이면 차액만큼 미수금으로 입력됨
      salePrice: userTicket.payments.initial_payment.amount,
      remainingPoint: userTicket.payments.current_point,

      payment: {
        amount: userTicket.payments.initial_payment.amount,
        discount: 0,
        installment_period: 2,
        card_amount: 0,
        cash_amount: 0,
        wiretransfer_amount: 0,
        reward_point: 0,
        point_amount: 0,
        unpaid_amount: 0,
        settlement_at: filters.dateDashTimeSecond(),
      },
    },
  });

  const navigate = useNavigate();
  const { setToast } = useToast();
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);
  const { mutate: upgradeMutate } = usePatchUserTicketUpgrade(userTicket.id);
  const { checkPermission } = useGetPermissionDoHavePermission();

  const title = TICKET_UPGRADE_TEXT.title[step - 1];
  const message = TICKET_UPGRADE_TEXT.subTitle[step - 1];

  const submit = (values: TicketUpgradeFormType) => {
    const { selectedTicket, payment, installment } = values;
    if (!selectedTicket) return;

    const paymentParams = getPaymentFormParams({ payment, installment });
    const params = {
      ...paymentParams,
      user_id: userTicket.user_id,
      ticket_id: selectedTicket.id,
    };

    upgradeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: TICKET_UPGRADE_TEXT.successMessage });
        navigate(-3);
      },
      onError: (error: CustomError) => {
        const isErrorDrawerCase =
          isAxiosError(error) &&
          (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);
        if (isErrorDrawerCase) {
          setErrorBackStep(-3);
        }
      },
    });
  };

  useEffect(() => {
    if (step > 1 && !methods.getValues('selectedTicket')) {
      navigate(-step);
    }
  }, [methods, navigate, step]);

  /** 다음 단계 넘어갈 때마다 권한 체크 */
  useEffect(() => {
    if (step !== 1) {
      checkPermission(MEMBER_PERMISSION.memberTicketEdit.id, {
        onError: () => {
          navigate(-step);
        },
      });
    }
  }, [checkPermission, navigate, step]);

  return (
    <MainLayout
      progress={{ step, max: 3 }}
      header={{ title: TICKET_UPGRADE_TEXT.title[step - 1], titleScrollThreshold: 40 }}
      resetScrollTrigger={String(step)}>
      <FormProvider {...methods}>
        <Container>
          <TitleWithButton step={step} title={title} message={message} />
          {step === 1 && <SelectUpgradeTicket userTicket={userTicket} />}
          {step === 2 && <UpgradeInfo userTicket={userTicket} onClickNextPage={onClickNextPage} />}
          {step === 3 && <PaymentInfo userTicket={userTicket} onSubmit={submit} />}
        </Container>
      </FormProvider>
    </MainLayout>
  );
};

export default TicketUpgrade;

const Container = styled.div`
  height: 100%;
  ${theme.flex('column', 'flex-start', 'flex-start')};

  > div {
    width: 100%;
  }

  hr {
    width: 100%;
    flex: 0 0 auto;
  }
`;

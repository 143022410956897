import { MessageTargetFormType, MessageTargetType } from 'pages/MoreDetails/Message/types';
import { createContext, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  target: MessageTargetType;
  className?: string;
  children?: ReactNode;
};

const TargetSelectable = ({ target, children, className }: Props) => {
  const { watch, setValue } = useFormContext<MessageTargetFormType>();
  const selectedMembers = watch('selected');
  const isSelected = selectedMembers.some(selected => selected.id === target.id);

  const isAllSelected = watch('isAllSelected');

  const changeSelected = () => {
    if (isAllSelected) return;
    const updatedList = isSelected
      ? selectedMembers.filter(selected => selected.id !== target.id) // 삭제
      : [...selectedMembers, target]; // 추가

    setValue('selected', updatedList);
    if (isSelected) {
      setValue('isAllSelected', false);
    }
  };

  return (
    <div
      className={className}
      onClick={e => {
        e.preventDefault();
        changeSelected();
      }}>
      <TargetSelectContext.Provider
        value={{
          isSelected,
          disabled: isAllSelected,
        }}>
        {children}
      </TargetSelectContext.Provider>
    </div>
  );
};

export default TargetSelectable;

export const TargetSelectContext = createContext({
  isSelected: false,
  disabled: false,
});

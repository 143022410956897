import Typography, { TypographyProps } from 'components/Typography';
import { forwardRef, memo, useMemo } from 'react';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

export type ButtonProps = ButtonBaseProps &
  Pick<TypographyProps, 'textColor' | 'lineHeight' | 'ellipsis'> & {
    /** 버튼의 텍스트 사이즈 */
    fontSize?: TypographyProps['size'];
    /** 버튼의 텍스트 굵기 */
    fontWeight?: TypographyProps['weight'];
    /** 텍스트 버튼의 밑줄 유무 */
    textUnderline?: TypographyProps['underline'];
    /** 텍스트 투명도 */
    textOpacity?: TypographyProps['opacity'];
    /** 텍스트 왼편에 아이콘 추가 */
    leftIcon?: React.ReactNode;
    /** 텍스트 오른편에 아이콘 추가 */
    rightIcon?: React.ReactNode;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      textColor,
      fontWeight = 500,
      lineHeight,
      textOpacity,
      leftIcon,
      rightIcon,
      textUnderline,
      ellipsis,
      ...props
    },
    ref,
  ) => {
    const { disabled, color, size, fontSize, variant } = props;

    const typographyColor = useMemo(() => {
      if (disabled) return 'gray4';
      if (textColor) return textColor;
      if (textUnderline) return 'gray3';

      if (variant === 'outlined') {
        switch (size) {
          case 'large56':
            return 'gray1';
          case 'medium48':
          case 'medium40':
          case 'small':
          case 'tiny2':
          case 'tiny':
            return 'gray2';
        }
      }

      switch (color) {
        case 'primary':
        case 'point':
          return 'white';
        case 'blue':
          return 'primary';
      }

      if (color === 'gray') {
        switch (size) {
          case 'large56':
          case 'medium48':
            return 'gray1';
          case 'medium40':
          case 'small':
          case 'tiny2':
          case 'tiny':
            return 'gray2';
        }
      }
    }, [textColor, textUnderline, disabled, color, size, variant]);

    const textSize = useMemo(() => {
      if (fontSize) return fontSize;

      switch (size) {
        case 'large60':
        case 'large56':
          return 15;
        case 'medium48':
        case 'medium40':
          return 14;
        case 'small':
        case 'tiny':
        default:
          return 13;
      }
    }, [fontSize, size]);

    return (
      <ButtonBase className={className ? `text-button ${className}` : `text-button`} ref={ref} {...props}>
        {leftIcon && leftIcon}
        <Typography
          span
          ellipsis={ellipsis}
          textColor={typographyColor}
          underline={textUnderline}
          size={textSize}
          weight={fontWeight}
          lineHeight={lineHeight}
          opacity={textOpacity}>
          {children}
        </Typography>
        {rightIcon && rightIcon}
      </ButtonBase>
    );
  },
);

Button.displayName = 'Button';
export default memo(Button);

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { MENUS } from '../constants';
import Navigation from './Navigation';
import QnaNewBadge from './QnaNewBadge';

const MenuNavigation = () => {
  return (
    <>
      <Navigation
        menus={MENUS.basic}
        renderMenu={menu => (
          <BasicLink key={menu.name} to={menu.path}>
            <Typography size={15} weight={600}>
              {menu.name}
            </Typography>
            {menu.name === '1:1 문의' && (
              <ApiBoundary>
                <QnaNewBadge />
              </ApiBoundary>
            )}
          </BasicLink>
        )}
      />
      <DividerGap thin margin={12} thinColor="gray6" />
      <Navigation
        menus={MENUS.sub}
        renderMenu={menu => (
          <SubLink key={menu.name} to={menu.path}>
            <Typography weight={500} textColor="gray2">
              {menu.name}
            </Typography>
          </SubLink>
        )}
      />
    </>
  );
};

export default MenuNavigation;

const BasicLink = styled(Link)`
  display: block;
  padding: 13px 20px 13px;
  ${theme.flex('', 'center', 'start', 8)}
`;

const SubLink = styled(Link)`
  display: block;
  padding: 12px 20px 12px;
`;

const DividerGap = styled(Divider)<{ margin: number }>`
  margin: ${({ margin }) => margin}px 0;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

const NoData = () => {
  return (
    <Container>
      <Typography size={15} textColor="gray2" opacity={0.8}>
        댓글이 없습니다.
      </Typography>
    </Container>
  );
};

export default NoData;

const Container = styled.section`
  ${theme.flex()};
  padding: 100px 0;
`;

import { useIsFetching } from '@tanstack/react-query';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import FormMain from './FormMain';

/** 그룹 수업 등록 */
const BookingGroupCreate = () => {
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <FormMain />
    </ApiBoundary>
  );
};

export default BookingGroupCreate;

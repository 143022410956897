import { MobileFormType } from './types';

export const MOBILE_FORM_TEXT = {
  title: '문자 메시지 ',
  titleEditSuffix: '수정',
  sender: '발송번호',
  senderTooltip: [
    '발송번호는 PC 웹페이지에서 설정 가능',
    '합니다. 발송번호 변경을 원할 경우',
    'PC 웹페이지로 접속하여 변경해 주세요.',
  ],
  target: '받는사람',
  targetMember: '회원목록',
  targetCounsel: '상담고객목록',
  targetPlaceholder: '회원 선택',
  noTarget: '목록에서 회원을 선택하세요.',

  targetReset: {
    title: '회원 목록을 초기화 하시겠습니까?',
    message: '다른 종류의 목록을 선택할 경우\n기존 선택된 회원 목록이 초기화 됩니다.',
    cancel: '취소',
    confirm: '초기화',
  },
  mobileTitle: '제목',
  titlePlaceholder: 'LMS 기준 20자(40바이트) 이내로 입력',
  titleTooltip: [
    '한글, 영어, 숫자, 띄어쓰기 및 (), [], < >만',
    '입력할 것을 권장합니다. 이 외 특수 기호',
    ' 사용 시 전송이 실패될 수 있습니다.',
  ],
  message: '내용',
  messagePlaceholder: '(광고)',

  create: {
    confirmButton: '메시지 보내기',
    drawerTitle: '메시지를 즉시 발송합니다.',
    drawerTitleReserved: '메시지를 예약 발송합니다.',
    sendButton: '발송 완료',
    successMessage: '메시지가 발송되었습니다.',
    successMessageReserved: '메시지가 예약되었습니다.',
  },
  update: {
    confirmButton: '수정',
    drawerTitle: '예약 메시지를 수정합니다.',
    drawerTitleReserved: '예약 메시지를 수정합니다.',
    sendButton: '수정 완료',
    successMessage: '예약 메시지가 수정되었습니다.',
  },

  type: '유형',
  count: '건수',
  sendOn: '발송일시',
  deductionPoint: '차감포인트',
  refundPoint: '환불포인트',
  cancel: '취소',
};

export const AD_TEXT = {
  buttonText: '광고문자 표기사항',
  title: '광고 문자 표기 의무사항 확인',
  descriptions: [
    { title: '본문 앞에 (광고) 넣기', contents: ['광고, (광고아님), (광/고) 등 변칙 표기 불가.'] },
    { title: '업체 또는 서비스명과 연락처 표기', contents: ['회신번호와 전송자 연락처가 동일한 경우', '연락처는 생략 가능.'] },
    {
      title: '무료거부 문구 표기 및 무료 수신거부 방법 안내',
      contents: ['”수신거부", “거부" 표기 안됨.', '반드시 “무료"가 표기되어야 함.'],
    },
  ],
  warningTitle: '반드시 확인하세요.',
  warning: [
    '정보통신망 이용촉진 및 정보보호 등에 관한 법률(시행 2019. 6. 25.) 제 50조의 4(정보 전송 역무 제공 등의 제한)에 근거하여 광고메시지 전송시 표기의무 준수해야하며 표기 위반시 ',
    '과태료가 최대 3천만원 부과',
    '될 수 있습니다. 야간 광고(21시~익일 8시) 전송 시 별도의 수신 동의가 필요합니다.',
  ],
};

export const MOBILE_POINT_TEXT = {
  notEnoughTooltip: ['포인트가 부족합니다.', 'PC웹페이지 접속 후 포인트를', '충전해 주세요.'],
  point: '보유 포인트',
  suffix: 'P',
  description: '*포인트 충전은 웹페이지에서 진행해 주세요.',
  confirm: '확인',
};

export const MOBILE_POINT = {
  SMS: 12,
  LMS: 37,
};

export const MOBILE_MAX_LENGTH = {
  title: 40,
  message: 2000,
};

export const MOBILE_DEFAULT_VALUES: MobileFormType = {
  mode: 'create',
  type: 'mobile',
  title: '',
  message: '',
  users: [],
  counseling_logs: [],
  from: '',
  isReserve: false,
  reservationDate: '',
  filter: null,
};

export const TITLE_EMOJI_ALLOWED_REGEX = /^[a-zA-Z0-9\p{L}\s.,()<>[\]]*$/u;

export const MOBILE_EMOJI_ERROR_TEXT = {
  title: '제목의 특수문자는\n( ), < >, [ ]만 사용 가능합니다.',
  content: '문자 메시지에는\n이모지를 포함할 수 없습니다.',
};

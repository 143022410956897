import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';

import { APP_PUSH_FORM_TEXT } from '../constants';
import { AppPushFormType } from '../types';

const AppPushTargets = () => {
  const { control, getValues } = useFormContext<AppPushFormType>();
  const targets = useWatch({ control, name: 'users' });

  const isSelected = targets.length > 0;

  const navigate = useNavigate();
  const navigateToTarget = () => {
    navigate(`/more-details/message/target?type=member`, { state: getValues(), replace: true });
  };

  return (
    <Container>
      <FlexRow>
        <Typography size={13} weight={500} textColor="gray2">
          {APP_PUSH_FORM_TEXT.target}
        </Typography>
        <Typography size={13} weight={600} textColor="primary">
          {filters.numberComma(targets.length)}
        </Typography>
      </FlexRow>
      <Button
        edge="circular"
        heightSize={32}
        variant="outlined"
        widthSize={81}
        fontSize={14}
        fontWeight={isSelected ? 600 : 400}
        textColor={isSelected ? 'primary' : 'gray2'}
        outlineColor={isSelected ? 'primary' : undefined}
        onClick={navigateToTarget}>
        {APP_PUSH_FORM_TEXT.users}
      </Button>
    </Container>
  );
};

export default AppPushTargets;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between')}
  margin : 16px 0 12px;
`;

const FlexRow = styled.div`
  ${theme.flex('', 'center', '', 4)}
`;

import Form from 'components/Form';
import usePatchPayment from 'hooks/service/mutations/usePatchPayment';
import useToast from 'hooks/useToast';
import { TICKET_PAYMENT_TEXT, TICKET_REFUND_TEXT } from 'pages/TicketDetail/constants';
import { TicketPaymentUpdateFormType } from 'pages/TicketDetail/types';
import { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';
import { TicketPaymentForm } from 'sharedComponents/TicketForm';
import getPaymentFormParams from 'utils/getPaymentFormParams';

import PaymentForm from './PaymentForm';
import SubmitButton from './SubmitButton';

type Props = Pick<ComponentProps<typeof PaymentForm>, 'goBack'>;

const PaymentUpdateForm = ({ goBack }: Props) => {
  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<TicketPaymentUpdateFormType>();

  const { setToast } = useToast();

  const { mutate: updatePaymentMutate } = usePatchPayment(getValues('id'));

  const updatePayment = (values: TicketPaymentUpdateFormType) => {
    const { id, status, ...payment } = values;

    if (dirtyFields.payment?.point_amount && Number(values.remainingPoint) < Number(values.payment.point_amount)) {
      setToast({
        type: 'error',
        message: TICKET_REFUND_TEXT.errorMessage.pointLack,
        bottom: 136,
      });
      return;
    }

    const paymentParams = getPaymentFormParams({ ...payment });

    const params = {
      ...paymentParams,
      status,
    };

    updatePaymentMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: TICKET_PAYMENT_TEXT.updateSuccessMessage });
        goBack();
      },
    });
  };
  return (
    <Form onSubmit={handleSubmit(updatePayment)}>
      <TicketPaymentForm isRefund={getValues('userTicket.status') === 'refund'} />
      <SubmitButton>수정 완료</SubmitButton>
    </Form>
  );
};

export default PaymentUpdateForm;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';

const ScheduleHeaderFallback = () => {
  return (
    <Container>
      <Icon name="headerSetting" fillColor={theme.color.white} />
    </Container>
  );
};

export default ScheduleHeaderFallback;

const Container = styled.div`
  position: relative;
  height: 56px;

  svg {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

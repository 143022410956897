import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import { BookingType, DropdownType } from 'pages/Booking/types';
import { Color } from 'utils/getColor';

import { BOOKING_DETAIL_DROPDOWN_DATA } from '../../constants';

type Props = {
  bookingType: BookingType;
  bookingClosedAt: LectureDetailResponse['booking_closed_at'];
  isOpen: boolean;
  onToggle: (value: boolean) => void;
  onClick: (value: DropdownType['value']) => void;
  canCreateLecture: boolean;
  canUpdateLecture: boolean;
  canDeleteLecture: boolean;
  contrastColor: Color;
};

const BookingDetailDropDown = ({
  bookingType,
  contrastColor,
  canCreateLecture,
  canUpdateLecture,
  canDeleteLecture,
  bookingClosedAt,
  isOpen,
  onClick,
  onToggle,
}: Props) => {
  /** 예약마감된 수업일 때, 보일 dropdown list */
  const openList = BOOKING_DETAIL_DROPDOWN_DATA.filter(({ value }) => ['open', 'update', 'delete'].includes(value));
  const clickList = bookingClosedAt ? openList : BOOKING_DETAIL_DROPDOWN_DATA.slice(1);

  return (
    <DropDown
      isOpen={isOpen}
      position="right"
      bottomSpacing={8}
      onClose={() => onToggle(false)}
      content={
        <Container>
          {clickList.map(({ value, label, textColor, icon }) => {
            if (bookingType === 'private' && value === 'close') return null;
            if (!canCreateLecture && value === 'copy') return null;
            if (!canUpdateLecture && value !== 'copy' && value !== 'delete') return null;
            if (!canDeleteLecture && value === 'delete') return null;
            return (
              <li key={value}>
                <Button
                  fullWidth
                  fontSize={15}
                  fontWeight={500}
                  textColor={textColor}
                  rightIcon={<Icon size={16} {...icon} />}
                  onClick={() => onClick(value)}>
                  {label}
                </Button>
              </li>
            );
          })}
        </Container>
      }>
      <IconButton onClick={() => onToggle(!isOpen)}>
        <Icon name="headerMore" color={contrastColor} />
      </IconButton>
    </DropDown>
  );
};

export default BookingDetailDropDown;

const Container = styled.ul`
  width: 128px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 2px 8px 0px rgba(145, 145, 145, 0.2);

  li {
    padding: 13px 12px 13px 16px;
    :not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray7};
    }

    button {
      height: 100%;
      justify-content: space-between;
    }
  }
`;

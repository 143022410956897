import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Form from 'components/Form';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Timer from 'components/Timer';
import { PLACEHOLDER } from 'constants/text';
import usePostCodeConfirm from 'hooks/service/mutations/usePostCodeConfirm';
import usePostSendMobileConfirmCodeForPasswordChange from 'hooks/service/mutations/usePostSendMobileConfirmCodeForPasswordChange';
import useAutoFocusTracking from 'hooks/useAutoFocusTracking';
import { MobileFormType } from 'pages/Join/types';
import { CHANGE_PHONE_NUMBER_TEXT } from 'pages/MoreDetails/Account/ChangePhoneNumber/constants';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { validationIdCodeAtom } from 'recoil/validationIdCode';
import { CustomError } from 'types/errorTypes';
import { mobileConfirmCode, mobileRequired } from 'utils/validate';
import { object } from 'yup';

import SendMobileKakaoDrawer from '../../../sharedComponents/SendMobileKakaoDrawer';
import { SUB_TITLE } from '../constants';
import SubmitButton from './SubmitButton';
import TitleStep from './TitleStep';

type Props = {
  currentStep: number;
  nextStep: () => void;
};

const yupSchema = object().shape({ mobileRequired, mobileConfirmCode });

const FindPwdCheckMobile = ({ currentStep, nextStep }: Props) => {
  const [validationIdCode, setValidationIdCode] = useRecoilState(validationIdCodeAtom);

  const [isMobilePass, setIsMobilePass] = useState(false);
  const [isKakaoDrawerOpen, setIsKakaoDrawerOpen] = useState(false);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    watch,
    setFocus,
    formState: { isValid, isSubmitting, dirtyFields },
  } = useForm<MobileFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      mobileRequired: '',
      mobileConfirmCode: '',
    },
  });

  useAutoFocusTracking('mobileRequired', 'mobileConfirmCode');

  useEffect(() => {
    /** 카카오 Drawer로 인해 포커스 예외 케이스 추가 */
    const mobileField = document.getElementById('mobileRequired') as HTMLInputElement;
    const mobileConfirmField = document.getElementById('mobileConfirmCode') as HTMLInputElement;
    if (isKakaoDrawerOpen) {
      mobileField.blur();
      mobileConfirmField.blur();
    }
  }, [isKakaoDrawerOpen]);

  const { mutate: postSendMobileConfirmCodeForPasswordChangeMutate, isPending } = usePostSendMobileConfirmCodeForPasswordChange({
    mobile: watch('mobileRequired'),
  });

  const { mutate: postCodeConfirmMutate } = usePostCodeConfirm({ type: 'password-confirm' });

  const params = {
    studio_id: undefined,
    staff_id: undefined,
  };

  const sendMobileConfirmCodeForPasswordChange = () => {
    postSendMobileConfirmCodeForPasswordChangeMutate(params, {
      onSuccess: ({ data }) => {
        if (!isMobilePass) {
          setIsMobilePass(true);
        }

        const validationId = data.validation_code;
        setValidationIdCode(prev => ({ ...prev, id: validationId }));
        setIsKakaoDrawerOpen(true);
        if (currentStep !== 2) {
          nextStep();
        }
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('mobileRequired', { message });
      },
    });
  };

  const onSubmit: SubmitHandler<MobileFormType> = () => {
    /** 휴대폰 번호 확인이 끝나지 않았으면, 인증코드 발송 */
    if (!isMobilePass) {
      sendMobileConfirmCodeForPasswordChange();
      return;
    }

    setValidationIdCode({
      id: validationIdCode.id,
      code: getValues('mobileConfirmCode'),
    });

    postCodeConfirmMutate(params, {
      onSuccess: () => {
        nextStep();
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('mobileConfirmCode', { message });
        return false;
      },
    });
  };

  const subTitleOptions = {
    title: currentStep === 2 ? SUB_TITLE.CHECK_MOBILE : SUB_TITLE.FIND_PASSWORD,
    message: {
      position: 'bottom',
      text: currentStep === 2 ? '인증번호를 입력해 주세요.' : '카카오톡 또는 문자 메시지로 인증번호가 전송 됩니다.',
    },
  };

  const timeoutError = () => {
    setError('mobileConfirmCode', { type: 'timeout', message: CHANGE_PHONE_NUMBER_TEXT.expired });
  };

  return (
    <>
      <TitleStep currentStep={currentStep} subTitleOptions={subTitleOptions} />

      <StyledForm currentStep={currentStep} onSubmit={handleSubmit(onSubmit)}>
        <OutlinedTextFieldController
          controllerProps={{ control, name: 'mobileRequired' }}
          inputMode="numeric"
          placeholder={PLACEHOLDER.MOBILE}
          onClear={() => setValue('mobileRequired', '', { shouldValidate: true })}
        />

        {currentStep === 2 && (
          <MobileConfirmWrapper>
            <OutlinedTextFieldController
              controllerProps={{ control, name: 'mobileConfirmCode' }}
              inputMode="numeric"
              suffixMarginRight={8}
              suffix={
                <Button
                  color="gray"
                  fontSize={13}
                  fontWeight={500}
                  textColor="gray2"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    sendMobileConfirmCodeForPasswordChange();
                  }}
                  padding={{ left: 8, right: 8 }}
                  disabled={isPending}>
                  인증번호 재발송
                </Button>
              }
            />
            {!isPending && <Timer count={60 * 5} onTimeOut={timeoutError} />}
          </MobileConfirmWrapper>
        )}

        <SubmitButton
          buttonProps={{
            text: currentStep === 2 ? '다음' : '비밀번호 찾기',
            disabled:
              !isValid ||
              isSubmitting ||
              isPending ||
              (isMobilePass && (watch('mobileConfirmCode').length !== 6 || !dirtyFields.mobileConfirmCode)),
          }}
        />
      </StyledForm>

      <SendMobileKakaoDrawer
        isOpen={isKakaoDrawerOpen}
        onClose={() => {
          setIsKakaoDrawerOpen(false);
          setFocus('mobileConfirmCode');
        }}
      />
    </>
  );
};

export default FindPwdCheckMobile;

export const StyledForm = styled(Form)<{ currentStep?: number }>`
  .outlined-text-field {
    &:first-of-type {
      margin-bottom: 8px;
    }
  }

  .change-pwd-button {
    margin-top: 24px;
  }

  ${({ currentStep }) =>
    currentStep === 2 &&
    css`
      .error {
        white-space: pre-wrap;
      }
    `}
`;

const MobileConfirmWrapper = styled.div`
  .outline-errormessage-wrapper {
    white-space: pre-wrap;
  }
`;

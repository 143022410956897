import { color } from 'assets/styles';

export type Color = keyof typeof color;

/**
 *  용도: 배경색에 따라서 그 안에 있는 텍스트 색상을 디자인 시스템에 따라 변경하기 위한 유틸
 *  사용법: getContrastTextColor(bgColor: theme.color의 key 또는 value값)
 *  @return { Color }
 */
const getContrastTextColor = (bgColor: Color | string = 'white') => {
  const isHexCode = bgColor.includes('#');
  /** currentColor: orange0 같은 변수가 아닌 hex 값으로 넘길 때 대응 */
  const currentColor = isHexCode ? Object.keys(color).find(key => color[key as Color] === bgColor) : bgColor;
  const contrastText = `${currentColor}Contrast`;
  const isExceptionCase = Object.prototype.hasOwnProperty.call(color, contrastText as Color);

  if (isExceptionCase) return contrastText;
  return 'white';
};

export default getContrastTextColor;

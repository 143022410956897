export const STAFF_HEADER_TITLE = {
  STAFF_LIST: '강사',
} as const;

export const NAME_SORT_FILTERS = [
  { id: 'asc', label: '이름 오름차순', value: 'asc' },
  { id: 'desc', label: '이름 내림차순', value: 'desc' },
];

export const DATE_SORT_FILTERS = [
  { id: 'desc', label: '최신순', value: 'desc' },
  { id: 'asc', label: '과거순', value: 'asc' },
];

export const CURRENT_SORT = {
  asc: { type: 'asc', label: '이름 오름차순' },
  desc: { type: 'desc', label: '이름 내림차순' },
} as const;

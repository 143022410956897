import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { validationIdCodeAtom } from 'recoil/validationIdCode';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 비밀번호 찾기 > 비밀번호 재설정, 카카오인증 필요 */
const usePostPasswordChange = () => {
  const validationIdCode = useRecoilValue(validationIdCodeAtom);
  const { id, code } = validationIdCode;

  const { mutationFn } = useMutationFnWithCommonParams<Params, Response>({
    method: 'post',
    url: `/api/auth/password-change/${id}/${code}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostPasswordChange;

type Params = {
  id: string;
  code: string;
  password: string;
  password_confirmation: string;
};

type Response = {
  message: string;
};

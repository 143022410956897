import usePatchCounsel, { PatchCounselingParamsType } from 'hooks/service/mutations/usePatchCounseling';
import { CounselingDetail } from 'hooks/service/queries/useGetCounseling';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

import { CounselingFormType } from '../type';
import CounselCreateUpdateForm from './CounselCreateUpdateForm';

type Props = {
  typeText: string;
  currentCounseling: CounselingDetail;
};
const CounselUpdateForm = ({ typeText, currentCounseling }: Props) => {
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: patchCounselMutate } = usePatchCounsel(currentCounseling.id);
  const handleSubmit = async (values: CounselingFormType) => {
    const { id, date, time, is_registered, target_staff_id, ...rest } = values;

    const combinedDateStartTime = `${filters.dateDash(date)} ${filters.timeSecond(time[0])}`;
    const combinedDateEndTime = `${filters.dateDash(date)} ${filters.timeSecond(time[1])}`;

    const params: PatchCounselingParamsType = {
      ...rest,
      target_staff_id: Number(target_staff_id),
      start_on: combinedDateStartTime,
      end_on: combinedDateEndTime,
      is_registered: is_registered === 'registered',
    };

    patchCounselMutate(params, {
      onSuccess: res => {
        setToast({ type: 'success', message: `상담이 ${typeText}되었습니다.` });
        navigate(-1);
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };
  return (
    <ApiBoundary>
      <CounselCreateUpdateForm typeText={typeText} onSubmit={handleSubmit} />
    </ApiBoundary>
  );
};

export default CounselUpdateForm;

import Divider from 'components/Divider';
import MainLayout from 'sharedComponents/MainLayout';
import localStorage from 'utils/localStorage';

import CurrentVersion from './components/CurrentVersion';
import SettingBottom from './components/SettingBottom';
import SettingNavigation from './components/SettingNavigation';
import SettingPopupTerms from './components/SettingPopupTerms';

const MoreDetailsSettings = () => {
  const hasAccessToken = !!localStorage.get('access_token');

  return (
    <MainLayout header={{ title: '설정' }}>
      <SettingNavigation />
      <SettingPopupTerms />
      <CurrentVersion />
      {hasAccessToken && (
        <>
          <Divider />
          <SettingBottom />
        </>
      )}
    </MainLayout>
  );
};

export default MoreDetailsSettings;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import NumberPicker from 'components/NumberPicker';
import Radio from 'components/Radio';
import Typography from 'components/Typography';
import { isNaN } from 'lodash';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';

const LastAttendanceDayFilter = ({ currentFilter, selectedOption, onClick: changeOption }: FilterProps) => {
  const selectedAmount = isNaN(Number(selectedOption)) ? undefined : Number(selectedOption);

  const changeLastAttendanceDay = (day?: number) => {
    changeOption({ value: day, label: '' }, 'last_attendance_day');
  };

  return (
    <>
      <Container>
        <FilterTitle title={currentFilter.title} />

        <Radio
          id="attendance-noCount"
          name={'attendance-noCount'}
          label={currentFilter.options[0].label}
          fontSize={14}
          onChange={() => changeLastAttendanceDay(undefined)}
          checked={!selectedAmount}
        />
        <Radio
          id="attendance-count"
          name="attendance-count"
          label={
            <LabelWrapper isOpen={!!selectedAmount}>
              <Typography className="count" size={14} weight={500}>
                {selectedAmount || 1}
              </Typography>
              <Typography size={14}>일 이상 방문하지 않은 회원보기</Typography>
            </LabelWrapper>
          }
          onChange={() => changeLastAttendanceDay(selectedAmount ?? 1)}
          checked={!!selectedAmount}
        />
      </Container>

      {!!selectedAmount && (
        <>
          <Divider thin thinColor="gray6" />
          <NumberPicker value={selectedAmount} onChange={(e: { value: number }) => changeLastAttendanceDay(e.value)} />
        </>
      )}
    </>
  );
};

export default LastAttendanceDayFilter;

const Container = styled(FilterWrap)`
  label:first-of-type {
    margin-bottom: 12px;
  }
`;

const LabelWrapper = styled.div<{ isOpen: boolean }>(
  css`
    ${theme.flex('', 'center', '', 6)};

    .count {
      ${theme.flex('', 'center', 'flex-start')}
      width: 43px;
      height: 32px;
      padding: 0 8px;
      border-radius: 6px;
      box-shadow: 0 0 0 1px ${theme.color.gray5};
    }
  `,

  ({ isOpen }) =>
    isOpen &&
    css`
      .count {
        box-shadow: 0 0 0 1px ${theme.color.primary};
      }
      .typography {
        color: ${theme.color.gray1};
      }
    `,
);

import useGetStaffNoticeCommonNoReadCount from 'hooks/service/queries/useGetStaffNoticeCommonNoReadCount';

import NoReadDot from '../NoReadDot';

const PushDot = () => {
  const { data: push } = useGetStaffNoticeCommonNoReadCount();
  const hasNewPush = push.is_checked_common_false_count > 0;

  return hasNewPush ? <NoReadDot left={46} /> : null;
};

export default PushDot;

import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import usePopup from 'hooks/usePopup';
import { ReactNode } from 'react';
import MainLayout from 'sharedComponents/MainLayout';

type Props = {
  title: string;
  children: ReactNode;
};

const PopupLayout = ({ title, children }: Props) => {
  const { setPopup } = usePopup();
  return (
    <MainLayout
      header={{
        title,
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <Container>{children}</Container>
    </MainLayout>
  );
};

export default PopupLayout;

const Container = styled.main`
  padding: 0 20px;
  #paper {
    padding-bottom: 72px;
  }
`;

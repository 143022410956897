import useInfinityUserTicketUpgrade from 'hooks/service/queries/useInfinityUserTicketUpgrade';
import NoDataText from 'pages/ProductForm/components/SelectTicket/NoDataText';
import TicketListItem from 'pages/ProductForm/components/SelectTicket/TicketListItem';
import { TicketUpgradeFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';

const UpgradeTicketList = ({ userTicket }: UserTicketProps) => {
  const { control } = useFormContext<TicketUpgradeFormType>();
  const search = useWatch({ control, name: 'search' });

  const { id: userTicketId, user_id } = userTicket;
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfinityUserTicketUpgrade({
    userTicketId,
    user_id,
    search,
  });

  if (!data.length) {
    return <NoDataText search={search} productType="userTicket" />;
  }

  return (
    <StyledSelectInfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      <ul>
        {data.map(ticket => (
          <TicketListItem key={ticket.id} ticket={ticket} tabValue="upgrade" />
        ))}
      </ul>
    </StyledSelectInfiniteScroll>
  );
};

export default UpgradeTicketList;

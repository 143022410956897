import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon, { IconProps } from 'components/Icon';
import Typography, { TypographyProps } from 'components/Typography';
import { PropsWithChildren } from 'react';

type Props = {
  className?: string;
  /** 왼쪽 아이콘 이름 */
  iconName?: IconProps['name'];
  /** 왼쪽 아이콘 컬러 (default: gray2) */
  iconColor?: IconProps['color'];
  /** children이 string 일 때, 텍스트 컬러 */
  textColor?: TypographyProps['textColor'];
  /** children이 string 일 때, 텍스트 굵기 */
  fontWeight?: TypographyProps['weight'];
  /** children이 string 일 때, 텍스트 크기 */
  fontSize?: TypographyProps['size'];
  /** 선택된 값이 있는지 유무 */
  hasValue?: boolean;
  /** border-bottom 유무 */
  hasBorder?: boolean;
  /** 필드 높이 (default: 56px) */
  height?: number;
};

const FormField = ({
  className,
  children,
  iconName,
  iconColor,
  textColor,
  fontWeight = 500,
  fontSize = 15,
  hasValue,
  hasBorder = true,
  height,
}: PropsWithChildren<Props>) => {
  return (
    <Container className={`form-field ${className ?? ''}`} hasBorder={hasBorder} height={height}>
      {iconName && <Icon name={iconName} color={iconColor || 'gray2'} />}
      {typeof children === 'string' ? (
        <Typography size={fontSize} weight={fontWeight} textColor={textColor ? textColor : hasValue ? 'gray1' : 'gray3'}>
          {children}
        </Typography>
      ) : (
        children
      )}
    </Container>
  );
};

export default FormField;

const Container = styled.div<Pick<Props, 'hasBorder' | 'height'>>(
  ({ height }) => css`
    height: ${height || 56}px;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid ${theme.color.gray8};
    ${theme.flex('row', 'flex-start', 'flex-start', 10)};

    svg {
      display: block;
      flex: 0 0 auto;
    }

    svg ~ * {
      flex: 1;
    }
  `,
  ({ hasBorder }) =>
    !hasBorder &&
    css`
      border-bottom: none;
    `,
);

import styled from '@emotion/styled';
import downArrowImg from 'assets/images/tutorial5_arrow_down.svg';
import upArrowImg from 'assets/images/tutorial5_arrow_up.svg';
import handImg from 'assets/images/tutorial5_hand.webp';
import floatingButtonImg from 'assets/images/tutorial5_plus.webp';
import Typography from 'components/Typography';

const TutorialFifth = () => {
  return (
    <Container>
      <HandImgWrapper>
        <img src={handImg} alt="손가락이미지" />
      </HandImgWrapper>
      <UpArrowWrapper>
        <img src={upArrowImg} alt="윗방향화살표" />
      </UpArrowWrapper>
      <TextWrapper>
        <Typography span textColor="white" size={19} weight={600}>
          달력의&nbsp;
        </Typography>
        <span className="highlight-color">빈공간</span>
        <Typography span textColor="white" size={19} weight={600}>
          이나
        </Typography>
        <p className="highlight-color">
          플러스 버튼
          <Typography span textColor="white" size={19} weight={600}>
            을 눌러 일정을
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            추가할 수 있습니다.
          </Typography>
        </p>
      </TextWrapper>
      <DownArrowWrapper>
        <img src={downArrowImg} alt="아랫방향화살표" />
      </DownArrowWrapper>
      <FloatingButtonWrapper>
        <img src={floatingButtonImg} alt="플로팅버튼" />
      </FloatingButtonWrapper>
    </Container>
  );
};

export default TutorialFifth;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const HandImgWrapper = styled.div`
  position: absolute;
  width: 186px;
  height: 82px;
  bottom: 29rem;
  right: 8rem;
  img {
    width: 100%;
  }
`;

const UpArrowWrapper = styled.div`
  position: absolute;
  bottom: 25rem;
  right: 20rem;
  img {
    height: auto;
  }
`;

const DownArrowWrapper = styled.div`
  position: absolute;
  bottom: 13.5rem;
  right: 4rem;
  img {
    height: auto;
  }
`;

const FloatingButtonWrapper = styled.div`
  position: absolute;
  width: 64px;
  height: 6px;
  bottom: 12rem;
  right: 1rem;
  img {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  width: 20rem;
  position: absolute;
  bottom: 18rem;
  right: 4rem;
  text-align: center;

  .highlight-color {
    font-size: 19px;
    font-weight: 600;
    color: #2cead7;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import usePhoto from 'hooks/usePhoto';
import { useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import InputFile from 'sharedComponents/InputFile';
import { CommonProfileFormType, ProfileFormProps } from 'sharedComponents/ProfileField/types';

import SubmitButton from './SubmitButton';

const ProfileForm = ({ label, placeholder = '회원명 입력' }: ProfileFormProps) => {
  const { control, getValues, setValue, clearErrors } = useFormContext<CommonProfileFormType>();
  const beforePhoto = useMemo(() => getValues('image'), [getValues]);

  const fileRef = useRef<HTMLInputElement>(null);
  const { imgUrls, files, onAddPhoto, onChange } = usePhoto(fileRef, { isProfile: true });

  const navigate = useNavigate();

  const handleClick = () => {
    if (files[0]) {
      setValue('file', files[0].file, { shouldDirty: true });
      setValue('image', imgUrls[0]?.imgUrl, { shouldDirty: true });
    }
    setValue('beforeName', getValues('profile.name'), { shouldDirty: true });
    navigate(-1);
  };

  return (
    <Container>
      <AvatarWrapper>
        <div className="avatar-contents">
          <Avatar size={96} imgUrl={imgUrls[0]?.imgUrl || beforePhoto} />
          <IconButton
            variant="outlined"
            outlineColor="gray5"
            widthSize={32}
            borderRadius="50%"
            bgColor="white"
            onClick={onAddPhoto}>
            <Icon name="camera" size={16} />
          </IconButton>
          <InputFile ref={fileRef} onChange={onChange} />
        </div>
      </AvatarWrapper>

      <OutlinedTextFieldController
        controllerProps={{ control, name: 'profile.name' }}
        required
        label={label}
        placeholder={placeholder}
        onClear={() => {
          setValue('profile.name', '', { shouldDirty: true });
          clearErrors('profile.name');
        }}
      />

      <SubmitButton onClick={handleClick} />
    </Container>
  );
};

export default ProfileForm;

const Container = styled.div`
  padding: 16px 20px 0;
  height: 100%;
  ${theme.flex('column', 'initial', 'initial', 24)};

  > .text-button {
    margin-top: auto;
    margin-bottom: 10px;
  }
`;

const AvatarWrapper = styled.div`
  .avatar-contents {
    position: relative;
    margin: 0 auto;
    width: 96px;
    height: 96px;
    border-radius: 50%;

    > div:first-of-type {
      border-radius: 50%;
    }

    .icon-button {
      position: absolute;
      bottom: 0;
      right: -4px;

      &::after {
        content: '';
        position: absolute;
        inset: -4px;
      }
    }
  }
`;

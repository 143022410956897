import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { Payment } from '../queries/useInfinityUserTicketProduct';

const usePatchPayment = (paymentId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<PatchPaymentParams, Payment>({
    method: `patch`,
    url: `/api/payment/${paymentId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePatchPayment;

export type PatchPaymentParams = {
  status: string;
  method: string;
  amount: number;
  cash_amount: number;
  unpaid_amount: number;
  wiretransfer_amount: number;
  card_amount: number;
  installment_period: number;
  point_amount: number;
  reward_point: number;
  settlement_at: string;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import MessageLabel from 'pages/MoreDetails/Message/components/MessageLabel';
import { ReactNode } from 'react';
import filters from 'utils/filters';

import { MOBILE_DETAIL_TEXT } from '../constants';
import { Row } from './MobileDetail';
import TargetsDetailButton from './TargetsDetailButton';

type Props = {
  /** 예약상태, "request" | "cancel" 일 때만 해당 라벨 노출 */
  status: string;
  sendOn: string;
  /** 대상회원 표기 */
  targetText: string;
  /** 대상회원 상세보기 클릭시 노출되는 drawer Contents */
  targetContents: ReactNode;
  count: number;
};
const MobileDetailCommonInfo = ({ status, sendOn, targetText, targetContents, count }: Props) => {
  return (
    <>
      <Row>
        <Typography size={15} textColor="gray2">
          {MOBILE_DETAIL_TEXT.sendOn}
        </Typography>
        <SendOnWrapper>
          <MessageLabel status={status} />
          <Typography size={15} weight={500}>
            {filters.dateTimeWithWeekday(sendOn)}
          </Typography>
        </SendOnWrapper>
      </Row>
      <Divider thin thinColor="gray8" />
      <Row>
        <Typography size={15} textColor="gray2">
          {MOBILE_DETAIL_TEXT.target}
        </Typography>
        <TargetWrapper>
          <Typography size={15} weight={500} className="target">
            {targetText}
          </Typography>
          <TargetsDetailButton>{targetContents}</TargetsDetailButton>
        </TargetWrapper>
      </Row>
      <Divider thin thinColor="gray8" />
      <Row>
        <Typography size={15} textColor="gray2">
          {MOBILE_DETAIL_TEXT.count}
        </Typography>
        <Typography size={15} weight={500}>
          {filters.numberComma(count)}건
        </Typography>
      </Row>
      <Divider thin thinColor="gray8" />
    </>
  );
};

export default MobileDetailCommonInfo;

const SendOnWrapper = styled.div`
  ${theme.flex('', 'center', 'end', 10)}
`;

const TargetWrapper = styled.div`
  ${theme.flex('column', 'end', '', 6)}
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { KIND } from 'pages/TicketDetail/constants';
import { TicketHistoryFormatContentType } from 'pages/TicketDetail/types';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { CommonStyleCard, CommonStyledCardTopWrapper } from 'sharedComponents/StyledComponents';

type Props = {
  data: {
    id: number;
    date: string;
    staffName: string;
    kind: string | null;
    contents: TicketHistoryFormatContentType[];
  };
};

const HistoryCard = ({ data }: Props) => {
  const { id, date, staffName, kind, contents } = data;

  return (
    <Container>
      <CardTopWrapper>
        <InfoFlexRow>
          <Typography size={15} weight={600}>
            {date}
          </Typography>
          <Typography textColor="gray2">{staffName} 강사</Typography>
        </InfoFlexRow>
        {kind && (
          <Typography className="kind" weight={500} textColor="gray2">
            {KIND[kind]}
          </Typography>
        )}
      </CardTopWrapper>

      <InfoFlexWrapper gap={8}>
        {contents.map(({ label, oldValue, newValue }, index) => {
          const showOldValue = kind !== 'created' && kind !== 'transfer' && !!oldValue;

          return (
            <FlexRow key={`${id}-${label}-${index}`} gap={8}>
              <InfoTypography size={14}>{label}</InfoTypography>
              <span>
                {showOldValue && (
                  <>
                    <InfoTypography className="old-value-text" textColor="gray3" size={14} weight={500}>
                      {oldValue}
                    </InfoTypography>
                    <Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray4} />
                  </>
                )}
                {!!newValue && (
                  <InfoTypography className="new-value-text" size={14} weight={500}>
                    {newValue}
                  </InfoTypography>
                )}
              </span>
            </FlexRow>
          );
        })}
      </InfoFlexWrapper>
    </Container>
  );
};

export default HistoryCard;

const Container = styled(CommonStyleCard)`
  .kind {
    margin-top: 2px;
  }
`;

const CardTopWrapper = styled(CommonStyledCardTopWrapper)`
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const FlexRow = styled(InfoFlexRow)`
  > span:last-of-type {
    margin-left: auto;
    ${theme.flex('', 'center')};
    word-break: keep-all;

    .old-value-text {
      flex: 1;
      text-align: right;
    }

    svg {
      rotate: -90deg;
    }
  }
`;

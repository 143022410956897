import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Search from 'pages/Booking/SelectMember/Search';
import { AllPageModeType } from 'pages/Booking/types';

import SelectUserTicketForm from './SelectUserTicketForm';

type Props = {
  pageMode: AllPageModeType;
};

const SelectMembers = ({ pageMode }: Props) => {
  return (
    <Container>
      <Search />
      <SelectUserTicketForm pageMode={pageMode} />
    </Container>
  );
};

export default SelectMembers;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  height: 100%;
`;

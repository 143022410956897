import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import PickerButtonOutlined from 'designedComponents/Pickers/PickerButton/PickerButtonOutlined';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecoilState, useRecoilState } from 'recoil';
import { reportSalePeriodAtom } from 'recoil/report/atom';
import ButtonFilterBase from 'sharedComponents/Filters/ButtonFilterBase';
import { FilterOptionsType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { PERIOD_OPTIONS, POINT_PERIOD_OPTIONS } from '../constants';
import { ReportPeriodType } from '../type';
import { getCalendarButtonLabel, getLabel } from '../util';
import CustomCalendarButton from './CustomCalendarButton';
import ReportCalendar from './ReportCalendar';

type Props = {
  filterAtom: RecoilState<ReportPeriodType>;
  isOpen: boolean;
  currentTab: string;
  onClose: () => void;
};

const ReportPeriodFilter = ({ filterAtom, isOpen, currentTab, onClose }: Props) => {
  const navigate = useNavigate();
  const [periodState, setPeriodState] = useRecoilState(filterAtom ?? reportSalePeriodAtom);
  const [isOpenCalender, setIsOpenCalendar] = useState(false);
  const [tempPeriod, setTempPeriod] = useState(periodState);

  const changeOption = (option: FilterOptionsType) => {
    const type = option.value;
    const unit = type === 'month' || type === 'isoWeek' ? type : 'day';
    const startDate = dayjs().startOf(unit);
    const endDate = dayjs().endOf(unit);

    setTempPeriod({
      period: {
        type: type as string,
        start: filters.dateDash(startDate),
        end: filters.dateDash(endDate),
      },
    });
  };

  const closeCalendar = () => {
    setIsOpenCalendar(false);
  };

  const savePeriod = () => {
    onClose();
    setPeriodState(tempPeriod);
  };

  const label = getLabel({ date: [tempPeriod.period?.start, tempPeriod.period?.end], type: tempPeriod.period?.type });

  const calendarButtonLabel = useMemo(() => {
    return getCalendarButtonLabel({ date: [tempPeriod.period?.start, tempPeriod.period?.end], type: tempPeriod.period?.type });
  }, [tempPeriod]);

  useEffect(() => {
    setTempPeriod(periodState);
  }, [periodState]);

  return (
    <StyledSingleButtonDrawer isOpen={isOpen} onClose={onClose} direction="top" button={{ text: '조회', onClick: savePeriod }}>
      <Container>
        <TitleSection>
          <div className="icon-button-wrapper">
            <IconButton onClick={onClose}>
              <Icon name="headerClose" />
            </IconButton>
          </div>
          <Typography size={17} weight={700}>
            매출
          </Typography>
        </TitleSection>
        <Divider thin />
        <OptionLabelSection>
          {_.isObject(label) ? (
            <div className="date-week-label">
              <Typography span size={14} weight={500} textColor="gray2">
                {label.dateKor}
              </Typography>
              <Typography span size={14} weight={500} textColor="gray3">
                {label.dateNumber}
              </Typography>
            </div>
          ) : (
            <Typography size={14} weight={500} textColor="gray2">
              {label}
            </Typography>
          )}
          <Icon name="arrowTopFill" size={16} color="gray3" />
        </OptionLabelSection>
        <Divider thin />
        <OptionSection>
          <ButtonFilterBase
            currentFilter={currentTab === 'point' ? POINT_PERIOD_OPTIONS[0] : PERIOD_OPTIONS[0]}
            selectedOption={tempPeriod.period || PERIOD_OPTIONS[0].options[0].value}
            onClick={changeOption}
          />
          {tempPeriod.period?.type === 'custom' ? (
            <CustomCalendarButton
              date={[tempPeriod.period?.start, tempPeriod.period?.end]}
              setIsOpenCalendar={setIsOpenCalendar}
              closeCalendar={closeCalendar}
            />
          ) : (
            <PickerButtonOutlined onClick={() => setIsOpenCalendar(true)}>{calendarButtonLabel}</PickerButtonOutlined>
          )}
          <ReportCalendar
            tempPeriod={tempPeriod}
            isOpenCalender={isOpenCalender}
            closeCalendar={closeCalendar}
            setTempPeriod={setTempPeriod}
          />
        </OptionSection>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ReportPeriodFilter;

const Container = styled.div``;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding: 0 0 76px 0;
`;

const TitleSection = styled.section`
  ${theme.flex('', 'center', 'center')};
  position: relative;
  height: 56px;

  .icon-button-wrapper {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const OptionLabelSection = styled.section`
  ${theme.flex('', 'center', 'center')};
  padding: 15px 0px;

  .date-week-label {
    > span:first-of-type {
      padding-right: 2px;
    }
  }
`;

const OptionSection = styled.section`
  padding: 24px 20px;

  .button-group {
    padding-bottom: 12px;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { ChangeEvent, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RecoilState, useRecoilState } from 'recoil';

type ReportSortType = {
  sort?: 'asc' | 'desc';
};

type Props<T extends ReportSortType> = {
  filterAtom: RecoilState<T>;
  sortOptions: Array<{ id: string; label: string; value: 'asc' | 'desc' }>;
  isShowTotal?: boolean;
  total?: number;
  className?: string;
};

const Sorting = <T extends ReportSortType>({ filterAtom, sortOptions, isShowTotal = false, total, className }: Props<T>) => {
  const [reportSort, setReportSort] = useRecoilState(filterAtom);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { control } = useForm<ReportSortType>({
    defaultValues: {
      sort: reportSort?.sort ?? 'asc',
    },
  });

  const sortLabel = useMemo(() => {
    return sortOptions.find(data => data.value === reportSort?.sort)?.label;
  }, [reportSort.sort, sortOptions]);

  const changeSort = (e: ChangeEvent<HTMLInputElement>) => {
    setReportSort(prev => ({
      ...prev,
      sort: e.target.value as 'asc' | 'desc',
    }));
  };

  return (
    <Container className={className}>
      {isShowTotal && (
        <div className="sorting-wrapper">
          <Typography span size={13} textColor="gray2" opacity={0.8}>
            총
          </Typography>
          <Typography span size={13} weight={600} textColor="gray2" opacity={0.8}>
            {total}
          </Typography>
          <Typography span size={13} textColor="gray2" opacity={0.8}>
            건
          </Typography>
        </div>
      )}

      <Button
        fontWeight={400}
        textColor="gray2"
        rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
        onClick={() => setIsFilterOpen(true)}>
        {sortLabel}
      </Button>

      <CheckRadioDrawer
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        control={control}
        name="sort"
        data={sortOptions}
        onClick={changeSort}
      />
    </Container>
  );
};

export default Sorting;

const Container = styled.div`
  ${theme.flex('', '', 'space-between')};
  padding: 12px 20px;
  background-color: ${theme.color.white};

  .sorting-wrapper {
    span:first-of-type {
      padding-right: 4px;
    }
    span:last-of-type {
      padding-right: 8px;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon, { IconProps } from 'components/Icon';
import useGetStaffNoticeCommonNoReadCount from 'hooks/service/queries/useGetStaffNoticeCommonNoReadCount';

const NotificationIcon = ({ name, ...props }: IconProps) => {
  const { data: push } = useGetStaffNoticeCommonNoReadCount();
  const hasNewPush = push.is_checked_common_false_count > 0;
  return <NotificationIconWithRedDot {...props} name="alertFill" hasDot={hasNewPush} />;
};

export default NotificationIcon;

const NotificationIconWithRedDot = styled(Icon)<{ hasDot: boolean }>`
  ${({ hasDot }) => hasDot && `circle { fill: ${theme.color.secondary3} !important;}`}
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { TicketUpgradeFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import CurrentTicketCard from './CurrentTicketCard';
import UpgradeTicketCard from './UpgradeTicketCard';

type Props = UserTicketProps & {
  onClickNextPage: () => void;
};

const UpgradeInfo = ({ userTicket, onClickNextPage }: Props) => {
  const { control, setValue } = useFormContext<TicketUpgradeFormType>();
  const upgradeTicket = useWatch({ control, name: 'selectedTicket' });

  const clickNext = () => {
    if (!upgradeTicket) return;
    setValue('salePrice', upgradeTicket.original_price);
    onClickNextPage();
  };

  return (
    <Container>
      <div className="ticket-wrapper">
        <CurrentTicketCard userTicket={userTicket} />
        <Icon name="upgrade32" size={32} color="gray5" />
        {upgradeTicket && <UpgradeTicketCard userTicket={userTicket} upgradeTicket={upgradeTicket} />}
      </div>

      <FooterButton onClick={clickNext}>다음</FooterButton>
    </Container>
  );
};

export default UpgradeInfo;

const Container = styled.div`
  padding-bottom: 116px;

  .ticket-wrapper {
    padding: 0 20px;
    margin-top: 24px;
    ${theme.flex('column', 'center', '', 4)}
  }
`;

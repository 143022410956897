import useGetStudiomateNoticePopup from 'hooks/service/queries/useGetStudiomateNoticePopup';
import TutorialPortal from 'pages/Tutorial/components/TutorialPortal';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { isClosedNoticePopupAtom, noticeCheckedListAtom } from 'recoil/popup';

import NoticePopupContents from './NoticePopupContents';

const NoticePopup = () => {
  const { data: notices } = useGetStudiomateNoticePopup();
  const [checkedList, setCheckedList] = useRecoilState(noticeCheckedListAtom);
  const [isClosed, setClosed] = useRecoilState(isClosedNoticePopupAtom);
  const navigate = useNavigate();

  if (!notices.length) return null;
  // 최신공지 1개만 노출
  const notice = notices[0];
  // 다시보지않기 체크한 공지는 노출하지 않음
  const isCheckedNeverAgain = checkedList.includes(notice.id);

  const closePopup = () => {
    setClosed(true);
  };

  const neverShowAgain = () => {
    setClosed(true);
    setCheckedList(prev => [...prev, notice.id]);
  };

  const goToMoreDetails = () => {
    navigate(`/more-details/settings/notice/${notice.id}`);
  };

  if (isClosed || isCheckedNeverAgain) return null;
  return (
    <TutorialPortal>
      <NoticePopupContents
        notice={notice}
        closePopup={closePopup}
        positiveAction={{
          text: '자세히 보기',
          onClick: goToMoreDetails,
        }}
        negativeAction={{
          text: '다시보지 않기',
          onClick: neverShowAgain,
        }}
      />
    </TutorialPortal>
  );
};

export default NoticePopup;

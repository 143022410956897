import { RefObject, useEffect, useState } from 'react';

type Props = {
  stickyRef: RefObject<HTMLDivElement>;
};

const useScrollContentAtTop = ({ stickyRef }: Props) => {
  const [isAtTop, setIsAtTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const topPosition = stickyRef.current.getBoundingClientRect().top;
        setIsAtTop(topPosition <= 0);
      }
    };

    const scrollableContainer = document.querySelector('#scrollableTarget');
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollableContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [stickyRef]);

  return { isAtTop };
};

export default useScrollContentAtTop;

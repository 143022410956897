import { DEFAULT_MEMBER_FILTER_OPTIONS, MEMBER_FILTER_DEFAULT_TAB_LABEL } from 'pages/Member/constants';
import { allMemberSelectedFiltersAtom, assignedMemberSelectedFiltersAtom } from 'recoil/Member';
import Filter from 'sharedComponents/Filters';

import AllMemberDrawerFilterOptions from './AllMemberFilter/AllMemberDrawerFilterOptions';
import AllMemberWholeFilterOptions from './AllMemberFilter/AllMemberWholeFilterOptions';

type Props = {
  isAssigned?: boolean;
};
const MemberSkeletonFilter = ({ isAssigned }: Props) => {
  const filterAtom = isAssigned ? assignedMemberSelectedFiltersAtom : allMemberSelectedFiltersAtom;
  return (
    <Filter
      filterAtom={filterAtom}
      filters={DEFAULT_MEMBER_FILTER_OPTIONS}
      defaultTabLabels={MEMBER_FILTER_DEFAULT_TAB_LABEL}
      renderDrawerOptions={AllMemberDrawerFilterOptions}
      renderWholeFilterPopupOptions={AllMemberWholeFilterOptions}
    />
  );
};

export default MemberSkeletonFilter;

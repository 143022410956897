import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { ReactNode, useEffect, useState } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { FilterOptionsType, FilterType, ParamsType } from 'sharedComponents/Filters/types';
import WholeFilters from 'sharedComponents/Filters/WholeFilters';

import { moreDetailSchedulePermissionsMap } from '../constant';
import { ScheduleRenderOption } from '../type';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  filters: FilterType[];
  defaultFilter?: T;
  renderWholeFilterPopupOptions: (props: ScheduleRenderOption<T>) => ReactNode;
};

const ScheduleWholeFilterPopup = <T extends ParamsType>({
  filterAtom,
  filters,
  defaultFilter,
  renderWholeFilterPopupOptions,
}: Props<T>) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const { data: staffs } = useGetFilterStaffs();
  const { setToast } = useToast();
  const { everyPermissions } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  // 리스트에 반영되는 필터, 필터 적용버튼 누르면 변경됨
  const [selectedFilter, setSelectedFilter] = useRecoilState(filterAtom);

  // 필터 UI용 상태
  const [filterChanged, setFilterChanged] = useState(selectedFilter);

  // 현재 스태프 정보 찾기
  const currentStaff = staffs?.find(staff => staff.id === currentStaffId);

  const changeOption = (option: FilterOptionsType, key: string) => {
    setFilterChanged({ ...filterChanged, [key]: option.value });
  };

  const { setPopup } = usePopup();

  const closePopup = () => {
    setPopup(null);
  };

  // 권한 체크를 위한 함수
  const getPermissionsForFilter = (filterValues: T) => {
    if (!('type' in filterValues)) {
      return [moreDetailSchedulePermissionsMap['group'], moreDetailSchedulePermissionsMap['private']];
    } else if (filterValues.type === 'G') {
      return [moreDetailSchedulePermissionsMap['group']];
    } else {
      return [moreDetailSchedulePermissionsMap['private']];
    }
  };

  const isStaffArray = (staffs: unknown): staffs is Array<{ id: number | string }> => {
    return Array.isArray(staffs) && staffs.every(staff => staff && 'id' in staff);
  };

  const handleSave = async () => {
    const futurePermissions = getPermissionsForFilter(filterChanged);
    const hasPermissionForChanges = everyPermissions(futurePermissions);

    // 선택된 강사가 본인만 있는지 체크
    const isOnlyCurrentStaffSelected =
      isStaffArray(filterChanged.staffs) && filterChanged.staffs.length === 1 && filterChanged.staffs[0].id === currentStaffId;

    // 본인만 선택된 경우 권한 체크 없이 바로 적용
    if (isOnlyCurrentStaffSelected) {
      setSelectedFilter(filterChanged);
      setToast({
        type: 'success',
        message: '필터가 적용되었습니다.',
        bottom: 68,
      });
      setPopup(undefined);
      return;
    }

    // 다른 강사가 포함된 경우의 처리
    const hasOtherStaffs = isStaffArray(filterChanged.staffs) && filterChanged.staffs.some(staff => staff.id !== currentStaffId);

    try {
      // 타입에 따른 권한 체크
      const permissionToCheck = (() => {
        if (filterChanged.type === 'G') {
          // 그룹 타입인 경우
          return [moreDetailSchedulePermissionsMap['group'].id];
        } else if (filterChanged.type === 'P') {
          // 개인 타입인 경우
          return [moreDetailSchedulePermissionsMap['private'].id];
        }
        return [moreDetailSchedulePermissionsMap['group'].id, moreDetailSchedulePermissionsMap['private'].id];
      })();

      // 실시간 권한 체크
      await checkPermission(permissionToCheck, {
        onError: error => {
          throw error;
        },
      });

      // 실시간 권한 체크 성공
      if (hasOtherStaffs) {
        if (hasPermissionForChanges) {
          // Case : 다른 강사 선택 O, 현재 권한 O
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        } else {
          // Case : 다른 강사 선택 O, 현재 권한 X (but 실시간 권한 체크 성공)
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        }
      } else {
        if (hasPermissionForChanges) {
          // Case: 다른 강사 선택 X, 현재 권한 O
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        } else {
          // Case: 다른 강사 선택 X, 현재 권한 X (but 실시간 권한 체크 성공)
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        }
      }

      setSelectedFilter(filterChanged);
    } catch (error) {
      // 실시간 권한 체크 실패
      if (hasOtherStaffs) {
        setToast({
          type: 'error',
          message: '다른 강사 조회 권한이 없습니다.',
          bottom: 68,
        });
      } else {
        setToast({
          type: 'error',
          message: '다른 강사 조회 권한이 없습니다.',
          bottom: 68,
        });
      }
      return;
    }

    setPopup(undefined);
  };

  const resetSelectedFilter = () => {
    const hasPermissionForChanges = everyPermissions([
      moreDetailSchedulePermissionsMap['group'],
      moreDetailSchedulePermissionsMap['private'],
    ]);

    if (!defaultFilter) {
      return;
    }

    // 권한이 없을 경우 현재 강사 정보로 초기화할 staffs 값 설정
    const resetStaffsValue = hasPermissionForChanges
      ? defaultFilter.staffs
      : currentStaff
        ? [
            {
              id: currentStaff.id,
              name: currentStaff.name,
              imgUrl: currentStaff.avatar,
            },
          ]
        : [];

    // 기존 필터를 복사하고 staffs만 업데이트
    const updatedFilter: T = {
      ...(defaultFilter as T),
      staffs: resetStaffsValue,
    };

    setFilterChanged(updatedFilter);
    setSelectedFilter(updatedFilter);

    setToast({
      type: 'success',
      message: '필터가 초기화되었습니다.',
      bottom: 68,
    });
  };

  useEffect(() => {
    setFilterChanged(selectedFilter);
  }, [selectedFilter]);

  return (
    <WholeFilters onReset={resetSelectedFilter} onSave={handleSave} onClose={closePopup}>
      {renderWholeFilterPopupOptions({ filterAtom, filters, filterChanged, changeOption })}
    </WholeFilters>
  );
};

export default ScheduleWholeFilterPopup;

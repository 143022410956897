import Divider from 'components/Divider';
import Icon from 'components/Icon';
import useGetMessagePushDetail from 'hooks/service/queries/useGetMessagePushDetail';
import { Row } from 'pages/MoreDetails/Message/Mobile/Detail/components/MobileDetail';
import MobileDetailCommonInfo from 'pages/MoreDetails/Message/Mobile/Detail/components/MobileDetailCommonInfo';
import MobileDetailContents from 'pages/MoreDetails/Message/Mobile/Detail/components/MobileDetailContents';
import StatusDetail from 'pages/MoreDetails/Message/Mobile/Detail/components/StatusDetail';
import TargetsDetailList from 'pages/MoreDetails/Message/Mobile/Detail/components/TargetsDetailList';
import TypographyWithTooltip from 'pages/MoreDetails/Message/Mobile/Detail/components/TypographyWithTooltip';
import { MOBILE_DETAIL_TEXT } from 'pages/MoreDetails/Message/Mobile/Detail/constants';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import convertTargetsText from 'utils/convertTargetsText';

import { MESSAGE_PUSH_STATUS_ADAPTER } from '../../List/constants';
import { APP_PUSH_DETAIL_TEXT } from '../constants';
import { convertAppPushStatusCount } from '../utils';
import AppPushMenuButton from './AppPushMenuButton';

type Props = {
  id: number;
};

const AppPushDetail = ({ id }: Props) => {
  const { data: push } = useGetMessagePushDetail(id);

  return (
    <MainLayout
      header={{
        title: APP_PUSH_DETAIL_TEXT.title,
        rightButton: (
          <ApiBoundary>
            <AppPushMenuButton push={push} />
          </ApiBoundary>
        ),
      }}>
      <MobileDetailCommonInfo
        status={MESSAGE_PUSH_STATUS_ADAPTER[push.status]}
        sendOn={push.send_on}
        targetText={convertTargetsText(push.targets)}
        targetContents={<TargetsDetailList targets={push.targets} />}
        count={push?.targets?.length || 0}
      />
      <Row>
        <TypographyWithTooltip
          size={15}
          textColor="gray2"
          tooltipMessages={MOBILE_DETAIL_TEXT.status.tooltip}
          tooltipIcon={<Icon name="information" size={16} color="gray3" />}>
          {MOBILE_DETAIL_TEXT.status.text}
        </TypographyWithTooltip>
        <StatusDetail status={convertAppPushStatusCount(push)} type="push" />
      </Row>

      <Divider />
      <MobileDetailContents title={push.title} message={push.message} />
    </MainLayout>
  );
};

export default AppPushDetail;

import SearchBarUI from 'components/SearchBar';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useState } from 'react';

type Props = {
  placeholder?: string;
  className?: string;
  onCancel?: () => void;
  /** 공용 전역변수에 저장될 key, 다른페이지의 검색어와 구분하기 위함 */
  identifier: string;
};

const SearchBar = ({ placeholder, className, onCancel, identifier }: Props) => {
  const { searchKeyword, setSearchKeyword } = useSearchKeywords(identifier);
  const [search, setSearch] = useState(searchKeyword);

  const clearSearch = () => {
    setSearch('');
    setSearchKeyword('');
  };

  return (
    <SearchBarUI
      id="search"
      value={search}
      className={className}
      placeholder={placeholder}
      onChange={e => setSearch(e.currentTarget.value)}
      onClear={clearSearch}
      onCancel={() => {
        clearSearch();
        onCancel?.();
      }}
      onSubmitForm={() => setSearchKeyword(search)}
    />
  );
};

export default SearchBar;

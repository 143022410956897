import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import dayjs from 'dayjs';
import Drawer from 'designedComponents/Drawers';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentDateAtom } from 'recoil/schedule';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateScheduleDrawer = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const currentDate = useRecoilValue(currentDateAtom);

  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const headerText = useMemo(() => {
    const dateFormat = dayjs(currentDate).format('YYYY.MM.DD (ddd)');
    return [dateFormat, '등록할 일정을 선택하세요.'];
  }, [currentDate]);

  const state = { from: 'calendar', selectedDate: currentDate };

  const actions = [
    {
      title: '프라이빗 수업',
      subTitle: '개인 (1:1), 듀엣 (1:2), 트리플 (1:3)',
      onClick: () => {
        const onSuccess = () => {
          navigate('/booking/private/form/create', { state });
        };

        checkPermission(PERMISSION.lecture.mine.create.private.id, {
          onSuccess,
          onError: () => {
            checkPermission(PERMISSION.lecture.others.create.private.id, {
              onSuccess,
            });
            return true;
          },
        });
      },
      permissions: [PERMISSION.lecture.mine.create.private, PERMISSION.lecture.others.create.private],
    },
    {
      title: '그룹 수업',
      subTitle: '4인 이상 수업',
      onClick: () => {
        const onSuccess = () => {
          navigate('/booking/group/form/create', { state });
        };

        checkPermission(PERMISSION.lecture.mine.create.group.id, {
          onSuccess,
          onError: () => {
            checkPermission(PERMISSION.lecture.others.create.group.id, {
              onSuccess,
            });
            return true;
          },
        });
      },
      permissions: [PERMISSION.lecture.mine.create.group, PERMISSION.lecture.others.create.group],
    },
    {
      title: '상담',
      subTitle: '방문, 전화, 채팅, 기타 상담',
      onClick: () => {
        checkPermission(PERMISSION.counsel.create.id, {
          onSuccess: () => {
            navigate('/counsel/form', { state });
          },
        });
      },
      permissions: [PERMISSION.counsel.create],
    },
    {
      title: '기타 일정',
      subTitle: '수업 외 일정',
      onClick: () => {
        const onSuccess = () => {
          navigate('/schedule/etc/form/create', { state });
        };

        checkPermission(PERMISSION.etcSchedule.mine.create.id, {
          onSuccess,
          onError: () => {
            checkPermission(PERMISSION.etcSchedule.mine.create.id, {
              onSuccess,
            });
            return true;
          },
        });
      },
      permissions: [PERMISSION.etcSchedule.mine.create, PERMISSION.etcSchedule.others.create],
    },
  ];

  const allowedActions = actions.filter(action => {
    return action.permissions.some(permission => hasPermission(permission));
  });

  if (!allowedActions.length) {
    return null;
  }

  return (
    <Drawer header={<TwoLinedTitle title={headerText} />} isOpen={isOpen} onClose={onClose}>
      <DrawerContainer>
        {allowedActions.map(({ title, subTitle, onClick }) => (
          <li key={title}>
            <LinkButton onClick={onClick} showArrow={false}>
              <Typography className="title" size={17} weight={600}>
                {title}
              </Typography>
              <Typography textColor="gray2">{subTitle}</Typography>
            </LinkButton>
          </li>
        ))}
      </DrawerContainer>
    </Drawer>
  );
};

const DrawerContainer = styled.ul`
  ${theme.flex('column', 'flex-start', '', 8)};

  li {
    .link-button {
      padding: 0;
      border-bottom: 0;
    }

    .title {
      margin-bottom: 2px;
    }
  }
`;

export default CreateScheduleDrawer;

export const SETTINGS_NAVIGATION = [
  {
    title: '알림',
    items: [{ name: 'PUSH 알림', path: '/more-details/settings/push' }],
  },
  {
    title: '이용정보',
    items: [{ name: '고객센터', path: '/more-details/settings/help' }],
  },
];

export const SETTINGS_TERM = {
  service: '이용약관',
  privacy: '개인정보 처리방침',
};
export const SETTINGS_VERSION = '버전 정보';
export const SETTINGS_LOGOUT = '로그아웃';
export const SETTINGS_DELETE_ACCOUNT = { name: '계정 탈퇴', path: '/more-details/account/withdraw' };

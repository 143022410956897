import styled from '@emotion/styled';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { TimeDatasetType } from 'pages/MoreDetails/Staff/Form/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import filters from 'utils/filters';

import { CommonProps } from '../../../types';

type Props = {
  restTimeField: TimeDatasetType;
  setWorkTimeField: CommonProps['setWorkTimeField'];
  startWorkTime: DateType;
  endWorkTime: DateType;
  isLastIndex: boolean;
  appendRestTimeField: () => void;
  restTimeIndex: number;
};

const RestTimeAccordion = ({
  restTimeField,
  setWorkTimeField,
  startWorkTime,
  endWorkTime,
  isLastIndex,
  appendRestTimeField,
  restTimeIndex,
}: Props) => {
  const initialDates = (): [Date, Date] => {
    const today = dayjs();
    const [year, month, day] = [today.year(), today.month() + 1, today.date()];
    const startTime = dayjs(`${year}-${month}-${day} ${startWorkTime}`);
    return [startTime.toDate(), startTime.add(30, 'minute').toDate()];
  };

  const [timeRange, setTimeRange] = useState<[Date, Date]>(initialDates());

  /** 마운트 후에 RangeTimePicker > onChange의 setTimeRange를 실행하기위한 트리거 */
  const isMounted = useRef(false);

  const { start_time, end_time } = restTimeField || {};

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const timeRangeDataset = useMemo(() => {
    const startHour = start_time?.slice(0, 2);
    const startMinute = start_time?.slice(3, 5);
    const endHour = end_time?.slice(0, 5);
    const endMinute = end_time?.slice(3, 5);

    return {
      start: {
        hour: parseInt(startHour || '09'),
        minute: parseInt(startMinute || '00'),
      },
      end: {
        hour: parseInt(endHour || '18'),
        minute: parseInt(endMinute || '00'),
      },
    };
  }, [start_time, end_time]);

  const timeText = useMemo(() => {
    return filters.timePeriodForOnlyTime(start_time, end_time);
  }, [start_time, end_time]);

  const endMinTime = useMemo(() => {
    const formatDate = dayjs(timeRange[0]).add(5, 'minute');
    return dayjs(formatDate).format('HH:mm:00');
  }, [timeRange]);

  return (
    <Container isLastIndex={isLastIndex}>
      <Accordion
        onChange={() => {
          const { start, end } = timeRangeDataset;
          setTimeRange([
            dayjs().set('hour', start.hour).set('minute', start.minute).toDate(),
            dayjs().set('hour', end.hour).set('minute', end.minute).toDate(),
          ]);
        }}
        header={
          <AcordionHeader>
            <Typography size={15} weight={600} textColor="gray2">
              {timeText}
            </Typography>

            <div className="icon-wrapper">
              <IconButton
                size="small"
                bgColor="gray6"
                widthSize={28}
                onClick={e => {
                  e.stopPropagation();

                  setWorkTimeField(prev => {
                    const restTimes = prev.restTimes?.filter((_, index) => index !== restTimeIndex);
                    const result = {
                      ...prev,
                      restTimes,
                    };
                    return result;
                  });
                }}>
                <Icon name="minusBold" size={16} fillColor={theme.color.gray1} />
              </IconButton>
              {isLastIndex && (
                <IconButton
                  size="small"
                  bgColor="gray6"
                  widthSize={28}
                  onClick={e => {
                    e.stopPropagation();
                    appendRestTimeField();
                  }}>
                  <Icon name="plusBold" size={16} fillColor={theme.color.gray1} />
                </IconButton>
              )}
            </div>
          </AcordionHeader>
        }
        showArrow={false}>
        <Divider thin />
        <RangeTimePicker
          stepMinute={5}
          timeRange={timeRange}
          onChange={value => {
            if (isMounted.current) {
              setTimeRange(value);
            }

            setWorkTimeField(prev => {
              const start_time = dayjs(value[0]).format('HH:mm:00');
              const end_time = dayjs(value[1]).format('HH:mm:00');

              const updatedRestTimes = prev.restTimes?.map((restTime, index) => {
                if (restTimeIndex !== index) return restTime;
                return {
                  ...restTime,
                  start_time,
                  end_time,
                };
              });

              return {
                ...prev,
                restTimes: updatedRestTimes,
              };
            });
          }}
          startMaxTime="23:50"
          endMinTime={endMinTime}
        />
        {isLastIndex && <Divider className="last-line" thin />}
      </Accordion>
    </Container>
  );
};

export default RestTimeAccordion;

const Container = styled.div<{ isLastIndex: boolean }>`
  hr {
    ${({ isLastIndex }) => isLastIndex && 'margin-top: 12px'};
  }

  .last-line {
    margin-top: 0;
  }
`;

const AcordionHeader = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 0 20px;
  width: 100%;

  .icon-wrapper {
    ${theme.flex('row', 'center', 'center', 8)};
  }
`;

import dayjs from 'dayjs';
import { StaffWorkSchedulesResponse } from 'hooks/service/queries/useGetStaffWorkSchedules';
import { ALL_DAYS } from 'sharedComponents/ProfileField/components/WorkTime/constants';

import filters from './filters';

const getWorkTimeDataset = (workSchedules: StaffWorkSchedulesResponse, type?: 'update') => {
  const { work_time, rest_time } = workSchedules;

  const workTimes = work_time.map(item => {
    return {
      ...item,
      isClosed: false,
    };
  });

  const restTimes = [...rest_time];

  /**
   * 시간 정보 수정 시에만 적용되는 로직.
   * schedules의 workTime 데이터 중 요일 데이터가 비는 경우는 휴무일로 간주하고 해당 위치에 휴무일에 대한 기본값 삽입.
   */
  if (type === 'update' && work_time.length !== ALL_DAYS.length) {
    for (const { value } of ALL_DAYS) {
      if (!work_time.some(workTime => workTime.day_of_week === value)) {
        const defaultValue = {
          id: 0,
          date: '',
          type: 'restTime',
          day_of_week: value,
          start_time: '09:00:00',
          end_time: '18:00:00',
          created_at: '',
          updated_at: '',
          isClosed: true,
        };

        workTimes.push({ ...defaultValue, type: 'workTime' });
      }
    }
  }

  workTimes.sort((a, b) => {
    if (a.day_of_week === 0 && b.day_of_week !== 0) return 1;
    if (a.day_of_week !== 0 && b.day_of_week === 0) return -1;
    return a.day_of_week - b.day_of_week;
  });

  const today = filters.dateDash();
  restTimes.sort((a, b) => {
    return dayjs(`${today} ${a.start_time}`).diff(dayjs(`${today} ${b.start_time}`));
  });

  return {
    workTimes,
    restTimes,
  };
};

export default getWorkTimeDataset;

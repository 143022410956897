import Typography from 'components/Typography';
import filters from 'utils/filters';

import { ScheduleFormType } from '../types';

type Props = {
  restTimes: ScheduleFormType[];
};

const EventRestTime = ({ restTimes }: Props) => {
  if (!restTimes.length) return null;

  return (
    <div className="schedule-rest-time">
      <Typography size={15} textColor="gray2">
        휴식시간
      </Typography>
      <div className="times">
        {restTimes.map(restTime => (
          <Typography key={restTime.id} size={15} textColor="gray2">
            {filters.timePeriodForOnlyTime(restTime.start_time, restTime.end_time)}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default EventRestTime;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { ReportCommonParamType } from '../type';

type StaffsOption = { id: number | string; name: string; imgUrl?: string };

type Props = {
  selectedOption: FilterOptionsType['value'];
  isOpen: boolean;
  multiSelectedOptions: FilterOptionsType[];
  onClose: () => void;
  onSave: (option: FilterOptionsType, key: string) => void;
};

const ReportMultiStaffOptionDrawer = ({
  selectedOption,
  isOpen,
  multiSelectedOptions,
  onClose,
  onSave: changeOptions,
}: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const prevSelectedStaffs = (selectedOption as ReportCommonParamType['staffs']) || [];
  const [options, setOptions] = useState<StaffsOption[]>([]);
  const [tempSelectedOption, setTempSelectedOption] = useState<StaffsOption[]>(prevSelectedStaffs);

  const isAllChecked = tempSelectedOption.length === options.length;
  const isOnlySomeChecked = !isAllChecked && tempSelectedOption.length > 0;

  const changeMultiSelectedOptions = () => {
    changeOptions({ value: tempSelectedOption, label: '' }, 'staffs');
    onClose();
  };

  const changeTempSelectedOption = (value: StaffsOption[]) => {
    setTempSelectedOption(value);
  };

  useEffect(() => {
    const staffInfos = multiSelectedOptions.map(multiSelectedOption => multiSelectedOption.value as StaffsOption);
    setOptions(staffInfos);
  }, [multiSelectedOptions]);

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="강사 선택" />}
      button={{ text: '선택 완료', onClick: () => changeMultiSelectedOptions(), disabled: !tempSelectedOption?.length }}>
      <Container isOnlySomeChecked={isOnlySomeChecked}>
        <CheckBox
          id="all-staff"
          className="header-checkbox"
          label="전체"
          gap={16}
          labelFontsize={17}
          labelFontWeight={500}
          value="all"
          checked={isAllChecked}
          iconName={isOnlySomeChecked ? 'lineBold' : 'selectCheckBold'}
          checkColor={isOnlySomeChecked || isAllChecked ? 'white' : 'gray5'}
          onChange={e => {
            if (!e.target.checked) {
              changeTempSelectedOption([]);
            } else {
              changeTempSelectedOption(options);
            }
          }}
        />
        <Divider thin thinColor="gray6" />
        <StyledUl>
          {options.length > 0 &&
            options.map(staff => {
              const checked = tempSelectedOption.find(targetStaff => targetStaff.id === staff.id);

              return (
                <li key={staff.id}>
                  <CheckBox
                    id={`${staff.id}`}
                    label={
                      <div className="staff-label-wrapper">
                        <Avatar imgUrl={staff.imgUrl} />
                        <Typography size={17} weight={500}>
                          {staff.name}
                        </Typography>
                        {currentStaffId === staff.id && (
                          <Typography textColor="gray3" weight={600}>
                            나
                          </Typography>
                        )}
                      </div>
                    }
                    gap={16}
                    checked={!!checked}
                    onChange={e => {
                      const staffValue = {
                        id: staff.id,
                        name: staff.name,
                      };
                      if (e.target.checked) {
                        changeTempSelectedOption([...tempSelectedOption, staffValue]);
                      } else {
                        changeTempSelectedOption([...tempSelectedOption.filter(targetStaff => targetStaff.id !== staff.id)]);
                      }
                    }}
                  />
                </li>
              );
            })}
        </StyledUl>
      </Container>
    </StyledSingleButtonDrawer>
  );
};

export default ReportMultiStaffOptionDrawer;

const Container = styled.div<{ isOnlySomeChecked: boolean }>`
  padding: 0 20px;

  hr {
    margin-top: 24px;
  }

  .header-checkbox {
    .input-span-style {
      border-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'gray5']};
      background-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'white']};
    }
  }
`;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding-top: 12px;
  padding-left: 20px;

  li {
    padding: 8px 0;
  }

  .staff-label-wrapper {
    ${theme.flex('', 'center', '', 4)}

    .typography:first-of-type {
      padding-left: 4px;
    }
  }
`;

import Divider from 'components/Divider';
import { TICKET_REFUND_TEXT } from 'pages/TicketDetail/constants';
import { useFormContext } from 'react-hook-form';
import {
  Card,
  Cash,
  PaymentFormTitle,
  PaymentSubmitButton,
  PointRefund,
  PointWithdraw,
  RefundPenalty,
  RemainingPoint,
  SettlementDate,
  TicketFormContainer,
  WireTransfer,
} from 'sharedComponents/TicketForm';
import { TicketPaymentFormType } from 'sharedComponents/TicketForm/types';

type Props = {
  onSubmit: (values: TicketPaymentFormType) => void;
};

const PaymentForm = ({ onSubmit }: Props) => {
  const { handleSubmit } = useFormContext<TicketPaymentFormType>();

  return (
    <TicketFormContainer onSubmit={handleSubmit(onSubmit)}>
      <PointWithdraw />
      <RemainingPoint />
      <Divider />
      <PaymentFormTitle size={14}>환불금액</PaymentFormTitle>
      <Card />
      <Cash />
      <WireTransfer />
      <PointRefund />
      <RefundPenalty />
      <SettlementDate title={TICKET_REFUND_TEXT.form.payment.settlementDate} />

      <PaymentSubmitButton isRefund text={TICKET_REFUND_TEXT.form.payment.footerButton}>
        환불
      </PaymentSubmitButton>
    </TicketFormContainer>
  );
};

export default PaymentForm;

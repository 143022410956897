import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Switch from 'components/Switch';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';

import { CalendarSettingsFormType } from '../../types';

const EtcScheduleHide = () => {
  const { control, setValue } = useFormContext<CalendarSettingsFormType>();
  const etcScheduleHide = useWatch({ control, name: 'etcScheduleHide' });

  return (
    <Container>
      <Typography className="title" textColor="gray2" size={15} weight={500}>
        기타 일정 숨기기
      </Typography>
      <Switch
        id="etcScheduleHide"
        checked={etcScheduleHide}
        onChange={(e: { target: { checked: boolean } }) => {
          setValue('etcScheduleHide', e.target.checked);
        }}
      />
    </Container>
  );
};

export default EtcScheduleHide;

const Container = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  padding: 18px 16px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 3px 10px 0px rgba(145, 145, 145, 0.12);

  .switch {
    width: auto;
  }
`;

import NodataTypography from 'sharedComponents/NodataTypography';

import { PUSH_TEXT } from '../constants';
import PushFilterButton from './Push/PushFilterButton';
import SkeletonHeader from './SkeletonHeader';

/** 시설선택되어 있지 않을 경우 보일 빈 목록 UI 껍데기 */
const EmptyList = () => {
  return (
    <>
      <SkeletonHeader />
      <PushFilterButton />
      <NodataTypography>{PUSH_TEXT.noData}</NodataTypography>;
    </>
  );
};

export default EmptyList;

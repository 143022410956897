import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  targetStaffId: number;
};

const usePatchStaffProfile = ({ targetStaffId }: Props) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<StaffProfileParams>({
    method: 'patch',
    url: `/api/staff/profile/${targetStaffId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['staff'] });
      queryClient.invalidateQueries({ queryKey: ['staff/me'] });
    },
  });
};

export default usePatchStaffProfile;

export type StaffProfileParams = {
  studio_id?: number;
  role_id?: number;
  profile?: ProfileType;
  mobile?: string;
  staffAvatar?: StaffAvatarType;
  careers?: Careers;
};

type ProfileType = {
  name?: string;
  gender?: 'F' | 'M';
  birthday?: string;
  get_noti_all?: number;
  self_introduction?: string;
  representative_color?: string;
  registered_at?: string;
};

type StaffAvatarType = {
  image: string | null;
};

type Careers = Career[];

type Career = {
  career: string;
};

import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import Main from './components/Main';

const MoreDetailsStaff = () => {
  return (
    <ApiBoundary>
      <Main />
    </ApiBoundary>
  );
};

export default MoreDetailsStaff;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { SaleType } from 'hooks/service/queries/useGetReportSaleDashboard';
import filters from 'utils/filters';

type Props = {
  emoji: any;
  isSellInfo: boolean;
  saleData: SaleType;
};

const SummaryListCard = ({ emoji, isSellInfo, saleData }: Props) => {
  const totalCount = saleData.P.count + saleData.G.count + saleData.S.count + saleData.R.count;
  const totalAmount = saleData.P.amount + saleData.G.amount + saleData.S.amount + saleData.R.amount;

  const formatAmount = (amount: number, isSellInfo: boolean) => {
    if (isSellInfo) return filters.numberComma(amount);
    return amount > 0 ? `-${filters.numberComma(amount)}` : filters.numberComma(amount);
  };

  return (
    <Container>
      <HeaderSection>
        <div className="card-label">
          <Typography>{emoji}</Typography>
          <div>
            <Typography textColor="gray2" weight={500}>{`${isSellInfo ? '총 매출' : '환불'}`}</Typography>
            <Typography textColor="gray2" weight={500}>{`${totalCount}건`}</Typography>
          </div>
        </div>
        <Typography span textColor="gray1" size={21} weight={700}>
          {formatAmount(totalAmount, isSellInfo)}
        </Typography>
        <Typography span textColor="gray1" size={19} weight={700}>
          원
        </Typography>
      </HeaderSection>
      <BodySection>
        <TicketWrapper>
          <div>
            <Typography className="report-title " textColor="gray1" weight={600}>
              수강권
            </Typography>
            <Typography className="text-align-end report-count" textColor="gray1" weight={600}>
              {`${saleData.P.count + saleData.G.count}건`}
            </Typography>
            <Typography className="text-align-end report-amount " textColor="gray1" weight={600}>
              {`${formatAmount(saleData.G.amount + saleData.P.amount, isSellInfo)}원`}
            </Typography>
          </div>
          <div>
            <div className="icon-label report-title ">
              <Icon name={'ellipse'} size={2} color="gray3" />
              <Typography weight={500} textColor="gray2">
                그룹
              </Typography>
            </div>
            <Typography
              className="text-align-end report-count"
              weight={500}
              textColor="gray2">{`${saleData.G.count}건`}</Typography>
            <Typography className="text-align-end report-amount " weight={500} textColor="gray2">
              {`${formatAmount(saleData.G.amount, isSellInfo)}원`}
            </Typography>
          </div>
          <div>
            <div className="icon-label report-title ">
              <Icon name={'ellipse'} size={2} color="gray3" />
              <Typography weight={500} textColor="gray2">
                프라이빗
              </Typography>
            </div>
            <Typography
              className="text-align-end report-count"
              weight={500}
              textColor="gray2">{`${saleData.P.count}건`}</Typography>
            <Typography className="text-align-end report-amount " weight={500} textColor="gray2">
              {`${formatAmount(saleData.P.amount, isSellInfo)}원`}
            </Typography>
          </div>
        </TicketWrapper>
        <Divider thin thinColor="gray5" />
        <ProductWrapper>
          <div>
            <Typography className="report-title " textColor="gray1" weight={600}>
              상품
            </Typography>
            <Typography className="text-align-end report-count" textColor="gray1" weight={600}>
              {`${saleData.S.count + saleData.R.count}건`}
            </Typography>
            <Typography className="text-align-end report-amount " textColor="gray1" weight={600}>
              {`${formatAmount(saleData.S.amount + saleData.R.amount, isSellInfo)}원`}
            </Typography>
          </div>
          <div>
            <div className="icon-label report-title ">
              <Icon name={'ellipse'} size={2} color="gray3" />
              <Typography weight={500} textColor="gray2">
                판매
              </Typography>
            </div>
            <Typography
              className="text-align-end report-count"
              weight={500}
              textColor="gray2">{`${saleData.S.count}건`}</Typography>
            <Typography className="text-align-end report-amount " weight={500} textColor="gray2">
              {`${formatAmount(saleData.S.amount, isSellInfo)}원`}
            </Typography>
          </div>
          <div>
            <div className="icon-label report-title ">
              <Icon name={'ellipse'} size={2} color="gray3" />
              <Typography weight={500} textColor="gray2">
                대여
              </Typography>
            </div>
            <Typography
              className="text-align-end report-count"
              weight={500}
              textColor="gray2">{`${saleData.R.count}건`}</Typography>
            <Typography className="text-align-end report-amount " weight={500} textColor="gray2">
              {`${formatAmount(saleData.R.amount, isSellInfo)}원`}
            </Typography>
          </div>
        </ProductWrapper>
      </BodySection>
    </Container>
  );
};

export default SummaryListCard;

const Container = styled.div`
  padding: 24px 20px;
  background: ${theme.color.white};
  border-radius: 20px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
`;

const HeaderSection = styled.section`
  padding-bottom: 12px;
  .card-label {
    ${theme.flex('row', '', '', 6)}
    padding-bottom: 6px;

    div {
      ${theme.flex('row', '', '', 2)}
    }
  }
`;

const BodySection = styled.section`
  padding: 16px;
  background: ${theme.color.gray8};
  border-radius: 16px;

  .icon-label {
    ${theme.flex('row', 'center', 'start', 4)}
  }

  .text-align-end {
    text-align: right;
  }

  .report-title {
    width: 55px;
  }

  .report-count {
    width: 40px;
  }

  .report-amount {
    width: 120px;
  }

  .padding-top-bottom {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

const TicketWrapper = styled.div`
  padding-bottom: 16px;
  div {
    ${theme.flex('', '', 'space-between', 16)}
    p {
      padding: 3px 0;
    }
  }
  > div:first-of-type {
    padding-bottom: 2px;
  }
`;

const ProductWrapper = styled.div`
  padding-top: 16px;
  div {
    ${theme.flex('', '', 'space-between', 16)}
    p {
      padding: 3px 0;
    }
  }
  > div:first-of-type {
    padding-bottom: 2px;
  }
`;

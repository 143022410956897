import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import useDeleteHoliday from 'hooks/service/mutations/useDeleteHoliday';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

import { HOLIDAY_SETTING_TEXT } from '../constants';

type Props = {
  id: number;
  date: string;
  isOpen: boolean;
  onClose: () => void;
};

const HolidayCancelDialog = ({ id, date, isOpen, onClose }: Props) => {
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: deleteHolidayMutate } = useDeleteHoliday({ holidayId: id });

  return (
    <>
      {isOpen && (
        <Dialog
          onClose={onClose}
          negativeAction={{ text: '취소' }}
          positiveAction={{
            text: '확인',
            onClick: () => {
              deleteHolidayMutate(undefined, {
                onSuccess: () => {
                  setToast({ type: 'success', message: HOLIDAY_SETTING_TEXT.cancel.success });
                },
                onError: (error: CustomError) => {
                  errorDialog.open(error);
                },
              });
              onClose();
            },
          }}>
          <>
            <Typography size={15} weight={600}>
              [{filters.date(date)}]
            </Typography>
            <DialogDescription>{HOLIDAY_SETTING_TEXT.cancel.confirm}</DialogDescription>
          </>
        </Dialog>
      )}
    </>
  );
};

export default HolidayCancelDialog;

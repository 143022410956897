import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import DateCalendarPicker from 'designedComponents/Pickers/DateCalendarPicker';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

const CounselSelectDate = () => {
  const { control, setValue } = useFormContext();
  const date = useWatch({ control, name: 'date' });

  const [isOpen, setIsOpen] = useState(false);

  const timeText = useMemo(() => {
    return (
      <Typography span textColor={isOpen ? 'primary' : 'gray1'} size={15} weight={500} lineHeight={22}>
        {filters.dateWithWeekday(date)}
      </Typography>
    );
  }, [date, isOpen]);

  const handleChange = useCallback((e: { value: Date }) => setValue('date', e.value, { shouldDirty: true }), [setValue]);

  return (
    <Container className="counsel-select-date" isOpen={isOpen}>
      <Accordion
        showArrow={false}
        isOpen={isOpen}
        onChange={() => setIsOpen(!isOpen)}
        size="medium"
        leftIcon={<Icon name="calendar" color="gray2" />}
        header={timeText}>
        <DateCalendarPicker value={date} onChange={handleChange} />
      </Accordion>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  border-radius: 12px;
  background-color: ${theme.color.white};

  .accordion .header-wrapper {
    width: 100%;

    .header-box {
      flex: 1;

      .time-picker {
        ${theme.flex('row', '', 'space-between')}
        width: 100%;
      }
    }
  }

  .accordion .content-wrapper {
    border-top: 1px solid ${theme.color.gray8};
    border-bottom: ${({ isOpen }) => (isOpen ? `1px solid ${theme.color.gray6}` : '0px')};
  }

  .accordion .date-range-picker {
    padding: 24px 0;
  }
`;

export default CounselSelectDate;

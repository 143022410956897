import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import Tabs, { TabDataElementType } from 'components/Tabs';
import useQueryString from 'hooks/useQueryString';

import { NOTIFICATION_TABS } from '../constants';

type Props = {
  isNoCheckIn?: boolean;
};

const NotificationTabs = ({ isNoCheckIn }: Props) => {
  const { getSearchParams, setSearchParams } = useQueryString();
  const currentTab = getSearchParams('type') ?? 'push';

  const queryClient = useQueryClient();
  const changeTab = (tab: TabDataElementType) => {
    setSearchParams({ type: tab.value }, { replace: true });
    queryClient.invalidateQueries({ queryKey: ['staff/notice', 'common', 'no-read-count'] });
    queryClient.invalidateQueries({ queryKey: ['staff/notice', 'check-in', 'no-read-count'] });
  };

  const notificationTabs = isNoCheckIn ? [NOTIFICATION_TABS.push] : [NOTIFICATION_TABS.push, NOTIFICATION_TABS.checkIn];
  return <StyledTabs data={notificationTabs} value={currentTab} onChange={changeTab} isUnderline={false} />;
};

export default NotificationTabs;

const StyledTabs = styled(Tabs)`
  .tabs-wrapper {
    margin: 0;
    gap: 20px;
  }
  button {
    padding: 0;
    align-items: start;
    min-height: 43px;
    width: auto;
    flex: unset;
    span {
      flex-shrink: 0;
      margin-top: 8px;
    }
  }
`;

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BoardNoticeParams } from './usePostBoardNotice';

const usePatchBoardNotice = (id: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<BoardNoticeParams>({
    method: `patch`,
    url: `/api/board/notice/${id}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePatchBoardNotice;

import { useIsMutating } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteEtcSchedule from 'hooks/service/mutations/useDeleteEtcSchedule';
import useDeleteEtcScheduleAll from 'hooks/service/mutations/useDeleteEtcScheduleAll';
import useDeleteEtcScheduleBulk from 'hooks/service/mutations/useDeleteEtcScheduleBulk';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import MoreButtonListDrawer from 'pages/Booking/Detail/components/LectureInfo/MoreButtonListDrawer';
import { ETC_DETAIL_DELETE_DRAWER_LIST, ETC_DETAIL_DELETE_TEXT } from 'pages/EtcSchedule/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

type Props = {
  currentEtcSchedule: EtcScheduleDetailResponse;
  isBulk: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const EtcDeleteListDrawer = ({ currentEtcSchedule, isBulk, isOpen, onClose }: Props) => {
  const { is_single_schedule, id, etc_schedule_id, start_on } = currentEtcSchedule;
  const [selectedOption, setSelectedOption] = useState<'delete' | 'deleteAfterDate' | 'deleteAll' | null>(null);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const isDeleting = useIsMutating({ mutationKey: ['etc/schedule', 'delete'] }) > 0;
  const { mutate: deleteEtcSingle } = useDeleteEtcSchedule(id);
  const { mutate: deleteEtcBulk } = useDeleteEtcScheduleBulk(etc_schedule_id);
  const { mutate: deleteEtcAll } = useDeleteEtcScheduleAll(etc_schedule_id);

  const mutateCallback = () => {
    const message = ETC_DETAIL_DELETE_TEXT.successMessage;

    return {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message, bottom: 76 });
      },
      onSettled: () => {
        setPopup(null);
      },
    };
  };

  const deleteEtcSchedule = () => {
    switch (selectedOption) {
      case 'delete':
        deleteEtcSingle(undefined, mutateCallback());
        break;
      case 'deleteAfterDate':
        deleteEtcBulk({ start: start_on }, mutateCallback());
        break;
      case 'deleteAll':
        deleteEtcAll(undefined, mutateCallback());
    }
    setSelectedOption(null);
  };

  const clickOption = (option: string) => {
    setSelectedOption(option as 'delete' | 'deleteAfterDate' | 'deleteAll');
    onClose();
  };

  useEffect(() => {
    if (isDeleting) {
      setPopup(<ProcessingPopupContents title={ETC_DETAIL_DELETE_TEXT.processingTitle} />);
    }
  }, [isDeleting, setPopup]);

  /** 하루짜리 수업은 바로 삭제 다이얼로그 노출 */
  useEffect(() => {
    if (isOpen && is_single_schedule) {
      setSelectedOption('deleteAll');
    }
  }, [isOpen, is_single_schedule]);

  return (
    <>
      <MoreButtonListDrawer
        buttonList={
          isBulk ? ETC_DETAIL_DELETE_DRAWER_LIST.filter(item => item.value === 'deleteAll') : ETC_DETAIL_DELETE_DRAWER_LIST
        }
        onClick={clickOption}
        isOpen={is_single_schedule ? false : isOpen}
        onClose={onClose}
      />

      {!!selectedOption && (
        <Dialog
          onClose={() => {
            onClose();
            setSelectedOption(null);
          }}
          negativeAction={{
            text: ETC_DETAIL_DELETE_TEXT.dialog.negativeText,
            onClick: () => {
              onClose();
              setSelectedOption(null);
            },
            style: 'outlined',
          }}
          positiveAction={{
            text: ETC_DETAIL_DELETE_TEXT.dialog.positiveText,
            onClick: deleteEtcSchedule,
          }}>
          <DialogDescription tag="pre">{ETC_DETAIL_DELETE_TEXT.dialog.description}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default EtcDeleteListDrawer;

import { RepeatCustomWeekdayType, RepeatType } from '../types';

const syncRepeatSetting = (weekdayArray: Array<RepeatCustomWeekdayType>) => {
  let value: RepeatType = 'custom';
  const isNoRepeat = !weekdayArray.length;
  const isCheckedOnlyMonday = weekdayArray.every(item => item.id === 'mon');
  const isCheckedWeekday = weekdayArray.length === 5 && weekdayArray.every(item => item.id !== 'sat' && item.id !== 'sun');

  if (isNoRepeat) {
    value = null;
  } else if (isCheckedOnlyMonday) {
    value = 'everyMonday';
  } else if (isCheckedWeekday) {
    value = 'weekday';
  }

  return value;
};
export default syncRepeatSetting;

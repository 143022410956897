import styled from '@emotion/styled';
import Tutorial from 'pages/Tutorial';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import { calendarViewTypeAtom } from 'recoil/schedule';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { MainBackgroundColorWrapper } from 'sharedComponents/MainLayout/StyledComponents';
import StudioException from 'sharedComponents/StudioException';
import localStorage from 'utils/localStorage';

import CalendarSettingDrawer from './components/drawer/CalendarSettingDrawer';
import NoticePopup from './components/NoticePopup';
import ScheduleCalendar from './components/ScheduleCalendar';
import ScheduleFloatingButton from './components/ScheduleFloatingButton';
import ScheduleHeader from './components/ScheduleHeader';
import ScheduleHeaderFallback from './components/ScheduleHeaderFallback';
import StudioExceptionCalendarFallback from './components/StudioExceptionCalendarFallback';
import ScheduleStudioExceptionLayout from './components/StudioExceptionLayout';

const ScheduleMain = () => {
  const tutorialShowFromLocalstorage = localStorage.get('tutorialShow');

  const [isShowTutorial, setIsShowTutorial] = useState(!tutorialShowFromLocalstorage);
  const [isSettingDrawerOpen, setIsSettingDrawerOpen] = useState(false);

  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0;
  const calendarViewType = useRecoilValue(calendarViewTypeAtom);

  const closeTutorial = () => {
    setIsShowTutorial(false);
  };

  useEffect(() => {
    if (!tutorialShowFromLocalstorage) {
      localStorage.set('tutorialShow', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openSettingDrawer = () => setIsSettingDrawerOpen(true);
  const closeSettingDrawer = () => setIsSettingDrawerOpen(false);

  if (!isStudioSelected) {
    return (
      <ApiBoundary>
        <StudioException noConnectedBackground={<ScheduleStudioExceptionLayout />} />
      </ApiBoundary>
    );
  }

  return (
    <MainLayout
      bottomNav
      scrollable
      resetScrollTrigger={calendarViewType}
      childrenFloatingButton={
        <ApiBoundary>
          <ScheduleFloatingButton />
        </ApiBoundary>
      }>
      {isShowTutorial ? (
        <Tutorial onClose={closeTutorial} />
      ) : (
        <ApiBoundary>
          <NoticePopup />
        </ApiBoundary>
      )}

      <Container>
        <ApiBoundary fallback={<ScheduleHeaderFallback />}>
          <ScheduleHeader openSettingDrawer={openSettingDrawer} />
        </ApiBoundary>
        <ApiBoundary fallback={<StudioExceptionCalendarFallback />}>
          <ScheduleCalendar />
        </ApiBoundary>
      </Container>

      <CalendarSettingDrawer isOpen={isSettingDrawerOpen} onClose={closeSettingDrawer} />
    </MainLayout>
  );
};

export default ScheduleMain;

const Container = styled(MainBackgroundColorWrapper)`
  height: 100%;
`;

import styled from '@emotion/styled';
import defaultProfile from 'assets/images/default_profile_extension.webp';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import usePopup from 'hooks/usePopup';
import { useEffect, useState } from 'react';
import MainLayout from 'sharedComponents/MainLayout';
import { AvatarImageTypes } from 'types/avatarTypes';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  image: AvatarImageTypes | null;
};

/** 프로필 사진 클릭 시 확대보기 */
const ProfileImgExtension = ({ image }: Props) => {
  const { setPopup } = usePopup();
  const [isLoading, setIsLoading] = useState(!!image);
  const fullSizeImage = getImageUrl(image, `${window.innerWidth * 3}x0`);

  useEffect(() => {
    if (fullSizeImage) {
      const img = new Image();
      img.src = fullSizeImage;
      img.onload = () => {
        setIsLoading(false);
      };
    }
  }, [fullSizeImage]);

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerClose" color="white" />
          </IconButton>
        ),
        bgColor: 'black',
      }}>
      <Container isLoading={isLoading}>
        {isLoading && <Loading />}
        <img
          src={fullSizeImage ?? defaultProfile}
          alt="프로필 이미지 확대보기"
          onError={e => {
            setIsLoading(false);
            (e.target as HTMLImageElement).src = defaultProfile;
          }}
        />
      </Container>
    </MainLayout>
  );
};

export default ProfileImgExtension;

const Container = styled.div<{ isLoading: boolean }>`
  background-color: black;
  height: 100%;
  padding-bottom: 56px;
  ${theme.flex('column', 'center', 'center')}
  position: relative;

  .loading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -56px;
  }

  img {
    display: block;
    object-fit: cover;
    width: ${({ isLoading }) => (isLoading ? '100%' : 'auto')};
    max-width: ${({ isLoading }) => (isLoading ? 'auto' : '100%')};
    max-height: ${({ isLoading }) => (isLoading ? 'auto' : '100%')};
  }
`;

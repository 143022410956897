export const HEADER_TITLE = {
  TERMS_OF_SERVICE: '이용약관',
  PRIVACY_POLICY: '개인정보처리방침',
} as const;

export const SUB_TITLE = {
  ENTER_MOBILE: '휴대폰 번호 입력',
  CHECK_MOBILE: '휴대폰 번호 인증',
  ENTER_PASSWORD: '비밀번호 입력',
  ENTER_BASIC_INFO: '기본 정보 입력',
} as const;

export const GENDER_SELECT = [
  { label: '남성', value: 'M' },
  { label: '여성', value: 'F' },
] as const;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import RoundedAccordion from 'components/Accordion/RoundedAccordion';
import Typography from 'components/Typography';
import { Fragment } from 'react/jsx-runtime';

import { FaqType } from '../type';

type Props = {
  faq: FaqType[number];
};

const FaqItem = ({ faq }: Props) => {
  return (
    <RoundedAccordionWrapper
      arrowGap={8}
      header={
        <AccordionHeaderWrapper>
          <Typography className="category" size={13} weight={500} textColor="gray2" opacity={0.8}>
            [{faq.category}]
          </Typography>
          <Typography size={15} weight={700}>
            {faq.title}
          </Typography>
        </AccordionHeaderWrapper>
      }>
      <AccordionContentWrapper>
        {faq.contents.map(content => (
          <Fragment key={content.subTitle}>
            <SubTitle size={15} weight={600}>
              {content.subTitle}
            </SubTitle>
            {content.message.map(message => (
              <Description size={15} textColor="gray2" key={message}>
                {message.includes('비밀번호를 잊어버렸나요?') ? (
                  <>
                    <em>비밀번호를 잊어버렸나요?</em> 버튼 선택
                  </>
                ) : (
                  message
                )}
              </Description>
            ))}
            {content.appendix && (
              <Appendix size={15} textColor="secondary3">
                {content.appendix}
              </Appendix>
            )}
          </Fragment>
        ))}
      </AccordionContentWrapper>
    </RoundedAccordionWrapper>
  );
};

export default FaqItem;

const RoundedAccordionWrapper = styled(RoundedAccordion)`
  min-height: 60px;
  .header-wrapper {
    height: auto;
    .header-box {
      display: block;
      padding: 16px 0;
    }
  }
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
`;

const AccordionHeaderWrapper = styled.div`
  ${theme.flex('column', '', '', 6)}
`;

const AccordionContentWrapper = styled.div`
  padding: 4px 20px 20px;
`;

const SubTitle = styled(Typography)`
  padding: 16px 0 0;
`;

const Description = styled(Typography)`
  position: relative;
  padding-top: 6px;

  em {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const Appendix = styled(Typography)`
  padding-top: 16px;
`;

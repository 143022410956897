import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { Z_INDEX } from 'constants/zIndex';
import useErrorDialog from 'hooks/useErrorDialog';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { errorDialogAtom } from 'recoil/dialog';

const ErrorDialog = () => {
  const errorDialog = useRecoilValue(errorDialogAtom);

  const { close } = useErrorDialog();

  const errorMessage = useMemo(() => {
    if (typeof errorDialog.error === 'string') {
      return errorDialog.error;
    }
    return errorDialog.error?.response?.data.message;
  }, [errorDialog.error]);

  return (
    <>
      {errorDialog.show && (
        <Dialog
          isError
          onClose={close}
          positiveAction={{
            text: errorDialog.buttonText ?? '확인',
            onClick: close,
          }}
          zIndex={Z_INDEX.errorDialog}>
          <DialogDescription tag="pre">{errorMessage}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default ErrorDialog;

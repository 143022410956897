import styled from '@emotion/styled';
import Divider from 'components/Divider';
import useInfinityPointHistory from 'hooks/service/queries/useInfinityPointHistory';
import { POINT_FILTER_DEFAULT, POINT_FILTER_DEFAULT_TAB_LABEL, POINT_FILTERS } from 'pages/MemberDetail/constants';
import { convertPointFilterParams } from 'pages/MemberDetail/utils/memberDetailFilters';
import { useRecoilValue } from 'recoil';
import { pointFiltersAtom } from 'recoil/MemberDetail';
import Filter from 'sharedComponents/Filters';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';

import DrawerFilterOptions from './PointFilter/DrawerFilterOptions';
import WholeFilterOptions from './PointFilter/WholeFilterOptions';
import PointHistoryCard from './PointHistoryCard';

type Props = {
  currentMemberId: number;
};

const PointHistory = ({ currentMemberId }: Props) => {
  const selectedFilter = useRecoilValue(pointFiltersAtom);
  const { start_date, end_date, ...rest } = convertPointFilterParams(selectedFilter);

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityPointHistory({
    member_id: currentMemberId,
    start_date,
    end_date,
    ...rest,
  });

  return (
    <Container>
      <Filter
        filterAtom={pointFiltersAtom}
        filters={POINT_FILTERS}
        defaultFilter={POINT_FILTER_DEFAULT}
        defaultTabLabels={POINT_FILTER_DEFAULT_TAB_LABEL}
        renderDrawerOptions={DrawerFilterOptions}
        renderWholeFilterPopupOptions={WholeFilterOptions}
      />
      <Divider thin thinColor="gray6" />
      {!data.length ? (
        <NodataTypography>포인트 내역이 없습니다.</NodataTypography>
      ) : (
        <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {data.map(item => (
            <PointHistoryCard key={item.id} pointData={item} />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default PointHistory;

const Container = styled.div`
  padding: 0 0 40px;
`;

import { useIsMutating } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import usePostUserTicketTransfer from 'hooks/service/mutations/usePostUserTicketTransfer';
import useToast from 'hooks/useToast';
import { TICKET_TRANSFER_TEXT } from 'pages/TicketDetail/constants';
import { TicketTransferFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';
import FooterButton from 'sharedComponents/FooterButton';
import { CustomError } from 'types/errorTypes';
import getPaymentFormParams from 'utils/getPaymentFormParams';

import AutoCancelDialog from './AutoCancelDialog';

type Props = UserTicketProps & {
  productTypeText: string;
};

const SubmitButton = ({ userTicket, productTypeText }: Props) => {
  const { handleSubmit, control } = useFormContext<TicketTransferFormType>();
  const selectedMember = useWatch({ control, name: 'selectedMember' });

  const isMutating = useIsMutating() > 0;
  const { setToast } = useToast();
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);
  const [dialog, setDialog] = useState({ show: false, message: '' });
  const navigate = useNavigate();
  const { mutate: transferMutate } = usePostUserTicketTransfer(userTicket.id);

  const submit = (values: TicketTransferFormType, isForce: boolean) => {
    const { selectedMember, installment, payment, count, period } = values;
    if (!selectedMember) return;

    const transfer_amount = payment.amount;
    const before_user_ticket_amount = userTicket.payments.total_amount;

    const { amount, ...paymentParams } = getPaymentFormParams({ payment, installment });

    const params = {
      ...count,
      ...period,
      ...paymentParams,
      user_id: userTicket.user_id,
      transferor_id: selectedMember.id,
      transfer_amount,
      before_user_ticket_amount,
      is_force: isForce,
    };

    transferMutate(params, {
      onSuccess: () => {
        setDialog({ show: false, message: '' });
        setToast({ type: 'success', message: `${productTypeText}${TICKET_TRANSFER_TEXT.successMessage}` });
        navigate(`/member/detail/${selectedMember.id}`);
      },
      onError: (error: CustomError) => {
        const isAutoCancel = error.response?.data.code === 10620;
        if (isAutoCancel) {
          setDialog({ show: true, message: error.response?.data.message || '' });
          return false;
        }

        const isErrorDrawerCase =
          isAxiosError(error) &&
          (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);
        if (isErrorDrawerCase) {
          setErrorBackStep(-4);
        }
      },
    });
  };

  return (
    <>
      <FooterButton onClick={handleSubmit(values => submit(values, false))} disabled={isMutating || !selectedMember}>
        수강권 양도
      </FooterButton>

      {dialog.show && (
        <AutoCancelDialog message={dialog.message} submit={submit} onClose={() => setDialog({ show: false, message: '' })} />
      )}
    </>
  );
};

export default SubmitButton;

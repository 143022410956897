import styled from '@emotion/styled';
import { theme } from 'assets/styles';

export const CardStyled = styled.div<{ cardColor: string }>`
  ${theme.flex('row', 'center', 'flex-start')};
  padding: 0 2px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ cardColor }) => cardColor};
  white-space: pre-wrap;
`;

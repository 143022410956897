type Props = {
  text: string;
  max: number;
  hasEllipsis?: boolean;
};

/**
 * 최대 노출 수가 정해져 있는 텍스트 말줄임
 * @param text 말줄임 필요한 전체 텍스트
 * @param max 최대 몇 자까지 노출 시킬 건지
 * @param hasEllipsis 말줄임 표시 노출 여부
 */
const getEllipsisByLength = ({ text, max, hasEllipsis = true }: Props) => {
  return text.length > max ? `${text.slice(0, max)}${hasEllipsis ? '...' : ''}` : text;
};

export default getEllipsisByLength;

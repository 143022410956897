import onboarding1 from 'assets/images/onboarding1.webp';
import onboarding2 from 'assets/images/onboarding2.webp';
import onboarding3 from 'assets/images/onboarding3.webp';
import onboarding4 from 'assets/images/onboarding4.webp';
import onboarding5 from 'assets/images/onboarding5.webp';

import { OnboardingContent } from './type';

export const ONBOARDING_CONTENT: OnboardingContent[] = [
  {
    page: 0,
    title: '더 편리한 일정관리',
    subTitle1: '일, 주, 월간 캘린더를 통해',
    subTitle2: '시간별 일정을 관리할 수 있습니다.',
    img: onboarding1,
    alt: '일정관리 설명 이미지',
  },
  {
    page: 1,
    title: '개인 일정까지 한 번에',
    subTitle1: '기타일정에서 나만의 일정을',
    subTitle2: '등록할 수 있습니다.',
    img: onboarding2,
    alt: '개인일정관리 설명 이미지',
  },
  {
    page: 2,
    title: '조건별 회원 조회',
    subTitle1: '원하는 조건대로 쉽고 빠르게',
    subTitle2: '회원목록을 조회할 수 있습니다.',
    img: onboarding3,
    alt: '회원조회 설명 이미지',
  },
  {
    page: 3,
    title: '모든 알림을 한 눈에',
    subTitle1: '수업 예약, 1:1 문의, 공지사항 등',
    subTitle2: '모든 알림을 한 곳에서 확인할 수 있습니다.',
    img: onboarding4,
    alt: '알림 설명 이미지',
  },
  {
    page: 4,
    title: '실시간 매출 현황 확인',
    subTitle1: '언제 어디서나 실시간 매출 현황을',
    subTitle2: '확인할 수 있습니다.',
    img: onboarding5,
    alt: '실시간매출현황 설명 이미지',
  },
];

import Typography, { TypographyProps } from 'components/Typography';
import React, { useMemo } from 'react';

type TypographyOptionTypes = Pick<TypographyProps, 'size' | 'weight' | 'textColor' | 'lineHeight'>;

type Props = {
  position: string;
  message?: string | string[];
  className?: string;
};

const Message = ({ position, message, className }: Props) => {
  const typographyOptions: TypographyOptionTypes = useMemo(() => {
    if (position === 'top') {
      return {
        size: 15,
        weight: 500,
        textColor: 'gray1',
      };
    }

    return {
      size: 14,
      weight: 400,
      textColor: 'gray2',
    };
  }, [position]);

  return (
    <Typography className={className} {...typographyOptions}>
      {Array.isArray(message)
        ? message.map((item, idx) => (
            <React.Fragment key={idx}>
              {item}
              <br />
            </React.Fragment>
          ))
        : message}
    </Typography>
  );
};

export default Message;

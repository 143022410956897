import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

const NewQnaMark = () => {
  return (
    <NewWrapper>
      <Typography size={10} weight={700} textColor="white" span>
        N
      </Typography>
    </NewWrapper>
  );
};

export default NewQnaMark;

const NewWrapper = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: ${theme.color.secondary3};
  ${theme.flex('row', 'center', 'center')}
  flex: 0 0 13px;

  .typography {
    width: 9px;
    line-height: 12px;
    text-align: center;
  }
`;

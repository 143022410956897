import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom, currentDateAtom } from 'recoil/schedule';

import { Container } from './CustomCalendarHeader';
import ViewTypeSelectDropdown from './dropdown/ViewTypeSelectDropdown';

const StudioExceptionCustomHeader = () => {
  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const currentDate = useRecoilValue(currentDateAtom);

  const headerDateText = useMemo(() => {
    const format = calendarViewType === 'day' ? 'MM.DD (ddd)' : 'YYYY.MM';
    return dayjs(currentDate).format(format);
  }, [calendarViewType, currentDate]);

  return (
    <Container>
      <div className="header-wrapper">
        <div className="header-days-controls">
          <Button fontSize={19} fontWeight={700} textColor="gray1" rightIcon={<Icon name="arrowBottomBold" size={16} />}>
            {headerDateText}
          </Button>
        </div>

        <div className="icons-wrapper">
          <IconButton>
            <Icon name="headerFilter" />
          </IconButton>

          <ViewTypeSelectDropdown isOpen={false} setIsOpen={() => null} />
        </div>
      </div>
    </Container>
  );
};

export default StudioExceptionCustomHeader;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { RefObject, useEffect, useState } from 'react';

import Tab, { TabProps } from './Tab';
import { TabBaseProps } from './TabBase';
import TabGroup from './TabGroup';

export type TabDataElementType = {
  /** Tab 데이터 요소 <br />
   * value: Tab 고유 값 <br />
   * label: Tab 텍스트 <br />
   */
  value: string;
  label: string;
};

export type TabsCommonProps = {
  /** Tab 데이터 */
  data: Array<TabDataElementType>;
  /** 현재 선택된 Tab의 value */
  value: TabDataElementType['value'];
  /** Tab 변경 로직  */
  onChange: TabBaseProps['onClick'];
};

export type TabsProps = TabsCommonProps &
  Pick<TabProps, 'isHeaderType'> & {
    /** true 일 때, 개별 Tab 영역이 전체 너비에 Tab 개수로 나눈 만큼 차지 */
    fullWidth?: boolean;
    /** 언더라인을 표시 유무 */
    isUnderline?: boolean;
    className?: string;
  };

const Tabs = ({ data, value, isHeaderType = false, fullWidth = true, onChange, isUnderline = true, className }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState<RefObject<HTMLButtonElement>>();
  const [indicatorPosition, setIndicatorPosition] = useState<{ width: number; left: number }>();

  useEffect(() => {
    if (!selectedTab?.current) return;

    setIndicatorPosition({
      width: selectedTab.current.offsetWidth,
      left: selectedTab.current.offsetLeft,
    });
  }, [fullWidth, selectedTab]);

  return (
    <StyledTapGroup
      underline={isUnderline}
      isHeaderType={isHeaderType}
      gap={!fullWidth ? 20 : 0}
      scrollable
      className={className}>
      {data.map(tab => (
        <Tab
          key={tab.value}
          data={tab}
          isHeaderType={isHeaderType}
          fullWidth={isHeaderType ? false : fullWidth}
          isSelected={tab.value === value}
          onClick={onChange}
          setSelectedTab={setSelectedTab}
        />
      ))}
      {!isHeaderType && <Indicator width={indicatorPosition?.width} left={indicatorPosition?.left} />}
    </StyledTapGroup>
  );
};

export default Tabs;

const StyledTapGroup = styled(TabGroup)`
  .tabs-wrapper {
    padding: 0 20px;
  }
`;

/** active 일 때 bar style */
const Indicator = styled.span<{ width?: number; left?: number }>`
  ${({ width }) => width && `width: ${width}px`};
  height: 2px;
  position: absolute;
  left: ${({ left }) => `${left || 0}px`};
  bottom: 0px;
  z-index: 2;
  background-color: ${theme.color.primary};
  transition: all 0.2s ease-in-out;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import FloatingActionButton from 'components/FloatingActionButton';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { staffListPageAtom } from 'recoil/staff';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout, { IMainLayoutProps } from 'sharedComponents/MainLayout';

import StaffSearchField from '../../components/StaffSearchField';
import { STAFF_HEADER_TITLE } from '../constants';
import { StaffFieldFormType } from '../types';
import SkelotonStaffList from './SkelotonStaffList';
import StaffCardListLayout from './StaffCardListLayout';

const Main = () => {
  const navigate = useNavigate();

  const [pageData, setPageData] = useRecoilState(staffListPageAtom);

  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const isRoleEditPermission = hasPermission(PERMISSION.role.edit);

  const methods = useForm<StaffFieldFormType>({
    mode: 'onChange',
    defaultValues: {
      sort: pageData.sort,
      search: pageData.search,
    },
  });

  const haederOptions: IMainLayoutProps['header'] = {
    title: STAFF_HEADER_TITLE.STAFF_LIST,
    bgColor: 'white',
    leftButton: (
      <IconButton onClick={() => navigate(-1)}>
        <Icon name="headerBack" />
      </IconButton>
    ),
    rightButton: isRoleEditPermission ? (
      <IconButton
        onClick={() => {
          checkPermission(PERMISSION.role.edit.id, {
            onSuccess: () => {
              navigate('permission-settings');
            },
          });
        }}>
        <Icon name="headerSetting" />
      </IconButton>
    ) : undefined,
    noUnderline: true,
  };

  const searchCancel = () => {
    setPageData({ ...pageData, search: '' });
  };

  const searchSubmit: SubmitHandler<StaffFieldFormType> = inputValues => {
    const { search } = inputValues;
    setPageData({ ...pageData, search });
  };

  return (
    <MainLayout header={haederOptions} backgroundColor="gray6">
      <FormProvider {...methods}>
        <SearchWrapper className="search-wrapper">
          <StaffSearchField onSubmit={methods.handleSubmit(searchSubmit)} onCancel={searchCancel} />
        </SearchWrapper>

        <ApiBoundary fallback={<SkelotonStaffList />}>
          <StaffCardListLayout />
        </ApiBoundary>
      </FormProvider>

      {hasPermission(PERMISSION.staff.create) && (
        <FloatingActionButton
          type="single"
          bottom={24}
          onClick={() => {
            checkPermission(PERMISSION.staff.create.id, {
              onSuccess: () => {
                navigate('create-form?step=1');
              },
            });
          }}
        />
      )}
    </MainLayout>
  );
};

export default Main;

const SearchWrapper = styled.div`
  position: sticky;
  top: 0;
  margin-top: -1px;
  padding: 8px 20px 16px;
  border-bottom: 1px solid ${theme.color.gray6};
  background-color: ${theme.color.white};
  z-index: 1;
`;

import { MemberCounselSelectedFilterType, MemberSelectedFilterType } from 'pages/Member/types';
import { atom } from 'recoil';

/**
 * 전체 회원
 */

export const allMemberSelectedFiltersAtom = atom<MemberSelectedFilterType>({
  key: 'allMemberSelectedFilters',
  default: {
    sort_target: 'name',
    sort_type: 'asc',
  },
});

/**
 * 담당 회원
 */

export const assignedMemberSelectedFiltersAtom = atom<MemberSelectedFilterType>({
  key: 'assignedMemberSelectedFiltersAtom',
  default: {
    sort_target: 'name',
    sort_type: 'asc',
  },
});
/**
 * 상담고객
 */
export const counselSelectedFiltersAtom = atom<MemberCounselSelectedFilterType>({
  key: 'counselSelectedFiltersAtom',
  default: { register_type: 'both' },
});

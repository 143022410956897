import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import UnderlinedTextField from 'components/TextField/components/UnderlinedTextField';
import Typography from 'components/Typography';
import { TICKET_TRANSFER_TEXT } from 'pages/TicketDetail/constants';
import { TicketTransferCommonProps, TicketTransferFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';
import { PaymentFormTitle } from 'sharedComponents/TicketForm';
import TicketCountForm from 'sharedComponents/TicketForm/TicketCountForm';
import TicketPeriodForm from 'sharedComponents/TicketForm/TicketPeriodForm';

import TicketSubTitle from '../TicketSubTitle';
import getTransferUserTicketDetail from '../utils/getTransferUserTicketDetail';
import FoldingTicketCard from './FoldingTicketCard';

type Props = UserTicketProps & TicketTransferCommonProps;

const TicketDetailForm = ({ userTicket, productTypeText, title, subTitle, onClickNextPage }: Props) => {
  const { control } = useFormContext<TicketTransferFormType>();
  const count = useWatch({ control, name: 'count' });
  const period = useWatch({ control, name: 'period' });

  const {
    is_show_cancel_count,
    ticket: { type, daily_booking_change_limit },
  } = userTicket;

  const ticketDetail = getTransferUserTicketDetail({
    userTicket,
    step: 2,
    finalData: { ...count, ...period },
  });

  return (
    <Container>
      <TicketSubTitle title={`${productTypeText} ${title}`} message={`${subTitle[0]} ${productTypeText} ${subTitle[1]}`} />
      <FoldingTicketCard userTicket={userTicket} ticketDetail={ticketDetail} />
      <Divider />
      <PaymentFormTitle size={14}>양도 {productTypeText} 정보</PaymentFormTitle>
      <TicketPeriodForm ticketType={type} />
      <TicketCountForm ticketType={type} isShowCancelCount={is_show_cancel_count} />

      {!is_show_cancel_count && (
        <UnderlinedTextField
          id="cancelCount"
          readOnly
          textAlign="right"
          left={{
            text: TICKET_TRANSFER_TEXT.form.cancelCount.label,
            prefix: <Icon name="roomCancel" color="gray3" />,
          }}
          value={TICKET_TRANSFER_TEXT.form.cancelCount.noValue}
        />
      )}

      <UnderlinedTextField
        id="change"
        readOnly
        textAlign="right"
        left={{
          text: TICKET_TRANSFER_TEXT.form.dailyBookingChangeLimit.label,
          prefix: <Icon name="changeRegular" color="gray3" />,
        }}
        value={daily_booking_change_limit || TICKET_TRANSFER_TEXT.form.dailyBookingChangeLimit.noValue}
        suffix={
          daily_booking_change_limit ? (
            <Typography span weight={500}>
              회
            </Typography>
          ) : undefined
        }
      />

      <FooterButton onClick={onClickNextPage}>다음</FooterButton>
    </Container>
  );
};

export default TicketDetailForm;

const Container = styled.div`
  padding-bottom: 116px;
  min-height: 100%;

  .underlined-text-field:last-of-type {
    border: none;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import SubTitle from 'components/SubTitle';
import Typography from 'components/Typography';

type Props = {
  title: string;
  rightText?: { text: string; onClick?: () => void };
};

const OneLinedTitle = ({ title, rightText }: Props) => {
  return (
    <Container>
      <SubTitle title={title} />

      {rightText &&
        (rightText.onClick ? (
          <Button fontSize={15} fontWeight={600} textColor="gray3" onClick={rightText.onClick}>
            {rightText.text}
          </Button>
        ) : (
          <Typography size={15} weight={600} textColor="gray3">
            {rightText.text}
          </Typography>
        ))}
    </Container>
  );
};

export default OneLinedTitle;

const Container = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 16px 20px;
`;

import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { SETTINGS_NAVIGATION } from '../constants';
import SettingItem from './SettingItem';

const SettingNavigation = () => {
  const isLast = (index: number) => index === SETTINGS_NAVIGATION.length - 1;
  const navigate = useNavigate();
  return (
    <>
      {SETTINGS_NAVIGATION.map((setting, index) => (
        <Fragment key={setting.title}>
          <Section>
            <Title size={13} weight={500} textColor="gray3">
              {setting.title}
            </Title>
            {setting.items.map(item => (
              <SettingItem key={item.name} onClick={() => navigate(item.path)} label={item.name} />
            ))}
          </Section>
          {!isLast(index) && <Divider />}
        </Fragment>
      ))}
    </>
  );
};

export default SettingNavigation;

const Section = styled.section`
  padding-top: 12px;
`;

const Title = styled(Typography)`
  margin: 4px 20px;
`;

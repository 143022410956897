import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckRadio, { CheckRadioProps } from 'components/Radio/CheckRadio';
import { ChangeEvent } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

import CheckRadioTypography from './CheckRadioTypography';

export type CheckRadioFilterDataType = Pick<CheckRadioProps, 'id' | 'label' | 'value'>;

export type CheckRadioFilterContentsProps<T extends FieldValues> = {
  /** radio input 데이터 */
  data: Array<CheckRadioFilterDataType>;
  /** input group 용도의 name */
  name: FieldPath<T>;
  /** useForm의 control */
  control: Control<T>;
  /** label을 전부 보여줄 건지 말지 (default: 1줄 말줄임) */
  showLabelAll?: boolean;
  /** onChange 내부에 커스텀 이벤트 삽입 */
  onClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  /** Drawer 닫기 */
  onClose?: () => void;
  className?: string;
};

const CheckRadioFilterContents = <T extends FieldValues>({
  data,
  name,
  control,
  showLabelAll,
  className,
  onClick,
  onClose,
}: CheckRadioFilterContentsProps<T>) => {
  return (
    <Container className={`check-radio-filter-contents ${className}`}>
      {data.map(item => (
        <li key={item.id}>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <CheckRadio
                id={item.id}
                label={
                  typeof item.label === 'string' ? (
                    <CheckRadioTypography
                      className="check-radio-filter-label-text"
                      isSelected={item.value === value}
                      ellipsis={!showLabelAll ? 1 : undefined}>
                      {item.label}
                    </CheckRadioTypography>
                  ) : (
                    item.label
                  )
                }
                value={item.value}
                checked={item.value === value}
                onChange={e => {
                  onChange(e);
                  onClick?.(e);
                  onClose?.();
                }}
                {...field}
              />
            )}
          />
        </li>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  ${theme.flex('column', 'initial', 'initial', 8)};

  li {
    padding: 8px 0;

    label {
      height: 100%;
    }
  }
`;

export default CheckRadioFilterContents;

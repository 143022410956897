import styled from '@emotion/styled';
import Typography from 'components/Typography';

import { CardStyled } from './CardStyled';

type Props = {
  title: string;
  cardColor: string;
};

const EventCounselCard = ({ ...props }: Props) => {
  const { title, cardColor } = props;

  return (
    <Container cardColor={cardColor}>
      <Typography size={12} weight={600} ellipsis={1}>
        {title} 님
      </Typography>
    </Container>
  );
};

export default EventCounselCard;

const Container = styled(CardStyled)`
  border-radius: 1px 2px 2px 1px;
  border-left: 2px solid ${({ cardColor }) => cardColor};
  background-color: ${({ cardColor }) => `${cardColor}1f`};
`;

import _ from 'lodash';
import { ScheduleFormType } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';

const getReverseEventTimeDataset = (schedules: ScheduleFormType[]) => {
  const result: ScheduleFormType[] = [];

  schedules.forEach(schedule => {
    if (!schedule.restTimes) {
      result.push(schedule);
      return;
    }

    /** 휴식시간 제외한 일정 삽입(기존 데이터 형식) */
    const origin = _.omit(schedule, 'restTimes');
    result.push(origin);

    /** 휴식시간만 따로 삽입 */
    schedule.restTimes.forEach(restTime => {
      result.push(restTime);
    });
  });

  return result;
};

export default getReverseEventTimeDataset;

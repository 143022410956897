import styled from '@emotion/styled';
import Typography from 'components/Typography';
import _ from 'lodash';
import { ScheduleFormType } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';
import { Dispatch, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { eventScheduleSelectDateSelector } from 'recoil/staff';

import { NO_CONTENT_TEXT } from '../constants';
import EventWorkTimeCardMulti from './EventWorkTimeCardMulti';

export type Props = {
  selectDateFields: ScheduleFormType[];
  setDatasetFields: Dispatch<SetStateAction<ScheduleFormType[]>>;
};

const EventWorkTimeCardListMulti = ({ selectDateFields, setDatasetFields }: Props) => {
  const selectDateTexts = useRecoilValue(eventScheduleSelectDateSelector);

  if (!selectDateTexts.length) {
    return (
      <NoContentText className="no-content-text" size={15} textColor="gray2">
        {NO_CONTENT_TEXT}
      </NoContentText>
    );
  }

  return (
    <Container>
      {selectDateFields.map((workTimeField, __, list) => {
        const { date, day_of_week } = workTimeField;

        /** 같은 요일 일괄 적용 버튼 노출 유무 */
        const uniqDays = _.uniqBy(list, 'day_of_week');
        const isShowBatchApply =
          uniqDays.findIndex(uniqDay => {
            return uniqDay.date === date && uniqDay.day_of_week === day_of_week;
          }) > -1;

        return (
          <EventWorkTimeCardMulti
            key={`${date}-${day_of_week}`}
            workTimeField={workTimeField}
            setDatasetFields={setDatasetFields}
            isShowBatchApply={isShowBatchApply}
          />
        );
      })}
    </Container>
  );
};

export default EventWorkTimeCardListMulti;

const Container = styled.section`
  padding: 20px;
  width: 100%;
`;

const NoContentText = styled(Typography)`
  margin-top: 120px;
  opacity: 0.8;
`;

export const WITHDRAW_TEXT = {
  headerTitle: '계정 탈퇴',
  title: ['⛔️ 탈퇴 전,', '꼭 읽어주세요!'],
  contents: [
    '강사 계정 탈퇴 시 개인 정보는 전자상거래 법률에 의거하여 탈퇴 후 5년간 보관됩니다.',
    '수업 내역을 포함한 모든 정보는 일괄적으로 삭제됩니다.',
    '시설에 등록되어 있는 강사 정보는 스튜디오메이트에서 삭제할 수 없습니다. 해당 시설측으로 직접 삭제요청을 하셔야 합니다.',
  ],
  guides: ['본인 확인을 위해 비밀번호를 입력하신 후', "'계정 탈퇴 신청' 버튼을 눌러주세요."],
  missingPassword: '비밀번호를 잊어버렸나요?',
  placeholder: '비밀번호 입력',
  agree: '모든 정보를 삭제하고 계정 탈퇴에 동의합니다.',
  submit: '계정 탈퇴 신청',
  finalTitle: '계정을 탈퇴합니다.',
  finalDescription: ['계정 탈퇴를 진행하면 그동안 이용하신 고객님의', '모든 데이터가 삭제되며', ' 복구를 진행할 수 없습니다.'],
  finalCancel: '계속 이용하기',
  finalSubmit: '탈퇴하기',
};

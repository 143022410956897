import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 기존 비밀번호 확인 */
const usePostAuthCheckCurrentPassword = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AuthCheckCurrentPasswordParams>({
    method: `post`,
    url: `/api/auth/check-current-password`,
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostAuthCheckCurrentPassword;

type AuthCheckCurrentPasswordParams = {
  current_password: string;
};

import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { DropdownType } from 'pages/Booking/types';
import { ETC_DETAIL_DROPDOWN_DATA } from 'pages/EtcSchedule/constants';

type Props = {
  contrastColor: keyof typeof color;
  isOpen: boolean;
  onToggle: (value: boolean) => void;
  onClick: (value: DropdownType['value']) => void;
  canCreateEtcSchedule: boolean;
  canUpdateEtcSchedule: boolean;
  canDeleteEtcSchedule: boolean;
};

const EtcDetailDropDown = ({
  contrastColor,
  isOpen,
  onClick,
  onToggle,
  canCreateEtcSchedule,
  canUpdateEtcSchedule,
  canDeleteEtcSchedule,
}: Props) => {
  return (
    <DropDown
      isOpen={isOpen}
      position="right"
      bottomSpacing={8}
      onClose={() => onToggle(false)}
      content={
        <Container>
          {ETC_DETAIL_DROPDOWN_DATA.map(({ value, label, textColor, icon }) => {
            if (!canCreateEtcSchedule && value === 'copy') return null;
            if (!canUpdateEtcSchedule && value === 'update') return null;
            if (!canDeleteEtcSchedule && value === 'delete') return null;
            return (
              <li key={value}>
                <Button
                  fullWidth
                  fontSize={15}
                  fontWeight={500}
                  textColor={textColor}
                  rightIcon={<Icon size={16} {...icon} />}
                  onClick={() => onClick(value)}>
                  {label}
                </Button>
              </li>
            );
          })}
        </Container>
      }>
      <IconButton onClick={() => onToggle(!isOpen)}>
        <Icon name="headerMore" color={contrastColor} />
      </IconButton>
    </DropDown>
  );
};

export default EtcDetailDropDown;

const Container = styled.ul`
  width: 128px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 2px 8px 0px rgba(145, 145, 145, 0.2);

  li {
    padding: 13px 12px 13px 16px;
    :not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray7};
    }

    button {
      height: 100%;
      justify-content: space-between;
    }
  }
`;

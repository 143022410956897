export const WEEKDAYS_KO = ['월', '화', '수', '목', '금', '토', '일'] as const;

export const STUDIO_DETAIL_TEXTS = {
  title: '시설조회',
  info: '시설정보',
  contact: '전화번호',
  openHours: '운영시간',
  address: '시설위치',
  call: '전화',
  closed: '휴무',
  notProvided: '시설에서 정보를 입력하지 않았어요',
  noStudio: '이용시설이 존재하지 않습니다.',
};

export const STUDIO_DELETE_TEXTS = {
  title: '시설 삭제',
  deletionConfirmation: '삭제하시겠습니까?',
  deletionDescription: '삭제된 시설은 조회할 수 없습니다.',
  deletionTitle: '이용하지 않는 시설을',
  deletionTitle2: '삭제할 수 있습니다.',
  cancel: '취소',
  delete: '삭제',
};

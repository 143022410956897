import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import UserGradeSetting from './UserGradeSetting';

const UserGrade = () => {
  return (
    <ApiBoundary
      fallback={
        <FallbackContainer>
          <Icon name="vip" fillColor={theme.color.gray2} />
          <Typography size={15} textColor="gray3">
            회원등급
          </Typography>
          <Icon name="arrowRightBold" size={16} fillColor={theme.color.gray3} />
        </FallbackContainer>
      }>
      <UserGradeSetting />
    </ApiBoundary>
  );
};

export default UserGrade;

const FallbackContainer = styled.div`
  padding: 0 20px;
  height: 56px;
  ${theme.flex('', 'center', '', 10)};

  .typography {
    flex: 1;
  }
`;

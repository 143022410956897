export const TITLE = {
  header: {
    profile: '프로필',
    timeInfoSetting: '시간정보 설정',
  },
  sub: {
    basicInfo: {
      introduce: '자기소개',
      careers: '주요이력',
    },
    timeInfo: {
      privateBookingTime: '프라이빗 수업 예약 시간 단위',
      workTime: '근무시간',
      eventWorkTime: '특정일 근무시간',
    },
  },
} as const;

// 시간정보(TimeSchedule) 관련 상수
export const PRIVATE_START_INTERVAL = {
  timeTable: [
    { label: '오전', value: 'am' },
    { label: '오후', value: 'pm' },
  ],
  dataset: {
    am: { time: '00:00 ~ 12:00' },
    pm: { time: '13:00 ~ 24:00' },
  },
} as const;

export const DAYS = {
  1: '월요일',
  2: '화요일',
  3: '수요일',
  4: '목요일',
  5: '금요일',
  6: '토요일',
  0: '일요일',
} as const;

export const SORT_DATA = [
  { id: 'nameAsc', label: '이름 오름차순', value: 'nameAsc' },
  { id: 'nameDesc', label: '이름 내림차순', value: 'nameDesc' },
  { id: 'dateDesc', label: '등록일 최신순', value: 'dateDesc' },
  { id: 'dateAsc', label: '등록일 과거순', value: 'dateAsc' },
];

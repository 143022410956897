import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

import { MOBILE_DETAIL_TEXT } from '../constants';

type Props = {
  title: string;
  message: string;
};

const MobileDetailContents = ({ title, message }: Props) => {
  return (
    <Container>
      <Typography size={15} weight={600}>
        {MOBILE_DETAIL_TEXT.subTitle}
      </Typography>
      <div>
        <Typography size={13} weight={500} textColor="gray2">
          {MOBILE_DETAIL_TEXT.message.title}
        </Typography>
        <ContentBox size={15} weight={500} textColor="gray2">
          {title}
        </ContentBox>
      </div>
      <div>
        <Typography size={13} weight={500} textColor="gray2">
          {MOBILE_DETAIL_TEXT.message.content}
        </Typography>
        <ContentBox size={15} weight={500} textColor="gray2">
          {message}
        </ContentBox>
      </div>
    </Container>
  );
};

export default MobileDetailContents;

const Container = styled.div`
  padding: 24px 20px 40px;
  ${theme.flex('column', '', '', 24)}
`;

const ContentBox = styled(Typography)`
  padding: 13px 16px;
  border-radius: 10px;
  background-color: ${theme.color.gray6};
  margin: 8px 0;
`;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePatchStaffNoticeCheckInReadAll = () => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `patch`,
    url: `/api/staff/notice/check-in-read-all`,
  });

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['staff/notice', 'check-in'] });
    },
  });
};

export default usePatchStaffNoticeCheckInReadAll;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Icon from 'components/Icon';
import ArrowFilterTabs from 'components/Tabs/ArrowFilterTabs';
import TabPanel from 'components/Tabs/TabPanel';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import Drawer from 'designedComponents/Drawers';

import FilterCount from './FilterCount';
import { FilterDrawerProps } from './types';

const FilterDrawer = ({
  isOpen,
  onClose,
  children,
  currentFilterTab,
  onChange,
  onReset,
  onSave,
  onOpenWholeFilters,
  filterTabs,
  filterValues,
  selectedCount,
  setTempParams,
}: FilterDrawerProps) => {
  const resetFilter = () => {
    onReset();
    setTempParams?.();
  };

  const openWholeFilters = () => {
    onOpenWholeFilters();
    onClose();
  };

  return (
    <>
      <StyledDrawer
        isOpen={isOpen}
        onClose={onClose}
        direction="top"
        zIndex={Z_INDEX.popup - 1} // 전체 필터 열릴 때 숨겨지도록
      >
        <Container className="filter-drawer-contents">
          <TabWrapper>
            <FilterCount selectedCount={selectedCount} onClick={openWholeFilters} />
            <ArrowFilterTabs data={filterTabs} value={currentFilterTab.value} filterValues={filterValues} onChange={onChange} />
          </TabWrapper>

          <TabPanel>{children}</TabPanel>

          <ButtonGroup leftButtonWidth={115}>
            <Button
              variant="outlined"
              size="medium48"
              leftIcon={<Icon name="resetBold" size={16} color="gray3" />}
              onClick={resetFilter}
              gap={2}>
              <Typography textColor="gray2" weight={500}>
                초기화
              </Typography>
            </Button>
            <Button variant="contained" color="point" size="medium48" fullWidth onClick={onSave}>
              필터 적용
            </Button>
          </ButtonGroup>
        </Container>
      </StyledDrawer>
    </>
  );
};

export default FilterDrawer;

const StyledDrawer = styled(Drawer)`
  padding: 0;
`;

const Container = styled.div`
  > .button-group {
    padding: 14px 20px;
    background-color: ${theme.color.gray7};
  }
  .tab-panel {
    border-top: 1px solid ${theme.color.gray7};
  }
`;

const TabWrapper = styled.div`
  ${theme.flex('', '', '')};
  padding-left: 20px;
  margin: 14px 0;
  position: relative;

  .tabs {
    margin: 0;
    white-space: nowrap;
    overflow: auto hidden;

    &::-webkit-scrollbar {
      display: none;
    }
    .tabs-wrapper {
      padding-left: 6px;
      padding-right: 20px;
    }
  }
`;

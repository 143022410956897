import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';

type Props = Omit<ButtonProps, 'fullWidth' | 'color' | 'size' | 'variant'>;

const FooterButton = ({ children, ...props }: Props) => {
  return (
    <FooterButtonWrapper>
      <Button fullWidth color="point" size="large56" fontSize={15} variant="contained" {...props}>
        {children}
      </Button>
    </FooterButtonWrapper>
  );
};

export default FooterButton;

export const FooterButtonWrapper = styled.footer`
  padding: 10px 20px;
  width: 100%;
  position: fixed;
  z-index: ${Z_INDEX.footerButton};
  left: 0;
  bottom: 0;
  background-color: ${theme.color.white};
`;

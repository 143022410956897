import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import ScheduleTemplate from './ScheduleTemplate';

const MoreDetailSchedule = () => {
  return (
    <ApiBoundary>
      <ScheduleTemplate />
    </ApiBoundary>
  );
};

export default MoreDetailSchedule;

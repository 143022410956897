import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Tabs, { TabDataElementType } from 'components/Tabs';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RecoilState, useRecoilState } from 'recoil';
import { reportLecturePeriodAtom, reportPointPeriodAtom, reportSalePeriodAtom } from 'recoil/report/atom';
import filters from 'utils/filters';

import { REPORT_TABS } from '../constants';
import { ReportPeriodType } from '../type';
import { getLabel } from '../util';
import ReportPeriodFilter from './ReportPeriodFilter';

dayjs.extend(isSameOrAfter);

type Props = {
  isShowSummaryList: boolean;
  currentTab: TabDataElementType;
  changeTab: (tabData: TabDataElementType) => void;
  setIsShowSummaryList: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportTabs = ({ isShowSummaryList, currentTab, changeTab, setIsShowSummaryList }: Props) => {
  const periodAtom: { [key: string]: RecoilState<ReportPeriodType> } = {
    sale: reportSalePeriodAtom,
    lecture: reportLecturePeriodAtom,
    point: reportPointPeriodAtom,
    unpaid: reportSalePeriodAtom,
  };

  const [isOpenPeriod, setIsOpenPeriod] = useState(false);
  const [isDisablePeriodArrowRight, setIsDisablePeriodArrowRight] = useState(true);
  const [currentPeriod, setCurrentPeriod] = useRecoilState(periodAtom[currentTab.value]);

  const clickPeriod = () => {
    setIsOpenPeriod(true);
  };

  const closePeriod = () => {
    setIsOpenPeriod(false);
  };

  const changeListMode = () => {
    setIsShowSummaryList(!isShowSummaryList);
  };

  // 타입 가드 함수
  const getReportPeriodFilterAtom = (currentTabValue: string): RecoilState<ReportPeriodType> => {
    if (currentTabValue === 'sale') {
      return reportSalePeriodAtom;
    } else if (currentTabValue === 'lecture') {
      return reportLecturePeriodAtom;
    } else if (currentTabValue === 'point') {
      return reportPointPeriodAtom;
    } else {
      // NOTE
      // useRecoil에 undefined를 넣을 수 없어서 period 컴포넌트가 표시는 안되지만 에러방지를 위해서 설정
      return reportSalePeriodAtom;
    }
  };

  const shouldShowArrows = useMemo(() => currentPeriod.period?.type !== 'custom', [currentPeriod.period?.type]);

  const label = useMemo(() => {
    return getLabel({ date: [currentPeriod.period?.start, currentPeriod.period?.end], type: currentPeriod.period?.type });
  }, [currentPeriod]);

  const clickPrevDay = useCallback(() => {
    const unit =
      currentPeriod.period?.type === 'month' || currentPeriod.period?.type === 'day' ? currentPeriod.period?.type : 'weeks';
    const startDate = currentPeriod.period?.start;
    const prevDate = dayjs(startDate).subtract(1, unit);
    const endDate = dayjs(prevDate).endOf(unit === 'weeks' ? 'isoWeek' : unit);
    setCurrentPeriod({
      period: {
        type: currentPeriod.period?.type || 'day',
        start: filters.dateDash(prevDate),
        end: filters.dateDash(endDate),
      },
    });
  }, [setCurrentPeriod, currentPeriod]);

  const clickNextDay = useCallback(() => {
    const unit =
      currentPeriod.period?.type === 'month' || currentPeriod.period?.type === 'day' ? currentPeriod.period?.type : 'weeks';
    const oldEndDate = currentPeriod.period?.start;
    const nextDate = dayjs(oldEndDate).add(1, unit);
    const endDate = dayjs(nextDate).endOf(unit === 'weeks' ? 'isoWeek' : unit);
    setCurrentPeriod({
      period: {
        type: currentPeriod.period?.type || 'day',
        start: filters.dateDash(nextDate),
        end: filters.dateDash(endDate),
      },
    });
  }, [setCurrentPeriod, currentPeriod]);

  useEffect(() => {
    const endDate = currentPeriod.period?.end;
    const isTodayOrAfter = dayjs(endDate).isSameOrAfter(dayjs(), 'day'); // endDate가 오늘이거나 이후인지 확인

    // endDate가 오늘이거나 오늘 이후라면 버튼을 활성화, 오늘 이전이면 비활성화
    setIsDisablePeriodArrowRight(!isTodayOrAfter);
  }, [currentPeriod]);

  return (
    <Container>
      <section className="filter-section">
        <Tabs fullWidth={false} isUnderline={false} data={REPORT_TABS} value={currentTab.value} onChange={changeTab} />
        {currentTab.value === 'sale' && (
          <Button
            className="report-list-button"
            edge="circular"
            heightSize={24}
            variant="outlined"
            fontSize={13}
            fontWeight={500}
            textColor="gray2"
            onClick={changeListMode}>
            {isShowSummaryList ? '목록보기' : '요약보기'}
          </Button>
        )}
      </section>
      {currentTab.value !== 'unpaid' && (
        <>
          <section className={!shouldShowArrows ? 'non-arrow-period-section' : 'period-section'}>
            <IconButton className={!shouldShowArrows ? 'hidden' : 'report-arrow-button'} onClick={clickPrevDay}>
              <Icon name="arrowLeft" size={24} color="gray2" />
            </IconButton>
            <Button
              fontSize={14}
              fontWeight={500}
              textColor="gray2"
              onClick={clickPeriod}
              rightIcon={<Icon name="arrowBottomFill" size={16} color="gray3" />}>
              {_.isObject(label) ? (
                <>
                  <div className="date-week-label">
                    <Typography span size={14} weight={500} textColor="gray2">
                      {label.dateKor}
                    </Typography>
                    <Typography span size={14} weight={500} textColor="gray3">
                      {label.dateNumber}
                    </Typography>
                  </div>
                </>
              ) : (
                label
              )}
            </Button>
            <IconButton
              className={!shouldShowArrows ? 'hidden' : 'report-arrow-button'}
              disabled={!isDisablePeriodArrowRight}
              onClick={clickNextDay}>
              <Icon name="arrowRight" size={24} color={isDisablePeriodArrowRight ? 'gray2' : 'gray4'} />
            </IconButton>
          </section>
          <ReportPeriodFilter
            filterAtom={getReportPeriodFilterAtom(currentTab.value)}
            isOpen={isOpenPeriod}
            currentTab={currentTab.value}
            onClose={closePeriod}
          />
        </>
      )}
    </Container>
  );
};

export default ReportTabs;

const Container = styled.div`
  .filter-section {
    ${theme.flex('', 'center', 'space-between')}
    height: 44px;
    padding-right: 20px;
    border-bottom: 1px solid ${theme.color.gray6};

    .tabs-wrapper {
      gap: 16px;
    }

    .report-list-button {
      min-width: 58px;
    }
  }

  .period-section {
    ${theme.flex('', 'center', 'space-between')}
    padding: 12px 20px;
    border-bottom: 1px solid ${theme.color.gray6};

    .text-button {
      .date-week-label {
        ${theme.flex('', '', '', 2)}
      }
    }
  }

  .non-arrow-period-section {
    ${theme.flex('', 'center', 'center')}
    padding: 15px 20px;
    border-bottom: 1px solid ${theme.color.gray6};

    .hidden {
      display: none;
    }
  }
`;

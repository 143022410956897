import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { ERROR_CODE, PLACEHOLDER } from 'constants/text';
import usePostLogin from 'hooks/service/mutations/usePostLogin';
import useGetAuthMeInstanceTokenCheck from 'hooks/service/queries/useGetAuthMeInstanceTokenCheck';
import useErrorDialog from 'hooks/useErrorDialog';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { accessTokenTempAtom } from 'recoil/common';
import { CustomError } from 'types/errorTypes';
import { postMessageToApp } from 'utils/communicateWithNative';
import localStorage from 'utils/localStorage';
import { mobileRequired, password } from 'utils/validate';
import { object } from 'yup';

import { LoginFormType } from './types';

const yupSchema = object().shape({ password, mobileRequired });

const LoginForm = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const setAccessTokenTemp = useSetRecoilState(accessTokenTempAtom);

  const errorDialog = useErrorDialog();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { isValid },
  } = useForm<LoginFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
      mobileRequired: '',
    },
  });

  const { mutate: postLoginMutate, isPending } = usePostLogin();
  const { data: fcmResponse } = useGetAuthMeInstanceTokenCheck();

  const onSubmit: SubmitHandler<LoginFormType> = async inputValues => {
    /** 로그인 시 토큰이 있으면 서버 에러를 반환해서, 제거 */
    localStorage.remove('access_token');

    const { mobileRequired, password } = inputValues;
    const params = {
      staff_id: undefined,
      studio_id: undefined,
      mobile: mobileRequired,
      password,
    };

    postLoginMutate(params, {
      onSuccess: ({ data }) => {
        localStorage.set('isUnifiedAccount', true);

        const accessToken = data.account_token;
        localStorage.set('access_token', accessToken);

        postMessageToApp('REQUEST_NOTIFICATION_PERMISSION');

        if (!fcmResponse?.validate) {
          postMessageToApp('REQUEST_FCM_TOKEN');
        }

        const url = data.is_name_nickname ? '/select-studio' : '/unified-account?step=2';
        navigate(url, { state: { type: 'loginSuccess', mobile: mobileRequired } });
      },
      onError: (error: CustomError) => {
        const code = error.response?.status;
        const message = error.response?.data.message;

        switch (code) {
          case ERROR_CODE.ALREADY_REPORTED: {
            errorDialog.open(error);
            break;
          }
          case ERROR_CODE.MULTIPLE_CHOICES: {
            const accessToken = error.response?.data.beforeIntegratedAccountLoginToken;
            setAccessTokenTemp(accessToken);

            const studios = error.response?.data.sameStaffAccounts;
            const step = studios.length > 1 ? 1 : 2;
            navigate(`/unified-account?step=${step}`, { state: { studios, mobile: mobileRequired } });
            break;
          }
          case ERROR_CODE.BAD_REQUEST:
            setError('password', { message });
            break;
          case ERROR_CODE.UNAUTHORIZED:
            setError('mobileRequired', { message });
        }
        return false;
      },
    });
  };

  const handleClear = (name: 'mobileRequired') => setValue(name, '', { shouldValidate: true });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <OutlinedTextFieldController
        inputMode="numeric"
        controllerProps={{ control, name: 'mobileRequired' }}
        placeholder={PLACEHOLDER.MOBILE}
        onClear={() => handleClear('mobileRequired')}
      />

      <OutlinedTextFieldController
        controllerProps={{ control, name: 'password' }}
        placeholder={PLACEHOLDER.PASSWORD}
        type={`${showPassword ? 'text' : 'password'}`}
        suffix={
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            <Icon name={`${showPassword ? 'showOff' : 'showOn'}`} fillColor={theme.color.gray3} />
          </IconButton>
        }
      />

      <Button
        className="submit-btn"
        fullWidth
        disabled={!isValid || isPending}
        type="submit"
        color="point"
        size="medium48"
        variant="contained">
        로그인
      </Button>
    </Form>
  );
};

export default LoginForm;

import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { HISTORY_SORT_DATA } from 'pages/TicketDetail/constants';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SortFormType } from 'sharedComponents/MemoForm/types';
import SortButton from 'sharedComponents/SortButton';

const Sorting = () => {
  const { control } = useFormContext<SortFormType>();
  const sort = useWatch({ control, name: 'sort' });
  const [isOpen, setIsOpen] = useState(false);

  const label = HISTORY_SORT_DATA.find(({ value }) => value === sort)?.label;

  return (
    <>
      {<SortButton onClick={() => setIsOpen(true)}>{label}</SortButton>}
      <CheckRadioDrawer name="sort" control={control} data={HISTORY_SORT_DATA} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Sorting;

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Textarea from 'components/Textarea';
import { Z_INDEX } from 'constants/zIndex';
import usePostBoardQna from 'hooks/service/mutations/usePostBoardQna';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';
import { maximumLength } from 'utils/validate';
import { object } from 'yup';

import { QNA_DETAIL_TEXT } from '../constants';
import { CommentFormType } from '../types';

type Props = {
  questionId: number;
  canComment: boolean;
};

const yupSchema = object().shape({
  contents: maximumLength(255),
});

const QnaCommentForm = ({ questionId, canComment }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<CommentFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      contents: '',
    },
  });
  const { mutate: answer, isPending } = usePostBoardQna(questionId);

  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const saveComment = (values: CommentFormType) => {
    if (!questionId) return;
    const params = { contents: values.contents };
    answer(params, {
      onSuccess: () => reset(),
      onError: () =>
        setErrorBackStep({
          to: `/more-details/qna/${questionId}`,
          options: { replace: true, state: { permissionDenied: true } },
        }),
    });
  };

  if (!canComment) return null;
  return (
    <Container onSubmit={handleSubmit(saveComment)}>
      <Textarea
        {...register('contents')}
        rows={1}
        autoHeight
        maxHeight={92}
        placeholder={QNA_DETAIL_TEXT.placeholder}
        key={`${isPending}`} // pending 후 초기화될 수 있도록 key 적용
      />
      <SendButton type="submit" disabled={!isDirty || isPending}>
        <Icon name="top" color="white" />
      </SendButton>
    </Container>
  );
};

export default QnaCommentForm;

const Container = styled.form`
  position: fixed;
  bottom: 0;
  padding: 16px 20px;
  width: 100%;
  background-color: ${theme.color.white};
  box-shadow: 0px -2px 3px 0px rgba(145, 145, 145, 0.12);
  ${theme.flex('row', 'end', '', 12)}
  .textarea {
    flex-grow: 1;
  }
  z-index: ${Z_INDEX.footerButton};
`;

const SendButton = styled(IconButton)`
  flex: 0 0 auto;
  padding: 10px;
  background-color: ${theme.color.point};
  border-radius: 50%;

  :disabled {
    background-color: ${theme.color.gray7};
    svg {
      color: ${theme.color.gray4} !important;
    }
  }
`;

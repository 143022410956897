import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom, currentPeriodAtom } from 'recoil/schedule';
import filters from 'utils/filters';

type Props = {
  currentDate: Date;
  setCurrentDate: Dispatch<SetStateAction<Date>>;
  currentMonth: string;
};

const TodayButton = ({ currentDate, setCurrentDate, currentMonth }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const currentPeriod = useRecoilValue(currentPeriodAtom);

  const isToday = useMemo(() => {
    if (calendarViewType === 'day') return filters.dateDash() === filters.dateDash(currentDate);

    if (calendarViewType === 'month') {
      const start = dayjs(currentMonth);
      const end = dayjs(currentMonth).endOf('month');
      return dayjs().isBetween(start, end, 'month', '[]');
    }

    const { startDate, endDate } = currentPeriod;
    return dayjs().isBetween(startDate, endDate, 'day', '[]');
  }, [calendarViewType, currentDate, currentPeriod, currentMonth]);

  return (
    <Container ref={containerRef} className={isToday ? 'hidden' : ''}>
      <Button
        className="today-button"
        size="medium40"
        edge="circular"
        fontSize={14}
        fontWeight={700}
        textColor="primary"
        widthSize={82}
        padding={{
          left: 16,
          right: 16,
        }}
        onClick={() => setCurrentDate(new Date())}>
        오늘로 가기
      </Button>
    </Container>
  );
};

export default TodayButton;

const Container = styled.div`
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
  background: ${theme.color.white};
  border-radius: 999px;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.2);
  z-index: ${Z_INDEX.floatingButton};
  opacity: 1;
  transition: opacity 0.1s ease;
  visibility: visible;

  &.hidden {
    opacity: 0;
  }

  .today-button {
    width: max-content;
  }
`;

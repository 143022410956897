import styled from '@emotion/styled';
import SubTitle from 'components/SubTitle';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

type Props = {
  children: ReactNode;
};
const HeaderAndTitle = ({ children }: Props) => {
  const { watch } = useFormContext<ChangePhoneNumberFormType>();
  const step = watch('step');

  return (
    <MainLayout header={{ title: CHANGE_PHONE_NUMBER_TEXT[step].title, titleScrollThreshold: 33 }}>
      <Container>
        <SubTitle
          title={CHANGE_PHONE_NUMBER_TEXT[step].title}
          message={{
            text: CHANGE_PHONE_NUMBER_TEXT[step].description,
            position: 'bottom',
          }}
          bottom={40}
        />
        {children}
      </Container>
    </MainLayout>
  );
};

export default HeaderAndTitle;

const Container = styled.div`
  padding: 8px 20px;
  footer {
    background-color: unset;
  }
`;

import usePostLectureGroup from 'hooks/service/mutations/usePostLectureGroup';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { formatBookingGroupParams } from 'pages/Booking/utils';
import { useNavigate } from 'react-router-dom';

import { BookingGroupCreateFormType } from '../../types';
import CommonGroupForm from './CommonGroupForm';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
};

const GroupCommonCreateForm = ({ pageMode }: Props) => {
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const { mutate: createGroupLecture } = usePostLectureGroup();

  const submit = (values: BookingGroupCreateFormType) => {
    const params = formatBookingGroupParams(values);
    createGroupLecture(params, {
      onSuccess: () => {
        const backStep = pageMode === 'copy' ? -2 : -1;
        navigate(backStep);
        setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.create, bottom: 76 });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(
            <FailAllBooking
              fails={error.response?.data}
              closePopup={() => {
                if (pageMode === 'create') {
                  navigate(-1);
                } else {
                  navigate('/schedule', { replace: true });
                }
              }}
            />,
          );
          return false;
        }
      },
    });
  };

  return (
    <CommonGroupForm pageMode={pageMode} onSubmit={submit}>
      <BookingRangeDate isRange isInitialOpen={pageMode === 'copy'} />
      <BookingRangeTime pageMode="create" />
    </CommonGroupForm>
  );
};

export default GroupCommonCreateForm;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import { PropsWithChildren } from 'react';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

type Props = {
  className?: string;
};

const CheckInFieldButton = ({ children, className }: PropsWithChildren<Props>) => {
  const { getAllSearchParams, setSearchParams } = useQueryString();
  const clickField = () => {
    setSearchParams({
      ...getAllSearchParams(),
      type: 'checkInTime',
    });
  };

  return (
    <StyledAvailableTimeFieldButton
      showBorder={false}
      className={`check-in-field-button ${className ?? ''}`}
      iconName="checkIn2"
      gap={12}
      onClick={clickField}>
      <Typography span textColor="gray2">
        체크인 가능 시간
      </Typography>
      <Typography span size={15} weight={500}>
        {children}
      </Typography>
    </StyledAvailableTimeFieldButton>
  );
};

export default CheckInFieldButton;

export const StyledAvailableTimeFieldButton = styled(FormFieldLinkButton)`
  height: 73px;

  .link-button-contents-wrapper {
    ${theme.flex('column', '', '', 2)};
  }
`;

import { useMutation } from '@tanstack/react-query';
import { RegisterDataType } from 'pages/Join/types';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostJoin = () => {
  const { mutationFn } = useMutationFnWithCommonParams<RegisterDataType>({
    method: 'post',
    url: 'api/auth/join',
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostJoin;

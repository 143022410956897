import { atom } from 'recoil';

import { MediaPermissionType } from './type';

export const mediaPermissionAtom = atom<MediaPermissionType>({
  key: 'mediaPermissionAtom',
  default: {
    camera: 'unavailable',
    photoLibrary: 'unavailable',
  },
});

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { PERMISSION } from 'constants/permission';
import usePatchBookingGroup from 'hooks/service/mutations/usePatchBookingGroup';
import usePatchBookingGroupCancelWaiting from 'hooks/service/mutations/usePatchBookingGroupCancelWaiting';
import usePatchBookingPrivate, { ChangePrivateBookingStatusParams } from 'hooks/service/mutations/usePatchBookingPrivate';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { BookingStatusType, BookingType } from 'pages/Booking/types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';

import CancelBookingDialog from './CancelBookingDialog';
import ChangeBookingStatusDrawer from './ChangeBookingStatusDrawer';
import MemberCard from './MemberCard';

type Props = {
  isMe: boolean;
  booking: LectureBookingListResponse;
  bookingType: BookingType;
  currentBookingTraineeCount: number;
  waitingOrder?: number;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
};

const BookingListItem = ({
  isMe,
  booking,
  bookingType,
  currentBookingTraineeCount,
  waitingOrder,
  canCancelBooking,
  canChangeBooking,
}: Props) => {
  const { pathname } = useLocation();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);
  const [currentMemberBooking, setCurrentMemberBooking] = useState<LectureBookingListResponse | null>(null);
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const { mutate: changePrivateBookingStatusMutate } = usePatchBookingPrivate(booking);
  const { mutate: changeGroupBookingStatusMutate } = usePatchBookingGroup(booking);
  const { mutate: cancelGroupWaitingMutate } = usePatchBookingGroupCancelWaiting(booking.id);
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const { checkPermission } = useGetPermissionDoHavePermission();

  const staffType = isMe ? 'mine' : 'others';

  const clickBookingStatusButton = (booking: LectureBookingListResponse) => {
    const onSuccess = () => {
      setCurrentMemberBooking(booking);
      setIsOpenDrawer(true);
    };

    checkPermission(PERMISSION.lecture[staffType].editBookings[bookingType].id, {
      onSuccess,
      onError: () => {
        checkPermission(PERMISSION.lecture[staffType].cancelBookings[bookingType].id, {
          onSuccess,
          onError: () => {
            if (isMe) {
              setErrorBackStep({
                to: pathname,
                options: { replace: true },
              });
            } else {
              checkPermission(PERMISSION.lecture.others.view[bookingType].id, {
                onSuccess: () => {
                  setErrorBackStep({
                    to: '/schedule',
                    options: { replace: true },
                  });
                },
              });
            }
            return true;
          },
        });
        return true;
      },
    });
  };

  const mutateCallback = (message?: string) => {
    return {
      onSettled: () => setIsOpenDrawer(false),
      onSuccess: () => {
        setToast({ type: 'success', message: message ?? '예약 상태가 변경되었습니다.' });
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    };
  };

  const changeBookingStatus = (params: ChangePrivateBookingStatusParams) => {
    if (bookingType === 'private') {
      changePrivateBookingStatusMutate(params, mutateCallback());
    } else {
      changeGroupBookingStatusMutate(params, mutateCallback());
    }
  };

  const clickDrawerItem = (value: BookingStatusType['value']) => {
    if (value === 'cancel') {
      setIsOpenCancelDialog(true);
    } else if (value === 'waiting_cancel') {
      cancelGroupWaitingMutate(undefined, mutateCallback('예약대기가 취소되었습니다.'));
    } else {
      changeBookingStatus({ status: value });
    }
  };

  return (
    <Container>
      <MemberCard
        booking={booking}
        waitingOrder={waitingOrder}
        canCancelBooking={canCancelBooking}
        canChangeBooking={canChangeBooking}
        onClickStatusChange={clickBookingStatusButton}
      />

      {currentMemberBooking && (
        <ChangeBookingStatusDrawer
          isMe={isMe}
          bookingType={bookingType}
          isOpen={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
          booking={currentMemberBooking}
          canCancelBooking={canCancelBooking}
          canChangeBooking={canChangeBooking}
          onClick={clickDrawerItem}
        />
      )}

      {isOpenCancelDialog && currentMemberBooking && (
        <CancelBookingDialog
          bookingType={bookingType}
          currentMemberBooking={currentMemberBooking}
          currentBookingTraineeCount={currentBookingTraineeCount}
          onClose={() => setIsOpenCancelDialog(false)}
        />
      )}
    </Container>
  );
};

export default BookingListItem;

const Container = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray8};
  }
`;

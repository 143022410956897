import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 알림용, 푸시 메세지 안읽음 개수  */
const useGetStaffNoticeCommonNoReadCount = () => {
  const { queryFn } = useQueryFnWithCommonParams<StaffNoticeCommonNoReadCountResponse>({
    url: `/api/staff/notice/common-no-read-count`,
  });

  return useSuspenseQuery({
    queryKey: ['staff/notice', 'common', 'no-read-count'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetStaffNoticeCommonNoReadCount;

type StaffNoticeCommonNoReadCountResponse = {
  is_checked_common_false_count: number;
};

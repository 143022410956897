import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import PaymentTextField from './PaymentTextField';
import { PaymentRow } from './StyledComponents';

const Unpaid = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<TicketPaymentFormType>();
  const amount = useWatch({ control, name: 'payment.amount' });
  const discount = useWatch({ control, name: 'payment.discount' });
  const isAutoWriteUnpaid = useWatch({ control, name: 'isAutoWriteUnpaid' });

  /**
   * 총결제금액(amount = 카드 + 현금 + 계좌이체 + 보유 포인트 입력 금액)이
   * 판매가에 할인율 적용한 금액 보다 적을 때, 미수금에 차액 자동 적용
   */
  useEffect(() => {
    if (!isAutoWriteUnpaid) return;
    if (!dirtyFields.payment?.amount) return;
    const salePrice = Number(getValues('salePrice') || 0);
    const discountedAmount = Math.ceil(salePrice * (1 - discount / 100));

    if (amount < discountedAmount) {
      const diff = discountedAmount - amount;
      setValue('payment.unpaid_amount', diff, { shouldDirty: true });
    } else {
      setValue('payment.unpaid_amount', 0, { shouldDirty: true });
    }
  }, [amount, dirtyFields.payment?.amount, discount, getValues, isAutoWriteUnpaid, setValue]);

  /**
   * 할인율을 입력하면 카드와 총결제금액에 자동 반영되면서
   * 미수금 0원으로 세팅
   */
  useEffect(() => {
    if (!dirtyFields.payment?.discount) return;
    if (discount) {
      setValue('payment.unpaid_amount', 0, { shouldDirty: true });
    }
  }, [dirtyFields.payment?.discount, discount, setValue]);

  return (
    <PaymentRow>
      <PaymentTextField
        control={control}
        setValue={setValue}
        keyValue={'payment.unpaid_amount'}
        textColor="secondary3"
        left={{
          prefix: <Icon name={'roomCancel'} fillColor={theme.color.gray2} />,
          text: TICKET_PAYMENT_FORM_TEXT.unpaid.label,
        }}
      />
    </PaymentRow>
  );
};

export default Unpaid;

import styled from '@emotion/styled';
import { Dispatch, RefObject, SetStateAction, useEffect, useMemo, useRef } from 'react';

import TabBase, { TabBaseProps } from './TabBase';

export type TabProps = {
  /** Header 컴포넌트에서 사용되는 Tab 일 때 */
  isHeaderType?: boolean;
  /** true일 때, 선택된 상태 */
  isSelected: boolean;
  /** 선택된 Tab의 indicator 스타일 적용을 위한 width, left 값 setter */
  setSelectedTab: Dispatch<SetStateAction<RefObject<HTMLButtonElement> | undefined>>;
} & TabBaseProps;

const Tab = ({ isSelected, setSelectedTab, isHeaderType, fullWidth, ...props }: TabProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const fontWeight = useMemo(() => {
    if (isHeaderType) return isSelected ? 700 : 600;
    return isSelected ? 600 : 500;
  }, [isHeaderType, isSelected]);

  const textColor = useMemo(() => {
    if (isSelected) return 'gray1';
    return isHeaderType ? 'gray4' : 'gray3';
  }, [isHeaderType, isSelected]);

  const lineHeight = useMemo(() => (isHeaderType ? 24 : 22), [isHeaderType]);

  const fontSize = useMemo(() => (isHeaderType ? 16 : 15), [isHeaderType]);

  const padding = useMemo(() => {
    if (!fullWidth) return undefined;
    if (isHeaderType) return undefined;

    return { left: 8, right: 8 };
  }, [fullWidth, isHeaderType]);

  useEffect(() => {
    if (!isSelected) return;

    setSelectedTab(buttonRef);
  }, [setSelectedTab, isSelected]);

  return (
    <StyledTabButton
      ref={buttonRef}
      {...props}
      fullWidth={fullWidth}
      fontWeight={fontWeight}
      textColor={textColor}
      fontSize={fontSize}
      lineHeight={lineHeight}
      heightSize={48}
      padding={padding}
    />
  );
};

const StyledTabButton = styled(TabBase)<Pick<TabProps, 'fullWidth'>>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ fullWidth }) => fullWidth && 'flex: 1 1 0px'};
`;

export default Tab;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon, { IconType } from 'components/Icon';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { useState } from 'react';

export type ActionsType = {
  label: string;
  icon: IconType;
  onClick: () => void;
};

export interface IFloatingActionButton {
  /**
   * multi 타입의 경우 버튼 눌렀을 때 여러 개의 버튼 선택 목록 노출
   * single 타입의 경우 버튼 눌렀을 때 onClick 동작
   */
  type?: 'multi' | 'single';
  /** single 타입일 때만 동작하는 함수 */
  onClick?: () => void;
  /** multi 타입일 때 여러 개의 버튼을 만드는 배열 값 */
  actions?: Array<ActionsType>;
  /** 버튼의 bottom 위치 값 (기본: 68px) */
  bottom?: number;
}

const FloatingActionButton = ({ type = 'multi', onClick, actions, bottom }: IFloatingActionButton) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => setOpen(!open);

  const handleClose = () => setOpen(false);

  return (
    <Container open={open} bottom={bottom}>
      <div className="overlay" onClick={handleClose} />
      <ul className="actions-wrapper" onClick={type === 'single' ? onClick : handleToggle}>
        <li className="fab-item">
          <Icon name="plusBold" size={20} fillColor={theme.color.white} />
        </li>
        {actions?.map(({ label, icon, onClick }, index) => (
          <Actions open={open} index={index} key={label} onClick={onClick}>
            <Icon name={icon} />
            <Typography span size={15} weight={500}>
              {label}
            </Typography>
          </Actions>
        ))}
      </ul>
    </Container>
  );
};

const Container = styled.div<{ open: boolean; bottom?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ open }) => (open ? '100%' : 0)};
  height: ${({ open }) => (open ? '100%' : 0)};
  z-index: ${Z_INDEX.floatingButton};

  .overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.96);
    opacity: ${({ open }) => (open ? 1 : 0)};
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    transition: ${({ open }) => (open ? 'opacity 0.2s, visibility 1s' : 'opacity 0.8s, visibility 1s')};
  }

  .actions-wrapper {
    ${theme.flex('column-reverse', '', '')};
    position: fixed;
    bottom: ${({ bottom }) => bottom || 68}px;
    right: 20px;
    max-height: 52px;

    ${({ open }) => open && 'max-height: max-content'};
    -webkit-tap-highlight-color: transparent;

    li {
      border-radius: 50%;
      box-shadow: 1px 2px 6px rgba(145, 145, 145, 0.4);
      display: grid;
      place-items: center;
      margin-top: 12px;
      min-width: 48px;
      min-height: 48px;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;
    }

    .fab-item {
      transition: all 0.4s;
      background-color: ${theme.color.point};
      transform: ${({ open }) => open && 'rotate(135deg)'};

      &:active {
        background-color: #20252c;
      }
    }
  }
`;

const Actions = styled.li<{ open: boolean; index: number }>`
  transform: translateY(50px) scale(0);
  transition:
    transform 300ms,
    opacity 300ms;
  opacity: 0;
  background-color: #fff;
  transition-delay: ${({ index }) => `${index * 60}ms`};

  ${({ open }) =>
    open &&
    css`
      transform: translateY(0) scale(1);
      opacity: 1;
    `}

  span {
    position: absolute;
    width: max-content;
    left: -12px;
    transform: translateX(-100%);
  }
`;

export default FloatingActionButton;

import dayjs from 'dayjs';
import { atom, selector } from 'recoil';

import { EventScheduleDrawerAtomType, StaffListPageAtomType, TargetStaffAtomType } from './types';

// staff 공용
export const listHeaderTotalAtom = atom({
  key: 'listHeaderTotalAtom',
  default: 0,
});

export const assignedMemberTotalAtom = atom({
  key: 'assignedMemberTotalAtom',
  default: 0,
});

export const targetStaffAtom = atom<TargetStaffAtomType>({
  key: 'targetStaffAtom',
  default: {
    id: null,
    name: '',
  },
});

// 강사목록
export const staffListPageAtom = atom<StaffListPageAtomType>({
  key: 'staffListPageAtom',
  default: {
    search: '',
    sort: 'asc',
  },
});

// 특정일 근무시간 목록
export const eventScheduleSortAtom = atom<'asc' | 'desc'>({
  key: 'eventScheduleSortAtom',
  default: 'asc',
});

// 강사상세 - 특정일 근무시간
export const eventScheduleSelectDateAtom = atom({
  key: 'eventScheduleSelectDateAtom',
  default: [new Date()],
});

export const eventScheduleSelectDateSelector = selector({
  key: 'eventScheduleSelectDateSelector',
  get: ({ get }) => {
    const result = get(eventScheduleSelectDateAtom);
    return result.length
      ? result.map(date => {
          return dayjs(date).format('YYYY-MM-DD');
        })
      : [];
  },
});

export const eventScheduleDrawerAtom = atom<EventScheduleDrawerAtomType>({
  key: 'eventScheduleDrawerAtom',
  default: { show: false, id: 0 },
});

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useGetHistoryBooking from 'hooks/service/queries/useGetHistoryBooking';
import { BOOKING_TYPE } from 'pages/MemberDetail/constants';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

type Props = {
  selectedBookingId: number;
};
const PastHistoryList = ({ selectedBookingId }: Props) => {
  const { data } = useGetHistoryBooking(selectedBookingId);

  return (
    <>
      {data.map(bookingHistory => (
        <HistoryCard key={bookingHistory.id}>
          <InfoFlexWrapper>
            <InfoFlexRow className="flex-row-first">
              <Typography span size={15} weight={600}>
                {filters.dateTimeWithWeekday(bookingHistory.act_at || undefined)}
              </Typography>
              <Typography span weight={600} textColor={bookingHistory.status === 'cancel' ? 'secondary3' : 'gray2'}>
                {BOOKING_TYPE[bookingHistory.status]}
              </Typography>
            </InfoFlexRow>
            <InfoFlexRow>
              <InfoTypography>변경한 사람</InfoTypography>
              <InfoTypography weight={500} textColor="gray2">
                {bookingHistory.act_by}
              </InfoTypography>
            </InfoFlexRow>
            <InfoFlexRow>
              <InfoTypography>사유</InfoTypography>
              <InfoTypography weight={500} textColor="gray2">
                {bookingHistory.created_for || '-'}
              </InfoTypography>
            </InfoFlexRow>
          </InfoFlexWrapper>
        </HistoryCard>
      ))}
    </>
  );
};

export default PastHistoryList;

const HistoryCard = styled.li`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${theme.color.gray6};

  .flex-row-first {
    margin-bottom: 6px;
  }
`;

import axios from '../axios';

const apiAttachment = {
  post: (type: string, file: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axios.post<AttachmentType>(`/api/attachment/${type}`, file, { headers });
  },
};

export type AttachmentType = {
  id: number;
  ref_type: string;
  ref_id: number;
  path: {
    path: string;
    name: string;
    extension: string;
  };
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
};

export default apiAttachment;

import styled from '@emotion/styled';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useQueryString from 'hooks/useQueryString';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import Room from 'pages/Booking/components/Room';
import ConfirmNoRoomDialog from 'pages/Booking/components/Room/ConfirmNoRoomDialog';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import convertTargetsText from 'utils/convertTargetsText';

import { BOOKING_PRIVATE_FORM_TEXT } from '../../constants';
import { BookingPrivateFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';
import NextButton from './NextButton';
import StyledPrivateForm from './StyledPrivateForm';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
  currentLecture?: LectureDetailResponse;
};

const PrivateCommonCreateForm = ({ pageMode, currentLecture }: Props) => {
  const { handleSubmit, getValues, setValue } = useFormContext<BookingPrivateFormType>();
  const [isOpenNoRoomDialog, setIsOpenNoRoomDialog] = useState(false);

  const { setSearchParams, getAllSearchParams } = useQueryString();

  const goNextPage = () => {
    /** 수업명 입력하지 않았을 때, 수강회원 이름으로 세팅 */
    if (!getValues('title')) {
      const member = getValues('selectedUserTickets').map(({ member }) => member);
      const title = member.length === 1 ? `${member[0].name} 님` : convertTargetsText(member);
      setValue('title', title, { shouldDirty: true });
    }

    setSearchParams({
      ...getAllSearchParams(),
      step: '2',
    });
  };

  const submit = (values: BookingPrivateFormType) => {
    const { roomId, selectedRoom } = values;

    /** 룸 선택 안함을 수동 클릭한 경우는 다음 버튼 누를 경우 확인 팝업 노출하지 않음 */
    if (!selectedRoom && roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value) {
      setIsOpenNoRoomDialog(true);
      return;
    }
    goNextPage();
  };

  return (
    <>
      <FormContainer onSubmit={handleSubmit(submit)}>
        <SelectStaff lectureStaff={currentLecture?.staff} />
        <BookingRangeDate isRange isInitialOpen={pageMode === 'copy'} />
        <BookingRangeTime pageMode="create" />
        <RepeatField />
        <Room />
        <LectureSize />
        <SelectMembersField />
        <ClassTitle description={BOOKING_PRIVATE_FORM_TEXT.title.description} />
        <NextButton />
      </FormContainer>

      {isOpenNoRoomDialog && (
        <ConfirmNoRoomDialog
          onClick={() => {
            setIsOpenNoRoomDialog(false);
            goNextPage();
          }}
          onClose={() => setIsOpenNoRoomDialog(false)}
        />
      )}
    </>
  );
};

export default PrivateCommonCreateForm;

const FormContainer = styled(StyledPrivateForm)`
  .underline-textarea {
    border-bottom: none;
  }
`;

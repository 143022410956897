import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { TicketCommon } from './useInfinityUserTicketProduct';

/** 그룹 수업 상세페이지에서 예약 회원 추가 시, 예약 가능한 수강권 목록 */
const useInfinityUserTicketLecture = (params: UserTicketLectureParams & { lectureId: number }) => {
  const { lectureId, ...rest } = params;

  const { queryFn } = useInfiniteQueryFnWithCommonParams<UserTicketLectureResponse, UserTicketLectureParams>({
    url: `/api/userTicket/lecture/${lectureId}`,
    params: {
      ...rest,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`userTicket/lecture`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityUserTicketLecture;

export type UserTicketLectureParams = {
  search?: string;
  start_date: string;
  end_date: string;
};

export type UserTicketLectureResponse = {
  id: number;
  is_shared: boolean;
  member: {
    id: number;
    name: string;
    account_type: string | null;
    account_id: number | null;
    mobile: string | null;
    image: {
      path: string;
      name: string;
      extension: string;
    } | null;
    vaccination_yn: 'Y' | 'N';
    deleted_at: string | null;
  };
  booking_limit_per_week: number;
  booking_limit_per_month: number;
  ticket: Pick<TicketCommon, 'title' | 'type'>;
  usable_coupon: number;
  availability_start_at: string;
  expire_at: string;
  created_at: string;
  updated_at: string;
};

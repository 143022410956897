import { Suspense, SuspenseProps } from 'react';

import ApiErrorBoundary from './components/ApiErrorBoundary';

const ApiBoundary = ({ children, fallback }: SuspenseProps) => {
  return (
    <ApiErrorBoundary>
      <Suspense fallback={fallback}>{children}</Suspense>
    </ApiErrorBoundary>
  );
};

export default ApiBoundary;

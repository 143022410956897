import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import SubTitle from 'components/SubTitle';
import Typography from 'components/Typography';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';

import ReportPassword from './components/ReportPassword';

const MoreDetailReportPassword = () => {
  const navigate = useNavigate();
  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <Container>
        <SubTitle
          title={
            <TitleSection>
              <Typography size={15} weight={500} lineHeight={22}>
                🔒
              </Typography>
              <Typography size={19} weight={700} lineHeight={26} textColor="gray1">
                매출 비밀번호
              </Typography>
              <Typography size={14} weight={400} lineHeight={18} textColor="gray2">
                매출 페이지 접근을 위한 비밀번호를 입력해 주세요.
              </Typography>
            </TitleSection>
          }
        />
        <ReportPassword />
      </Container>
    </MainLayout>
  );
};

export default MoreDetailReportPassword;

const Container = styled.div`
  padding: 0px 20px;
`;

const TitleSection = styled.section`
  ${theme.flex('column', '', '', 4)}
  padding-top: 8px;
  padding-bottom: 40px;
`;

import usePostUserTicket from 'hooks/service/mutations/usePostUserTicket';
import { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useQueryString from 'hooks/useQueryString';
import useToast from 'hooks/useToast';
import { useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { errorBackStepAtom, staffIdAtom } from 'recoil/common';
import { currentMemberAtom } from 'recoil/MemberDetail';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { CustomError } from 'types/errorTypes';

import ProductCreateUpdateForm from './components/ProductCreateUpdateForm';
import SelectTicket from './components/SelectTicket';
import { PRODUCT_TYPE_TEXT } from './constants';
import { ProductCreateFormType, ProductFormType } from './types';
import { getCreateTicketDefaultValues, getCreateTicketParams } from './utils';

/**
 * 회원 상세 페이지에서 수강권 추가할 때
 */
const ProductForm = () => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const currentMember = useRecoilValue(currentMemberAtom);
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const { setToast } = useToast();
  const navigate = useNavigate();

  const { getAllSearchParams } = useQueryString();
  const queryStringParsed = getAllSearchParams();

  const currentMemberId = Number(queryStringParsed.memberId);
  const productType = queryStringParsed.productType as Exclude<UserTicketParams['product_type'], 'all'>;

  const methods = useForm<ProductFormType>({
    defaultValues: {
      user_id: currentMemberId,
      search: '',
      tempSearchKeyword: '',
      selectedTicket: null,
    },
  });
  const { control, getValues } = methods;
  const selectedTicket = useWatch({ control, name: 'selectedTicket' });

  const ticketWithCurrentPoint = useMemo(() => {
    if (!selectedTicket || !currentMember) return;

    const { reward_point, paid_point } = currentMember.profile;
    return getCreateTicketDefaultValues({
      ticket: selectedTicket,
      currentMemberPoint: reward_point + paid_point,
    });
  }, [currentMember, selectedTicket]);

  const headerTitle = useMemo(() => {
    if (queryStringParsed.step === '1') return;
    return `${PRODUCT_TYPE_TEXT[productType]} 발급`;
  }, [productType, queryStringParsed.step]);

  const { mutate: createTicketMutate } = usePostUserTicket();

  /** 수강권(상품) 발급 요청 */
  const submit = async (values: ProductCreateFormType) => {
    const { user_id } = getValues();
    const { ticket } = values;

    const ticketsParamsArray = getCreateTicketParams({
      tickets: [values],
      currentMemberId: user_id,
      currentStaffId: currentStaffId,
      currentStudioId: ticket.studio_id,
    });

    createTicketMutate(
      { user_tickets: ticketsParamsArray },
      {
        onSuccess: () => {
          setToast({ type: 'success', message: `${PRODUCT_TYPE_TEXT[productType]}이 발급되었습니다.` });
          navigate(-2);
        },
        onError: err => {
          setErrorBackStep(-2);
          const error = err as CustomError;
          const noPermission = error.response?.data?.code === 35000;
          if (noPermission) {
            return false;
          }
        },
      },
    );
  };

  return (
    <MainLayout header={{ title: headerTitle }}>
      <FormProvider {...methods}>
        {queryStringParsed.step === '1' && <SelectTicket productType={productType} />}
        {queryStringParsed.step === '2' && ticketWithCurrentPoint && (
          <ApiBoundary>
            <ProductCreateUpdateForm ticketWithCurrentPoint={ticketWithCurrentPoint} onSubmit={submit} />
          </ApiBoundary>
        )}
      </FormProvider>
    </MainLayout>
  );
};

export default ProductForm;

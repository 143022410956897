import styled from '@emotion/styled';
import useGetPaymentUserTicket from 'hooks/service/queries/useGetPaymentUserTicket';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { Payment } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useQueryString from 'hooks/useQueryString';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { selectedPaymentAtom } from 'recoil/TicketDetail';
import { CardWrapper } from 'sharedComponents/InfoTypography';
import NodataTypography from 'sharedComponents/NodataTypography';
import getPaymentDetail from 'utils/getPaymentDetail';

import getIsUnpaid from '../utils/getIsUnpaid';
import PaymentDetailCard from './PaymentDetailCard';
import UnPaidDeleteDialog from './UnPaidDeleteDialog';

const PaymentList = ({ canUpdateUserTicket, userTicket }: UserTicketProps) => {
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const { getAllSearchParams, setSearchParams } = useQueryString();
  const [selectedPayment, setSelectedPayment] = useRecoilState(selectedPaymentAtom);
  const { checkPermission } = useGetPermissionDoHavePermission();
  const navigate = useNavigate();

  const goToEditForm = (mode: 'update' | 'unpaid', payment: Payment) => {
    setSelectedPayment(payment);
    setSearchParams({
      ...getAllSearchParams(),
      mode,
      paymentId: `${payment.id}`,
    });
  };

  const clickDeleteButton = (payment: Payment) => {
    setIsOpenDeleteDialog(true);
    setSelectedPayment(payment);
  };

  const checkPermissionFirst = ({ onSuccess, onError }: { onSuccess: () => void; onError?: () => void }) => {
    checkPermission(MEMBER_PERMISSION.memberTicketEdit.id, {
      onSuccess,
      onError,
    });
  };

  const { data: payments } = useGetPaymentUserTicket({
    userTicketId: userTicket.id,
    user_id: userTicket.user_id,
  });

  return (
    <>
      {!payments.length ? (
        <NodataTypography>결제내역이 없습니다.</NodataTypography>
      ) : (
        <CardListWrapper>
          {payments.map((payment, index) => {
            const { unpaid_amount, status, settlement_at, amount } = payment;
            const { data } = getPaymentDetail(
              payment,
              getIsUnpaid(status) ? amount + unpaid_amount : userTicket.ticket.actual_price,
            );

            const showUnPaidDelete =
              canUpdateUserTicket &&
              (status === 'full_payment' || (status === 'installment_payment' && !userTicket.payments.unpaid_amount_total));
            const showUnPaidPayment =
              canUpdateUserTicket &&
              status !== 'refund' &&
              (unpaid_amount || (status === 'installment_payment' && userTicket.payments.unpaid_amount_total));

            const leftButton = {
              children: showUnPaidDelete ? '삭제' : showUnPaidPayment ? '미수금 결제' : '',
              onClick: () =>
                checkPermissionFirst({
                  onSuccess: () => {
                    if (showUnPaidDelete) {
                      clickDeleteButton(payment);
                    }
                    if (showUnPaidPayment) {
                      goToEditForm('unpaid', payment);
                    }
                  },
                  onError: () => navigate(-1),
                }),
            };

            // 가장 최신 결제 내역만 수정 버튼 노출
            const clickUpdateButton =
              canUpdateUserTicket && index === 0
                ? () =>
                    checkPermissionFirst({
                      onSuccess: () => goToEditForm('update', payment),
                      onError: () => navigate(-1),
                    })
                : undefined;

            return (
              <PaymentDetailCard
                key={payment.id}
                data={data}
                settlementAt={settlement_at}
                leftButton={showUnPaidDelete || showUnPaidPayment ? leftButton : null}
                onClick={clickUpdateButton}
              />
            );
          })}
        </CardListWrapper>
      )}

      {isOpenDeleteDialog && selectedPayment && (
        <UnPaidDeleteDialog paymentId={selectedPayment.id} onClose={() => setIsOpenDeleteDialog(false)} />
      )}
    </>
  );
};

export default PaymentList;

const CardListWrapper = styled(CardWrapper)`
  padding: 20px;
`;

import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { MemberCounselParams, MemberCounselResponse } from './useInfinityMemberCounsel';

/** 필터된 상담고객 전체 데이터 가져오기 */
const useGetAllMemberCounsel = (params: MemberCounselParams, enabled: boolean) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllMemberCounselResponse, AllMemberCounselParams>({
    url: `/api/member/counsel`,
    params: { ...params, limit: 200 },
  });

  return useQuery({
    queryKey: [`member/counsel`, { ...params }, 'all', enabled],
    queryFn,
    enabled,
    select: data => data.data,
  });
};

export default useGetAllMemberCounsel;

type AllMemberCounselParams = MemberCounselParams & { limit: number };
type AllMemberCounselResponse = MemberCounselResponse[];

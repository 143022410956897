import styled from '@emotion/styled';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Drawer from 'components/Drawer';
import Typography from 'components/Typography';
import useDeleteStaff from 'hooks/service/mutations/useDeleteStaff';
import useToast from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { targetStaffAtom } from 'recoil/staff';

type Props = {
  isDrawerOpen: boolean;
  onClose: () => void;
};

const DeleteStaffDrawer = ({ isDrawerOpen, onClose }: Props) => {
  const targetStaff = useRecoilValue(targetStaffAtom);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { mutate: deleteStaffMutate } = useDeleteStaff(targetStaff.id);

  const deleteStaff = () => {
    deleteStaffMutate(undefined, {
      onSettled: () => {
        onClose();
      },
      onSuccess: () => {
        navigate('/more-details/staff');
        setToast({ type: 'success', message: `${targetStaff.name} 강사가 삭제되었습니다.` });
      },
    });
  };

  return (
    <Drawer isOpen={isDrawerOpen} onClose={onClose}>
      <DrawerContainer>
        <Typography className="title" size={19} weight={700}>
          [{targetStaff.name}] 강사를
          <br />
          삭제하시겠습니까?
        </Typography>

        <Typography size={15}>
          담당강사로 지정된 그룹 수업이 있는 경우,
          <br />
          강사를 삭제할 수 없습니다. (프라이빗 수업 제외)
        </Typography>

        <ButtonGroup leftButtonWidth={115}>
          <Button fontSize={15} size="large56" variant="outlined" onClick={onClose}>
            취소
          </Button>
          <Button fontSize={15} size="large56" variant="contained" color="point" fullWidth onClick={deleteStaff}>
            삭제
          </Button>
        </ButtonGroup>
      </DrawerContainer>
    </Drawer>
  );
};

export default DeleteStaffDrawer;

const DrawerContainer = styled.div`
  padding: 11px 20px 0;

  .title {
    margin-bottom: 20px;
  }

  .button-group {
    margin-top: 32px;
    padding: 10px 0;
  }
`;

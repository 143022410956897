import CheckBox from 'components/CheckBox';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import ApplyAllAmountButton from './ApplyAllAmountButton';
import PaymentTextField from './PaymentTextField';
import { PaymentCheckboxRow } from './StyledComponents';

const Card = () => {
  const {
    control,
    setValue,
    setFocus,
    formState: { touchedFields },
  } = useFormContext<TicketPaymentFormType>();
  const isCheckedCard = useWatch({ control, name: 'isCheckedCard' });

  useEffect(() => {
    if (!touchedFields.isCheckedCard) return;
    if (isCheckedCard) {
      setFocus('payment.card_amount');
    }
  }, [isCheckedCard, setFocus, touchedFields.isCheckedCard]);

  return (
    <>
      <PaymentCheckboxRow>
        <div className="left">
          <Controller
            name="isCheckedCard"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <CheckBox
                gap={10}
                id="card"
                label={TICKET_PAYMENT_FORM_TEXT.card.label}
                checked={value}
                value={'card'}
                onChange={e => {
                  if (!e.target.checked) {
                    setValue('payment.card_amount', 0, { shouldDirty: true });
                  }
                  setValue('isCheckedCard', e.target.checked, { shouldTouch: true });
                }}
                {...field}
              />
            )}
          />
        </div>
        {isCheckedCard && (
          <div className="apply-all">
            <ApplyAllAmountButton text={TICKET_PAYMENT_FORM_TEXT.card.label} keyValue="payment.card_amount" />
          </div>
        )}
        <div className="right">
          <PaymentTextField control={control} setValue={setValue} keyValue={'payment.card_amount'} disabled={!isCheckedCard} />
        </div>
      </PaymentCheckboxRow>
    </>
  );
};

export default Card;

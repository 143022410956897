import Divider from 'components/Divider';
import Form from 'components/Form';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { ComponentProps } from 'react';
import TicketCard from 'sharedComponents/TicketCard';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';
import {
  PaymentSubmitButton,
  SelectStaffs,
  ShareTicketSwitch,
  TicketCountForm,
  TicketFormContainer,
  TicketPaymentForm,
  TicketPeriodForm,
} from 'sharedComponents/TicketForm';

import { PRODUCT_FORM_TEXT } from '../constants';

type Props = Omit<ComponentProps<typeof Form>, 'children'> & {
  pageMode: 'create' | 'update';
  ticket: FormatTicketCard;
  type: TicketsResponse['type'];
  isShowCancelCount: boolean;
  isItem: boolean;
  disabled?: boolean;
};

const CommonProductForm = ({ pageMode, ticket, type, isShowCancelCount, isItem, disabled, ...props }: Props) => {
  return (
    <TicketFormContainer {...props}>
      <TicketCard ticket={ticket} />
      <Divider />
      <TicketPeriodForm ticketType={type} />
      <TicketCountForm ticketType={type} isShowCancelCount={isShowCancelCount} />
      {!isItem && <ShareTicketSwitch />}
      <SelectStaffs />
      <Divider />
      <TicketPaymentForm />
      <PaymentSubmitButton>{PRODUCT_FORM_TEXT.footerButton[pageMode]}</PaymentSubmitButton>
    </TicketFormContainer>
  );
};

export default CommonProductForm;

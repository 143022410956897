import dayjs from 'dayjs';
import { FilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { MobileSelectedFilterType, MobileStatusType } from './type';

export const MOBILE_LIST_TEXT = {
  periodDescription: (max: number) => `최대 ${max}개월까지 조회할 수 있습니다.`,
  overPeriod: (max: number) => `최대 ${max}개월까지 선택 가능합니다.`,
  empty: '문자 내역이 없습니다.',
  noPermission: '문자 메시지 조회 권한이 없습니다.',
};

export const MOBILE_FILTER_TAB_LABELS = {
  period: '이번달',
  message_type: '메시지 종류 전체',
  message_status: '메시지 상태 전체',
};

export const MOBILE_DEFAULT_PERIOD = {
  start: filters.dateDash(dayjs().startOf('month')),
  end: filters.dateDash(dayjs().endOf('month')),
};

export const MOBILE_FILTERS: FilterType[] = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      { label: '오늘', value: 'day' },
      { label: '이번주', value: 'isoWeek' },
      { label: '이번달', value: 'month' }, // 기본값 이번달
      { label: '직접선택', value: 'custom' },
    ],
    tabConverter: value => {
      const periodValue = value as MobileSelectedFilterType['period'];
      if (!periodValue || periodValue.type === 'month') return '이번달';
      if (periodValue.type === 'day') return '오늘';
      if (periodValue.type === 'isoWeek') return '이번주';
      return filters.period(periodValue.start, periodValue.end);
    },
  },
  {
    key: 'message_type',
    title: '메시지 종류',
    type: 'button',
    options: [
      { label: '전체', value: undefined },
      { label: 'SMS', value: 'SMS' },
      { label: 'LMS', value: 'LMS' },
    ],
  },
  {
    key: 'message_status',
    title: '메시지 상태',
    type: 'button',
    options: [
      { label: '전체', value: undefined },
      { label: '예약', value: 'request' },
      { label: '발송', value: 'success' },
    ],
  },
];

export const MOBILE_STATUS: MobileStatusType = {
  cancel: { label: '예약취소', color: 'secondary3', width: 54 },
  request: { label: '예약', color: 'secondary1', width: 40 },
};

export const MOBILE_FILTER_DEFAULT = {
  period: {
    type: 'month',
    start: filters.dateDash(dayjs().startOf('month')),
    end: filters.dateDash(dayjs().endOf('month')),
  },
};

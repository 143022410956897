import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 프라이빗 수업의 예약 취소
 */
const usePatchBookingPrivateCancel = (bookingId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'patch',
    url: `/api/booking/private/cancel/${bookingId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['booking'],
      });
    },
  });
};

export default usePatchBookingPrivateCancel;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import FilterTabs, { FilterTabsProps } from 'components/Tabs/FilterTabs';
import { useState } from 'react';

const FoldingFilterTabs = ({ ...props }: FilterTabsProps) => {
  const [isUnFolded, setIsUnFolded] = useState(false);

  return (
    <Container className="folding-filter-tabs" isUnFolded={isUnFolded}>
      <FilterTabs gap={6} selectedColor="point" padding={{ left: 16, right: 16 }} {...props} />

      <IconButton onClick={() => setIsUnFolded(!isUnFolded)}>
        <Icon name={isUnFolded ? 'arrowTop' : 'arrowBottom'} fillColor={theme.color.gray3} />
      </IconButton>
    </Container>
  );
};

export default FoldingFilterTabs;

const Container = styled.div<{ isUnFolded: boolean }>(
  css`
    position: relative;
    padding: 14px 48px 14px 0;
    background-color: ${theme.color.white};
    border-bottom: 1px solid ${theme.color.gray7};

    :after {
      content: '';
      width: 20px;
      height: 32px;
      position: absolute;
      top: 14px;
      right: 48px;
      background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }

    .tabs-wrapper {
      padding: 0 20px;
    }

    .icon-button {
      position: absolute;
      right: 20px;
      top: 16px;
      background-color: ${theme.color.white};
    }
  `,
  /** 폴딩 탭 펼쳤을 때 */
  ({ isUnFolded }) =>
    isUnFolded &&
    css`
      .tabs-wrapper {
        margin: 0;
        width: 100%;
        flex-wrap: wrap;

        .text-button {
          margin-bottom: 2px;
        }
      }
    `,
);

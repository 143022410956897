import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import { Controller, useFormContext } from 'react-hook-form';

import { StaffProfileFormType } from '../types';

const GetNotiAll = () => {
  const { control } = useFormContext<StaffProfileFormType>();

  return (
    <Controller
      name="profile.get_noti_all"
      control={control}
      render={({ field: { value, ...field } }) => (
        <Switch
          id="get_noti_all"
          label="모든 강사 알림받기"
          switchSize="small"
          gap={10}
          checked={value}
          leftIcon={<Icon name="alert" fillColor={theme.color.gray2} />}
          {...field}
        />
      )}
    />
  );
};

export default GetNotiAll;

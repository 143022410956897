import { useIsMutating } from '@tanstack/react-query';
import { TicketEditFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

const SubmitButton = () => {
  const {
    formState: { dirtyFields },
  } = useFormContext<TicketEditFormType>();
  const isDirty = !!Object.keys(dirtyFields).length;
  const isMutating = useIsMutating() > 0;

  return (
    <FooterButton type="submit" disabled={isMutating || !isDirty}>
      수정 완료
    </FooterButton>
  );
};

export default SubmitButton;

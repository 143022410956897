import useGetPointDetail from 'hooks/service/queries/useGetPointDetail';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Point } from 'sharedComponents/TicketForm';

type Props = {
  selectedMember: MemberResponse;
};

const SelectedMemberPoint = ({ selectedMember }: Props) => {
  const { setValue } = useFormContext<TicketTransferFormType>();
  const {
    data: { current_point },
  } = useGetPointDetail(selectedMember.id);

  useEffect(() => {
    setValue('remainingPoint', current_point, { shouldDirty: true });
  }, [current_point, setValue]);

  return <Point />;
};

export default SelectedMemberPoint;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import { InfoTypography } from 'sharedComponents/InfoTypography';
import { postMessageToApp } from 'utils/communicateWithNative';
import filters from 'utils/filters';

import { STUDIO_DETAIL_TEXTS } from '../../pages/MoreDetails/Studio/constants';
import StudioDetailOpenHours from './StudioDetailOpenHours';

type Props = {
  studioId: number;
};

const StudioDetailInformation = ({ studioId }: Props) => {
  const { data: studioInfo } = useGetStudioInfo(studioId);

  const studioDetails = {
    name: studioInfo.name,
    contact: studioInfo.contactInfos.find(c => c.is_representative)?.contact ?? null,
    address: studioInfo.address.address ? `${studioInfo.address.address} ${studioInfo.address?.detail_address ?? ''}` : null,
  };

  const callToStudio = () => {
    postMessageToApp('OPEN_URL', `tel:${studioDetails.contact}`);
  };

  return (
    <>
      <Contents>
        <InfoWrapper>
          <InfoTitle size={15} textColor="gray2">
            {STUDIO_DETAIL_TEXTS.contact}
          </InfoTitle>
          <InfoDetail size={15} textColor={`gray${studioDetails.contact ? '1' : '3'}`}>
            {studioDetails.contact ? filters.contact(studioDetails.contact) : STUDIO_DETAIL_TEXTS.notProvided}
          </InfoDetail>
        </InfoWrapper>
        <StudioDetailOpenHours title={STUDIO_DETAIL_TEXTS.openHours} openEvents={studioInfo.openEvents} />
        <InfoWrapper>
          <InfoTitle size={15} textColor="gray2">
            {STUDIO_DETAIL_TEXTS.address}
          </InfoTitle>
          <InfoDetail size={15} textColor={`gray${studioDetails.address ? '1' : '3'}`}>
            {studioDetails.address ?? STUDIO_DETAIL_TEXTS.notProvided}
          </InfoDetail>
        </InfoWrapper>
      </Contents>
      <BottomButton
        size="large56"
        variant="contained"
        color="point"
        fontSize={15}
        fontWeight={500}
        onClick={callToStudio}
        fullWidth>
        {STUDIO_DETAIL_TEXTS.call}
      </BottomButton>
    </>
  );
};

export default StudioDetailInformation;

const Contents = styled.div`
  width: 100%;
  padding: 12px 0 32px;
  ${theme.flex('column', '', '', 16)}
`;

const BottomButton = styled(Button)`
  margin: 10px 0;
`;

const InfoWrapper = styled.div`
  ${theme.flex('row', '', '', 24)}
`;

const InfoTitle = styled(InfoTypography)`
  opacity: 0.8;
  flex-shrink: 0;
`;

const InfoDetail = styled(Typography)`
  flex-grow: 1;
`;

import Divider from 'components/Divider';
import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import CounselPeriodFilter from './CounselPeriodFilter';
import StaffFilter from './StaffFilter';

const CounselWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  const staffFilterIndex = filters.length - 1;
  return (
    <>
      <CounselPeriodFilter
        currentFilter={filters[0]}
        selectedOption={get(filterChanged, filters[0].key)}
        onClick={changeOption}
      />
      <Divider />
      <ButtonFilter currentFilter={filters[1]} selectedOption={get(filterChanged, filters[1].key)} onClick={changeOption} />
      <Divider />
      <ButtonFilter currentFilter={filters[2]} selectedOption={get(filterChanged, filters[2].key)} onClick={changeOption} />
      <Divider />
      {staffFilterIndex > 2 && (
        <>
          <ButtonFilter currentFilter={filters[3]} selectedOption={get(filterChanged, filters[3].key)} onClick={changeOption} />
          <Divider />
        </>
      )}
      <ApiBoundary>
        <StaffFilter
          currentFilter={filters[staffFilterIndex]}
          selectedOption={get(filterChanged, filters[staffFilterIndex].key)}
          onClick={changeOption}
        />
      </ApiBoundary>
    </>
  );
};

export default CounselWholeFilterOptions;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import InputSelectBase, { IInputSelectBaseProps, StyledLabel } from 'components/InputSelectBase';
import Typography from 'components/Typography';
import React, { ReactNode, useMemo } from 'react';

type CheckBoxProps = Omit<IInputSelectBaseProps, 'type'> & {
  /** 체크박스 형태 (기본값: box) */
  variant?: 'simple' | 'box';
  /** 텍스트 라벨 */
  label?: ReactNode;
  /** 텍스트 라벨 폰트 크기 */
  labelFontsize?: 13 | 14 | 15 | 17;
  /** 바깥 border 상자 여부 */
  outlineBorder?: boolean;
  /** 체크박스와 label 텍스트간의 간격 */
  gap?: number;
  /** 커스텀 className */
  className?: string;
  /** 텍스트 라벨 폰트 두께 */
  labelFontWeight?: 400 | 700 | 600 | 500;
};

const CheckBox = React.forwardRef<HTMLInputElement, CheckBoxProps>(
  ({ variant = 'box', labelFontsize = 15, outlineBorder, className, labelFontWeight = 400, ...props }, ref) => {
    const { id, label, gap, checked, disabled } = props;

    const isBoxType = variant === 'box';
    const textColor = disabled ? 'gray3' : !isBoxType && !checked ? 'gray2' : 'gray1';
    const checkIconColor = useMemo(() => {
      if (isBoxType) {
        return checked ? 'white' : 'gray5';
      } else {
        return disabled || !checked ? 'gray4' : 'primary';
      }
    }, [isBoxType, checked, disabled]);

    return (
      <Container
        className={className ? `checkbox ${className}` : 'checkbox'}
        htmlFor={id}
        isBoxType={isBoxType}
        outlineBorder={!!outlineBorder}
        gap={gap}>
        <InputSelectBase type="checkbox" checkBold checkColor={checkIconColor} checkSize={16} ref={ref} {...props} />
        {typeof label === 'string' ? (
          <Typography span size={labelFontsize} textColor={textColor} weight={labelFontWeight}>
            {label}
          </Typography>
        ) : (
          <div className="check-box-label-wrapper">{label}</div>
        )}
      </Container>
    );
  },
);

const Container = styled(StyledLabel)<{ isBoxType: boolean; outlineBorder: boolean; gap?: number }>(
  /** 공통 스타일  */
  ({ gap }) => css`
    ${theme.flex('', 'center', '', gap)};

    .input-span-check-style {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,

  /** variant === 'box' 일 때, */
  ({ isBoxType }) =>
    isBoxType &&
    css`
      .input-select-base-input-wrapper {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        flex: 0 0 auto;

        input {
          & + span {
            width: 24px;
            height: 24px;
            border-radius: 4px;
          }

          &:checked {
            & + span {
              border-color: ${theme.color.primary};
              background-color: ${theme.color.primary};
            }
          }

          &:disabled {
            & + span {
              background-color: ${theme.color.gray7};
            }
            &:checked + span {
              border-color: ${theme.color.gray5};
              background-color: ${theme.color.gray5};
            }
          }
        }
      }
    `,

  /** variant === 'simple' 일 때, */
  ({ isBoxType }) =>
    !isBoxType &&
    css`
      .input-select-base-input-wrapper {
        width: 16px;
        height: 16px;

        input {
          & + span {
            width: 16px;
            height: 16px;
            border: none;
          }
        }
      }
    `,

  ({ outlineBorder }) =>
    outlineBorder &&
    css`
      border: 1px solid ${theme.color.gray5};
      border-radius: 8px;
      padding: 16px;
    `,
);

CheckBox.displayName = 'CheckBox';
export default CheckBox;

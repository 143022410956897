import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { StaffProfileFormType } from 'sharedComponents/ProfileField/types';

import { REPRESENTATIVE_COLOR_LIST } from './constants';

const RepresentativeColor = () => {
  const { setSearchParams, getAllSearchParams } = useQueryString();
  const { control } = useFormContext<StaffProfileFormType>();
  const representativeColor = useWatch({ control, name: 'profile.representative_color' }) || '';

  const matchColorLabel = useMemo(
    () => REPRESENTATIVE_COLOR_LIST.find(item => item.value === representativeColor)?.label,
    [representativeColor],
  );

  return (
    <StyledLinkButton
      className="representative-color"
      showBorder={false}
      leftIcon={<Icon name="colorChips" fillColor={color[representativeColor as keyof typeof color]} />}
      arrowBold={true}
      arrowSize={16}
      fontWeight={500}
      gap={10}
      onClick={() => {
        setSearchParams({ ...getAllSearchParams(), type: 'representativeColor' });
      }}>
      <Typography size={15}>대표컬러</Typography>
      <Typography size={15} weight={500}>
        {matchColorLabel}
      </Typography>
    </StyledLinkButton>
  );
};

export default RepresentativeColor;

const StyledLinkButton = styled(LinkButton)`
  .link-button-contents-wrapper {
    ${theme.flex('row', 'center', 'space-between')};
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';

import Drawer, { DrawerProps } from '..';

type Props = DrawerProps & { button: Omit<ButtonProps, 'children'> & { text: string } };

const SingleButtonDrawer = ({ children, button: { text: buttonText, ...button }, ...props }: Props) => {
  const isTopDrawer = props.direction === 'top';
  return (
    <DrawerContainer {...props}>
      {children}
      <ButtonWrapper isTopDrawer={isTopDrawer}>
        <Button fullWidth size={isTopDrawer ? 'medium48' : 'large56'} variant="contained" color="point" {...button}>
          {buttonText}
        </Button>
      </ButtonWrapper>
    </DrawerContainer>
  );
};

export default SingleButtonDrawer;

const DrawerContainer = styled(Drawer)`
  padding-bottom: calc(32px + 76px); // 버튼 있을 시 bottom + 하단 버튼 영역 높이
`;

const ButtonWrapper = styled.section<{ isTopDrawer: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: ${({ isTopDrawer }) => (isTopDrawer ? '14px' : '10px')} 20px;
  width: 100%;
  background-color: ${({ isTopDrawer }) => theme.color[isTopDrawer ? 'gray7' : 'white']};
  z-index: ${Z_INDEX.footerButton};
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import DateTimePicker from 'designedComponents/Pickers/DateTimePicker';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

import { TicketPaymentFormType } from '../types';
import { PaymentRow } from './StyledComponents';

const SettlementDate = ({ title }: { title?: string }) => {
  const { setValue, control } = useFormContext<TicketPaymentFormType>();
  const settlementAt = useWatch({ control, name: 'payment.settlement_at' });

  const [isOpen, setIsOpen] = useState(false);

  const settlementAtText = useMemo(() => filters.dateDashTime(settlementAt), [settlementAt]);

  const handleChangeDate = useCallback(
    (e: { value: Date }) => {
      const date = filters.dateDashTimeSecond(e.value);
      setValue('payment.settlement_at', date, { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <StyledPaymentRow isOpen={isOpen}>
      <FormFieldAccordion
        iconName="calendar"
        isOpen={isOpen}
        onChange={() => setIsOpen(!isOpen)}
        header={
          <div className="last-picker">
            <Typography span size={15}>
              {title || '결제일시'}
            </Typography>
            <Typography span size={15} weight={isOpen ? 600 : 500} textColor={isOpen ? 'primary' : 'gray1'}>
              {filters.dateTimeWithWeekday(settlementAtText)}
            </Typography>
          </div>
        }>
        <DateTimePicker value={settlementAt} onChange={handleChangeDate} />
      </FormFieldAccordion>
    </StyledPaymentRow>
  );
};

export default SettlementDate;

const StyledPaymentRow = styled(PaymentRow)<{ isOpen: boolean }>`
  border-bottom: none;

  .accordion {
    ${({ isOpen }) => !isOpen && `border-bottom: none;`}

    .header-wrapper {
      width: 100%;
      ${({ isOpen }) => !isOpen && `border-bottom: none;`}

      .header-box {
        flex: 1;

        .last-picker {
          ${theme.flex('', 'center', 'space-between')};
          width: 100%;
        }
      }
    }
  }
`;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { UserTicketResponse } from './useInfinityUserTicketProduct';

const useGetUserTicket = ({ ticket_id, user_id }: UserTicketDetailParams) => {
  const { queryFn } = useQueryFnWithCommonParams<{ data: UserTicketResponse }, Pick<UserTicketDetailParams, 'user_id'>>({
    url: `/api/userTicket/${ticket_id}`,
    params: {
      user_id,
    },
  });

  return useSuspenseQuery({
    queryKey: ['user-ticket', `${ticket_id}`],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetUserTicket;

type UserTicketDetailParams = {
  ticket_id: number;
  user_id: number;
};

import React from 'react';
import { useFormContext } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

import { MemberFormType } from '../types';

type Props = {
  hasType: boolean;
};

const LeaveDialog = ({ hasType }: Props) => {
  const {
    formState: { dirtyFields, isSubmitSuccessful },
  } = useFormContext<MemberFormType>();
  const isDirty = !!Object.keys(dirtyFields).length;

  return <DialogBeforeLeave isBlocked={!hasType && !isSubmitSuccessful && isDirty} />;
};

export default LeaveDialog;

import delay from 'utils/delay';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MessagePushParams } from './usePostMessagePush';

/** 예약 앱푸시 메세지 수정 */
const usePatchMessagePush = (id?: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<MessagePushPushParams>({
    method: `patch`,
    url: `/api/message/push/${id}`,
  });

  return useCustomMutation({
    mutationFn: async (param: MessagePushPushParams) => {
      await delay(2000);
      return mutationFn(param);
    },
  });
};

export default usePatchMessagePush;

type MessagePushPushParams = MessagePushParams;

import instance from 'api/axios';
import { AxiosResponse } from 'axios';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

/**
 * 데이터 양이 많을 때, 시간이 오래걸려 병렬로 나눠서 요청하는 용도의 훅
 * 내부 로직만 다를 뿐 사용법은 일반 api 훅과 같음
 */
const useGetAllQueryFnWithCommonParams = <TResponse, TParams = undefined>(axiosInfo: { url: string; params?: TParams }) => {
  const studio_id = useRecoilValue(studioIdAtom);
  const staff_id = useRecoilValue(staffIdAtom);

  const commonParams = { studio_id, staff_id };

  const queryFn: () => Promise<AxiosResponse<TResponse>> = async () => {
    const { url, params } = axiosInfo;

    /** 첫 페이지 요청은 total, last_page 파악을 위해 따로 요청 */
    const firstPageParams = { ...commonParams, paginate_type: 'detail', limit: 40, ...params, page: 1 };
    const firstPageRes = await instance(url, { params: firstPageParams });

    const {
      data: firstPageData,
      meta: { total, last_page },
    } = firstPageRes.data;

    if (firstPageData.length === total) return firstPageRes.data;

    const allParams = { ...commonParams, limit: 40, ...params };
    /** 첫 페이지 요청은 했기 때문에 last_page - 1 */
    const promiseList = Array.from({ length: last_page - 1 }, (_, i) => i + 2).map(page => {
      return instance(url, { params: { ...allParams, page } });
    });
    const allResponse = await Promise.all(promiseList);
    const allResult = allResponse.flatMap(res => res.data.data);
    return { data: [...firstPageData, ...allResult] };
  };

  return { queryFn };
};

export default useGetAllQueryFnWithCommonParams;

import styled from '@emotion/styled';
import { AxiosError } from 'axios';
import { LectureValidationError } from 'hooks/service/mutations/usePatchLecturePrivate';
import usePatchLecturePrivateBulk, { BookingPrivateBulkParams } from 'hooks/service/mutations/usePatchLecturePrivateBulk';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import { LectureCourseResponse } from 'hooks/service/queries/useGetLectureCourse';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import usePopup from 'hooks/usePopup';
import { isArray } from 'lodash';
import LectureOverlap from 'pages/Booking/components/AfterRequestBooking/LectureOverlap';
import BookingEndDate from 'pages/Booking/components/BookingRangeDate/BookingEndDate';
import BookingStartDate from 'pages/Booking/components/BookingRangeDate/BookingStartDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import BookingAvailableField from 'pages/Booking/components/Operation/Setting/BookingAvailableField';
import CheckInAvailableField from 'pages/Booking/components/Operation/Setting/CheckInAvailableField';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import formatBookingPrivateUpdateAllParams from 'pages/Booking/utils/private/formatBookingPrivateUpdateAllParams';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProcessingPopupContents from 'sharedComponents/ProcessingPopupContents';

import { BookingPrivateUpdateAllFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';
import ValidErrorDrawer from '../components/SelectMembers/ValidErrorDrawer';
import StyledPrivateForm from '../components/StyledPrivateForm';
import SubmitButton from './SubmitButton';

type Props = {
  currentLecture: LectureDetailResponse;
  course: LectureCourseResponse;
};

const PrivateUpdateAllForm = ({ currentLecture, course }: Props) => {
  const { handleSubmit, getValues } = useFormContext<BookingPrivateUpdateAllFormType>();

  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const navigate = useNavigate();
  const { setPopup } = usePopup();
  const { mutate: privateBulkUpdateMutate, isPending } = usePatchLecturePrivateBulk(currentLecture);

  const updateAllPrivate = useCallback(
    (updateAllParams: Omit<BookingPrivateBulkParams, 'start' | 'start_date'>) => {
      const params = {
        ...updateAllParams,
        start: currentLecture.start_on,
        start_date: course.start_date,
      };

      privateBulkUpdateMutate(params, {
        onSuccess: res => {
          // 단순 수업 수정만 한 경우 (빈배열 반환)
          if (isArray(res.data)) {
            navigate(-1);
          } else {
            // 예약이 변경된 경우
            navigate(`/booking/private/complete?from=updateAll`, {
              replace: true,
              state: { data: res.data },
            });
          }
          setPopup(null);
        },
        onError: (error: AxiosError<LectureValidationError>) => {
          setPopup(null);
          if (!error.response?.data) return;
          const errorData = error.response.data;
          if (errorData.limit) {
            setBookingCountValidation(errorData.limit);
            return false;
          }
          if (errorData.overlap) {
            setPopup(
              <LectureOverlap fails={errorData.overlap} onSubmit={() => updateAllPrivate({ ...params, is_force: true })} />,
            );
            return false;
          }
        },
      });
    },
    [course.start_date, currentLecture.start_on, navigate, privateBulkUpdateMutate, setPopup],
  );

  // 1. 주/월간 초과해도 수정
  const clickPassLimit = useCallback(() => {
    const params = {
      ...formatBookingPrivateUpdateAllParams(getValues()),
      is_pass_limit: true,
    };
    updateAllPrivate(params);
    setBookingCountValidation(null);
  }, [getValues, updateAllPrivate]);

  const submit = useCallback(() => {
    const params = {
      ...formatBookingPrivateUpdateAllParams(getValues()),
    };
    updateAllPrivate(params);
  }, [getValues, updateAllPrivate]);

  useEffect(() => {
    if (isPending) {
      setPopup(<ProcessingPopupContents title={BOOKING_COMMON_FORM_TEXT.loadingTitle} />);
    }
  }, [isPending, setPopup]);

  return (
    <>
      <StyleForm onSubmit={handleSubmit(submit)} showEnterField={currentLecture.policy.is_enter}>
        <ClassTitle />
        <SelectStaff lectureStaff={currentLecture.staff} />
        <BookingStartDate />
        <BookingEndDate />
        <BookingRangeTime pageMode="updateAll" />
        <RepeatField />
        <Room />
        <LectureSize disabled />
        <SelectMembersField />
        <BookingAvailableField />
        {currentLecture.policy.is_enter && <CheckInAvailableField />}
        <SubmitButton />
      </StyleForm>

      {bookingCountValidation && (
        <ValidErrorDrawer
          bookingCountValidation={bookingCountValidation}
          isOpen={!!bookingCountValidation}
          onClose={() => {
            setBookingCountValidation(null);
          }}
          onClick={clickPassLimit}
        />
      )}
    </>
  );
};

export default PrivateUpdateAllForm;

const StyleForm = styled(StyledPrivateForm)<{ showEnterField?: boolean }>`
  .booking-cancel-available-button {
    ${({ showEnterField }) => !showEnterField && `border: none;`}
  }
`;

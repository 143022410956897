import styled from '@emotion/styled';
import SubTitle from 'components/SubTitle';
import useQueryString from 'hooks/useQueryString';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';

import ChangeNameForm from './components/ChangeNameForm';
import { CHANGE_NAME_TEXT } from './constants';

const MoreDetailsAccountName = () => {
  const { getSearchParams } = useQueryString();

  const currentName = getSearchParams('currentValue');
  const navigate = useNavigate();

  useEffect(() => {
    // 현재 이름없이 접근하면 내 계정정보로 이동
    if (!currentName) {
      navigate('/more-details/account');
    }
  }, [currentName, navigate]);

  return (
    <MainLayout header={{}}>
      <Container>
        <SubTitle title={CHANGE_NAME_TEXT.title} bottom={40} />
        <ChangeNameForm currentName={currentName || ''} />
      </Container>
    </MainLayout>
  );
};

export default MoreDetailsAccountName;

const Container = styled.main`
  padding: 8px 20px;
`;

import dayjs from 'dayjs';

import { MessageDetailType } from '../../types';
import { MessageCounselSelectedFilterType } from './types';

// 상담필터 => 시설웹 공용 필터로 변환
export const convertCounselFilterToMessageFilter = (
  filter: MessageCounselSelectedFilterType,
  keyword: string,
): MessageDetailType['filter'] => {
  const staffId = filter.staffs?.id;
  const staffIdNumber = typeof staffId === 'string' ? parseInt(staffId) : staffId;

  return {
    keyword: keyword ?? null,
    channel: filter.channel ?? null,
    start_on: filter.period?.start,
    end_on: filter.period?.end,
    staff_id: staffIdNumber && !isNaN(staffIdNumber) ? staffIdNumber : undefined,
    staff_name: staffIdNumber && !isNaN(staffIdNumber) ? filter.staffs?.name : undefined, // staff_id가 있을 때만 staff_name을 보냄
    register_type: filter.register,
    funnel: filter.funnel,
  };
};

// 시설웹 공용필터 => 상담 필터로 변환
export const convertMessageFilterToCounselFilter = (
  messageFilter: MessageDetailType['filter'],
): MessageCounselSelectedFilterType => {
  if (!messageFilter) return {} as MessageCounselSelectedFilterType;

  const { start_on, end_on, staff_id, staff_name, register_type, channel, funnel } = messageFilter;

  const periodDiffDays = dayjs(end_on).diff(dayjs(start_on), 'day') + 1;
  const periodType = [30, 90, 180].includes(periodDiffDays) ? periodDiffDays : 0;

  return {
    period: start_on && end_on ? { start: start_on, end: end_on, type: periodType } : undefined,
    staffs: staff_id && staff_name ? { id: `${staff_id}`, name: staff_name } : undefined,
    register: register_type,
    channel: channel ?? undefined,
    funnel,
  };
};

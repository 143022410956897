import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import Icon from 'components/Icon';

const SortButton = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledButton
      className="sort-button"
      fontWeight={400}
      rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
      textColor="gray2"
      {...props}>
      {children}
    </StyledButton>
  );
};

export default SortButton;

const StyledButton = styled(Button)`
  margin-left: auto;

  svg {
    flex: 0 0 auto;
  }
`;

import styled from '@emotion/styled';
import Counter from 'components/Counter';
import Typography from 'components/Typography';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormField from 'sharedComponents/FormField';
import FormFieldTypography from 'sharedComponents/FormField/FormFieldTypography';

import { BookingGroupCreateFormType } from '../../types';
import { TraineeCounterWrapper } from './MaxTrainee';

const MinTrainee = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();
  const maxTrainee = useWatch({ control, name: 'maxTrainee' });

  return (
    <FormField className="min-trainee" iconName="privateClass" height={95}>
      <div>
        <TraineeCounterWrapper>
          <FormFieldTypography textColor="gray1" fontWeight={400}>
            최소 수강인원
          </FormFieldTypography>
          <Controller
            name="minTrainee"
            control={control}
            render={({ field: { ref, onBlur, ...rest } }) => <Counter id="minTrainees" max={maxTrainee} {...rest} />}
          />
        </TraineeCounterWrapper>
        <StyledTypography size={13} textColor="error">
          &lsquo;폐강 시간&rsquo; 이후, 최소 수강 인원이 채워지지 않으면
          <br /> 이 수업은 자동 삭제 됩니다. 0명인 경우 삭제되지 않습니다.
        </StyledTypography>
      </div>
    </FormField>
  );
};

export default MinTrainee;

const StyledTypography = styled(Typography)`
  margin-top: 9px;
`;

import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import SubTitle from 'components/SubTitle';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';

import Studios from './Studios';

const SelectStudioLayout = () => {
  const navigate = useNavigate();

  const setCurrentStaffId = useSetRecoilState(staffIdAtom);
  const setStudioId = useSetRecoilState(studioIdAtom);
  const [selectStudio, setSelectStudio] = useState('');

  const queryClient = useQueryClient();

  const {
    data: { studios },
  } = useGetStudio();

  const updateCommonIds = useCallback(
    (studioId: number, staffId: number) => {
      setStudioId(studioId);
      setCurrentStaffId(staffId);
    },
    [setStudioId, setCurrentStaffId],
  );

  useEffect(() => {
    if (!studios.length) {
      navigate('/no-link-studio');
    } else if (studios.length === 1) {
      const { id, staff_id } = studios[0];
      updateCommonIds(id, staff_id);
      navigate('/schedule');
    }
  }, [studios.length, navigate, studios, updateCommonIds]);

  if (!studios.length || studios.length === 1) {
    return null;
  }

  const changeStudio = (e: { target: { value: string } }) => {
    setSelectStudio(e.target.value);
  };

  return (
    <MainLayout>
      <Container>
        <SubTitle title="시설 선택" message={{ position: 'bottom', text: '이용할 시설을 선택해 주세요' }} bottom={40} />

        <section>
          <Studios studios={studios} selectStudio={selectStudio} onChange={changeStudio} updateCommonIds={updateCommonIds} />
        </section>
      </Container>

      <BottomWrapper>
        <div className="help-text">
          <Typography size={14} weight={500} textColor="gray3">
            무엇을 도와드릴까요?
          </Typography>
          <Button fontSize={14} fontWeight={500} textColor="primary" onClick={() => navigate('/more-details/settings/faq')}>
            자주 묻는 질문
          </Button>
        </div>

        <ButtonGroup leftButtonWidth={115}>
          <Button
            size="large56"
            variant="outlined"
            onClick={async () => {
              await queryClient.refetchQueries({ queryKey: ['studio'] });
              setSelectStudio('');
            }}>
            새로고침
          </Button>
          <Button
            type="submit"
            form="select-studio-form"
            size="large56"
            color="point"
            variant="contained"
            disabled={!selectStudio}
            fullWidth>
            선택 완료
          </Button>
        </ButtonGroup>
      </BottomWrapper>
    </MainLayout>
  );
};

export default SelectStudioLayout;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  padding: 56px 20px 0;
  height: 100%;
  form {
    ${theme.flex('column', '', '', 8)};
    padding: 0;
  }

  h2 {
    margin-top: 8px;
  }
`;

const BottomWrapper = styled.section`
  position: fixed;
  bottom: 0;
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid ${theme.color.gray5};
  background: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};

  .help-text {
    ${theme.flex('row', 'center', 'center', 4)};
    margin-bottom: 8px;
  }

  .button-group {
    padding: 10px 20px;
  }
`;

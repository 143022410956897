import { studioInfo } from '_mocks/fixtures/studioInfo';
import { HttpResponse } from 'msw';

import { api } from '../utils';

export const handlers = [
  api.get('/studio/:id', ({ params }) => {
    const studioId = params.id;
    const studio = studioInfo.find(studio => studio.id === Number(studioId));
    if (!studio) {
      return HttpResponse.json({ message: 'Not Found', id: studioId }, { status: 404 });
    }
    return HttpResponse.json(studio);
  }),
];

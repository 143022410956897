import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose: () => void;
};

/** 프라이빗 폼 > 회원 추가 LeaveDialog */
const SelectMemberLeaveDialog = ({ onClose }: Props) => {
  const { setValue, watch } = useFormContext<BookingPrivateFormType>();
  const { prevSelectedUserTickets } = watch();

  const navigate = useNavigate();

  const clickLeave = () => {
    setValue('selectedUserTickets', [...prevSelectedUserTickets]);
    onClose();
    navigate(-1);
  };

  return (
    <Dialog onClose={onClose} positiveAction={{ text: '나가기', onClick: clickLeave }} negativeAction={{ text: '취소' }}>
      <DialogDescription>
        변경사항이 저장되지 않았습니다. <br />
        페이지를 나가시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default SelectMemberLeaveDialog;

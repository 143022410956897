import Typography from 'components/Typography';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import filters from 'utils/filters';
import { safeDayjs } from 'utils/safeDayjs';

type Props = {
  dateTime: string;
};

const NotificationDate = ({ dateTime }: Props) => {
  // 상대시각 표시 설정 및 커스텀
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.updateLocale('ko', {
    relativeTime: {
      ...dayjs.Ls['ko'].relativeTime,
      h: '1시간',
    },
  });
  const isTodayNotification = safeDayjs(dateTime).isSame(dayjs(), 'day');
  const relativeText = safeDayjs(dateTime).fromNow();
  const originalDateTime = filters.dateTimeWithWeekday(dateTime);

  const dateText = isTodayNotification ? relativeText : originalDateTime;

  return <Typography textColor="gray3">{dateText}</Typography>;
};

export default NotificationDate;

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Form from 'components/Form';
import Icon from 'components/Icon';
import SubTitle from 'components/SubTitle';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Typography from 'components/Typography';
import usePopup from 'hooks/usePopup';
import { POINT_POPUP_TEXT } from 'pages/MemberDetail/constants';
import { CurrentMemberIdProps, PointFormType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';
import filters from 'utils/filters';
import { maximumLength } from 'utils/validate';
import { object } from 'yup';

import UpdateButton from '../UpdateButton';
import PointConfirmDrawer from './PointConfirmDrawer';
import PointType from './PointType';

type Props = CurrentMemberIdProps & {
  currentPoint: number;
};

const yupSchema = object().shape({ pointUpdateReason: maximumLength(20) });

const PointUpdatePopup = ({ currentPoint, currentMemberId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setPopup } = usePopup();

  const methods = useForm<PointFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      type: 'reward',
      rewardAmount: '',
      deductAmount: '',
      pointUpdateReason: '',
    },
  });

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        <FormContainer>
          <div className="top-wrapper">
            <SubTitle title={POINT_POPUP_TEXT.subtitle} />
            <Typography className="current-text" size={13} weight={500} textColor="primary">
              잔여 포인트 ㆍ {filters.numberComma(currentPoint)}P
            </Typography>
            <PointType currentPoint={currentPoint} />
          </div>
          <Divider />
          <div className="bottom-wrapper">
            <Typography className="reason-title" weight={600}>
              포인트 수정 사유
            </Typography>

            <OutlinedTextFieldController
              controllerProps={{ control: methods.control, name: 'pointUpdateReason' }}
              placeholder={POINT_POPUP_TEXT.reason.placeholder}
              onClear={() => methods.setValue('pointUpdateReason', '', { shouldValidate: true })}
            />
          </div>
          <UpdateButton onClick={() => setIsOpen(true)} />
        </FormContainer>
        <PointConfirmDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          currentPoint={currentPoint}
          currentMemberId={currentMemberId}
        />
      </FormProvider>
    </MainLayout>
  );
};

export default PointUpdatePopup;

const FormContainer = styled(Form)`
  height: 100%;
  ${theme.flex('column', '', '', 32)};

  hr {
    flex: 0 0 auto;
  }

  .top-wrapper {
    padding: 0 20px;

    h2 {
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }

  .bottom-wrapper {
    flex: 1;
    padding: 0 20px;

    .reason-title {
      margin-bottom: 12px;
    }
  }
`;

import styled from '@emotion/styled';
import { MATCH_REPRESENTATIVE_COLOR, theme } from 'assets/styles';
import Icon, { IconProps } from 'components/Icon';
import { ETC_COLOR_DATA } from 'pages/EtcSchedule/constants';
import { useMemo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { ProfileType } from 'types/staffTypes';

import Drawer from '..';
import { CheckRadioDrawerProps } from '.';
import CheckRadioFilterContents from './CheckRadioFilterContents';
import CheckRadioTypography from './CheckRadioTypography';

type Props<T extends FieldValues> = {
  representativeColor: ProfileType['representative_color'];
  color: ProfileType['representative_color'];
  control: Control<T>;
} & Omit<CheckRadioDrawerProps<T>, 'data'>;

const ScheduleColorCheckRadioDrawer = <T extends FieldValues>({
  representativeColor,
  color,
  name,
  control,
  showLabelAll,
  className,
  onClick,
  onClose,
  ...props
}: Props<T>) => {
  const data = useMemo(() => {
    const array = ETC_COLOR_DATA.map(({ id, value, label }) => {
      return {
        id,
        value,
        label: (
          <StyledLabel>
            <Icon name="colorChips" color={id as IconProps['color']} />
            <CheckRadioTypography isSelected={color === value}>{label}</CheckRadioTypography>
          </StyledLabel>
        ),
      };
    });
    const defaultItem = {
      id: MATCH_REPRESENTATIVE_COLOR[representativeColor],
      label: (
        <StyledLabel className="representative-color">
          <Icon name="colorChips" color={MATCH_REPRESENTATIVE_COLOR[representativeColor]} />
          <CheckRadioTypography isSelected={color === representativeColor}>대표 컬러</CheckRadioTypography>
        </StyledLabel>
      ),
      value: representativeColor,
    };

    return [defaultItem, ...array];
  }, [color, representativeColor]);

  return (
    <Drawer {...props} onClose={onClose}>
      <Container>
        <CheckRadioFilterContents control={control} name={name} data={data} onClose={onClose} />
      </Container>
    </Drawer>
  );
};

export default ScheduleColorCheckRadioDrawer;

const Container = styled.div`
  li:first-of-type {
    margin-bottom: 16px;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -12px;
      left: 0;
      background-color: ${theme.color.gray6};
    }
  }
`;

const StyledLabel = styled.div`
  ${theme.flex('row', 'center', '', 12)};
`;

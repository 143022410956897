import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  onClose: () => void;
  onClick: () => void;
};

const LoginExceptionDialog = ({ onClose, onClick }: Props) => {
  return (
    <Dialog onClose={onClose} negativeAction={{ text: '닫기' }} positiveAction={{ text: '로그인 하러 가기', onClick: onClick }}>
      <DialogDescription>로그인 후 이용할 수 있습니다.</DialogDescription>
    </Dialog>
  );
};

export default LoginExceptionDialog;

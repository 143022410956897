import styled from '@emotion/styled';
import Icon from 'components/Icon';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { isObject } from 'lodash';
import { MemberStaffFilterType } from 'pages/Member/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';

const StaffFilter = ({ currentFilter, selectedOption, onClick: changeOption }: FilterProps) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const selectedValue = selectedOption as MemberStaffFilterType;
  const filterOptions = currentFilter.options as { value: MemberStaffFilterType; label: string }[];

  const selectedStaffId = selectedValue?.id;
  const selectedStaffText = filterOptions.find(option => option.value?.id === selectedStaffId)?.label;

  const { control } = useForm({
    defaultValues: { staff: selectedStaffId ?? 'default' },
  });

  const changeSelectedStaff = (value: string) => {
    const selectedOption = filterOptions.find(option => option.value?.id === value);

    changeOption(selectedOption ?? { value: undefined, label: '선택 안함' }, currentFilter.key);
    setIsDrawerOpened(false);
  };

  const radioOptionData = filterOptions.map(option => {
    const avatar = option.value?.avatar;
    const id = option.value?.id ?? 'default';
    const imgUrl =
      isObject(avatar) && 'path' in avatar && 'extension' in avatar && 'name' in avatar // ArrowFilterValuesType에서 AttachmentType 타입 전환
        ? { name: `${avatar.name}`, path: `${avatar.path}`, extension: `${avatar.extension}` }
        : null;

    return {
      id: id,
      value: id,
      label: !['선택 안함', '전체'].includes(option.label) ? (
        <AvatarLabel
          key={id}
          id={id}
          selectStaffId={selectedStaffId ?? 'default'}
          imgUrl={imgUrl}
          label={option.label}
          showLabelAll // 강사명이 길 경우 줄바꿈
        />
      ) : (
        option.label
      ),
    };
  });

  return (
    <FilterWrap>
      <FilterTitle title={currentFilter.title} />
      <StaffSelectButton
        icon={<Icon name="arrowBottom" color="gray2" />}
        onClick={() => setIsDrawerOpened(true)}
        textColor="gray1"
        fontSize={15}
        fontWeight={500}>
        {selectedStaffText ?? '선택 안함'}
      </StaffSelectButton>
      <CheckRadioDrawer
        header={<OneLinedTitle title="담당강사 선택" />}
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        control={control}
        name="staff"
        data={radioOptionData}
        onClick={e => changeSelectedStaff(e.target.value)}
      />
    </FilterWrap>
  );
};

export default StaffFilter;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import SubTitle from 'components/SubTitle';
import Typography from 'components/Typography';
import { TicketTransferCommonProps, TicketTransferFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';
import TicketCard from 'sharedComponents/TicketCard';
import filters from 'utils/filters';

import formatSmallTransferCard from '../../utils/formatSmallTransferCard';
import getTransferUserTicketDetail from '../../utils/getTransferUserTicketDetail';
import MemberCard from '../MemberCard';
import SubmitButton from './SubmitButton';

type Props = UserTicketProps & TicketTransferCommonProps;

const FinalConfirm = ({ userTicket, productTypeText, title }: Props) => {
  const { watch } = useFormContext<TicketTransferFormType>();
  const {
    selectedMember,
    count,
    period,
    installment,
    payment: {
      amount,
      card_amount,
      cash_amount,
      wiretransfer_amount,
      point_amount,
      installment_period,
      unpaid_amount,
      settlement_at,
    },
  } = watch();
  const ticketDetail = getTransferUserTicketDetail({
    userTicket,
    step: 4,
    finalData: { ...count, ...period },
  });

  const transferTicket = { ...userTicket, ...count, ...period };

  const paymentMethodDetail = useMemo(() => {
    const installmentPeriod = installment === 'single' ? '일시불' : `${installment_period}개월`;

    return [
      { label: `카드(${installmentPeriod})`, value: card_amount },
      { label: '현금', value: cash_amount },
      { label: '계좌이체', value: wiretransfer_amount },
      { label: '포인트', value: point_amount, unit: 'P' },
    ];
  }, [card_amount, cash_amount, installment, installment_period, point_amount, wiretransfer_amount]);

  const paymentDetail = useMemo(() => {
    return [
      { label: '미수금', value: `${filters.numberComma(unpaid_amount)}원` },
      { label: '결제일시', value: `${filters.dateTimeWithWeekday(settlement_at)}` },
    ];
  }, [unpaid_amount, settlement_at]);

  return (
    <Container>
      <SubTitle title={[`${selectedMember?.name || ''} ${title[0]}`, `${productTypeText}${title[1]}`]} />

      <Typography size={13} weight={500} textColor="gray2">
        양수자
      </Typography>
      {!!selectedMember && (
        <CardWrapper bgColor="gray7" showBoxShadow={false}>
          <MemberCard member={selectedMember} isFinalConfirm={true} />
        </CardWrapper>
      )}

      <Typography size={13} weight={500} textColor="gray2">
        양도 수강권
      </Typography>

      <TicketCardWrapper bgColor="gray7" showBoxShadow={false}>
        <TicketCard ticket={formatSmallTransferCard(transferTicket)} />
        <TextWrapper>
          {ticketDetail.map(({ value, label }, index) => (
            <InfoFlexRow key={`ticketDetail-${index}`}>
              <InfoTypography size={14} opacity={0.88}>
                {label}
              </InfoTypography>
              <InfoTypography size={14} weight={500}>
                {value}
              </InfoTypography>
            </InfoFlexRow>
          ))}
        </TextWrapper>

        <TextWrapper>
          {paymentMethodDetail.map(({ value, label, unit }) => (
            <InfoFlexRow key={`paymentMethodDetail-${label}`}>
              <InfoTypography size={14} opacity={0.88}>
                {label}
              </InfoTypography>
              <InfoTypography size={14} weight={500}>
                {filters.numberComma(value)}
                {unit || '원'}
              </InfoTypography>
            </InfoFlexRow>
          ))}
        </TextWrapper>

        <TextWrapper>
          <InfoFlexRow>
            <InfoTypography size={14} opacity={0.88} weight={500}>
              총 양도금액
            </InfoTypography>
            <InfoTypography size={14} weight={600} textColor="primary">
              {filters.numberComma(amount)}원
            </InfoTypography>
          </InfoFlexRow>
        </TextWrapper>

        <LastTextWrapper>
          {paymentDetail.map(({ value, label }, index) => (
            <InfoFlexRow key={`paymentDetail-${index}`}>
              <InfoTypography size={14} opacity={0.88}>
                {label}
              </InfoTypography>
              <InfoTypography size={14} weight={500}>
                {value}
              </InfoTypography>
            </InfoFlexRow>
          ))}
        </LastTextWrapper>
      </TicketCardWrapper>

      <SubmitButton userTicket={userTicket} productTypeText={productTypeText} />
    </Container>
  );
};

export default FinalConfirm;

const Container = styled.div`
  min-height: 100%;
  padding: 0 20px 82px;

  .sub-title {
    margin: 8px 0 40px;
  }
`;

const CardWrapper = styled(CommonStyleCard)`
  padding: 16px;
  margin: 8px 0 32px;

  .member-card {
    .text-wrapper {
      flex: 1;
    }
  }

  .bottom-text-wrapper {
    .typography {
      opacity: 0.8;
    }
  }
`;

const TicketCardWrapper = styled(CardWrapper)`
  padding: 0;

  .ticket-card {
    padding: 16px 16px 12px;
  }
`;

const TextWrapper = styled(InfoFlexWrapper)`
  border-top: 1px solid ${theme.color.gray5};
  padding: 12px 16px;
`;

const LastTextWrapper = styled(TextWrapper)`
  padding: 12px 16px 16px;
`;

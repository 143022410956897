import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { CheckInItemType } from 'pages/Notification/types';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';

const useInfinityStaffNoticeCheckIn = () => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<StaffNoticeCheckInResponse>({
    url: `/api/staff/notice/check-in`,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['staff/notice', 'check-in'],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => ({
      checkIns: data.pages.flatMap(page => page.data.data),
      today: data.pages[0].data.enter_attendance_count,
    }),
  });
};

export default useInfinityStaffNoticeCheckIn;

type StaffNoticeCheckInResponse = {
  data: Array<CheckInItemType>; // 일주일동안 출석한 전체 목록
  enter_attendance_count: number; // 오늘 출석한 총 인원
};

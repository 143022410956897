import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Typography from 'components/Typography';
import usePopup from 'hooks/usePopup';
import { useNavigate } from 'react-router-dom';
import ProfileImgExtension from 'sharedComponents/ProfileField/components/ProfileImgExtension';
import { ColorType } from 'types/colorTypes';
import { AvatarType } from 'types/staffTypes';
import getImageUrl from 'utils/getImageUrl';

import { MORE_DETAILS_TEXT } from '../constants';

type Props = {
  name?: string;
  substituteName?: string;
  avatarImage?: AvatarType['image'];
  id?: number;
  studioIdentity?: {
    representativeColor: ColorType;
    roleName: string;
  };
};

const Profile = ({ name, substituteName, avatarImage, id, studioIdentity }: Props) => {
  const navigate = useNavigate();
  const { setPopup } = usePopup();

  const image = avatarImage
    ? getImageUrl({ path: avatarImage.path, name: avatarImage.name, extension: avatarImage.extension })
    : undefined;

  const clickProfileImage = () => {
    setPopup(<ProfileImgExtension image={avatarImage || null} />);
  };

  return (
    <Container>
      <div onClick={clickProfileImage}>
        <Avatar imgUrl={image} size={56} />
      </div>
      <Details isUnknown={!name}>
        <Typography size={name ? 19 : 17} weight={700} tag="pre">
          {name || substituteName}
        </Typography>
        {studioIdentity && (
          <Row>
            <Color color={studioIdentity.representativeColor} data-testid="color" />
            <Typography weight={500} textColor="gray3" data-testid="role">
              {studioIdentity.roleName}
            </Typography>
          </Row>
        )}
      </Details>
      {studioIdentity && (
        <MyStaffInfoButton
          color="gray"
          textColor="gray2"
          variant="contained"
          size="small"
          onClick={() => navigate(`staff/${id}?type=my-info`)}>
          {MORE_DETAILS_TEXT.myStaffInfo}
        </MyStaffInfoButton>
      )}
    </Container>
  );
};

export default Profile;

const Container = styled.section`
  ${theme.flex('row', '', '')}
  padding: 0 20px;
`;

const Details = styled.div<{ isUnknown: boolean }>`
  ${theme.flex('column', '', '', 2)}
  flex-grow: 1;
  padding-top: ${({ isUnknown }) => (isUnknown ? '4px' : '5px')};
  margin: 0 20px 0 16px;
`;

const Row = styled.div`
  ${theme.flex('row', 'center', '', 4)}
`;
const Color = styled.div<{ color: ColorType }>`
  width: 7px;
  height: 7px;
  border-radius: 2px;
  background-color: ${({ color }) => theme.color[color]};
`;

const MyStaffInfoButton = styled(Button)`
  width: 79px;
  height: 28px;
  background-color: ${theme.color.gray6};
  padding: 6px 8px;
  flex-shrink: 0;
  margin-top: 14px;
`;

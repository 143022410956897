import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MessageTargetType } from 'pages/MoreDetails/Message/types';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { MobileFormType } from '../types';
type Props = {
  target: MessageTargetType;
};
const SelectedTargetItem = ({ target }: Props) => {
  const { getValues, setValue } = useFormContext<MobileFormType>();

  const type = getValues('target');
  const targets = type === 'member' ? 'users' : 'counseling_logs';

  const removeTarget = () => {
    setValue(
      targets,
      getValues(targets).filter(item => item.id !== target.id),
    );
  };

  return (
    <Row key={target.id}>
      <Avatar imgUrl={target.avatar ? getImageUrl(target.avatar) : undefined} />
      <Name size={15} weight={500} textColor="gray2" ellipsis={1}>
        {target.name}
      </Name>
      <Phone size={15} textColor="gray2">
        ({filters.contact(target.mobile)})
      </Phone>
      <IconButton onClick={removeTarget}>
        <Icon name="delete2Fill" color="gray3" />
      </IconButton>
    </Row>
  );
};

const memoItem = memo(SelectedTargetItem, (prev, next) => prev.target.id === next.target.id);
export default memoItem;

const Row = styled.div`
  width: 100%;
  ${theme.flex('', 'center', 'space-between', 2)}
`;

const Name = styled(Typography)`
  margin-left: 6px;
  flex: 0 1 auto;
`;

const Phone = styled(Typography)`
  flex: 1 0 auto;
`;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 지난 이용내역 보기 */
const useGetHistoryBooking = (bookingId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<HistoryBookingResponse[]>({
    url: `/api/history/booking/${bookingId}`,
  });

  return useSuspenseQuery({
    queryKey: ['booking', 'history', bookingId],
    queryFn,
    select: data => data.data,
  });
};

export default useGetHistoryBooking;

export type HistoryBookingResponse = {
  id: number;
  status: string;
  user_account_type: string | null;
  act_by: string | null;
  act_at: string | null;
  enter_at: string | null;
  created_for: string | null;
};

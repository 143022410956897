import { yupResolver } from '@hookform/resolvers/yup';
import usePolicy from 'hooks/usePolicy';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { currentCounselingAtom } from 'recoil/counseling';
import { memberNameRequired, mobile } from 'utils/validate';
import { object } from 'yup';

import MemberFormMainLayout from '../components/MemberFormMainLayout';
import { MemberFormType } from '../types';
import convertFormDefaultValues from '../utils/convertFormDefaultValues';
import MemberCreateForm from './MemberCreateForm';

const yupSchema = object().shape({ mobile, profile: object({ name: memberNameRequired }) });

const FormMain = () => {
  const { isPolicyAllowed } = usePolicy();
  const is_use_user_grade = isPolicyAllowed('is_use_user_grade');

  /** 상담 고객 디테일에서 회원 등록 폼으로 이동하는 경우 */
  const currentCounseling = useRecoilValue(currentCounselingAtom);

  const methods = useForm<MemberFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    shouldFocusError: false,
    defaultValues: {
      ...convertFormDefaultValues({ currentCounseling }),
    },
  });

  return (
    <MemberFormMainLayout pageMode="create" {...methods}>
      <MemberCreateForm isUserGrade={!!is_use_user_grade} />
    </MemberFormMainLayout>
  );
};

export default FormMain;

import usePatchHolding, { HoldingPatchParams } from 'hooks/service/mutations/usePatchHolding';
import { HoldingPostResponse } from 'hooks/service/mutations/usePostHolding';
import { HoldingResponse } from 'hooks/service/queries/useInfinityHolding';
import { MutationCallbackType, TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';

import TicketHoldingForm from './TicketHoldingForm';

type Props = UserTicketProps & {
  holding: HoldingResponse;
  mutateCallback: MutationCallbackType<HoldingPostResponse, HoldingPatchParams>;
};

const HoldingUpdate = ({ canUpdateUserTicket, userTicket, holding, mutateCallback }: Props) => {
  const { mutate: updatePeriodMutate } = usePatchHolding(holding.id);

  const submit = (values: TicketHoldingFormType) => {
    const { params } = values;
    updatePeriodMutate({ ...params }, mutateCallback);
  };

  return <TicketHoldingForm canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} onSubmit={submit} />;
};

export default HoldingUpdate;

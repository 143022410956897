import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorDrawerContent } from '../type';

type Props = {
  content: ErrorDrawerContent;
  goBack?: () => void;
};

const ErrorDrawer = ({ content, goBack = () => null }: Props) => {
  const navigate = useNavigate();
  const [isOpen] = useState(true);

  const { header, image, buttonText, buttonText2, buttonType, isShowContact, hasImageBottomPadding, buttonClick, buttonClick2 } =
    content;

  // 싱글버튼 drawer
  if (buttonType === 3) {
    return (
      <SingleButtonDrawer
        header={header}
        isOpen={isOpen}
        onClose={goBack}
        button={{
          text: buttonText,
          onClick: () => {
            buttonClick?.();
          },
        }}>
        <Container hasImageBottomPadding={hasImageBottomPadding}>
          <div className="image-container">
            <img src={image} alt="" />
          </div>
          {isShowContact && (
            <div className="contact">
              <Typography weight={500} textColor="gray3">
                문제가 계속 발생하나요?
              </Typography>
              <Button
                fontWeight={500}
                size="medium40"
                textColor="primary"
                onClick={() => navigate('/more-details/settings/help')}>
                고객센터 문의
              </Button>
            </div>
          )}
        </Container>
      </SingleButtonDrawer>
    );
  }
  return (
    // 더블버튼 drawer
    <DoubleButtonDrawer
      header={header}
      isOpen={isOpen}
      onClose={goBack}
      leftButton={{
        text: buttonText,
        onClick: () => {
          buttonClick?.();
        },
      }}
      rightButton={{
        text: buttonText2 || '이전 화면으로',
        onClick: () => {
          buttonClick2?.();
        },
      }}>
      <Container hasImageBottomPadding={hasImageBottomPadding}>
        <div className="image-container">
          <img src={image} alt="" />
        </div>
        <div className="contact">
          <Typography weight={500} textColor="gray3">
            문제가 계속 발생하나요?
          </Typography>
          <Button fontWeight={500} size="medium40" textColor="primary" onClick={() => navigate('/more-details/settings/help')}>
            고객센터 문의
          </Button>
        </div>
      </Container>
    </DoubleButtonDrawer>
  );
};

export default ErrorDrawer;

const Container = styled.section<{ hasImageBottomPadding?: boolean }>`
  padding: 0 20px;
  .titles {
    margin-bottom: 16px;
    h2 {
      margin-bottom: 4px;
    }
  }

  .image-container {
    padding-top: 8px;
    padding-bottom: ${({ hasImageBottomPadding }) => (hasImageBottomPadding ? '32px' : '0px')};
    img {
      width: 100%;
    }
  }

  .contact {
    ${theme.flex()};
    gap: 4px;

    .text-button {
      min-height: 0;
    }
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import InputSelectBase, { IInputSelectBaseProps, StyledLabel } from 'components/InputSelectBase';
import Typography from 'components/Typography';
import React, { useMemo } from 'react';

export type RadioProps = Omit<IInputSelectBaseProps, 'type' | 'label'> & {
  label: React.ReactNode;
  fontSize?: number;
};

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ fontSize = 15, ...props }, ref) => {
  const { id, label, disabled } = props;

  const textColor = useMemo(() => (disabled ? 'gray3' : 'gray1'), [disabled]);

  return (
    <Container htmlFor={id} className="radio" fontSize={fontSize}>
      <InputSelectBase type="radio" ref={ref} {...props} />
      {typeof label === 'string' ? (
        <Typography span size={fontSize} textColor={textColor} weight={400}>
          {label}
        </Typography>
      ) : (
        <div className="radio-label-wrapper">{label}</div>
      )}
    </Container>
  );
});

const Container = styled(StyledLabel)<Pick<RadioProps, 'fontSize'>>(
  ({ fontSize }) => css`
    ${theme.flex('', 'center', '', fontSize === 14 ? 8 : 10)};

    .input-select-base-input-wrapper {
      width: 24px;
      height: 24px;

      input + span {
        width: 24px;
        height: 24px;
        border-radius: 50%;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${theme.color.white};
          border-radius: 50%;
        }
      }

      input:checked {
        & + span {
          border-color: ${theme.color.primary};

          &:before {
            background-color: ${theme.color.primary};
          }
        }
      }

      input:disabled {
        &:checked + span {
          border-color: ${theme.color.gray5};

          &:before {
            background-color: ${theme.color.gray5};
          }
        }
      }
    }
  `,
);

Radio.displayName = 'Radio';
export default Radio;

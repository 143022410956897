import styled from '@emotion/styled';
import { theme } from 'assets/styles';

const SwiperBanner = () => {
  return <Container>banner</Container>;
};

export default SwiperBanner;

const Container = styled.div`
  height: 140px;
  margin-bottom: 8px;
  background-color: aqua;
  border-top: 1px solid ${theme.color.gray7};
`;

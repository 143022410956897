import { PERMISSION } from 'constants/permission';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import usePermission from 'hooks/usePermission';
import { DEFAULT_COUNSEL_FILTER_TABS } from 'pages/Member/constants';
import { convertCounselFilter } from 'pages/Member/utils';
import { RecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import Filter from 'sharedComponents/Filters';
import { ParamsType } from 'sharedComponents/Filters/types';

import CounselDrawerFilterOptions from './CounselDrawerFilterOptions';
import CounselWholeFilterOptions from './CounselWholeFilterOptions';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  hasRegisterFilter?: boolean; // 회원등록여부 필터 추가여부
};

const CounselCustomerFilter = <T extends ParamsType>({ filterAtom, hasRegisterFilter }: Props<T>) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const { hasPermission } = usePermission();
  const canViewCounsel = hasPermission(PERMISSION.counsel.view);
  const { data: staffLists } = useGetFilterStaffs();

  const staffs = canViewCounsel ? staffLists : staffLists.filter(({ id }) => id === currentStaffId);

  const counselFilter = convertCounselFilter(staffs, canViewCounsel, hasRegisterFilter);
  const counselFilterTabs = {
    ...DEFAULT_COUNSEL_FILTER_TABS,
    staffs: canViewCounsel ? '담당스태프 전체' : staffs?.[0].name,
  };

  return (
    <Filter
      filterAtom={filterAtom}
      filters={counselFilter}
      defaultTabLabels={counselFilterTabs}
      renderDrawerOptions={CounselDrawerFilterOptions}
      renderWholeFilterPopupOptions={CounselWholeFilterOptions}
    />
  );
};

export default CounselCustomerFilter;

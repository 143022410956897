import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';

import { NOTICE_LIST_TEXT } from '../constants';
import { itemStyle } from './NoticeListItem';

const SkeletonList = () => {
  const DUMMY_LENGTH = 5;
  const dummyArray = Array.from({ length: DUMMY_LENGTH }, (_, i) => i);

  return (
    <Container>
      {dummyArray.map((_, index) => (
        <Item key={index}>
          <StyledSkeleton width={100} height={18} marginBottom={8} />
          <StyledSkeleton height={22} marginBottom={10} />
          <Row marginBottom={6}>
            <Typography size={13} weight={400} opacity={0.8} textColor="gray2">
              {`${NOTICE_LIST_TEXT.updatedAt}: `}
            </Typography>
            <Skeleton width={130} height={14} />
          </Row>
          <Row>
            <Typography size={13} weight={400} opacity={0.8} textColor="gray2">
              {`${NOTICE_LIST_TEXT.postingPeriod}: `}
            </Typography>
            <Skeleton width={130} height={14} />
          </Row>
        </Item>
      ))}
    </Container>
  );
};

export default SkeletonList;

const Container = styled.div`
  padding: 20px;
  ${theme.flex('column', 'center', '', 12)}
`;

const Item = styled.div`
  ${itemStyle}
`;

const Row = styled.div<{ marginBottom?: number }>`
  ${theme.flex('row', 'center', '', 3)}
  ${({ marginBottom = 0 }) => `margin-bottom: ${marginBottom}px;`}
`;

const StyledSkeleton = styled(Skeleton)<{ marginBottom?: number }>`
  ${({ marginBottom = 0 }) => `margin-bottom: ${marginBottom}px;`}
`;

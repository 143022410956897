import dayjs from 'dayjs';
import { EtcScheduleBulkDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcSchedule';
import filters from 'utils/filters';

const convertBulkData = (data: EtcScheduleBulkDetailResponse) => {
  const { start_date, end_date, start_time, end_time, staffs, ...rest } = data;

  /**
   * 현재 종일 일정인 경우,
   * 폼 시간 defaultValues 현재 시점으로부터 가장 가까운 정각으로 startTime 세팅
   * (종일 아닌 일정으로 수정할 때를 위한 초기화)
   */
  const startTime = dayjs().add(1, 'hour').startOf('hour').toDate();
  const endTime = dayjs(startTime).add(50, 'minute').toDate();

  const start_on = `${start_date} ${rest.is_all_day ? filters.timeSecondZero(startTime) : start_time}`;
  const end_on = `${end_date} ${rest.is_all_day ? filters.timeSecondZero(endTime) : end_time}`;

  return {
    ...rest,
    staffs,
    start_on,
    end_on,
    etc_schedule_id: rest.id,
    etc_schedule: { start_date, end_date, weekday: rest.weekday },
  };
};

export default convertBulkData;

import styled from '@emotion/styled';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useToast from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import { MORE_DETAILS_TEXT } from '../constants';
import { MenuType } from '../types';
import FakeNavigation from './FakeNavigation';

const LoginRequired = () => {
  const { setToast } = useToast();
  const navigate = useNavigate();

  const goLoginPage = () => {
    navigate('/login', { state: { isLookAround: true } });
  };

  const alertMessage = () => {
    setToast({ message: MORE_DETAILS_TEXT.loginRequired.toast, bottom: 76 });
  };

  const onSubMenuClick = (menu: MenuType) => {
    if (menu.name === '시설조회') {
      navigate(menu.path);
      return;
    }
    alertMessage();
  };

  return (
    <>
      <StickyWrapper>
        <ProfileWrapper>
          <Typography size={17} weight={700} tag="pre">
            {MORE_DETAILS_TEXT.loginRequired.title}
          </Typography>
          <Button size="medium48" fullWidth color="point" variant="contained" onClick={goLoginPage}>
            {MORE_DETAILS_TEXT.loginRequired.button}
          </Button>
        </ProfileWrapper>
        <DividerWithMargin />
      </StickyWrapper>
      <FakeNavigation onBasicMenuClick={alertMessage} onSubMenuClick={onSubMenuClick} />
    </>
  );
};

export default LoginRequired;

const DividerWithMargin = styled(Divider)`
  margin-bottom: 12px;
`;

const ProfileWrapper = styled.div`
  padding: 4px 20px 20px;

  > button {
    margin-top: 28px;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';

import { HOLIDAY_SETTING_TEXT } from '../constants';

type Props = {
  isOpen: boolean;
  dateText: string;
  onClose: () => void;
  onSubmit: () => void;
};

const SubmitDrawer = ({ isOpen, dateText, onClose, onSubmit }: Props) => {
  return (
    <StyledDoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={onClose}
      header={<TwoLinedTitle title={[dateText, HOLIDAY_SETTING_TEXT.submit.confirm]} />}
      leftButton={{ text: '취소', onClick: onClose }}
      rightButton={{ text: '설정', onClick: onSubmit }}>
      <ul>
        {HOLIDAY_SETTING_TEXT.submit.warnings.map((sentence, index) => (
          <li key={index}>
            <Typography size={15}>{sentence}</Typography>
          </li>
        ))}
      </ul>
    </StyledDoubleButtonDrawer>
  );
};

export default SubmitDrawer;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  ul > li {
    position: relative;
    padding-left: 6px;

    &:first-of-type {
      margin-bottom: 12px;
    }

    ::before {
      content: '';
      position: absolute;
      width: 2px;
      height: 2px;
      top: 10px;
      left: 0;
      border-radius: 50%;
      background-color: ${theme.color.gray3};
    }
  }
`;

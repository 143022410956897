import { theme } from 'assets/styles';

import { DropdownDataType } from './type';

export const DROPDOWN_DATA: DropdownDataType[] = [
  { value: 'update', label: '수정하기', icon: { name: 'writing2', fillColor: theme.color.gray2 } },
  { value: 'remove', label: '삭제하기', icon: { name: 'delete', fillColor: theme.color.secondary3 } },
];

export const CHANNEL_DATA = [
  { id: 'visit_channel', label: '방문상담', value: 'visit' },
  { id: 'phone_channel', label: '전화상담', value: 'phone' },
  { id: 'chat_channel', label: '채팅상담', value: 'chat' },
  { id: 'etc_channel', label: '기타상담', value: 'etc' },
];

export const FUNNEL_DATA = [
  { id: 'search_funnel', label: '네이버검색', value: 'search' },
  { id: 'introduction_funnel', label: '지인소개', value: 'introduction' },
  { id: 'sign_funnel', label: '간판', value: 'sign' },
  { id: 'flyer_funnel', label: '전단지', value: 'flyer' },
  { id: 'sns_funnel', label: 'SNS', value: 'sns' },
  { id: 'etc_funnel', label: '기타', value: 'etc' },
];

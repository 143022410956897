import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckRadio, { CheckRadioProps } from 'components/Radio/CheckRadio';
import Drawer from 'designedComponents/Drawers';
import CheckRadioTypography from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioTypography';
import { LectureRuleType } from 'hooks/service/queries/useGetLectureCourse';
import { BOOKING_RULE_TYPE_DATA } from 'pages/Booking/constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentRuleType: LectureRuleType;
  textType: 'booking' | 'cancel';
  onChange: CheckRadioProps['onChange'];
};

/** 운영정보 상세 설정 페이지의 RuleType 변경하는 Drawer */
const CheckRadioRuleTypeDrawer = ({ textType, currentRuleType, onChange, isOpen, onClose }: Props) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <StyledUl>
        {BOOKING_RULE_TYPE_DATA.map(({ value, label }) => {
          const checked = value === currentRuleType;

          return (
            <li key={value}>
              <CheckRadio
                name={textType}
                id={`${textType}-${value}`}
                label={<CheckRadioTypography isSelected={checked}>{label}</CheckRadioTypography>}
                value={value}
                checked={checked}
                onChange={e => {
                  onChange?.(e);
                  onClose();
                }}
              />
            </li>
          );
        })}
      </StyledUl>
    </Drawer>
  );
};

export default CheckRadioRuleTypeDrawer;

const StyledUl = styled.ul`
  ${theme.flex('column', 'initial', 'initial', 8)};

  li {
    height: 40px;

    label {
      height: 100%;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { BookingPrivateSuccessResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import filters from 'utils/filters';

type Props = {
  members: BookingPrivateSuccessResponse['members'];
  isOpen: boolean;
  onClose: () => void;
  goToMemberDetail: (id: number) => void;
};

const BookedMembersDrawer = ({ members, isOpen, onClose, goToMemberDetail }: Props) => {
  return (
    <StyledDrawer isOpen={isOpen} onClose={onClose} header={<OneLinedTitle title="예약내역 보기" />}>
      {members.map(({ id, name, mobile }) => (
        <LinkButton key={id} size="large60" gap={16} onClick={() => goToMemberDetail(id)}>
          <TextWrapper>
            <Typography span size={15} weight={600} ellipsis={1}>
              {name}
            </Typography>
            {mobile && (
              <Typography className="mobile" span size={15} weight={600}>
                ({filters.mobile(mobile)})
              </Typography>
            )}
          </TextWrapper>
        </LinkButton>
      ))}
    </StyledDrawer>
  );
};

export default BookedMembersDrawer;

const StyledDrawer = styled(Drawer)`
  padding: 8px 0 40px;

  .link-button:last-of-type {
    border-bottom: none;
  }
`;

const TextWrapper = styled.span`
  ${theme.flex('', '', '', 3)};

  .mobile {
    flex: 0 0 auto;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { BoardNoticeType } from 'hooks/service/queries/useGetBoardNotice';
import { Link } from 'react-router-dom';
import filters from 'utils/filters';

import { periodText } from '../../utils';
import { NOTICE_LIST_TEXT } from '../constants';

type Props = {
  notice: BoardNoticeType;
};

const NoticeListItem = ({ notice }: Props) => {
  return (
    <Container to={`${notice.id}`}>
      {notice.is_top_fixed && (
        <Label size="small" textColor="primary" borderColor="primary" fontSize={12}>
          {NOTICE_LIST_TEXT.topFixed}
        </Label>
      )}
      {notice.popup_on && (
        <Label size="small" textColor="secondary1" borderColor="secondary1" fontSize={12}>
          {NOTICE_LIST_TEXT.popup}
        </Label>
      )}
      <Typography size={15} weight={600} ellipsis={1} className="title">
        {notice.title}
      </Typography>
      <Typography size={13} textColor="gray2" opacity={0.8} className="date">
        {NOTICE_LIST_TEXT.updatedAt}: {filters.dateTimeWithWeekday(notice.updated_at)}
      </Typography>
      <Typography size={13} textColor="gray2" opacity={0.8}>
        {NOTICE_LIST_TEXT.postingPeriod}: {periodText(notice)}
      </Typography>
    </Container>
  );
};

export default NoticeListItem;

export const itemStyle = `
  width: 100%;
  padding: 18px 20px;
  border-radius: 16px;
  background: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  `;

const Container = styled(Link)`
  ${itemStyle}
  .label {
    margin-right: 4px;
    margin-bottom: 8px;
  }
  .title {
    margin-bottom: 10px;
  }
  .date {
    margin-bottom: 6px;
  }
`;

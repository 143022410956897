import styled from '@emotion/styled';
import FoldingFilterTabs from 'components/Tabs/FoldingFilterTabs';
import TabPanel from 'components/Tabs/TabPanel';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useTab from 'hooks/useTab';
import { useMemo } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import ExpiredTicketList from './components/ExpiredTicketList';
import { ITEM_TAB_DATA, USER_TICKET_TAB_DATA } from './constants';

const ExpiredTicket = () => {
  const currentMemberId = useParamsId();
  const { getSearchParams } = useQueryString();
  const productType = getSearchParams('productType') as UserTicketParams['product_type'];

  const tab = useMemo(() => (productType === 'userTicket' ? USER_TICKET_TAB_DATA : ITEM_TAB_DATA), [productType]);

  const { currentTab, onChange } = useTab(tab);

  useScrollRestoration();

  return (
    <MainLayout header={{ title: `이전 ${PRODUCT_TYPE_TEXT[productType]} 보기` }}>
      <TabsWrapper>
        <FoldingFilterTabs data={tab} value={currentTab.value} onChange={onChange} />
      </TabsWrapper>

      <TabPanel>
        <ApiBoundary>
          <ExpiredTicketList
            currentMemberId={currentMemberId}
            productType={productType}
            status={currentTab.value as UserTicketParams['status']}
          />
        </ApiBoundary>
      </TabPanel>
    </MainLayout>
  );
};

export default ExpiredTicket;

const TabsWrapper = styled(StickyWrapper)`
  .folding-filter-tabs {
    border-bottom: none;
  }
`;

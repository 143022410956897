import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { Z_INDEX } from 'constants/zIndex';
import { useNavigate } from 'react-router-dom';

type Props = {
  buttonText?: string;
  cancelClick?: () => void;
  onClick?: () => void;
  single?: boolean;
};

const SubmitButtonGroup = ({ buttonText, cancelClick, onClick, single }: Props) => {
  const navigate = useNavigate();

  return (
    <ButtonWrapper>
      {
        <ButtonGroup leftButtonWidth={115}>
          {!single && (
            <Button
              variant="contained"
              color="gray"
              size="large56"
              onClick={() => {
                if (cancelClick) cancelClick();
                else navigate(-1);
              }}>
              이전
            </Button>
          )}
          <Button type="submit" size="large56" variant="contained" color="point" fullWidth onClick={() => onClick?.()}>
            {buttonText}
          </Button>
        </ButtonGroup>
      }
    </ButtonWrapper>
  );
};

export default SubmitButtonGroup;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  padding: 10px 20px;
  width: 100%;
  background-color: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};
`;

import useGetStaffNoticeCheckInNoReadCount from 'hooks/service/queries/useGetStaffNoticeCheckInNoReadCount';

import NoReadDot from '../NoReadDot';

const CheckInDot = () => {
  const { data: checkIn } = useGetStaffNoticeCheckInNoReadCount();
  const hasNewCheckIn = checkIn.is_checked_check_in_false_count > 0;

  return hasNewCheckIn ? <NoReadDot left={105} /> : null;
};

export default CheckInDot;

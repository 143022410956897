import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Tab from 'components/Tabs/Tab';
import TabGroup from 'components/Tabs/TabGroup';
import TabPanel from 'components/Tabs/TabPanel';
import { Z_INDEX } from 'constants/zIndex';
import useTab from 'hooks/useTab';
import { Fragment } from 'react/jsx-runtime';

import ListTypeContent from './components/ListTypeContent';
import PaletteTypeContent from './components/PaletteTypeContent';
import { TAB_LIST } from './constants';

const SelectRepresentativeColor = () => {
  const { currentTab, onChange } = useTab(TAB_LIST);

  const isPaletteTab = currentTab.value === 'palette';

  return (
    <Container>
      <TabGroup>
        {TAB_LIST.map((tab, index) => (
          <Fragment key={tab.value}>
            <Tab data={tab} isSelected={tab.value === currentTab.value} onClick={onChange} setSelectedTab={() => null} />
            {index === 0 && <span className="vertical-line">|</span>}
          </Fragment>
        ))}
      </TabGroup>
      <TabPanel>{isPaletteTab ? <PaletteTypeContent /> : <ListTypeContent />}</TabPanel>
    </Container>
  );
};

export default SelectRepresentativeColor;

const Container = styled.div`
  .tabs {
    position: fixed;
    top: 55px;
    width: 100%;
    background-color: ${theme.color.white};
    border-bottom: 1px solid ${theme.color.gray7};
    z-index: ${Z_INDEX.stickyTab + 1};

    .vertical-line {
      margin: 0 8px;
      font-size: 1.3rem;
      color: ${theme.color.gray4};
    }
  }

  .tab-panel {
    margin-top: 49px;
  }
`;

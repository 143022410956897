import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useInfinityMember from 'hooks/service/queries/useInfinityMember';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { allMemberSelectedFiltersAtom } from 'recoil/Member';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import MemberCard from 'sharedComponents/MemberCard';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import { convertAllMemberFilterToParams } from '../utils';
import NoData from './NoData';
import Sorting from './Sorting';
import Total from './Total';

const AllMemberList = () => {
  const selectedFilter = useRecoilValue(allMemberSelectedFiltersAtom);
  const { searchKeyword } = useSearchKeywords('member');

  const {
    data: { members, total },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    refetch,
  } = useInfinityMember({
    ...convertAllMemberFilterToParams(selectedFilter),
    search: searchKeyword,
  });

  const navigate = useNavigate();
  const { checkPermission } = useGetPermissionDoHavePermission();
  const goToMemberDetail = (memberId: number) => {
    checkPermission(PERMISSION.member.view.id, {
      onSuccess: () => navigate(`/member/detail/${memberId}`),
    });
  };

  useScrollRestoration();

  const queryClient = useQueryClient();
  const refetchMembers = () => {
    refetch();
    queryClient.invalidateQueries();
  };

  return (
    <PullToRefresh isRefetching={isRefetching} onRefresh={refetchMembers}>
      <TotalWrapper>
        <Total total={total} />
        <Sorting filterAtom={allMemberSelectedFiltersAtom} />
      </TotalWrapper>
      {total === 0 ? (
        <NoData currentTabLabel="등록된" currentKeyword={searchKeyword} />
      ) : (
        <InfiniteScroll gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {members.map(member => (
            <MemberCard key={member.id} member={member} onClick={() => goToMemberDetail(member.id)} />
          ))}
        </InfiniteScroll>
      )}
    </PullToRefresh>
  );
};

export default AllMemberList;

const TotalWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  padding-top: 24px;
  margin-bottom: 16px;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { ColorType } from 'types/colorTypes';

import InfiniteScroll from './InfiniteScroll';

/**
 * 텍스트들 사이에 gray색의 세로 막대바로 구분자를 주는 디자인 wrapper
 */
export const ArrangeTypographyWrapper = styled.div<{ bgColor?: ColorType }>`
  .typography {
    position: relative;
    padding-left: 13px;

    &:first-of-type {
      padding-left: 0;
      &:before {
        content: none;
      }
    }

    &:before {
      content: '';
      width: 1px;
      height: 10px;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: 6px;
      background-color: ${({ bgColor }) => theme.color[bgColor || 'gray4']};
    }
  }
`;

/**
 * 리스트 아이템이 카드 모양인 디자인
 */
export const CommonStyleCard = styled.div<{ bgColor?: ColorType; showBoxShadow?: boolean }>(
  ({ bgColor = 'white', showBoxShadow = true }) => css`
    padding: 18px 20px;
    border-radius: 12px;
    box-shadow: ${showBoxShadow ? '0px 2px 5px 0px rgba(0, 0, 0, 0.08)' : 'none'};
    background-color: ${theme.color[bgColor]};
  `,
);

/**
 * 리스트 아이템이 카드 모양인 디자인 중 위아래 정보가 gray border 로 나뉘어져 있을 때,
 * 위를 감싸는 wrapper
 */
export const CommonStyledCardTopWrapper = styled.div`
  border-bottom: 1px solid ${theme.color.gray6};
`;

/** li 마다 언더라인 있는 인피니트 스크롤 리스트 wrapper */
export const StyledSelectInfiniteScroll = styled(InfiniteScroll)`
  flex: 1;
  overflow-y: auto;

  ul {
    padding: 0 20px;
  }

  li {
    &:not(:last-of-type) {
      border-bottom: 1px solid ${theme.color.gray8};
    }
  }
`;

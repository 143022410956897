import { useSetRecoilState } from 'recoil';
import { toastAtom } from 'recoil/toast';

const useToast = () => {
  const setToast = useSetRecoilState(toastAtom);

  return { setToast };
};

export default useToast;

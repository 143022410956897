import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { Member, Staff } from 'pages/MoreDetails/Report/type';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityReportPoint = (params: ReportPointParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<ReportPointResponse, ReportPointParams>({
    url: `/api/report/point`,
    params: { ...params },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`report/point`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        points: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
      };
    },
  });
};

export default useInfinityReportPoint;

export type ReportPointParams = {
  /** 검색 시작일 */
  start_date: string;
  /** 검색 종료일 */
  end_date: string;
  /** 이름검색 */
  staff_ids?: string[];
  /** 구분 검색 */
  types?: string[];
  /** 분류 검색 */
  status?: string[];
  /** 페이지네이션 종류 */
  paginate_type: string;
  /** 리스트 솔트 기준 */
  order_by?: string;
};

export type ReportPointResponse = {
  /** 아이디 */
  id: number;
  /** 포인트 구분
   * 'reward' : 적립,'batch_reward' : 일괄적립 ,'deduct' : 차감 ,'batch_deduct' : 일괄차감 ,'destruction': 소멸,'mix' : 혼합
   */
  type: 'reward' | 'batch_reward' | 'deduct' | 'batch_deduct' | 'destruction' | 'mix';
  /**  결제 구분
   *  'new','re_take','experience','refund','upgrade','installment_payment','commission','transfer','re-take'
   */
  payment_type:
    | 'new'
    | 're_take'
    | 'experience'
    | 'refund'
    | 'upgrade'
    | 'installment_payment'
    | 'commission'
    | 'transfer'
    | 're-take';
  /**
   * 결제 분류
   *  'purchase','refund','upgrade','downgrade','extension','full_payment','pending','installment_payment','transfer','change'
   */
  payment_status:
    | 'purchase'
    | 'refund'
    | 'upgrade'
    | 'downgrade'
    | 'extension'
    | 'full_payment'
    | 'pending'
    | 'installment_payment'
    | 'transfer'
    | 'change';
  member: Member;
  /** 변경 사유 */
  updated_for: string;
  /** 생성일 */
  created_at: string;
  /** 수정일  */
  updated_at: string;
  /** 차감 포인트 */
  paid_point: number;
  /** 적립 포인트 */
  reward_point: number;
  /** 깅사 정보 */
  staff: Staff;
  /** user ticket 아이디 */
  ref_id: number;
  /** ref 타입 */
  ref_type: string;
  /** 결제일 */
  settlement_at: string;
};

import { theme } from 'assets/styles';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import useStatusBarColor from 'hooks/useStatusBarColor';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import LoginExceptionContainer from 'sharedComponents/LoginExceptionContainer';
import localStorage from 'utils/localStorage';

import MainLayout from '../MainLayout';
import StudioNoConnected from './components/StudioNoConnected';
import StudioSelectionRequired from './components/StudioSelectionRequired';

type Props = {
  /**
   * 연동시설없음 토스트 뒤에 보일 UI
   * MainLayout + BottomNav 포함해야 함
   */
  noConnectedBackground: ReactNode;
};

/**
 * recoil studioId가 비었을 때 활용하는 예외상황처리
 * @description 연동된 시설 없을 때는 특정 UI 배경으로 토스트
 * @description 연동된 시설은 있지만 선택하지 않았을 경우 선택하도록 안내
 */
const StudioException = ({ noConnectedBackground }: Props) => {
  const { pathname } = useLocation();
  const statusBarColor = ['/schedule', '/member'].includes(pathname) ? 'linear' : theme.color.white;
  useStatusBarColor(statusBarColor);

  const { data } = useGetStudio();

  const hasStudioConnected = data?.studios.length > 0;
  const hasAccessToken = !!localStorage.get('access_token');

  if (!hasAccessToken) {
    return (
      <MainLayout bottomNav>
        <LoginExceptionContainer>{noConnectedBackground}</LoginExceptionContainer>
      </MainLayout>
    );
  }

  if (!hasStudioConnected) {
    return (
      <MainLayout bottomNav>
        <StudioNoConnected>{noConnectedBackground}</StudioNoConnected>
      </MainLayout>
    );
  }

  return (
    <MainLayout bottomNav>
      <StudioSelectionRequired studios={data.studios} />
    </MainLayout>
  );
};

export default StudioException;

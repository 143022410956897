import { TabDataElementType } from 'components/Tabs';
import { useState } from 'react';

const useTab = (data: TabDataElementType[]) => {
  const [currentTab, setCurrentTab] = useState(data[0]);

  const onChange = (tabData: TabDataElementType) => {
    setCurrentTab(tabData);
  };

  return { currentTab, setCurrentTab, onChange };
};

export default useTab;

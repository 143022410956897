import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Form from 'components/Form';
import SelectBoxSquare from 'components/SelectBox/SelectBoxSquare';
import Typography, { TypographyProps } from 'components/Typography';
import { FormEvent, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { SameStaffAccountsType, UnifiedAccountFormTypes } from '../types';
import MoreInfoDrawer from './MoreInfoDrawer';

type Props = {
  studios: SameStaffAccountsType[];
  next: (e: FormEvent<HTMLFormElement>) => void;
};

const UnifiedSelectId = ({ studios, next }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { control } = useFormContext<UnifiedAccountFormTypes>();
  const selectedId = useWatch({ control, name: 'selectedId' });

  return (
    <>
      <Container onSubmit={next} footerButton={{ text: '선택', disabled: !selectedId }}>
        <ul>
          {studios?.map(({ id, identity, integrated_account_login_staff }) => {
            const currentName = integrated_account_login_staff?.name;
            const isChecked = id === Number(selectedId);

            const commonTypoOptions: Omit<TypographyProps, 'children'> = {
              textColor: isChecked ? 'primary' : 'gray1',
              weight: isChecked ? 700 : 400,
              ellipsis: 1,
            };

            return (
              <li key={id}>
                <Controller
                  name="selectedId"
                  control={control}
                  render={({ field }) => (
                    <SelectBoxSquare
                      {...field}
                      id={id.toString()}
                      type="radio"
                      label={
                        <>
                          <Typography className="identity" {...commonTypoOptions}>
                            {identity}
                          </Typography>
                          &nbsp;
                          <Typography {...commonTypoOptions}>&#40;{currentName}</Typography>
                          &#41;
                        </>
                      }
                      value={id}
                      checked={isChecked}
                    />
                  )}
                />
              </li>
            );
          })}
        </ul>

        <Button className="more-info-text" textColor="gray3" textUnderline onClick={() => setIsDrawerOpen(true)}>
          계정 통합 자세히 보기
        </Button>
      </Container>

      <MoreInfoDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </>
  );
};

export default UnifiedSelectId;

const Container = styled(Form)`
  ul {
    ${theme.flex('column', 'center', 'center', 8)};

    li {
      width: 100%;
      height: 48px;

      .label-text {
        ${theme.flex()};
        padding: 0 16px;

        .identity {
          min-width: max-content;
        }
      }
    }
  }

  .more-info-text {
    margin-top: 8px;
  }
`;

import { staff } from '_mocks/fixtures/staff';
import { HttpResponse } from 'msw';

import { api } from '../utils';

export const handlers = [
  api.get('/staff/me', () => {
    return HttpResponse.json({ data: staff });
  }),
];

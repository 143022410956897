import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import usePostMemberDeductPoint from 'hooks/service/mutations/usePostMemberDeductPoint';
import usePostMemberRewardPoint from 'hooks/service/mutations/usePostMemberRewardPoint';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { POINT_POPUP_TEXT } from 'pages/MemberDetail/constants';
import { CurrentMemberIdProps, PointFormType } from 'pages/MemberDetail/types';
import { useMemo } from 'react';
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { ColorType } from 'types/colorTypes';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

type Props = CurrentMemberIdProps & {
  isOpen: boolean;
  onClose: () => void;
  currentPoint: number;
};

const PointConfirmDrawer = ({ isOpen, onClose, currentPoint, currentMemberId }: Props) => {
  const { control, handleSubmit } = useFormContext<PointFormType>();
  const type = useWatch({ control, name: 'type' });
  const rewardAmount = useWatch({ control, name: 'rewardAmount' });
  const deductAmount = useWatch({ control, name: 'deductAmount' });

  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: deductPointMutate, isPending: isPendingDeduct } = usePostMemberDeductPoint();
  const { mutate: rewardPointMutate, isPending: isPendingReward } = usePostMemberRewardPoint();

  const mutateCallback = () => {
    const message = POINT_POPUP_TEXT.toastMessage;

    return {
      onSuccess: () => {
        onClose();
        setPopup(null);
        setToast({ type: 'success', message });
      },
      onError: (error: CustomError) => {
        onClose();
        errorDialog.open(error);
      },
    };
  };

  const submit: SubmitHandler<PointFormType> = values => {
    const { type, deductAmount, rewardAmount, pointUpdateReason } = values;

    const commonParams = {
      member_id: currentMemberId,
      updated_for: pointUpdateReason || undefined,
    };

    switch (type) {
      case 'reward':
        rewardPointMutate({ ...commonParams, point: Number(rewardAmount) }, mutateCallback());
        break;
      case 'deduct':
        deductPointMutate({ ...commonParams, point: Number(deductAmount) }, mutateCallback());
        break;
    }
  };

  const data: { color: ColorType; [key: string]: string | number } = useMemo(() => {
    switch (type) {
      case 'reward':
        return {
          color: 'primary',
          text: POINT_POPUP_TEXT[type].label,
          amount: filters.numberComma(rewardAmount),
          updatePoint: filters.numberComma(currentPoint + Number(rewardAmount)),
        };
      case 'deduct':
        return {
          color: 'secondary3',
          text: POINT_POPUP_TEXT[type].label,
          amount: `-${filters.numberComma(deductAmount)}`,
          updatePoint: filters.numberComma(currentPoint - Number(deductAmount)),
        };
    }
  }, [currentPoint, deductAmount, rewardAmount, type]);

  return (
    <DoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title={POINT_POPUP_TEXT.drawer.headerText} />}
      leftButton={{ text: '취소', disabled: isPendingDeduct || isPendingReward, onClick: onClose }}
      rightButton={{ text: '수정 완료', disabled: isPendingDeduct || isPendingReward, onClick: handleSubmit(submit) }}>
      <GrayBoxWrapper>
        <InfoFlexRow>
          <InfoTypography size={15}>{data.text}</InfoTypography>
          <InfoTypography size={15} weight={600} textColor={data.color}>
            {data.amount} P
          </InfoTypography>
        </InfoFlexRow>
        <InfoFlexRow>
          <InfoTypography size={15}>{data.text} 후 잔여 포인트</InfoTypography>
          <InfoTypography size={15} weight={500}>
            {data.updatePoint} P
          </InfoTypography>
        </InfoFlexRow>
      </GrayBoxWrapper>
      <Typography size={15} textColor="gray2">
        회원의 잔여 포인트를 수정합니다.
        <br />
        계속 하시겠습니까?
      </Typography>
    </DoubleButtonDrawer>
  );
};

export default PointConfirmDrawer;

const GrayBoxWrapper = styled(InfoFlexWrapper)`
  background-color: ${theme.color.gray7};
  padding: 18px 20px;
  margin-bottom: 24px;
  border-radius: 12px;
`;

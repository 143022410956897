import errorNotConnected from 'assets/images/error_not_connected.webp';
import errorTemporaryError from 'assets/images/error_temporary_error.webp';
import errorUnauthorized from 'assets/images/error_unauthorized.webp';
import Typography from 'components/Typography';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import useQueryString from 'hooks/useQueryString';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';
import ErrorDrawer from 'sharedComponents/Boundaries/components/ErrorDrawer';
import { ErrorDrawerContent } from 'sharedComponents/Boundaries/type';
import { recordErrorWithSentry } from 'utils/sentry';

type Props = {
  error?: unknown;
};

const Error = ({ error }: Props) => {
  const [errorBackStep, setErrorBackStep] = useRecoilState(errorBackStepAtom);
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const errorType = getSearchParams('type') as 'not-connected' | '401' | '403' | 'default';

  const isNetworkError = errorType === 'not-connected';
  const isUnauthorized = errorType === '401';
  const isForbidden = errorType === '403';

  const routeError = useRouteError();

  useEffect(() => {
    // 에러타입이 없는 경우 ApiErrorFallback을 거치지 않은 에러
    // 글로벌 에러바운더리나 라우터에서 전달되었을 에러를 기록함
    if (!errorType) {
      const errorTarget = error ?? routeError;
      recordErrorWithSentry(errorTarget);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goSpecificPage = useCallback(
    (url: string) => {
      navigate(url, { replace: true });
    },
    [navigate],
  );

  const goBack = useCallback(() => {
    const backStep = errorBackStep ?? -1;
    if (typeof backStep === 'number') {
      navigate(backStep);
    } else {
      navigate(backStep.to, backStep.options);
    }
    setErrorBackStep(null);
  }, [errorBackStep, navigate, setErrorBackStep]);

  const content: ErrorDrawerContent = useMemo(() => {
    if (isNetworkError) {
      return {
        header: <TitleWithSubText title="인터넷에 연결되지 않았습니다." subTitle="연결 확인 후 다시 시도해 주세요." />,
        image: errorNotConnected,
        buttonText: '재시도',
        buttonType: 3 as 3 | 4,
        buttonClick: goBack,
      };
    }

    if (isUnauthorized) {
      return {
        header: (
          <TwoLinedTitle
            title={
              <Typography size={19} weight={700}>
                인증이 만료되어
                <br />
                재로그인이 필요합니다.
              </Typography>
            }
          />
        ),
        image: errorUnauthorized,
        buttonText: '로그인',
        buttonType: 3 as 3 | 4,
        buttonClick: () => goSpecificPage('/login'),
      };
    }

    if (isForbidden) {
      return {
        header: <TitleWithSubText title="권한 없음" subTitle="페이지에 접근할 수 없습니다." />,
        image: errorTemporaryError,
        buttonText: '이전 화면으로',
        buttonType: 3 as 3 | 4,
        isShowContact: false,
        hasImageBottomPadding: false,
        buttonClick: goBack,
      };
    }

    return {
      header: <TitleWithSubText title="일시적인 오류입니다." subTitle="잠시 후 다시 시도해 주세요." />,
      image: errorTemporaryError,
      buttonText: '첫 화면으로',
      buttonText2: '이전 화면으로',
      buttonType: 4 as 3 | 4,
      buttonClick: () => goSpecificPage('/schedule'),
      buttonClick2: goBack,
    };
  }, [goBack, goSpecificPage, isForbidden, isNetworkError, isUnauthorized]);

  return <ErrorDrawer content={content} goBack={goBack} />;
};

export default Error;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon, { IconType } from 'components/Icon';
import Typography from 'components/Typography';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type AccordionProps = {
  /** Accordion 제목 텍스트 */
  header?: string | ReactNode;
  /** Accordion 이 열렸을 때 내용물 */
  children?: ReactNode;
  /** header 좌측의 아이콘 */
  leftIcon?: ReactNode;
  /** header와 좌측 아이콘 사이의 gap */
  gap?: number;
  /** Accordion 형태 변경 (기본값: large) */
  size?: 'medium' | 'large' | 'none';
  /** 우측 화살표 아이콘 노출 유무 */
  showArrow?: boolean;
  /** 우측 화살표 사이의 gap */
  arrowGap?: number;
  /** Open 여부를 제어하는 값 (Controlled) */
  isOpen?: boolean;
  /** 초기에 Open 되어야할 때 사용 */
  defaultOpen?: boolean;
  /** Accordion 눌렀을 때 */
  onChange?: () => void;
  className?: string;
  customHeight?: number;
};

const Accordion = ({
  header,
  children,
  leftIcon,
  gap,
  size = 'large',
  showArrow = true,
  arrowGap,
  isOpen: controlledIsOpen,
  defaultOpen,
  onChange,
  className,
  customHeight,
}: AccordionProps) => {
  const [isOpenInternal, setIsOpenInternal] = useState(defaultOpen);
  const isOpen = controlledIsOpen ?? isOpenInternal;
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (controlledIsOpen !== undefined) setIsOpenInternal(controlledIsOpen);
  }, [controlledIsOpen]);

  const handleClick = useCallback(() => {
    if (controlledIsOpen === undefined) setIsOpenInternal(!isOpen);

    if (onChange) onChange();
  }, [isOpen, controlledIsOpen, onChange]);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      if (isOpen) contentElement.style.maxHeight = `${customHeight || contentElement.scrollHeight}px`;
      else contentElement.style.maxHeight = `0px`;
    }
  }, [isOpen, contentRef.current?.scrollHeight, customHeight]);

  const isLargeSize = useMemo(() => size === 'large', [size]);

  const ArrowIcon = useMemo<IconType>(() => {
    const position = isOpen ? 'Top' : 'Bottom';
    const weight = isLargeSize ? '' : 'Bold';

    return `arrow${position}${weight}`;
  }, [isLargeSize, isOpen]);

  return (
    <Container className={`accordion${isOpen ? ' opened' : ''} ${className ?? ''}`} gap={gap} size={size} arrowGap={arrowGap}>
      <div onClick={handleClick} className={`header-wrapper accordion__${size}`}>
        <div className="header-box">
          {leftIcon && leftIcon}
          {typeof header === 'string' ? (
            <Typography size={15} weight={isLargeSize ? 600 : 500}>
              {header}
            </Typography>
          ) : (
            header
          )}
        </div>
        {showArrow && <Icon name={ArrowIcon} fillColor={theme.color.gray3} size={isLargeSize ? 24 : 16} />}
      </div>
      <div ref={contentRef} className="content-wrapper">
        {children}
      </div>
    </Container>
  );
};

const Container = styled.div<Pick<AccordionProps, 'gap' | 'size' | 'arrowGap'>>`
  ${theme.flex('column', '', '')};
  position: relative;

  .header-wrapper {
    ${({ arrowGap }) => theme.flex('', 'center', 'space-between', arrowGap || 0)};
    height: ${({ size }) => (size === 'none' ? '100%' : '60px')};
    width: 100%;
    padding: ${({ size }) => (size === 'none' ? '0' : '0 20px')};
    &.accordion {
      &__medium {
        height: 56px;
      }
    }

    .header-box {
      ${({ gap }) => theme.flex('row', 'center', '', gap || 10)};
      width: 100%;
    }
  }

  .content-wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease;
  }
`;

export default Accordion;

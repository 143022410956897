import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetRolePermissions = (roleId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<PermissionResponse>({
    url: `/api/role/${roleId}/permissions`,
  });

  return useSuspenseQuery({
    queryKey: [`permission`, roleId],
    queryFn,
    select: data => data.data,
  });
};

export default useGetRolePermissions;

type PermissionResponse = Permission[];

type Permission = {
  id: number;
  name: string;
  display_name: string;
  create_at: string;
  updated_at: string;
};

import useQueryString from 'hooks/useQueryString';
import { AllPageModeType } from 'pages/Booking/types';
import { getFormHeaderTitle } from 'pages/Booking/utils';
import { PropsWithChildren, useMemo } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import LeaveDialog from 'sharedComponents/LeaveDialog';
import MainLayout from 'sharedComponents/MainLayout';

import FormDetailSetting from './FormDetailSetting';

type Props<T extends FieldValues> = UseFormReturn<T> & {
  pageMode: AllPageModeType;
};

const GroupFormMainLayout = <T extends FieldValues>({ children, pageMode, ...methods }: PropsWithChildren<Props<T>>) => {
  const { getAllSearchParams } = useQueryString();
  const { type, setting } = getAllSearchParams();

  const headerTitle = useMemo(() => {
    if (pageMode === 'update' && type === 'otherDetailTime') {
      return '당일예약변경/대기예약';
    }
    return getFormHeaderTitle({ bookingType: 'group', mode: pageMode, type, setting });
  }, [pageMode, setting, type]);

  return (
    <MainLayout
      header={{
        title: headerTitle,
      }}>
      <FormProvider {...methods}>
        {/* 상세 설정 페이지 */}
        <FormDetailSetting type={type} pageMode={pageMode} />
        {!type && children}
        <LeaveDialog />
      </FormProvider>
    </MainLayout>
  );
};

export default GroupFormMainLayout;

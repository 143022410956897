import useToast from 'hooks/useToast';
import { ReactNode, useCallback, useEffect } from 'react';
import { Container } from 'sharedComponents/LoginExceptionContainer';

type Props = {
  children: ReactNode;
};

const StudioNoConnected = ({ children }: Props) => {
  const { setToast } = useToast();

  const openToast = useCallback(() => {
    setToast({
      message: '시설과 연동이 되어있지 않습니다.',
      bottom: 72,
    });
  }, [setToast]);

  useEffect(() => {
    openToast();
  }, [openToast]);

  return <Container onClick={openToast}>{children}</Container>;
};

export default StudioNoConnected;

import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import { ComponentProps } from 'react';

import ValidErrorCard from '../../Private/Form/components/SelectMembers/ValidErrorCard';

type Result = ComponentProps<typeof ValidErrorCard>;
type ListType = ComponentProps<typeof ValidErrorCard>['dateList'];

/**
 *  프라이빗 폼에서 수업 만들면서 회원 추가할 때,
 *  주/월간 초과 검증 drawer 에 보여줄 예약 초과 정보
 */
const getPrivateLimitValidArray = (data: BookingCountResponse): Array<Result> => {
  const array: Result[] = [];
  Object.entries(data).forEach(([name, history]) => {
    const dailyList: ListType = [];
    const weeklyList: ListType = [];
    const monthlyList: ListType = [];

    if (history.daily) {
      Object.entries(history.daily).forEach(([date, count]) => {
        dailyList.push({ date, count });
      });
    }

    if (history.weekly) {
      Object.entries(history.weekly).forEach(([date, count]) => {
        weeklyList.push({ date, count });
      });
    }

    if (history.monthly) {
      Object.entries(history.monthly).forEach(([date, count]) => {
        monthlyList.push({ date, count });
      });
    }

    if (dailyList.length > 0) {
      array.push({
        name,
        type: 'daily',
        typeLimit: history.daily_booking_limit,
        dateList: dailyList,
      });
    }

    if (weeklyList.length > 0) {
      array.push({
        name,
        type: 'weekly',
        typeLimit: history.booking_limit_per_week,
        dateList: weeklyList,
      });
    }

    if (monthlyList.length > 0) {
      array.push({
        name,
        type: 'monthly',
        typeLimit: history.booking_limit_per_month,
        dateList: monthlyList,
      });
    }
  });

  return array;
};

export default getPrivateLimitValidArray;

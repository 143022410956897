import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { MemberCreateParams } from '../mutations/usePostMember';

const useGetMemberDetail = (memberId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<Response>({
    url: `/api/member/detail/${memberId}`,
  });

  return useSuspenseQuery({
    queryKey: ['member', memberId],
    queryFn,
    select: data => data.data.data,
    gcTime: 0,
  });
};

export default useGetMemberDetail;

type Response = {
  data: MemberDetailResponse;
};

export type MemberDetailResponse = {
  id: number;
  studio: { id: number; name: string };
  name: string;
  account_type: string;
  account_id: number;
  mobile: string | null;
  vaccination_yn: string;
  avatar: AccountAvatar | null;
  profile: Profile;
  address: MemberCreateParams['address'] | null;
  account: Account;
  user_grade: UserGrade | null;
  lockers: Locker[];
  policy: {
    is_locker: boolean;
    is_use_user_grade: boolean;
  };
};

export type Account = {
  id: number;
  email: string;
  name: string;
  mobile: string;
};

export type AccountAvatar = {
  id: number;
  owner_type: string;
  owner_id: number;
  is_representative: boolean;
  image: Image;
  created_at: string;
  updated_at: string;
  deleted_at: null;
};

export type Image = {
  extension: string;
  path: string;
  name: string;
};

export type Profile = {
  id: number;
  studio_user_id: number;
  name: string;
  gender: string | null;
  birthday: string | null;
  registered_at: string;
  last_attendance_at: string;
  paid_point: number;
  reward_point: number;
};

export type UserGrade = {
  id: number;
  studio_id: number;
  name: string;
  representative_color: string;
};
export type Locker = {
  id: number;
  member_id: number;
  no: number;
  name: string;
  start_on: string;
  end_on: string;
};

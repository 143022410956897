import styled from '@emotion/styled';
import Drawer from 'designedComponents/Drawers';
import TitleWithImage from 'designedComponents/Drawers/DrawerTitle/TitleWithImage';
import { Studio } from 'hooks/service/queries/useGetStudio';
import { STUDIO_DETAIL_TEXTS } from 'pages/MoreDetails/Studio/constants';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import StudioDetailInformation from './StudioDetailInformation';
import StudioDetailSkeleton from './StudioDetailSkeleton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  studio: Studio | null;
  studioName?: string;
};

const StudioDetailDrawer = ({ isOpen, onClose, studio, studioName }: Props) => {
  const name = studio && studioName ? studioName : '';
  return (
    <StyledDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<TitleWithImage infoText={STUDIO_DETAIL_TEXTS.info} title={name} hasLine />}>
      {!studio ? (
        <StudioDetailSkeleton />
      ) : (
        <ApiBoundary fallback={<StudioDetailSkeleton />}>
          <StudioDetailInformation studioId={studio.id} />
        </ApiBoundary>
      )}
    </StyledDrawer>
  );
};

export default StudioDetailDrawer;

const StyledDrawer = styled(Drawer)`
  padding-bottom: 0;
`;

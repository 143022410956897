import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';
import { CommonStyleCard, CommonStyledCardTopWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

type Props = {
  data: {
    settlementAt: string;
    detail: InfoValueRowType[];
  };
};

const PaymentDetailCard = ({ data }: Props) => {
  const { settlementAt, detail } = data;
  return (
    <CommonStyleCard className="payment-detail-card">
      <CardTopWrapper>
        <Typography size={13} textColor="gray2">
          결제일시
        </Typography>
        <Typography size={15} weight={600}>
          {filters.dateTimeSecondWeekday(settlementAt)}
        </Typography>
      </CardTopWrapper>
      <InfoFlexWrapper gap={8}>
        {detail.map(({ label, value, textColor }) => (
          <InfoFlexRow className="info-flex-row" key={`${label}-${value}`}>
            <InfoTypography size={14}>{label}</InfoTypography>
            <InfoTypography size={14} weight={500} textColor={textColor || 'gray2'}>
              {value}
            </InfoTypography>
          </InfoFlexRow>
        ))}
      </InfoFlexWrapper>
    </CommonStyleCard>
  );
};

export default PaymentDetailCard;

const CardTopWrapper = styled(CommonStyledCardTopWrapper)`
  ${theme.flex('column', '', '', 4)};
  padding-bottom: 12px;
  margin-bottom: 12px;

  .info-flex-row {
    align-items: flex-start;
  }
`;

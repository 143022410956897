import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { ButtonBaseProps } from 'components/Button/ButtonBase';

import TabBase, { TabBaseProps } from './TabBase';

export type FilterTabBaseProps = {
  /** true일 때, 선택된 상태 */
  isSelected: boolean;
  /** 선택된 Tab의 배경색 (Button 컴포넌트의 color와 동일) */
  selectedColor?: ButtonBaseProps['color'];
  /** 선택되지 않은 Tab의 배경색 (Button 컴포넌트의 color와 동일) */
  defaultColor?: ButtonBaseProps['color'];
};

export type FilterTabProps = FilterTabBaseProps & TabBaseProps;

const FilterTab = ({ data, isSelected, defaultColor = 'gray', selectedColor, heightSize = 32, ...props }: FilterTabProps) => {
  return (
    <StyledFilterTabBase
      data={data}
      edge="circular"
      heightSize={heightSize}
      variant="contained"
      isSelected={isSelected}
      color={isSelected ? selectedColor : defaultColor}
      textColor={isSelected ? 'white' : 'gray1'}
      fontWeight={isSelected ? 600 : 400}
      {...props}
    />
  );
};

export default FilterTab;

/**
 * button gray 색상이 size에 따라 달라지는데
 * tab 높이와 맞는 사이즈 없어서 커스텀
 */
const StyledFilterTabBase = styled(TabBase)<Pick<FilterTabProps, 'isSelected'>>`
  ${({ isSelected }) =>
    !isSelected &&
    css`
      background-color: ${theme.color.gray7};
    `}
`;

import styled from '@emotion/styled';
import Typography, { TypographyProps } from 'components/Typography';

const PaymentFormTitle = ({ children, ...props }: TypographyProps) => {
  return (
    <StyledTypography size={15} weight={600} {...props}>
      {children}
    </StyledTypography>
  );
};

export default PaymentFormTitle;

const StyledTypography = styled(Typography)`
  padding: 24px 20px 12px;
`;

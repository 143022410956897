import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';

import MessageFormLabel from '../../../components/MessageFormLabel';
import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';

const Targets = () => {
  const { control, getValues, setValue } = useFormContext<MobileFormType>();

  const target = useWatch({ control, name: 'target' });
  const targets = useWatch({ control, name: target === 'member' ? 'users' : 'counseling_logs' });

  const [isResetDrawerOpened, setIsResetDrawerOpened] = useState(false);

  const navigate = useNavigate();

  // 발송대상 선택 페이지로 이동
  const navigateToTarget = (selectedTarget: 'member' | 'counsel') => {
    navigate(`/more-details/message/target?type=${selectedTarget}`, { state: getValues(), replace: true });
  };

  // 발송대상 선택시 이전 선택값이 있을 경우 초기화 팝업
  const selectTarget = (selectedTarget: 'member' | 'counsel') => {
    if (target && target !== selectedTarget) {
      setIsResetDrawerOpened(true);
      return;
    }
    navigateToTarget(selectedTarget);
  };

  // 초기화 선택시 기존 타겟이 아닌 타겟 선택 페이지로 이동
  const reselectTarget = () => {
    setValue('target', undefined);
    setValue(target === 'member' ? 'users' : 'counseling_logs', []);
    setValue('filter', null); // 검색필터도 초기화
    navigateToTarget(target === 'member' ? 'counsel' : 'member');
  };

  return (
    <>
      <TargetLabel
        labelText={
          <>
            {MOBILE_FORM_TEXT.target}
            <b>{filters.numberComma(targets?.length ?? 0)}</b>
          </>
        }
      />
      <Row>
        <Button
          onClick={() => selectTarget('member')}
          edge="circular"
          heightSize={32}
          variant="outlined"
          padding={{ left: 16, right: 16 }}
          fontSize={14}
          fontWeight={target === 'member' ? 600 : 400}
          textColor={target === 'member' ? 'primary' : 'gray2'}
          outlineColor={target === 'member' ? 'primary' : undefined}>
          {MOBILE_FORM_TEXT.targetMember}
        </Button>
        <Button
          onClick={() => selectTarget('counsel')}
          edge="circular"
          heightSize={32}
          variant="outlined"
          padding={{ left: 16, right: 16 }}
          fontSize={14}
          fontWeight={target === 'counsel' ? 600 : 400}
          textColor={target === 'counsel' ? 'primary' : 'gray2'}
          outlineColor={target === 'counsel' ? 'primary' : undefined}>
          {MOBILE_FORM_TEXT.targetCounsel}
        </Button>
      </Row>
      <DoubleButtonDrawer
        type={3}
        isOpen={isResetDrawerOpened}
        onClose={() => setIsResetDrawerOpened(false)}
        header={<OneLinedTitle title={MOBILE_FORM_TEXT.targetReset.title} />}
        leftButton={{ text: MOBILE_FORM_TEXT.targetReset.cancel, onClick: () => setIsResetDrawerOpened(false) }}
        rightButton={{ text: MOBILE_FORM_TEXT.targetReset.confirm, onClick: reselectTarget }}>
        <Typography size={15} tag="pre">
          {MOBILE_FORM_TEXT.targetReset.message}
        </Typography>
      </DoubleButtonDrawer>
    </>
  );
};

export default Targets;

const TargetLabel = styled(MessageFormLabel)`
  b {
    font-weight: 600;
    color: ${theme.color.primary};
    margin-left: 4px;
  }
`;

const Row = styled.div`
  ${theme.flex('', 'center', 'start', 6)}
  margin: 12px 0 16px;
`;

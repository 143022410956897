import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { FieldType } from 'pages/MoreDetails/Staff/Form/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ScheduleFormType, StaffProfileFormType } from 'sharedComponents/ProfileField/types';

import { ALL_DAYS } from '../constants';
import WorkTimeCard from './WorkTimeCard';

const WorkTimeCardList = () => {
  const { control } = useFormContext<StaffProfileFormType>();

  const {
    fields: workTimeFields,
    update: updateWorkTime,
    replace: replaceWorkTimeFields,
  } = useFieldArray({ control, name: 'schedules.workTimes' });
  const {
    fields: restTimeFields,
    update: updateRestTime,
    remove: removeRestTime,
    append: appendRestTime,
    replace: replaceRestTimeFields,
  } = useFieldArray({ control, name: 'schedules.restTimes' });

  const update = (index: number, value: ScheduleFormType, type: FieldType) => {
    if (type === 'work') {
      updateWorkTime(index, value);
    } else {
      updateRestTime(index, value);
    }
  };

  const replaceFields = (newFields: ScheduleFormType[], type: FieldType) => {
    if (type === 'work') {
      replaceWorkTimeFields(newFields);
    } else {
      replaceRestTimeFields(newFields);
    }
  };

  return (
    <Container>
      {ALL_DAYS.map(({ value }, index) => {
        return (
          <WorkTimeCard
            key={value}
            index={index}
            workTimeField={workTimeFields[index]}
            workTimeFieldsOrigin={workTimeFields}
            restTimeFields={restTimeFields.filter(({ day_of_week }) => day_of_week === value)}
            updateTime={(fieldIndex: number, value: ScheduleFormType, type: FieldType) => update(fieldIndex, value, type)}
            removeRestTime={(fieldIndex: number) => removeRestTime(fieldIndex)}
            appendRestTime={(value: ScheduleFormType) => appendRestTime(value)}
            replaceFields={(newFields: ScheduleFormType[], type: FieldType) => replaceFields(newFields, type)}
            restTimeFieldsOrigin={restTimeFields}
          />
        );
      })}
    </Container>
  );
};

export default WorkTimeCardList;

const Container = styled.section`
  padding: 20px;
  background-color: ${theme.color.gray6};
`;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostHoliday = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<HolidayParams>({
    method: 'post',
    url: '/api/holiday',
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['holidays'],
      });
    },
  });
};

export default usePostHoliday;

type HolidayParams = {
  date: string;
  not_action_auto_balance: boolean;
};

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { TICKET_INACTIVE_TEXT } from 'pages/TicketDetail/constants';
import { TicketInactiveFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';
import TicketCard from 'sharedComponents/TicketCard';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import { ticketInactiveReason } from 'utils/validate';
import { object } from 'yup';

import ReasonForm from './ReasonForm';

const TicketInactive = ({ userTicket }: UserTicketProps) => {
  const yupSchema = object().shape({ ticketInactiveReason });

  const methods = useForm<TicketInactiveFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      userTicket,
      ticketInactiveReason: '',
    },
  });

  return (
    <MainLayout header={{ title: TICKET_INACTIVE_TEXT.title }}>
      <FormProvider {...methods}>
        <Container>
          <div className="wrapper">
            <Typography size={13} weight={500} textColor="gray2">
              수강권 정보
            </Typography>
            <TicketCard ticket={formatSmallUserTicketCard(userTicket)} />
          </div>
          <ReasonForm />
        </Container>
      </FormProvider>
    </MainLayout>
  );
};

export default TicketInactive;

const Container = styled.div`
  height: 100%;
  padding-top: 16px;
  ${theme.flex('column', '', '', 32)}

  .wrapper {
    padding: 0 20px;
  }

  .ticket-card {
    margin-top: 8px;
    padding: 16px;
    border-radius: 12px;
    background-color: ${theme.color.gray7};
  }
`;

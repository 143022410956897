import styled from '@emotion/styled';
import Button from 'components/Button';
import SubTitle from 'components/SubTitle';
import MainLayout from 'sharedComponents/MainLayout';
import { postMessageToApp } from 'utils/communicateWithNative';

import { SETTINGS_PUSH_TEXT } from './constants';

const MoreDetailsSettingsPush = () => {
  return (
    <MainLayout header={{}}>
      <Container>
        <SubTitle
          title={SETTINGS_PUSH_TEXT.title}
          message={{ position: 'bottom', text: SETTINGS_PUSH_TEXT.subTitle }}
          bottom={40}
        />
        <Button size="medium48" variant="contained" fullWidth color="gray" onClick={() => postMessageToApp('OPEN_SETTING')}>
          {SETTINGS_PUSH_TEXT.button}
        </Button>
      </Container>
    </MainLayout>
  );
};

export default MoreDetailsSettingsPush;
const Container = styled.main`
  padding: 8px 20px;
`;

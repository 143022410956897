import useQueryString from 'hooks/useQueryString';
import { BookingGroupCreateFormType } from 'pages/Booking/Group/types';
import { stringTime } from 'pages/Booking/utils/convertTimes';
import { useFormContext } from 'react-hook-form';

import FieldButton from '../../../../components/AvailableTime/FieldButton';

const GroupBookingAvailableField = () => {
  const { getValues } = useFormContext<BookingGroupCreateFormType>();
  const {
    policy: { booking, bookingCancel },
  } = getValues();

  const { getAllSearchParams, setSearchParams } = useQueryString();
  const clickField = (detail: 'booking' | 'bookingCancel') => {
    setSearchParams({
      ...getAllSearchParams(),
      type: 'bookingCancelTime',
      detail,
    });
  };

  const bookingTime = stringTime(booking);
  const bookingCancelTime = stringTime(bookingCancel);

  return (
    <>
      <FieldButton showIcon label="예약 가능 시간" time={bookingTime} onClick={() => clickField('booking')} />
      <FieldButton label="예약취소 가능 시간" time={bookingCancelTime} onClick={() => clickField('bookingCancel')} />
    </>
  );
};

export default GroupBookingAvailableField;

import { ETC_SCHEDULE_PERMISSION } from 'pages/EtcSchedule/constants';
import { StaffPermissionType } from 'pages/EtcSchedule/types';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { PermissionType } from 'types/permissionTypes';

import { EtcScheduleDetailResponse } from './service/queries/useGetEtcScheduleEtcScheduleTime';
import usePermission from './usePermission';

export type EtcDetailPermissionKeyType = 'view' | 'create' | 'update' | 'delete';
const initialValue: Record<EtcDetailPermissionKeyType, boolean> = {
  view: true,
  create: false,
  update: false,
  delete: false,
};

/**
 * 기타 일정 상세 페이지에서 강사별 일정 생성/수정/삭제 권한 체크
 * @param staffs 기타 일정에 포함되어 있는 강사 리스트
 */
const useCheckPermissionEtcDetail = (staffs: EtcScheduleDetailResponse['staffs']) => {
  const currentStaffId = useRecoilValue(staffIdAtom);

  const { hasPermission, everyPermissions } = usePermission();
  const staffType: StaffPermissionType = useMemo(() => {
    /** 등록된 강사가 나만 있을 때 */
    const isOnlyMe = staffs.length === 1 && staffs[0].id === currentStaffId;
    /** 등록된 강사가 다른 강사만 있을 때 */
    const isOnlyOthers = !!staffs.length && !staffs.some(({ id }) => id === currentStaffId);

    if (isOnlyMe) return 'mine';
    if (isOnlyOthers) return 'others';
    return 'all';
  }, [currentStaffId, staffs]);

  const {
    view: canViewEtcSchedule,
    create: canCreateEtcSchedule,
    update: canUpdateEtcSchedule,
    delete: canDeleteEtcSchedule,
  } = Object.entries(ETC_SCHEDULE_PERMISSION[staffType]).reduce((acc, [key, value]) => {
    acc[key as EtcDetailPermissionKeyType] =
      staffType === 'all' ? everyPermissions(value as PermissionType[]) : hasPermission(value);
    return acc;
  }, initialValue);

  return {
    canViewEtcSchedule: staffType === 'all' ? true : canViewEtcSchedule,
    canCreateEtcSchedule,
    canUpdateEtcSchedule,
    canDeleteEtcSchedule,
    staffType,
  };
};

export default useCheckPermissionEtcDetail;

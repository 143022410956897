import useInfinityHistory from 'hooks/service/queries/useInfinityHistory';
import { CurrentMemberIdProps, HistoryFilterFormType } from 'pages/MemberDetail/types';
import splitUserTicketHistoryByDate from 'pages/MemberDetail/utils/splitUserTicketHistoryByDate';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';

import HistoryDividedByDate from './HistoryDividedByDate';

const BookingHistory = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { watch } = useFormContext<HistoryFilterFormType>();
  const { status, sort, ticketId } = watch();

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfinityHistory({
    member_id: currentMemberId,
    user_ticket_id: ticketId === 'all' ? undefined : Number(ticketId),
    status: status === 'all' ? undefined : status,
    order_by: sort,
  });

  /** 예약 리스트 날짜별로 묶어서 배열로 가공 */
  const splittingArray = useMemo(() => {
    return splitUserTicketHistoryByDate(data);
  }, [data]);

  return (
    <>
      {splittingArray.length ? (
        <InfiniteScroll showMoreButton hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          <HistoryDividedByDate data={splittingArray} />
        </InfiniteScroll>
      ) : (
        <NodataTypography>이용내역이 없습니다.</NodataTypography>
      )}
    </>
  );
};

export default BookingHistory;

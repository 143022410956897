import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Drawer from 'components/Drawer';
import { TitleType } from 'components/SubTitle';
import React, { SetStateAction, useMemo, useState } from 'react';

import SelectButton from './components/SelectButton';
import SelectOption from './components/SelectOption';

export interface ISelectProps {
  /**
   * Select를 활성화 했을 때 노출되는 형태
   */
  type?: 'dropdown' | 'drawer';
  /**
   * small: 40px, large: 48px
   */
  height?: 'small' | 'large';
  /**
   * Select 선택 옵션 리스트
   */
  option: React.ReactNode;
  /**
   * 메뉴 노출 여부
   */
  isOpen: boolean;
  /**
   * 메뉴 노출 트리거
   */
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  /**
   * 현재 선택된 값
   */
  value?: string;
  /**
   * 메뉴 닫기 함수
   */
  onClose: () => void;
  /**
   * 초기에 아무것도 선택하지 않았을 떄의 값
   */
  placeholder?: string;
  /**
   * Select를 읽기전용으로 비활성화
   */
  readOnly?: boolean;
  className?: string;
  /**
   * Select 비활성화
   */
  disabled?: boolean;
  /**
   * Dropdown 헤더 타이틀
   */
  headerText?: TitleType;
}

const Select = ({ type = 'drawer', height = 'small', className, headerText, ...props }: ISelectProps) => {
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const { isOpen, option, onClose, readOnly, setIsOpen } = props;

  const getHeight = useMemo(() => (value: number) => setDropdownHeight(value), []);

  const isSmall = useMemo(() => height === 'small', [height]);

  const containerProps = {
    type,
    isSmall,
    isOpen,
    readOnly,
  };

  const buttonProps = {
    type,
    isSmall,
    height,
    ...props,
  };

  /** dropdown (라운지 특정 부분에서만 사용) */
  if (type === 'dropdown' && isSmall) {
    return (
      <Container className="select-dropdown" {...containerProps} dropdownHeight={dropdownHeight}>
        <div className="select-wrapper" onClick={() => setIsOpen(!isOpen)} />
        <SelectButton {...buttonProps} />
        {isOpen && <SelectOption getHeight={getHeight} option={option} />}
      </Container>
    );
  }

  /** drawer */
  return (
    <Container className={`select-drawer ${className}`} {...containerProps}>
      <SelectButton {...buttonProps} />
      <Drawer isOpen={isOpen} onClose={onClose} headerText={headerText}>
        <SelectOption option={option} />
      </Drawer>
    </Container>
  );
};

export default Select;

type StyledContainerTypes = Pick<ISelectProps, 'type' | 'isOpen' | 'readOnly'> & { isSmall: boolean; dropdownHeight?: number };

const Container = styled.div<StyledContainerTypes>(
  ({ isSmall }) => css`
    ${theme.flex('column', 'center', 'center')}
    box-shadow: 0 0 0 1px ${theme.color.gray5} inset;
    border-radius: ${isSmall ? 6 : 10}px;

    .select-option {
      width: 100%;
      box-shadow: 0 -1px 0 0 ${theme.color.gray6};
      background-color: ${theme.color.white};
    }
  `,

  ({ readOnly }) =>
    readOnly &&
    css`
      background-color: ${theme.color.gray5};
      pointer-events: none;
    `,

  /** dropdown && small 케이스 (라운지 상품 옵션에만 사용) */
  ({ type, isOpen, isSmall, dropdownHeight }) => {
    const activeBorder = `1px solid ${theme.color.gray4}`;

    return (
      type === 'dropdown' &&
      isSmall &&
      css`
        position: relative;
        box-shadow: none;

        .select-wrapper {
          position: absolute;
          top: 0;
          width: 100%;
          height: ${isOpen ? `calc(40px + ${dropdownHeight}px)` : '40px'};
          box-shadow: 0 0 0 1px ${theme.color[isOpen ? 'gray4' : 'gray5']} inset;
          border-radius: 6px;
          pointer-events: none;
          z-index: 2;
        }

        .select-option {
          position: absolute;
          top: 40px;
          border-left: ${activeBorder};
          border-right: ${activeBorder};
          border-bottom: ${activeBorder};
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          z-index: 1;
        }
      `
    );
  },
);

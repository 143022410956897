import { FailItemType } from 'pages/Booking/utils/formatFailCard';
import filters from 'utils/filters';

import DetailResultItem from './DetailResultItem';

type Props = {
  data: Array<FailItemType>;
};

const BookingFailList = ({ data }: Props) => {
  return (
    <ul>
      {data.map((fail, index) => {
        const { staff, start_on, end_on, title, room, code } = fail;
        const date = `${filters.dateTimeWithWeekday(start_on)} ~ ${filters.time(end_on)}`;

        return (
          <DetailResultItem
            key={`${fail.start_on}-${index}`}
            date={date}
            staffName={staff}
            title={title}
            roomName={room}
            errorCode={code}
          />
        );
      })}
    </ul>
  );
};

export default BookingFailList;

import styled from '@emotion/styled';
import Label from 'components/Label';

import { MOBILE_STATUS } from '../Mobile/List/constants';

type Props = {
  status?: string;
  className?: string;
  width?: number;
};

const MessageLabel = ({ status, className, width }: Props) => {
  // 라벨은 예약 혹은 취소상황만
  if (status !== 'cancel' && status !== 'request') return null;
  return (
    <StyledLabel
      className={className}
      size="regular"
      opacity={0.12}
      color={MOBILE_STATUS[status]?.color}
      textColor={MOBILE_STATUS[status]?.color}
      // 목록에서는 54 고정, 상세에서는 상황별 width
      width={width ?? MOBILE_STATUS[status]?.width}>
      {MOBILE_STATUS[status]?.label}
    </StyledLabel>
  );
};

export default MessageLabel;

const StyledLabel = styled(Label)<{ width: number }>`
  width: ${({ width }) => width}px;
`;

import { BookingPrivateBulkParams } from 'hooks/service/mutations/usePatchLecturePrivateBulk';
import { BookingPrivateUpdateAllFormType } from 'pages/Booking/Private/types';

import formatBookingPrivateParams from './formatBookingPrivateParams';

const formatBookingPrivateUpdateAllParams = (
  formValues: BookingPrivateUpdateAllFormType,
): Omit<BookingPrivateBulkParams, 'start' | 'start_date'> => {
  const { policy } = formValues;
  const { start_date, ...rest } = formatBookingPrivateParams(formValues);

  return {
    ...rest,
    is_force: false,
    enter_start_deadline: policy.enterStartline,
    enter_end_deadline: policy.enterDeadline,
    private_booking_rule_type: policy.booking.ruleType,
    private_booking_startline: policy.booking.startline,
    private_booking_deadline: policy.booking.deadline,
    private_booking_start_days: policy.booking.startDays,
    private_booking_end_days: policy.booking.endDays,
    private_booking_start_time: policy.booking.startTime,
    private_booking_end_time: policy.booking.endTime,
    private_booking_cancel_rule_type: policy.bookingCancel.ruleType,
    private_booking_cancel_startline: policy.bookingCancel.startline,
    private_booking_cancel_deadline: policy.bookingCancel.deadline,
    private_booking_cancel_start_days: policy.bookingCancel.startDays,
    private_booking_cancel_end_days: policy.bookingCancel.endDays,
    private_booking_cancel_start_time: policy.bookingCancel.startTime,
    private_booking_cancel_end_time: policy.bookingCancel.endTime,
  };
};

export default formatBookingPrivateUpdateAllParams;

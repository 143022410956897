import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';

const RoomSettingDialog = () => {
  const { control, setValue } = useFormContext<BookingCommonFormType>();
  const needResetRoom = useWatch({ control, name: 'needResetRoom' });
  const roomId = useWatch({ control, name: 'roomId' });
  const target = needResetRoom ? BOOKING_COMMON_FORM_TEXT.room.resetType[needResetRoom] : '';

  const close = () => {
    setValue('needResetRoom', undefined, { shouldDirty: true });
  };

  return (
    <>
      {roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value && needResetRoom && (
        <Dialog isError onClose={close} positiveAction={{ text: '확인', onClick: close }}>
          <DialogDescription>
            {target} 변경되어 설정된 룸이
            <br />
            초기화 되었습니다.
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default RoomSettingDialog;

import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostAuthMeInstanceTokenRegister = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AuthMeInstanceTokenRegisterParams>({
    method: 'post',
    url: '/api/auth/me/instance-token-register',
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostAuthMeInstanceTokenRegister;

type AuthMeInstanceTokenRegisterParams = {
  instance_token: string;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { counselSelectedFiltersAtom } from 'recoil/Member';

import { COUNSEL_MEMBER_CHECK } from '../constants';
import { MemberCounselSelectedFilterType } from '../types';

const CheckMember = () => {
  const [params, setParams] = useRecoilState(counselSelectedFiltersAtom);
  const [checkedArray, setCheckedArray] = useState<Array<MemberCounselSelectedFilterType['register_type']>>(
    params.register_type === 'none'
      ? []
      : params.register_type === 'both'
        ? ['registered', 'not_registered']
        : [params.register_type],
  );

  const changeCheckMember = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const checkedValue = e.target.value as Exclude<MemberCounselSelectedFilterType['register_type'], 'both' | 'none'>;

      if (checkedArray.includes(checkedValue)) {
        const remain = checkedArray.filter(temp => temp !== checkedValue);
        const register_type = !remain.length ? 'none' : remain.length === 2 ? 'both' : remain[0];
        setCheckedArray(remain);
        setParams({ ...params, register_type });
      } else {
        const checked = [...checkedArray, checkedValue];
        const register_type = checked.length === 2 ? 'both' : checked[0];
        setCheckedArray([...checkedArray, checkedValue]);
        setParams({ ...params, register_type });
      }
    },
    [checkedArray, params, setParams],
  );

  return (
    <Container>
      {COUNSEL_MEMBER_CHECK.map(({ id, label, value }) => (
        <CheckBox
          key={id}
          id={id}
          variant="simple"
          label={label}
          value={value}
          labelFontsize={13}
          gap={4}
          onChange={changeCheckMember}
          checked={checkedArray.includes(value)}
        />
      ))}
    </Container>
  );
};

export default CheckMember;

const Container = styled.div`
  margin-left: auto;
  ${theme.flex('', 'center', '', 8)}
`;

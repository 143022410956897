import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureBookingListResponse } from '../queries/useInfinityBookingList';
import { ChangePrivateBookingStatusParams } from './usePatchBookingPrivate';

/** 그룹 수업 예약 변경(예약을 노쇼, 출석, 결석 처리함) */
const usePatchBookingGroup = (booking: LectureBookingListResponse) => {
  const { id, lecture_id } = booking;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<ChangePrivateBookingStatusParams>({
    method: `patch`,
    url: `/api/booking/group/${id}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['booking', 'list', lecture_id],
      });
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lecture_id],
      });
    },
  });
};

export default usePatchBookingGroup;

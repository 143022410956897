import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import usePostAuthChangeAuthenticatedMobile from 'hooks/service/mutations/usePostAuthChangeAuthenticatedMobile';
import usePostCodeConfirm from 'hooks/service/mutations/usePostCodeConfirm';
import usePostSendMobileConfirmCode from 'hooks/service/mutations/usePostSendMobileConfirmCode';
import useErrorDialog from 'hooks/useErrorDialog';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import useToast from 'hooks/useToast';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import { validationIdCodeAtom } from 'recoil/validationIdCode';
import { CustomError } from 'types/errorTypes';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';
import ChangePhoneFooterButton from './ChangePhoneFooterButton';
import PhoneNumberInput from './PhoneNumberInput';
import ValidationCodeInput from './ValidationCodeInput';
import ValidationCompleted from './ValidationCompleted';
import ValidationTimer from './ValidationTimer';

const ChangePhoneForm = () => {
  const { setValue, setError, handleSubmit, getValues, clearErrors } = useFormContext<ChangePhoneNumberFormType>();

  const submit = ({ step }: ChangePhoneNumberFormType) => {
    if (step === 'number') {
      sendCodeToMobile();
    } else if (step === 'validationCode') {
      validateCode();
    } else if (step === 'confirm') {
      changePhoneNumber();
    }
  };

  // 인증번호 발송
  const { mutate: sendConfirmCode, isPending: isSendingConfirmCode } = usePostSendMobileConfirmCode({
    mobile: getValues('mobileRequired'),
  });
  const sendCodeToMobile = () => {
    clearErrors('mobileConfirmCode');
    setValue('mobileConfirmCode', ''); // 인증번호 입력값 초기화
    sendConfirmCode(undefined, {
      onSuccess: ({ data: { validation_code } }) => {
        setValue('step', 'validationCode');
        setValue('validationId', validation_code);
      },
      onError: error => {
        const message = error?.response?.data?.message;
        const code = error?.response?.data?.code;
        if (code === 409) {
          setError('mobileRequired', { message }); // "이미 등록된 번호입니다."
          return false; // 기본 에러처리 dialog 방지
        }
        // 그외 기본 에러처리
      },
    });
  };

  // 인증번호 검증
  const { mutate: validateConfirmCodeMutate } = usePostCodeConfirm({ type: 'mobile-change' });
  const setValidationValues = useSetRecoilState(validationIdCodeAtom);
  const validateCode = () => {
    setValidationValues({ id: getValues('validationId'), code: getValues('mobileConfirmCode') });
    validateConfirmCodeMutate(undefined, {
      onSuccess: () => {
        setValue('step', 'confirm');
      },
      onError: (error: CustomError) => {
        setError('mobileConfirmCode', { message: error?.response?.data?.message });
      },
    });
  };

  // 휴대폰번호 변경
  const { mutate: changeNumberMutate } = usePostAuthChangeAuthenticatedMobile();
  const resetStudioConnections = useResetAllRecoilState([studioIdAtom, studioIdAtom]);
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const changePhoneNumber = () => {
    const params = { mobile: getValues('mobileRequired') };
    changeNumberMutate(params, {
      onSuccess: () => {
        setValue('isCompleted', true, { shouldDirty: true }); // 경고 다이얼로그 무시
        navigate(-1);
        setToast({ type: 'success', message: CHANGE_PHONE_NUMBER_TEXT.success });
        // 번호가 변경되면 시설연동이 모두 끊김
        resetStudioConnections();
      },
      onError: error => errorDialog.open(error),
    });
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(submit)}>
        <PhoneNumberInput />
        <ValidationCodeInput onResendClick={sendCodeToMobile} resendDisabled={isSendingConfirmCode} />
        <ValidationTimer />
        <ValidationCompleted />
        <ChangePhoneFooterButton />
      </StyledForm>
    </>
  );
};

export default ChangePhoneForm;

const StyledForm = styled(Form)`
  ${theme.flex('column', '', '', 8)}
  .outlined-text-field:nth-of-type(2) {
    .error {
      white-space: pre-line;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';

type Props = {
  currentKeyword?: string;
  noDataText?: string;
};

const NoData = ({ currentKeyword, noDataText }: Props) => {
  return (
    <Container>
      {currentKeyword ? (
        <NoSearchDataTypography keyword={currentKeyword} />
      ) : (
        <Typography size={15} textColor="gray2" opacity={0.8}>
          {`${noDataText ?? '검색 결과가 없습니다.'}`}
        </Typography>
      )}
    </Container>
  );
};

export default NoData;

const Container = styled.section`
  ${theme.flex()};
  padding: 160px 0;
  background-color: ${theme.color.gray6};
`;

import Icon from 'components/Icon';
import dayjs from 'dayjs';
import MonthPickerDrawer from 'designedComponents/Pickers/MonthPicker/MonthPickerDrawer';
import { PointPeriodParamsType } from 'pages/MemberDetail/types';
import { useEffect, useState } from 'react';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterProps } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

type Props = Pick<FilterProps, 'onClick'> & {
  previousOptions: PointPeriodParamsType;
};

const PeriodSelectMonth = ({ previousOptions, onClick: changeOptions }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState([previousOptions.start_date, previousOptions.end_date]);
  const monthText = filters.dateYearMonth(date[0]);

  const changeDate = (e: { value: Date }) => {
    const endValue = dayjs(e.value).endOf('month') > dayjs() ? dayjs() : dayjs(e.value).endOf('month');
    const startDate = filters.dateDash(dayjs(e.value).startOf('month'));
    const endDate = filters.dateDash(endValue);

    setDate([startDate, endDate]);
  };

  const save = () => {
    changeOptions(
      {
        value: {
          type: 'month',
          start_date: date[0],
          end_date: date[1],
        },
        label: '',
      },
      'period',
    );
    setIsOpen(false);
  };

  const cancelSelect = () => {
    setDate([previousOptions.start_date, previousOptions.end_date]);
    setIsOpen(false);
  };

  /** 기간선택 필터 초기화 시 버튼도 초기화 */
  useEffect(() => {
    setDate([previousOptions.start_date, previousOptions.end_date]);
  }, [previousOptions]);

  return (
    <>
      <ButtonWithRightIconOutlined
        className="month-picker-drawer-button"
        icon={<Icon name="calendar" color="gray1" />}
        textColor="gray1"
        onClick={() => setIsOpen(true)}>
        {monthText}
      </ButtonWithRightIconOutlined>

      <MonthPickerDrawer isOpen={isOpen} value={date[0]} onClose={cancelSelect} onChange={changeDate} onSave={save} />
    </>
  );
};

export default PeriodSelectMonth;

import Button from 'components/Button';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import usePopup from 'hooks/usePopup';
import StudioDeletePopup from 'sharedComponents/Studio/StudioDeletePopup';

import { STUDIO_DELETE_TEXTS } from '../constants';

const DeleteButton = () => {
  const { data } = useGetStudio();
  const { setPopup } = usePopup();

  if (data.studios.length <= 1) return null;
  return (
    <Button onClick={() => setPopup(<StudioDeletePopup />)} fontSize={15} fontWeight={600} textColor="gray2" textOpacity={0.8}>
      {STUDIO_DELETE_TEXTS.delete}
    </Button>
  );
};

export default DeleteButton;

import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { IHeaderProps } from 'components/Header';
import Icon from 'components/Icon';
import Textarea from 'components/Textarea';
import usePopup from 'hooks/usePopup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';
import PhotoArea from 'sharedComponents/MemoForm/PhotoArea';
import { MemoFormType } from 'sharedComponents/MemoForm/types';

import { MEMO_TEXT } from './constants';
import SaveButton from './SaveButton';

type Props = {
  formValues: MemoFormType;
};

const MemoForm = ({ formValues }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setPopup } = usePopup();

  const methods = useForm<MemoFormType>({
    defaultValues: formValues,
  });

  const {
    control,
    formState: { isDirty },
  } = methods;

  const closeMemoForm = useCallback(() => {
    if (isDirty) {
      setIsOpen(true);
    } else {
      setPopup(null);
    }
  }, [isDirty, setPopup]);

  const headerOption: IHeaderProps = useMemo(() => {
    return {
      leftButton: (
        <IconButton onClick={closeMemoForm}>
          <Icon name="headerClose" />
        </IconButton>
      ),
      title: MEMO_TEXT.title[formValues.memo_id ? 'update' : 'create'],
      rightButton: <SaveButton />,
    };
  }, [closeMemoForm, formValues.memo_id]);

  const setCustomBackHandler = useSetRecoilState(customBackHandlersAtom);
  useEffect(() => {
    setCustomBackHandler(prev => [...prev, { handler: closeMemoForm, key: 'memoForm' }]);

    return () => {
      setCustomBackHandler(prev => prev.filter(handler => handler.key !== 'memoForm'));
    };
  }, [closeMemoForm, setCustomBackHandler]);

  return (
    <>
      <FormProvider {...methods}>
        <MainLayout header={headerOption}>
          <Container>
            <Controller
              name="memo"
              control={control}
              render={({ field }) => <Textarea placeholder={MEMO_TEXT.placeholder} height={254} {...field} />}
            />
            <PhotoArea />
          </Container>
        </MainLayout>
      </FormProvider>

      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          negativeAction={{ text: MEMO_TEXT.formDialog.negativeText }}
          positiveAction={{ text: MEMO_TEXT.formDialog.positiveText, onClick: () => setPopup(null) }}>
          <DialogDescription>
            변경사항이 저장되지 않았습니다.
            <br /> 페이지를 나가시겠습니까?
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default MemoForm;

const Container = styled.div`
  padding: 16px 20px;
  height: 100%;
`;

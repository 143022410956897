import styled from '@emotion/styled';
import Icon, { IconType } from 'components/Icon';
import { DayTimePickerChangeParamsType } from 'designedComponents/Pickers/DayTimePicker';
import { AvailableTimeType } from 'designedComponents/Pickers/NumberPicker/AvailableTimePicker';
import { LectureRuleType } from 'hooks/service/queries/useGetLectureCourse';
import { BOOKING_RULE_TYPE_DATA } from 'pages/Booking/constants';
import { LecturePolicyFormType } from 'pages/Booking/types';
import { colonTimeToNum } from 'pages/Booking/utils/convertTimes';
import { ChangeEvent, useState } from 'react';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';

import ARType from '../RuleType/ARType';
import AType from '../RuleType/AType';
import CheckRadioRuleTypeDrawer from '../RuleType/CheckRadioRuleTypeDrawer';
import RRType from '../RuleType/RRType';
import RType from '../RuleType/RType';

type Props = {
  iconName: IconType;
  textType: 'booking' | 'cancel';
  isOpen: boolean;
  onToggle: (value: string | boolean) => void;
  tempPolicy: LecturePolicyFormType;
  changeTempPolicy: (value: LecturePolicyFormType) => void;
};

const SettingByRuleType = ({ tempPolicy, changeTempPolicy, ...props }: Props) => {
  const { textType } = props;
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [initialTempPolicy] = useState(tempPolicy);
  const [tempRuleType, setTempRuleType] = useState(tempPolicy.ruleType);
  const currentPolicy = { ...tempPolicy, ruleType: tempRuleType };

  const changeRuleType = (e: ChangeEvent<HTMLInputElement>) => {
    const newRuleType = e.target.value as LectureRuleType;
    changeTempPolicy({ ...initialTempPolicy, ruleType: newRuleType });
    setTempRuleType(newRuleType);
    setIsOpenDrawer(false);
  };

  const changeA = (values: DayTimePickerChangeParamsType) => {
    if (!values.start) {
      changeTempPolicy({
        ...currentPolicy,
        endDays: values.end.day,
        endTime: colonTimeToNum(values.end.time),
      });
    } else {
      changeTempPolicy({
        ...currentPolicy,
        startDays: values.start.day,
        startTime: colonTimeToNum(values.start.time),
        endDays: values.end.day,
        endTime: colonTimeToNum(values.end.time),
      });
    }
  };

  const changeR = (value: AvailableTimeType) => {
    const { hour, min } = value;
    const deadline = hour ? hour * 60 + min : min;
    changeTempPolicy({
      ...currentPolicy,
      deadline,
    });
  };

  const changeRR = (value: { left: AvailableTimeType; right: AvailableTimeType }) => {
    const { left, right } = value;
    const deadline = left.hour ? left.hour * 60 + left.min : left.min;
    const startline = right.hour ? right.hour * 60 + right.min : right.min;

    changeTempPolicy({
      ...currentPolicy,
      startline,
      deadline,
    });
  };

  return (
    <>
      <StyledButton
        className={textType}
        size="medium40"
        textColor="gray1"
        fontSize={14}
        borderRadius={6}
        icon={<Icon name="arrowBottomBold" size={16} color="gray2" />}
        onClick={() => setIsOpenDrawer(true)}>
        {BOOKING_RULE_TYPE_DATA.filter(({ value }) => value === tempRuleType)[0].label}
      </StyledButton>

      {tempRuleType === 'A' && (
        <AType {...props} endDays={tempPolicy.endDays} endTime={tempPolicy.endTime} changeDate={changeA} />
      )}
      {tempRuleType === 'AR' && (
        <ARType
          {...props}
          startDays={tempPolicy.startDays}
          startTime={tempPolicy.startTime}
          endDays={tempPolicy.endDays}
          endTime={tempPolicy.endTime}
          changeDate={changeA}
        />
      )}
      {tempRuleType === 'R' && <RType {...props} deadline={tempPolicy.deadline} changeDate={changeR} />}
      {tempRuleType === 'RR' && (
        <RRType {...props} startline={tempPolicy.startline} deadline={tempPolicy.deadline} changeDate={changeRR} />
      )}

      <CheckRadioRuleTypeDrawer
        textType={textType}
        currentRuleType={tempRuleType}
        onChange={changeRuleType}
        isOpen={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
      />
    </>
  );
};

export default SettingByRuleType;

const StyledButton = styled(ButtonWithRightIconOutlined)`
  width: calc(100% - 40px);
  margin: 16px auto 8px;

  &.cancel {
    margin-top: 20px;
  }
`;

import styled from '@emotion/styled';
import { Datepicker, localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { DatepickerBase } from '@mobiscroll/react/dist/src/core/components/datepicker/datepicker';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import { useCallback, useRef, useState } from 'react';

export interface IDateCalendarPickerProps extends MbscDatepickerOptions {
  /** 기간 선택 또는 날짜 한 개 선택 속성 */
  select?: 'range' | 'preset-range' | 'date';
}

const DateCalendarPicker = ({ min = undefined, max = undefined, ...props }: IDateCalendarPickerProps) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [currentDisplayedDate, setCurrentDisplayedDate] = useState<Date>(dayjs().toDate());

  const dateCalendarPickerRef = useRef<DatepickerBase>(null);

  const handleChangeSelectedDate = useCallback((e: { value: Date }) => {
    if (!dateCalendarPickerRef.current) return;

    setCurrentDisplayedDate(e.value);
    dateCalendarPickerRef.current.navigate(e.value);
  }, []);

  const renderCalendarHeader = useCallback(() => {
    return (
      <>
        <Button
          className="title"
          fontSize={17}
          fontWeight={700}
          lineHeight={24}
          textColor="gray1"
          rightIcon={
            <Icon name={isOpenDatePicker ? 'arrowTopFill' : 'arrowBottomFill'} fillColor={theme.color.gray1} size={16} />
          }
          onClick={() => setIsOpenDatePicker(!isOpenDatePicker)}>
          {dayjs(currentDisplayedDate).format('YYYY년 M월')}
        </Button>
        {isOpenDatePicker && (
          <DatePicker
            dateFormat={'YYYY/MM'}
            rows={5}
            min={min}
            max={max}
            gap={24}
            value={currentDisplayedDate}
            onChange={handleChangeSelectedDate}
          />
        )}
      </>
    );
  }, [isOpenDatePicker, currentDisplayedDate, min, max, handleChangeSelectedDate]);

  return (
    <Container className="date-range-picker">
      <Datepicker
        ref={dateCalendarPickerRef}
        {...props}
        theme="ios"
        themeVariant="light"
        locale={localeKo}
        display="inline"
        controls={['calendar']}
        min={min}
        max={max}
        rangeStartLabel="시작일"
        rangeEndLabel="종료일"
        dateFormat="YYYY.MM.DD"
        showOuterDays={true}
        renderCalendarHeader={renderCalendarHeader}
        onPageLoaded={e => setCurrentDisplayedDate(e.month)}
      />
    </Container>
  );
};

export default DateCalendarPicker;

export const Container = styled.div`
  /** 달력 상단 segmented */
  .mbsc-ios.mbsc-datepicker-inline {
    border: none;
  }
  .mbsc-ios.mbsc-range-control-wrapper {
    padding: 0 20px 20px;

    .mbsc-segmented {
      width: auto;
      height: 56px;
      margin: 0;
      padding: 2px;
      border-radius: 10px;
      background: ${theme.color.gray6};
    }
  }
  .mbsc-ios.mbsc-segmented-item:before {
    border: none;
  }
  .mbsc-ios.mbsc-segmented-selectbox {
    padding: 0;
  }
  .mbsc-ios.mbsc-segmented-selectbox-inner.mbsc-selected {
    box-shadow: none;
    height: 52px;
    border-radius: 10px;
  }
  .mbsc-ios.mbsc-range-control-wrapper .mbsc-segmented-button {
    padding: 0;
    height: 100%;
  }
  .mbsc-ios.mbsc-range-control-label {
    padding: 9px 12px 0;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.color.gray2};
  }
  .mbsc-ios.mbsc-range-control-value {
    padding: 0;
    padding-left: 12px;
    font-size: 1.4rem;
    line-height: 18px;
    color: ${theme.color.gray2};
    font-weight: 600;

    &.active {
      color: ${theme.color.primary};
      font-weight: 700;
    }

    &.mbsc-range-control-text-empty {
      color: ${theme.color.gray4};
    }
  }
  .mbsc-ios.mbsc-range-label-clear.mbsc-ltr {
    width: 14px;
    height: 14px;
    right: 13px;
    color: ${theme.color.gray3};
  }

  /** segmented 아래 날짜 버튼 (DatePicker open) */
  .mbsc-calendar-controls {
    position: relative;
    min-height: 0;
    padding: 0 20px 20px;

    /** 날짜 버튼 클릭하면 보이는 DatePicker 컴포넌트 스타일 */
    .date-picker {
      position: absolute;
      top: 46px;
      left: 0px;
      padding-top: 16px;
      padding-bottom: 50px;
    }
  }

  /** 달력 */
  .mbsc-calendar-table {
    gap: 5px;
  }
  .mbsc-datepicker .mbsc-calendar,
  .mbsc-datepicker .mbsc-calendar-slide {
    padding: 0;
  }

  /** 달력의 요일 라인 */
  .mbsc-calendar-week-days {
    padding: 0 12px 5px;
  }
  .mbsc-ios.mbsc-calendar-week-day {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.color.gray3};
    border-bottom: none;
    height: auto;
  }

  /** 달력의 날짜(숫자) 라인 */
  .mbsc-calendar-row {
    padding: 0 15px;
  }
  .mbsc-ios.mbsc-calendar-cell {
    border: none;
    color: ${theme.color.gray1};

    &.mbsc-calendar-day-outer,
    &.mbsc-disabled {
      .mbsc-ios.mbsc-calendar-day-text {
        color: ${theme.color.gray4};
        opacity: 1;
      }
    }
  }
  .mbsc-calendar-day-inner {
    border: none;
    padding: 6px 0;
  }

  /** 달력의 날짜(숫자) 텍스트 */
  .mbsc-ios.mbsc-calendar-day-text,
  .mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
    border: none;
    font-size: 1.5rem;
    font-weight: 500;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 0;
    color: ${theme.color.gray1};
  }
  .mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
    background-color: transparent;
  }
  .mbsc-ios.mbsc-calendar-today {
    color: ${theme.color.primary};
    font-weight: 600;
  }

  /** 달력의 선택된 날짜(숫자) 텍스트 */
  .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text,
  .mbsc-ios.mbsc-datepicker .mbsc-selected.mbsc-hover .mbsc-calendar-day-text {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${theme.color.primary};
    color: ${theme.color.white};
  }

  /** 달력의 선택된 기간 띠 스타일 */
  .mbsc-ios.mbsc-range-day:after {
    background-color: ${`${theme.color.primary}1a`};
    height: 34px;
    top: 1px;
    left: 0;
    right: 0;
  }
  .mbsc-ios.mbsc-range-day:first-of-type:after {
    margin-left: 8px;
  }
  .mbsc-ios.mbsc-range-day:last-of-type:after {
    margin-right: 8px;
  }
  .mbsc-ios.mbsc-range-day-start.mbsc-ltr:after {
    margin-left: 15px;
  }
  .mbsc-ios.mbsc-range-day-end.mbsc-ltr:after {
    margin-right: 15px;
  }

  /** hover 스타일 초기화 */
  .mbsc-ios.mbsc-range-hover:before {
    border: none;
  }
  .mbsc-ios.mbsc-range-day-start.mbsc-rtl:after,
  .mbsc-ios.mbsc-range-hover-end.mbsc-ltr:before,
  .mbsc-ios.mbsc-range-hover-start.mbsc-rtl:before {
    right: 0;
    margin-right: 0;
    border-radius: 0;
  }
`;

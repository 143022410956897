import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { MemberCounselResponse } from 'hooks/service/queries/useInfinityMemberCounsel';
import { COUNSEL_CHANNEL_TEXT, COUNSEL_FUNNEL_TEXT } from 'pages/Member/constants';
import { convertCounselToTargets } from 'pages/MoreDetails/Message/utils';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

import TargetCheckBox from '../../Member/components/TargetCheckBox';
import TargetSelectable from '../../Member/components/TargetSelectable';
import { MESSAGE_COUNSEL_TEXT } from '../constants';

type Props = {
  counsel: MemberCounselResponse;
};

const CounselListItem = ({ counsel }: Props) => {
  return (
    <CounselCardSelectable target={convertCounselToTargets([counsel])[0]}>
      <Row>
        <TargetCheckBox id={counsel.id} />
        <div>
          <Typography size={15} weight={700}>
            {counsel.name}
          </Typography>
          <Typography textColor="gray2">{filters.contact(counsel.phone)}</Typography>
        </div>
        <Label size="regular" minWidth={44} textColor="gray2" color="gray7">
          {counsel.member ? '회원' : '비회원'}
        </Label>
      </Row>
      <Divider thin />
      <InfoFlexWrapper>
        <StyledInfoFlexRow>
          <Typography>{MESSAGE_COUNSEL_TEXT.start_on}</Typography>
          <Typography>{filters.date(counsel.start_on)}</Typography>
        </StyledInfoFlexRow>
        <StyledInfoFlexRow>
          <Typography>{MESSAGE_COUNSEL_TEXT.channel}</Typography>
          <Typography>{COUNSEL_CHANNEL_TEXT[counsel.channel]}</Typography>
        </StyledInfoFlexRow>
        <StyledInfoFlexRow>
          <Typography>{MESSAGE_COUNSEL_TEXT.funnel}</Typography>
          <Typography>{COUNSEL_FUNNEL_TEXT[counsel.funnel]}</Typography>
        </StyledInfoFlexRow>
        <StyledInfoFlexRow>
          <Typography>{MESSAGE_COUNSEL_TEXT.staff}</Typography>
          <Typography>{counsel.staff.name}</Typography>
        </StyledInfoFlexRow>
      </InfoFlexWrapper>
    </CounselCardSelectable>
  );
};

export default CounselListItem;

const CounselCardSelectable = styled(TargetSelectable)`
  border-radius: 12px;
  padding: 18px 20px;
  background-color: ${theme.color.white};
  box-shadow: 1px 2px 10px 0px rgba(145, 145, 145, 0.12);

  hr {
    margin: 14px 0;
  }
`;

const Row = styled.div`
  ${theme.flex('', '', 'start', 12)}
  position: relative;
  .label {
    position: absolute;
    right: 0;
  }
`;

const StyledInfoFlexRow = styled(InfoFlexRow)`
  .typography {
    color: ${theme.color.gray2};
    font-weight: 500;
    :first-of-type {
      opacity: 0.88;
      font-weight: 400;
    }
  }
`;

import { useSuspenseQuery } from '@tanstack/react-query';
import { StudiomateNoticeDetailType } from 'pages/MoreDetails/Settings/Notice/Detail/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStudiomateNoticePopup = () => {
  const { queryFn } = useQueryFnWithCommonParams<StudiomateNoticePopupResponse, StudiomateNoticePopupParams>({
    url: `/api/studiomate-notice/popup`,
    params: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: [`studiomate-notice`, `popup`],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetStudiomateNoticePopup;

type StudiomateNoticePopupParams = {
  studio_id: undefined;
  staff_id: undefined;
};

type StudiomateNoticePopupResponse = {
  data: StudiomateNoticePopupType[];
};

export type StudiomateNoticePopupType = StudiomateNoticeDetailType & {
  // 공지 게시일
  start_date: string;
  end_date: string;
};

import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SendMobileKakaoDrawer from 'sharedComponents/SendMobileKakaoDrawer';

import { ChangePhoneNumberFormType } from '../types';

const KakaoDrawerDrawer = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const { watch, setFocus } = useFormContext<ChangePhoneNumberFormType>();
  const validationId = watch('validationId');

  useEffect(() => {
    if (validationId) {
      setIsDrawerOpened(true);
    }
  }, [validationId]);

  const closeKakaoDrawer = () => {
    setIsDrawerOpened(false);
    setFocus('mobileConfirmCode');
  };

  return <SendMobileKakaoDrawer isOpen={isDrawerOpened} onClose={closeKakaoDrawer} />;
};

export default KakaoDrawerDrawer;

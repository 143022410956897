import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { ScheduleManagementBooking } from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import { ScheduleManagementDeletedLecture } from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';

import CommonDetailInfo from './CommonDetailInfo';

type Props = {
  isOpen: boolean;
  scheduleInfo: ScheduleManagementDeletedLecture;
  bookingInfo: ScheduleManagementBooking;
  onClose: () => void;
};

const DeletedDetailInfo = ({ isOpen, scheduleInfo, bookingInfo, onClose }: Props) => {
  const navigate = useNavigate();

  const goToMemberDetail = (memberId: number) => {
    navigate(`/member/detail/${memberId}`);
  };

  return (
    <StyledSingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title={scheduleInfo.title} />}
      button={{ text: '회원 상세', color: 'gray', onClick: () => goToMemberDetail(bookingInfo.member.id) }}>
      <CommonDetailInfo scheduleInfo={scheduleInfo} bookingInfo={bookingInfo} />
      <Divider />
      <InfoWrapper>
        <Typography textColor="gray2" size={15} weight={400}>
          삭제일시
        </Typography>
        <Typography textColor="secondary3" size={15} weight={500}>
          {filters.dateTimeWithWeekday(scheduleInfo.deleted_at)}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper>
        <Typography textColor="gray2" size={15} weight={400}>
          삭제한 사람
        </Typography>
        <Typography textColor="secondary3" size={15} weight={500}>
          {scheduleInfo.deleted_by ? scheduleInfo.deleted_by.name : '-'}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper className="last-info-wrapper">
        <Typography textColor="gray2" size={15} weight={400}>
          삭제사유
        </Typography>
        <Typography textColor="secondary3" size={15} weight={500}>
          {scheduleInfo.deleted_for ? scheduleInfo.deleted_for : '-'}
        </Typography>
      </InfoWrapper>
    </StyledSingleButtonDrawer>
  );
};

export default DeletedDetailInfo;

const StyledSingleButtonDrawer = styled(SingleButtonDrawer)`
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: calc(32px + 76px);
`;
const InfoWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  height: 56px;
  padding: 17px 20px;
`;

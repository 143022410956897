import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Loading from 'components/Loading';
import Typography from 'components/Typography';
import useBooking from 'hooks/useBooking';
import LimitValidationDialog from 'pages/Booking/SelectMember/LimitValidationDialog';
import Search from 'pages/Booking/SelectMember/Search';
import { BookingType } from 'pages/Booking/types';
import { formatBookingParams } from 'pages/Booking/utils/bookings';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import LeaveDialog from 'sharedComponents/LeaveDialog';

import BookableList from './BookableList';
import BookingErrorDialog from './BookingErrorDialog';
import SelectedUserTicketList from './SelectedUserTicketList';
import SubmitButton from './SubmitButton';
import { BookingFormType } from './types';

type Props = {
  bookingType: BookingType;
};

const BookingForm = ({ bookingType }: Props) => {
  const methods = useFormContext<BookingFormType>();
  const { handleSubmit } = methods;
  const { isPending, bookingCountValidation, bookingMember, error, closeBookingCountValidation, clickPassLimit, closeError } =
    useBooking(methods);

  const submit = (values: BookingFormType) => {
    const params = formatBookingParams(values);
    bookingMember(params);
  };

  return (
    <>
      <Container>
        <Search />
        <FormContainer onSubmit={handleSubmit(submit)}>
          <SelectedUserTicketList />
          <ListTitleTypography size={13} weight={500} textColor="gray2">
            회원목록
          </ListTitleTypography>
          <ApiBoundary>
            <BookableList />
          </ApiBoundary>
          <SubmitButton bookingType={bookingType} />
        </FormContainer>
      </Container>
      <LeaveDialog />

      {bookingCountValidation && (
        <LimitValidationDialog
          isPending={isPending}
          bookingCountValidation={bookingCountValidation}
          onClickBooking={clickPassLimit}
          onClose={closeBookingCountValidation}
        />
      )}
      {error && <BookingErrorDialog onClose={closeError} error={error} />}

      {isPending && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
    </>
  );
};

export default BookingForm;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  height: 100%;
`;

const FormContainer = styled(Form)`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 76px;
  ${theme.flex('column', '', '')};
`;

const ListTitleTypography = styled(Typography)`
  width: 100%;
  flex: 0 0 auto;
  padding: 11px 20px 4px;
`;

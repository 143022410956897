import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Processing from 'components/Processing';
import Typography from 'components/Typography';

type Props = {
  title: string;
  message?: string;
};

const ProcessingPopupContents = ({ title, message }: Props) => {
  return (
    <Container>
      <Processing />
      <Typography size={21} weight={700}>
        {title}
      </Typography>
      <Typography size={15} textColor="gray2">
        {message || '잠시만 기다려 주세요'}
      </Typography>
    </Container>
  );
};

export default ProcessingPopupContents;

const Container = styled.div`
  ${theme.flex('column', 'center', 'center', 8)};
  margin-top: -80px;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.white};
`;

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { BookingFailResponse } from './usePostBookingPrivate';
import { EtcScheduleParams } from './usePostEtcSchedule';

/** 기타 일정 이후 모든 일정 수정 */
const usePatchEtcScheduleBulk = (etcScheduleId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<EtcScheduleUpdateBulkParams>({
    method: `patch`,
    url: `/api/etcSchedule/bulk/${etcScheduleId}`,
  });

  return useCustomMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    EtcScheduleUpdateBulkParams | undefined,
    unknown
  >({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['etc/schedule', 'detail'],
      });
    },
  });
};

export default usePatchEtcScheduleBulk;

export type EtcScheduleUpdateBulkParams = EtcScheduleParams & {
  /** 어느 시점 이후로 수정하는지 */
  start: string; // 2024-07-12 13:32:47
};

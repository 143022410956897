import CheckBox from 'components/CheckBox';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import ApplyAllAmountButton from './ApplyAllAmountButton';
import PaymentTextField from './PaymentTextField';
import { PaymentCheckboxRow } from './StyledComponents';

/** 계좌이체 입력 필드 */
const WireTransfer = () => {
  const {
    control,
    setValue,
    setFocus,
    formState: { touchedFields },
  } = useFormContext<TicketPaymentFormType>();
  const isCheckedWireTransfer = useWatch({ control, name: 'isCheckedWireTransfer' });

  useEffect(() => {
    if (!touchedFields.isCheckedWireTransfer) return;
    if (isCheckedWireTransfer) {
      setFocus('payment.wiretransfer_amount');
    }
  }, [isCheckedWireTransfer, setFocus, touchedFields.isCheckedWireTransfer]);

  return (
    <>
      <PaymentCheckboxRow>
        <div className="left">
          <Controller
            name="isCheckedWireTransfer"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <CheckBox
                gap={10}
                id="transfer"
                label={TICKET_PAYMENT_FORM_TEXT.wiretransfer.label}
                checked={value}
                value={'transfer'}
                onChange={e => {
                  if (!e.target.checked) {
                    setValue('payment.wiretransfer_amount', 0, { shouldDirty: true });
                  }
                  setValue('isCheckedWireTransfer', e.target.checked, { shouldTouch: true });
                }}
                {...field}
              />
            )}
          />
        </div>
        {isCheckedWireTransfer && (
          <div className="apply-all">
            <ApplyAllAmountButton text={TICKET_PAYMENT_FORM_TEXT.wiretransfer.label} keyValue="payment.wiretransfer_amount" />
          </div>
        )}
        <div className="right">
          <PaymentTextField
            control={control}
            setValue={setValue}
            keyValue={'payment.wiretransfer_amount'}
            disabled={!isCheckedWireTransfer}
          />
        </div>
      </PaymentCheckboxRow>
    </>
  );
};

export default WireTransfer;

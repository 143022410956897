import { MemberPointHistoryParams } from 'hooks/service/queries/useInfinityPointHistory';
import { FilterOptionsType } from 'sharedComponents/Filters/types';

import { POINT_FILTER_DEFAULT, POINT_FILTERS } from '../constants';
import { PointSelectedFilterType } from '../types';

type MultipleParams = {
  key: string;
  option: FilterOptionsType;
  prevOptionValues: string[] | undefined;
};

/** 버튼 필터 다중 선택 클릭 로직 */
export const clickOptionMultiple = ({ key, option, prevOptionValues }: MultipleParams) => {
  let newOptionValue: FilterOptionsType['value'];
  /** '전체' 옵션을 제외한 모든 옵션 갯수 */
  const currentKeyOptionsTotal = POINT_FILTERS.filter(item => item.key === key)[0].options.length - 1;

  /**
   * 여러개 선택하면 배열로 저장하고, 선택된 옵션 다시 선택하면 선택해제 되도록,
   * 전부 선택해제 하거나 전체 제외 전부 선택됐으면 '전체'가 선택되게 undefined 저장
   */
  if (!prevOptionValues) {
    newOptionValue = [option.value as string];
  } else if (prevOptionValues.includes(option.value as string)) {
    newOptionValue = prevOptionValues.length > 1 ? prevOptionValues.filter(value => value !== option.value) : undefined;
  } else {
    const newOptions = prevOptionValues.concat(option.value as string);
    newOptionValue =
      currentKeyOptionsTotal === newOptions.length
        ? undefined
        : option.value
          ? prevOptionValues.concat(option.value as string)
          : undefined;
  }

  // label 은 tabConverter가 해줌
  const newOption: FilterOptionsType = {
    value: newOptionValue,
    label: '',
  };

  return newOption;
};

/** 버튼 필터 다중 선택했을 때 콤마로 라벨 구분한 것 */
export const extractMultipleLabels = (options: FilterOptionsType[], params: string[]) => {
  return options
    .filter(option => params.includes(option.value as string))
    .map(option => option.label)
    .join(', ');
};

export const convertPointFilterParams = (filterValues: PointSelectedFilterType): Omit<MemberPointHistoryParams, 'member_id'> => {
  return {
    start_date: filterValues.period?.start_date || POINT_FILTER_DEFAULT.period.start_date,
    end_date: filterValues.period?.end_date || POINT_FILTER_DEFAULT.period.end_date,
    type: filterValues.type,
    status: filterValues.status,
    staff_ids: filterValues.selectedStaffs?.map(({ value }) => value as number).join(';'),
  };
};

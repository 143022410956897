import localStorage from 'utils/localStorage';

const getParams = (type: 'lecture' | 'counsel' | 'etcSchedule', schedules: string[]) => {
  const currentTypes = type === 'lecture' ? ['group', 'private'] : [type];
  const enabled = ['all', ...currentTypes].some(type => schedules.includes(type));

  if (type === 'lecture') {
    const isGroup = schedules.includes('group');
    const isPrivate = schedules.includes('private');
    const types = [];

    if (isGroup) types.push('G');
    if (isPrivate) types.push('P');

    return {
      type: types,
      enabled,
    };
  }

  /** 기타 일정일 때 캘린더 설정 > 기타 일정 숨기기 옵션에 영향받음 */
  if (type === 'etcSchedule') {
    const etcScheduleHide = localStorage.get('calendar_setting')?.etcScheduleHide;
    return {
      enabled: !etcScheduleHide && enabled,
    };
  }

  return {
    enabled,
  };
};

export default getParams;

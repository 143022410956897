import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { UserTicketResponse } from '../queries/useInfinityUserTicketProduct';

const usePatchUserTicketActive = (productId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<UserTicketActiveParams, UserTicketResponse>({
    method: `patch`,
    url: `/api/userTicket/active/${productId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePatchUserTicketActive;

type UserTicketActiveParams = {
  user_id: number;
};

import Icon from 'components/Icon';
import UnderlinedTextField from 'components/TextField/components/UnderlinedTextField';
import Typography from 'components/Typography';
import { PaymentRow } from 'sharedComponents/TicketForm/TicketPaymentForm/StyledComponents';
import filters from 'utils/filters';

type Props = {
  upgradeSalePrice: number;
  priceDiff: number;
};

/** 수강권 업그레이드 > 업그레이드 판매가, 차액 */
const UpgradePriceDifference = ({ upgradeSalePrice, priceDiff }: Props) => {
  return (
    <>
      <PaymentRow>
        <UnderlinedTextField
          id="upgradeSalePrice"
          readOnly
          placeholder="0"
          textAlign="right"
          left={{ prefix: <Icon name="money" color="gray3" />, text: '판매가' }}
          suffix={
            <Typography span size={15} weight={500} textColor="gray3">
              원
            </Typography>
          }
          value={filters.numberComma(upgradeSalePrice)}
        />
      </PaymentRow>
      <PaymentRow>
        <UnderlinedTextField
          id="priceDiff"
          readOnly
          placeholder="0"
          textAlign="right"
          left={{ prefix: <Icon name="plus2" color="gray3" />, text: '차액' }}
          suffix={
            <Typography span size={15} weight={500} textColor="gray3">
              원
            </Typography>
          }
          value={filters.numberComma(priceDiff)}
        />
      </PaymentRow>
    </>
  );
};

export default UpgradePriceDifference;

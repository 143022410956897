import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label, { ILabelProps } from 'components/Label';
import CheckRadioInfiniteDrawer from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioInfiniteDrawer';
import CheckRadioTypography from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioTypography';
import useInfinityHistoryTickets from 'hooks/service/queries/useInfinityHistoryTickets';
import { CurrentMemberIdProps, HistoryFilterFormType } from 'pages/MemberDetail/types';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import SortButton from 'sharedComponents/SortButton';
import { USER_TICKET_STATUS } from 'sharedComponents/UserTicketCard/constants';

const HistoryTicketFilter = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { control } = useFormContext<HistoryFilterFormType>();
  const ticketId = useWatch({ control, name: 'ticketId' });

  const [isOpen, setIsOpen] = useState(false);

  const {
    data: userTicketData,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityHistoryTickets({
    member_id: currentMemberId,
  });

  const data = useMemo(() => {
    const defaultFirstIndex = {
      id: 'all',
      label: <CheckRadioTypography isSelected={ticketId === 'all'}>전체</CheckRadioTypography>,
      value: 'all',
    };

    if (!userTicketData) return [defaultFirstIndex];

    const ticketArray = userTicketData.map(({ id, title, status }) => {
      const showLabel = status === 'not_usable' || status === 'expired';
      const labelColor: ILabelProps['borderColor'] = status === 'not_usable' ? 'secondary3' : 'gray2';

      const label = (
        <StyledItem>
          <CheckRadioTypography isSelected={id === Number(ticketId)} showLabelAll>
            {title}
          </CheckRadioTypography>
          {showLabel && (
            <Label size="small" borderColor={labelColor} textColor={labelColor} opacity={0.64} minWidth={50}>
              {USER_TICKET_STATUS[status]}
            </Label>
          )}
        </StyledItem>
      );
      return {
        id: `${id}`,
        label,
        value: `${id}`,
      };
    });
    ticketArray.unshift(defaultFirstIndex);

    return ticketArray;
  }, [userTicketData, ticketId]);

  const ticketLabel = useMemo(() => {
    if (!userTicketData) return '수강권 전체';
    const selectedTicket = userTicketData.find(userTicket => userTicket.id === Number(ticketId));

    return !selectedTicket ? '수강권 전체' : selectedTicket.title;
  }, [userTicketData, ticketId]);

  return (
    <>
      <SortButton onClick={() => setIsOpen(true)} ellipsis={ticketLabel.length > 15 ? 1 : undefined}>
        {ticketLabel}
      </SortButton>

      <CheckRadioInfiniteDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header="수강권 선택"
        loadMore={fetchNextPage}
        isLoading={isFetchingNextPage}
        hasMore={hasNextPage}
        name="ticketId"
        control={control}
        data={data}
      />
    </>
  );
};

export default HistoryTicketFilter;

const StyledItem = styled.div`
  ${theme.flex('', 'center', '', 10)};
  margin-right: 16px;
`;

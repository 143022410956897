import { useEffect, useRef } from 'react';

import { ISelectProps } from '..';

type SelectOptionPropTypes = Pick<ISelectProps, 'option'> & {
  getHeight?: (value: number) => void;
};

const SelectOption = ({ option, getHeight }: SelectOptionPropTypes) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && getHeight) getHeight(ref.current.offsetHeight);
  }, [ref, getHeight]);

  return (
    <div ref={ref} className="select-option">
      {option}
    </div>
  );
};

export default SelectOption;

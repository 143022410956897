import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import MainLayout from 'sharedComponents/MainLayout';
import SearchBar from 'sharedComponents/SearchBar';

import Categories from './components/Categories';
import FaqList from './components/FaqList';
import { FAQ_IDENTIFIER, FAQ_TEXT } from './constants';

const MoreDetailsSettingsFaq = () => {
  return (
    <MainLayout header={{ title: FAQ_TEXT.title, bgColor: 'white', noUnderline: true }} backgroundColor="gray6">
      <ScrollFixed>
        <SearchBar placeholder={FAQ_TEXT.placeholder} identifier={FAQ_IDENTIFIER} />
        <Categories />
      </ScrollFixed>
      <FaqList />
    </MainLayout>
  );
};

export default MoreDetailsSettingsFaq;

const ScrollFixed = styled.div`
  position: sticky;
  top: 0;
  padding: 8px 0 16px;
  z-index: 20;
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${theme.color.gray6};

  .search-bar {
    padding: 0 20px;
  }

  .tabs-wrapper {
    padding-left: 20px;
  }
`;

import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import SearchBar from 'components/SearchBar';
import { TICKET_TRANSFER_TEXT } from 'pages/TicketDetail/constants';
import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

const Search = () => {
  const queryClient = useQueryClient();
  const { setValue, control } = useFormContext<TicketTransferFormType>();
  const tempSearchKeyword = useWatch({ control, name: 'tempSearchKeyword' });

  /** 취소 버튼 클릭 시 회원 조회 리스트 초기화 */
  const cancel = () => {
    setValue('search', undefined, { shouldDirty: true });
    queryClient.invalidateQueries({
      queryKey: ['member'],
    });
  };

  return (
    <Container>
      <Controller
        control={control}
        name="tempSearchKeyword"
        render={({ field }) => (
          <SearchBar
            id="parent-ticket-search-bar"
            placeholder={TICKET_TRANSFER_TEXT.form.search.placeholder}
            onCancel={cancel}
            onClear={() => {
              setValue('tempSearchKeyword', '', { shouldDirty: true });
            }}
            onSubmitForm={() => setValue('search', tempSearchKeyword, { shouldDirty: true })}
            {...field}
          />
        )}
      />
    </Container>
  );
};

export default Search;

const Container = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${theme.color.gray6};
`;

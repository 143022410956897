import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';
import React from 'react';

const SkeletonList = () => {
  const DUMMY_LENGTH = 5;
  const dummyArray = Array.from({ length: DUMMY_LENGTH }, (_, i) => i);
  return (
    <Container>
      <TopBox height={40} type="round" />
      {dummyArray.map((_, index) => (
        <Card key={index}>
          <Skeleton height={36} width={36} type="circle" />
          <div className="detail">
            <Skeleton height={20} type="round" />
            <Skeleton height={42} type="round" />
            <Skeleton height={16} width={135} type="round" />
          </div>
        </Card>
      ))}
    </Container>
  );
};

export default SkeletonList;

const Container = styled.div`
  padding: 0 20px;
`;

const TopBox = styled(Skeleton)`
  margin: 16px 0;
  border-radius: 6px;
`;

const Card = styled.div`
  border-bottom: 1px solid rgba(46, 46, 46, 0.06);
  padding: 20px 0;
  ${theme.flex('', '', '', 11)}

  [type='circle'] {
    margin-top: 4px;
  }

  .detail {
    flex-grow: 1;
    padding-right: 3px;
    ${theme.flex('column', '', '', 6)}
    :last-child {
      margin-top: 6px;
    }
    [type='round'] {
      border-radius: 3px;
    }
  }
`;

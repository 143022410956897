import { SettingByRuleTypeProps } from 'pages/Booking/types';

import EndAtUpdate from './EndAtUpdate';
import StartEndAtUpdate from './StartEndAtUpdate';

/** 그룹/프라이빗 1개 수정 때만 사용 */
const SettingByRuleType = (props: SettingByRuleTypeProps) => {
  switch (props.ruleType) {
    case 'A':
    case 'R':
      return <EndAtUpdate {...props} />;
    case 'AR':
    case 'RR':
      return <StartEndAtUpdate {...props} />;
  }
};

export default SettingByRuleType;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import Icon from 'components/Icon';
import filters from 'utils/filters';

type Props = Omit<
  ButtonProps,
  'size' | 'variant' | 'fontSize' | 'fontWeight' | 'textColor' | 'padding' | 'rightIcon' | 'borderRadius' | 'children'
> & {
  children?: string | Date;
  placeholder?: string;
  readonly?: boolean;
};

const PickerButtonOutlined = ({ children, placeholder, ...props }: Props) => {
  const buttonText = children && typeof children === 'object' ? filters.date(children) : children;
  return (
    <StyledButton
      {...props}
      fullWidth
      size="medium48"
      variant="outlined"
      fontSize={15}
      fontWeight={500}
      textColor="gray1"
      padding={{ left: 16, right: 12 }}
      rightIcon={props.disabled ? undefined : <Icon name="calendar" />}
      borderRadius={10}
      hasValue={!!buttonText}
      disabled={props.readonly || props.disabled}>
      {buttonText || placeholder}
    </StyledButton>
  );
};

export default PickerButtonOutlined;

const StyledButton = styled(Button)<{ hasValue: boolean }>`
  justify-content: space-between;

  /** DSG Normal 데이터 없어 placeholder 노출시 */
  ${({ hasValue }) => !hasValue && `.typography { color: ${theme.color.gray4};}`}

  /** DSG ReadOnly */
  :disabled {
    background-color: ${theme.color.gray6};
    border: 1px solid ${theme.color.gray6};
    .typography {
      color: ${theme.color.gray3};
    }
  }
`;

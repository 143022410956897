import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

type Props = {
  currentTabLabel: string;
  currentKeyword?: string;
};

const NoData = ({ currentTabLabel, currentKeyword }: Props) => {
  return (
    <Container>
      {currentKeyword ? (
        <>
          <Typography size={15} weight={600} textColor="primary">
            “{currentKeyword}”
          </Typography>
          <Typography size={15} textColor="gray2">
            의 검색 결과가 없습니다.
          </Typography>
        </>
      ) : (
        <Typography size={15} textColor="gray2">
          {currentTabLabel} 정보가 없습니다.
        </Typography>
      )}
    </Container>
  );
};

export default NoData;

const Container = styled.div`
  margin-top: 120px;
  ${theme.flex()};
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import React from 'react';
import { InfoFlexRow, InfoTypography } from 'sharedComponents/InfoTypography';

import { NOTICE_TEXT } from '../../constants';
import Foldable from './Foldable';

type Props = {
  setting: string;
  period: string;
  target: string;
};

const FoldableSettings = ({ setting, period, target }: Props) => {
  return (
    <FoldableContainer>
      {({ isOpen }) => (
        <ContentsWrapper>
          <InfoRowLeftAligned key={setting}>
            <InfoTypography weight={500} size={14}>
              {NOTICE_TEXT.settings}
            </InfoTypography>
            <InfoTypography size={14}>{setting}</InfoTypography>
          </InfoRowLeftAligned>
          {isOpen && (
            <>
              <InfoRowLeftAligned>
                <InfoTypography weight={500} size={14}>
                  {NOTICE_TEXT.period}
                </InfoTypography>
                <InfoTypography size={14}>{period}</InfoTypography>
              </InfoRowLeftAligned>
              <InfoRowLeftAligned>
                <InfoTypography weight={500} size={14}>
                  {NOTICE_TEXT.target}
                </InfoTypography>
                <InfoTypography size={14}>{target}</InfoTypography>
              </InfoRowLeftAligned>
            </>
          )}
        </ContentsWrapper>
      )}
    </FoldableContainer>
  );
};

export default FoldableSettings;

const FoldableContainer = styled(Foldable)`
  margin: 24px 0 40px;
`;

const ContentsWrapper = styled.div`
  ${theme.flex('column', '', '', 10)}
  padding: 3px 0;
`;

const InfoRowLeftAligned = styled(InfoFlexRow)`
  justify-content: flex-start;
  gap: 16px;
  .typography:last-of-type {
    text-align: left;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { memo } from 'react';
import { ColorType } from 'types/colorTypes';

import ButtonBase, { ButtonBaseProps } from './ButtonBase';

export interface IconButtonProps extends Omit<ButtonBaseProps, 'color'> {
  bgColor?: ColorType;
}

const IconButton = ({ children, className, bgColor, ...props }: IconButtonProps) => {
  return (
    <Container className={className ? `icon-button ${className}` : `icon-button`} bgColor={bgColor} {...props}>
      {children}
    </Container>
  );
};

const Container = styled(ButtonBase)<IconButtonProps>`
  padding: 0;
  height: ${({ widthSize }) => (widthSize ? `${widthSize}px` : 'auto')};
  background-color: ${({ bgColor }) => (bgColor ? theme.color[bgColor] : 'transparent')};
`;

export default memo(IconButton);

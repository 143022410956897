import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router-dom';
import { CareerType } from 'types/staffTypes';

import ModifyButton from './ModifyButton';

type Props = {
  title: string;
  selfIntroduction?: string;
  careers?: CareerType[];
  privateStartInterval?: {
    isEnabled: boolean;
    am: number;
    pm: number;
  };
};

const TabContentHeader = ({ title, selfIntroduction, careers, privateStartInterval }: Props) => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const isProfileTab = title === '프로필';

  const goUpdate = (type: 'profile' | 'schedule') => {
    const isProfile = type === 'profile';

    // 검색의 용이함을 위해서 리터럴을 쓰지않음
    if (isProfile) {
      navigate('update-form/profile', {
        state: {
          defaultInfo: {
            profile: {
              self_introduction: selfIntroduction || '',
            },
            careers: careers?.map(({ career }) => {
              return { career };
            }),
          },
        },
      });
    } else {
      navigate('update-form/schedule', {
        state: {
          defaultInfo: {
            profile: {
              private_start_interval_on_off: privateStartInterval?.isEnabled,
              private_start_interval_am: privateStartInterval?.am,
              private_start_interval_pm: privateStartInterval?.pm,
            },
          },
        },
      });
    }
  };

  return (
    <Container>
      <Typography size={15} weight={700}>
        {title}
      </Typography>
      {hasPermission(PERMISSION.staff.edit) && (
        <ModifyButton
          onClick={() => {
            checkPermission(PERMISSION.staff.edit.id, {
              onSuccess: () => {
                goUpdate(isProfileTab ? 'profile' : 'schedule');
              },
            });
          }}
        />
      )}
    </Container>
  );
};

export default TabContentHeader;

const Container = styled.section`
  ${theme.flex('row', 'center', 'space-between')};
  margin-bottom: 24px;
`;

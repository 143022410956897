import { useIsMutating } from '@tanstack/react-query';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingGroupCreateFormType } from '../../types';

type Props = {
  pageMode: AllPageModeType;
};

const SubmitButton = ({ pageMode }: Props) => {
  const {
    watch,
    formState: { dirtyFields },
  } = useFormContext<BookingGroupCreateFormType>();
  const isMutating = useIsMutating() > 0;
  const { title, startDate, endDate } = watch();
  const isDirty = !!Object.keys(dirtyFields).length;

  const disabled = useMemo(() => {
    if (isMutating) return true;
    if (!isDirty && pageMode !== 'copy') return true;
    if (!title || !startDate || !endDate) return true;
    return false;
  }, [endDate, isDirty, isMutating, pageMode, startDate, title]);

  return (
    <FooterButton type="submit" disabled={disabled}>
      {BOOKING_COMMON_FORM_TEXT.footerButton[pageMode]} 완료
    </FooterButton>
  );
};

export default SubmitButton;

import { BookingGroupPolicyFormType } from '../Group/types';

/**
 * A, AR 타입의 start_time, end_time 을 받아서
 * 시간, 분 문자열로 분리
 * @param 예) 1003
 * @returns 예) { hour: '10', min: '03' }
 */
export const splitHourMinTypeA = (param: number) => {
  const paddedNumber = String(param).padStart(4, '0');

  const hours = paddedNumber.slice(0, 2);
  const minutes = paddedNumber.slice(2);

  return {
    hour: hours,
    min: minutes,
  };
};

/**
 * R, RR 타입의 startline, deadline 을 받아서
 * 시간, 분 숫자로 분리
 * @param 예) 74
 * @returns 예) {hour: 1, min: 14 }
 */
export const splitHourMinTypeR = (param: number) => {
  return {
    hour: Math.floor(param / 60),
    min: param % 60,
  };
};

/**
 * 시간 형식의 문자열을 숫자로 변환
 * @param 예) 00:00, 01:45, 10:03, 23:59
 * @returns 예) 0, 145, 1003, 2359
 */
export const colonTimeToNum = (param: string) => {
  const [hours, minutes] = param.split(':');
  return parseInt(hours.padStart(2, '0') + minutes.padStart(2, '0'), 10);
};

/**
 * 분단위 숫자를 한국어 시간 텍스트로 변경
 * @param 예) 74, 59, 60
 * @returns 예) 1시간 14분, 0시간 59분, 1시간 0분
 */
export const numToTimeKor = (param: number) => {
  const hours = Math.floor(param / 60);
  const minutes = param % 60;

  return `${hours}시간 ${minutes}분`;
};

/**
 * 숫자를 콜론을 붙인 시간:분 텍스트로 변경
 * @param 예) 1910, 304
 * @returns 예) 19:10, 03:04
 */
export const numToTimeColon = (param: number) => {
  const paddedNumber = String(param).padStart(4, '0');

  const hours = paddedNumber.slice(0, 2);
  const minutes = paddedNumber.slice(2);

  return `${hours}:${minutes}`;
};

export const stringTime = (params: BookingGroupPolicyFormType) => {
  const { ruleType, deadline, startDays, startTime, startline, endDays, endTime } = params;

  switch (ruleType) {
    case 'R':
      return `${numToTimeKor(deadline)} 전까지`;
    case 'RR':
      return `${numToTimeKor(startline)} 전부터 ${numToTimeKor(deadline)} 전까지`;
    case 'A':
      return `${endDays}일 전, ${numToTimeColon(endTime)}까지`;
    case 'AR':
      return `${startDays}일 전, ${numToTimeColon(startTime)}부터 ${endDays}일 전, ${numToTimeColon(endTime)}까지`;
  }
};

import styled from '@emotion/styled';
import SubTitle from 'components/SubTitle';

type Props = {
  title: string;
  subTitle: string;
};

const TitleWithSubText = ({ title, subTitle }: Props) => {
  return (
    <Container>
      <SubTitle title={title} message={{ position: 'bottom', text: subTitle }} />
    </Container>
  );
};

export default TitleWithSubText;

const Container = styled.div`
  padding: 16px 20px 8px;
`;

import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { isEqual } from 'lodash';
import { POINT_FILTER_DEFAULT, POINT_FILTER_TEXT } from 'pages/MemberDetail/constants';
import { PointSelectedFilterType } from 'pages/MemberDetail/types';
import { useEffect, useState } from 'react';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';

import PeriodSelectCustom from './PeriodSelectCustom';
import PeriodSelectMonth from './PeriodSelectMonth';

const PeriodSelectFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const previousOptions = (selectedOption as PointSelectedFilterType['period']) || POINT_FILTER_DEFAULT.period;

  const [buttonOption, setButtonOption] = useState(previousOptions.type);

  const clickOption = (option: FilterOptionsType) => {
    const buttonValue = option.value as string;
    setButtonOption(buttonValue);
    changeOptions(
      {
        value: {
          ...previousOptions,
          type: buttonValue,
        },
        label: '',
      },
      'period',
    );
  };

  /** 기간선택 필터 초기화 시 버튼 초기화 */
  useEffect(() => {
    if (isEqual(POINT_FILTER_DEFAULT.period, previousOptions)) {
      setButtonOption(previousOptions.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousOptions]);

  return (
    <Container>
      <ButtonFilter currentFilter={currentFilter} onClick={clickOption} selectedOption={buttonOption} />
      {buttonOption === 'month' && <PeriodSelectMonth previousOptions={previousOptions} onClick={changeOptions} />}
      {buttonOption === 'custom' && <PeriodSelectCustom previousOptions={previousOptions} onClick={changeOptions} />}
      <Typography className="max-text" size={13} textColor="gray2">
        {POINT_FILTER_TEXT.periodDescription}
      </Typography>
    </Container>
  );
};

export default PeriodSelectFilter;

const Container = styled.div`
  padding: 0 20px 24px;

  .max-text {
    margin-top: 8px;
  }

  .button-filter {
    padding: 20px 0 0;
  }

  .filter-option-button-wrapper {
    margin-bottom: 12px;
  }

  .month-picker-drawer-button {
    justify-content: space-between;
  }
`;

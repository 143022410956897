import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';

import { TabProps } from './Tab';

export type TabGroupProps = Pick<TabProps, 'isHeaderType'> & {
  /** Tab 컴포넌트 */
  children: React.ReactNode;
  /** Tab wrapper에 border bottom 노출 유무 */
  underline?: boolean;
  /** Tabs 가로 스크롤 처리 */
  scrollable?: boolean;
  /** Tab 버튼 사이 간격 */
  gap?: number;
  className?: string;
};

const TabGroup = ({ children, scrollable = false, ...props }: TabGroupProps) => {
  return (
    <Container className="tabs" {...props}>
      <div className={[`tabs-wrapper ${scrollable ? 'tabs-wrapper-scrollable' : ''}`].join(' ')}>{children}</div>
      {props.underline && <div className="tabs-underline"></div>}
    </Container>
  );
};

const Container = styled.div<TabGroupProps>(
  ({ gap, isHeaderType }) => css`
    position: relative;

    .tabs-wrapper {
      ${theme.flex('', 'center', '', isHeaderType ? 10 : gap)};
      position: relative;
      margin: ${isHeaderType ? 0 : '0 20px'};
    }

    .tabs-wrapper-scrollable {
      margin: 0;
      white-space: nowrap;
      overflow: auto hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .tabs-underline {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: ${theme.color.gray7};
    }
  `,
);

export default TabGroup;

import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';

import GrayFoldingCard from '../GrayFoldingCard';

type Props = UserTicketProps & {
  ticketDetail: InfoValueRowType[];
};

const FoldingTicketCard = ({ userTicket, ticketDetail }: Props) => {
  return (
    <Container className="transfer-ticket-info">
      <Typography weight={600}>기존 수강권 정보</Typography>

      <GrayFoldingCard userTicket={userTicket} isTransfer>
        <InfoFlexWrapper>
          {ticketDetail.map(({ label, value }) => (
            <InfoFlexRow key={`${label}`}>
              <InfoTypography size={14} opacity={0.88}>
                {label}
              </InfoTypography>
              <InfoTypography size={14} weight={500}>
                {value}
              </InfoTypography>
            </InfoFlexRow>
          ))}
        </InfoFlexWrapper>
      </GrayFoldingCard>
    </Container>
  );
};

export default FoldingTicketCard;

const Container = styled.div`
  padding: 40px 20px 24px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import TicketCard from 'sharedComponents/TicketCard';

import formatSmallUpgradeCard from '../../utils/formatSmallUpgradeCard';
import getUpgradeCurrentTicketInfo from '../../utils/getUpgradeCurrentTicketInfo';

const CurrentTicketCard = ({ userTicket }: UserTicketProps) => {
  const ticketDetail = getUpgradeCurrentTicketInfo({ userTicket });

  return (
    <UpgradeInfoCardContainer className="current-ticket-card">
      <TicketCard ticket={formatSmallUpgradeCard(userTicket)} />
      <InfoFlexWrapper className="info-flex-wrapper" gap={8}>
        {ticketDetail.map(({ label, value }) => (
          <InfoFlexRow key={label}>
            <InfoTypography size={14} opacity={0.88}>
              {label}
            </InfoTypography>
            <InfoTypography size={14} weight={500}>
              {value}
            </InfoTypography>
          </InfoFlexRow>
        ))}
      </InfoFlexWrapper>
    </UpgradeInfoCardContainer>
  );
};

export default CurrentTicketCard;

export const UpgradeInfoCardContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: ${theme.color.gray7};

  .ticket-card {
    padding: 16px 16px 12px;
  }

  .info-flex-wrapper {
    padding: 12px 16px 16px;
    border-top: 1px solid ${theme.color.gray5};
  }
`;

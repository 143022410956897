import styled from '@emotion/styled';
import { MbscDatepickerOptions } from '@mobiscroll/react';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';

import MonthPicker from '.';

type Props = MbscDatepickerOptions & {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  zIndex?: number;
};

const MonthPickerDrawer = ({ isOpen, onClose, onSave, zIndex, ...props }: Props) => {
  return (
    <StyledDoubleButtonDrawer
      type={3}
      hideHandle
      zIndex={zIndex}
      isOpen={isOpen}
      onClose={onClose}
      leftButton={{
        text: '취소',
        onClick: onClose,
      }}
      rightButton={{
        text: '완료',
        onClick: onSave,
      }}>
      <MonthPicker {...props} />
    </StyledDoubleButtonDrawer>
  );
};

export default MonthPickerDrawer;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  && .mbsc-calendar-header .mbsc-ios.mbsc-calendar-controls,
  && .mbsc-datepicker .mbsc-calendar-slide,
  && .mbsc-datepicker .mbsc-calendar {
    padding: 0;
  }
`;

import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import DashBoardSkeleton from './DashBoardSkeleton';
import PointDashBoard from './PointDashBoard';
import PointHistory from './PointHistory';
import PointListSkeleton from './PointListSkeleton';

const Point = ({ currentMemberId }: CurrentMemberIdProps) => {
  return (
    <>
      <ApiBoundary fallback={<DashBoardSkeleton />}>
        <PointDashBoard currentMemberId={currentMemberId} />
      </ApiBoundary>
      <Divider />
      <StyledTypography size={17} weight={700}>
        적립 및 차감내역
      </StyledTypography>
      <ApiBoundary fallback={<PointListSkeleton />}>
        <PointHistory currentMemberId={currentMemberId} />
      </ApiBoundary>
    </>
  );
};

export default Point;

const StyledTypography = styled(Typography)`
  padding: 32px 20px 4px;
`;

import Divider from 'components/Divider';
import { get } from 'lodash';
import { clickOptionMultiple } from 'pages/MemberDetail/utils/memberDetailFilters';
import { Fragment } from 'react';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps, FilterOptionsType } from 'sharedComponents/Filters/types';

import PeriodSelectFilter from './PeriodSelectFilter';
import StaffSelectFilter from './StaffSelectFilter';

const WholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  const buttonFilters = filters.filter(filter => filter.type === 'button');

  /** 버튼 필터 다중 선택 */
  const changeOptionMultiple = (option: FilterOptionsType, key: string) => {
    const prevOptionValues = filterChanged[key] as string[] | undefined;
    const newOption = clickOptionMultiple({ key, option, prevOptionValues });
    changeOption(newOption, key);
  };

  return (
    <>
      <PeriodSelectFilter currentFilter={filters[0]} selectedOption={get(filterChanged, 'period')} onClick={changeOption} />
      <Divider />
      <StaffSelectFilter
        currentFilter={filters[1]}
        selectedOption={get(filterChanged, 'selectedStaffs')}
        onClick={changeOption}
      />
      {buttonFilters.map((filter, index) => (
        <Fragment key={index}>
          <Divider />
          <ButtonFilter currentFilter={filter} selectedOption={get(filterChanged, filter.key)} onClick={changeOptionMultiple} />
        </Fragment>
      ))}
    </>
  );
};

export default WholeFilterOptions;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { TicketPaymentFormType } from '../types';
import { PaymentCheckboxRow } from './StyledComponents';

const RemainingPoint = () => {
  const { control } = useFormContext<TicketPaymentFormType>();
  const remainingPoint = useWatch({ control, name: 'remainingPoint' });

  return (
    <RemainingPointRow>
      <div className="left">
        <Typography span textColor="gray2">
          회원의 잔여 포인트
        </Typography>
      </div>
      <div className="right">
        <Typography span textColor="gray2">
          {filters.numberComma(remainingPoint || 0)}
        </Typography>
        <Typography span textColor="gray2" className="suffix">
          P
        </Typography>
      </div>
    </RemainingPointRow>
  );
};

export default RemainingPoint;

const RemainingPointRow = styled(PaymentCheckboxRow)`
  padding: 16px 20px 16px 54px;
  height: 52px;

  .right {
    ${theme.flex('row', 'center', 'flex-end', 2)};

    .suffix {
      min-width: 13px;
      text-align: center;
    }
  }
`;

import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteMemo from 'hooks/service/mutations/useDeleteMemo';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MemoFormType } from 'sharedComponents/MemoForm/types';
import { CustomError } from 'types/errorTypes';

import { MEMO_TEXT } from './constants';

type Props = {
  onClose: () => void;
};

const DeleteMemoDialog = ({ onClose }: Props) => {
  const { control } = useFormContext<MemoFormType>();
  const id = useWatch({ control, name: 'memo_id' });

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: deleteMemoMutate } = useDeleteMemo(Number(id));

  const clickDelete = useCallback(() => {
    deleteMemoMutate(undefined, {
      onSettled: onClose,
      onSuccess: () => {
        setToast({ type: 'success', message: MEMO_TEXT.successMessage.delete });
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  }, [deleteMemoMutate, errorDialog, onClose, setToast]);

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: MEMO_TEXT.deleteDialog.negativeText }}
      positiveAction={{
        text: MEMO_TEXT.deleteDialog.positiveText,
        onClick: clickDelete,
      }}>
      <DialogDescription>메모를 삭제하시겠습니까?</DialogDescription>
    </Dialog>
  );
};

export default DeleteMemoDialog;

import { get } from 'lodash';
import { useRecoilState } from 'recoil';
import { searchKeywordsAtom } from 'recoil/searchKeywords';

/**
 * 공용 searchKeywordAtom를 활용한 검색어 관리
 * */
const useSearchKeywords = (identifier: string) => {
  const [searchKeywords, setSearchKeywords] = useRecoilState(searchKeywordsAtom);

  const searchKeyword = get(searchKeywords, identifier, '');

  const setSearchKeyword = (keyword: string) => {
    setSearchKeywords(prev => ({ ...prev, [identifier]: keyword }));
  };

  return {
    searchKeyword,
    setSearchKeyword,
  };
};

export default useSearchKeywords;

import { useFormContext } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

const LeaveDialog = () => {
  const {
    formState: { dirtyFields, isSubmitSuccessful },
  } = useFormContext();
  const isDirty = !!Object.keys(dirtyFields).length;

  return <DialogBeforeLeave isBlocked={isDirty && !isSubmitSuccessful} />;
};

export default LeaveDialog;

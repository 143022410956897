import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';

import { InfoAccordionPropsType } from '../types';

const InfoAccordion = ({ data, accordionProps, buttonProps }: InfoAccordionPropsType) => {
  return (
    <Container>
      <Accordion showArrow {...accordionProps}>
        <InfoFlexWrapper gap={8} className="contents-wrapper">
          {data.map(({ label, value, textColor }) => (
            <InfoFlexRow gap={14} key={`${label}-${value}`}>
              <InfoTypography opacity={0.88}>{label}</InfoTypography>
              <InfoTypography weight={500} textColor={textColor || 'gray2'}>
                {value}
              </InfoTypography>
            </InfoFlexRow>
          ))}
          {buttonProps && (
            <Button fullWidth size="medium40" {...buttonProps}>
              {buttonProps.children}
            </Button>
          )}
        </InfoFlexWrapper>
      </Accordion>
    </Container>
  );
};

export default InfoAccordion;

const Container = styled.div`
  border-bottom: 1px solid ${theme.color.gray7};

  .contents-wrapper {
    padding: 0 20px 24px;

    .text-button {
      margin-top: 20px;
    }
  }
`;

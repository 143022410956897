import { MemberDetailResponse } from 'hooks/service/queries/useGetMemberDetail';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { POINT_FILTER_DEFAULT } from 'pages/MemberDetail/constants';
import { PointSelectedFilterType } from 'pages/MemberDetail/types';
import { atom } from 'recoil';
import { PeriodFilterType } from 'sharedComponents/Filters/types';

export const currentMemberAtom = atom<MemberDetailResponse | null>({
  key: 'currentMemberAtom',
  default: null,
});
export const currentMemberUserTicketsAtom = atom<UserTicketResponse[]>({
  key: 'currentMemberUserTicketsAtom',
  default: [],
});

/** 결제 내역 필터 */
export const paymentSelectedFilterAtom = atom<PeriodFilterType>({
  key: 'paymentSelectedFilterAtom',
  default: {},
});

/** 포인트 내역 필터 */
export const pointFiltersAtom = atom<PointSelectedFilterType>({
  key: 'pointFiltersAtom',
  default: {
    period: POINT_FILTER_DEFAULT.period,
  },
});

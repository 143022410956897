import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { Payment } from '../queries/useInfinityUserTicketProduct';
import { PatchPaymentParams } from './usePatchPayment';

const usePostUnpaid = (userTicketId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<PatchPaymentParams, Payment>({
    method: `post`,
    url: `/api/payment/unpaid/${userTicketId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePostUnpaid;

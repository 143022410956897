type ColorType = {
  [key: string]: string[];
};

export const OLD_CARD_COLOR: ColorType = {
  card01: ['ff8181', 'ff2525'],
  card02: ['ff8697', 'ff5068'],
  card03: ['fd915f', 'ff5100'],
  card04: ['94d26b', '43a700'],
  card05: ['23ffb2', '009651'],
  card06: ['6ef1f1', '0bb7b7'],
  card07: ['808cff', '2a38c2'],
  card08: ['e67272', 'e52323'],
  card09: ['e67787', 'e6485e'],
  card10: ['ca724a', 'cc4100'],
  card11: ['74a753', '368600'],
  card12: ['1ac98c', '007942'],
  card13: ['56c0c0', '099393'],
  card14: ['5860b1', '1e2787'],
  card15: ['b25959', '991818'],
  card16: ['b25c68', '99303f'],
  card17: ['b16440', '993101'],
  card18: ['669149', '286401'],
  card19: ['17b17b', '006a39'],
  card20: ['4ca7a7', '098181'],
  card21: ['4c5499', '192275'],
  card22: ['994c4c', '991717'],
  card23: ['99505a', '99303f'],
  card24: ['975638', '822b03'],
  card25: ['587e3f', '286401'],
  card26: ['14986a', '005b32'],
  card27: ['419090', '095f5f'],
  card28: ['3f457f', '151c61'],
  card29: ['7f3e3e', '7f1313'],
  card30: ['7f424a', '7f2834'],
  card31: ['7e472d', '7f2800'],
  card32: ['486833', '225301'],
  card33: ['107d57', '004c29'],
  card34: ['357777', '065b5b'],
  card35: ['323765', '11174e'],
};

export const CARD_COLOR: ColorType = {
  G: ['#5F98FF', '#938EFA'],
  I: ['#5F98FF', '#938EFA'],
  P: ['#A888FA', '#C685DF'],
  card01: ['#FF463E', '#FF7E78'],
  card02: ['#FF495D', '#FF909C'],
  card03: ['#FF7628', '#FFA132'],
  card04: ['#5CCA15', '#BCD52A'],
  card05: ['#00B763', '#26DCA6'],
  card06: ['#00AAC1', '#00E7CB'],
  card07: ['#5B64FF', '#9795FF'],
  card08: ['#DD3F39', '#FF6862'],
  card09: ['#EB4B72', '#FB6B8D'],
  card10: ['#EE5615', '#FF824D'],
  card11: ['#4DAE23', '#86BB1C'],
  card12: ['#009C54', '#00C193'],
  card13: ['#0099AE', '#00BEA7'],
  card14: ['#383FC7', '#7A77E9'],
  card15: ['#903B39', '#E3726E'],
  card16: ['#96475A', '#D56B84'],
  card17: ['#995033', '#CB7A5A'],
  card18: ['#3F5E30', '#64984C'],
  card19: ['#2C6048', '#49A57E'],
  card20: ['#306269', '#429A90'],
  card21: ['#464877', '#7D7BB4'],
  card22: ['#86221E', '#AB3C39'],
  card23: ['#962C4C', '#C64060'],
  card24: ['#833904', '#B8531A'],
  card25: ['#205109', '#438724'],
  card26: ['#004E2A', '#009858'],
  card27: ['#005E73', '#009494'],
  card28: ['#222453', '#333193'],
  card29: ['#5C1714', '#89221E'],
  card30: ['#691C2A', '#962C4C'],
  card31: ['#521F09', '#814128'],
  card32: ['#133800', '#235909'],
  card33: ['#002F19', '#005330'],
  card34: ['#003137', '#005853'],
  card35: ['#14152E', '#323257'],
};

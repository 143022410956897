import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';

export interface IDateFilterProps {
  period?: string;
  text?: string;
  onClick?: () => void;
  className?: string;
}

const DateFilter = ({ period, text, onClick, className }: IDateFilterProps) => {
  return (
    <Container className={`date-filter ${className}`} onClick={onClick}>
      <Typography span textColor="gray2">
        {period}
      </Typography>
      <span>
        <Typography span weight={500} textColor="gray2">
          {text}
        </Typography>
        <Icon name="arrowBottomBold" size={16} color="gray3" />
      </span>
    </Container>
  );
};

export default DateFilter;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between', 10)};
  height: 44px;
  padding: 0 20px;
  border-bottom: 1px solid ${theme.color.gray7};
  flex: 0 0 auto;

  > span:last-of-type {
    ${theme.flex('', 'center', '')};
  }
`;

import { AppLinkMessageFormType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import SendConfirm from './SendConfirm';
import SubmitButton from './SubmitButton';

type Props = {
  onSubmit: (values: AppLinkMessageFormType) => void;
};

const SendCheck = ({ onSubmit }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <SubmitButton onOpen={() => setIsOpen(true)} />
      <ApiBoundary>
        <SendConfirm isOpen={isOpen} onClose={() => setIsOpen(false)} onSubmit={onSubmit} />
      </ApiBoundary>
    </>
  );
};

export default SendCheck;

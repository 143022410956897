import styled from '@emotion/styled';
import Button, { ButtonProps } from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';

type Props = {
  className?: string;
  leftButton: Omit<ButtonProps, 'children'> & {
    text: string;
  };
  rightButton: Omit<ButtonProps, 'children'> & {
    text: string;
  };
};

/**
 * 하단 버튼 두개일 때, 왼쪽 버튼 사이즈가 작은(115px) 버튼 그룹
 * 왼쪽 버튼 default gray
 */
const LeftSmallButtonGroup = ({ className, leftButton, rightButton }: Props) => {
  const { text: leftText, ...leftButtonProps } = leftButton;
  const { text: rightText, ...rightButtonProps } = rightButton;

  return (
    <StyledButtonGroup leftButtonWidth={115} className={className}>
      <Button size="large56" variant="contained" color="gray" {...leftButtonProps}>
        {leftText}
      </Button>
      <Button fullWidth size="large56" variant="contained" color="point" {...rightButtonProps}>
        {rightText}
      </Button>
    </StyledButtonGroup>
  );
};

export default LeftSmallButtonGroup;

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
  padding: 10px 20px;
`;

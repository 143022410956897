import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MemberDetailResponse } from '../queries/useGetMemberDetail';

/** 프라이빗 수업 생성 및 예약 */
const usePostBookingPrivate = () => {
  const { mutationFn } = useMutationFnWithCommonParams<BookingPrivateParams, BookingPrivateResponse>({
    method: `post`,
    url: `/api/booking/private`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostBookingPrivate;

export type WeekdayNum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type BookingCommonParams = {
  /** 수업명, default: 회원명 님 */
  title: string;
  /** 강사 id */
  instructor_id: number;
  /** 수업 시작일, 종료일 */
  start_date: string;
  end_date: string;
  /** 수업 시작시간, 종료시간 */
  start_time: string;
  end_time: string;
  /** 룸 id */
  room_id?: number;
  /** 반복 요일 */
  weekday?: Array<WeekdayNum>;
};

export type BookingPrivateParams = BookingCommonParams & {
  /** 프라이빗 수업 예약하는 회원의 수강권 아이디 */
  user_ticket_ids: number[];
};

export type BookingPrivateLectureResponse = {
  id: number;
  title: string;
  room: string | null;
  start_on: string;
  end_on: string;
  weekday: number;
  staff: string;
};

export type BookingFailResponse = {
  id: number;
  title: string;
  room: string | null;
  start_on: string;
  end_on: string;
  weekday: number;
  /** 강사 이름 */
  staff: string;
  /** 에러 코드 */
  code: string;
  /** 에러 메세지 */
  message: string;
  user_ticket_id: number;
};

export type BookingPrivateResponse = {
  success: BookingPrivateSuccessResponse | null;
  /** 생성 실패한 수업의 개수 */
  fail: Array<BookingFailResponse>;
};

export type BookingPrivateSuccessResponse = {
  /** 생성된 수업의 개수 */
  lecture_count: number;
  /** 생성된 수업에 예약된 개수 */
  booking_count: number;
  /** 생성 성공한 수업의 개수 */
  lectures: Array<BookingPrivateLectureResponse>;
  /** 예약 성공한 회원 리스트  */
  members: Array<Pick<MemberDetailResponse, 'id' | 'name' | 'mobile'>>;
};

import filters from 'utils/filters';

import { BOOKING_COMMON_FORM_TEXT, REPEAT_SETTING_CHECK_RADIO_DATA } from '../../constants';
import { LECTURE_SIZE_CHECK_RADIO_DATA } from '../../Private/constants';
import { BookingPrivateFormType } from '../../Private/types';

const formatConfirmBookingInfo = (values: BookingPrivateFormType) => {
  const {
    lectureSize,
    selectedStaff,
    startDate,
    endDate,
    title,
    repeat,
    repeatCustomWeekday,
    startTime,
    endTime,
    selectedRoom,
    selectedUserTickets,
  } = values;

  let repeatValue: string | undefined = '반복 안함';
  if (repeat !== 'custom') {
    repeatValue = REPEAT_SETTING_CHECK_RADIO_DATA.find(({ value }) => value === repeat)?.label || '반복 안함';
  } else {
    repeatValue = repeatCustomWeekday.map(weekday => weekday.value).join(', ');
  }

  let lectureSizeValue = null;
  if (lectureSize) {
    lectureSizeValue = LECTURE_SIZE_CHECK_RADIO_DATA.find(({ value }) => value === lectureSize)?.label;
  }

  const dateValue = `${filters.dateWithWeekday(startDate)} ~ ${filters.dateWithWeekday(endDate)}`;
  const timeValue = filters.timePeriod(startTime, endTime);
  const roomValue = selectedRoom?.name ?? BOOKING_COMMON_FORM_TEXT.room.notSelected.label;
  const memberValue = selectedUserTickets.map(({ member }) => member.name).join(', ');

  return [
    { id: 'staff', label: '강사', value: `${selectedStaff?.name} 강사` },
    { id: 'date', label: '수업일', value: dateValue },
    { id: 'time', label: '수업시간', value: timeValue },
    { id: 'repeat', label: '반복설정', value: repeatValue },
    { id: 'room', label: '룸', value: roomValue },
    { id: 'size', label: '수업인원', value: lectureSizeValue },
    { id: 'member', label: '수강회원', value: memberValue },
    { id: 'title', label: '수업명', value: title },
  ];
};

export default formatConfirmBookingInfo;

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { UserTicketResponse } from '../queries/useInfinityUserTicketProduct';

/** 수강권, 상품 양도 */
const usePostUserTicketTransfer = (userTicketId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<UserTicketTransferParams, UserTicketResponse>({
    method: `post`,
    url: `/api/userTicket/transfer/${userTicketId}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostUserTicketTransfer;

export type UserTicketTransferParams = {
  user_id: number;
  transferor_id: number;
  max_coupon: number;
  remaining_coupon: number;
  remaining_cancel: number;
  availability_start_at: string;
  expire_at: string;
  settlement_at: string;
  method: string;
  transfer_amount: number;
  cash_amount: number;
  unpaid_amount: number;
  wiretransfer_amount: number;
  card_amount: number;
  installment_period: number;
  before_user_ticket_amount: number;
};

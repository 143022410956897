import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { TabDataElementType } from 'components/Tabs';
import FilterTabs from 'components/Tabs/FilterTabs';
import TabPanel from 'components/Tabs/TabPanel';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useQueryString from 'hooks/useQueryString';
import useTab from 'hooks/useTab';
import { BASIC_TAB_DATA } from 'pages/MemberDetail/constants';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import BookedClass from './BookedClass';
import MemberTicketSkeleton from './MemberTicketSkeleton';
import UserTicketList from './UserTicketList';

const Basic = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { onChange } = useTab(BASIC_TAB_DATA);
  const navigate = useNavigate();
  const { setSearchParams, getAllSearchParams, getSearchParams } = useQueryString();
  const tabValue = (getSearchParams('productType') ?? 'userTicket') as UserTicketParams['product_type'];

  const goExpiredPage = () => {
    navigate(`/product/expired/${currentMemberId}?productType=${tabValue}`);
  };

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams(
      {
        ...getAllSearchParams(),
        productType: tabData.value,
      },
      { replace: true },
    );
    onChange(tabData);
  };

  return (
    <>
      <TabWrapper>
        <FilterTabs
          data={BASIC_TAB_DATA}
          value={tabValue ?? 'userTicket'}
          onChange={changeTab}
          gap={6}
          selectedColor="point"
          padding={{ left: 16, right: 16 }}
        />
        <Button
          fontWeight={400}
          textColor="gray2"
          rightIcon={<Icon name="arrowRightBold" size={16} fillColor={theme.color.gray3} />}
          onClick={goExpiredPage}>
          이전 {PRODUCT_TYPE_TEXT[tabValue as UserTicketParams['product_type']]} 보기
        </Button>
      </TabWrapper>

      <TabPanel>
        <ApiBoundary fallback={<MemberTicketSkeleton />}>
          <UserTicketList currentMemberId={currentMemberId} productType={tabValue as UserTicketParams['product_type']} />
        </ApiBoundary>
        <ApiBoundary>
          <BookedClass currentMemberId={currentMemberId} />
        </ApiBoundary>
      </TabPanel>
    </>
  );
};

export default Basic;

const TabWrapper = styled.div`
  ${theme.flex('', 'center', 'space-between')};
  padding: 24px 20px 0;
`;

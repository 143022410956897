import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import { LinkButtonProps } from 'components/Button/LinkButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import getColor, { Color } from 'utils/getColor';
import getImageUrl from 'utils/getImageUrl';

type Props = Omit<LinkButtonProps, 'children'> & {
  staffInfo: {
    imgUrl: {
      path: string;
      name: string;
      extension: string;
    } | null;
    name: string;
    color: string | number;
  };
};

const StaffLinkButton = ({ staffInfo, ...props }: Props) => {
  return (
    <FormFieldLinkButton leftIcon={<Avatar imgUrl={getImageUrl(staffInfo.imgUrl)} />} {...props}>
      <ColorChipWithName>
        {!!staffInfo.color && <Icon name="colorChips" fillColor={color[getColor(staffInfo.color) as Color]} />}
        <div className="name-wrapper">
          <Typography className="name" size={15} weight={500} ellipsis={1}>
            {staffInfo.name}
          </Typography>
          <Typography span size={15} weight={500}>
            강사
          </Typography>
        </div>
      </ColorChipWithName>
    </FormFieldLinkButton>
  );
};

export default StaffLinkButton;

const ColorChipWithName = styled.div`
  ${theme.flex('', 'center', 'flex-start', 2)};
  svg {
    flex: 0 0 auto;
  }

  .name-wrapper {
    ${theme.flex('', 'center', 'flex-start', 3)};
    .name {
      flex: 1;
    }
  }
`;

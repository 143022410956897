import { studio } from '_mocks/fixtures/studio';
import dayjs from 'dayjs';
import { HttpResponse } from 'msw';

import { api } from '../utils';

export const handlers = [
  api.get('/studio', ({ params }) => {
    const onlyNotExpired = params?.expired === '0';
    return HttpResponse.json({
      studios: onlyNotExpired ? studio.filter(s => !dayjs().isBefore(s.expire_on)) : studio,
      staff_account_name: '홍길동',
    });
  }),
  api.patch('/studio/un-sync', () => {
    return HttpResponse.json({ message: '시설이 삭제되었습니다.' });
  }),
];

export const errors = [
  api.get('/studio', () => {
    return HttpResponse.json({}, { status: 503 });
  }),
];

import useCustomBookingForm from 'hooks/useCustomBookingForm';
import useQueryString from 'hooks/useQueryString';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCreateStateType } from 'pages/Booking/types';
import convertBookingDateCreateFormState from 'pages/Booking/utils/convertBookingDateCreateFormState';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { BookingPrivateFormType } from '../../types';
import ConfirmBeforeBooking from '../components/ConfirmBeforeBooking';
import PrivateCommonCreateForm from '../components/PrivateCommonCreateForm';
import PrivateFormLayout from '../components/PrivateFormLayout';

/** 프라이빗 수업 생성 */
const BookingPrivateCreate = () => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const { state } = useLocation() as { state: BookingCreateStateType };

  const { startDate, startTime, endTime } = convertBookingDateCreateFormState(
    state ?? {
      selectedDate: new Date(),
      from: 'floating',
    },
  );

  const methods = useCustomBookingForm<BookingPrivateFormType>({
    defaultValues: {
      title: '',
      startDate,
      endDate: startDate,
      startTime,
      endTime,
      lectureSize: '1',
      userTicketIds: [],
      selectedStaffId: String(currentStaffId),
      roomId: BOOKING_COMMON_FORM_TEXT.room.notSelected.value,
      repeat: null,
      repeatCustomWeekday: [],
      tempRepeatCustomWeekday: [],
      selectedUserTickets: [],
      originUserTicketIds: [],
      prevSelectedUserTickets: [],
    },
  });

  const { getAllSearchParams } = useQueryString();
  const { step } = getAllSearchParams();

  return (
    <PrivateFormLayout pageMode="create" {...methods}>
      {step === '2' ? <ConfirmBeforeBooking pageMode="create" /> : <PrivateCommonCreateForm pageMode="create" />}
    </PrivateFormLayout>
  );
};

export default BookingPrivateCreate;

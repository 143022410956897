import dayjs from 'dayjs';
import useInfinityBoardQna from 'hooks/service/queries/useInfinityBoardQna';
import NewQnaMark from 'pages/MoreDetails/Qna/List/components/NewQnaMark';
import { BASE_DATE, QNA_LOCAL_STORAGE_KEY } from 'pages/MoreDetails/Qna/List/constants';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import localStorage from 'utils/localStorage';

const QnaNewBadge = () => {
  const { data: qnaList } = useInfinityBoardQna();

  const currentStudioId = useRecoilValue(studioIdAtom);
  // 현재 시설 마지막 확인 시각을 로컬스토리지에서 가져옴
  const lastTimeList = localStorage.get(QNA_LOCAL_STORAGE_KEY) || {};
  const lastVisitTime = lastTimeList[currentStudioId] ?? BASE_DATE;
  const hasNewQna = qnaList.some(qna => dayjs(qna.created_at).isAfter(dayjs(lastVisitTime)));

  return hasNewQna ? <NewQnaMark /> : null;
};

export default QnaNewBadge;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import Filter from './Filter';
import PaymentList from './PaymentList';
import PaymentSkeleton from './PaymentSkeleton';

const Payment = ({ currentMemberId }: CurrentMemberIdProps) => {
  return (
    <Container>
      <Filter />

      <ApiBoundary fallback={<PaymentSkeleton />}>
        <PaymentList currentMemberId={currentMemberId} />
      </ApiBoundary>
    </Container>
  );
};

export default Payment;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  flex: 1;
`;

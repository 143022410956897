import { Eventcalendar } from '@mobiscroll/react';

import { Container as CalendarContainer } from './ScheduleCalendar';
import StudioExceptionCustomHeader from './StudioExceptionCustomHeader';

const StudioExceptionCalendarFallback = () => {
  return (
    <CalendarContainer viewType="day" allDaysLength={[0]}>
      <Eventcalendar
        theme="ios"
        themeVariant="light"
        view={{ schedule: { type: 'day' } }}
        renderHeader={() => <StudioExceptionCustomHeader />}
      />
    </CalendarContainer>
  );
};

export default StudioExceptionCalendarFallback;

import Typography from 'components/Typography';
import DateTimePicker from 'designedComponents/Pickers/DateTimePicker';
import {
  BOOKING_AVAILABLE_TIME_SETTING_TEXT,
  BOOKING_AVAILABLE_TIME_SETTING_TITLE,
  BOOKING_RULE_TYPE_DATA,
} from 'pages/Booking/constants';
import { SmallTitleTypography } from 'pages/Booking/Group/Form/components/Operation/Create/OthersAvailableSetting';
import { SettingByRuleTypeProps } from 'pages/Booking/types';
import { useEffect, useState } from 'react';

import { RuleTypeContainer, RuleTypeTypography, StyledRuleTypeTimeTypography, UpdateDateTypography } from '../StyledComponents';
import RuleTypeAccordion from './RuleTypeAccordion';

/**
 * 프라이빗/그룹 1개 수정 시,
 * 수업일 기준 - 시작시간부터 마감시간까지 (AR)
 * 수업시간 기준 - 시작시간부터 마감시간까지 (RR)
 */
const StartEndAtUpdate = ({
  iconName,
  target,
  lectureDate,
  isOpen,
  onToggle,
  textType,
  startAt,
  endAt,
  ruleType,
  changeDate,
}: SettingByRuleTypeProps) => {
  const startTarget = `${target}startAt`;
  const endTarget = `${target}EndAt`;
  const [expanded, setExpanded] = useState<string | false>(isOpen && startTarget);

  const changeAccordionOpen = (panel: string) => {
    onToggle(panel.includes('startAt') ? `${target}` : panel);
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  useEffect(() => {
    if (!isOpen && (startTarget || endTarget)) {
      setExpanded(false);
    }
  }, [endTarget, isOpen, startTarget]);

  return (
    <RuleTypeContainer className={`rule-type ${ruleType} update ${textType}`}>
      <RuleTypeTypography>{BOOKING_RULE_TYPE_DATA.filter(({ id }) => id === ruleType)[0].label}</RuleTypeTypography>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={expanded === startTarget}
        onChange={() => changeAccordionOpen(startTarget)}
        header={
          <div>
            <SmallTitleTypography>{BOOKING_AVAILABLE_TIME_SETTING_TITLE[textType]}</SmallTitleTypography>
            <StyledRuleTypeTimeTypography>
              <UpdateDateTypography isOpen={expanded === startTarget} startAt={startAt} />
              <Typography span size={15}>
                부터
              </Typography>
            </StyledRuleTypeTimeTypography>
          </div>
        }>
        <DateTimePicker max={endAt} defaultValue={startAt} value={startAt} onChange={e => changeDate(e, 'start')} />
      </RuleTypeAccordion>
      <RuleTypeAccordion
        className="update-end-at"
        isLast={textType === 'cancel'}
        noBottomBorder={textType === 'booking'}
        isOpen={expanded === endTarget}
        onChange={() => changeAccordionOpen(endTarget)}
        header={
          <StyledRuleTypeTimeTypography>
            <UpdateDateTypography isOpen={expanded === endTarget} endAt={endAt} />
            <Typography span size={15}>
              {BOOKING_AVAILABLE_TIME_SETTING_TEXT[textType]}
            </Typography>
          </StyledRuleTypeTimeTypography>
        }>
        <DateTimePicker max={lectureDate} defaultValue={endAt} value={endAt} onChange={e => changeDate(e, 'end')} />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default StartEndAtUpdate;

import styled from '@emotion/styled';
import { useState } from 'react';
import MainLayout from 'sharedComponents/MainLayout';

import CurrentPasswordForm from './components/CurrentPasswordForm';
import NewPasswordForm from './components/NewPasswordForm';
import { CHANGE_PASSWORD_TEXT } from './constants';

const MoreDetailsAccountPassword = () => {
  const [validatedPassword, setValidatedPassword] = useState('');

  const step = !validatedPassword ? 'current' : 'change';
  return (
    <MainLayout header={{ title: CHANGE_PASSWORD_TEXT[step].title, titleScrollThreshold: 40 }}>
      <Container>
        {!validatedPassword ? (
          <CurrentPasswordForm onSuccess={setValidatedPassword} />
        ) : (
          <NewPasswordForm validatedPassword={validatedPassword} />
        )}
      </Container>
    </MainLayout>
  );
};
export default MoreDetailsAccountPassword;

const Container = styled.div`
  padding: 8px 20px;
`;

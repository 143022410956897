import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import MessageFormLabel from '../../../components/MessageFormLabel';
import { MOBILE_FORM_TEXT } from '../constants';
import MobileSenderNumber from './MobileSenderNumber';

const MobileSender = () => {
  return (
    <Container>
      <MessageFormLabel labelText={MOBILE_FORM_TEXT.sender} hasTooltip tooltipMessages={MOBILE_FORM_TEXT.senderTooltip} />
      <ApiBoundary fallback={<Skeleton height={48} type="round" />}>
        <MobileSenderNumber />
      </ApiBoundary>
    </Container>
  );
};

export default MobileSender;

const Container = styled.div`
  padding: 0 20px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { ColorType } from 'types/colorTypes';

import Accordion, { AccordionProps } from '.';

type Props = AccordionProps & {
  /** 배경색, 기본 white 펼치지 않은 상태의 헤더와 펼친 상태의 컨텐츠에 적용 */
  backgroundColor?: ColorType;
  /** 펼쳐진 상태의 헤더 색, 기본 primary */
  activeBackgroundColor?: ColorType;
  /** 펼쳐진 상태의 아이콘 및 헤더 텍스트 색, 기본 white */
  activeColor?: ColorType;
};

const RoundedAccordion = ({ ...props }: Props) => {
  return <StyledAccordion {...props} />;
};

export default RoundedAccordion;

const StyledAccordion = styled(Accordion)<Props>`
  border-radius: 16px;
  &.opened .content-wrapper {
    border-radius: 0 0 16px 16px;
  }
  ${({ backgroundColor = 'white', activeBackgroundColor = 'primary', activeColor = 'white' }) => `
    background-color: ${theme.color[backgroundColor]};
    &.opened {
      background-color: ${theme.color[activeBackgroundColor]};
      svg {
        fill: ${theme.color[activeColor]} !important;
        opacity: 0.64;
      }
      .header-wrapper * {
        color: ${theme.color[activeColor]};
      }
      .content-wrapper {
        background-color: ${theme.color[backgroundColor]};
      }
    }`}
`;

import { useMutation } from '@tanstack/react-query';
import instance from 'api/axios';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import imageUpload from 'utils/imageUpload';

export type AttachmentType = 'boards' | 'memo' | 'studio_policies';

const usePostAttachment = (type: AttachmentType) => {
  const mutationFn = async (formData: FormData): Promise<AxiosResponse<AttachmentResponse>> => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await instance({
        method: 'post',
        url: `/api/attachment/${type}`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
    } catch (error) {
      // 여기서 에러 처리
      throw error;
    }
  };

  const mutation = useMutation({
    mutationFn,
  });

  const getAttachmentIds = useCallback(
    async (files: Array<File>) => {
      if (files.length === 0) return undefined;

      const responses = await Promise.all(
        files.map(async file => {
          const formData = (await imageUpload.imageCompressor(file, 'file')) as FormData;
          return await mutation.mutateAsync(formData);
        }),
      );

      const ids = responses.map(res => res.data.id);
      return ids;
    },
    [mutation],
  );

  return { ...mutation, getAttachmentIds };
};

export default usePostAttachment;

export type AttachmentResponse = {
  id: number;
  ref_type: string;
  ref_id: number;
  path: {
    path: string;
    name: string;
    extension: string;
  };
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
};

import { useSuspenseQuery } from '@tanstack/react-query';
import { PolicyType } from 'types/policyTypes';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 시설 상세 정보, useGetStudio와 구분을 위해 임의로 Info를 붙임 */
const useGetStudioInfo = (studioId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<StudioInfoResponse, StudioInfoParams>({
    url: `/api/studio/${studioId}`,
    params: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: ['studio_info', studioId],
    queryFn,
    select: data => data.data,
  });
};

export default useGetStudioInfo;

export type StudioInfoResponse = {
  id: number;
  name: string;
  contactInfos: Array<{
    id: number;
    contact: string; // "010-9999-9999"
    is_representative: boolean; // 대표번호 여부
  }>;
  openEvents: Array<{
    id: number;
    day_of_week: 1 | 2 | 3 | 4 | 5 | 6 | 0;
    day_of_week_ko: '월' | '화' | '수' | '목' | '금' | '토' | '일';
    start_time: string; // '09:00:00'
    end_time: string; //'18:00:00'
  }>;
  address: {
    id: number;
    studio_id: number;
    address: string; // '서울특별시 강남구 역삼동 123-4'
    detail_address: string | null; // '빌딩 3층'
  };
  policy: PolicyType;
};

type StudioInfoParams = {
  studio_id: undefined;
  staff_id: undefined;
};

import CheckBox from 'components/CheckBox';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import ApplyAllAmountButton from './ApplyAllAmountButton';
import PaymentTextField from './PaymentTextField';
import { PaymentCheckboxRow } from './StyledComponents';

/** 현금 입력 필드 */
const Cash = () => {
  const {
    control,
    setValue,
    setFocus,
    formState: { touchedFields },
  } = useFormContext<TicketPaymentFormType>();
  const isCheckedCash = useWatch({ control, name: 'isCheckedCash' });

  useEffect(() => {
    if (!touchedFields.isCheckedCash) return;
    if (isCheckedCash) {
      setFocus('payment.cash_amount');
    }
  }, [isCheckedCash, setFocus, touchedFields.isCheckedCash]);

  return (
    <>
      <PaymentCheckboxRow>
        <div className="left">
          <Controller
            name="isCheckedCash"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <CheckBox
                gap={10}
                id="cash"
                label={TICKET_PAYMENT_FORM_TEXT.cash.label}
                checked={value}
                value={'cash'}
                onChange={e => {
                  if (!e.target.checked) {
                    setValue('payment.cash_amount', 0, { shouldDirty: true });
                  }
                  setValue('isCheckedCash', e.target.checked, { shouldTouch: true });
                }}
                {...field}
              />
            )}
          />
        </div>
        {isCheckedCash && (
          <div className="apply-all">
            <ApplyAllAmountButton text={TICKET_PAYMENT_FORM_TEXT.cash.label} keyValue="payment.cash_amount" />
          </div>
        )}
        <div className="right">
          <PaymentTextField control={control} setValue={setValue} keyValue={'payment.cash_amount'} disabled={!isCheckedCash} />
        </div>
      </PaymentCheckboxRow>
    </>
  );
};

export default Cash;

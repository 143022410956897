import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import React, { Children, isValidElement } from 'react';

import Avatar from '.';

export type AvatarGroupProps = {
  /** Avatar 컴포넌트 */
  children: React.ReactNode;
  /** 다수의 프로필 나타내는 형태의 전체를 감싸는 사이즈 */
  AvatarSize?: 24 | 16;
  /** 아바타가 그룹 내 하나일 때 보일 사이즈 */
  oneAvatarSize?: number;
};

const AvatarGroup = ({ children, AvatarSize = 24, oneAvatarSize = 24 }: AvatarGroupProps) => {
  const childrenCount = Children.count(children);

  return (
    <Container
      className="avatar-group"
      hasOnlyOneAvatar={childrenCount === 1}
      AvatarSize={AvatarSize}
      oneAvatarSize={oneAvatarSize}>
      {Children.map(children, (child, i) => {
        if (!isValidElement(child) || child.type !== Avatar) return;

        if (i < 2) return child;
      })}
    </Container>
  );
};

const Container = styled.div<AvatarGroupProps & { hasOnlyOneAvatar: boolean }>(
  ({ AvatarSize, hasOnlyOneAvatar }) =>
    !hasOnlyOneAvatar &&
    css`
      width: ${AvatarSize}px;
      height: ${AvatarSize}px;
      position: relative;
      padding-left: ${AvatarSize === 24 ? '1px' : '0px'};
      padding-right: ${AvatarSize === 24 ? '1px' : '0px'};

      .avatar {
        padding: 0;
        position: absolute;
        width: ${AvatarSize === 24 ? 19 : 13}px;
        height: ${AvatarSize === 24 ? 19 : 13}px;
      }

      .avatar:first-of-type {
        z-index: 2;
        bottom: 0;
        right: ${AvatarSize === 24 ? '1px' : 0};
        border: 1px solid ${theme.color.white};
        border-radius: 50%;
      }

      .avatar:last-of-type {
        z-index: 1;
        top: 0;
        left: ${AvatarSize === 24 ? '1px' : 0};
      }
    `,
  ({ hasOnlyOneAvatar, oneAvatarSize }) =>
    hasOnlyOneAvatar &&
    css`
      width: ${oneAvatarSize}px;
      height: ${oneAvatarSize}px;

      .avatar {
        width: ${oneAvatarSize}px;
        height: ${oneAvatarSize}px;

        &:first-of-type {
          border: none;
        }
      }
    `,
);

export default AvatarGroup;

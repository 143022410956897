import styled from '@emotion/styled';
import { theme } from 'assets/styles';

type Props = {
  left: number;
};

const NoReadDot = ({ left }: Props) => {
  return <Dot left={left} />;
};

export default NoReadDot;

const Dot = styled.div<Props>`
  position: absolute;
  top: 8px;
  left: ${({ left }) => left}px;
  width: 4px;
  height: 4px;
  background-color: ${theme.color.secondary3};
  border-radius: 50%;
`;

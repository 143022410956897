import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';

const PaymentSkeleton = () => {
  const dummy = [
    { text: '수강권명', width: 145 },
    { text: '구분', width: 55 },
    { text: '판매가', width: 145 },
    { text: '포인트 적립', width: 80 },
    { text: '할인율', width: 55 },
    { text: '결제금액', width: 145 },
    { text: '미수금', width: 55 },
    { text: '결제방법', width: 80 },
  ];
  const dummyArray = Array.from({ length: 2 }, (_, i) => i);

  return (
    <>
      <TotalWrapper>
        <Typography size={13} textColor="gray2">
          총 결제금액
        </Typography>
        <div>
          <StyledSkeleton width={178} height={30} />
          <StyledSkeleton width={78} height={28} />
        </div>
      </TotalWrapper>
      <StyledUl>
        {dummyArray.map((_, index) => (
          <li key={index}>
            <CommonStyleCard>
              <div className="wrapper">
                <InfoTypography size={13}>결제일시</InfoTypography>
                <StyledSkeleton width={169} height={20} />
              </div>
              <Divider thin thinColor="gray6" />
              <StyledFlexWrapper gap={8}>
                {dummy.map(({ text, width }) => (
                  <StyledFlexRow key={text}>
                    <InfoTypography>{text}</InfoTypography>
                    <StyledSkeleton width={width} height={20} />
                  </StyledFlexRow>
                ))}
              </StyledFlexWrapper>
            </CommonStyleCard>
          </li>
        ))}
      </StyledUl>
    </>
  );
};

export default PaymentSkeleton;

const TotalWrapper = styled.div`
  padding: 24px 20px 20px;

  > div {
    margin-top: 6px;
    ${theme.flex('row', 'flex-start', 'space-between')};
  }
`;

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 12)};
  padding: 20px;
  background-color: ${theme.color.gray6};

  .wrapper {
    padding-bottom: 14px;

    div {
      margin-top: 4px;
    }
  }
`;

const StyledFlexWrapper = styled(InfoFlexWrapper)`
  padding-top: 14px;
`;

const StyledFlexRow = styled(InfoFlexRow)`
  .typography:last-of-type {
    text-align: left;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 3px;
`;

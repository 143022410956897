import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { LectureDataResponse } from 'hooks/service/queries/useInfinityReportLecture';
import filters from 'utils/filters';

import ReportLectureAccordion from './ReportLectureAccordion';

type Props = {
  lectures: LectureDataResponse[][];
};
const ReportLectureCard = ({ lectures }: Props) => {
  return (
    <>
      {lectures.map((lecture, index) => {
        return (
          <Container key={index}>
            <Typography className="date-text" size={13} weight={500} textColor="gray2">
              {filters.dateMonthDayKor(lecture[0].action_at)}
            </Typography>
            {lecture.map(lectureData => {
              return (
                <ReportLectureAccordion key={`${lectureData.lecture_id}-${lectureData.member.id}`} lectureData={lectureData} />
              );
            })}
          </Container>
        );
      })}
    </>
  );
};

export default ReportLectureCard;

const Container = styled.div`
  margin-bottom: 40px;

  .accordion:not(:first-of-type) {
    margin-top: 12px;
  }
`;

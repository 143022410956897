/**
 * 바이트
 * 시설웹 smsByteCheckWithPreventWriting함수과 동일
 * escape 함수가 deprecated 되어서 encodeURI로 변경
 */
export const smsByteCheck = (str: string) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i);
    try {
      if (encodeURI(char).length > 4) {
        count += 2;
      } else if (char !== '\r') {
        count += 1;
      }
    } catch (e) {
      // 이모지나 특수문자 등일 경우 인코딩 오류일 경우 4바이트로 취급
      count += 2;
    }
  }
  return count;
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography, { TypographyProps } from 'components/Typography';
import { useMemo } from 'react';

import { ISelectProps } from '..';

type SelectButtonPropTypes = Omit<ISelectProps, 'option' | 'onClose'>;

const SelectButton = ({
  type,
  isOpen,
  setIsOpen,
  height = 'small',
  value,
  placeholder,
  readOnly,
  disabled,
}: SelectButtonPropTypes) => {
  const isSmall = useMemo(() => height === 'small', [height]);

  const typographyProps: Pick<TypographyProps, 'size' | 'weight' | 'lineHeight'> = useMemo(() => {
    return {
      size: isSmall ? 13 : 15,
      lineHeight: isSmall ? 18 : 22,
      textColor: readOnly ? 'gray3' : value ? 'gray1' : 'gray4',
    };
  }, [isSmall, readOnly, value]);

  const iconName = useMemo(() => {
    if (isSmall && type === 'drawer') return isOpen ? 'arrowTopFill' : 'arrowBottomFill';
    return isOpen ? 'arrowTop' : 'arrowBottom';
  }, [isSmall, type, isOpen]);

  const iconSize = useMemo(() => (isSmall ? 16 : 24), [isSmall]);

  const changeIsOpen = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  return (
    <Container isSmall={isSmall} onClick={changeIsOpen}>
      <Typography {...typographyProps} weight={500}>
        {value || placeholder}
      </Typography>

      {readOnly || <Icon name={iconName} size={iconSize} color={disabled ? 'gray4' : 'gray2'} />}
    </Container>
  );
};

export default SelectButton;

const Container = styled.section<{ isSmall: boolean }>(
  ({ isSmall }) => css`
    ${theme.flex('row', 'center', 'space-between')};
    padding: 0 12px 0 16px;
    width: 100%;
    height: ${isSmall ? 40 : 48}px;
  `,
);

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useGetStaffEventSchedules from 'hooks/service/queries/useGetStaffEventSchedules';
import useParamsId from 'hooks/useParamsId';
import { orderBy } from 'lodash';
import { StaffFieldFormType } from 'pages/MoreDetails/Staff/List/types';
import { Fragment, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { listHeaderTotalAtom } from 'recoil/staff';
import getEventTimeDatasetNew from 'utils/getEventTimeDatasetNew';

import EventScheduleCard from './EventScheduleCard';

const EventScheduleCardList = () => {
  const setListHeaderTotalAtom = useSetRecoilState(listHeaderTotalAtom);

  const targetStaffId = useParamsId();
  const { data: eventSchedules } = useGetStaffEventSchedules({ targetStaffId });
  const eventTimesDataset = useMemo(() => getEventTimeDatasetNew(eventSchedules), [eventSchedules]);

  const { control } = useFormContext<StaffFieldFormType>();
  const sort = useWatch({ control, name: 'sort' });

  useEffect(() => {
    setListHeaderTotalAtom(eventTimesDataset.length);
  }, [setListHeaderTotalAtom, eventTimesDataset.length]);

  const sortedDataset = useMemo(() => {
    return orderBy(eventTimesDataset, ['date'], [sort]);
  }, [sort, eventTimesDataset]);

  if (!eventTimesDataset.length) {
    return (
      <NoContentContainer>
        <Typography size={15} textColor="gray2" opacity={0.8}>
          등록된 일정이 없습니다.
        </Typography>
      </NoContentContainer>
    );
  }

  return (
    <ul>
      {sortedDataset.map((workTime, index, list) => {
        return (
          <Fragment key={workTime.id}>
            <EventScheduleCard workTime={workTime} dataset={list} />
            {list.length - 1 !== index && <Divider thin />}
          </Fragment>
        );
      })}
    </ul>
  );
};

export default EventScheduleCardList;

const NoContentContainer = styled.div`
  ${theme.flex()};
  margin-top: 100px;
`;

import { RegisterDataType } from 'pages/Join/types';
import { atom } from 'recoil';

export const registerDataAtom = atom<RegisterDataType>({
  key: 'registerDataAtom',
  default: {
    mobile: '',
    password: '',
    password_confirmation: '',
    name: '',
    nickname: '',
    birthday: '',
    gender: '',
  },
});

import styled from '@emotion/styled';
import Button from 'components/Button';
import usePostHoliday from 'hooks/service/mutations/usePostHoliday';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

import { HolidaySettingFormType } from '../type';
import AutoDeductionEnabled from './formField/AutoDeductionEnabled';
import HolidayDate from './formField/HolidayDate';
import SubmitDrawer from './SubmitDrawer';

const HolidaySettingForm = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const methods = useForm<HolidaySettingFormType>({
    defaultValues: {
      holidayDate: filters.dateDash(new Date()),
      notActionAutoBalance: false,
    },
  });

  const { handleSubmit, watch } = methods;

  const { mutate: postHolidayMutate } = usePostHoliday();

  const dateText = filters.date(watch('holidayDate'));

  const openSubmitDrawer = () => setIsDrawerOpen(true);
  const closeSubmitDrawer = () => setIsDrawerOpen(false);

  const submitHoliday: SubmitHandler<HolidaySettingFormType> = inputhValues => {
    const { holidayDate: date, notActionAutoBalance: not_action_auto_balance } = inputhValues;

    const params = {
      date: filters.dateDash(date),
      not_action_auto_balance,
    };

    postHolidayMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: '휴일이 추가되었습니다.' });
        closeSubmitDrawer();
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <HolidayDate />
        <AutoDeductionEnabled />

        <Button variant="contained" color="point" size="medium48" fullWidth onClick={openSubmitDrawer}>
          저장
        </Button>
      </FormProvider>

      <SubmitDrawer
        isOpen={isDrawerOpen}
        dateText={dateText}
        onClose={closeSubmitDrawer}
        onSubmit={handleSubmit(submitHoliday)}
      />
    </Container>
  );
};

export default HolidaySettingForm;

const Container = styled.form`
  padding: 16px 20px 24px;
`;

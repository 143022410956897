import { atom } from 'recoil';

export const showBottomNavAtom = atom({
  key: 'showBottomNavAtom',
  default: true,
});

export const isPageEndAtom = atom({
  key: 'isPageEndAtom',
  default: false,
});

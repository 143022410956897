import usePatchMember from 'hooks/service/mutations/usePatchMember';
import { useMemberForm } from 'hooks/useMemberForm';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';

import CommonMemberForm from '../components/CommonMemberForm';

type Props = CurrentMemberIdProps & {
  isUserGrade: boolean;
};

const MemberUpdateForm = ({ currentMemberId, isUserGrade }: Props) => {
  const { mutate: updateMemberMutate } = usePatchMember(currentMemberId);
  const { onSubmit } = useMemberForm({ pageMode: 'update', mutationFunc: updateMemberMutate });

  return <CommonMemberForm pageMode="update" onSubmit={onSubmit} isUserGrade={isUserGrade} />;
};

export default MemberUpdateForm;

import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import usePatchBookingGroupCancel from 'hooks/service/mutations/usePatchBookingGroupCancel';
import usePatchBookingPrivateCancel from 'hooks/service/mutations/usePatchBookingPrivateCancel';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { BOOKING_DETAIL_CANCEL_DIALOG_TEXT } from 'pages/Booking/constants';
import { BookingType } from 'pages/Booking/types';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose: () => void;
  bookingType: BookingType;
  currentMemberBooking: LectureBookingListResponse;
  currentBookingTraineeCount: number;
};

const CancelBookingDialog = ({ bookingType, currentMemberBooking, currentBookingTraineeCount, onClose }: Props) => {
  const type = bookingType === 'private' && currentBookingTraineeCount === 1 ? 'delete' : 'cancel';

  const queryClient = useQueryClient();
  const isMutating = useIsMutating() > 0;
  const navigate = useNavigate();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: cancelPrivateBookingMutate } = usePatchBookingPrivateCancel(currentMemberBooking.id);
  const { mutate: cancelGroupBookingMutate } = usePatchBookingGroupCancel(currentMemberBooking.id);

  const mutateCallback = () => {
    return {
      onSettled: onClose,
      onSuccess: () => {
        setToast({
          type: 'success',
          message: BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].successMessage,
          bottom: type === 'delete' ? 76 : 40,
        });
        if (type === 'delete') {
          navigate('/schedule');
        } else {
          /**
           * 프라이빗 수업은 한 명 남은 예약 취소 시,
           * 수업 삭제까지 같이 되어 버려서 api hook에 쿼리 갱신 코드 넣을 수 없음
           * 상세 페이지 다시 부르려고 하면 404 에러 발생
           */
          queryClient.invalidateQueries({
            queryKey: ['lecture', 'detail', currentMemberBooking.lecture_id],
          });
        }
      },
      onError: (error: Error) => {
        errorDialog.open(error);
      },
    };
  };

  const clickPositiveAction = () => {
    if (bookingType === 'private') {
      cancelPrivateBookingMutate(undefined, mutateCallback());
    } else {
      cancelGroupBookingMutate(undefined, mutateCallback());
    }
  };

  return (
    <Dialog
      onClose={onClose}
      positiveAction={{
        text: BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].positiveText,
        disabled: isMutating,
        onClick: clickPositiveAction,
      }}
      negativeAction={{
        text: BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].negativeText,
        disabled: isMutating,
      }}>
      <DialogDescription tag="pre">
        <Typography size={15} weight={600} span>
          [{currentMemberBooking.member.name}]
        </Typography>
        {BOOKING_DETAIL_CANCEL_DIALOG_TEXT[type].description}
      </DialogDescription>
    </Dialog>
  );
};

export default CancelBookingDialog;

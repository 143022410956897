import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement>;

const StudioIcon = (props: Props) => {
  return (
    <Container {...props}>
      <Icon name="centerFill" size={24} fillColor={theme.color.gray3} />
    </Container>
  );
};

export default StudioIcon;

const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: ${theme.color.gray6};
  ${theme.flex()}
  flex: 0 0 auto;
`;

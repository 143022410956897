import Icon from 'components/Icon';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import useToast from 'hooks/useToast';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { BOOKING_GROUP_FORM_TEXT } from '../../constants';
import { BookingGroupCreateFormType } from '../../types';

/** 수업 소개 */
const Description = () => {
  const { control, formState } = useFormContext<BookingGroupCreateFormType>();
  const { setToast } = useToast();

  useEffect(() => {
    if (formState.errors.title || !formState.errors.description) return;
    setToast({ type: 'error', message: formState.errors.description.message ?? null, bottom: 92 });
  }, [formState.errors.description, formState.errors.title, setToast]);

  return (
    <UnderlinedTextareaController
      controllerProps={{ control, name: 'description' }}
      placeholder={BOOKING_GROUP_FORM_TEXT.description.placeholder}
      left={<Icon name="explain" color="gray2" />}
      showBottom={false}
      maxLength={200}
    />
  );
};

export default Description;

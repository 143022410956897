import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MemberDetailResponse } from '../queries/useGetMemberDetail';
import { MemberCreateParams } from './usePostMember';

const usePatchMember = (memberId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemberCreateParams, MemberDetailResponse>({
    method: `patch`,
    url: `/api/member/${memberId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['member'],
      });
    },
  });
};

export default usePatchMember;

import Timer from 'components/Timer';
import { useFormContext } from 'react-hook-form';

import { CHANGE_PHONE_NUMBER_TEXT, KAKAO_VALIDATION_TIME } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

const ValidationTimer = () => {
  const { watch, setError } = useFormContext<ChangePhoneNumberFormType>();
  const validationId = watch('validationId');
  const step = watch('step');

  const timeoutError = () => {
    setError('mobileConfirmCode', { type: 'timeout', message: CHANGE_PHONE_NUMBER_TEXT.expired });
  };

  return step === 'validationCode' && !!validationId ? (
    <Timer key={validationId} count={KAKAO_VALIDATION_TIME} onTimeOut={timeoutError} />
  ) : null;
};

export default ValidationTimer;

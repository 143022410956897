import { bookingParamsAtom, checkinParamsAtom, deletedLectureParamsAtom } from 'recoil/moreDetail';

import Sorting from './Sorting';

type Props = {
  currentTab: string;
};

const ScheduleSorting = ({ currentTab }: Props) => {
  return (
    <>
      {currentTab === 'booking' && <Sorting filterAtom={bookingParamsAtom} />}
      {currentTab === 'checkin' && <Sorting filterAtom={checkinParamsAtom} />}
      {currentTab === 'deletedLecture' && <Sorting filterAtom={deletedLectureParamsAtom} />}
    </>
  );
};

export default ScheduleSorting;

import SearchBar from 'components/SearchBar';
import { SEARCH_PLACEHOLDER } from 'constants/text';
import { Controller, useFormContext } from 'react-hook-form';

import { StaffFieldFormType } from '../List/types';

type Props = {
  type?: 'staff' | 'assignedMember';
  onSubmit: () => void;
  onCancel: () => void;
};

const StaffSearchField = ({ type = 'staff', onSubmit, onCancel }: Props) => {
  const { control, setValue } = useFormContext<StaffFieldFormType>();

  const isAssignedMemberPage = type === 'assignedMember';

  return (
    <Controller
      name="search"
      control={control}
      render={({ field }) => (
        <SearchBar
          id="search-staff"
          placeholder={isAssignedMemberPage ? SEARCH_PLACEHOLDER.STAFF_ASSIGNED_MEMBER : SEARCH_PLACEHOLDER.STAFF_AND_MOBILE}
          onClear={() => setValue('search', '')}
          onCancel={onCancel}
          onSubmitForm={() => {
            onSubmit();
            (document.activeElement as HTMLElement).blur();
          }}
          {...field}
        />
      )}
    />
  );
};

export default StaffSearchField;

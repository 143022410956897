import styled from '@emotion/styled';
import { USER_TICKET_TAB_DATA } from 'pages/ExpiredTicket/constants';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

import HoldingEndPicker from './HoldingEndPicker';

const HoldingPeriodPicker = ({ userTicket, canUpdateUserTicket }: UserTicketProps) => {
  const { control, setValue } = useFormContext<TicketHoldingFormType>();
  const startOn = useWatch({ control, name: 'params.start_on' });
  const endOn = useWatch({ control, name: 'params.end_on' });
  const initialDate = !startOn || !endOn ? undefined : [startOn, endOn];
  /** 이전 수강권/상품 보기에서 넘어온 경우 정지 기간 설정할 수 없도록 */
  const disabled = USER_TICKET_TAB_DATA.map(({ value }) => value).includes(userTicket.user_ticket_status) || !canUpdateUserTicket;

  const saveDate = (period: string[]) => {
    const startOn = period[0] ? filters.dateDash(period[0]) : '';
    const endOn = period[1] ? filters.dateDash(period[1]) : '';

    setValue('params.start_on', startOn, { shouldDirty: true });
    setValue('params.end_on', endOn, { shouldDirty: true });
    return false;
  };

  /**
   * - 현재 정지중일 때, 정지시작일 수정 불가 및 기간 변경은 가능해서 종료일만 선택 가능.
   * - 현재 정지중이 아닐 때, 수강권 이용시작일 이전 날짜를 정지 기간으로 선택 불가(min).
   */
  return (
    <Container>
      {userTicket.holdings ? (
        <HoldingEndPicker canUpdateUserTicket={canUpdateUserTicket} />
      ) : (
        <PeriodPicker
          startDateDisabled={disabled}
          endDateDisabled={disabled}
          min={filters.date(userTicket.availability_start_at)}
          initialDate={initialDate}
          onSave={saveDate}
        />
      )}
    </Container>
  );
};

export default HoldingPeriodPicker;

const Container = styled.div`
  margin: 12px 0;
`;

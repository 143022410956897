import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';
import { useLocation } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import { MessageType } from '../../types';
import AdTextMandatory from './components/AdTextMandatory';
import CurrentPoint from './components/CurrentPoint';
import MobileForm from './components/MobileForm';
import { MOBILE_FORM_TEXT } from './constants';

const MoreDetailsMessageMobileForm = () => {
  const { state } = useLocation() as { state?: MessageType };

  const mode = state?.mode || 'create';

  const title = `${MOBILE_FORM_TEXT.title}${mode === 'update' ? MOBILE_FORM_TEXT.titleEditSuffix : ''}`;
  return (
    <MainLayout header={{ title }}>
      <Row>
        <AdTextMandatory />
        <ApiBoundary fallback={<Skeleton height={40} type="round" />}>
          <CurrentPoint />
        </ApiBoundary>
      </Row>
      <ApiBoundary>
        <MobileForm defaultValues={state} />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsMessageMobileForm;

const Row = styled.section`
  padding: 8px 20px 12px;
  ${theme.flex('', 'center', 'center', 8)}
  border-bottom: 1px solid ${theme.color.gray6};

  .ad-text-mandatory {
    flex: 0 0 144px;
  }
  > div {
    flex: 1;
  }
`;

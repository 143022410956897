import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography, { TypographyProps } from 'components/Typography';
import { memo, useMemo } from 'react';

import { ButtonProps } from '.';
import ButtonBase from './ButtonBase';

export interface LinkButtonProps extends ButtonProps {
  /** border-bottom 노출 유무 */
  showBorder?: boolean;
  /** 텍스트 왼편에 아이콘 추가 */
  leftIcon?: React.ReactNode;
  /** 텍스트 왼편에 아이콘과 텍스트 사이의 gap */
  gap?: number;
  /** 오른쪽 화살표 아이콘 노출 유무 */
  showArrow?: boolean;
  /** 오른쪽 화살표 컬러, 기본 컬러는 gray3 */
  arrowColor?: string;
  /** 오른쪽 화살표 사이즈, 기본 24 */
  arrowSize?: number;
  /** 오른쪽 화살표 아이콘 굵기 여부 */
  arrowBold?: boolean;
}

const LinkButton = ({
  className,
  children,
  leftIcon,
  arrowSize,
  arrowColor,
  arrowBold,
  fontWeight = 400,
  fontSize = 15,
  textColor,
  showArrow = true,
  fullWidth = true,
  showBorder = true,
  gap = 0,
  size = 'large56',
  edge = 'square',
  padding = { left: 20, right: 20 },
  ...props
}: LinkButtonProps) => {
  const baseOption = { fullWidth, size, edge, gap, padding };

  const typographyWeight: TypographyProps['weight'] = useMemo(() => {
    if (size === 'large60') return 600;
    return fontWeight;
  }, [fontWeight, size]);

  return (
    <StyledButton
      className={className ? `link-button ${className}` : `link-button`}
      showBorder={showBorder}
      {...baseOption}
      {...props}>
      {leftIcon && leftIcon}

      <div className="link-button-contents-wrapper">
        {typeof children === 'string' ? (
          <Typography span size={fontSize} weight={typographyWeight} textColor={textColor} ellipsis={props.ellipsis}>
            {children}
          </Typography>
        ) : (
          children
        )}
      </div>

      {showArrow && (
        <div className="link-button-arrow-wrapper">
          <Icon name={arrowBold ? 'arrowRightBold' : 'arrowRight'} size={arrowSize} fillColor={arrowColor || theme.color.gray3} />
        </div>
      )}
    </StyledButton>
  );
};

const StyledButton = styled(ButtonBase)<{ showBorder?: boolean }>`
  background-color: ${theme.color.white};
  justify-content: space-between;
  border-bottom: ${({ showBorder }) => (showBorder ? `1px solid ${theme.color.gray8}` : 'none')};

  svg {
    display: block;
  }

  .link-button-contents-wrapper {
    flex: 1;
    text-align: left;
  }

  .link-button-arrow-wrapper {
    margin-left: auto;
  }
`;

export default memo(LinkButton);

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { WeekdayNum } from '../mutations/usePostBookingPrivate';
import { CommonEtcScheduleDetailResponse } from './useGetEtcScheduleEtcScheduleTime';

/** 기타일정 종일 일정 상세 */
const useGetEtcScheduleEtcSchedule = (etcScheduleId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<{ data: EtcScheduleBulkDetailResponse }>({
    url: `/api/etcSchedule/etcSchedule/${etcScheduleId}`,
  });

  return useSuspenseQuery({
    queryKey: ['etc/schedule', 'detail', etcScheduleId],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetEtcScheduleEtcSchedule;

export type EtcScheduleBulkDetailResponse = CommonEtcScheduleDetailResponse & {
  start_date: string;
  end_date: string;
  start_time: string | null;
  end_time: string | null;
  weekday: Array<WeekdayNum>;
};

export const TICKET_TAB_DATA = [
  { value: 'A', label: '전체' },
  { value: 'G', label: '그룹' },
  { value: 'P', label: '프라이빗' },
  { value: 'F', label: '즐겨찾기' },
];

export const ITEM_TAB_DATA = [
  { value: 'A', label: '전체' },
  { value: 'S', label: '판매' },
  { value: 'R', label: '대여' },
  { value: 'F', label: '즐겨찾기' },
];

export const PRODUCT_TYPE_TEXT = {
  userTicket: '수강권',
  item: '상품',
};

export const SELECT_TICKET_TEXT = {
  userTicket: {
    subTitle: '발급할 수강권을 선택해 주세요.',
  },
  item: {
    subTitle: '발급할 상품을 선택해 주세요.',
  },
};

export const PRODUCT_FORM_TEXT = {
  footerButton: {
    create: '발급 완료',
    update: '수정 완료',
  },
};

import { TicketCommon } from 'hooks/service/queries/useInfinityUserTicketProduct';

/** 티켓 타입별로 기간, 횟수 노출여부 판별 */
const getTicketInfoByType = (type: TicketCommon['type']) => {
  /**
   * 기간 노출
   * - 수강권: 기간제(P), 횟수제(T)
   * - 상품 대여형: 기간제(RP), 혼합(RM)
   */
  let showPeriod = false;
  switch (type) {
    case 'P':
    case 'T':
    case 'RP':
    case 'RM':
      showPeriod = true;
      break;
  }

  /**
   * 횟수 노출
   * - 수강권: 횟수제
   * - 상품 대여형: 횟수제, 혼합
   */
  let showCoupon = false;
  switch (type) {
    case 'T':
    case 'RT':
    case 'RM':
      showCoupon = true;
      break;
  }

  return {
    showCoupon,
    showPeriod,
  };
};

export default getTicketInfoByType;

import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import UnderlinedTextField from 'components/TextField/components/UnderlinedTextField';
import Typography from 'components/Typography';
import { useFormContext } from 'react-hook-form';
import filters from 'utils/filters';

import { TicketPaymentFormType } from '../types';
import { PaymentRow } from './StyledComponents';

/** 부모 수강권의 금액(수정 불가) */
const SalePrice = () => {
  const { getValues } = useFormContext<TicketPaymentFormType>();

  return (
    <PaymentRow>
      <UnderlinedTextField
        id="amount"
        readOnly
        textAlign="right"
        left={{ prefix: <Icon name="money" fillColor={theme.color.gray3} />, text: '판매가' }}
        suffix={
          <Typography span size={15} weight={500} textColor="gray3">
            원
          </Typography>
        }
        value={filters.numberComma(getValues('salePrice') || 0)}
      />
    </PaymentRow>
  );
};

export default SalePrice;

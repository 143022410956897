import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion, { AccordionProps } from 'components/Accordion';
import Icon, { IconType } from 'components/Icon';
import Typography, { TypographyProps } from 'components/Typography';
import { ReactNode, useEffect, useMemo, useState } from 'react';

type Props = AccordionProps &
  Pick<TypographyProps, 'textColor'> & {
    /** 왼쪽 아이콘 종류 */
    iconName: IconType;
    /** 값이 있는지 없는지 여부에 따라 기본 textColor 변경됨 */
    hasValue?: boolean;
    /** 아코디언이 자동으로 닫혀야 할 때 */
    closeTrigger?: ReactNode;
  };

const FormFieldAccordion = ({
  children,
  iconName,
  header,
  textColor,
  hasValue,
  closeTrigger,
  isOpen,
  onChange,
  ...props
}: Props) => {
  const [isOpenState, setIsOpenState] = useState(false);
  const isControlled = isOpen !== undefined && onChange !== undefined;
  const isOpenControl = isControlled ? isOpen : isOpenState;

  const accordionTextColor = useMemo(() => {
    if (isOpenControl) return 'primary';
    if (!hasValue) return 'gray3';
    return textColor;
  }, [hasValue, isOpenControl, textColor]);

  const toggleAccordion = () => {
    if (isControlled) {
      onChange && onChange();
    } else {
      setIsOpenState(!isOpenControl);
    }
  };

  const closeAccordion = () => {
    if (isOpenControl && isControlled) {
      onChange && onChange();
    } else {
      setIsOpenState(false);
    }
  };

  useEffect(() => {
    if (!closeTrigger) return;
    const timer = setTimeout(() => {
      closeAccordion();
    }, 250);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeTrigger]);

  return (
    <StyledAccordion
      size="medium"
      leftIcon={<Icon name={iconName} color="gray2" />}
      showArrow={false}
      gap={10}
      isOpen={isOpenControl}
      onChange={toggleAccordion}
      header={
        typeof header === 'string' ? (
          <Typography size={15} weight={isOpenControl ? 600 : 500} textColor={accordionTextColor}>
            {header}
          </Typography>
        ) : (
          header
        )
      }
      {...props}>
      {children}
    </StyledAccordion>
  );
};

export default FormFieldAccordion;

const StyledAccordion = styled(Accordion)<{ isOpen: boolean }>(
  ({ isOpen }) => css`
    &.accordion {
      border-bottom: 1px solid ${theme.color[isOpen ? 'gray6' : 'gray8']};

      .header-wrapper {
        border-bottom: ${isOpen ? `1px solid ${theme.color.gray8}` : 'none'};
      }
    }
  `,
);

import { yupResolver } from '@hookform/resolvers/yup';
import usePostEtcSchedule from 'hooks/service/mutations/usePostEtcSchedule';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import { BookingCreateStateType } from 'pages/Booking/types';
import { convertBookingDateCreateFormState } from 'pages/Booking/utils';
import { ETC_FORM_TEXT, ETC_FORM_VALIDATE } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useLocation, useNavigate } from 'react-router-dom';

import EtcFormMainLayout from '../components/EtcFormMainLayout';
import formatEtcParams from '../utils/formatEtcParams';

const FormMain = () => {
  const { data } = useGetStaffMe();

  const { state } = useLocation() as { state: BookingCreateStateType };
  const { startDate, startTime, endTime } = convertBookingDateCreateFormState(
    state ?? {
      selectedDate: new Date(),
      from: 'floating',
    },
  );

  const methods = useCustomBookingForm<EtcScheduleCreateFormType>({
    resolver: yupResolver(ETC_FORM_VALIDATE),
    defaultValues: {
      title: '',
      startDate,
      endDate: startDate,
      startTime,
      endTime,
      repeat: null,
      repeatCustomWeekday: [],
      tempRepeatCustomWeekday: [],
      is_all_day: true,
      is_overlap: true,
      is_private: true,
      color: data.profile.representative_color,
      representativeColor: data.profile.representative_color,
      staff_ids: [data.id],
      selectedStaffs: [{ id: data.id, name: data.name, avatar: data.avatar ? data.avatar.image : null }],
      tempSelectedStaffs: [{ id: data.id, name: data.name, avatar: data.avatar ? data.avatar.image : null }],
    },
  });

  const navigate = useNavigate();
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const { mutate: createEtc } = usePostEtcSchedule();

  const submit = (values: EtcScheduleCreateFormType) => {
    const params = formatEtcParams(values);
    createEtc(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: ETC_FORM_TEXT.successMessage.create, bottom: 76 });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(
            <FailAllBooking
              fails={error.response?.data}
              closePopup={() => {
                navigate(-1);
              }}
            />,
          );
          return false;
        }
        return true;
      },
    });
  };

  return (
    <EtcFormMainLayout {...methods} pageMode="create" onSubmit={submit}>
      <BookingRangeDate isRange />
    </EtcFormMainLayout>
  );
};

export default FormMain;

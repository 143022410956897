import styled from '@emotion/styled';
import Counter from 'components/Counter';
import Typography from 'components/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import FormField from 'sharedComponents/FormField';
import FormFieldTypography from 'sharedComponents/FormField/FormFieldTypography';

import { BookingGroupCreateFormType } from '../../types';
import { TraineeCounterWrapper } from './MaxTrainee';

const WaitingTrainee = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();

  return (
    <FormField className="waiting-trainee" iconName="waiting" height={81}>
      <div>
        <TraineeCounterWrapper>
          <FormFieldTypography textColor="gray1" fontWeight={400}>
            예약대기 가능 인원
          </FormFieldTypography>
          <Controller
            name="waitingTrainee"
            control={control}
            render={({ field: { ref, ...rest } }) => <Counter id="waitingTrainee" {...rest} />}
          />
        </TraineeCounterWrapper>
        <StyledTypography size={13} textColor="gray2">
          0을 입력할 경우, 예약대기 인원수에는 제한이 없습니다.
        </StyledTypography>
      </div>
    </FormField>
  );
};

export default WaitingTrainee;

const StyledTypography = styled(Typography)`
  margin-top: 8px;
`;

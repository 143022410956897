import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import useInfinityBoardNotice from 'hooks/service/queries/useInfinityBoardNotice';
import usePermission from 'hooks/usePermission';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import { NOTICE_IDENTIFIER, NOTICE_SORT_IDENTIFIER } from '../../constants';
import { NOTICE_LIST_TEXT } from '../constants';
import NoticeListItem from './NoticeListItem';

type Props = {
  permissionDenied?: boolean;
};

const NoticeList = ({ permissionDenied }: Props) => {
  const { searchKeyword } = useSearchKeywords(NOTICE_IDENTIFIER);
  const { searchKeyword: sortKeyword } = useSearchKeywords(NOTICE_SORT_IDENTIFIER);
  const sort = sortKeyword === 'asc' ? 'asc' : 'desc';
  const { hasPermission } = usePermission();
  const canViewNotice = hasPermission(PERMISSION.board.notice.view);

  // 등록삭제 403에러로 진입했지만 조회권한도 없으면 api 호출하지 않음
  const canCallApi = !permissionDenied || canViewNotice;

  const {
    data: notices,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isRefetching,
    isError,
    error,
    refetch,
  } = useInfinityBoardNotice(canCallApi, searchKeyword, sort);

  const { showBoundary } = useErrorBoundary();

  // 등록삭제 403으로 진입했지만 조회권한도 없어서 호출못한 경우 더보기 메인으로
  if (!canCallApi) {
    return <Navigate to="/more-details" replace />;
  }
  if (isError) {
    showBoundary(error);
  }

  if (notices?.length === 0)
    return (
      <Container isEmpty>
        <Typography size={15} textColor="gray2">
          {searchKeyword ? (
            <>
              <b>&ldquo;{searchKeyword}&rdquo;</b>
              {`${NOTICE_LIST_TEXT.noSearchResult}`}
            </>
          ) : (
            NOTICE_LIST_TEXT.noNotice
          )}
        </Typography>
      </Container>
    );

  return (
    <PullToRefresh onRefresh={refetch} isRefetching={isRefetching}>
      <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
        <Container>{notices?.map(notice => <NoticeListItem key={notice.id} notice={notice} />)}</Container>
      </InfiniteScroll>
    </PullToRefresh>
  );
};

export default NoticeList;

const Container = styled.section<{ isEmpty?: boolean }>`
  padding: 20px;
  ${theme.flex('column', 'center', '', 12)}
  justify-content: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
  min-height: 400px;
  b {
    font-weight: 600;
    color: ${theme.color.primary};
  }
`;

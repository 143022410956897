import styled from '@emotion/styled';
import Divider from 'components/Divider';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';
import SearchBar from 'sharedComponents/SearchBar';

import NoticeList from './components/List';
import SkeletonList from './components/SkeletonList';
import { NOTICE_TEXT, STUDIOMATE_NOTICE_IDENTIFIER } from './constants';

const MoreDetailsSettingsNoticeList = () => {
  return (
    <MainLayout header={{ title: NOTICE_TEXT.title, noUnderline: true }} scrollable>
      <SearchWrapper>
        <SearchBar placeholder={NOTICE_TEXT.placeholder} identifier={STUDIOMATE_NOTICE_IDENTIFIER} />
        <Divider thin thinColor="gray6" />
      </SearchWrapper>
      <ApiBoundary fallback={<SkeletonList />}>
        <NoticeList />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsSettingsNoticeList;

const SearchWrapper = styled(StickyWrapper)`
  .search-bar {
    padding: 0 20px 12px;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import useInfinityReportPoint, { ReportPointParams } from 'hooks/service/queries/useInfinityReportPoint';
import { useRecoilValue } from 'recoil';
import { reportPointParamAtom, reportPointPeriodAtom } from 'recoil/report/atom';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import NoData from '../NoData';
import PointListCard from './PointListCard';
import ReportPointFilterOptions from './ReportPointFilterOptions';

const ReportPointList = () => {
  const reportPointPeriod = useRecoilValue(reportPointPeriodAtom);
  const reportPointParams = useRecoilValue(reportPointParamAtom);
  const params: ReportPointParams = {
    start_date: reportPointPeriod.period?.start || filters.dateDash(dayjs().startOf('month')),
    end_date: reportPointPeriod.period?.end || filters.dateDash(dayjs().endOf('month')),
    staff_ids: reportPointParams.staffs?.map(item => item.id.toString()),
    types: reportPointParams.type,
    status: reportPointParams.status,
    paginate_type: 'detail',
    order_by: reportPointParams.sort,
  };

  const {
    data: { points, total },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityReportPoint(params);

  return (
    <Container>
      <ReportPointFilterOptions total={total} />
      {total === 0 ? (
        <NoData noDataText="포인트 내역이 없습니다." />
      ) : (
        <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
          <PointListCard points={points} />
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default ReportPointList;

const Container = styled.div`
  background-color: ${theme.color.gray6};
`;

import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import MyAccount from './components/MyAccount';
import { ACCOUNT_TEXT } from './constants';

const MoreDetailsMyAccount = () => {
  return (
    <MainLayout header={{ title: ACCOUNT_TEXT.title }}>
      <ApiBoundary>
        <MyAccount />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsMyAccount;

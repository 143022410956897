import dayjs from 'dayjs';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { UserTicketProps } from 'pages/TicketDetail/types';
import filters from 'utils/filters';

import { formatValue, min999 } from './getUpgradeCurrentTicketInfo';

type Props = UserTicketProps & {
  upgradeTicket: TicketsResponse;
};

type Response = {
  label: string;
  value: string;
  diff?: string;
};

/** 수강권 업그레이드 > 업그레이드 수강권 정보 세팅 */
const getUpgradeTicketInfo = ({ userTicket, upgradeTicket }: Props): Array<Response> => {
  const {
    availability_start_at,
    expire_at,
    max_cancel,
    remaining_cancel,
    remaining_coupon,
    max_coupon,
    is_show_cancel_count,
    ticket_id: currentParentTicketId,
    ticket: { daily_booking_change_limit, type, available_period },
  } = userTicket;

  const {
    id: upgradeTicketId,
    available_period: upgradeAvailablePeriod,
    max_coupon: upgradeMaxCoupon,
    max_cancel: upgradeMaxCancel,
    is_show_cancel_count: upgradeShowCancelCount,
    daily_booking_change_limit: upgradeDailyBookingChangeLimit,
    type: upgradeTicketType,
  } = upgradeTicket;

  /** 같은 수강권으로 업그레이드(연장) 할 때 */
  const isExtension = upgradeTicketId === currentParentTicketId;
  /** 업그레이드할 수강권이 기간제일 때 */
  const isPeriodType = upgradeTicketType === 'P';
  /**
   * 취소 가능 횟수 노출 여부
   * - 횟수제(차감제): 노출
   * - 기간제: 수강권 생성 시, 취소 가능 횟수 노출 체크 했을 경우 노출
   */
  const isShowCurrentCancelCount = !(type === 'P' && !is_show_cancel_count);
  const isShowUpgradeCancelCount = !(isPeriodType && !upgradeShowCancelCount);

  /** 기간 */
  const periodDiff =
    !upgradeAvailablePeriod || !available_period
      ? 0
      : isExtension
        ? upgradeAvailablePeriod
        : upgradeAvailablePeriod - available_period;
  const expireAt = dayjs(expire_at).add(periodDiff, 'day');
  const period = filters.period(availability_start_at, expireAt);

  const getDiff = ({ currentValue, upgradeValue }: GetDiffProps) => {
    if (isExtension) {
      return min999(upgradeValue);
    } else {
      return min999(upgradeValue - currentValue);
    }
  };

  /** 잔여 횟수 */
  const remainingCouponDiff = getDiff({ currentValue: remaining_coupon, upgradeValue: upgradeMaxCoupon });
  const extendRemainingCoupon = min999(remaining_coupon + remainingCouponDiff);

  /** 전체 횟수 */
  const maxCouponDiff = getDiff({ currentValue: max_coupon, upgradeValue: upgradeMaxCoupon });
  const extendMaxCoupon = min999(max_coupon + maxCouponDiff);

  /** 예약 취소 가능 횟수 */
  const maxCancelDiff = getDiff({ currentValue: max_cancel, upgradeValue: upgradeMaxCancel });
  const extendRemainingCancel = min999(remaining_cancel + maxCancelDiff);

  /** 당일 예약 변경 횟수 */
  const dailyBookingChangeDiff = getDiff({
    currentValue: daily_booking_change_limit,
    upgradeValue: upgradeDailyBookingChangeLimit,
  });

  /** 예약 취소 가능 횟수, 당일 예약 변경 횟수는 없으면 '사용안함' 표시 */
  const remainingCancel = formatValue(isShowUpgradeCancelCount ? extendRemainingCancel : undefined);
  const dailyBookingChangeLimit = formatValue(upgradeDailyBookingChangeLimit);

  const array = [
    {
      label: '기간',
      value: period,
      diff: periodDiff ? `+${periodDiff}일` : undefined,
    },
    {
      label: '잔여횟수',
      value: `${extendRemainingCoupon}회`,
      diff: getDiffWithUnit({
        hasDiff: !isPeriodType,
        value: remainingCouponDiff,
      }),
    },
    {
      label: '전체횟수',
      value: `${extendMaxCoupon}회`,
      diff: getDiffWithUnit({
        hasDiff: !isPeriodType,
        value: maxCouponDiff,
      }),
    },
    {
      label: '예약취소 가능 횟수',
      value: remainingCancel,
      diff: getDiffWithUnit({
        hasDiff: isShowCurrentCancelCount && isShowUpgradeCancelCount,
        value: maxCancelDiff,
      }),
    },
    {
      label: '당일 예약 변경 가능 횟수',
      value: dailyBookingChangeLimit,
      diff: getDiffWithUnit({
        hasDiff: !isExtension,
        value: dailyBookingChangeDiff,
      }),
    },
  ];

  /** 기간제는 횟수 정보 제외(잔여횟수, 전체횟수) */
  if (type === 'P') {
    array.splice(1, 2);
  }

  return array;
};
export default getUpgradeTicketInfo;

type GetDiffProps = {
  isExtension?: boolean;
  currentValue: number;
  upgradeValue: number;
};

type GetDiffWithUnitProps = {
  hasDiff: boolean;
  value: number;
};

const getDiffWithUnit = ({ hasDiff, value }: GetDiffWithUnitProps) => {
  if (!hasDiff || !value) return;
  return `${value > 0 ? '+' : ''}${value}회`;
};

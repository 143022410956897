import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import CounselPeriodFilter from './CounselPeriodFilter';
import StaffFilter from './StaffFilter';

const CounselDrawerFilterOptions = ({ filters, filterChanged, changeOption, currentFilterTab }: FilterOptionsProps) => {
  const currentFilter = filters.find(filter => filter.key === currentFilterTab?.value);
  if (!currentFilterTab || !currentFilter) return null;
  return (
    <>
      {currentFilter.type === 'period' && (
        <CounselPeriodFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
      {currentFilter.type === 'button' && (
        <ButtonFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
      {currentFilter.type === 'select' && (
        <ApiBoundary>
          <StaffFilter
            currentFilter={currentFilter}
            selectedOption={get(filterChanged, currentFilter.key)}
            onClick={changeOption}
          />
        </ApiBoundary>
      )}
    </>
  );
};

export default CounselDrawerFilterOptions;

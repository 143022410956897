import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import CheckBox from 'components/CheckBox';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { HOLIDAY_SETTING_TEXT } from '../../constants';
import { HolidaySettingFormType } from '../../type';

const AutoDeductionEnabled = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { control } = useFormContext<HolidaySettingFormType>();

  return (
    <Container>
      <Controller
        control={control}
        name="notActionAutoBalance"
        render={({ field: { value: isChecked, ...field } }) => {
          return (
            <CheckBox
              id="notActionAutoBalance"
              variant="simple"
              label={HOLIDAY_SETTING_TEXT.autoDeductionEnabled.notEnabled}
              gap={4}
              labelFontsize={13}
              checked={isChecked}
              {...field}
            />
          );
        }}
      />

      <Tooltip
        isOpen={isTooltipOpen}
        showCloseButton
        onClose={() => setIsTooltipOpen(false)}
        message={HOLIDAY_SETTING_TEXT.autoDeductionEnabled.tooltipMessage}
        position="left">
        <IconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
          <Icon name="information" size={14} fillColor={theme.color.gray3} />
        </IconButton>
      </Tooltip>
    </Container>
  );
};

export default AutoDeductionEnabled;

const Container = styled.div`
  ${theme.flex('row', 'center', '', 4)};
  padding: 12px 0 24px;

  label > .typography {
    line-height: 1;
  }
`;

import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostReportVerify = () => {
  const { mutationFn } = useMutationFnWithCommonParams<ReportVerifyParams, ReportVerifyResponse>({
    method: `post`,
    url: `/api/report/verify`,
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostReportVerify;

export type ReportVerifyParams = {
  password: string;
};

export type ReportVerifyResponse = {
  result: boolean;
};

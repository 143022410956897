import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

import QnaCommentForm from './QnaCommentForm';

const SkeletonDetail = () => {
  return (
    <>
      <Row>
        <Skeleton height={20} width={120} />
        <Skeleton height={16} width={130} />
      </Row>
      <Divider thin />
      <Contents>
        <Skeleton height={20} width={270} />
        <Skeleton height={66} />
      </Contents>
      <Divider />
      <Comments>
        <Skeleton height={18} width={36} />
        <Skeleton height={208} />
        <Skeleton height={208} />
        <Skeleton height={208} />
      </Comments>
      <QnaCommentForm questionId={0} canComment={true} />
    </>
  );
};

export default SkeletonDetail;

const Row = styled.div`
  padding: 16px 20px;
  ${theme.flex('row', 'center', 'space-between')}
  div {
    border-radius: 3px;
  }
`;

const Contents = styled.div`
  padding: 20px 20px 40px;
  div {
    border-radius: 3px;
    :first-of-type {
      margin-bottom: 13px;
    }
  }
`;

const Comments = styled.div`
  padding: 22px 20px;
  div {
    border-radius: 3px;
    margin-bottom: 16px;
    :not(:first-of-type) {
      border-radius: 6px;
      margin-bottom: 18px;
    }
  }
`;

import PrivacyPolicyDetail from 'components/AgreeDetail/PrivacyPolicyDetail';
import TermOfServiceDetail from 'components/AgreeDetail/TermOfServiceDetail';
import usePopup from 'hooks/usePopup';

import { SETTINGS_TERM } from '../constants';
import PopupLayout from './PopupLayout';
import SettingItem from './SettingItem';

const SettingPopupTerms = () => {
  const { setPopup } = usePopup();
  return (
    <>
      <SettingItem
        label={SETTINGS_TERM.service}
        onClick={() =>
          setPopup(
            <PopupLayout title={SETTINGS_TERM.service}>
              <TermOfServiceDetail />
            </PopupLayout>,
          )
        }
      />
      <SettingItem
        label={SETTINGS_TERM.privacy}
        onClick={() =>
          setPopup(
            <PopupLayout title={SETTINGS_TERM.privacy}>
              <PrivacyPolicyDetail />
            </PopupLayout>,
          )
        }
      />
    </>
  );
};

export default SettingPopupTerms;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Checkmark from 'components/Checkmark';
import Divider from 'components/Divider';
import { BottomWrapper } from 'components/Form';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import usePopup from 'hooks/usePopup';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';

import { COMPLETE_POPUP_CONTENTS } from './constants';
import { CompletePopupContentType } from './type';

type Props = {
  type?: keyof typeof COMPLETE_POPUP_CONTENTS;
  /**
   * 하단 테이블 형태의 UI 데이터
   */
  tableData?: Array<{ title: string; content: string }>;
  /**
   * popup을 닫을 때 실행되는 함수. default는 login으로 가는 함수 실행
   */
  onClose?: () => void;
};

const CompletePopupContents = ({ type = 'join', tableData, onClose }: Props) => {
  const navigate = useNavigate();
  const content: CompletePopupContentType = COMPLETE_POPUP_CONTENTS[type];

  const { setPopup } = usePopup();

  const closeAction = () => {
    if (onClose) {
      onClose?.();
    } else {
      setPopup(null);
      navigate('/login', { replace: true });
    }
  };

  return (
    <MainLayout
      header={{
        leftButton: content.hasCloseButton ? (
          <IconButton onClick={closeAction}>
            <Icon name="headerClose" />
          </IconButton>
        ) : (
          ' '
        ),
      }}>
      <Container>
        <section className="complete-text">
          <Checkmark />

          <Typography size={21} weight={700}>
            {content.title}
          </Typography>

          <Typography tag="pre" size={15} textColor="gray2">
            {content.description}
          </Typography>
        </section>

        {tableData && (
          <section className="table-wrapper">
            <Divider />
            <ul>
              {tableData.map(({ title, content }) => (
                <li key={title}>
                  <Typography weight={400} textColor="gray2">
                    {title}
                  </Typography>
                  <Typography weight={500} textColor="gray2">
                    {content}
                  </Typography>
                </li>
              ))}
            </ul>
            <Divider />
          </section>
        )}

        <BottomWrapper>
          <Button type="submit" color="point" fontSize={15} fullWidth size="large56" variant="contained" onClick={closeAction}>
            {content.bottomButtonText}
          </Button>
        </BottomWrapper>
      </Container>
    </MainLayout>
  );
};

export default CompletePopupContents;

const Container = styled.section`
  ${theme.flex('column', 'center', 'center', 48)}
  height: 80%;

  .typography {
    text-align: center;
  }

  .complete-text {
    #lottie {
      margin-bottom: 12px !important;
    }

    h1 {
      margin-bottom: 8px;
    }
  }

  .table-wrapper {
    margin-bottom: 80px;
    padding: 0 32px;
    width: 100%;

    ul {
      ${theme.flex('column', 'center', 'center', 8)};
      margin: 16px 0;

      li {
        ${theme.flex('row', '', 'space-between')};
        width: 100%;
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background-color: ${theme.color.gray5};
    }
  }
`;

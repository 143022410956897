import { ButtonProps } from 'components/Button';
import useQueryString from 'hooks/useQueryString';
import { REPEAT_SETTING_CHECK_RADIO_DATA } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

type Props = Pick<ButtonProps, 'onClick'>;

/** 1개 수정에서만 반복설정 미노출 */
const RepeatField = ({ onClick }: Props) => {
  const { control } = useFormContext<BookingCommonFormType>();
  const repeat = useWatch({ control, name: 'repeat' });
  const repeatCustomWeekday = useWatch({ control, name: 'repeatCustomWeekday' });
  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });
  const isSameDate = startDate === endDate;

  const { setSearchParams, getAllSearchParams } = useQueryString();
  const goRepeatPage = () => {
    setSearchParams({
      ...getAllSearchParams(),
      type: 'repeat',
    });
  };

  const label = useMemo(() => {
    if (isSameDate) {
      return '반복 안함';
    }
    if (repeat === 'custom') {
      return repeatCustomWeekday.map(weekday => weekday.value).join(', ');
    }
    return REPEAT_SETTING_CHECK_RADIO_DATA.find(item => item.id === repeat)?.label || '반복 설정';
  }, [isSameDate, repeat, repeatCustomWeekday]);

  return (
    <FormFieldLinkButton
      iconName="repeat"
      disabled={isSameDate}
      showArrow={!isSameDate}
      hasValue={!!repeat}
      onClick={e => {
        goRepeatPage();
        onClick?.(e);
      }}>
      {label}
    </FormFieldLinkButton>
  );
};

export default RepeatField;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

const ValidationCompleted = () => {
  const { watch } = useFormContext<ChangePhoneNumberFormType>();
  const step = watch('step');

  if (step === 'confirm') {
    return (
      <Container>
        <Icon name="selectCheck" color="primary" size={16} />
        <Typography size={13} weight={500} textColor="primary">
          {CHANGE_PHONE_NUMBER_TEXT.confirmed}
        </Typography>
      </Container>
    );
  }
  return null;
};

export default ValidationCompleted;

const Container = styled.div`
  ${theme.flex('', 'center', '', 2)}
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { StaffSudamateCommentResponse } from 'hooks/service/queries/useInfinityStaffSudamateComment';
import filters from 'utils/filters';

type Props = {
  comment: StaffSudamateCommentResponse;
};

const Comment = ({ comment }: Props) => {
  const { contents, staff_account, updated_at, depth } = comment;
  return (
    <Container>
      <InfoSection>
        <Typography size={15} weight={600} lineHeight={22}>
          {staff_account.name}
        </Typography>
        <IconButton>
          <Icon name="more" color="gray3" />
        </IconButton>
      </InfoSection>
      <ContentSection>
        <Typography className="sudamate-comment" size={15} lineHeight={22}>
          {contents}
        </Typography>
        <div className="sudamate-comment-reply">
          <Typography size={13} lineHeight={16} textColor="gray3">
            {filters.dateTimeWithWeekday(updated_at)}
          </Typography>
          {depth !== 2 && (
            <Button onClick={() => console.log('')}>
              <Typography size={13} lineHeight={16} textColor="gray3">
                답글쓰기
              </Typography>
            </Button>
          )}
        </div>
      </ContentSection>
    </Container>
  );
};

export default Comment;

const Container = styled.div`
  padding: 16px 20px;
`;

const InfoSection = styled.section`
  ${theme.flex('row', 'center', 'space-between')}
  padding-bottom: 3px;
`;

const ContentSection = styled.section`
  .sudamate-comment {
    padding-bottom: 5px;

    &-reply {
      ${theme.flex('row', 'center', '', 8)}
    }
  }
`;

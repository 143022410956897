import styled from '@emotion/styled';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import CheckRadioTypography from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioTypography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { isObject } from 'lodash';
import { MemberCounselSelectedFilterType, MemberStaffFilterType } from 'pages/Member/types';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterProps } from 'sharedComponents/Filters/types';

type CounselStaffForm = {
  selectedStaffId: string;
};

const StaffFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const selectedStaffs = selectedOption as MemberCounselSelectedFilterType['staffs'];
  const filterOptions = currentFilter.options as { value: MemberStaffFilterType; label: string }[];
  const defaultStaffs = filterOptions.length > 1 ? 'all' : filterOptions[0].value?.id;
  const selectedStaffId = selectedStaffs?.id || defaultStaffs;

  const [isOpen, setIsOpen] = useState(false);

  const { control } = useForm<CounselStaffForm>({
    defaultValues: {
      selectedStaffId,
    },
  });

  const onClose = () => {
    setIsOpen(false);
  };

  const changeSelectStaff = (e: ChangeEvent<HTMLInputElement>) => {
    const targetStaffId = e.target.value;
    const targetStaffOption = filterOptions.find(option => option.value?.id === targetStaffId);

    changeOptions({ value: targetStaffOption?.value, label: targetStaffOption?.label || '전체' }, 'staffs');
  };

  const data = filterOptions.map(option => {
    const avatar = option.value?.avatar;
    const id = option.value?.id ?? 'all';
    const imgUrl =
      isObject(avatar) && 'path' in avatar && 'extension' in avatar && 'name' in avatar // ArrowFilterValuesType에서 AttachmentType 타입 전환
        ? { name: `${avatar.name}`, path: `${avatar.path}`, extension: `${avatar.extension}` }
        : null;
    return {
      id,
      value: id,
      label: !option.value ? (
        <CheckRadioTypography isSelected={selectedStaffId === 'all'}>전체</CheckRadioTypography>
      ) : (
        <AvatarLabel key={id} id={id} selectStaffId={selectedStaffId} imgUrl={imgUrl} label={option.label} showLabelAll />
      ),
    };
  });

  const selectButtonLabel = filterOptions.find(option => option.value?.id === selectedStaffId)?.label || '전체';

  return (
    <Container>
      <Typography size={15} weight={700}>
        담당스태프
      </Typography>
      <StaffSelectButton icon={<Icon name="arrowBottom" color="gray2" />} onClick={() => setIsOpen(true)}>
        <Typography textColor="gray1" size={15} weight={500}>
          {selectButtonLabel}
        </Typography>
      </StaffSelectButton>
      <CheckRadioDrawer
        header={<OneLinedTitle title="담당스태프 선택" />}
        isOpen={isOpen}
        onClose={onClose}
        control={control}
        name="selectedStaffId"
        data={data}
        onClick={changeSelectStaff}
      />
    </Container>
  );
};

export default StaffFilter;

const Container = styled.div`
  padding: 20px 20px 24px;

  .select-drawer {
    margin-top: 16px;
  }
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { MemberParams, MemberResponse } from './useInfinityMember';

const useInfinityMemberAssigned = (params: MemberAssignedParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MemberAssignedResponse, MemberAssignedParams>({
    url: `/api/member/assigned`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`member`, `assigned`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => ({
      members: data.pages.flatMap(page => page.data.data),
      total: data.pages[0].data.meta.total,
    }),
  });
};

export default useInfinityMemberAssigned;

type MemberAssignedParams = MemberParams;
type MemberAssignedResponse = MemberResponse;

import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingPrivateFormType } from '../../types';

const NextButton = () => {
  const { watch } = useFormContext<BookingPrivateFormType>();
  const watchedFields = watch([
    'userTicketIds',
    'lectureSize',
    'startDate',
    'endDate',
    'selectedStaffId',
    'endTime',
    'startTime',
    'title',
  ]);

  const isValid = watchedFields.every(field => (Array.isArray(field) ? !!field.length : !!field));
  return (
    <FooterButton type="submit" disabled={!isValid}>
      다음
    </FooterButton>
  );
};

export default NextButton;

import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SortFormType } from 'sharedComponents/MemoForm/types';
import SortButton from 'sharedComponents/SortButton';

import { MEMO_SORTING_DATA } from './constants';

const Sorting = () => {
  const { control } = useFormContext<SortFormType>();
  const sort = useWatch({ control, name: 'sort' });
  const showButton = useWatch({ control, name: 'showButton' });

  const [isOpen, setIsOpen] = useState(false);
  const label = MEMO_SORTING_DATA.find(({ value }) => value === sort)?.label;

  return (
    <>
      {showButton && <SortButton onClick={() => setIsOpen(true)}>{label}</SortButton>}
      <CheckRadioDrawer name="sort" control={control} data={MEMO_SORTING_DATA} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Sorting;

import Button from 'components/Button';
import Divider from 'components/Divider';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import { Dispatch, PropsWithChildren, SetStateAction, useMemo } from 'react';

import { DROPDOWN_DATA } from '../constants';
import { DropdownDataType } from '../types';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClick: (value: DropdownDataType['value']) => void;
};

const EventScheduleDropdown = ({ children, isOpen, setIsOpen, onClick }: PropsWithChildren<Props>) => {
  const dropdownContent = useMemo(() => {
    return (
      <ul>
        {DROPDOWN_DATA.map(({ value, icon, label }, index) => {
          return (
            <li key={index}>
              <Button
                size="medium48"
                fontSize={15}
                textColor={value === 'remove' ? 'secondary3' : 'gray2'}
                lineHeight={22}
                gap={32}
                heightSize={35}
                rightIcon={<Icon size={16} {...icon} />}
                padding={{ left: 16, right: 12 }}
                onClick={() => onClick(value)}>
                {label}
              </Button>
              {index === 0 && <Divider thin />}
            </li>
          );
        })}
      </ul>
    );
  }, [onClick]);

  return (
    <DropDown
      className="modify-dropdown"
      isOpen={isOpen}
      content={dropdownContent}
      position="right"
      bottomSpacing={1}
      onClose={() => setIsOpen(false)}>
      {children}
    </DropDown>
  );
};

export default EventScheduleDropdown;

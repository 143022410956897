import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';
import { Payment, TicketCommon } from './useInfinityUserTicketProduct';

const useInfinityPayment = (params: PaymentParams) => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<PaymentResponse, PaymentParams>({
    url: `/api/payment`,
    params: {
      ...params,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['payment', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        data: data.pages.flatMap(page => page.data.data),
        total_credit: data.pages[0].data.total_credit,
        unpaid_credit: data.pages[0].data.unpaid_credit,
      };
    },
  });
};

export default useInfinityPayment;

export type PaymentParams = {
  user_id: number;
  start_date: string;
  end_date: string;
};

export type PaymentUserTicket = {
  id: number;
  ticket: Pick<TicketCommon, 'title' | 'available_class_type' | 'actual_price'> & {
    id: number;
  };
};

export type PaymentResponse = {
  data: Array<
    Payment & {
      user_ticket: PaymentUserTicket;
    }
  >;
  total_credit: number;
  unpaid_credit: number;
};

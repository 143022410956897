import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

import { GENDER_DATA } from '../constants';
import { CommonProfileFormType } from '../types';

const Gender = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<CommonProfileFormType>();
  const gender = useWatch({ control, name: 'profile.gender' });

  const label = useMemo(() => {
    return GENDER_DATA.find(item => item.value === gender)?.label;
  }, [gender]);

  return (
    <>
      <FormFieldLinkButton iconName="gender" hasValue={!!gender} onClick={() => setIsOpen(true)}>
        {label}
      </FormFieldLinkButton>

      <CheckRadioDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header="성별 선택"
        name="profile.gender"
        control={control}
        data={GENDER_DATA}
      />
    </>
  );
};

export default Gender;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { PERMISSION } from 'constants/permission';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import { BookingStatusType, BookingType } from 'pages/Booking/types';
import formatChangeStatusList from 'pages/Booking/utils/detail/formatChangeStatusList';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';

type Props = {
  isMe: boolean;
  bookingType: BookingType;
  isOpen: boolean;
  onClose: () => void;
  booking: LectureBookingListResponse;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
  onClick: (value: BookingStatusType['value']) => void;
};

const ChangeBookingStatusDrawer = ({
  isMe,
  bookingType,
  isOpen,
  booking,
  canCancelBooking,
  canChangeBooking,
  onClick,
  onClose,
}: Props) => {
  const { pathname } = useLocation();

  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const { checkPermission } = useGetPermissionDoHavePermission();

  const buttonArray = formatChangeStatusList({ booking, canCancelBooking, canChangeBooking });

  const staffType = isMe ? 'mine' : 'others';
  const clickStatus = (value: BookingStatusType['value']) => {
    checkPermission(PERMISSION.lecture[staffType].cancelBookings[bookingType].id, {
      onSuccess: () => {
        onClick(value);
        onClose();
      },
      onError: () => {
        if (isMe) {
          setErrorBackStep({
            to: pathname,
            options: { replace: true },
          });
        } else {
          checkPermission(PERMISSION.lecture[staffType].cancelBookings[bookingType].id, {
            onSuccess: () => {
              setErrorBackStep({
                to: '/schedule',
                options: { replace: true },
              });
            },
          });
        }
      },
    });
  };

  return (
    <StyledDrawer isOpen={isOpen} onClose={onClose} header={<OneLinedTitle title={booking?.member.name} />}>
      {buttonArray.map(({ label, value }) => {
        return (
          <Button
            key={value}
            fullWidth
            size="medium40"
            disabled={value === 'cancel' && !canCancelBooking}
            fontSize={17}
            fontWeight={400}
            onClick={() => clickStatus(value)}>
            {label}
          </Button>
        );
      })}
    </StyledDrawer>
  );
};

export default ChangeBookingStatusDrawer;

const StyledDrawer = styled(Drawer)`
  ${theme.flex('column', 'center', 'flex-start', 8)};
  border-top: none;

  .drawer-header-observer-target {
    display: none;
  }

  button {
    justify-content: flex-start;
  }
`;

import styled from '@emotion/styled';
import ImageNoPoint from 'assets/images/no_point.webp';
import { theme } from 'assets/styles';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { useMemo } from 'react';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';
import filters from 'utils/filters';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentPoint: string | number;
  deductPoint: number;
};

/** 메세지 전송 시도 후 포인트 부족알림 Drawer */
const NoPointDrawer = ({ isOpen, onClose, currentPoint, deductPoint }: Props) => {
  const data: InfoValueRowType[] = useMemo(
    () => [
      { label: '보유 포인트 ', value: `${filters.numberComma(currentPoint)}P`, textColor: 'secondary3' },
      { label: '필요 포인트 ', value: `${filters.numberComma(deductPoint)}P` },
    ],
    [deductPoint, currentPoint],
  );

  return (
    <SingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<TitleWithSubText title="포인트가 부족합니다." subTitle="웹 페이지 접속 후 포인트를 충전해 주세요." />}
      button={{
        text: '확인',
        onClick: onClose,
      }}>
      <Image src={ImageNoPoint} alt={'포인트가 부족합니다.'} />
      <ContentsWrapper>
        {data.map(({ label, value, textColor }, index) => (
          <InfoFlexRow key={`${index}-${value}`} gap={8}>
            <InfoTypography size={15}>{label}</InfoTypography>
            <InfoTypography size={15} weight={500} textColor={textColor || 'gray2'}>
              {value}
            </InfoTypography>
          </InfoFlexRow>
        ))}
      </ContentsWrapper>
    </SingleButtonDrawer>
  );
};

export default NoPointDrawer;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const ContentsWrapper = styled(InfoFlexWrapper)`
  background-color: ${theme.color.gray7};
  border-radius: 12px;
  padding: 18px 20px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { isEmpty } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import sessionStorage from 'utils/sessionStorage';

import { bottomNavs } from '../constants';

const BottomNavigation = () => {
  const selectedStudioId = useRecoilValue(studioIdAtom);

  const location = useLocation();

  /** 탭 변경될 때마다  */
  const resetScrollPositions = () => {
    const scrollPositions = sessionStorage.get('scrollPositions');
    if (!isEmpty(scrollPositions)) {
      sessionStorage.reset();
    }
  };

  return (
    <Container>
      {bottomNavs.map(({ title, route, isSelected, iconName, IconComponent }) => {
        const isActive = isSelected.some(url => location.pathname.includes(url));
        const iconColor = isActive ? 'point' : 'gray4';

        return (
          <StyledLink to={route} key={title} onClick={resetScrollPositions}>
            {selectedStudioId ? (
              <ApiBoundary fallback={<Icon size={24} name={iconName} color={iconColor} />} key={selectedStudioId}>
                <IconComponent size={24} name={iconName} color={iconColor} key={selectedStudioId} />
              </ApiBoundary>
            ) : (
              <Icon size={24} name={iconName} color={iconColor} />
            )}
            <Typography size={12} weight={500} span textColor={isActive ? 'gray1' : 'gray3'}>
              {title}
            </Typography>
          </StyledLink>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  ${theme.flex('row', '', 'space-between', 8)};
  width: 100%;
  padding: 0 8px 4px;
  background-color: ${theme.color.white};
  box-shadow: 0px -2px 3px rgba(145, 145, 145, 0.06);
`;

const StyledLink = styled(Link)`
  ${theme.flex('column', 'center', 'center', 2)};
  width: 65px;
  padding: 8px 0 6px;
  text-align: center;

  span {
    line-height: 16px;
  }
`;

export default BottomNavigation;

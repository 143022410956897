import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Z_INDEX } from 'constants/zIndex';
import React, { useEffect, useRef } from 'react';

interface DropDownPropTypes {
  /**
   * 드롭다운 보여줄 대상 (타겟)
   */
  children: React.ReactNode;
  /**
   * 드롭다운 내부 콘텐츠
   */
  content: React.ReactNode;
  /**
   * 드롭다운 노출 여부
   */
  isOpen: boolean;
  /**
   * 드롭다운 위치 (타겟 기준)
   */
  position?: 'left' | 'center' | 'right';
  /**
   * 드롭다운과 타겟 사이의 거리
   */
  bottomSpacing?: number;
  /**
   * 드롭다운 닫기 로직
   */
  onClose?: () => void;
  className?: string;
}

const DropDown = ({ children, content, onClose, ...props }: DropDownPropTypes) => {
  const { isOpen } = props;

  const ref = useRef<HTMLDivElement>(null);

  /** 드롭다운 외부를 클릭했을 시 드롭다운 닫기 */
  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        e.stopPropagation();
        onClose?.();
      }
    };

    // dialog나 drawer 등 다른 root에 영향을 주지 않도록 document가 아닌 root에 적용
    const root = document.getElementById('root');

    root?.addEventListener('click', clickOutside, true);
    return () => root?.removeEventListener('click', clickOutside, true);
  }, [isOpen, onClose]);

  /** 드롭다운 열려 있을 때, 페이지 스크롤하면 자동 닫힘 */
  useEffect(() => {
    const scrollableTarget = document.getElementById('scrollableTarget');
    if (!isOpen || !scrollableTarget) return;

    const handleScroll = () => {
      onClose?.();
    };
    scrollableTarget.addEventListener('scroll', handleScroll);
    return () => scrollableTarget.removeEventListener('scroll', handleScroll);
  }, [isOpen, onClose]);

  return (
    <Container ref={ref} {...props}>
      <span className="dropdown-target">{children}</span>
      <section className="dropdown-contents-container">{content}</section>
    </Container>
  );
};

export default DropDown;

const Container = styled.div<Pick<DropDownPropTypes, 'isOpen' | 'position' | 'bottomSpacing'>>(
  ({ isOpen, bottomSpacing }) => css`
    position: relative;
    width: max-content;
    z-index: ${Z_INDEX.dropdown};

    .dropdown-contents-container {
      position: absolute;
      display: ${isOpen ? 'block' : 'none'};
      margin-top: ${bottomSpacing || 8}px;
      width: max-content;

      background-color: white;
      box-shadow: 1px 2px 8px rgba(145, 145, 145, 0.2);
      border-radius: 12px;
    }
  `,

  ({ position }) => {
    switch (position) {
      case 'left':
        return css`
          .dropdown-contents-container {
            left: 0;
          }
        `;
      case 'right':
        return css`
          .dropdown-contents-container {
            right: 0;
          }
        `;
      default:
        return css`
          .dropdown-contents-container {
            left: 50%;
            transform: translate(-50%);
          }
        `;
    }
  },
);

import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';
import formatSmallUserTicketCard, { getCurrentUserTicketInfo } from 'utils/formatSmallUserTicketCard';
import getDaysDiff from 'utils/getDaysDiff';
import getTicketInfoByType from 'utils/getTicketInfoByType';

/** 양도 단계에서 두번째 수강권 정보 입력 페이지에서 접힌 회색 카드에 보일 정보 세팅 */
const formatSmallTransferCard = (userTicket: UserTicketResponse): FormatTicketCard => {
  const { bottomOptions, ...rest } = formatSmallUserTicketCard(userTicket);
  const { dailyBookingChangeLimit, maxCoupon } = getCurrentUserTicketInfo(userTicket);
  const { expire_at, availability_start_at, ticket } = userTicket;
  const { showPeriod } = getTicketInfoByType(ticket.type);

  const totalDays = showPeriod ? `${getDaysDiff(expire_at, availability_start_at) + 1}일` : null;

  return {
    ...rest,
    bottomOptions: [totalDays, maxCoupon, dailyBookingChangeLimit],
  };
};

export default formatSmallTransferCard;

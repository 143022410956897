import { UserTicketProps } from 'pages/TicketDetail/types';
import filters from 'utils/filters';

type Props = UserTicketProps & {
  step: number;
  finalData: {
    availability_start_at: string;
    expire_at: string;
    max_coupon: number;
    remaining_cancel: number;
    remaining_coupon: number;
  };
};

const getTransferUserTicketDetail = ({ userTicket, step, finalData }: Props) => {
  const {
    is_show_cancel_count,
    ticket: { type, daily_booking_change_limit },
    max_coupon,
    remaining_cancel,
    remaining_coupon,
    availability_start_at,
    expire_at,
  } = userTicket;

  const defaultData = {
    max_coupon,
    remaining_coupon,
    remaining_cancel,
    availability_start_at,
    expire_at,
  };

  const data = step === 4 ? finalData : defaultData;
  const ticketPeriod = filters.period(data.availability_start_at, data.expire_at);
  const dailyBookingChangeLimit = !daily_booking_change_limit ? '사용안함' : `${daily_booking_change_limit}회`;

  const defaultArray = [
    { label: '기간', value: ticketPeriod },
    { label: '잔여횟수', value: `${data.remaining_coupon}회` },
    { label: '전체횟수', value: `${data.max_coupon}회` },
    { label: '예약취소 가능 횟수', value: `${data.remaining_cancel}회` },
    { label: '당일 예약 변경 가능 횟수', value: dailyBookingChangeLimit },
  ];
  const array = [...defaultArray];

  /** 상품(대여 - 혼합) */
  if (type === 'RM') {
    array.splice(3, 2);
    return array;
  }
  /** 상품(대여 - 횟수제) */
  if (type === 'RT') {
    array.splice(3, 2);
    array.splice(0, 1);
    return array;
  }
  /** 상품(대여 - 기간제) */
  if (type === 'RP') {
    return array.splice(0, 1);
  }
  /** 수강권(기간제) */
  if (type === 'P') {
    /** 취소 가능 횟수 노출 여부 */
    if (!is_show_cancel_count) {
      array.splice(1, 3);
    } else {
      array.splice(1, 2);
    }
    return array;
  }

  /** 수강권(횟수제) - 전부 노출 */
  return defaultArray;
};
export default getTransferUserTicketDetail;

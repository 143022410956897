import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import InputSelectBase, { IInputSelectBaseProps, StyledLabel } from 'components/InputSelectBase';
import Typography from 'components/Typography';
import React, { ReactNode, useMemo } from 'react';

export interface ISelectBoxProps extends IInputSelectBaseProps {
  /** 선택되는 요소의 텍스트 */
  label?: string | ReactNode;
  /** 선택되는 요소의 텍스트 옆 컬러칩 색상 */
  colorChip?: string;
  /** 하단 라벨이 있을 경우 */
  isBottomLabel?: boolean;
  /** circle selectbox outline 있을 경우 */
  isOutline?: boolean;
  /** circle selectbox content가 text인지 icon인지 판단 */
  isContentIcon?: boolean;
}

const SelectBoxSquare = React.forwardRef<HTMLInputElement, ISelectBoxProps>(({ colorChip, ...props }, ref) => {
  const { id, label, checked, disabled } = props;

  const labelText = useMemo(
    () => (
      <Typography
        className="label-text"
        span
        size={14}
        textColor={checked ? 'primary' : 'gray1'}
        weight={checked ? 700 : 400}
        lineHeight={18}>
        {label}
      </Typography>
    ),
    [label, checked],
  );

  const mainContents = useMemo(() => {
    if (!colorChip) return labelText;
    return (
      <span className="select-box-color-chip-wrapper">
        <Icon name="colorChips" size={16} fillColor={colorChip} />
        {labelText}
      </span>
    );
  }, [colorChip, labelText]);

  return (
    <Container htmlFor={id} colorChip={colorChip} disable={disabled}>
      <InputSelectBase ref={ref} {...props} />
      {mainContents}
    </Container>
  );
});

export const StyledSelectBoxCommon = styled(StyledLabel)<{ disable?: boolean }>`
  .input-select-base-input-wrapper {
    width: 100%;
    height: 100%;
    background-color: ${props => (props.disable ? theme.color.gray8 : 'none')};

    .input-span-check-style {
      display: none;
    }

    input {
      & + .input-span-style {
        width: 100%;
        height: 100%;
      }

      &:checked + .input-span-style {
        border-color: ${theme.color.primary};

        &:after {
          background-color: ${theme.color.primary};
        }
      }

      &:disabled + .input-span-style {
        border-color: ${theme.color.gray5};

        &:after {
          background-color: ${theme.color.gray5};
        }
      }
    }

    & + .typography {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 22;
    }
  }
`;

const Container = styled(StyledSelectBoxCommon)<Pick<ISelectBoxProps, 'colorChip' | 'disabled'>>(
  /** square 기본 스타일 */
  css`
    width: 100%;
    height: 48px;

    input + .input-span-style {
      border-radius: 10px;
    }
  `,

  /** 텍스트 왼편 colorChip 있을 때 */
  ({ colorChip }) =>
    colorChip &&
    css`
      .select-box-color-chip-wrapper {
        ${theme.flex('', 'center', 'center', 2)};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 22;
      }
    `,
);

SelectBoxSquare.displayName = 'SelectBoxSquare';
export default SelectBoxSquare;

import styled from '@emotion/styled';
import LinkButton from 'components/Button/LinkButton';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';
import SubTitle from 'components/SubTitle';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { postMessageToApp } from 'utils/communicateWithNative';

import NoticeSummary from './components/NoticeSummary';
import { HELP_BASIC_TEXT, HELP_NAVIGATIONS } from './constants';

const MoreDetailsSettingsHelp = () => {
  const navigate = useNavigate();

  const navigateExceptEmail = (name: string, path: string) => {
    if (path.startsWith('mailto')) {
      postMessageToApp('OPEN_URL', path);
      return;
    }
    navigate(path);
  };

  return (
    <MainLayout header={{ title: HELP_BASIC_TEXT.title }}>
      <TitleWrapper>
        <SubTitle title={HELP_BASIC_TEXT.subTitles} bottom={24} />
        <ApiBoundary fallback={<StyledSkeleton height={54} type="round" />}>
          <NoticeSummary />
        </ApiBoundary>
      </TitleWrapper>
      <Divider />
      {HELP_NAVIGATIONS.map((menu, index) => (
        <>
          <LinkButton
            key={menu.name}
            onClick={() => navigateExceptEmail(menu.name, menu.path)}
            size="large60"
            showArrow={false}
            showBorder={false}
            fontSize={15}
            fontWeight={600}>
            {menu.name}
          </LinkButton>
          {index !== HELP_NAVIGATIONS.length - 1 && <Divider thin />}
        </>
      ))}
    </MainLayout>
  );
};

export default MoreDetailsSettingsHelp;

const TitleWrapper = styled.div`
  height: 185px;
  padding: 16px 0 24px;
  .sub-title {
    padding: 0 20px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  margin: 0 20px;
  width: calc(100% - 40px);
`;

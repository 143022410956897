import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

type Props = {
  showWaitingCount: boolean;
  currentBookingTraineeCount: number;
  maxTrainee: number;
  currentWaitingTraineeCount: number;
  waitingTraineeLimit: number | null;
};

const TraineeCountInfo = ({
  showWaitingCount,
  currentBookingTraineeCount,
  currentWaitingTraineeCount,
  waitingTraineeLimit,
  maxTrainee,
}: Props) => {
  return (
    <Container>
      <div className="wrapper">
        <Typography size={13} textColor="gray2">
          예약인원
        </Typography>
        <Typography size={13} weight={500} textColor="gray3">
          <Typography span size={13} weight={500} textColor="gray2">
            {currentBookingTraineeCount}
          </Typography>
          /{maxTrainee}
        </Typography>
      </div>
      {showWaitingCount && (
        <div className="wrapper">
          <Typography size={13} textColor="gray2">
            대기인원
          </Typography>
          <Typography size={13} weight={500} textColor="gray3">
            {waitingTraineeLimit ? (
              <>
                <Typography span size={13} weight={500} textColor="gray2">
                  {currentWaitingTraineeCount}
                </Typography>
                {`/${waitingTraineeLimit}`}
              </>
            ) : (
              '제한없음'
            )}
          </Typography>
        </div>
      )}
    </Container>
  );
};

export default TraineeCountInfo;

const Container = styled.div`
  ${theme.flex('column', 'flex-start', '', 6)};

  .wrapper {
    ${theme.flex('', 'center', '', 8)};
  }
`;

import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Icon from 'components/Icon';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { FUNNEL_DATA } from 'pages/CounselDetail/constants';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckRadioFilterContents from 'sharedComponents/CheckRadioFilterContents';

import { CounselingFormType } from '../type';

const Funnel = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<CounselingFormType>();
  const funnel = useWatch({ control, name: 'funnel' });

  const label = useMemo(() => {
    const result = FUNNEL_DATA.find(item => item.value === funnel);
    return result ? result.label : '-';
  }, [funnel]);

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LinkButton
        gap={10}
        arrowBold
        arrowSize={16}
        leftIcon={<Icon name="inflow" fillColor={theme.color.gray2} />}
        onClick={() => setIsOpen(true)}
        fontWeight={500}>
        {label}
      </LinkButton>

      <Drawer header={<OneLinedTitle title="유입경로" />} isOpen={isOpen} onClose={closeDrawer}>
        <CheckRadioFilterContents name="funnel" control={control} data={FUNNEL_DATA} onClick={closeDrawer} />
      </Drawer>
    </>
  );
};

export default Funnel;

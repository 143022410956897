import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeletePaymentUnpaid from 'hooks/service/mutations/useDeletePaymentUnpaid';
import useToast from 'hooks/useToast';
import { useSetRecoilState } from 'recoil';
import { selectedPaymentAtom } from 'recoil/TicketDetail';

type Props = {
  onClose: () => void;
  paymentId: number;
};

const UnPaidDeleteDialog = ({ onClose, paymentId }: Props) => {
  const { setToast } = useToast();
  const setSelectedPayment = useSetRecoilState(selectedPaymentAtom);

  const { mutate: deleteUnPaidPaymentMutate } = useDeletePaymentUnpaid(paymentId);

  const deleteUnPaidItem = () => {
    deleteUnPaidPaymentMutate(undefined, {
      onSuccess: () => {
        setToast({ type: 'success', message: '결제정보가 삭제되었습니다' });
        setSelectedPayment(null);
      },
    });
  };

  return (
    <Dialog onClose={onClose} negativeAction={{ text: '취소' }} positiveAction={{ text: '삭제', onClick: deleteUnPaidItem }}>
      <DialogDescription>미수금 결제 정보를 삭제하시겠습니까?</DialogDescription>
    </Dialog>
  );
};

export default UnPaidDeleteDialog;

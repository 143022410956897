import Icon from 'components/Icon';
import Switch from 'components/Switch';
import { Controller, useFormContext } from 'react-hook-form';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';

const Vaccination = () => {
  const { control } = useFormContext<MemberFormType>();

  return (
    <Controller
      name="vaccination_yn"
      control={control}
      render={({ field: { value, ...field } }) => (
        <Switch
          id="vaccination_yn"
          label={MEMBER_FORM_TEXT.vaccination.label}
          switchSize="small"
          gap={10}
          leftIcon={<Icon name="vaccinateRegular" color="gray2" />}
          checked={value}
          {...field}
        />
      )}
    />
  );
};

export default Vaccination;

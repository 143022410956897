import useScrollRestoration from 'hooks/useScrollRestoration';
import { useLocation } from 'react-router-dom';
import SortKeyword from 'sharedComponents/Board/SortKeyword';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';

import { NOTICE_IDENTIFIER, NOTICE_SORT_IDENTIFIER } from '../constants';
import CreateButton from './components/CreateButton';
import NoticeList from './components/NoticeList';
import SkeletonList from './components/SkeletonList';
import { NOTICE_LIST_TEXT } from './constants';

// 시설의 공지사항 페이지
const MoreDetailsNoticeList = () => {
  useScrollRestoration();

  const { state } = useLocation();
  const permissionDenied: boolean = state?.permissionDenied ?? false;

  return (
    <MainLayout
      header={{
        title: NOTICE_LIST_TEXT.title,
        rightButton: <HeaderSearchButton identifier={NOTICE_IDENTIFIER} />,
        bgColor: 'white',
        noUnderline: true,
      }}
      backgroundColor="gray6">
      <SortKeyword identifier={NOTICE_SORT_IDENTIFIER} />
      <ApiBoundary fallback={<SkeletonList />}>
        <NoticeList permissionDenied={permissionDenied} />
      </ApiBoundary>
      <ApiBoundary>
        <CreateButton />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsNoticeList;

import styled from '@emotion/styled';
import Form from 'components/Form';

export const TicketFormContainer = styled(Form)`
  width: 100%;
  padding-bottom: 160px;

  .ticket-card {
    padding: 13px 20px;
  }
`;

export const StyledPaymentFormContainer = styled.div`
  width: 100%;
  min-height: 100%;
  padding-bottom: 160px;
`;

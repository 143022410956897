import { Payment } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { atom } from 'recoil';
import { PeriodFilterType } from 'sharedComponents/Filters/types';

export const selectedUserTicketHistoryAtom = atom<PeriodFilterType>({
  key: 'selectedUserTicketHistoryAtom',
  default: {},
});

export const selectedPaymentAtom = atom<Payment | null>({
  key: 'selectedPaymentAtom',
  default: null,
});

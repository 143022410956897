import { useSuspenseQuery } from '@tanstack/react-query';
import { MemberGradeType } from 'pages/Member/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 시설에 설정된 회원 등급 목록 조회 */
const useGetMemberGrade = (enabled = true) => {
  const { queryFn } = useQueryFnWithCommonParams<MemberGradeResponse>({
    url: `/api/member/grade`,
  });

  return useSuspenseQuery({
    queryKey: ['member-grade', enabled],
    queryFn: () => (enabled ? queryFn() : null),
    select: data => data?.data,
  });
};

export default useGetMemberGrade;

export type MemberGradeResponse = {
  is_use_user_grade: boolean; // 시설의 policy 회원등급설정 on/off
  grades: Array<MemberGradeType>;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';

import { CONTENT_TEXT } from '../constants';

type Props = {
  title: string;
  sub?: string;
  showRoleButton?: boolean;
  isStudioOwner?: boolean;
  isPermissionTitle?: boolean;
  clickRoleManagement?: () => void;
};

const ContentTitle = ({ title, sub, showRoleButton, isStudioOwner, isPermissionTitle = false, clickRoleManagement }: Props) => {
  return (
    <Container isPermissionTitle={isPermissionTitle}>
      <div>
        <Typography className="title" size={17} weight={600}>
          {title}
        </Typography>
        {sub ? (
          <Typography textColor="gray2">{sub}</Typography>
        ) : (
          isStudioOwner && (
            <Typography className="sub-title" textColor="secondary3">
              {CONTENT_TEXT.AccessItem.sub}
            </Typography>
          )
        )}
      </div>
      {showRoleButton && (
        <Button
          variant="contained"
          borderRadius={50}
          size="small"
          color="gray"
          padding={{ left: 12, right: 12 }}
          onClick={clickRoleManagement}>
          역할관리
        </Button>
      )}
    </Container>
  );
};

export default ContentTitle;

const Container = styled.div<{ isPermissionTitle: boolean }>`
  ${theme.flex('row', 'center', 'space-between')};
  margin-bottom: ${({ isPermissionTitle }) => (isPermissionTitle ? '16px' : '24px')};
  padding: ${({ isPermissionTitle }) => (isPermissionTitle ? '0 20px' : '0')};

  .sub-title {
    margin-top: 6px;
  }
`;

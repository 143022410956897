import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import Drawer from 'designedComponents/Drawers';
import CheckRadioFilterContents from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioFilterContents';
import { StaffFieldFormType } from 'pages/MoreDetails/Staff/List/types';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  sortData: Array<{
    id: string;
    label: string;
    value: string;
  }>;
  total: number;
  type?: 'normal' | 'event';
  onChange: (e: { target: { value: string } }) => void;
  sort: ('asc' | 'desc') | ('nameAsc' | 'nameDesc' | 'dateAsc' | 'dateDesc');
};

const ListContentHeader = ({ sortData, total, type = 'normal', onChange, sort }: Props) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const { control } = useFormContext<StaffFieldFormType>();

  const isEventTime = type === 'event';

  const totalUnit = isEventTime ? '건' : '명';

  const sortLabel = useMemo(() => {
    return sortData.filter(data => sort === data.value)[0].label;
  }, [sort, sortData]);

  const onClose = () => {
    setIsFilterOpen(false);
  };

  return (
    <Container className="list-content-header" isEventTime={isEventTime}>
      <div className="total-text-wrapper">
        <Typography size={13} textColor="gray2" opacity={0.8}>
          총
        </Typography>
        <Typography className="total-count" size={13} weight={600} textColor="primary">
          {total}
        </Typography>
        <Typography size={13} textColor="gray2" opacity={0.8}>
          {totalUnit}
        </Typography>
      </div>

      <div>
        <Button
          fontWeight={400}
          textColor="gray2"
          rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
          onClick={() => setIsFilterOpen(true)}>
          {sortLabel}
        </Button>

        <Drawer isOpen={isFilterOpen} onClose={onClose}>
          <CheckRadioFilterContents name="sort" data={sortData} control={control} onClick={onChange} onClose={onClose} />
        </Drawer>
      </div>
    </Container>
  );
};

export default ListContentHeader;

const Container = styled.section<{ isEventTime: boolean }>`
  ${theme.flex('row', '', 'space-between')};
  padding: 24px 20px 16px;
  border-bottom: 1px solid ${theme.color.gray6};
  z-index: ${Z_INDEX.dropdown + 1}; // 내부에 드롭다운 콘텐츠가 있어서 커스텀

  ${({ isEventTime }) => {
    return (
      isEventTime &&
      css`
        position: sticky;
        top: 0;
        padding: 12px 20px;
        background-color: ${theme.color.white};
      `
    );
  }}

  .total-count {
    margin-left: 4px;
  }

  .text-button {
    width: 100%;
  }

  .total-text-wrapper {
    ${theme.flex()};
  }
`;

import { useInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityMessagePush = (params: MessagePushParams, enabled?: boolean) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MessagePushResponse, MessagePushParams>({
    url: `/api/message/push`,
    params,
  });

  return useInfiniteQuery({
    queryKey: [`message/push`, params],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
    enabled,
  });
};

export default useInfinityMessagePush;

export type MessagePushParams = {
  start_date: string;
  end_date: string;
  search?: string;
  push_status?: 'scheduled' | 'sended' | 'canceled';
};

export type MessagePushResponse = {
  id: number;
  studio_id: number;
  title: string;
  status: 'scheduled' | 'sended' | 'canceled';
  all_count: number;
  success_count: number;
  failed_count: number;
  ready_count: number;
  send_on: string;
  send_person: string; // "홍길동" or "홍길동 외"
  created_at: string;
  updated_at: string;
};

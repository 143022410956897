import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useGetMessageMobileMainContact from 'hooks/service/queries/useGetMessageMobileMainContact';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import filters from 'utils/filters';

import { MobileFormType } from '../types';

const MobileSenderNumber = () => {
  const { setValue, getValues } = useFormContext<MobileFormType>();
  const { data } = useGetMessageMobileMainContact();
  const hyphenatedSender = filters.contact(data.contact) ?? '';

  // 발신번호가 없으면 발신번호를 채워줘야 함
  const isEmptySender = !getValues('from');
  useEffect(() => {
    if (hyphenatedSender && isEmptySender) {
      setValue('from', hyphenatedSender);
    }
  }, [setValue, hyphenatedSender, isEmptySender]);

  return (
    <SenderNumber size={15} weight={500} textColor="gray3">
      {hyphenatedSender}
    </SenderNumber>
  );
};

export default MobileSenderNumber;

const SenderNumber = styled(Typography)`
  display: block;
  width: 100%;
  border-radius: 10px;
  padding: 13px 16px;
  background-color: ${theme.color.gray6};
`;

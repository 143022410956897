import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { useFormContext } from 'react-hook-form';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

type Props = {
  onResendClick: () => void;
  resendDisabled: boolean;
};

const ValidationCodeInput = ({ onResendClick, resendDisabled }: Props) => {
  const { control, watch, setValue } = useFormContext<ChangePhoneNumberFormType>();
  const step = watch('step');

  if (step === 'number') return null;
  return (
    <OutlinedTextFieldController
      inputMode="numeric"
      controllerProps={{ control, name: 'mobileConfirmCode' }}
      suffixMarginRight={8}
      suffix={
        <StyledButton
          className="resend-button"
          color="gray"
          fontSize={13}
          fontWeight={500}
          textColor="gray2"
          size="small"
          variant="contained"
          onClick={onResendClick}
          padding={{ left: 8, right: 8 }}
          disabled={resendDisabled}>
          {CHANGE_PHONE_NUMBER_TEXT.mobileConfirmResend}
        </StyledButton>
      }
      onChange={() => {
        if (step === 'confirm') {
          setValue('step', 'validationCode');
          setValue('validationId', '');
        }
      }}
    />
  );
};

export default ValidationCodeInput;

const StyledButton = styled(Button)`
  width: 98px;
  background-color: ${theme.color.gray6};
`;

import dayjs from 'dayjs';

import filters from './filters';

/**
 * 이용 가능 기간으로 수강권 시작일에 따른 종료일 계산
 * 예) 시작일(startAt): 2024년 1월 1일, availablePeriod: 30일 때
 *    ==> 종료일(expireAt): 2024년 1월 30일
 * */
const getExpiredAtByAvailablePeriod = (availablePeriod: number | null, startAt?: string) => {
  if (!availablePeriod) return filters.dateDash();
  return dayjs(startAt)
    .add(availablePeriod ? availablePeriod - 1 : 0, 'day')
    .format('YYYY-MM-DD');
};

export default getExpiredAtByAvailablePeriod;

import { useIsFetching } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import useParamsId from 'hooks/useParamsId';
import { useLayoutEffect } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import IndividualDetail from './IndividualDetail';

/** 기타일정 Bulk 외 상세 */
const EtcScheduleIndividualDetail = () => {
  const currentEtcScheduleId = useParamsId();
  const isFetching = useIsFetching() > 0;

  /** 캐시된 강사 데이터로 권한 요청 후 재요청 하는 것 방지를 위해 현재 로그인 강사 캐시 지움 */
  useLayoutEffect(() => {
    queryClient.removeQueries({ queryKey: ['staff/me'] });
  }, []);

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <IndividualDetail currentEtcScheduleId={currentEtcScheduleId} />
    </ApiBoundary>
  );
};

export default EtcScheduleIndividualDetail;

import useQueryString from 'hooks/useQueryString';

import { NOTIFICATION_TABS } from '../constants';
import CheckInList from './CheckIn/CheckInList';
import PushFilterButton from './Push/PushFilterButton';
import PushList from './Push/PushList';

const NotificationList = () => {
  const { getSearchParams } = useQueryString();
  const currentTab = getSearchParams('type') ?? NOTIFICATION_TABS.push.value;

  if (currentTab === NOTIFICATION_TABS.push.value) {
    return (
      <>
        <PushFilterButton />
        <PushList />
      </>
    );
  }
  return <CheckInList />;
};

export default NotificationList;

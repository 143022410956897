import { useSetRecoilState } from 'recoil';
import { popupAtom } from 'recoil/popup';

const usePopup = () => {
  const setPopup = useSetRecoilState(popupAtom);

  return { setPopup };
};

export default usePopup;

import styled from '@emotion/styled';
import dayjs from 'dayjs';
import useToast from 'hooks/useToast';
import { get } from 'lodash';
import { useState } from 'react';
import PeriodFilter from 'sharedComponents/Filters/PeriodFilter';
import { FilterOptionsType, FilterProps, PeriodFilterProps, PeriodFilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { DEFAULT_PERIOD } from '../constants';

type Props = FilterProps & Partial<PeriodFilterProps> & { maxPeriod?: number };

const PeriodFilterContents = ({
  currentFilter,
  selectedOption,
  onClick: changeOptions,
  maxPeriod = 24,
  max = 'today',
  ...props
}: Props) => {
  const { setToast } = useToast();
  const previousOptions = (selectedOption as PeriodFilterType['period']) || DEFAULT_PERIOD;
  const [date, setDate] = useState<string[]>([previousOptions.start, previousOptions.end]);
  const description = `최대 ${maxPeriod}개월까지 조회할 수 있습니다.`;

  const saveCustomPeriod = (period: string[]) => {
    if (dayjs(period[0]).isBefore(dayjs(period[1]).subtract(maxPeriod, 'month'))) {
      setToast({ message: description, bottom: 92 });
      setDate([previousOptions.start, previousOptions.end]); // 기간선택 초기화
      return true;
    }
    const dateFormatted = period.map(filters.dateDash);
    setDate(dateFormatted);
    changeOptions({ value: { type: 'custom', start: dateFormatted[0], end: dateFormatted[1] }, label: '' }, 'period');
    return false;
  };

  const clickOptionButton = (option: FilterOptionsType) => {
    const months = option.value === '6months' ? 6 : option.value === '12months' ? 12 : 3;
    const startDate = filters.dateDash(dayjs().subtract(months, 'month'));
    const endDate = filters.dateDash();
    setDate([startDate, endDate]);
    changeOptions(
      {
        value: option.value
          ? {
              type: option.value as string,
              start: startDate,
              end: endDate,
            }
          : undefined,
        label: '',
      },
      'period',
    );
  };

  return (
    <StyledPeriodFilter
      currentFilter={currentFilter}
      selectedOption={get(selectedOption, 'type')}
      onClick={clickOptionButton}
      initialDate={date}
      description={description}
      onSave={props.onSave ?? saveCustomPeriod}
      max={max}
      {...props}
    />
  );
};

export default PeriodFilterContents;

const StyledPeriodFilter = styled(PeriodFilter)`
  padding: 0 20px 24px;
`;

import { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useQueryString from 'hooks/useQueryString';
import { MemberFormType } from 'pages/MemberForm/types';
import SelectTicket from 'pages/ProductForm/components/SelectTicket';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ProductCreate from './ProductCreate';
import ProductUpdate from './ProductUpdate';

type Props = {
  productType: Exclude<UserTicketParams['product_type'], 'all'>;
};

/** 회원 등록(수정) 폼에서 수강권/상품 만들 때 */
const ProductForm = ({ productType }: Props) => {
  const { resetField } = useFormContext<MemberFormType>();

  const { getAllSearchParams } = useQueryString();
  const { step, userTicketId } = getAllSearchParams();

  /** 회원 생성/수정 폼 > 새로운 수강권 발급 */
  const isCreateProduct = step === '2';
  /** 회원 수정 폼 > 기존 회원 보유 수강권 수정 */
  const isUpdateProduct = step === '2' && userTicketId;

  useEffect(() => {
    return () => {
      resetField('selectedTicket');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {step === '1' && <SelectTicket productType={productType} />}
      {isCreateProduct && <ProductCreate />}
      {isUpdateProduct && <ProductUpdate />}
    </>
  );
};

export default ProductForm;

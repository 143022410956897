import styled from '@emotion/styled';
import { MATCH_REPRESENTATIVE_COLOR, theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Divider from 'components/Divider';
import Label from 'components/Label';
import { MemberDetailResponse } from 'hooks/service/queries/useGetMemberDetail';
import { useMemo } from 'react';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

type Props = {
  member: MemberDetailResponse;
};

const MemberInfoAccordion = ({ member }: Props) => {
  const {
    profile: { birthday, gender },
    user_grade,
    address,
    lockers,
    policy: { is_locker, is_use_user_grade },
  } = member;

  const isNoData = useMemo(
    () => !birthday && !gender && !user_grade && !address?.detail_address && !lockers.length,
    [birthday, address, gender, lockers.length, user_grade],
  );

  if (isNoData) return <></>;

  return (
    <>
      <Accordion header="회원정보">
        <AccordionWrapper gap={8}>
          {gender && (
            <InfoFlexRow>
              <InfoTypography>성별</InfoTypography>
              <InfoTypography weight={500}>{gender === 'F' ? '여성' : '남성'}</InfoTypography>
            </InfoFlexRow>
          )}
          {birthday && (
            <InfoFlexRow>
              <InfoTypography>생년월일</InfoTypography>
              <InfoTypography weight={500}>{filters.date(birthday)}</InfoTypography>
            </InfoFlexRow>
          )}
          {is_use_user_grade && user_grade && (
            <InfoFlexRow>
              <InfoTypography>회원등급</InfoTypography>
              <Label
                size="small"
                padding={3}
                color={MATCH_REPRESENTATIVE_COLOR[user_grade.representative_color]}
                textColor="white">
                {user_grade.name}
              </Label>
            </InfoFlexRow>
          )}
          {address && (address.address || address.detail_address) && (
            <InfoFlexRow className="flex-start">
              <InfoTypography>주소</InfoTypography>
              <InfoTypography className="address" weight={500}>
                {address.address} {address.detail_address}
              </InfoTypography>
            </InfoFlexRow>
          )}

          {is_locker && !!lockers.length && (
            <InfoFlexRow className="locker flex-start">
              <InfoTypography className="locker-label">락커</InfoTypography>
              <LockerWrapper>
                {lockers.map(locker => (
                  <span className="locker-item" key={locker.id}>
                    <InfoTypography weight={500}>
                      {locker.name} {locker.name ? ' / ' : null} {locker.no}번
                    </InfoTypography>
                    <InfoTypography textColor="gray3">({filters.period(locker.start_on, locker.end_on)})</InfoTypography>
                  </span>
                ))}
              </LockerWrapper>
            </InfoFlexRow>
          )}
        </AccordionWrapper>
      </Accordion>
      <Divider />
    </>
  );
};

export default MemberInfoAccordion;

const AccordionWrapper = styled(InfoFlexWrapper)`
  padding: 0 20px 24px;

  .address {
    max-width: 200px;
    word-break: keep-all;
  }

  .flex-start {
    align-items: flex-start;
  }

  .locker-label {
    text-align: left;
  }
`;

const LockerWrapper = styled.span`
  ${theme.flex('column', '', '', 4)};

  .locker-item {
    ${theme.flex('column', '', '', 2)}

    .typography {
      display: block;
      text-align: right;
    }
  }
`;

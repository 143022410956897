import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import { ReactNode } from 'react';
import { useBlocker } from 'react-router-dom';

type Props = {
  /**
   * 이동 전 확인 다이얼로그를 띄울 조건
   * 기본값 true (항상)
   * e.g. methods.formState.isDirty
   */
  isBlocked?: boolean;
  negativeActionText?: string;
  positiveActionText?: string;
  children?: ReactNode;
};

const DialogBeforeLeave = ({ isBlocked = true, negativeActionText = '취소', positiveActionText = '나가기', children }: Props) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isBlocked && currentLocation.pathname !== nextLocation.pathname,
  );

  if (blocker.state !== 'blocked') return null;

  return (
    <Dialog
      onClose={() => blocker.reset()}
      positiveAction={{ text: positiveActionText, onClick: () => blocker.proceed() }}
      negativeAction={{ text: negativeActionText, onClick: () => blocker.reset(), style: 'outlined' }}>
      {children ?? (
        <>
          <Typography size={15}>입력 정보가 저장되지 않았습니다.</Typography>
          <Typography size={15}>페이지를 나가시겠습니까?</Typography>
        </>
      )}
    </Dialog>
  );
};

export default DialogBeforeLeave;

import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';
import convertTargetsText from 'utils/convertTargetsText';

type Props = {
  onClick: () => void;
};

const SaveButton = ({ onClick }: Props) => {
  const { control, setValue } = useFormContext<BookingPrivateFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });

  /** 수강회원 선택 시, 수강회원 이름으로 수업명 세팅 */
  const click = () => {
    onClick();
    const member = selectedUserTickets.map(({ member }) => member);
    const title = member.length === 1 ? `${member[0].name} 님` : convertTargetsText(member);
    setValue('title', title, { shouldDirty: true });
    setValue(
      'userTicketIds',
      selectedUserTickets.map(({ id }) => id),
    );
  };

  return (
    <FooterButton disabled={!selectedUserTickets.length} onClick={click}>
      선택 완료
    </FooterButton>
  );
};

export default SaveButton;

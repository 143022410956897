import { AppPushFormType } from './types';

export const APP_PUSH_FORM_TEXT = {
  title: '앱 푸시 메시지',
  titleEditSuffix: ' 수정',
  titleAndMessage: {
    titlePlaceholder: '제목 입력 (20자 이내)',
    messagePlaceholder: '내용 입력 (500자 이내)',
    hasTitleTooltip: false,
  },
  target: '받는사람',
  users: '회원목록',
  create: {
    confirmButton: '메시지 보내기',
  },
  update: {
    confirmButton: '수정',
  },
  dialogButton: {
    now: '보내기',
    reserve: '예약',
  },
  dialogDescription: {
    now: '즉시 발송할까요?',
    reserve: '발송 예약할까요?',
  },
};

export const APP_PUSH_DEFAULT_VALUES: AppPushFormType = {
  mode: 'create',
  type: 'push',
  title: '',
  message: '',
  users: [],
  counseling_logs: [],
  isReserve: false,
  reservationDate: '',
  filter: null,
};

// 미리보기 하단에 skeleton 길이
export const APP_PUSH_DUMMY_LENGTH = 3;

export const APP_PUSH_MAX_LENGTH = {
  title: 20,
  message: 500,
};

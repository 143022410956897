import { RecoilState, useRecoilCallback } from 'recoil';

// TODO: any !!
const useResetAllRecoilState = (stateArray: Array<RecoilState<any>>) => {
  return useRecoilCallback(({ reset }) => () => {
    stateArray.forEach(state => {
      reset(state);
    });
  });
};

export default useResetAllRecoilState;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetRole = () => {
  const { queryFn } = useQueryFnWithCommonParams<RoleResponse>({
    url: '/api/role',
  });

  return useSuspenseQuery({
    queryKey: ['role'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetRole;

type RoleResponse = Role[];

type Role = {
  created_at: string;
  description: string | null;
  display_name: string;
  id: number;
  is_immutable: 0 | 1;
  name: string;
  studio_id: number;
  updated_at: string;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { hexToRgba } from 'utils/styles';

export type Props = {
  imgSrc?: string;
  /** 메모 폼에서 사진 추가 시에 노출 */
  showRemoveButton?: boolean;
  onRemove?: () => void;
  /** 썸네일 클릭하면 해당 상세 이미지 노출 */
  onClick?: () => void;
  /** 상세 이미지 하단 pagination의 active 일 때 */
  isActive?: boolean;
};

const Thumbnail = ({ imgSrc, showRemoveButton, isActive, onRemove, onClick }: Props) => {
  return (
    <Container onClick={onClick} isActive={isActive}>
      {showRemoveButton && (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            onRemove?.();
          }}>
          <Icon name="error2Fill" fillColor={theme.color.gray2} />
        </IconButton>
      )}

      <Wrapper>
        <img src={imgSrc} alt="첨부 이미지" />
      </Wrapper>
    </Container>
  );
};

export default Thumbnail;

const Container = styled.div<Pick<Props, 'isActive'>>`
  background-color: ${theme.color.white};
  position: relative;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 2px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    border-radius: 2px;
    border-style: solid;
    border-width: ${({ isActive }) => (isActive ? 2 : 1)}px;
    border-color: ${({ isActive }) => (isActive ? theme.color.primary : hexToRgba(theme.color.gray1, 0.12))};
  }

  .icon-button {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  ${theme.flex('column')};
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 2px;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
`;

export const ThumbnailCommonWrap = styled.div`
  ${theme.flex('', '', 'flex-start', 12)};
`;

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureRuleType } from '../queries/useGetLectureCourse';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';
import { LecturePrivatePatchParams, LectureValidationError } from './usePatchLecturePrivate';
import { BookingFailResponse, BookingPrivateParams } from './usePostBookingPrivate';

/** 프라이빗 이후 모든 수업 수정 (코스 수정) */
const usePatchLecturePrivateBulk = (lecture: LectureDetailResponse) => {
  const { course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingPrivateBulkParams, BookingPrivateBulkResponse>({
    method: `patch`,
    url: `/api/lecture/private/bulk/${course_id}`,
  });

  return useCustomMutation<AxiosResponse, AxiosError<LectureValidationError>, BookingPrivateBulkParams | undefined, unknown>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture'],
      });
    },
  });
};

export default usePatchLecturePrivateBulk;

export type BookingPrivateBulkParams = Pick<LecturePrivatePatchParams, 'is_force' | 'is_pass_limit'> &
  BookingPrivateParams & {
    start: string;
    private_booking_rule_type: LectureRuleType;
    private_booking_cancel_rule_type: LectureRuleType;
    private_booking_cancel_startline: number;
    private_booking_cancel_deadline: number;
    private_booking_cancel_start_days: number;
    private_booking_cancel_end_days: number;
    private_booking_cancel_start_time: number;
    private_booking_cancel_end_time: number;
    private_booking_startline: number;
    private_booking_deadline: number;
    private_booking_start_days: number;
    private_booking_end_days: number;
    private_booking_start_time: number;
    private_booking_end_time: number;
    enter_start_deadline: number;
    enter_end_deadline: number;
  };

export type BookingPrivateBulkResponse = {
  /**
   * 수업 모두 수정 성공 시 fail 에 빈 배열,
   * 1개라도 실패가 있으면 fail 에 실패만 반환되고 성공된 것은 수정되지 않음.
   * 실패 화면에서 계속 버튼 클릭하여 is_force: true 를 보내면 그 때 성공, 실패 모두 수정됨
   */
  fail: Array<BookingFailResponse>;
};

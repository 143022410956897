import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const SENTRY_RATES = {
  production: {
    sampleRate: 0.01,
    tracesSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.05,
  },
  qa: {
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  },
};

export const sentryInit = () => {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  const environment = process.env.REACT_APP_ENV;
  const isSentryEnabled = environment === 'production' || environment === 'qa';

  if (!dsn || !isSentryEnabled) return;
  Sentry.init({
    dsn,
    integrations: [
      // 기본 센트리 설정, ReactRouter v6 Routes 기준
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),

      // 오류상황 재현 Replay
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // ui 이벤트 발생시, 클릭한 엘리먼트로 표시
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb?.category?.startsWith('ui') && hint?.event?.target?.tagName) {
        breadcrumb.message = `${hint?.event.target.tagName.toLowerCase()}: ${hint?.event.target.innerText}`;
      }
      return breadcrumb;
    },

    sampleRate: SENTRY_RATES[environment].sampleRate,
    tracesSampleRate: SENTRY_RATES[environment].tracesSampleRate,
    replaysOnErrorSampleRate: SENTRY_RATES[environment].replaysOnErrorSampleRate,
    environment,
  });
};

/**
 * https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
 * */
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const recordErrorWithSentry = (error: unknown) => {
  Sentry.captureException(error);
};

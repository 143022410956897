import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { BookingCommonParams } from '../mutations/usePostBookingPrivate';

/** 수업 주/월간 예약 제한 초과 여부 */
const useGetBookingCount = (enabled: boolean, params: BookingCountParams) => {
  const { queryFn } = useQueryFnWithCommonParams<{ limit: BookingCountResponse }, BookingCountParams>({
    url: `/api/booking/count`,
    params,
  });

  return useQuery({
    queryKey: [`booking/count`, { ...params }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetBookingCount;

export type BookingCountParams = Pick<BookingCommonParams, 'weekday'> & {
  user_ticket_ids: Array<number>;
  member_ids: Array<number>;
  start_date: string; // 2024-07-01 11:00:00
  end_date: string;
};

/**
 * 초과 중복 검증 반환 예시
 * '2024-07-01': 1,
 * '2024-07-02': 1,
 * ...
 */
export type BookingLimitType = {
  [key: string]: number;
};
export type BookingCountResponse = {
  [key: string]: {
    daily: BookingLimitType;
    weekly: BookingLimitType;
    monthly: BookingLimitType;
    booking_limit_per_month: number;
    booking_limit_per_week: number;
    daily_booking_limit: number;
  };
};

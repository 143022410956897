import * as icons from 'assets/icons';
import { theme } from 'assets/styles';
import React from 'react';
import { ColorType } from 'types/colorTypes';

export type IconType = keyof typeof icons;
export const iconTypes: IconType[] = Object.keys(icons) as IconType[];

export interface IconProps {
  /**
   * 사용할 아이콘 이름
   */
  name: IconType;
  /**
   * 기본 아이콘 색, default: gray1
   */
  color?: ColorType;
  /**
   * 아이콘 선 색 상세 커스텀용, 기본 아이콘 색은 color로 설정 권장
   */
  strokeColor?: string;
  /**
   * 아이콘 내부 색 상세 커스텀용, 기본 아이콘 색은 color로 설정 권장
   */
  fillColor?: string;
  /**
   * 아이콘 크기
   */
  size?: number;
  /**
   * 커스텀 클래스네임
   */
  className?: string;
  /**
   * 선 굵기 속성
   */
  strokeWidth?: number;
}

const Icon = ({ name, strokeColor, fillColor, color, size = 24, className, strokeWidth }: IconProps) => {
  const SVGIcon = icons[name];

  return (
    <SVGIcon
      title={name}
      style={{
        fill: fillColor && fillColor,
        stroke: strokeColor && strokeColor,
        color: (color && theme.color[color]) || fillColor || (strokeColor && 'transparent') || theme.color.gray1,
        width: size,
        height: 'auto',
        strokeWidth: strokeWidth && strokeWidth,
        flex: '0 0 auto',
      }}
      className={className}
    />
  );
};

export default React.memo(Icon);

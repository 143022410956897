import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';

interface SkeletonProps {
  /** Skeleton 모양 - 기본값: box */
  type?: 'box' | 'circle' | 'round';
  width?: number;
  height: number;
}

const Skeleton = styled.div<SkeletonProps>`
  background-color: ${theme.color.gray6};
  width: ${props => (props.width ? props.width + 'px' : '100%')};
  height: ${props => props.height + 'px'};

  ${({ type }) => {
    switch (type) {
      case 'circle':
        return css`
          border-radius: 50%;
        `;
      case 'round':
        return css`
          border-radius: 8px;
        `;
    }
  }}
`;

export default Skeleton;

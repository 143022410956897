import { StaffForFilter } from 'hooks/service/queries/useGetFilterStaffs';
import { moreDetailSchedulePermissionsMap } from 'pages/MoreDetails/Schedule/constant';
import {
  ScheduleBookingParamType,
  ScheduleCheckinParamType,
  ScheduleDeletedLectureParamType,
} from 'pages/MoreDetails/Schedule/type';
import { atom, selector } from 'recoil';
import filters from 'utils/filters';

/**
 * 더보기 > 설정에 노출할 버전정보
 */
export const currentVersionAtom = atom<string>({
  key: 'currentVersionAtom',
  default: '',
});

/**
 * 일정관리
 */
export const bookingParamsAtom = atom<ScheduleBookingParamType>({
  key: 'bookingParamsAtom',
  default: {
    period: {
      type: 'today',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 체크인
 */
export const checkinParamsAtom = atom<ScheduleCheckinParamType>({
  key: 'checkinParamsAtom',
  default: {
    period: {
      type: 'today',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 삭제된 수업
 */
export const deletedLectureParamsAtom = atom<ScheduleDeletedLectureParamType>({
  key: 'deletedLectureParamsAtom',
  default: {
    period: {
      type: 'today',
      start: filters.dateDash(),
      end: filters.dateDash(),
    },
  },
});

/**
 * 더보기 > 일정관리 강사목록
 */
export const staffListForScheduleFilter = atom<StaffForFilter[]>({
  key: 'staffListForScheduleFilter',
  default: [],
});

/**
 * 더보기 > 일정관리 권한
 */

export const isEveryPermissionMoreDetailScheduleAtom = atom({
  key: 'isEveryPermissionMoreDetailScheduleAtom',
  default: true,
});

/**
 * 더보기 > 일정관리 현재 볼 수 있는 권한 - 일정관리
 */
export const currentPermissionMoreDetailScheduleAtom = atom({
  key: 'currentPermissionMoreDetailScheduleAtom',
  default: selector({
    key: 'currentPermissionMoreDetailScheduleSelector',
    get: ({ get }) => {
      const bookingFilter = get(bookingParamsAtom);
      if (!bookingFilter.type) {
        return [moreDetailSchedulePermissionsMap['group'], moreDetailSchedulePermissionsMap['private']];
      } else if (bookingFilter.type === 'G') {
        return [moreDetailSchedulePermissionsMap['group']];
      } else {
        return [moreDetailSchedulePermissionsMap['private']];
      }
    },
  }),
});

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 계정 탈퇴
 */
const useDeleteAuthWithdraw = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<AuthWithdrawParams, AuthWithdrawResponse>({
    method: 'delete',
    url: '/api/auth/withdraw',
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.clear();
    },
  });
};

export default useDeleteAuthWithdraw;

type AuthWithdrawParams = {
  password: string;
};

type AuthWithdrawResponse = {
  message: string;
};

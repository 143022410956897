import styled from '@emotion/styled';
import LoginExceptionDialog from 'pages/Schedule/ScheduleMain/components/LoginExceptionDialog';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const LoginExceptionContainer = ({ children }: Props) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const navigate = useNavigate();

  const openDialog = () => {
    setIsOpenDialog(true);
  };

  const closeDialog = () => {
    setIsOpenDialog(false);
  };

  const goLoginPage = () => {
    navigate('/login', { state: { isLookAround: true } });
  };

  return (
    <>
      <Container onClick={openDialog}>{children}</Container>
      {isOpenDialog && <LoginExceptionDialog onClose={closeDialog} onClick={goLoginPage} />}
    </>
  );
};

export default LoginExceptionContainer;

export const Container = styled.div`
  height: 100%;

  > * {
    pointer-events: none;
  }
`;

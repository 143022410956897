import styled from '@emotion/styled';
import Typography, { TypographyProps } from 'components/Typography';

const NodataTypography = ({ children, className, ...props }: TypographyProps) => {
  return (
    <Container className={`no-data-typography ${className ?? ''}`}>
      <Typography size={15} textColor="gray2" {...props}>
        {children}
      </Typography>
    </Container>
  );
};

export default NodataTypography;

const Container = styled.div`
  margin: 120px 0;
  text-align: center;
`;

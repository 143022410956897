import 'swiper/css';

import styled from '@emotion/styled';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import useInfinityUserTicketProduct, { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import usePermission from 'hooks/usePermission';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { currentMemberUserTicketsAtom } from 'recoil/MemberDetail';
import NodataTypography from 'sharedComponents/NodataTypography';
import formatUserTicketCard from 'utils/formatUserTicketCard';

import TicketSwiper from './TicketSwiper';

type Props = CurrentMemberIdProps & {
  productType: UserTicketParams['product_type'];
};

const UserTicketList = ({ currentMemberId, productType }: Props) => {
  const setCurrentMemberUserTickets = useSetRecoilState(currentMemberUserTicketsAtom);

  const { hasPermission } = usePermission();
  const canCreateUserTicket = hasPermission(MEMBER_PERMISSION.memberTicketCreate);

  const { data: currentUserTickets } = useInfinityUserTicketProduct({
    user_id: currentMemberId,
    product_type: 'all',
    status: 'active',
  });

  /**
   * 회원 수정 시, 수정 폼에서 보유 수강권/상품 노출 및
   * 회원 삭제 시, 보유 수강권 판단용도 (수강권 있으면 회원 삭제 불가)
   */
  useEffect(() => {
    if (currentUserTickets) {
      setCurrentMemberUserTickets(currentUserTickets);
    }
  }, [currentUserTickets, setCurrentMemberUserTickets]);

  const userTickets = useMemo(() => {
    return currentUserTickets
      .filter(({ ticket }) =>
        productType === 'item' ? ticket.available_class_type === 'I' : ticket.available_class_type !== 'I',
      )
      .map(ticket => {
        return formatUserTicketCard(ticket);
      });
  }, [currentUserTickets, productType]);

  if (!canCreateUserTicket && !userTickets.length) {
    return <StyledNoTypography opacity={0.8}>등록된 {PRODUCT_TYPE_TEXT[productType]}이 없습니다.</StyledNoTypography>;
  }

  return (
    <TicketSwiper
      canCreateUserTicket={canCreateUserTicket}
      key={userTickets.length}
      currentMemberId={currentMemberId}
      currentUserTickets={userTickets}
      productType={productType}
    />
  );
};

export default UserTicketList;

const StyledNoTypography = styled(NodataTypography)`
  margin: 96px 0 126px;
`;

import dayjs, { ConfigType } from 'dayjs';

export const safeDayjs = (date: ConfigType) => {
  const timezoneRegex = /([Zz]|([+-]\d{2}:\d{2}))$/; // 날짜 문자열이 타임존 정보를 포함하고 있는지 확인하는 정규식

  if (typeof date === 'string' && !timezoneRegex.test(date)) {
    date = date.replace(/\./g, '-'); // 사파리에서 . 구분자 사용시 발생하는 invalid date 오류 방지
  }
  return dayjs(date);
};

import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import NoticeDetail from './components/NoticeDetail';
import SkeletonDetail from './components/SkeletonDetail';
import { NOTICE_DETAIL_TEXT } from './constants';

const MoreDetailsSettingsNoticeDetail = () => {
  const id = useParamsId();

  return (
    <MainLayout header={{ title: NOTICE_DETAIL_TEXT.title }}>
      <ApiBoundary fallback={<SkeletonDetail />}>
        <NoticeDetail id={id} />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsSettingsNoticeDetail;

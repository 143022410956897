import { useSuspenseQuery } from '@tanstack/react-query';
import { MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStaffColor = () => {
  const { queryFn } = useQueryFnWithCommonParams<StaffColorResponse>({
    url: '/api/staff/color',
  });

  return useSuspenseQuery({
    queryKey: ['staffColor'],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetStaffColor;

export type StaffColorResponse = DataType;

type DataType = { data: ColorType[] };

type ColorType = { color: keyof typeof MATCH_REPRESENTATIVE_COLOR; count: number };

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import { Dispatch, SetStateAction } from 'react';

import { CommonProps } from '../types';
import ApplyButton from './ApplyButton';
import FilterStaffItems from './FilterStaffItems';

type Props = {
  staffs: StaffResponse[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isDirty: boolean;
} & Omit<CommonProps, 'tempApplyStaffs'>;

const SelectCheckBoxList = ({
  staffs,
  tempCheckedStaffs,
  setIsOpen,
  setTempApplyStaffs,
  setTempCheckedStaffs,
  isDirty,
}: Props) => {
  const isAllChecked = tempCheckedStaffs.includes('all');
  const isOnlySomeChecked = !isAllChecked && !!tempCheckedStaffs.length;

  const changeAllSelect = (e: { target: { checked: boolean } }) => {
    const isChecked = e.target.checked;
    const newStaffs = isChecked ? ['all' as const] : [];
    setTempCheckedStaffs(newStaffs);
  };

  const changeFilterStaffs = (e: { target: { id: string; checked: boolean } }) => {
    /** 전체 선택되어 있을 경우, id가 아닌 'all' 문자열이므로 예외 처리 */
    if (isAllChecked) {
      const staffIds = staffs.map(staff => staff.id);
      setTempCheckedStaffs(staffIds.filter(staffId => staffId !== Number(e.target.id)));
      return;
    }

    const filterValue = Number(e.target.id);
    const isChecked = e.target.checked;

    if (isChecked) {
      const standardStaffs = [...tempCheckedStaffs, filterValue];
      const newStaffs = standardStaffs.length === staffs.length ? ['all' as const] : standardStaffs;
      setTempCheckedStaffs(newStaffs);
    } else {
      const newFilters = tempCheckedStaffs.filter(staffId => staffId !== filterValue);
      setTempCheckedStaffs(newFilters);
    }
  };

  const applyTempApplyStaffs = () => {
    setTempApplyStaffs(tempCheckedStaffs);
    setIsOpen(false);
  };

  return (
    <Container isOnlySomeChecked={isOnlySomeChecked}>
      <CheckBox
        id="all-select"
        className="all-select"
        label="전체"
        gap={16}
        labelFontWeight={500}
        labelFontsize={17}
        checked={isAllChecked}
        onChange={changeAllSelect}
        iconName={isOnlySomeChecked ? 'lineBold' : 'selectCheckBold'}
        checkColor={isOnlySomeChecked || isAllChecked ? 'white' : 'gray5'}
      />
      <Divider thin />

      <FilterStaffItems staffs={staffs} tempCheckedStaffs={tempCheckedStaffs} onChange={changeFilterStaffs} />

      <ApplyButton text="선택 완료" onClick={applyTempApplyStaffs} disabled={isDirty || !tempCheckedStaffs.length} />
    </Container>
  );
};

export default SelectCheckBoxList;

const Container = styled.div<{ isOnlySomeChecked: boolean }>`
  ${theme.flex('column', 'flex-start', 'center', 12)};
  padding: 16px 20px calc(32px + 76px); // 하단 버튼 높이 계산

  hr {
    width: 100%;
  }

  .checkbox {
    height: 40px;
  }

  .all-select {
    .input-span-style {
      border-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'gray5']};
      background-color: ${({ isOnlySomeChecked }) => theme.color[isOnlySomeChecked ? 'primary' : 'white']};
    }
  }
`;

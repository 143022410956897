import Typography, { TypographyProps } from 'components/Typography';

type Props = {
  isSelected: boolean;
  showLabelAll?: boolean;
} & TypographyProps;

const CheckRadioTypography = ({ children, showLabelAll, isSelected, ...props }: Props) => {
  return (
    <Typography
      className="check-radio-filter-custom-label-text"
      span
      size={17}
      weight={isSelected ? 600 : 400}
      textColor={isSelected ? 'primary' : 'gray1'}
      ellipsis={!showLabelAll ? 1 : undefined}
      {...props}>
      {children}
    </Typography>
  );
};

export default CheckRadioTypography;

import { selector } from 'recoil';

import { mediaPermissionAtom } from './atoms';

export const hasMediaPermissionSelector = selector({
  key: 'hasMediaPermissionSelector',
  get: ({ get }) => {
    // 모바일이 아닐때는 항상 true
    if (window?.ReactNativeWebView === undefined) {
      return true;
    }
    const { camera, photoLibrary } = get(mediaPermissionAtom);
    // 카메라나 포토앨범 중 하나라도 권한 얻으면 true
    return camera === 'granted' || photoLibrary === 'granted';
  },
});

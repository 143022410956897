import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import { IHeaderProps } from 'components/Header';
import Icon from 'components/Icon';
import SubTitle from 'components/SubTitle';
import useQueryString from 'hooks/useQueryString';
import { FormEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import { nickNameRequired, teacherNameRequired } from 'utils/validate';
import { object } from 'yup';

import UnifiedNameNickname from './components/UnifiedNameNickname';
import UnifiedSelectId from './components/UnifiedSelectId';
import { DEFAULT_VALUE, UNIFIED_ACCOUNT_TEXT } from './constants';
import { StateType, UnifiedAccountFormTypes } from './types';

const yupSchema = object().shape({ teacherNameRequired, nickNameRequired });

const UnifiedAccount = () => {
  const { state }: StateType = useLocation();
  const navigate = useNavigate();

  const { getSearchParams, setSearchParams } = useQueryString();

  const methods = useForm<UnifiedAccountFormTypes>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      ...DEFAULT_VALUE,
      mobile: state?.mobile,
    },
  });

  const currentStep = Number(getSearchParams('step'));
  const currentType = currentStep === 1 ? 'selectId' : 'unified';

  const subTitleOptions = {
    title: UNIFIED_ACCOUNT_TEXT[currentType].title,
    message: {
      position: 'top',
      text: currentStep === 1 || state?.type === 'loginSuccess' ? UNIFIED_ACCOUNT_TEXT.commonTop : '',
      bottomMessage: UNIFIED_ACCOUNT_TEXT[currentType].bottom,
    },
    bottom: 40,
  };

  const next = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSearchParams({ step: '2' });
  };

  const headerProps: IHeaderProps = {
    leftButton:
      currentStep === 2 ? (
        <IconButton
          onClick={() => {
            methods.reset({
              ...DEFAULT_VALUE,
              mobile: methods.getValues('mobile'),
            });
            navigate(-1);
          }}>
          <Icon name="headerBack" />
        </IconButton>
      ) : (
        <></>
      ),
  };

  return (
    <MainLayout header={headerProps}>
      <Container>
        <SubTitle {...subTitleOptions} />
        <ApiBoundary>
          <FormProvider {...methods}>
            {currentStep === 1 && <UnifiedSelectId studios={state?.studios || []} next={next} />}
            {currentStep === 2 && <UnifiedNameNickname isLoginSuccess={state?.type === 'loginSuccess'} />}
          </FormProvider>
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default UnifiedAccount;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  padding: 8px 20px 0;
  height: 100%;

  form {
    ${theme.flex('column', '', '', 8)};
  }
`;

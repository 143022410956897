import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import { useEffect } from 'react';

type Props = {
  ref: React.RefObject<HTMLDivElement>;
  show?: () => void;
  hide?: () => void;
};

const useObserver = ({ ref, show, hide }: Props) => {
  useEffect(() => {
    const targetRef = ref.current;
    if (!targetRef) return;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            /** target 이 화면에 보일 때 실행 */
            show?.();
          } else {
            /** target 이 화면에서 사라질 때 실행 */
            hide?.();
          }
        });
      },
      { rootMargin: '50px' },
    );

    observer.observe(targetRef);

    return () => observer.unobserve(targetRef);
  }, [ref, show, hide]);
};
export default useObserver;

export const ObserverTarget = styled.div<{ bgColor?: keyof typeof color }>`
  height: 1px;
  background-color: ${({ bgColor }) => theme.color[bgColor || 'white']};
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import InputSelectBase, { StyledLabel } from 'components/InputSelectBase';
import React from 'react';

import { RadioProps } from '.';

export type CheckRadioProps = Omit<RadioProps, 'label' | 'fontSize'> & {
  /** Radio 요소에서 사용되는 label 컨텐츠 */
  label: React.ReactNode;
};

const CheckRadio = React.forwardRef<HTMLInputElement, CheckRadioProps>(({ label, ...props }, ref) => {
  return (
    <Container htmlFor={props.id} className="radio-check-label">
      <div className="radio-check-contents-wrapper">{label}</div>
      <InputSelectBase isCheckRadio type="radio" ref={ref} {...props} />
    </Container>
  );
});

const Container = styled(StyledLabel)`
  &.radio-check-label {
    ${theme.flex('', 'center', '')};
    flex: 1;

    .radio-check-contents-wrapper {
      flex: 1;
    }

    .input-select-base-input-wrapper {
      width: 24px;
      height: 24px;

      .input-span-check-style {
        display: none;
      }

      input:checked {
        & + span + span {
          display: block;
        }
      }
    }
  }
`;

CheckRadio.displayName = 'CheckRadio';
export default CheckRadio;

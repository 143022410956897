import styled from '@emotion/styled';
import arrowImg from 'assets/images/tutorial1_arrow.svg';
import studioNameImg from 'assets/images/tutorial1_studiomate.svg';
import Typography from 'components/Typography';

const TutorialFirst = () => {
  return (
    <Container>
      <ContentWrapper>
        <StudioNameWrapper>
          <img src={studioNameImg} alt="시설이름" />
        </StudioNameWrapper>
        <ArrowTopLeftWrapper>
          <img src={arrowImg} alt="화살표이미지" />
        </ArrowTopLeftWrapper>
        <TextWrapper>
          <span className="highlight-color">시설별 일정</span>
          <Typography span textColor="white" size={19} weight={600}>
            을
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            확인 할 수 있습니다.
          </Typography>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default TutorialFirst;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
`;

const StudioNameWrapper = styled.div`
  margin-bottom: 8px;
  img {
    width: 100%;
  }
`;

const ArrowTopLeftWrapper = styled.div`
  position: absolute;
  top: 3.5rem;
  left: 1rem;
  img {
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 20rem;
  position: absolute;
  top: 7rem;
  left: -1.5rem;
  text-align: center;

  .highlight-color {
    font-size: 19px;
    font-weight: 600;
    color: #2cead7;
  }
`;

import instance from 'api/axios';
import { AxiosResponse } from 'axios';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

const useInfiniteQueryFnWithCommonParams = <TResponse, TParams = undefined>(axiosInfo: { url: string; params?: TParams }) => {
  const studio_id = useRecoilValue(studioIdAtom);
  const staff_id = useRecoilValue(staffIdAtom);

  const queryFn: ({ pageParam }: { pageParam: number }) => Promise<AxiosResponse<InfiniteData<TResponse>>> = async ({
    pageParam,
  }) => {
    const { url, params } = axiosInfo;
    return await instance({
      method: 'get',
      url,
      params: {
        studio_id,
        staff_id,
        paginate_type: 'simple',
        page: pageParam,
        limit: 20,
        ...params,
      },
    });
  };

  return { queryFn };
};

export default useInfiniteQueryFnWithCommonParams;

export type InfiniteLinksType = {
  first: string | null;
  last: string | null;
  next: string | null;
  prev: string | null;
};

export type InfiniteMetaType = {
  current_page: number;
  from: number;
  last_page: number;
  links: { active: boolean; label: string; url: string | null }[];
  path: string;
  per_page: number;
  to: number;
  total: number;
};

type InfiniteData<T> = {
  data: Array<T>;
  links: InfiniteLinksType;
  meta: InfiniteMetaType;
};

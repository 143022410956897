import { AxiosError, AxiosResponse } from 'axios';
import { CustomError } from 'types/errorTypes';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureRuleType } from '../queries/useGetLectureCourse';
import { BookingCommonParams, BookingFailResponse } from './usePostBookingPrivate';

const usePostLectureGroup = () => {
  const { mutationFn } = useMutationFnWithCommonParams<LectureGroupParams>({
    method: `post`,
    url: `/api/lecture/group`,
  });

  return useCustomMutation<
    AxiosResponse,
    AxiosError<Array<BookingFailResponse> | CustomError>,
    LectureGroupParams | undefined,
    unknown
  >({
    mutationFn,
  });
};

export default usePostLectureGroup;

export type LectureGroupParams = BookingCommonParams & {
  is_booking_only: boolean;
  description?: string;
  division_id?: number;
  max_trainee: number;
  min_trainee: number;
  waiting_trainee_limit: number;
  autoclose_deadline?: number;
  booking_auto_shift_deadline?: number;
  daily_booking_change_deadline?: number;
  group_booking_rule_type: LectureRuleType;
  group_booking_cancel_rule_type: LectureRuleType;
  group_booking_cancel_startline: number;
  group_booking_cancel_deadline: number;
  group_booking_cancel_start_days: number;
  group_booking_cancel_end_days: number;
  group_booking_cancel_start_time: number;
  group_booking_cancel_end_time: number;
  group_booking_startline: number;
  group_booking_deadline: number;
  group_booking_start_days: number;
  group_booking_end_days: number;
  group_booking_start_time: number;
  group_booking_end_time: number;
  enter_start_deadline: number;
  enter_end_deadline: number;
};

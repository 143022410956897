import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography, { TypographyProps } from 'components/Typography';
import { PropsWithChildren } from 'react';

type Props = Pick<TypographyProps, 'children' | 'span' | 'tag' | 'className'>;
export const DialogDescription = ({ children, className, span, tag }: Props) => (
  <Typography span={!!span} tag={tag} size={15} textColor="gray1" className={className}>
    {children}
  </Typography>
);

export const DialogInfoContent = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <StyledInfoWrapper className={className}>{children}</StyledInfoWrapper>
);

export const DialogInfoItem = ({ children }: PropsWithChildren) => (
  <StyledInfoLi>
    <DialogDescription span>{children}</DialogDescription>
  </StyledInfoLi>
);

const StyledInfoWrapper = styled.div`
  text-align: left;

  > ul {
    margin-bottom: 8px;
  }
`;

export const StyledInfoLi = styled.li`
  text-align: left;
  position: relative;
  padding-left: 6px;
  margin-top: 4px;

  &:before {
    content: '';
    width: 2px;
    height: 2px;
    position: absolute;
    top: 10px;
    left: 0;
    background-color: ${theme.color.gray3};
    border-radius: 50%;
  }
`;

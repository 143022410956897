import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';

import { GENDER } from './constants';

type Props = {
  birthday: string | null;
  gender?: keyof typeof GENDER;
};

const BirthdaySection = ({ birthday, gender }: Props) => {
  return (
    <Container>
      <Icon name="birthdayFill" size={16} fillColor={theme.color.gray4} />
      <Typography weight={500} textColor="gray2">
        {birthday || '-'}
      </Typography>
      {gender && (
        <Typography className="gender" weight={500} textColor="gray2" lineHeight={2}>
          {GENDER[gender]}
        </Typography>
      )}
    </Container>
  );
};

export default BirthdaySection;

const Container = styled.section`
  ${theme.flex('row', 'center', '', 8)};
  padding: 20px;
  height: 58px;
  border-top: 1px solid ${theme.color.gray7};

  .gender {
    &::before {
      content: '|';
      margin-right: 8px;
      font-size: 1.2rem;
      color: ${theme.color.gray4};
    }
  }
`;

export const CHANGE_PASSWORD_TEXT = {
  current: {
    title: '기존 비밀번호',
    description: '본인확인을 위해 비밀번호를 입력해 주세요.',
    submit: '확인',
    placeholder: '비밀번호 입력',
  },
  change: {
    title: '비밀번호 변경',
    description: '변경할 비밀번호를 입력해 주세요.',
    placeholder1: '변경 비밀번호 입력',
    placeholder2: '변경 비밀번호 확인',
    submit: '완료',
  },
  label: '변경 비밀번호',
  success: '비밀번호가 변경되었습니다.',
  duplicated: '기존 비밀번호와 \n다르게 입력해 주세요.',
  ok: '확인',
};

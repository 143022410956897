import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { MemberCounselResponse } from 'hooks/service/queries/useInfinityMemberCounsel';
import filters from 'utils/filters';

import { COUNSEL_CHANNEL_TEXT, COUNSEL_FUNNEL_TEXT } from '../constants';

type Props = {
  customer: MemberCounselResponse;
  onClick: () => void;
};

const CounselCard = ({ customer, onClick }: Props) => {
  const { channel, funnel, contents, member, name, staff, start_on } = customer;
  return (
    <Container onClick={onClick}>
      <div className="counsel-member">
        <div className="counsel-member-info">
          <Typography span size={15} weight={700}>
            {name}
          </Typography>
          <div className="counsel-member-info-date">
            <Typography className="counsel-member-info-date-text" span textColor="gray2">
              {filters.date(start_on)}
            </Typography>
            <Typography ellipsis={1} span textColor="gray2">
              {staff.name}
            </Typography>
          </div>
        </div>

        <Label size="regular" minWidth={44} textColor="gray2" color="gray7">
          {member ? '회원' : '비회원'}
        </Label>
      </div>
      <div className="counsel-contents-detail">
        <div className="counsel-contents-detail-label">
          <Label size="regular" textColor="secondary2" borderColor="secondary2" opacity={0.64}>
            {COUNSEL_CHANNEL_TEXT[channel]}
          </Label>
          <Label size="regular" textColor="secondary1" borderColor="secondary1" opacity={0.64}>
            {COUNSEL_FUNNEL_TEXT[funnel]}
          </Label>
        </div>
        <Typography textColor="gray2" ellipsis={2}>
          {contents}
        </Typography>
      </div>
    </Container>
  );
};

export default CounselCard;

const Container = styled.div`
  background-color: ${theme.color.white};
  padding: 18px 20px;
  box-shadow: 1px 3px 10px rgba(145, 145, 145, 0.12);
  border-radius: 12px;

  .counsel-member {
    position: relative;
    ${theme.flex('', '', 'space-between')};
    padding-bottom: 14px;
    border-bottom: 1px solid ${theme.color.gray7};

    .label {
      position: absolute;
      top: 0;
      right: 0;
    }

    &-info {
      ${theme.flex('column', 'initial', 'initial', 2)};

      &-date {
        ${theme.flex('', '', '')};

        &-text {
          max-width: 218px;
          padding-right: 13px;
          position: relative;

          &:first-of-type:after {
            content: '';
            width: 1px;
            height: 10px;
            position: absolute;
            top: 50%;
            right: 7px;
            margin-top: -5px;
            background-color: ${theme.color.gray4};
          }
        }
      }
    }

    .label {
      margin-left: auto;
    }
  }

  .counsel-contents-detail {
    padding-top: 14px;

    &-label {
      ${theme.flex('', 'flex-start', '', 4)};
      margin-bottom: 8px;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';

/** 일정, 회원 페이지 등 메인 페이지의 스크롤 되는 페이지에서의 상단 그라데이션 배경 wrapper */
export const MainBackgroundColorWrapper = styled.div`
  background: ${theme.color.mainTopBackground};
`;

/** 일정, 회원 페이지 등 메인 페이지의 스크롤 되는 페이지에서의 Drawer 디자인 같은 테두리 스타일 */
export const MainContentsBorderWrapper = styled.div`
  background-color: ${theme.color.white};
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
`;

/** 일정, 회원 페이지 등 메인 페이지의 스크롤 되는 페이지에서의 상단 sticky 용도의 wrapper */
export const MainStickyTopWrapper = styled(MainContentsBorderWrapper)`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.stickyTab};
  transition: border-radius 0.2s ease;

  &.sticky--at-top {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
`;

/** 상단 sticky 용도의 wrapper */
export const StickyWrapper = styled.div`
  background-color: ${theme.color.white};
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX.stickyTab + 1};
`;

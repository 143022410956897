import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MessageMobileParams } from './usePostMessageMobile';

const usePostMessageMobileCheck = () => {
  const { mutationFn } = useMutationFnWithCommonParams<MessageMobileCheckParams, MessageMobileCheckResponse>({
    method: `post`,
    url: `/api/message/mobile/check`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostMessageMobileCheck;

export type MessageMobileCheckParams = Pick<MessageMobileParams, 'message' | 'users' | 'counseling_logs'>;
export type MessageMobileCheckResponse = {
  message: string | null; // null: 포인트 충분, 그 외: 에러 메시지
  code: number; // 200: 성공, 그 외: 에러코드 (140000: 포인트 부족)
  count: number;
  type: 'SMS' | 'LMS';
  studio_point: number;
  required_point: number;
};

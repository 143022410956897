import { theme } from 'assets/styles';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';

import filters from './filters';
import formatCommonTicketInfo from './formatCommonTicketInfo';
import getDaysDiff from './getDaysDiff';
import getTicketInfoByType from './getTicketInfoByType';

/**
 * 리스트 형식 디자인에 사용되는 회원의 보유 수강권 정보 세팅 함수
 */
const formatSmallUserTicketCard = (userTicket: UserTicketResponse): FormatTicketCard => {
  const {
    id,
    expire_at,
    is_show_cancel_count,
    max_coupon,
    remaining_coupon,
    remaining_cancel,
    booking_limit_per_month,
    booking_limit_per_week,
    ticket,
    user_ticket_status,
  } = userTicket;
  const { ticketColor, classType, convertTicketType, maxTrainee } = formatCommonTicketInfo(userTicket.ticket);

  const { showCoupon } = getTicketInfoByType(ticket.type);
  const totalDays = `${getDaysDiff(expire_at, filters.dateDash())}일`; // 회원 보유 수강권은 잔여일 남은 기간으로 표기
  const maxCoupon = showCoupon ? `전체 ${max_coupon}회` : null;
  const remainingCoupon = !showCoupon || !remaining_coupon ? null : `잔여 ${remaining_coupon}회`;
  const remainingCancel = !showCoupon && !is_show_cancel_count ? null : `취소 ${remaining_cancel}회`;
  const dailyBookingChangeLimit = ticket.daily_booking_change_limit ? `당일변경 ${ticket.daily_booking_change_limit}회` : null;

  let limitText = null;
  if (booking_limit_per_month) {
    limitText = `월 ${booking_limit_per_month}회`;
  }
  if (booking_limit_per_week) {
    limitText = `주 ${booking_limit_per_week}회`;
  }

  let bottomOptions: (string | null)[] = [];
  /** 프라이빗 */
  if (ticket.available_class_type === 'P') {
    if (ticket.type === 'T') {
      // 횟수제 or 차감제
      bottomOptions = [totalDays, remainingCoupon, maxCoupon, remainingCancel];
    }
    if (ticket.type === 'P') {
      // 기간제
      bottomOptions = [totalDays];
    }
  }

  /** 그룹 */
  if (ticket.available_class_type === 'G') {
    if (ticket.type === 'T') {
      // 횟수제
      bottomOptions = [totalDays, dailyBookingChangeLimit, remainingCoupon, maxCoupon, remainingCancel];
    }
    if (ticket.type === 'P') {
      // 기간제
      bottomOptions = [totalDays, dailyBookingChangeLimit, remainingCancel];
    }
  }

  /** 상품 */
  switch (ticket.type) {
    case 'RM': // 혼합
      bottomOptions = [totalDays, maxCoupon];
      break;
    case 'RT': // 횟수제
      bottomOptions = [maxCoupon];
      break;
    case 'RP': // 기간제
      bottomOptions = [totalDays];
      break;
    case 'S': // 판매
      bottomOptions = [];
      break;
  }

  return {
    id: `${id}`,
    title: ticket.title,
    ticketColor:
      user_ticket_status === 'using' || user_ticket_status === 'to_use' ? ticketColor : [theme.color.gray3, theme.color.gray3],
    topOptions: [classType, convertTicketType, maxTrainee, limitText],
    bottomOptions,
  };
};

export default formatSmallUserTicketCard;

export const getCurrentUserTicketInfo = (userTicket: UserTicketResponse) => {
  const { is_show_cancel_count, ticket, max_coupon, remaining_coupon, remaining_cancel } = userTicket;
  const { showCoupon, showPeriod } = getTicketInfoByType(ticket.type);

  const totalDays = showPeriod ? `${ticket.available_period}일` : null;
  const maxCoupon = showCoupon ? `${max_coupon}회` : null;
  const dailyBookingChangeLimit = ticket.daily_booking_change_limit ? `당일변경 ${ticket.daily_booking_change_limit}회` : null;
  const remainingCoupon = !showCoupon || !remaining_coupon ? null : `잔여 ${remaining_coupon}회`;
  const remainingCancel = !showCoupon && !is_show_cancel_count ? null : `취소 ${remaining_cancel}회`;

  return {
    totalDays,
    maxCoupon,
    dailyBookingChangeLimit,
    remainingCancel,
    remainingCoupon,
  };
};

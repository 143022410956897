import Button, { ButtonProps } from 'components/Button';
import React, { useCallback } from 'react';

import { TabDataElementType } from '.';

export type TabBaseProps = {
  data: TabDataElementType;
  onClick: (tabData: TabDataElementType) => void;
} & Omit<ButtonProps, 'onClick' | 'onChange' | 'value' | 'children'>;

const TabBase = React.forwardRef<HTMLButtonElement, TabBaseProps>(({ data, onClick, ...props }, ref) => {
  const handleClick = useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  return (
    <Button role="tab" ref={ref} onClick={handleClick} {...props}>
      {data.label}
    </Button>
  );
});

TabBase.displayName = 'TabBase';
export default TabBase;

import { useSuspenseQuery } from '@tanstack/react-query';
import { StaffType } from 'types/staffTypes';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

type Props = {
  targetStaffId: number;
};

const useGetStaff = ({ targetStaffId }: Props) => {
  const { queryFn } = useQueryFnWithCommonParams<StaffResponse>({
    url: `/api/staff/${targetStaffId}`,
  });

  return useSuspenseQuery({
    queryKey: ['staff', targetStaffId],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetStaff;

type StaffResponse = {
  data: StaffType;
};

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePatchStudioUnSync = () => {
  const { mutationFn } = useMutationFnWithCommonParams<StudioUnSyncParams, StudioUnSyncResponse>({
    method: 'patch',
    url: '/api/studio/un-sync',
    data: { staff_id: undefined },
  });
  const queries = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queries.invalidateQueries({ queryKey: ['studio'] });
    },
  });
};

export default usePatchStudioUnSync;

type StudioUnSyncParams = {
  studio_id: number;
};

type StudioUnSyncResponse = {
  message: string;
};

import instance from 'api/axios';
import { AxiosResponse } from 'axios';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

/**
 * 공통 파라미터인 studio_id, staff_id 를 포함한 queryFn 반환 hook.
 * studio_id, staff_id 중 포함되지 않아도 되는 값에는
 * props의 params에 undefined로 추가.
 */
const useQueryFnWithCommonParams = <TResponse, TParams = undefined>(axiosInfo: { url: string; params?: TParams }) => {
  const studio_id = useRecoilValue(studioIdAtom);
  const staff_id = useRecoilValue(staffIdAtom);

  const queryFn: () => Promise<AxiosResponse<TResponse>> = async () => {
    const { url, params } = axiosInfo;
    return await instance({
      method: 'get',
      url,
      params: {
        studio_id,
        staff_id,
        ...params,
      },
    });
  };

  return { queryFn };
};

export default useQueryFnWithCommonParams;

import styled from '@emotion/styled';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { BOOKING_PRIVATE_FORM_TEXT, LECTURE_SIZE_CHECK_RADIO_DATA } from 'pages/Booking/Private/constants';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

type Props = {
  disabled?: boolean;
};

const LectureSize = ({ disabled }: Props) => {
  const { control, setValue } = useFormContext<BookingPrivateFormType>();
  const lectureSize = useWatch({ control, name: 'lectureSize' });
  const [isOpen, setIsOpen] = useState(false);

  const label = useMemo(() => {
    if (!lectureSize) {
      return BOOKING_PRIVATE_FORM_TEXT.lectureSize.placeholder;
    }
    return LECTURE_SIZE_CHECK_RADIO_DATA.find(item => item.value === lectureSize)?.label;
  }, [lectureSize]);

  return (
    <>
      <StyledFormFieldLinkButton iconName="number" hasValue={!!lectureSize} onClick={() => setIsOpen(true)} disabled={disabled}>
        {label}
      </StyledFormFieldLinkButton>
      <CheckRadioDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        name="lectureSize"
        control={control}
        header={BOOKING_PRIVATE_FORM_TEXT.lectureSize.drawer.title}
        data={LECTURE_SIZE_CHECK_RADIO_DATA}
        onClick={() => {
          setValue('selectedUserTickets', []);
          setValue('userTicketIds', []);
          setValue('title', '');
        }}
      />
    </>
  );
};

export default LectureSize;

const StyledFormFieldLinkButton = styled(FormFieldLinkButton)<{ disabled?: boolean }>`
  .link-button-arrow-wrapper {
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
  }
`;

import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  roleId: number;
};

const usePatchRole = ({ roleId }: Props) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<RoleParams>({
    method: 'patch',
    url: `/api/role/${roleId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['role'],
      });
    },
  });
};

export default usePatchRole;

type RoleParams = {
  display_name: string;
};

import styled from '@emotion/styled';
import { ReactComponent as Logo } from 'assets/images/logo_type_bottom-text.svg';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { BACK_PRESS_INTERVAL } from 'hooks/useHardwareBackKey';
import useStatusBarColor from 'hooks/useStatusBarColor';
import useToast from 'hooks/useToast';
import { useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import { postMessageToApp } from 'utils/communicateWithNative';
import localStorage from 'utils/localStorage';

const Intro = () => {
  useStatusBarColor(theme.color.white);

  const navigate = useNavigate();

  const lastPressedTime = useRef<number>(0);
  const setCustomBackHandlers = useSetRecoilState(customBackHandlersAtom);

  const { setToast } = useToast();

  useEffect(() => localStorage.clear(), []);

  useEffect(() => {
    setCustomBackHandlers(prev => [
      ...prev,
      {
        key: 'intro-back',
        handler: () => {
          const isPressedDouble = Date.now() - lastPressedTime.current < BACK_PRESS_INTERVAL;
          if (isPressedDouble) {
            postMessageToApp('EXIT_APP');
            return;
          }

          setToast({ message: '두번 연속으로 누르면 앱이 종료됩니다.', bottom: 72 });
          lastPressedTime.current = Date.now();
        },
      },
    ]);
    return () => {
      setCustomBackHandlers(prev => prev.filter(({ key }) => key !== 'intro-back'));
    };
  }, [setCustomBackHandlers, setToast]);

  const goLink = (url: string) => {
    navigate(url);
  };

  const commonButtonProps: Omit<ButtonProps, 'children'> = useMemo(() => {
    return {
      variant: 'contained',
      fontSize: 15,
      size: 'large56',
      fullWidth: true,
    };
  }, []);

  return (
    <Container>
      <section className="logo-wrapper">
        <Logo />
      </section>
      <section className="bottom-wrapper">
        <ButtonGroup>
          <Button color="gray" onClick={() => goLink('/join?step=0')} {...commonButtonProps}>
            회원가입
          </Button>
          <Button color="point" onClick={() => goLink('/login')} {...commonButtonProps}>
            로그인
          </Button>
        </ButtonGroup>

        <Button fontSize={15} textUnderline onClick={() => navigate('/lounge-temp')}>
          로그인 없이 둘러보기
        </Button>
      </section>
    </Container>
  );
};

export default Intro;

const Container = styled.div`
  ${theme.flex('column')}
  padding: 0 20px 24px;
  height: 100vh;

  .logo-wrapper {
    ${theme.flex()}
    flex: 1;
  }

  .bottom-wrapper {
    ${theme.flex('column', '', '', 24)}
    width: 100%;
  }
`;

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/Button';
import Form from 'components/Form';
import SubTitle from 'components/SubTitle';
import usePostAuthCheckCurrentPassword from 'hooks/service/mutations/usePostAuthCheckCurrentPassword';
import { useForm } from 'react-hook-form';
import { CustomError } from 'types/errorTypes';
import { password } from 'utils/validate';
import { object } from 'yup';

import { CHANGE_PASSWORD_TEXT } from '../constants';
import PasswordFieldController from './PasswordFieldController';

type Props = {
  onSuccess: (password: string) => void;
};

const yupSchema = object().shape({ password });

const CurrentPasswordForm = ({ onSuccess }: Props) => {
  const { control, formState, handleSubmit, setError } = useForm<{ password: string }>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
    },
  });

  const { mutate: validateMutate, isPending } = usePostAuthCheckCurrentPassword();

  const validatePassword = (data: { password: string }) => {
    const params = { current_password: data.password };
    validateMutate(params, {
      onSuccess: () => {
        onSuccess(data.password);
      },
      onError: (error: CustomError) => {
        setError('password', { message: error?.response?.data?.message });
      },
    });
  };

  return (
    <>
      <SubTitle
        title={CHANGE_PASSWORD_TEXT.current.title}
        message={{ position: 'bottom', text: CHANGE_PASSWORD_TEXT.current.description }}
        bottom={40}
      />
      <Form onSubmit={handleSubmit(validatePassword)}>
        <PasswordFieldController
          controllerProps={{ control, name: 'password' }}
          placeholder={CHANGE_PASSWORD_TEXT.current.placeholder}
        />
        <StyledButton
          fullWidth
          type="submit"
          size="medium48"
          variant="contained"
          color="point"
          disabled={!formState.isValid || formState.isSubmitting || isPending}>
          {CHANGE_PASSWORD_TEXT.current.submit}
        </StyledButton>
      </Form>
    </>
  );
};

export default CurrentPasswordForm;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { ButtonProps } from 'components/Button';
import Divider from 'components/Divider';
import Form from 'components/Form';
import Icon from 'components/Icon';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import {
  Birthday,
  Gender,
  GetNotiAll,
  Mobile,
  Profile,
  RegisteredDate,
  RepresentativeColor,
  Role,
} from 'sharedComponents/ProfileField';

import { STAFF_PROFILE_FORM_TEXT } from '../constants';

type Props = {
  onSubmit: () => void;
  footerButton: Omit<ButtonProps, 'children'> & { text: string };
};

const StaffInfoForm = (props: Props) => {
  const { pathname } = useLocation();

  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  const { control, setValue } = useFormContext();

  const { setSearchParams, getAllSearchParams } = useQueryString();

  return (
    <Container {...props} isKeyboardShow={isKeyboardShow}>
      <InfoText size={13} weight={500} textColor="secondary3" opacity={0.8}>
        {STAFF_PROFILE_FORM_TEXT.subTitle.required}
      </InfoText>

      {pathname.includes('create-form') ? (
        <Profile
          className="profile-section"
          label="강사명"
          onClick={() => setSearchParams({ ...getAllSearchParams(), type: 'profile' })}
          cautionText={false}
        />
      ) : (
        <UnderlinedTextFieldController
          controllerProps={{ control, name: 'profile.name' }}
          placeholder={STAFF_PROFILE_FORM_TEXT.placeholer.profile}
          left={{ prefix: <Icon name="privateClass" fillColor={theme.color.gray2} /> }}
          onClear={() => setValue('profile.name', '', { shouldDirty: true })}
          required
        />
      )}

      <RegisteredDate />

      <Role />
      <RepresentativeColor />

      <Divider />

      <InfoText isOptional={true} size={13} weight={500} textColor="gray3">
        {STAFF_PROFILE_FORM_TEXT.subTitle.optional}
      </InfoText>

      <Mobile desc={STAFF_PROFILE_FORM_TEXT.desc.mobile} />
      <Gender />
      <Birthday />

      <div className="get-noti-all-wrapper">
        <GetNotiAll />
      </div>
    </Container>
  );
};

export default StaffInfoForm;

const Container = styled(Form)<{ isKeyboardShow: boolean }>`
  margin-top: 16px;
  padding-bottom: calc(76px + 40px);

  /** Profile 컴포넌트 커스텀 용도 */
  .profile-section > .link-button {
    padding: 0 20px;
  }
  .get-noti-all-wrapper {
    ${theme.flex()};
    padding: 0 20px;
    height: 56px;
  }

  .bottom-wrapper {
    ${({ isKeyboardShow }) => isKeyboardShow && 'padding: 10px 20px'};
  }
`;

const InfoText = styled(Typography)<{ isOptional?: boolean }>(
  css`
    margin-bottom: 4px;
    padding-left: 20px;
  `,

  ({ isOptional }) =>
    isOptional &&
    css`
      margin-top: 16px;
    `,
);

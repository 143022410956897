import useGetEtcScheduleEtcScheduleTime from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';

import CopyForm from './CopyForm';
import UpdateForm from './UpdateForm';

type Props = {
  currentEtcScheduleId: number;
  mode: 'copy' | 'update';
};

const EtcIndividual = ({ currentEtcScheduleId, mode }: Props) => {
  const { data: staff } = useGetStaffMe();
  const { data } = useGetEtcScheduleEtcScheduleTime(currentEtcScheduleId);

  return <>{mode === 'update' ? <UpdateForm data={data} staff={staff} /> : <CopyForm data={data} staff={staff} />}</>;
};

export default EtcIndividual;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';

import StudioSelectDrawer from './drawer/StudioSelectDrawer';

type Props = {
  openSettingDrawer: () => void;
};

const ScheduleHeader = ({ openSettingDrawer }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const currentStudioId = useRecoilValue(studioIdAtom);

  const {
    data: { studios },
  } = useGetStudio();

  const currentStudioName = studios.filter(studio => studio.id === currentStudioId)[0].name;

  const openStudioSelectDrawer = () => setIsDrawerOpen(true);

  return (
    <>
      <Container>
        <Button
          fontSize={15}
          fontWeight={600}
          textColor="white"
          rightIcon={<Icon name="arrowBottomFill" fillColor={theme.color.white} size={16} />}
          onClick={openStudioSelectDrawer}>
          {currentStudioName}
        </Button>

        <div className="setting-button">
          <IconButton onClick={openSettingDrawer}>
            <Icon name="headerSetting" color="white" />
          </IconButton>
        </div>
      </Container>

      <StudioSelectDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} studios={studios} />
    </>
  );
};

export default ScheduleHeader;

const Container = styled.section`
  position: sticky;
  top: 0;
  ${theme.flex()};
  padding: 17px 20px;

  .setting-button {
    position: absolute;
    right: 20px;
  }
`;

import styled from '@emotion/styled';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker, localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import { hexToRgba } from 'utils/styles';

const MonthPicker = ({ ...props }: MbscDatepickerOptions) => {
  const renderCalendarHeader = () => {
    return (
      <>
        <div className="month-picker-arrow">
          <Icon name="arrowLeft" fillColor={theme.color.gray2} className="month-picker-arrow-icon" />
          <CalendarPrev className="month-picker-arrow-button prev" />
        </div>
        <div className="month-picker-year">
          <div className="year-button-hidden" />
          <CalendarNav className="month-picker-year-nav" />
        </div>
        <div className="month-picker-arrow">
          <CalendarNext className="month-picker-arrow-button next" />
          <Icon name="arrowRight" fillColor={theme.color.gray2} className="month-picker-arrow-icon" />
        </div>
      </>
    );
  };

  return (
    <Container className="month-picker">
      <Datepicker
        {...props}
        theme="ios"
        themeVariant="light"
        touchUi={true}
        locale={localeKo}
        display="inline"
        dateFormat="MM/YY"
        max={dayjs().format('YYYY-MM-DD')}
        renderCalendarHeader={renderCalendarHeader}
      />
    </Container>
  );
};

export default MonthPicker;

const Container = styled.div`
  position: relative;
  z-index: 1;

  .mbsc-ios.mbsc-datepicker-inline {
    border: none;
  }

  /** 헤더와 월별 선택 wrapper */
  .mbsc-ios.mbsc-calendar-wrapper {
    gap: 32px;
  }
  .mbsc-datepicker .mbsc-calendar {
    padding: 0 0 32px;
  }

  /** 연도 이동 화살표, 연도 텍스트 있는 header */
  .mbsc-calendar-header {
    height: auto;

    .mbsc-ios.mbsc-calendar-controls {
      z-index: 2;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      min-height: unset;

      .month-picker-year {
        width: 80px;
        height: 26px;

        .month-picker-year-nav {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;

          .mbsc-calendar-title {
            font-size: 1.9rem;
            line-height: 26px;
            font-weight: 700;
            color: ${theme.color.gray1};
          }
        }

        // 연도 텍스트 버튼 클릭이 가능해서 이벤트 실행 방지를 목적으로 위에 덮음
        .year-button-hidden {
          position: absolute;
          top: 2px;
          left: 50%;
          margin-left: -40px;
          z-index: 3;
          background-color: transparent;
          width: 80px;
          height: 24px;
        }
      }

      .month-picker-arrow {
        position: relative;
        width: 24px;
        height: 24px;

        .month-picker-arrow-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .month-picker-arrow-button {
          position: absolute;
          top: 0;
          padding: 0;
          background-color: transparent;
          height: 24px;

          &.prev {
            left: 0;
          }
          &.next {
            right: 0;
          }
          &.mbsc-disabled + svg {
            opacity: 0.32;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  /** 월 선택 wrapper */
  .mbsc-datepicker .mbsc-calendar-slide {
    padding: 0 20px;
  }
  .mbsc-flex-col {
    gap: 20px;
  }
  .mbsc-calendar-row {
    gap: 16px;
  }
  .mbsc-calendar-month-inner {
    height: 40px;
    width: 100%;
    padding: 0;
  }
  .mbsc-calendar-month-text {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    font-size: 1.7rem;
    font-weight: 500;
  }
  .mbsc-selected {
    .mbsc-calendar-month-text {
      font-weight: 600;
      color: ${theme.color.primary};
      border-color: ${() => hexToRgba(theme.color.primary, 0.12)};
      background: ${() => hexToRgba(theme.color.primary, 0.12)};
    }
  }
  .mbsc-disabled {
    .mbsc-calendar-month-text {
      color: ${theme.color.gray4};
      opacity: 1;
    }
  }
`;

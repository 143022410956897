import { StaffWorkSchedulesResponse } from 'hooks/service/queries/useGetStaffWorkSchedules';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';

/** 기존과 완전히 다른 구조로 가공하며, 특정일 근무시간 수정 페이지에서 사용됨 */
const getWorkTimeDatasetNew = ({ work_time, rest_time }: StaffWorkSchedulesResponse) => {
  /** 휴일 가공 */
  const closeTimes = rest_time.filter(restTime => {
    return restTime.start_time === CLOSED_DAY_TIME.start && restTime.end_time === CLOSED_DAY_TIME.end;
  });

  /** 휴무일을 판단할 수 있는 부분이 따로 없고, start_time, end_time 고정값으로 판단해야함 */
  const resultWorkTimes = work_time.map(({ day_of_week, end_time, start_time, id, type }) => {
    const restTimes = rest_time.filter(restTime => restTime.day_of_week === day_of_week);
    return {
      day_of_week,
      end_time,
      start_time,
      id,
      type,
      restTimes: restTimes.map(({ created_at, updated_at, ...rest }) => {
        return rest;
      }),
    };
  });

  return [...resultWorkTimes, ...closeTimes];
};

export default getWorkTimeDatasetNew;

import Dialog, { IDialogProps } from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useDeleteMember from 'hooks/service/mutations/useDeleteMember';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentMemberUserTicketsAtom } from 'recoil/MemberDetail';
import { CustomError } from 'types/errorTypes';

import { MEMBER_DETAIL_MAIN_TEXT } from '../constants';
import { CurrentMemberIdProps } from '../types';

type Props = CurrentMemberIdProps & {
  onClose: () => void;
};

const DeleteMemberDialog = ({ currentMemberId, onClose }: Props) => {
  const currentMemberUserTickets = useRecoilValue(currentMemberUserTicketsAtom);
  const hasTickets = !!currentMemberUserTickets.length;

  const { mutate: deleteMemberMutate, isPending } = useDeleteMember(currentMemberId);
  const { setToast } = useToast();
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();

  const deleteMember = useCallback(() => {
    deleteMemberMutate(undefined, {
      onSettled: onClose,
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: MEMBER_DETAIL_MAIN_TEXT.deleteDialog.successMessage });
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  }, [deleteMemberMutate, errorDialog, navigate, onClose, setToast]);

  const dialogProps: Omit<IDialogProps, 'children' | 'onClose'> = useMemo(() => {
    if (hasTickets) {
      return {
        isError: true,
        positiveAction: { text: '확인', onClick: onClose },
      };
    } else {
      return {
        title: MEMBER_DETAIL_MAIN_TEXT.deleteDialog.title,
        negativeAction: { text: '취소' },
        positiveAction: { text: '삭제', onClick: deleteMember, disabled: isPending },
      };
    }
  }, [deleteMember, hasTickets, isPending, onClose]);

  return (
    <Dialog {...dialogProps} onClose={onClose}>
      <DialogDescription>
        {hasTickets ? (
          <>
            사용중인 수강권/상품이 있어
            <br />
            회원을 삭제할 수 없습니다.
          </>
        ) : (
          '회원을 삭제하면 복구할 수 없습니다.'
        )}
      </DialogDescription>
    </Dialog>
  );
};

export default DeleteMemberDialog;

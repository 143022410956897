import { UserTicketProps } from 'pages/TicketDetail/types';
import filters from 'utils/filters';

type Response = {
  label: string;
  value: string;
};

/** 수강권 업그레이드 > 현재 수강권 정보 세팅 */
const getUpgradeCurrentTicketInfo = ({ userTicket }: UserTicketProps): Array<Response> => {
  const {
    availability_start_at,
    expire_at,
    remaining_cancel,
    remaining_coupon,
    max_coupon,
    is_show_cancel_count,
    ticket: { daily_booking_change_limit, type },
  } = userTicket;

  /**
   * 취소 가능 횟수 노출 여부
   * - 횟수제(차감제): 노출
   * - 기간제: 수강권 생성 시, 취소 가능 횟수 노출 체크 했을 경우 노출
   */
  const isShowCancelCount = !(type === 'P' && !is_show_cancel_count);

  /** 예약 취소 가능 횟수, 당일 예약 변경 횟수는 없으면 '사용안함' 표시 */
  const remainingCancel = formatValue(isShowCancelCount ? min999(remaining_cancel) : undefined);
  const dailyBookingChangeLimit = formatValue(daily_booking_change_limit);

  const array = [
    { label: '기간', value: `${filters.period(availability_start_at, expire_at)}` },
    { label: '잔여횟수', value: `${remaining_coupon}회` },
    { label: '전체횟수', value: `${max_coupon}회` },
    { label: '예약취소 가능 횟수', value: remainingCancel },
    {
      label: '당일 예약 변경 가능 횟수',
      value: dailyBookingChangeLimit,
    },
  ];

  /** 기간제는 횟수 정보 제외(잔여횟수, 전체횟수) */
  if (type === 'P') {
    array.splice(1, 2);
  }

  return array;
};
export default getUpgradeCurrentTicketInfo;

/** 횟수 정보가 999회 넘을 수 없음 */
export const min999 = (num: number) => {
  return Math.min(num, 999);
};

export const formatValue = (value?: number) => {
  return value ? `${value}회` : '사용안함';
};

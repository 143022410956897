import styled from '@emotion/styled';
import Button from 'components/Button';
import { BottomWrapper } from 'components/Form';
import { AgreeDataType, AgreeType } from 'pages/Join/types';
import { Dispatch, SetStateAction, UIEvent, useMemo, useState } from 'react';

import PrivacyPolicyDetail from './PrivacyPolicyDetail';
import TermsOfServiceDetail from './TermOfServiceDetail';

type Props = {
  /**
   * 이용약관 or 개인정보 처리방침
   */
  type: AgreeType;
  /**
   * 이용약관 동의 여부
   */
  setAgreeData: Dispatch<SetStateAction<AgreeDataType>>;
  /**
   * 뒤로가기 함수
   */
  prevStep: () => void;
};

const AgreeDetail = ({ type, prevStep, setAgreeData }: Props) => {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 100) setIsBottom(true);
    else setIsBottom(false);
  };

  const isTerms = type === 'TERMS_OF_SERVICE';

  const handleAgreeCheck = () => {
    if (isTerms) {
      setAgreeData(prev => ({ ...prev, terms: true }));
    } else {
      setAgreeData(prev => ({ ...prev, privacy: true }));
    }

    return prevStep();
  };

  const scrollToBottom = () => {
    const target = document.getElementById('paper');
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const bottomButtonText = useMemo(() => (isBottom ? '약관에 동의합니다' : '내용 확인 스크롤'), [isBottom]);

  return (
    <Container onScroll={handleScroll}>
      {isTerms ? <TermsOfServiceDetail /> : <PrivacyPolicyDetail />}

      <BottomWrapper>
        <Button
          variant="contained"
          color="point"
          fullWidth={true}
          size="large56"
          fontSize={15}
          onClick={isBottom ? handleAgreeCheck : scrollToBottom}>
          {bottomButtonText}
        </Button>
      </BottomWrapper>
    </Container>
  );
};

export default AgreeDetail;

const Container = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CALENDER_SETTING_MENUS = {
  defaultViewType: [
    { id: 'week', label: '주간', value: 'week' },
    { id: 'day', label: '일간', value: 'day' },
    { id: 'month', label: '월간', value: 'month' },
  ],
  dayRange: [
    { id: 'monday', label: '월요일', value: 1 },
    { id: 'tuesday', label: '화요일', value: 2 },
    { id: 'wednesday', label: '수요일', value: 3 },
    { id: 'thursday', label: '목요일', value: 4 },
    { id: 'friday', label: '금요일', value: 5 },
    { id: 'saturday', label: '토요일', value: 6 },
    { id: 'sunday', label: '일요일', value: 0 },
  ],
  closedClassBackground: [
    { id: 'private', label: '프라이빗 수업', value: 'P' },
    { id: 'group', label: '그룹 수업', value: 'G' },
  ],
};

export const CALENDAR_SETTING_TEXT = {
  main: {
    header: '캘린더 설정',
    toastMessage: '캘린더 설정이 완료되었습니다.',
  },
  timeRange: {
    title: '시간',
    tooltipMessage: ['일간/주간 달력에 적용되는', '설정입니다.'],
  },
  defaultViewType: {
    title: '기본 캘린더',
    tooltipMessage: ['앱 실행 시 사용할 기본 캘린더를', '선택합니다.'],
  },
  dayRange: {
    title: '요일',
    tooltipMessage: ['주간 달력에 적용되는 설정입니다.'],
  },
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';

type Props = {
  disabled: boolean;
};

const SubmitButton = ({ disabled }: Props) => {
  return (
    <BottomWrapper>
      <Button
        form="name-nickname-form"
        type="submit"
        color="point"
        fontSize={15}
        fullWidth
        size="medium48"
        variant="contained"
        disabled={disabled}>
        저장
      </Button>
    </BottomWrapper>
  );
};

export default SubmitButton;

const BottomWrapper = styled.div`
  padding: 16px 0 0;
  width: 100%;
  background: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};
`;

import MoreButtonListDrawer from 'pages/Booking/Detail/components/LectureInfo/MoreButtonListDrawer';
import { ETC_DETAIL_UPDATE_DRAWER_LIST } from 'pages/EtcSchedule/constants';

type Props = {
  isOpen: boolean;
  isBulk: boolean;
  onClose: () => void;
  goEtcUpdateForm: (mode: string) => void;
};

const EtcUpdateListDrawer = ({ isBulk, isOpen, onClose, goEtcUpdateForm }: Props) => {
  return (
    <MoreButtonListDrawer
      buttonList={
        isBulk ? ETC_DETAIL_UPDATE_DRAWER_LIST.filter(item => item.value === 'update-all') : ETC_DETAIL_UPDATE_DRAWER_LIST
      }
      onClick={goEtcUpdateForm}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default EtcUpdateListDrawer;

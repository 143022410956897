import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { registerDataAtom } from 'recoil/join';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import AgreeStep from './components/AgreeStep';
import BasicInfoStep from './components/BasicInfoStep';
import MobileStep from './components/MobileStep';
import PasswordStep from './components/PasswordStep';
import { HEADER_TITLE } from './constants';
import { AgreeDetailType } from './types';

const Join = () => {
  const navigate = useNavigate();

  const [agreeDetail, setAgreeDetail] = useState<AgreeDetailType>({
    type: 'TERMS_OF_SERVICE',
    show: false,
  });

  const setRegisterData = useSetRecoilState(registerDataAtom);

  const { getSearchParams, setSearchParams } = useQueryString();

  /** 0: '이용약관', 1: '휴대폰번호', 2: '비밀번호', 3: '기본정보' */
  const currentStep = Number(getSearchParams('step'));

  const prevStep = useCallback(() => {
    if (agreeDetail.show) setAgreeDetail(prev => ({ ...prev, show: false }));
    else navigate(-1);
  }, [agreeDetail, navigate]);

  const leftButton = useMemo(() => {
    return (
      <IconButton onClick={prevStep}>
        <Icon name="headerBack" />
      </IconButton>
    );
  }, [prevStep]);

  const headerOptions = useMemo(() => {
    if (currentStep === 0) {
      return {
        title: agreeDetail.show ? HEADER_TITLE[agreeDetail.type] : '',
        leftButton,
      };
    }
    return { leftButton };
  }, [currentStep, leftButton, agreeDetail]);

  return (
    <MainLayout header={headerOptions} progress={{ max: 3, step: currentStep }}>
      <Container currentStep={currentStep}>
        {currentStep === 0 && (
          <AgreeStep
            agreeDetail={agreeDetail}
            openAgreeDetail={type => {
              setAgreeDetail({ type, show: true });
            }}
            nextStep={({ terms, privacy }) => {
              if (!terms || !privacy) return;
              setSearchParams({ step: String(1) });
            }}
            prevStep={prevStep}
          />
        )}

        {currentStep === 1 && (
          <ApiBoundary>
            <MobileStep
              nextStep={data => {
                setRegisterData(prev => ({ ...prev, ...data }));
                setSearchParams({ step: String(2) });
              }}
            />
          </ApiBoundary>
        )}

        {currentStep === 2 && (
          <PasswordStep
            nextStep={data => {
              setRegisterData(prev => ({ ...prev, ...data }));
              setSearchParams({ step: String(3) });
            }}
          />
        )}

        {currentStep === 3 && (
          <ApiBoundary>
            <BasicInfoStep />
          </ApiBoundary>
        )}
      </Container>
    </MainLayout>
  );
};

export default Join;

const Container = styled.div<{ currentStep: number }>(
  css`
    padding: 8px 20px 0;
    position: relative;
    height: 100%;
  `,

  ({ currentStep }) => {
    const gap = currentStep === 3 ? 32 : 8;
    return css`
      form {
        ${theme.flex('column', '', '', gap)};
      }
    `;
  },
);

import Divider from 'components/Divider';
import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MultiStaffFilter from '../MultiStaffFilter';
import SchedulePeriodFilter from '../SchedulePeriodFilter';

const DeletedLectureWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  return (
    <>
      <SchedulePeriodFilter
        currentFilter={filters[0]}
        selectedOption={get(filterChanged, filters[0].key)}
        onClick={changeOption}
      />
      <Divider />
      <ApiBoundary>
        <MultiStaffFilter currentFilter={filters[1]} selectedOption={get(filterChanged, filters[1].key)} onClick={changeOption} />
      </ApiBoundary>
      <Divider />
      <ButtonFilter currentFilter={filters[2]} selectedOption={get(filterChanged, filters[2].key)} onClick={changeOption} />
    </>
  );
};

export default DeletedLectureWholeFilterOptions;

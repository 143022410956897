import Divider from 'components/Divider';
import { TICKET_TRANSFER_TEXT } from 'pages/TicketDetail/constants';
import { TicketTransferFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import {
  Card,
  Cash,
  Installment,
  PaymentFormTitle,
  PaymentSubmitButton,
  SettlementDate,
  Unpaid,
  WireTransfer,
} from 'sharedComponents/TicketForm';
import { StyledPaymentFormContainer } from 'sharedComponents/TicketForm/StyledComponents';

import FoldingTicketPaymentCard from '../../FoldingTicketPaymentCard';
import TicketSubTitle from '../../TicketSubTitle';
import SelectedMemberPoint from './SelectedMemberPoint';

type Props = UserTicketProps & {
  productTypeText: string;
  onClickNextPage: () => void;
};

const PaymentForm = ({ userTicket, productTypeText, onClickNextPage }: Props) => {
  const { control } = useFormContext<TicketTransferFormType>();
  const selectedMember = useWatch({ control, name: 'selectedMember' });

  return (
    <StyledPaymentFormContainer>
      <TicketSubTitle
        title={TICKET_TRANSFER_TEXT.title[2]}
        message={`${TICKET_TRANSFER_TEXT.subTitle[2][0]} ${productTypeText}${TICKET_TRANSFER_TEXT.subTitle[2][1]}`}
      />
      <FoldingTicketPaymentCard userTicket={userTicket} isTransfer />
      <Divider />
      <PaymentFormTitle size={14}>양도금액</PaymentFormTitle>
      <Card />
      <Installment />
      <Cash />
      <WireTransfer />

      {selectedMember && (
        <ApiBoundary>
          <SelectedMemberPoint selectedMember={selectedMember} />
        </ApiBoundary>
      )}

      <Divider />
      <Unpaid />
      <SettlementDate title={TICKET_TRANSFER_TEXT.form.payment.settlementDate} />

      <PaymentSubmitButton text={TICKET_TRANSFER_TEXT.form.payment.footerButton} onClick={onClickNextPage}>
        다음
      </PaymentSubmitButton>
    </StyledPaymentFormContainer>
  );
};

export default PaymentForm;

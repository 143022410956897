import { TypographyProps } from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import FieldButton from 'pages/Booking/components/AvailableTime/FieldButton';

type Props = {
  dailyChangeTime: TypographyProps['children'];
  autoCloseTime?: TypographyProps['children'];
  autoWaitingTime?: TypographyProps['children'];
};

/** 당일 예약변경 가능시간/폐강시간/예약대기 자동 예약시간 폼 필드 */
const OthersAvailableField = ({ dailyChangeTime, autoCloseTime, autoWaitingTime }: Props) => {
  const { getAllSearchParams, setSearchParams } = useQueryString();
  const clickField = (detail: 'dailyChange' | 'autoClose' | 'waiting') => {
    setSearchParams({
      ...getAllSearchParams(),
      type: 'otherDetailTime',
      detail,
    });
  };

  return (
    <>
      <FieldButton showIcon label="당일 예약변경 가능 시간" time={dailyChangeTime} onClick={() => clickField('dailyChange')} />
      {autoCloseTime && <FieldButton label="폐강시간" time={autoCloseTime} onClick={() => clickField('autoClose')} />}
      {autoWaitingTime && (
        <FieldButton label="예약대기 자동 예약 시간" time={autoWaitingTime} onClick={() => clickField('waiting')} />
      )}
    </>
  );
};

export default OthersAvailableField;

import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import SelectStudioLayout from './components/SelectStudioLayout';

const SelectStudio = () => {
  return (
    <ApiBoundary>
      <SelectStudioLayout />
    </ApiBoundary>
  );
};
export default SelectStudio;

import Button from 'components/Button';

type Props = {
  onClick: () => void;
  buttonText?: string;
};

const ModifyButton = ({ onClick, buttonText = '수정' }: Props) => {
  const isModify = buttonText === '수정';
  return (
    <Button
      variant={isModify ? 'outlined' : 'contained'}
      size="tiny"
      padding={{ left: 8, right: 8 }}
      edge="circular"
      color="gray"
      textColor="gray2"
      onClick={onClick}>
      {buttonText}
    </Button>
  );
};

export default ModifyButton;

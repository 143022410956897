import { deletedLectureParamsAtom } from 'recoil/moreDetail/atoms';

import { DEFAULT_DELETED_CLASS_FILTER_OPTIONS, DEFAULT_DELETED_CLASS_FILTER_TABS, DEFAULT_SCHEDULE_FILTER } from '../../constant';
import ScheduleCommonFilter from '../ScheduleCommonFilter';
import DeletedClassDrawerFilterOptions from './DeletedLectureDrawerFilterOptions';
import DeletedClassWholeFilterOptions from './DeletedLectureWholeFilterOptions';

const DeletedLectureFilter = () => {
  return (
    <ScheduleCommonFilter
      filterAtom={deletedLectureParamsAtom}
      filters={DEFAULT_DELETED_CLASS_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_DELETED_CLASS_FILTER_TABS}
      defaultFilter={DEFAULT_SCHEDULE_FILTER}
      renderDrawerOptions={DeletedClassDrawerFilterOptions}
      renderWholeFilterPopupOptions={DeletedClassWholeFilterOptions}
    />
  );
};

export default DeletedLectureFilter;

import dayjs from 'dayjs';
import DateTimePickerDrawer from 'designedComponents/Pickers/DateTimePicker/DateTimePickerDrawer';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { MessageReservationType } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

/** 메세지 예약발송 시간 설정 Drawer */
const SelectMessageReservationDateDrawer = ({ isOpen, onClose }: Props) => {
  const { control, setValue } = useFormContext<MessageReservationType>();
  const reservationDate = useWatch({ control, name: 'reservationDate' });
  const [tempDate, setTempDate] = useState(reservationDate);

  /**
   * 현 시각부터 10분 후를 반올림하여 5분 단위로 설정
   * 예시) 현시각 16:21 ~ 16:25이면 16:35로 자동입력
   */
  const formatAfter10min = () => {
    const tenMinutesLater = dayjs().add(10, 'minute');
    const nextFiveMinutes = Math.ceil(tenMinutesLater.minute() / 5) * 5;
    return tenMinutesLater.minute(nextFiveMinutes).second(0);
  };

  /** 초기화 */
  useEffect(() => {
    if (reservationDate) return;
    if (isOpen) {
      const reservationDate = formatAfter10min();
      setTempDate(filters.dateDashTimeSecond(reservationDate));
    }
  }, [isOpen, reservationDate]);

  const changeDate = (e: { value: Date }) => {
    if (isOpen) {
      const date = filters.dateDashTimeSecond(e.value);
      setTempDate(date);
    }
  };

  const cancelDateChange = () => {
    setValue('reservationDate', reservationDate, { shouldDirty: true });
    setTempDate(reservationDate);
    onClose();
  };

  const saveReservationDate = () => {
    setValue('reservationDate', tempDate, { shouldDirty: true });
    onClose();
  };

  return (
    <DateTimePickerDrawer
      isOpen={isOpen}
      onClose={cancelDateChange}
      onSave={saveReservationDate}
      min={filters.dateDashTimeSecond(formatAfter10min())}
      stepMinute={5}
      value={tempDate}
      onChange={changeDate}
    />
  );
};

export default SelectMessageReservationDateDrawer;

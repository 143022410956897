import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SendReservation from 'sharedComponents/Message/SendReservation';

import { MESSAGE_RESERVATION_ALERT } from '../constants';
import { MessageType } from '../types';

const MessageSendReservation = () => {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const { watch, setValue } = useFormContext<Pick<MessageType, 'isReserve' | 'mode'>>();
  const isReserve = watch('isReserve');
  const isEditMode = watch('mode') === 'update';

  const confirmOffReserved = () => {
    setIsDialogOpened(true);
  };

  const offReserved = () => {
    setValue('isReserve', false);
    setIsDialogOpened(false);
  };

  return (
    <>
      <SendReservation offAlert={isReserve && isEditMode ? confirmOffReserved : undefined} />
      {isDialogOpened ? (
        <Dialog
          onClose={() => setIsDialogOpened(false)}
          title={MESSAGE_RESERVATION_ALERT.title}
          positiveAction={{ text: MESSAGE_RESERVATION_ALERT.ok, onClick: offReserved }}
          negativeAction={{ text: MESSAGE_RESERVATION_ALERT.cancel, onClick: () => setIsDialogOpened(false) }}>
          <DialogDescription tag="pre">{MESSAGE_RESERVATION_ALERT.description}</DialogDescription>
        </Dialog>
      ) : null}
    </>
  );
};

export default MessageSendReservation;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostAuthChangeAuthenticatedMobile = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AuthChangeAuthenticatedMobileParams>({
    method: `post`,
    url: `/api/auth/change-authenticated-mobile`,
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      // 번호변경시 시설연동이 모두 끊어지므로 api 캐시도 모두 삭제
      queryClient.clear();
    },
  });
};

export default usePostAuthChangeAuthenticatedMobile;

type AuthChangeAuthenticatedMobileParams = {
  mobile: string;
};

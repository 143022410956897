import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

type Props = {
  subTitle: string;
  title: string;
};

const MiniTitle = ({ subTitle, title }: Props) => {
  return (
    <Container>
      <Typography>{subTitle}</Typography>
      <Typography size={24} weight={700}>
        {title}
      </Typography>
    </Container>
  );
};

export default MiniTitle;

const Container = styled.div`
  ${theme.flex('column', 'flex-start', 'center', 2)};
  padding: 16px 20px;
`;

import Divider from 'components/Divider';
import { MemberDetailResponse } from 'hooks/service/queries/useGetMemberDetail';
import { postMessageToApp } from 'utils/communicateWithNative';

import MainTopInfo from './MainTopInfo';
import MemberInfoAccordion from './MemberInfoAccordion';

type Props = {
  member: MemberDetailResponse;
};

const MemberInfo = ({ member }: Props) => {
  return (
    <>
      <MainTopInfo
        member={member}
        onClickCopyEmail={(text: string) => postMessageToApp('COPY_CLIPBOARD', { text, target: '이메일' })}
      />
      <Divider />
      <MemberInfoAccordion member={member} />
    </>
  );
};

export default MemberInfo;

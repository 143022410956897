import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { getCurrentWaitingCount } from 'pages/Booking/utils/bookings';
import { useFormContext } from 'react-hook-form';

import { BookingFormType, BookingUserTicket } from '../types';

type Props = {
  overBookingTicket: BookingUserTicket | null;
  onClose: () => void;
};

const WaitingExceedDialog = ({ overBookingTicket, onClose }: Props) => {
  const { getValues, watch, setValue } = useFormContext<BookingFormType>();
  const {
    currentLecture: { waiting_trainee_limit },
  } = getValues();
  const { selectedUserTickets } = watch();
  const currentWaitingCount = getCurrentWaitingCount(selectedUserTickets);

  const click = () => {
    if (!overBookingTicket) return;
    const waitingUserTicket: BookingUserTicket = {
      ...overBookingTicket,
      status: 'new_booking_waiting',
      id: overBookingTicket.is_shared ? `${overBookingTicket.id}-isShared` : overBookingTicket.id,
      waitingNumber: currentWaitingCount + 1,
    };
    setValue('selectedUserTickets', [...selectedUserTickets, waitingUserTicket], { shouldDirty: true });
    onClose();
  };

  return (
    <Dialog onClose={onClose} negativeAction={{ text: '취소' }} positiveAction={{ text: '예약대기', onClick: click }}>
      <DialogDescription>
        예약대기 가능인원({waiting_trainee_limit}명)을 초과했습니다.
        <br />
        계속 하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default WaitingExceedDialog;

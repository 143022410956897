import axios from 'axios';
import localStorage from 'utils/localStorage';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 1000 * 60,
  headers: { 'Cache-control': 'no-cache' },
});

instance.interceptors.request.use(config => {
  config.headers = config.headers || {};

  const token = localStorage.get('access_token');
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});

export default instance;

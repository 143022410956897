export const staff = {
  id: 29069,
  studio_id: 59672,
  user_grade_id: 97891,
  name: '일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십',
  account_type: 'staffAccount',
  account_id: 51809,
  mobile: '01012345678',
  vaccination_yn: 'Y',
  created_at: '2024-03-14 14:09:05',
  updated_at: '2024-03-14 14:09:05',
  deleted_at: '2024-03-14 14:09:05',
  profile: {
    id: 90217,
    studio_id: 60523,
    name: '홍길동',
    self_introduction: '강사를 소개한다',
    representative_color: 'f95454',
    hired_at: '2024-03-14 14:09:06',
    get_noti_all: false,
    private_start_interval_on_off: false,
    private_start_interval_am: 30,
    private_start_interval_pm: 30,
    registered_at: '2024-03-14 14:09:06',
    created_at: '2024-03-14 14:09:06',
    updated_at: '2024-03-14 14:09:06',
    deleted_at: '2024-03-14 14:09:06',
  },
  avatar: {
    id: 83366,
    owner_type: 'staff',
    owner_id: 93813,
    is_representative: true,
    image: [
      {
        path: 'https://img.studiomate.kr/',
        url: 'JIrFNJusNgRx9pVarAHgQvEfxEWq94IYa5cVKeAd',
        extension: 'jpeg',
      },
    ],
    created_at: '2024-03-14 14:09:06',
    updated_at: '2024-03-14 14:09:06',
    deleted_at: '2024-03-14 14:09:06',
  },
  contact_infos: {
    id: 43485,
    contact: '01012341234',
    is_representative: true,
    type: 'cellphone',
    created_at: '2022-06-24 04:30:23',
    updated_at: '2022-06-24 04:30:23',
  },
  roles: [
    {
      id: 3690,
      studio_id: 17352,
      name: 'instructor',
      display_name: '강사',
      is_immutable: false,
      created_at: '2022-06-24 04:30:23',
      updated_at: '2022-06-24 04:30:23',
      pivot: {
        studio_id: 17352,
        role_id: 3690,
      },
    },
  ],
  career: {
    id: 43485,
    index: 1,
    career: '자격증',
    created_at: '2023-06-24 04:30:23',
    updated_at: '2023-06-24 04:30:23',
  },
  schedules: {
    id: 43485,
    type: 'workTime',
    day_of_week: 1,
    start_time: '04:30:23',
    end_time: '04:30:23',
    created_at: '2023-06-24 04:30:23',
    updated_at: '2023-06-24 04:30:23',
  },
  account: {
    id: 22493,
    studio_id: 70586,
    name: '홍길동',
    nickname: '닉네임',
    mobile: '01012341234',
    gender: 'M',
    birthday: '2023-03-24',
    app_type: 'I',
    app_version: 'test',
    created_at: '2024-03-14 14:09:06',
    updated_at: '2024-03-14 14:09:06',
    deleted_at: '2024-03-14 14:09:06',
  },
};

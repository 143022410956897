import 'assets/styles/global-mobiscroll.scss';
import 'dayjs/locale/ko';

import { worker } from '_mocks/browser';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { sentryInit } from 'utils/sentry';

import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.extend(isBetween);

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

dayjs.locale('ko');

const enableMocking = async () => {
  if (process.env.REACT_APP_MOCK === 'true') {
    await worker.start();
  }
};

sentryInit();
// 서비스 워커 등록이 완료된 후에 렌더링을 시작해야
enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <RecoilRoot>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </RecoilRoot>,
  );
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { useFormContext } from 'react-hook-form';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';

type Props = {
  mobileType: 'SMS' | 'LMS';
  count: number;
  deductPoint: number;
  isOpen: boolean;
  onClose: () => void;
};

const MobileSendConfirmDrawer = ({ deductPoint, count, mobileType, isOpen, onClose }: Props) => {
  const { watch } = useFormContext<MobileFormType>();
  const { mode, isReserve: isReserved, reservationDate, originalMessage } = watch();

  const title = mode === 'create' && isReserved ? 'drawerTitleReserved' : 'drawerTitle';

  const currentMode = isReserved ? mode : 'create'; // 예약 메시지 수정이라도 reserve off면 즉시 발송 문구로 적용
  const canCompare = mode === 'update' && originalMessage;

  return (
    <DoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={onClose}
      header={
        <TwoLinedTitle
          title={
            <TitleTypography size={19} weight={700}>
              총 <b>{filters.numberComma(count)}</b>건의
              <br />
              {MOBILE_FORM_TEXT[currentMode][title]}
            </TitleTypography>
          }
        />
      }
      leftButton={{
        text: MOBILE_FORM_TEXT.cancel,
        onClick: onClose,
      }}
      rightButton={{
        form: 'mobile',
        type: 'submit',
        text: MOBILE_FORM_TEXT[currentMode].sendButton,
      }}>
      <Container>
        <InfoFlexRow>
          <InfoTypography>{MOBILE_FORM_TEXT.type}</InfoTypography>
          <InfoTypography weight={500} className="aligned">
            {canCompare && (
              <>
                {originalMessage.messageType}
                <Icon name="arrowBottomFill" color="gray3" size={16} />
              </>
            )}
            {mobileType}
          </InfoTypography>
        </InfoFlexRow>
        <InfoFlexRow>
          <InfoTypography>{MOBILE_FORM_TEXT.count}</InfoTypography>
          <InfoTypography weight={500} className="aligned">
            {canCompare && (
              <>
                {filters.numberComma(originalMessage.count)}건
                <Icon name="arrowBottomFill" color="gray3" size={16} />
              </>
            )}
            {filters.numberComma(count)}건
          </InfoTypography>
        </InfoFlexRow>
        {isReserved && reservationDate && (
          <InfoFlexRow>
            <InfoTypography>{MOBILE_FORM_TEXT.sendOn}</InfoTypography>
            <InfoTypography weight={500}>[예약] {filters.dateTimeWithWeekday(reservationDate)}</InfoTypography>
          </InfoFlexRow>
        )}
        <InfoFlexRow>
          <InfoTypography>{deductPoint >= 0 ? MOBILE_FORM_TEXT.deductionPoint : MOBILE_FORM_TEXT.refundPoint}</InfoTypography>
          <InfoTypography weight={500} textColor="primary">
            <Tooltip isOpen={isReserved && deductPoint > 0} message={['예약 발송 시, 포인트는 선차감 됩니다.']} position="right">
              {filters.numberComma(Math.abs(deductPoint))}P
            </Tooltip>
          </InfoTypography>
        </InfoFlexRow>
      </Container>
    </DoubleButtonDrawer>
  );
};

export default MobileSendConfirmDrawer;

const Container = styled(InfoFlexWrapper)`
  padding: 18px 20px;
  border-radius: 12px;
  background-color: ${theme.color.gray7};
  .aligned {
    vertical-align: middle;
    ${theme.flex('', 'center', 'end')}
    svg {
      transform: rotate(-90deg);
    }
  }

  // 툴팁 위치 조정
  div.tooltip-contents-container {
    right: -17px;
    transform: none;
  }
`;

const TitleTypography = styled(Typography)`
  b {
    color: ${theme.color.primary};
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { AttachmentType } from 'api/modules/attachment';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Typography, { TypographyProps } from 'components/Typography';
import { ComponentProps } from 'react';
import getAvatarImageUrl from 'utils/getImageUrl';

import CheckRadioTypography from './CheckRadioTypography';

export type Props = Pick<TypographyProps, 'weight'> & {
  imgUrl: AttachmentType['path'] | null;
  label: string;
  id: string;
  selectStaffId?: string;
  isMe?: boolean;
  gap?: number;
  className?: string;
} & Pick<ComponentProps<typeof CheckRadioTypography>, 'showLabelAll'>;

const AvatarLabel = ({ className, imgUrl, label, id, selectStaffId, showLabelAll, isMe, gap, weight }: Props) => {
  return (
    <Container className={`avatar-label ${className ?? ''}`} gap={gap}>
      <Avatar imgUrl={getAvatarImageUrl(imgUrl)} />
      <div className="name-wrapper">
        <CheckRadioTypography
          isSelected={!!selectStaffId && id === selectStaffId}
          showLabelAll={showLabelAll}
          weight={!!selectStaffId && id === selectStaffId ? 600 : weight}>
          {label}
        </CheckRadioTypography>
        {isMe && (
          <Typography span weight={600} textColor="gray3">
            나
          </Typography>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div<Pick<Props, 'gap'>>(
  ({ gap = 12 }) => css`
    ${theme.flex('row', 'center', 'start', gap)};

    .name-wrapper {
      ${theme.flex('row', 'center', 'start', 4)};
    }
  `,
);

export default AvatarLabel;

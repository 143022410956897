import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 수업 예약 마감 및 마감해제 */
const usePatchLectureClose = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: `patch`,
    url: `/api/lecture/close/${lectureId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default usePatchLectureClose;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import useDeleteRole from 'hooks/service/mutations/useDeleteRole';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useToast from 'hooks/useToast';
import { useState } from 'react';

import { SUCCESS_MESSAGE } from '../../constants';
import { DisplayNameType, RoleRemoveType, RoleUpdateType } from '../types';

type Props = {
  roleIndex: number;
  role: DisplayNameType;
  update: RoleUpdateType;
  remove: RoleRemoveType;
};

const IconGroup = ({ roleIndex, role, update, remove }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { setToast } = useToast();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const { mutate: deleteRoleMutate } = useDeleteRole({ roleId: role.roleId });

  const modifyRole = () => {
    update(roleIndex, {
      ...role,
      isModify: true,
    });
  };

  const closeDialog = () => setIsDialogOpen(false);

  const deleteRole = () => {
    deleteRoleMutate(undefined, {
      onSuccess: () => {
        setToast({ type: 'success', message: SUCCESS_MESSAGE.delete });
        remove(roleIndex);
      },
      onSettled: closeDialog,
    });
  };

  return (
    <Container>
      <IconButton
        onClick={() => {
          checkPermission(PERMISSION.role.edit.id, {
            onSuccess: () => {
              modifyRole();
            },
          });
        }}>
        <Icon name="writing" size={16} fillColor={theme.color.gray2} />
      </IconButton>

      <IconButton
        onClick={() => {
          checkPermission(PERMISSION.role.edit.id, {
            onSuccess: () => {
              setIsDialogOpen(true);
            },
          });
        }}>
        <Icon name="delete" size={16} fillColor={theme.color.gray2} />
      </IconButton>

      {isDialogOpen && (
        <Dialog
          onClose={closeDialog}
          negativeAction={{
            text: '취소',
            style: 'outlined',
            onClick: closeDialog,
          }}
          positiveAction={{
            text: '삭제',
            onClick: deleteRole,
          }}>
          <DialogDescription>
            <Typography span weight={600} size={15}>
              [{role.displayName}]&nbsp;
            </Typography>
            역할을
            <br />
            삭제하시겠습니까?
          </DialogDescription>
        </Dialog>
      )}
    </Container>
  );
};

export default IconGroup;

const Container = styled.div`
  ${theme.flex('row', 'center', 'center', 16)};
`;

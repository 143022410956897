import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

import ModifyButton from './ModifyButton';

type Props = {
  text: string;
  onClick?: () => void;
  buttonText?: string;
  isShowButton?: boolean;
};

const TimeScheduleSubTitle = ({ text, onClick, buttonText, isShowButton }: Props) => {
  return (
    <Container>
      <Typography className="sub-title" weight={600} textColor="gray2">
        {text}
      </Typography>
      {isShowButton && <ModifyButton onClick={() => onClick?.()} buttonText={buttonText} />}
    </Container>
  );
};

export default TimeScheduleSubTitle;

const Container = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  width: 100%;
`;

import styled from '@emotion/styled';

import PointSummaryCard from './PointSummaryCard';
import ReportPointList from './ReportPointList';

const ReportPoint = () => {
  return (
    <Container>
      <PointSummaryCard />
      <ReportPointList />
    </Container>
  );
};

export default ReportPoint;

const Container = styled.div``;

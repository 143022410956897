import dayjs from 'dayjs';
import filters from 'utils/filters';

import { BookingCommonFormType, BookingCreateStateType } from '../types';

type Result = Pick<BookingCommonFormType, 'startDate' | 'endDate' | 'startTime' | 'endTime'>;

/**
 * 프라이빗, 그룹 수업 등록 폼에서 필요한 날짜, 시간 초기화
 * default
 *  날짜: 시작 날짜 ~ 3개월 뒤
 *  시간: 시작 시간 ~ 50분 뒤
 */
const convertBookingDateCreateFormState = (state: BookingCreateStateType): Result => {
  const { selectedDate, from } = state;

  const startDate = filters.dateDash(selectedDate);
  const add3month = dayjs(selectedDate).add(3, 'month');
  const endDate = filters.dateDash(add3month);

  let startTime = selectedDate;
  const add50Min = dayjs(selectedDate).add(50, 'minute');
  let endTime = dayjs(add50Min).toDate();

  /**
   * 플러스 플로팅 버튼을 통해 이동한 경우,
   * 등록 시점으로부터 가장 가까운 정각으로 startTime 세팅
   */
  if (from === 'floating') {
    startTime = dayjs().add(1, 'hour').startOf('hour').toDate();
    endTime = dayjs(startTime).add(50, 'minute').toDate();
  }

  return {
    startDate,
    endDate,
    startTime,
    endTime,
  };
};

export default convertBookingDateCreateFormState;

import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { get } from 'lodash';

import { FilterProps } from './types';

const ButtonFilterBase = ({ currentFilter, onClick, selectedOption }: FilterProps) => {
  return (
    <ButtonGroup className="filter-option-button-wrapper" gap={6}>
      {currentFilter.options.map(({ value, label }, index) => {
        let isSelected;
        if (Array.isArray(selectedOption)) {
          isSelected = selectedOption.find(item => item === value);
        } else if (get(selectedOption, 'type')) {
          isSelected = value === get(selectedOption, 'type');
        } else {
          isSelected = value === selectedOption;
        }

        return (
          <Button
            key={index}
            edge="circular"
            heightSize={32}
            variant="outlined"
            padding={{ left: 16, right: 16 }}
            fontSize={14}
            fontWeight={isSelected ? 600 : 400}
            textColor={isSelected ? 'primary' : 'gray2'}
            outlineColor={isSelected ? 'primary' : undefined}
            onClick={() => onClick({ value, label }, currentFilter.key)}>
            {label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default ButtonFilterBase;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  holidayId: number;
};

const useDeleteHoliday = ({ holidayId }: Props) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'delete',
    url: `/api/holiday/${holidayId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['holidays'],
      });
    },
  });
};

export default useDeleteHoliday;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { UserTicketLectureResponse } from './useInfinityUserTicketLecture';

/** 프라이빗 수업 예약에 가능한 수강권 목록 조회(수강회원 선택) */
const useInfinityUserTicketPrivate = (params: UserTicketBookingPrivateParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<UserTicketBookingPrivateResponse, UserTicketBookingPrivateParams>({
    url: `/api/userTicket/private`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', 'private', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityUserTicketPrivate;

export type UserTicketBookingPrivateParams = {
  start_date: string;
  end_date: string;
  max_trainee: number;
  lecture_id?: number;
  search?: string;
};

export type UserTicketBookingPrivateResponse = UserTicketLectureResponse;

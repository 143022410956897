import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MemoResponse } from 'hooks/service/queries/useInfinityMemo';
import usePopup from 'hooks/usePopup';
import { useEffect, useMemo, useRef, useState } from 'react';
import ImageDetail from 'sharedComponents/ImageDetail';
import { ArrangeTypographyWrapper, CommonStyleCard } from 'sharedComponents/StyledComponents';
import Thumbnail from 'sharedComponents/Thumbnail';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  data: MemoResponse;
  onUpdate: (id: number) => void;
  onRemove: (id: number) => void;
  canUpdateMemo: boolean;
};

const MemoCard = ({ data, onRemove, onUpdate, canUpdateMemo }: Props) => {
  const { setPopup } = usePopup();
  const [isOpen, setIsOpen] = useState(false);
  const [hasToggle, setHasToggle] = useState(false);
  const preRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!data.memo || !preRef.current) return;
    const totalTextHeight = preRef.current.scrollHeight;

    // 3줄까지 노출 (66px = line-height * 3)
    if (data.memo && totalTextHeight > 66) {
      setHasToggle(true);
      return;
    }
    setHasToggle(false);
  }, [data.memo]);

  const files = useMemo(() => {
    if (!data.attachments) return [];
    return data.attachments.map(attachment => {
      const imgUrl = getImageUrl(attachment.path, '0x0');

      if (!imgUrl) return '';
      return imgUrl;
    });
  }, [data.attachments]);

  const handleClickOpenImageDetail = (index: number) => {
    setPopup(<ImageDetail files={files} selectedIndex={index} onClose={() => setPopup(null)} />);
  };

  return (
    <Container
      className="memo-card"
      bgColor="gray7"
      showBoxShadow={false}
      hasTextOnly={!hasToggle && !files.length && !canUpdateMemo}>
      {data.memo && (
        <TextWrapper isOpen={isOpen}>
          <Typography ref={preRef} tag="pre" size={15} weight={500} ellipsis={isOpen ? undefined : 4}>
            {data.memo}
          </Typography>
          {hasToggle && !isOpen && <Typography span>...</Typography>}
        </TextWrapper>
      )}

      <StyledWrapper>
        <Typography span size={13} textColor="gray2">
          {filters.date(data.updated_at)}
        </Typography>
        <StyledStaffTypography span>
          <Typography className="name" span size={13} textColor="gray2" ellipsis={1}>
            {data.staff_name}
          </Typography>
          <Typography className="no-bar" span size={13} textColor="gray2">
            강사
          </Typography>
        </StyledStaffTypography>
      </StyledWrapper>

      {!!data.attachments.length && (
        <div className="thumbnail-wrapper">
          {data.attachments.map(({ id, path }, index) => {
            return <Thumbnail key={id} imgSrc={getImageUrl(path, '720x720')} onClick={() => handleClickOpenImageDetail(index)} />;
          })}
        </div>
      )}

      {canUpdateMemo && (
        <ButtonGroup gap={4}>
          <Button
            size="tiny"
            variant="outlined"
            textColor="gray2"
            fontWeight={500}
            widthSize={40}
            padding={{ left: 6, right: 6 }}
            onClick={() => onUpdate(data.id)}>
            수정
          </Button>
          <Button
            size="tiny"
            variant="outlined"
            textColor="gray2"
            fontWeight={500}
            widthSize={40}
            padding={{ left: 6, right: 6 }}
            onClick={() => onRemove(data.id)}>
            삭제
          </Button>
        </ButtonGroup>
      )}

      {hasToggle && (
        <>
          <Divider thin thinColor="gray5" />
          <Button
            className="toggle-button"
            textColor="gray2"
            rightIcon={<Icon name={isOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} color="gray3" />}
            onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? '접기' : '더보기'}
          </Button>
        </>
      )}
    </Container>
  );
};

export default MemoCard;

const Container = styled(CommonStyleCard)<{ hasTextOnly: boolean }>`
  padding: ${({ hasTextOnly }) => (hasTextOnly ? '16px 16px 60px' : '16px')};

  .thumbnail-wrapper {
    margin-top: 16px;
    ${theme.flex('', '', 'flex-start', 8)};
  }

  .button-group {
    margin-top: 24px;
    justify-content: flex-end;
  }

  hr {
    margin-top: 16px;
  }

  .toggle-button {
    width: 100px;
    padding-top: 16px;
    margin: 0 auto;
  }
`;

const TextWrapper = styled.div<{ isOpen: boolean }>`
  margin-bottom: 8px;

  pre {
    max-height: ${({ isOpen }) => (isOpen ? 'auto' : '66px')};
  }
`;

const StyledWrapper = styled(ArrangeTypographyWrapper)`
  ${theme.flex('row', 'center', '')};
`;

const StyledStaffTypography = styled(Typography)`
  ${theme.flex('row', 'center', '', 3)};

  .typography.name {
    max-width: 190px;
  }

  .typography.no-bar {
    padding-left: 0;
    flex: 0 0 auto;
    &:before {
      content: none;
    }
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography, { TypographyProps } from 'components/Typography';

export const InfoTypography = ({ children, className, size = 15, textColor = 'gray2', ...props }: TypographyProps) => {
  return (
    <Typography span className={className} size={size} textColor={textColor} {...props}>
      {children}
    </Typography>
  );
};

export const InfoFlexWrapper = styled.div<{ gap?: number }>(
  ({ gap = 6 }) => css`
    ${theme.flex('column', 'initial', 'initial', gap)};
  `,
);

export const InfoFlexRow = styled.div<{ gap?: number }>(
  ({ gap = 10 }) => css`
    ${theme.flex('', 'flex-start', 'space-between', gap)};

    .typography {
      &:first-of-type {
        flex: 0 0 auto;
      }
      &:last-of-type {
        flex: 1;
        text-align: right;
      }
    }
  `,
);

export const CardWrapper = styled.div`
  ${theme.flex('column', '', '', 12)};
`;

export const STAFF_HEADER_TITLE = {
  staffDetail: '강사 정보',
  myInfoDetail: '내 강사 정보',
} as const;

const TAB_LABEL = {
  basicInfo: '기본정보',
  schedule: '시간정보',
  assignedMember: '담당회원',
} as const;

export const TAB_DATA = [
  { value: 'basicInfo', label: TAB_LABEL.basicInfo },
  { value: 'schedule', label: TAB_LABEL.schedule },
  { value: 'AssignedMember', label: TAB_LABEL.assignedMember },
];

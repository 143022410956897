import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { MessageMobileCheckParams, MessageMobileCheckResponse } from './usePostMessageMobileCheck';

const usePostMessageMobileUpdateCheck = (id?: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<MessageMobileUpdateCheckParams, MessageMobileUpdateCheckResponse>({
    method: `post`,
    url: `/api/message/mobile/check/${id}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostMessageMobileUpdateCheck;

type MessageMobileUpdateCheckParams = MessageMobileCheckParams;
type MessageMobileUpdateCheckResponse = MessageMobileCheckResponse;

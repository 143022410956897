import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { TicketsParams } from 'hooks/service/queries/useInfinityTickets';
import { PRODUCT_TYPE_TEXT } from 'pages/ProductForm/constants';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';

type Props = {
  search?: string;
  productType: TicketsParams['product_type'];
};

const NoDataText = ({ search, productType }: Props) => {
  return (
    <Container>
      {search ? (
        <NoSearchDataTypography keyword={search} />
      ) : (
        <Typography span size={15} textColor="gray2">
          {PRODUCT_TYPE_TEXT[productType]}이 없습니다.
        </Typography>
      )}
    </Container>
  );
};

export default NoDataText;

const Container = styled.div`
  ${theme.flex('', '', 'center', 2)}
  margin-top: 100px;
`;

import styled from '@emotion/styled';
import SubTitle, { TitleType } from 'components/SubTitle';

type Props = {
  title: TitleType;
  message: TitleType;
};

const TicketSubTitle = ({ title, message }: Props) => {
  return (
    <Container className="ticket-sub-title">
      <SubTitle title={title} message={{ text: [`${message}`], position: 'bottom' }} />
    </Container>
  );
};

export default TicketSubTitle;

const Container = styled.div`
  padding: 8px 20px 0;
`;

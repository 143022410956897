import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureBookingListResponse } from '../queries/useInfinityBookingList';

const usePatchBookingPrivate = (booking: LectureBookingListResponse) => {
  const { id, lecture_id } = booking;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<ChangePrivateBookingStatusParams, ChangePrivateBookingStatusResponse>({
    method: `patch`,
    url: `/api/booking/private/${id}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['booking', 'list', lecture_id],
      });
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lecture_id],
      });
    },
  });
};

export default usePatchBookingPrivate;

export type ChangePrivateBookingStatusParams = {
  status: Exclude<LectureBookingListResponse['status'], 'booking_waiting' | 'booking_pending'>;
};

export type ChangePrivateBookingStatusResponse = {
  id: number;
  studio_id: number;
  user_id: number;
  lecture_id: number;
  ticket_id: number;
  user_ticket_id: number;
  status: Exclude<LectureBookingListResponse, 'booking_waiting' | 'booking_pending'>;
  updated_by: number;
  updated_for: string;
  enter_at: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

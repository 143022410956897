import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import useInfinityScheduleManagementBooking, {
  ScheduleManagementBookingParams,
  ScheduleManagementBookings,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { bookingParamsAtom, isEveryPermissionMoreDetailScheduleAtom, staffListForScheduleFilter } from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import { ScheduleStaff } from '../../type';
import { getScheduleGroupByStartOn, getStaffIds } from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const BookingScheduleList = ({ currentTab }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const staffs = useRecoilValue(staffListForScheduleFilter);
  const isEveryPermission = useRecoilValue(isEveryPermissionMoreDetailScheduleAtom);
  const [scheduleBooking, setBookingParams] = useRecoilState(bookingParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');
  const [filteredSchedules, setFilteredSchedules] = useState<ScheduleManagementBookings[][]>([]);

  const scheduleBookingParams: ScheduleManagementBookingParams = {
    staff_ids: isEveryPermission ? getStaffIds(scheduleBooking.staffs || []) : [currentStaffId],
    start_date: scheduleBooking.period?.start || filters.dateDash(),
    end_date: scheduleBooking.period?.end || filters.dateDash(),
    type: scheduleBooking.type,
    paginate_type: 'detail',
    status: scheduleBooking.status,
    order_by: scheduleBooking.sort ?? 'asc',
    search: searchKeyword,
  };

  const {
    data = { schedules: [], total: 0, groupBookingCount: 0, privateBookingCount: 0 },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useInfinityScheduleManagementBooking(scheduleBookingParams);

  useEffect(() => {
    const groupedSchedules = getScheduleGroupByStartOn(data.schedules);
    setFilteredSchedules(groupedSchedules as ScheduleManagementBookings[][]);
  }, [data.schedules, searchKeyword]);

  useEffect(() => {
    if (!isEveryPermission) {
      const currentStaff = staffs.filter(staff => staff.id === currentStaffId)[0];
      const staffInfo = {
        id: currentStaff?.id,
        imgUrl: getImageUrl(currentStaff?.avatar),
        name: currentStaff?.name,
      } as ScheduleStaff;

      setBookingParams(prev => ({ ...prev, staffs: [staffInfo] }));
    }
  }, [currentStaffId, isEveryPermission, setBookingParams, staffs]);

  useEffect(() => {
    refetch();
  }, [scheduleBooking, refetch]);

  if (data.total === 0 || filteredSchedules.length === 0)
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={data.groupBookingCount} privateTotal={data.privateBookingCount} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.booking} />
      </>
    );

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={data.total} groupTotal={data.groupBookingCount} privateTotal={data.privateBookingCount} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <PullToRefresh isRefetching={isRefetching} onRefresh={refetch}>
        <ContentSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ScheduleCard schedules={filteredSchedules} currentTab={currentTab} />
          </InfiniteScroll>
        </ContentSection>
      </PullToRefresh>
    </Container>
  );
};

export default BookingScheduleList;

const Container = styled.div`
  & > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;

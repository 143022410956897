import { MbscCalendarEvent, MbscCalendarEventData } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import { EVENT_CARD_TYPE } from 'pages/Schedule/ScheduleMain/constants';
import { useMemo } from 'react';

import EventCounselCard from './EventCounselCard';
import EventEtcScheduleCard from './EventEtcScheduleCard';
import EventLectureCard from './EventLectureCard';

type Props = {
  eventData: MbscCalendarEventData;
  original: MbscCalendarEvent;
};

const ScheduleEventCard = ({ eventData, original }: Props) => {
  const {
    id,
    title,
    currentTraineeCount,
    maxTrainee,
    color = theme.color.red0,
    isPrivate,
    staffName,
    allDay,
    isBookingClosed,
  } = original;
  const { start, end, position } = eventData;

  /** 같은 시간에 겹친 일정 카드의 개수 */
  const sameTimeEvents = useMemo(() => {
    const cardWidth = parseInt(position.width);

    switch (cardWidth) {
      default:
      case 100:
        return 1;
      case 50:
        return 2;
      case 33:
        return 3;
      case 25:
        return 4;
      case 20:
        return 5;
    }
  }, [position.width]);

  if (!id || !title) return null;

  const lectureCardProps = {
    lectureType: original.type,
    startTime: start,
    endTime: end,
    sameTimeEvents,
    title: title,
    currentTraineeCount,
    maxTrainee,
    cardColor: color,
    staffName,
    isBookingClosed,
  };

  const counselCardProps = {
    title,
    cardColor: color,
    sameTimeEvents,
  };

  const etcScheduleCardProps = {
    title,
    isPrivate,
    cardColor: color,
    isAllDay: allDay,
    sameTimeEvents,
  };

  /** G(그룹), P(프라이빗), C(상담), E(기타 일정) */
  switch (original.type) {
    case EVENT_CARD_TYPE.group:
    case EVENT_CARD_TYPE.private:
      return <EventLectureCard {...lectureCardProps} />;
    case EVENT_CARD_TYPE.counsel:
      return <EventCounselCard {...counselCardProps} />;
    case EVENT_CARD_TYPE.etcSchedule:
    case EVENT_CARD_TYPE.allDayEtcSchedule:
      return <EventEtcScheduleCard {...etcScheduleCardProps} />;
  }
};

export default ScheduleEventCard;

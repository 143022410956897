import styled from '@emotion/styled';
import Divider from 'components/Divider';
import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MultiStaffFilter from '../MultiStaffFilter';
import SchedulePeriodFilter from '../SchedulePeriodFilter';

const BookingWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  return (
    <Container>
      <SchedulePeriodFilter
        currentFilter={filters[0]}
        selectedOption={get(filterChanged, filters[0].key)}
        onClick={changeOption}
      />
      <Divider />
      <ApiBoundary>
        <MultiStaffFilter currentFilter={filters[1]} selectedOption={get(filterChanged, filters[1].key)} onClick={changeOption} />
      </ApiBoundary>
      <Divider />
      <ButtonFilter currentFilter={filters[2]} selectedOption={get(filterChanged, filters[2].key)} onClick={changeOption} />
      <Divider />
      <ButtonFilter currentFilter={filters[3]} selectedOption={get(filterChanged, filters[3].key)} onClick={changeOption} />
    </Container>
  );
};

export default BookingWholeFilterOptions;

const Container = styled.div`
  > div:first-of-type {
    padding: 16px 20px 24px 20px;
  }
`;

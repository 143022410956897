import dayjs from 'dayjs';

type Props = {
  repeat: Array<number>;
  startDate: string;
  endDate: string;
};

/** 수업 예약 api 요청 전 확인 페이지에서 예약되는 총 수업 개수 반환 */
const getTotalLectureCount = ({ repeat, startDate, endDate }: Props) => {
  const startValue = dayjs(startDate);
  const endValue = dayjs(endDate);

  const allDates = [];
  let currentDate = startValue;
  while (currentDate.isBefore(endValue) || currentDate.isSame(endValue, 'day')) {
    allDates.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  const targetDates = allDates.filter(date => repeat.includes(date.day()));
  return repeat.length ? targetDates.length : allDates.length;
};

export default getTotalLectureCount;

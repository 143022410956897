import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Suffix from 'components/TextField/components/Suffix';
import Typography from 'components/Typography';
import {
  ChangeEvent,
  forwardRef,
  ReactNode,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import filters from 'utils/filters';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  /** textarea의 높이 */
  height?: number;
  /** 최대 높이, (기본값 무한대) margin 제외 offsetHeight랑 비교 */
  maxHeight?: number;
  /** 높이 자동 조절 여부,  height 속성이 있을 경우 false 처럼 취급 */
  autoHeight?: boolean;
  /** textarea의 타이틀 텍스트 */
  label?: string;
  /** 현재 입력되는 value의 length (입력된 텍스트 카운트) */
  currentLength?: number;
  /** 에러 메시지 */
  errorMessage?: string;
  size?: 'large' | 'small';
  /** Textfield와 유사한 UI를 위한 suffix 노출여부, Textarea는 기본적으로 false */
  hasSuffix?: boolean;
  suffix?: ReactNode;
  onClear?: () => void;
  className?: string;
  suffixMarginRight?: number;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      height,
      label,
      currentLength,
      errorMessage,
      onChange,
      autoHeight,
      maxHeight = Infinity,
      size = 'large',
      className,
      hasSuffix = false,
      suffix,
      onClear,
      maxLength,
      suffixMarginRight,
      ...props
    },
    ref,
  ) => {
    const { id, readOnly } = props;
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const [focus, setFocus] = useState(false);

    const suffixProps = {
      disabled: props.disabled,
      value: props.value,
      readOnly,
      suffix,
      onClear: () => {
        onChange?.({ target: { value: '' } } as ChangeEvent<HTMLTextAreaElement>);
      },
      suffixMarginRight: suffixMarginRight ?? 16,
      focus,
    };

    const showBottom = useMemo(() => !!errorMessage || !!maxLength, [errorMessage, maxLength]);
    const fontSize = FONT_SIZE[size];

    const changeHandler = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        const target = event.currentTarget;
        target.style.height = '100%'; // height 초기화, 줄어들 때도 적용되도록
        if (!height && autoHeight) {
          target.style.height = `${Math.min(target.scrollHeight, maxHeight)}px`; // 스크롤 높이에 맞춤
        }
        // onChange 기본 동작
        onChange?.(event);
      },
      [height, autoHeight, maxHeight, onChange],
    );

    const handleRef = (element: HTMLTextAreaElement | null) => {
      if (typeof ref === 'function') {
        ref(element);
      }
      textareaRef.current = element;
    };

    /**
     * underline 타입일 때,
     * clear 버튼 눌러서 빈 값되면 textarea height 값 1줄로 초기화
     */
    useEffect(() => {
      if (!textareaRef.current) return;
      if (hasSuffix && !props.value) {
        textareaRef.current.style.height = `24px`;
      }
    }, [hasSuffix, props.value]);

    /** 초기 랜더링시 높이 자동맞춤 */
    useEffect(() => {
      if (!height && autoHeight && textareaRef.current) {
        textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, maxHeight)}px`;
      }
    }, [autoHeight, height, maxHeight]);

    return (
      <Container className={`textarea ${className}`}>
        {label && (
          <StyledLabel htmlFor={id}>
            <Typography size={fontSize.label} weight={500} textColor="gray2">
              {label}
            </Typography>
          </StyledLabel>
        )}

        <TextareaWrapper height={height} showBottom={showBottom} className="textarea-wrapper">
          <StyledTextarea
            ref={handleRef}
            {...props}
            onChange={changeHandler}
            errorMessage={errorMessage}
            showBottom={showBottom}
            size={size}
            onFocus={() => setFocus(true)}
            onBlurCapture={() => setFocus(false)}
          />
          <div />
          {hasSuffix && <Suffix {...suffixProps} />}
        </TextareaWrapper>

        {showBottom && (
          <BottomWrapper className="textarea-bottom-wrapper">
            {!readOnly && errorMessage && (
              <Typography className="error" size={fontSize.error} textColor="error">
                {errorMessage}
              </Typography>
            )}

            {maxLength && (
              <p className="count-wrapper">
                <Typography span size={14} weight={500} textColor="gray3">
                  {filters.numberComma(currentLength || 0)}
                </Typography>
                <Typography span size={14} weight={500} textColor="gray3">
                  /
                </Typography>
                <Typography span size={14} weight={500} textColor="gray3">
                  {filters.numberComma(maxLength)}
                </Typography>
              </p>
            )}
          </BottomWrapper>
        )}
      </Container>
    );
  },
);

const FONT_SIZE = {
  large: { label: 13, textarea: 1.5, textLineHeight: 22, error: 14 },
  small: { label: 12, textarea: 1.4, textLineHeight: 18, error: 13 },
};

const Container = styled.div`
  position: relative;
`;

const StyledLabel = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;

const TextareaWrapper = styled.div<{ height?: number; showBottom: boolean }>(
  css`
    position: relative;
  `,

  ({ height }) =>
    height &&
    css`
      height: ${height}px;
    `,

  ({ showBottom }) =>
    showBottom &&
    css`
      padding-bottom: 43px;
    `,
);

const StyledTextarea = styled.textarea<Pick<TextareaProps, 'errorMessage' | 'size'> & { showBottom: boolean }>(
  css`
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    outline: none;
    color: ${theme.color.gray1};
    caret-color: ${theme.color.primary};
    font-weight: 500;
    background-color: ${theme.color.white};

    &::placeholder {
      color: ${theme.color.gray4};
    }

    & + div {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      border: 1px solid ${theme.color.gray5};
      border-radius: 10px;
    }

    &:focus {
      & + div {
        border-color: ${theme.color.primary};
      }
    }

    &:read-only {
      z-index: 1;
      color: ${theme.color.gray3};
      background-color: ${theme.color.gray6};

      & + div {
        background-color: ${theme.color.gray6};
        border-color: ${theme.color.gray6};
      }
    }
  `,

  ({ size = 'large' }) => css`
    font-size: ${FONT_SIZE[size].textarea}rem;
    line-height: ${FONT_SIZE[size].textLineHeight}px;
    border-radius: ${size === 'small' ? '8px' : '10px'};
    padding: ${size === 'small' ? '10px 16px' : '13px 16px'};
    & + div {
      border-radius: ${size === 'small' ? '8px' : '10px'};
    }
  `,
  ({ errorMessage }) =>
    errorMessage &&
    css`
      & + div {
        border-color: ${theme.color.error};
      }

      &:focus {
        & + div {
          border-color: ${theme.color.error};
        }
      }
    `,

  ({ showBottom }) =>
    showBottom &&
    css`
      padding-bottom: 0;
    `,
);

const BottomWrapper = styled.div`
  ${theme.flex('', 'center', 'space-between', 4)};
  width: 100%;
  padding: 0 16px;
  position: absolute;
  bottom: 13px;
  left: 0;

  .count-wrapper {
    flex: 0 0 auto;
    ${theme.flex('row', 'center', 'end', 2)}
  }

  .error,
  p:first-of-type {
    flex: 1;
  }
`;

Textarea.displayName = 'Textarea';
export default Textarea;

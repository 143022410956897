import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';

type Props = { disabled: boolean };

const SubmitButton = ({ disabled }: Props) => {
  return (
    <Container className="submit-wrapper">
      <Button type="submit" form="check-submit" variant="contained" size="large56" color="point" fullWidth disabled={disabled}>
        저장
      </Button>
    </Container>
  );
};

export default SubmitButton;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  padding: 10px 20px;
  width: 100%;
  background-color: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};
`;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { Payment } from './useInfinityUserTicketProduct';

const useGetPaymentUserTicket = (params: PaymentUserTicketParams & { userTicketId: number }) => {
  const { userTicketId, user_id } = params;
  const { queryFn } = useQueryFnWithCommonParams<{ data: Array<Payment> }, PaymentUserTicketParams>({
    url: `/api/payment/${userTicketId}`,
    params: {
      user_id,
    },
  });

  return useSuspenseQuery({
    queryKey: ['user-ticket', 'payment', params.userTicketId],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetPaymentUserTicket;

export type PaymentUserTicketParams = {
  user_id: number;
};

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 기타 일정 삭제 (상세 페이지의 1개 삭제) */
const useDeleteEtcSchedule = (id: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/etcSchedule/${id}`,
  });

  return useCustomMutation({
    mutationFn,
    mutationKey: ['etc/schedule', 'delete', id],
  });
};

export default useDeleteEtcSchedule;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import CheckBox from 'components/CheckBox';
import Typography from 'components/Typography';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import getImageUrl from 'utils/getImageUrl';

import { CommonProps } from '../types';

type Props = {
  staffs: StaffResponse[];
  onChange: (e: { target: { id: string; checked: boolean } }) => void;
} & Pick<CommonProps, 'tempCheckedStaffs'>;

const FilterStaffItems = ({ staffs, tempCheckedStaffs, onChange }: Props) => {
  const staffId = useRecoilValue(staffIdAtom);

  return (
    <Container>
      {staffs.map(({ id, name, avatar }) => {
        const isStaffMe = staffId === id;
        const isChecked = tempCheckedStaffs.includes(id);
        return (
          <li key={id}>
            <CheckBox
              id={id.toString()}
              label={
                <div className="staff-label-wrapper">
                  <Avatar size={22} imgUrl={getImageUrl(avatar)} />
                  <Typography className={isStaffMe ? 'staff-me' : ''} size={17} weight={500}>
                    {name}
                  </Typography>
                </div>
              }
              gap={16}
              checked={tempCheckedStaffs.includes('all') || isChecked}
              onChange={onChange}
            />
          </li>
        );
      })}
    </Container>
  );
};

export default FilterStaffItems;

const Container = styled.ul`
  ${theme.flex('column', 'flex-start', 'center', 8)};
  width: 100%;

  li {
    padding-left: 20px;
    width: 100%;

    .staff-label-wrapper {
      ${theme.flex('row', 'center', 'center', 8)};

      .staff-me {
        &::after {
          content: '나';
          margin-left: 4px;
          font-weight: 600;
          color: ${theme.color.gray3};
        }
      }
    }
  }
`;

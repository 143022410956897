import { PointFormType } from 'pages/MemberDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

type Props = {
  onClick: () => void;
};

const UpdateButton = ({ onClick }: Props) => {
  const {
    control,
    formState: { isValid },
  } = useFormContext<PointFormType>();
  const rewardAmount = useWatch({ control, name: 'rewardAmount' });
  const deductAmount = useWatch({ control, name: 'deductAmount' });

  return (
    <FooterButton onClick={onClick} disabled={!isValid || (!rewardAmount && !deductAmount)}>
      수정
    </FooterButton>
  );
};

export default UpdateButton;

import Typography from 'components/Typography';
import useGetMemberGrade from 'hooks/service/queries/useGetMemberGrade';
import useInfinityStaffMember from 'hooks/service/queries/useInfinityStaffMember';
import useParamsId from 'hooks/useParamsId';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { assignedMemberTotalAtom } from 'recoil/staff';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import MemberCard from 'sharedComponents/MemberCard';

import { AssignedMemberPageDataType } from '../../../types';
import NoContentText from './NoContentText';

type Props = {
  pageData: AssignedMemberPageDataType;
  setPageData: Dispatch<SetStateAction<AssignedMemberPageDataType>>;
};

const AssignedMemberList = ({ pageData, setPageData }: Props) => {
  useGetMemberGrade(); // 회원카드 안에서 사용되는 회원등급 정보를 미리 호출
  const navigate = useNavigate();

  const setAssignedMemberTotal = useSetRecoilState(assignedMemberTotalAtom);

  const sortTarget = useMemo(() => {
    const isDateSort = pageData.sort.includes('date');
    if (isDateSort) return 'registered_at';
    return 'name';
  }, [pageData.sort]);

  const sortType = useMemo(() => {
    const isAsc = pageData.sort.includes('Asc');
    if (isAsc) return 'asc';
    return 'desc';
  }, [pageData.sort]);

  const targetStaffId = useParamsId();
  const {
    data: assignedMembersResponse,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfinityStaffMember({
    target_staff_id: targetStaffId,
    sort_target: sortTarget,
    sort_type: sortType,
    search: pageData.search,
  });

  useEffect(() => {
    setAssignedMemberTotal(assignedMembersResponse.total);
  }, [assignedMembersResponse.total, setAssignedMemberTotal]);

  const noContentText = useMemo(() => {
    if (pageData.search) {
      return (
        <Typography size={15} textColor="gray2">
          <b>&#34;{pageData.search}&#34;</b>의 검색 결과가 없습니다.
        </Typography>
      );
    }
    return '담당회원 정보가 없습니다.';
  }, [pageData.search]);

  if (!assignedMembersResponse.total) return <NoContentText text={noContentText} />;

  return (
    <InfiniteScroll isLoading={isFetchingNextPage} hasMore={hasNextPage} loadMore={fetchNextPage}>
      <ul className="assigned-members">
        {assignedMembersResponse.members.map(member => {
          return <MemberCard key={member.id} member={member} onClick={() => navigate(`/member/detail/${member.id}`)} />;
        })}
      </ul>
    </InfiniteScroll>
  );
};

export default AssignedMemberList;

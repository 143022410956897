import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { PLACEHOLDER } from 'constants/text';
import usePostPasswordChange from 'hooks/service/mutations/usePostPasswordChange';
import useAutoFocusTracking from 'hooks/useAutoFocusTracking';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import { PasswordFormType } from 'pages/Join/types';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { validationIdCodeAtom } from 'recoil/validationIdCode';
import CompletePopupContents from 'sharedComponents/CompletePopupContents';
import { CustomError } from 'types/errorTypes';
import { password, passwordConfirmation } from 'utils/validate';
import { object } from 'yup';

import { SUB_TITLE } from '../constants';
import { StyledForm } from './FindPwdCheckMobile';
import TitleStep from './TitleStep';

type Props = {
  currentStep: number;
};

const yupSchema = object().shape({ password, passwordConfirmation });

const FindPwdCheckPassword = ({ currentStep }: Props) => {
  const errorDialog = useErrorDialog();

  const validationIdCode = useRecoilValue(validationIdCodeAtom);

  // 비밀번호 필드 타입 변경 컨트롤
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [isConfirmedShowPwd, setIsConfirmedShowPwd] = useState(false);

  // 비밀번호 입력 확인 필드 노출 유무
  const [showConfirmedPwd, setShowConfirmedPwd] = useState(false);

  const { setPopup } = usePopup();

  const {
    handleSubmit,
    formState: { isValid, isSubmitting, errors, dirtyFields },
    control,
    trigger,
    clearErrors,
  } = useForm<PasswordFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  useAutoFocusTracking('password', 'passwordConfirmation');

  const { mutate: postPasswordChangeMutate } = usePostPasswordChange();

  const onSubmit: SubmitHandler<PasswordFormType> = async inputValues => {
    const { password, passwordConfirmation } = inputValues;
    const passwordConfirmField = document.getElementById('passwordConfirmation') as HTMLInputElement;

    const params = {
      studio_id: undefined,
      staff_id: undefined,
      id: validationIdCode.id,
      code: validationIdCode.code,
      password,
      password_confirmation: passwordConfirmation,
    };

    postPasswordChangeMutate(params, {
      onSuccess: () => {
        setPopup(<CompletePopupContents type="findPassword" />);
        passwordConfirmField.blur();
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  const buttonDisabled = useMemo(() => {
    if (showConfirmedPwd) return !isValid || isSubmitting;
    return !dirtyFields.password || !!errors.password || isSubmitting;
  }, [showConfirmedPwd, isValid, isSubmitting, dirtyFields, errors.password]);

  const subTitleOptions = {
    title: SUB_TITLE.CHANGE_PASSWORD,
    message: { position: 'bottom', text: '영문, 숫자를 포함한 8~32자로 입력해 주세요.' },
  };

  return (
    <>
      <TitleStep currentStep={currentStep} subTitleOptions={subTitleOptions} />

      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <OutlinedTextFieldController
          controllerProps={{ control, name: 'password' }}
          type={showPwd ? 'text' : 'password'}
          placeholder={PLACEHOLDER.PASSWORD}
          suffix={
            <IconButton onClick={() => setShowPwd(!showPwd)}>
              <Icon name={showPwd ? 'showOff' : 'showOn'} fillColor={theme.color.gray3} />
            </IconButton>
          }
          suffixMarginRight={16}
          onChange={() => {
            trigger('passwordConfirmation');
          }}
        />

        {showConfirmedPwd && (
          <OutlinedTextFieldController
            controllerProps={{ control, name: 'passwordConfirmation' }}
            type={isConfirmedShowPwd ? 'text' : 'password'}
            placeholder={PLACEHOLDER.CONFIRM_PASSWORD}
            suffix={
              <IconButton onClick={() => setIsConfirmedShowPwd(!isConfirmedShowPwd)}>
                <Icon name={isConfirmedShowPwd ? 'showOff' : 'showOn'} fillColor={theme.color.gray3} />
              </IconButton>
            }
          />
        )}

        <Button
          className="change-pwd-button"
          type={showConfirmedPwd ? 'submit' : 'button'}
          fullWidth
          variant="contained"
          color="point"
          size="medium48"
          disabled={buttonDisabled}
          onClick={() => {
            if (!showConfirmedPwd) {
              setShowConfirmedPwd(true);
              clearErrors('passwordConfirmation');
            }
          }}>
          확인
        </Button>
      </StyledForm>
    </>
  );
};

export default FindPwdCheckPassword;

import Typography from 'components/Typography';
import { AllPageModeType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';

type Props = {
  pageMode: AllPageModeType;
};

const LeaveDialog = ({ pageMode }: Props) => {
  const {
    formState: { dirtyFields, isSubmitSuccessful },
  } = useFormContext();
  const isDirty = !!Object.keys(dirtyFields).length;

  return (
    <DialogBeforeLeave isBlocked={isDirty && !isSubmitSuccessful}>
      <Typography size={15}>
        {pageMode === 'create' || pageMode === 'copy' ? '예약' : '입력'} 정보가 저장되지 않았습니다. <br />
        페이지를 나가시겠습니까?
      </Typography>
    </DialogBeforeLeave>
  );
};

export default LeaveDialog;

import Line from 'assets/animation/lottie_line_spinner.json';
import Circles from 'assets/animation/lottie_loading.json';
import Processing from 'assets/animation/lottie_processing.json';
import Refresh from 'assets/animation/lottie_refresh.json';

export const LOADINGS = {
  circles: { width: 80, src: Circles },
  line: { width: 40, src: Line },
  refresh: { width: 40, src: Refresh },
  processing: { width: 180, src: Processing },
};

import styled from '@emotion/styled';
import { Payment } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { TicketPaymentUpdateFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import filters from 'utils/filters';
import getPaymentDetail from 'utils/getPaymentDetail';

import getIsUnpaid from '../utils/getIsUnpaid';
import PaymentUpdateForm from './PaymentUpdateForm';
import UnpaidPaymentForm from './UnpaidPaymentForm';

type Props = UserTicketProps & {
  selectedPayment: Payment;
  paymentStatus: string;
  goBack: () => void;
};

const PaymentForm = ({ userTicket, paymentStatus, selectedPayment, goBack }: Props) => {
  const isUnpaid = paymentStatus === 'unpaid' || getIsUnpaid(selectedPayment.status);

  const defaultValues: TicketPaymentUpdateFormType = useMemo(() => {
    const {
      id,
      status,
      amount,
      card_amount,
      cash_amount,
      wiretransfer_amount,
      unpaid_amount,
      installment_period,
      point_amount,
      reward_point,
      settlement_at,
    } = selectedPayment;

    const commonDefaultValues = {
      id,
      status,
      userTicket,
      paymentStatus,
      remainingPoint: userTicket.payments.current_point,
      isAutoWriteUnpaid: userTicket.policy.is_auto_write_unpaid,
    };

    /** 미수금 결제 폼일 때, */
    if (paymentStatus === 'unpaid') {
      const unpaidPaymentDefaultAmount = userTicket.payments.unpaid_amount_total;
      return {
        ...commonDefaultValues,

        installment: 'single',
        isCheckedCard: true,
        isCheckedCash: false,
        isCheckedWireTransfer: false,
        isCheckedPoint: false,
        salePrice: unpaidPaymentDefaultAmount,

        payment: {
          amount: unpaidPaymentDefaultAmount,
          discount: 0,
          card_amount: unpaidPaymentDefaultAmount,
          cash_amount: 0,
          wiretransfer_amount: 0,
          unpaid_amount: 0,
          point_amount: 0,
          reward_point: 0,
          settlement_at: filters.dateDashTimeSecond(),
          installment_period: 2,
        },
      };
    } else {
      /** 수정 폼일 때(일반 결제, 미수금 결제) */
      return {
        ...commonDefaultValues,

        installment: !selectedPayment || installment_period < 2 ? 'single' : 'split',
        isCheckedCard: !!card_amount,
        isCheckedCash: !!cash_amount,
        isCheckedWireTransfer: !!wiretransfer_amount,
        isCheckedPoint: !!point_amount,
        salePrice: isUnpaid ? amount + unpaid_amount : userTicket.ticket.actual_price,

        payment: {
          amount,
          discount: getPaymentDetail(selectedPayment, userTicket.ticket.actual_price).discountPercent,
          cash_amount,
          card_amount,
          wiretransfer_amount,
          unpaid_amount,
          point_amount,
          reward_point,
          settlement_at: filters.dateDashTimeSecond(settlement_at),
          installment_period: !selectedPayment || installment_period < 2 ? 2 : installment_period,
        },
      };
    }
  }, [isUnpaid, paymentStatus, selectedPayment, userTicket]);

  const methods = useForm<TicketPaymentUpdateFormType>({
    defaultValues,
  });

  return (
    <Container>
      <FormProvider {...methods}>
        {isUnpaid ? <UnpaidPaymentForm goBack={goBack} /> : <PaymentUpdateForm goBack={goBack} />}
      </FormProvider>
    </Container>
  );
};

export default PaymentForm;

const Container = styled.div`
  min-height: 100%;
  padding-bottom: 152px;

  .payment-title {
    display: none;
  }
`;

import { useSuspenseQuery } from '@tanstack/react-query';
import { AccountType } from 'pages/MoreDetails/Account/MyAccount/type';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 내 계정 정보, 시설과 상관없음 */
const useGetAuthAccountMe = () => {
  const { queryFn } = useQueryFnWithCommonParams<AuthAccountMeResponse, AuthAccountMeParams>({
    url: `/api/auth/account/me`,
    params: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: [`auth/account/me`],
    queryFn,
    select: data => data.data,
  });
};

export default useGetAuthAccountMe;

type AuthAccountMeParams = {
  studio_id: undefined;
  staff_id: undefined;
};
type AuthAccountMeResponse = AccountType;

/**
 * mobiscroll <Select /> 컴포넌트의 number wheel 만드는데 사용
 *
 * @param {number | undefined} min
 * @param {number | undefined} max
 * @param {string | undefined} suffix
 */

type Props = {
  min?: number;
  max?: number;
  suffix?: string;
  /** 한자리 수 앞에 0 붙이는 유무 */
  hasZero?: boolean;
};

/** 한자리 수 앞에 0 붙이기 */
export const addZero = (num: number) => num.toString().padStart(2, '0');

const getNumberWheel = ({ min = 0, max = 999, suffix, hasZero }: Props) => {
  const numbers = [...Array.from({ length: max - min + 1 }, (_, i) => i + min)];

  const data: Array<{ text: number | string; value: number; disabled: boolean }> = numbers.map(n => {
    const text = hasZero ? addZero(n) : n;
    return {
      text: suffix ? `${text}${suffix}` : text,
      value: n,
      disabled: false,
    };
  });

  return data;
};

export default getNumberWheel;

import { useSuspenseQueries } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import useQueryFnWithCommonParams from './service/common/useQueryFnWithCommonParams';
import { FilterStaffsResponse } from './service/queries/useGetFilterStaffs';
import { MemberCountParams, MemberCountResponse } from './service/queries/useGetMemberCount';
import { MemberGradeResponse } from './service/queries/useGetMemberGrade';

type Params = {
  countParams?: MemberCountParams;
};
const useGetMemberFilters = ({ countParams }: Params) => {
  const { queryFn: gradeFn } = useQueryFnWithCommonParams<MemberGradeResponse>({
    url: `/api/member/grade`,
  });
  const { queryFn: countFn } = useQueryFnWithCommonParams<MemberCountResponse, MemberCountParams>({
    url: `/api/member/count`,
    params: countParams,
  });

  const { queryFn: staffFn } = useQueryFnWithCommonParams<FilterStaffsResponse>({
    url: '/api/filter/staffs',
  });

  return useSuspenseQueries({
    queries: [
      {
        queryKey: ['member-grade', true],
        queryFn: gradeFn,
        select: (data: AxiosResponse<MemberGradeResponse>) => data?.data,
      },
      {
        queryKey: ['member', 'count', { ...countParams }],
        queryFn: countFn,
        select: (data: AxiosResponse<MemberCountResponse>) => data.data,
      },
      {
        queryKey: ['filter/staffs'],
        queryFn: staffFn,
        select: (data: AxiosResponse<FilterStaffsResponse>) => data.data.data,
      },
    ],
    combine: results => {
      const [grade, count, staffs] = results;
      return {
        gradeResponse: grade?.data,
        tagCounts: count?.data,
        staffList: staffs?.data,
      };
    },
  });
};

export default useGetMemberFilters;

import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import usePolicy from 'hooks/usePolicy';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import filters from 'utils/filters';
import formatTicketCard from 'utils/formatTicketCard';
import getDaysDiff from 'utils/getDaysDiff';

import { ProductCreateFormType } from '../types';
import CommonProductForm from './CommonProductForm';

type Props = {
  ticketWithCurrentPoint: TicketsResponse & { currentMemberPoint: number };
  onSubmit: (values: ProductCreateFormType) => void;
  selectedCreatedTicket?: ProductCreateFormType;
};

const ProductCreateUpdateForm = ({ ticketWithCurrentPoint, selectedCreatedTicket, onSubmit }: Props) => {
  const { isPolicyAllowed } = usePolicy();
  const isAutoWriteUnpaid = isPolicyAllowed('is_auto_write_unpaid');

  const {
    id,
    original_price,
    reward_point,
    max_coupon,
    remaining_cancel,
    remaining_coupon,
    availability_start_at,
    expire_at,
    available_period,
    currentMemberPoint,
    type,
    is_show_cancel_count,
    available_class_type,
  } = ticketWithCurrentPoint;

  const methods = useForm<ProductCreateFormType>({
    defaultValues: selectedCreatedTicket
      ? { ...selectedCreatedTicket, isAutoWriteUnpaid }
      : {
          ticket: ticketWithCurrentPoint,

          installment: 'single',
          isCheckedCard: true,
          isCheckedCash: false,
          isCheckedWireTransfer: false,
          isCheckedPoint: false,
          isAutoWriteUnpaid,

          salePrice: original_price,
          remainingPoint: currentMemberPoint,
          originAvailablePeriod: available_period || undefined,

          payment: {
            amount: original_price,
            card_amount: original_price,
            cash_amount: 0,
            wiretransfer_amount: 0,
            reward_point,
            point_amount: 0,
            installment_period: 2,
            discount: 0,
            unpaid_amount: 0,
            settlement_at: filters.dateDashTimeSecond(),
          },
          count: {
            max_coupon,
            remaining_cancel,
            remaining_coupon,
          },
          period: {
            availability_start_at,
            expire_at,
          },
          detail: {
            is_shared: false,
            is_show_cancel_count: true,
            staffs: [],
            tempStaffs: [],
            ticket_id: id,
          },
        },
  });

  const ticketData = useMemo(() => {
    if (!selectedCreatedTicket) return ticketWithCurrentPoint;

    const {
      count: { max_coupon, remaining_coupon, remaining_cancel },
      period: { availability_start_at, expire_at },
    } = selectedCreatedTicket;
    const period = getDaysDiff(expire_at, availability_start_at) + 1;

    return {
      ...ticketWithCurrentPoint,
      availability_start_at,
      expire_at,
      max_coupon,
      remaining_coupon,
      max_cancel: remaining_cancel,
      available_period: period,
    };
  }, [selectedCreatedTicket, ticketWithCurrentPoint]);

  return (
    <FormProvider {...methods}>
      <CommonProductForm
        pageMode={selectedCreatedTicket ? 'update' : 'create'}
        ticket={formatTicketCard(ticketData)}
        isShowCancelCount={!!is_show_cancel_count}
        type={type}
        isItem={available_class_type === 'I'}
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};

export default ProductCreateUpdateForm;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetLectureCourse = (courseId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<LectureCourseResponse>({
    url: `/api/lecture/course/${courseId}`,
  });

  return useSuspenseQuery({
    queryKey: [`lecture`, 'course', courseId],
    queryFn,
    select: data => data.data,
  });
};

export default useGetLectureCourse;

/**
 * A: 수업일 기준 - 마감시간
 * R: 수업시간 기준 - 마감시간
 * AR: 수업일 기준 - 시작시간부터 마감시간까지
 * RR: 수업시간 기준 - 시작시간부터 마감시간까지
 */
export type LectureRuleType = 'A' | 'R' | 'AR' | 'RR';
export type LectureCourseResponse = {
  id: number;
  instructor_id: number;
  is_booking_only: number;
  is_process: number;
  max_trainee: number;
  min_trainee: number;
  start_date: string;
  end_date: string;
  studio_id: number;
  title: string;
  type: 'G' | 'P';
  waiting_trainee_limit: number;
  room_id: number | null;
  active: boolean;
  attendance_type: string;
  autoclose_deadline: number;
  booking_auto_shift_deadline: number;
  booking_deadline: number;
  cancel_deadline: number;
  daily_booking_change_deadline: number;
  description: string | null;
  division: null;
  division_id: number | null;
  enter_end_deadline: number;
  enter_start_deadline: number;
  facility_id: number | null;
  schedules: Array<LectureCourseSchedule>;
  rule_type: LectureRuleType;
  group_booking_cancel_deadline: number;
  group_booking_cancel_end_days: number;
  group_booking_cancel_end_time: number;
  group_booking_cancel_start_days: number;
  group_booking_cancel_rule_type: LectureRuleType;
  group_booking_cancel_start_time: number;
  group_booking_cancel_startline: number;
  group_booking_deadline: number;
  group_booking_end_days: number;
  group_booking_end_time: number;
  group_booking_rule_type: LectureRuleType;
  group_booking_start_days: number;
  group_booking_start_time: number;
  group_booking_startline: number;
  private_booking_cancel_deadline: number;
  private_booking_cancel_end_days: number;
  private_booking_cancel_end_time: number;
  private_booking_cancel_rule_type: LectureRuleType;
  private_booking_cancel_start_days: number;
  private_booking_cancel_start_time: number;
  private_booking_cancel_startline: number;
  private_booking_deadline: number;
  private_booking_end_days: number;
  private_booking_end_time: number;
  private_booking_rule_type: LectureRuleType;
  private_booking_start_days: number;
  private_booking_start_time: number;
  private_booking_startline: number;
  closed_at: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export type LectureCourseSchedule = {
  course_id: number;
  id: number;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at: string;
  weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

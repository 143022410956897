import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { calendarSettingSelector } from 'recoil/calendarSettings';
import MainLayout from 'sharedComponents/MainLayout';

import CalendarSettingForm from './components/CalendarSettingForm';
import { CALENDAR_SETTING_TEXT } from './constants';
import { CalendarSettingsFormType } from './types';

const CalendarSetting = () => {
  const calendarSettings = useRecoilValue(calendarSettingSelector);

  const methods = useForm<CalendarSettingsFormType>({
    defaultValues: {
      ...calendarSettings,
    },
  });

  return (
    <MainLayout
      header={{ title: CALENDAR_SETTING_TEXT.main.header, bgColor: 'gray7', textColor: 'gray1' }}
      backgroundColor="gray7"
      scrollable>
      <FormProvider {...methods}>
        <CalendarSettingForm />
      </FormProvider>
    </MainLayout>
  );
};

export default CalendarSetting;

import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormFieldDisabled from 'sharedComponents/FormField/FormFieldDisabled';

import CheckRoomUsable from './CheckRoomUsable';

const Room = () => {
  return (
    <ApiBoundary fallback={<FormFieldDisabled iconName="room">{BOOKING_COMMON_FORM_TEXT.room.placeholder}</FormFieldDisabled>}>
      <CheckRoomUsable />
    </ApiBoundary>
  );
};

export default Room;

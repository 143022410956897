import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import usePopup from 'hooks/usePopup';
import DaumPostcodeEmbed, { Address } from 'react-daum-postcode';
import MainLayout from 'sharedComponents/MainLayout';

type Props = {
  saveAddress: (data: string) => void;
};

const FindAddress = ({ saveAddress }: Props) => {
  const { setPopup } = usePopup();
  const closePopup = () => {
    setPopup(null);
  };

  const handleComplete = (data: Address) => {
    const selectedAddress = data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
    const fullAddress = `${selectedAddress} (${data.bname})`; // e.g. '서울 강남구 논현로98길 28 (역삼동)'
    saveAddress(fullAddress);
    closePopup();
  };

  return (
    <MainLayout
      header={{
        title: '주소 찾기',
        underLine: true,
        leftButton: (
          <IconButton onClick={closePopup}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <DaumPostcodeEmbed style={{ height: '100%' }} onComplete={handleComplete} submitMode={false} />
    </MainLayout>
  );
};

export default FindAddress;

import { useIsMutating } from '@tanstack/react-query';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingPrivateUpdateFormType } from '../../types';

const SubmitButton = () => {
  const {
    control,
    formState: { dirtyFields },
  } = useFormContext<BookingPrivateUpdateFormType>();
  const title = useWatch({ control, name: 'title' });
  const isDirty = !!Object.keys(dirtyFields).length;
  const isMutating = useIsMutating() > 0;

  return (
    <FooterButton type="submit" disabled={isMutating || !title || !isDirty}>
      수정 완료
    </FooterButton>
  );
};

export default SubmitButton;

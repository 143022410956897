import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MultiStaffFilter from '../MultiStaffFilter';
import ReportMultiButtonFilter from '../ReportMultiButtonFilter';

const ReportPointDrawerFilterOptions = ({ filters, filterChanged, changeOption, currentFilterTab }: FilterOptionsProps) => {
  const currentFilter = filters.find(filter => filter.key === currentFilterTab?.value);
  if (!currentFilterTab || !currentFilter) return null;

  return (
    <>
      {currentFilter.type === 'select' && (
        <ApiBoundary>
          <MultiStaffFilter
            currentFilter={currentFilter}
            selectedOption={get(filterChanged, currentFilter.key)}
            onClick={changeOption}
          />
        </ApiBoundary>
      )}
      {currentFilter.type === 'multiButtonType' && (
        <ReportMultiButtonFilter
          selectedOptionKey={filters[1].key as 'type'}
          currentAllOptionCount={filters[1].options.length - 1}
          currentFilter={filters[1]}
          selectedOption={get(filterChanged, filters[1].key)}
          onClick={changeOption}
        />
      )}
      {currentFilter.type === 'multiButtonStatus' && (
        <ReportMultiButtonFilter
          selectedOptionKey={filters[2].key as 'status'}
          currentAllOptionCount={filters[2].options.length - 1}
          currentFilter={filters[2]}
          selectedOption={get(filterChanged, filters[2].key)}
          onClick={changeOption}
        />
      )}
    </>
  );
};

export default ReportPointDrawerFilterOptions;

import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 계정 이름 변경 */
const usePatchAuthChangeStaffAccountName = () => {
  const { mutationFn } = useMutationFnWithCommonParams<AuthChangeStaffAccountNameParams>({
    method: `patch`,
    url: `/api/auth/change-staff-account-name`,
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useMutation({
    mutationFn,
  });
};

export default usePatchAuthChangeStaffAccountName;

type AuthChangeStaffAccountNameParams = {
  name: string;
};

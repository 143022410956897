import Typography, { TypographyProps } from 'components/Typography';
import { PropsWithChildren } from 'react';

type Props = {
  textColor?: TypographyProps['textColor'];
  fontWeight?: TypographyProps['weight'];
  hasValue?: boolean;
};

const FormFieldTypography = ({ children, fontWeight, textColor, hasValue }: PropsWithChildren<Props>) => {
  return (
    <Typography size={15} weight={fontWeight || 500} textColor={textColor ? textColor : hasValue ? 'gray1' : 'gray3'}>
      {children}
    </Typography>
  );
};

export default FormFieldTypography;

import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import filters from 'utils/filters';

import { UserTicketProps } from '../types';
import GrayFoldingCard from './GrayFoldingCard';

type Props = UserTicketProps & {
  title?: string;
  isRefund?: boolean;
  isTransfer?: boolean;
};

const FoldingTicketPaymentCard = ({ userTicket, title = '기존 결제정보', isRefund, isTransfer }: Props) => {
  const {
    payments: {
      total_amount,
      card_amount_total,
      cash_amount_total,
      wiretransfer_amount_total,
      unpaid_amount_total,
      point_amount_total,
      reward_point_total,
      initial_payment,
      last_payment,
    },
  } = userTicket;

  const paymentMethods = [
    { label: '카드', value: card_amount_total },
    { label: '현금', value: cash_amount_total },
    { label: '계좌이체', value: wiretransfer_amount_total },
    { label: '포인트', value: point_amount_total, unit: 'P' },
  ];

  // 기존 결제 정보 수강권 판매가는 최초 발급시 결제한 금액 (시설웹 동일)
  const actualPrice = useMemo(() => {
    const { status, before_user_ticket_amount, amount, unpaid_amount } = initial_payment;
    return status === 'transfer' ? before_user_ticket_amount : amount + unpaid_amount;
  }, [initial_payment]);

  return (
    <Container className="folding-ticket-payment-card">
      <Typography weight={600}>{title}</Typography>

      <GrayFoldingCard userTicket={userTicket} isTransfer={isTransfer}>
        <InfoFlexWrapper>
          <InfoFlexRow>
            <StyledLeftTypography>판매가</StyledLeftTypography>
            <StyledRightTypography>{filters.numberComma(actualPrice)}원</StyledRightTypography>
          </InfoFlexRow>
          {isRefund && (
            <InfoFlexRow>
              <StyledLeftTypography>포인트 적립</StyledLeftTypography>
              <StyledRightTypography>{filters.numberComma(reward_point_total)}P</StyledRightTypography>
            </InfoFlexRow>
          )}
        </InfoFlexWrapper>

        <InfoFlexWrapper>
          {paymentMethods.map(({ value, label, unit }) => (
            <InfoFlexRow key={label}>
              <StyledLeftTypography>{label}</StyledLeftTypography>
              <StyledRightTypography>
                {filters.numberComma(value)}
                {unit || '원'}
              </StyledRightTypography>
            </InfoFlexRow>
          ))}
        </InfoFlexWrapper>

        <InfoFlexRow>
          <StyledLeftTypography weight={500}>총 결제금액</StyledLeftTypography>
          <InfoTypography size={14} weight={600} textColor="primary">
            {filters.numberComma(total_amount)}원
          </InfoTypography>
        </InfoFlexRow>

        <InfoFlexWrapper>
          <InfoFlexRow>
            <StyledLeftTypography>미수금</StyledLeftTypography>
            <StyledRightTypography>{filters.numberComma(unpaid_amount_total)}원</StyledRightTypography>
          </InfoFlexRow>

          <InfoFlexRow>
            <StyledLeftTypography>결제일시</StyledLeftTypography>
            <StyledRightTypography>{filters.dateTimeWithWeekday(last_payment.settlement_at)}</StyledRightTypography>
          </InfoFlexRow>
        </InfoFlexWrapper>
      </GrayFoldingCard>
    </Container>
  );
};

export default FoldingTicketPaymentCard;

const Container = styled.div`
  padding: 40px 20px 24px;
`;

const StyledLeftTypography = styled(InfoTypography)`
  opacity: 0.88;
  font-size: 1.4rem;
`;

const StyledRightTypography = styled(InfoTypography)`
  font-size: 1.4rem;
  font-weight: 500;
`;

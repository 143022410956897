import styled from '@emotion/styled';
import { ComponentProps } from 'react';

import FormField from '.';

type Props = ComponentProps<typeof FormField>;

/** 폼 필드 중 버튼이 아닌 disabled 처리용 */
const FormFieldDisabled = ({ children, className, iconName, iconColor, ...props }: Props) => {
  return (
    <Container className={`disabled ${className ?? ''}`} iconName={iconName} iconColor="gray3" {...props}>
      {children}
    </Container>
  );
};

export default FormFieldDisabled;

const Container = styled(FormField)`
  svg {
    opacity: 0.8;
  }
`;

import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

type Props = {
  onClickNextPage: () => void;
};

const NextButton = ({ onClickNextPage }: Props) => {
  const { control } = useFormContext<TicketTransferFormType>();
  const selectedMember = useWatch({ control, name: 'selectedMember' });

  return (
    <FooterButton disabled={!selectedMember} onClick={onClickNextPage}>
      다음
    </FooterButton>
  );
};

export default NextButton;

import { useMutation } from '@tanstack/react-query';
import { ChannelType, FunnelType } from 'api/modules/counseling2';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { CounselingResponseType } from './usePostCounseling';

const usePatchCounseling = (counselId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams<PatchCounselingParamsType, CounselingResponseType>({
    method: `patch`,
    url: `/api/counseling/${counselId}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default usePatchCounseling;

export type PatchCounselingParamsType = {
  studio_id: number;
  staff_id: number;
  name: string;
  phone: string;
  channel: ChannelType;
  funnel: FunnelType;
  contents: string;
  start_on: string;
  end_on: string;
  is_registered: boolean;
  target_staff_id: number;
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import NumberPicker from 'components/NumberPicker';
import Radio from 'components/Radio';
import Typography from 'components/Typography';
import { isEmpty } from 'lodash';
import { MemberTicketFilterType } from 'pages/Member/types';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

const TicketPeriodFilter = ({ currentFilter, selectedOption, onClick: changeOption }: FilterProps) => {
  const previousOption = (selectedOption as MemberTicketFilterType) ?? { date: [] };

  const changePeriod = (period: string[]) => {
    changeOption({ value: { date: period.map(date => filters.dateDash(date)) }, label: '' }, 'ticket_remaining');
    return false;
  };

  const changeCount = (count: number) => {
    if (isEmpty(previousOption.date)) return;
    changeOption({ value: { date: previousOption.date, count }, label: '' }, 'ticket_remaining');
  };

  const selectNoCount = () => {
    if (isEmpty(previousOption.date)) return;
    changeOption({ value: { date: previousOption.date }, label: '' }, 'ticket_remaining');
  };

  const isCountSelected = !isEmpty(previousOption.date) && !!previousOption?.count;
  const isNoCountSelected = isEmpty(previousOption.date) || !previousOption?.count;

  return (
    <>
      <Container>
        <FilterTitle title={currentFilter.title} />
        <PeriodPicker initialDate={previousOption.date} onSave={changePeriod} />
        <Radio
          id="ticket-noCount"
          name="ticket-noCount"
          label={
            <LabelWrapper isOpen={isNoCountSelected} disabled={isEmpty(previousOption.date)}>
              <Typography size={14}>{currentFilter.options[0].label}</Typography>
            </LabelWrapper>
          }
          onChange={selectNoCount}
          checked={isNoCountSelected}
          disabled={isEmpty(previousOption.date)}
        />
        <Radio
          id="ticket-count"
          name="ticket-count"
          label={
            <LabelWrapper isOpen={isCountSelected} disabled={isEmpty(previousOption.date)}>
              <Typography size={14}>기간 내</Typography>
              <Typography className="count" size={14} weight={500}>
                {previousOption?.count ?? 1}
              </Typography>
              <Typography size={14}>회 이하로 남은 회원만 보기</Typography>
            </LabelWrapper>
          }
          onChange={() => changeCount(1)}
          checked={isCountSelected}
          disabled={isEmpty(previousOption.date)}
        />
      </Container>
      {isCountSelected && (
        <>
          <Divider thin thinColor="gray6" />
          <NumberPicker value={previousOption.count} onChange={(e: { value: number }) => changeCount(e.value)} />
        </>
      )}
    </>
  );
};

export default TicketPeriodFilter;

const Container = styled(FilterWrap)`
  label:first-of-type {
    margin-bottom: 12px;
  }

  .period-picker {
    margin-bottom: 16px;
  }

  .radio {
    gap: 8px;
  }

  :disabled {
    color: ${theme.color.gray4};
  }
`;

const LabelWrapper = styled.div<{ isOpen: boolean; disabled: boolean }>(
  css`
    ${theme.flex('', 'center', '', 6)};

    .count {
      ${theme.flex('', 'center', 'flex-start')}
      width: 43px;
      height: 32px;
      padding: 0 8px;
      border-radius: 6px;
      box-shadow: 0 0 0 1px ${theme.color.gray5};
    }
  `,

  ({ isOpen }) =>
    isOpen &&
    css`
      .count {
        box-shadow: 0 0 0 1px ${theme.color.primary};
      }
    `,

  ({ disabled }) =>
    disabled &&
    css`
      .count {
        background-color: ${theme.color.gray6};
        border-color: ${theme.color.gray6};
      }
      > .typography {
        color: ${theme.color.gray3};
      }
    `,
);

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Switch from 'components/Switch';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { StaffProfileFormType } from 'sharedComponents/ProfileField/types';

import IntervalAmController from './conponents/IntervalAmController';
import IntervalPmController from './conponents/IntervalPmController';
import { PRIVATE_START_INTERVAL } from './constants';

const PrivateStartInterval = () => {
  const { control } = useFormContext<StaffProfileFormType>();

  const isPrivateStartInterval = useWatch({ control, name: 'profile.private_start_interval_on_off' });

  return (
    <Container>
      <Controller
        name="profile.private_start_interval_on_off"
        control={control}
        render={({ field: { value, ...field } }) => (
          <Switch
            id="private_staert_interval_on_off"
            label={PRIVATE_START_INTERVAL.title}
            tooltipProps={{
              message: PRIVATE_START_INTERVAL.tooltip,
              position: 'center',
            }}
            switchSize="small"
            fontWeight={600}
            checked={value}
            {...field}
          />
        )}
      />

      <section className={isPrivateStartInterval ? 'show' : 'hide'}>
        <IntervalAmController />
        <Divider thin />
        <IntervalPmController />
      </section>
    </Container>
  );
};

export default PrivateStartInterval;

const Container = styled.div`
  ${theme.flex('column')};
  padding: 0 20px;

  .private-start-interval {
    ${theme.flex('column', 'flex-start')};
    gap: 16px;
    padding: 16px 0;

    .time-table-list {
      ${theme.flex('row', 'center', 'center', 16)};

      .typography {
        font-weight: 500;
      }
    }
  }

  .switch-label-wrapper {
    gap: 4px;
    height: 60px;
  }

  .show,
  .hide {
    width: 100%;
    overflow: hidden;
    transition: height 0.3s ease;

    hr {
      margin: 4px 0;
    }
  }

  .show {
    height: 197px;
  }

  .hide {
    height: 0;
  }
`;

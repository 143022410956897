import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

const MobileSkeletonList = () => {
  const dummyLength = 4;
  const dummyArray = Array.from({ length: dummyLength }, (_, index) => index);
  return (
    <Container>
      {dummyArray.map(i => (
        <Item key={i}>
          <Row>
            <Skeleton width={142} height={20} type="round" />
            <Skeleton width={57} height={24} type="round" />
          </Row>
          <Skeleton height={16} type="round" />
          <DividerWithMargin thin />
          <Skeleton height={36} type="round" />
        </Item>
      ))}
    </Container>
  );
};

export default MobileSkeletonList;

const Container = styled.div`
  padding: 20px;
  ${theme.flex('column', '', '', 12)}
`;

const Item = styled.div`
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  padding: 18px 20px;
  [type='round'] {
    border-radius: 3px;
  }
`;

const Row = styled.div`
  ${theme.flex('', '', 'space-between')}
  margin-bottom: 4px;
`;

const DividerWithMargin = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 14px;
`;

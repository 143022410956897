import BookingFilter from './BookingFilter';
import CheckinFilter from './CheckinFilter';
import DeletedLectureFilter from './DeletedLectureFilter';

type Props = {
  currentTab: string;
};

const ScheduleFilters = ({ currentTab }: Props) => {
  return (
    <>
      {currentTab === 'booking' && <BookingFilter />}
      {currentTab === 'checkin' && <CheckinFilter />}
      {currentTab === 'deletedLecture' && <DeletedLectureFilter />}
    </>
  );
};

export default ScheduleFilters;

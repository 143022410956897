import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useGetAuthAccountMe from 'hooks/service/queries/useGetAuthAccountMe';
import useGetStaffSudamate, { StaffSudamateParams } from 'hooks/service/queries/useGetStaffSudamate';
import useQueryString from 'hooks/useQueryString';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';
import localStorage from 'utils/localStorage';

import NoData from './NoData';
import Pagination from './Pagination';

const SudamateList = () => {
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const page = getSearchParams('page') || 1;
  const search = getSearchParams('search') || '';
  const viewIds = localStorage.get('is_view_ids') || [];

  const params: StaffSudamateParams = {
    limit: 20,
    page: Number(page),
    search: search,
  };

  const {
    data: { sudamatePosts, meta },
  } = useGetStaffSudamate(params);

  /**
   * NOTE
   * 라운지는 시설 연동이 안 된 유저들도 접근이 가능하기 때문에
   * account정보가 필요
   * 현재 로그인시 account 정보를 주고 있지 않기에 라운지 페이지 접근 할때 account 정보를 가져오도록 설정
   *
   * 내가 쓴 글인지 아닌지 판단하기 위해서 account 정보가 필요
   */
  const { data: account } = useGetAuthAccountMe();

  const movePage = (page: number) => {
    navigate(`/lounge?page=${page}&search=${search || ''}`);
  };

  const goToSudamateDetail = (id: number) => {
    navigate(`detail/${id}?comment_sort_by=asc`, { state: { search, page } });
  };

  useEffect(() => {
    localStorage.set('account_id', account.id);
  }, []);

  if (sudamatePosts.length === 0) {
    return <NoData currentKeyword={search} />;
  }

  return (
    <>
      <Container>
        {sudamatePosts?.map(post => {
          const { attachment_count, comment_count, staff_account, created_at, view_count } = post;
          const isReadPost = viewIds.includes(post.id);

          return (
            <PostWrapper key={post.id} onClick={() => goToSudamateDetail(post.id)}>
              <PostHeaderSection isReadPost={isReadPost}>
                <Typography className="sudamate-title" weight={600} lineHeight={18} ellipsis={1}>
                  {post.title}
                </Typography>
                <div className="post-header-icons">
                  {!!attachment_count && <Icon name="camera" fillColor={theme.color.gray3} size={16} />}
                  {!!comment_count && (
                    <Typography className="comment-count" size={13} weight={500} lineHeight={16}>
                      {comment_count > 999 ? '999+' : comment_count}
                    </Typography>
                  )}
                </div>
              </PostHeaderSection>
              <PostInfoSection>
                <Typography size={13} textColor="gray3" lineHeight={16}>
                  {staff_account?.name}
                </Typography>
                <Typography size={13} textColor="gray3" lineHeight={16}>
                  |
                </Typography>
                <Typography size={13} textColor="gray3" lineHeight={16}>
                  {filters.dateTime(created_at)}
                </Typography>
                <Typography size={13} textColor="gray3" lineHeight={16}>
                  ㆍ
                </Typography>
                <Typography size={13} textColor="gray3" lineHeight={16}>
                  조회수 {view_count}
                </Typography>
              </PostInfoSection>
            </PostWrapper>
          );
        })}
        {!!sudamatePosts.length && <Pagination movePage={movePage} lastIndex={meta.last_page} />}
      </Container>
    </>
  );
};

export default SudamateList;

const Container = styled.div`
  padding-bottom: 60px;
`;

const PostWrapper = styled.div`
  padding: 18px 20px;
  border-bottom: 1px solid ${theme.color.gray7};
`;

const PostHeaderSection = styled.section<{ isReadPost: boolean }>`
  ${theme.flex('row', 'center', '', 4)}
  padding-bottom:8px;

  .sudamate-title {
    color: ${({ isReadPost }) => (isReadPost ? `${theme.color.gray3}` : `${theme.color.gray1}`)};
  }

  .post-header-icons {
    ${theme.flex('row', 'center', '', 6)}

    .comment-count {
      ${theme.flex()};
      padding: 0 5px;
      min-width: max-content;
      height: 18px;
      border-radius: 20px;
      background-color: ${theme.color.gray6};
      color: ${theme.color.gray2};
    }
  }
`;

const PostInfoSection = styled.section`
  ${theme.flex('row', 'center', '', 8)};
`;

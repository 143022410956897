import styled from '@emotion/styled';
import kakaoEmblemURl from 'assets/images/kakao_emblem.webp';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SendMobileKakaoDrawer = ({ isOpen, onClose }: Props) => {
  return (
    <SingleButtonDrawer
      header={<OneLinedTitle title="카카오 알림톡을 확인해 주세요!" />}
      isOpen={isOpen}
      onClose={onClose}
      button={{ text: '확인', onClick: onClose }}>
      <Container>
        <img src={kakaoEmblemURl} alt="kakao_emblem" />

        <div className="send-info-message">
          <Typography size={15}>카카오톡 알림톡으로 인증번호를 전송했습니다.</Typography>
          <Typography size={14} textColor="secondary3">
            *전송된 알림톡이 없다면 문자를 확인해 주세요.
          </Typography>
        </div>
      </Container>
    </SingleButtonDrawer>
  );
};

export default SendMobileKakaoDrawer;

const Container = styled.div`
  img {
    width: 100%;
  }

  .send-info-message {
    ${theme.flex('column', '', '', 4)};
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Dispatch } from 'react';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';

type Props = {
  date: (string | undefined)[];
  setIsOpenCalendar: Dispatch<React.SetStateAction<boolean>>;
  closeCalendar: () => void;
};

const CustomCalendarButton = ({ date, setIsOpenCalendar }: Props) => {
  return (
    <Container>
      <div className="period-picker-buttons">
        <DateButton
          icon={<Icon name="calendar" fillColor={theme.color.gray1} />}
          showIcon={true}
          textColor="gray1"
          onClick={() => setIsOpenCalendar(true)}>
          {date?.[0]}
        </DateButton>
        <Typography textColor="gray2">~</Typography>
        <DateButton
          icon={<Icon name="calendar" fillColor={theme.color.gray1} />}
          showIcon={true}
          textColor="gray1"
          onClick={() => setIsOpenCalendar(true)}>
          {date?.[1]}
        </DateButton>
      </div>

      <Typography size={13} textColor="gray2">
        최대 5개월까지 조회할 수 있습니다.
      </Typography>
    </Container>
  );
};

export default CustomCalendarButton;

const Container = styled.div`
  > .period-picker-buttons {
    ${theme.flex('', 'center', 'space-between', 8)};
  }

  > .typography {
    padding-top: 8px;
  }
`;

export const DateButton = styled(ButtonWithRightIconOutlined)`
  :disabled {
    background-color: ${theme.color.gray6};
    border: 1px solid ${theme.color.gray6};
  }
`;

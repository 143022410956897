import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import { PointStaffsSelectFormType, PointStaffType } from 'pages/MemberDetail/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { FilterProps } from 'sharedComponents/Filters/types';

import SelectStaffDrawerContents from './SelectStaffDrawerContents';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  previousOptions?: PointStaffType[];
  changeOptions: FilterProps['onClick'];
};

const StaffSelectDrawer = ({ isOpen, onClose, previousOptions, changeOptions }: Props) => {
  const { data } = useGetFilterStaffs();
  const currentStaffId = useRecoilValue(staffIdAtom);
  const allStaffs: Array<PointStaffType> = data.map(({ id, name, avatar }) => ({
    value: id,
    label: name,
    isMe: currentStaffId === id,
    staffImgUrl: avatar,
  }));

  const methods = useForm<PointStaffsSelectFormType>({
    defaultValues: {
      selectedStaffs: previousOptions ?? allStaffs,
      tempSelectedStaffs: previousOptions ?? allStaffs,
    },
  });

  const save = (selectedStaffs: PointStaffType[]) => {
    const isAllSelected = data.length === selectedStaffs.length;

    changeOptions(
      {
        value: isAllSelected ? undefined : selectedStaffs,
        label: '',
      },
      'selectedStaffs',
    );
    onClose();
  };

  return (
    <FormProvider {...methods}>
      <SelectStaffDrawerContents data={data} allStaffs={allStaffs} isOpen={isOpen} onClose={onClose} saveFilter={save} />
    </FormProvider>
  );
};

export default StaffSelectDrawer;

import styled from '@emotion/styled';
import CheckRadio from 'components/Radio/CheckRadio';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { SelectTicketFormType } from 'pages/ProductForm/types';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TicketCard from 'sharedComponents/TicketCard';
import formatTicketCard from 'utils/formatTicketCard';

type Props = {
  ticket: TicketsResponse;
  tabValue: string;
};

const TicketListItem = ({ ticket, tabValue }: Props) => {
  const { control, setValue } = useFormContext<SelectTicketFormType>();

  return (
    <StyledLi key={ticket.id}>
      <Controller
        control={control}
        name="selectedTicket"
        render={({ field: { value } }) => {
          const checked = value ? ticket.id === Number(value.id) : false;

          return (
            <CheckRadio
              id={`${ticket.id}`}
              name={tabValue}
              label={<TicketCard ticket={formatTicketCard(ticket)} isSelected={checked} />}
              value={ticket.id}
              checked={checked}
              onChange={() => {
                setValue('selectedTicket', ticket);
              }}
            />
          );
        }}
      />
    </StyledLi>
  );
};

export default memo(TicketListItem);

const StyledLi = styled.li`
  label.radio-check-label {
    gap: 34px;
  }

  .ticket-card {
    padding: 13px 0;
  }
`;

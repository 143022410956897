import { theme } from 'assets/styles';
import AgreeDetail from 'components/AgreeDetail';
import CheckBox from 'components/CheckBox';
import Form from 'components/Form';
import SubTitle from 'components/SubTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AgreeDataType, AgreeDetailType, AgreeFormType, AgreeType } from '../types';

type Props = {
  agreeDetail: AgreeDetailType;
  openAgreeDetail: (type: AgreeType) => void;
  nextStep: (data: AgreeDataType) => void;
  prevStep: () => void;
};

const AgreeStep = ({ agreeDetail, openAgreeDetail, nextStep, prevStep }: Props) => {
  const [agreeData, setAgreeData] = useState<AgreeDataType>({
    terms: false,
    privacy: false,
  });

  const { handleSubmit, register } = useForm<AgreeFormType>({ mode: 'onChange' });

  const isAllChecked = agreeData.terms && agreeData.privacy;

  const save: SubmitHandler<AgreeDataType> = () => {
    if (!isAllChecked) return;

    nextStep({ ...agreeData });
  };

  return (
    <>
      {agreeDetail.show ? (
        <AgreeDetail type={agreeDetail.type} prevStep={prevStep} setAgreeData={setAgreeData} />
      ) : (
        <>
          <SubTitle bottom={40} title={['회원가입을 위해', '약관에 동의해 주세요']} />
          <Form
            onSubmit={handleSubmit(save)}
            footerButton={{
              variant: 'contained',
              color: 'point',
              fullWidth: true,
              size: 'large56',
              fontSize: 15,
              text: '다음',
              disabled: !isAllChecked,
            }}>
            <CheckBox
              {...register('terms')}
              id="termsOfService"
              label="이용약관 동의 (필수)"
              outlineBorder
              gap={12}
              color={theme.color.gray1}
              onChange={() => openAgreeDetail('TERMS_OF_SERVICE')}
              checked={agreeData.terms}
            />
            <CheckBox
              {...register('privacy')}
              id="privacyPolicy"
              label="개인정보 수집 및 이용 동의 (필수)"
              outlineBorder
              gap={12}
              onChange={() => openAgreeDetail('PRIVACY_POLICY')}
              checked={agreeData.privacy}
            />
          </Form>
        </>
      )}
    </>
  );
};

export default AgreeStep;

import { useQueryClient } from '@tanstack/react-query';
import { MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import { AxiosResponse } from 'axios';
import Label from 'components/Label';
import { MemberGradeResponse } from 'hooks/service/queries/useGetMemberGrade';

type Props = {
  name?: string;
  color?: string;
};

/**
 * 회원 등급 사용 설정 확인 후 노출
 *
 * 목록 컴포넌트에서 "member/grade" 호출을 했다는 전제 하에
 * 캐시에 접근해서 회원 등급 사용 여부를 확인
 */
const MemberGrade = ({ name, color }: Props) => {
  const queryClient = useQueryClient();
  const gradeQuery = queryClient.getQueryState<AxiosResponse<MemberGradeResponse>>(['member-grade', true]);
  const gradeCacheData = gradeQuery?.status === 'success' && !gradeQuery?.isInvalidated ? gradeQuery.data?.data : undefined;

  const isUseGrade = gradeCacheData?.is_use_user_grade;

  if (!isUseGrade || !name || !color) return null;
  return (
    <Label size="small" textColor="white" color={MATCH_REPRESENTATIVE_COLOR[color]}>
      {name}
    </Label>
  );
};

export default MemberGrade;

import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 기타 일정 삭제 (모든 일정 삭제) */
const useDeleteEtcScheduleAll = (id: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/etcSchedule/all/${id}`,
  });

  return useCustomMutation({
    mutationFn,
    mutationKey: ['etc/schedule', 'delete', 'all', id],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['etc/schedule', 'detail', id],
      });
    },
  });
};

export default useDeleteEtcScheduleAll;

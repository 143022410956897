import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';

type Props = {
  label: string;
  onClick: () => void;
};

const DayLabel = ({ label, onClick }: Props) => {
  return (
    <Container>
      <IconButton onClick={onClick}>
        <Typography size={15} weight={600}>
          {label}
        </Typography>
        <Icon name="arrowBottomBold" fillColor={theme.color.gray1} size={16} />
      </IconButton>
    </Container>
  );
};

export default DayLabel;

const Container = styled.div`
  .icon-button > svg {
    margin-left: 4px;
  }
`;

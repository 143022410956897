import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { useFormContext } from 'react-hook-form';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

const PhoneNumberInput = () => {
  const { control, setValue, watch } = useFormContext<ChangePhoneNumberFormType>();
  const step = watch('step');

  const clearPhoneNumber = () => {
    setValue('mobileRequired', '', { shouldValidate: true });
    setValue('step', 'number');
  };

  return (
    <OutlinedTextFieldController
      inputMode="numeric"
      controllerProps={{ control, name: 'mobileRequired' }}
      placeholder={CHANGE_PHONE_NUMBER_TEXT.placeholder}
      onClear={() => clearPhoneNumber()}
      onChange={() => {
        if (step !== 'number') {
          setValue('step', 'number');
        }
      }}
    />
  );
};

export default PhoneNumberInput;

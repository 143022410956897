import instance from 'api/axios';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

const useMutationFnWithCommonParams = <TParams = void, TResponse = unknown>(
  axiosInfo: AxiosRequestConfig & {
    method: 'post' | 'patch' | 'delete';
  },
) => {
  const studio_id = useRecoilValue(studioIdAtom);
  const staff_id = useRecoilValue(staffIdAtom);

  const mutationFn: (params?: TParams) => Promise<AxiosResponse<TResponse>> = async (params?: TParams) => {
    const { method, url, data, ...rest } = axiosInfo;
    return await instance({
      method,
      url,
      data: {
        studio_id,
        staff_id,
        ...data,
        ...params,
      },
      ...rest,
    });
  };

  return { mutationFn };
};

export default useMutationFnWithCommonParams;

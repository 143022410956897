import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useInfinityStudiomateNotice from 'hooks/service/queries/useInfinityStudiomateNotice';
import useSearchKeywords from 'hooks/useSearchKeywords';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';

import { NOTICE_TEXT, STUDIOMATE_NOTICE_IDENTIFIER } from '../constants';
import ListItem from './ListItem';

const NoticeList = () => {
  const { searchKeyword } = useSearchKeywords(STUDIOMATE_NOTICE_IDENTIFIER);
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityStudiomateNotice(searchKeyword);

  if (data?.length === 0)
    return (
      <Container>
        {searchKeyword ? (
          <NoSearchDataTypography keyword={searchKeyword} />
        ) : (
          <Typography size={15} textColor="gray2">
            {NOTICE_TEXT.empty}
          </Typography>
        )}
      </Container>
    );

  return (
    <InfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      {data?.map((notice, index) => <ListItem key={notice.id} notice={notice} isLastItem={index === data.length - 1} />)}
    </InfiniteScroll>
  );
};

export default NoticeList;

const Container = styled.section`
  padding: 120px 0 0;
  ${theme.flex('column', 'center', 'center')}
  .typography:last-of-type {
    opacity: 0.8;
  }
`;

import styled from '@emotion/styled';
import Typography from 'components/Typography';
import useGetStudio, { Studio } from 'hooks/service/queries/useGetStudio';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { STUDIO_DETAIL_TEXTS } from '../../pages/MoreDetails/Studio/constants';
import StudioDeleteDialog from './StudioDeleteDialog';
import StudioDetailDrawer from './StudioDetailDrawer';
import StudioListItem from './StudioListItem';

type Props = {
  type?: 'detail' | 'delete';
};

const StudioList = ({ type = 'detail' }: Props) => {
  const [selectedStudio, setSelectedStudio] = useState<Studio | null>(null);
  const { data } = useGetStudio();
  const { pathname } = useLocation();

  const isDetail = type === 'detail';
  const icon = isDetail ? 'arrowRight' : 'deleteFill';
  const resetSelected = () => {
    requestAnimationFrame(() => setSelectedStudio(null));
  };

  const isShowIcon = useMemo(() => {
    if (pathname !== '/schedule') return true;
    return data.studios.length > 1;
  }, [pathname, data.studios.length]);

  return (
    <Container>
      {data.studios.length === 0 && (
        <CenterTypography size={15} textColor="gray2">
          {STUDIO_DETAIL_TEXTS.noStudio}
        </CenterTypography>
      )}
      {data.studios.map(studio => (
        <StudioListItem
          key={studio.id}
          studio={studio}
          isShowIcon={isShowIcon}
          onClick={() => (isShowIcon ? setSelectedStudio(studio) : null)}
          rightIconName={icon}
        />
      ))}
      {isDetail ? (
        <StudioDetailDrawer
          isOpen={!!selectedStudio}
          onClose={resetSelected}
          studio={selectedStudio}
          studioName={selectedStudio?.name}
        />
      ) : (
        selectedStudio && <StudioDeleteDialog studio={selectedStudio} onClose={resetSelected} />
      )}
    </Container>
  );
};

export default StudioList;

const Container = styled.div`
  margin-top: 16px;
`;

const CenterTypography = styled(Typography)`
  margin-top: 220px;
  text-align: center;
`;

import { useInfiniteQuery } from '@tanstack/react-query';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';

export type Props = {
  staff_id?: number;
  staff_ids?: number[];
  start_date: string;
  end_date: string;
  /**
   * 수업유형
   * G: 그룹 수강권
   * P: 프라이빗 수강권
   */
  type?: 'P' | 'G';
  /**
   * 예약 상태
   * 예약(대기/확정), 취소,  출석, 결석, 노쇼
   */
  status?: 'booked_list' | 'cancel' | 'attendance' | 'absence' | 'noshow';
  /**
   * 정렬유형 - 수업일시
   */
  order_by?: 'asc' | 'desc';
  search?: string;
};

const useInfinityScheduleManagementBooking = (params: ScheduleManagementBookingParams) => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<
    ScheduleManagementBookingsResponse,
    ScheduleManagementBookingParams
  >({
    url: `/api/schedule-management/booking`,
    params: {
      ...params,
    },
  });

  return useInfiniteQuery({
    queryKey: [`scheduleManagement-booking`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        schedules: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
        groupBookingCount: data.pages[0].data.group_booking_count,
        privateBookingCount: data.pages[0].data.private_booking_count,
      };
    },
    enabled: false,
  });
};

export default useInfinityScheduleManagementBooking;

type Member = {
  /** 회원 아이디 */
  id: number;
  /** 회원 이름 */
  name: string;
  /** 회원 전화번호 */
  mobile: string;
  /** userAccount 아이디 */
  account_id: number;
  /** 회원사진 */
  avatar: {
    /** 회원 아이디 */
    id: number;
    /** 오너 타입 */
    owner_type: string;
    /** 오너 아이디  */
    owner_id: number;
    /** 오너인지? */
    is_representative: number;
    image: {
      /**  아바타 이미지 URL */
      path: string;
      /** 파일이름 */
      name: string;
      /** 확장자이름 */
      extension: string;
    };
  } | null;
};

type UserTicket = {
  /** 수강권 아이디 */
  id: number;
  /** 회원 아이디 */
  user_id: number;
  /**  부모 수강권 아이디*/
  ticket_id: number;
  ticket: {
    /** 부모 수강권 아이디 */
    id: number;
    /** 수강권 이름  */
    title: string;
  };
};

type Staff = {
  /** 스테프 아이디 */
  id: number;
  /** 스테프 이름 */
  name: string;
  staff_avatar: {
    /** 부모 수강권 아이디 */
    id: number;
    /** 수강권 이름  */
    title: string;
    /** 오너 타입 */
    owner_type: string;
    /** 오너인지? */
    is_representative: number;
    image: {
      /**  아바타 이미지 URL */
      path: string;
      /** 파일이름 */
      name: string;
      /** 확장자이름 */
      extension: string;
    };
  };
};

export type ScheduleManagementBooking = {
  /** 부킹 아이디 */
  id: number;
  /** 시설 아이디 */
  studio_id: number;
  /** 수업 아이디 */
  lecture_id: number;
  /** 부모 수강권 아이디 */
  ticket_id: number;
  /** 부모 수강권 아이디 */
  user_ticket_id: number;
  /** 예약 상태 */
  status: string;
  /** 업데이트한 사람 */
  updated_by: number;
  /** 업데이트 이유 */
  updated_for: string;
  /** 입장 시간 */
  enter_at: string | null;
  /** 생성 시간 */
  created_at: string;
  /** 수정 시간 */
  updated_at: string;
  /** 삭제 시간 */
  deleted_at: string;
  member: Member;
  user_ticket: UserTicket;
};

export type ScheduleManagementBookings = {
  /**  수업 아이디 */
  id: number;
  /** 수업명 */
  title: string;
  /** 수업구분 */
  type: string;
  /** 스테프 아이디 */
  instructor_id: number;
  /** 룸 아이디 */
  room_id: number | null;
  /** 수업 시작 시간 */
  start_on: string;
  /** 수업 종료 시간 */
  end_on: string;
  /** 수업 현재 참여 인원 */
  current_trainee_count: number;
  /** 최대 수강 인원 */
  max_trainee: number;
  /** 노쇼/결석을 포함한 현재 인원 */
  total_current_trainee_count: number;
  /** 생성 시간 */
  created_at: string;
  /** 수정 시간 */
  updated_at: string;
  /** 삭제 시간 */
  deleted_at: string;
  /**  업데이트 이유 */
  deleted_for: string;
  /** 삭제한 사람 */
  deleted_by: string;
  /** 예약목록 */
  bookings: ScheduleManagementBooking[];
  staff: Staff;
  room: {
    /** 룸 아이디 */
    id: number;
    /** 룸 이름 */
    name: string;
  } | null;
};

export type ScheduleManagementBookingParams = {
  staff_id?: number;
  staff_ids?: number[];
  start_date: string;
  end_date: string;
  /**
   * 수업유형
   * G: 그룹 수강권
   * P: 프라이빗 수강권
   */
  type?: 'P' | 'G';
  /**
   * 예약 상태
   * 예약(대기/확정), 취소,  출석, 결석, 노쇼
   */
  status?: 'booked_list' | 'cancel' | 'attendance' | 'absence' | 'noshow';
  /**
   * 정렬유형 - 수업일시
   */
  order_by?: 'asc' | 'desc';
  search?: string;
  paginate_type: string;
};

export type ScheduleManagementBookingsResponse = {
  data: ScheduleManagementBookings[];
  /** 그룹수업 예약 인원 */
  group_booking_count: number;
  /** 프라이빗 수업 예약 인원 */
  private_booking_count: number;
};

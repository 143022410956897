export const CHANGE_PHONE_NUMBER_TEXT = {
  number: {
    title: '휴대폰 번호 변경',
    description: ['변경할 휴대폰 번호를 입력해주세요.', '(로그인시 아이디로 사용됩니다.)'],
    submit: '인증번호 전송',
  },
  validationCode: {
    title: '휴대폰 번호 인증',
    description: ['인증번호를 입력해 주세요.'],
    submit: '인증번호 확인',
  },
  confirm: {
    title: '휴대폰 번호 인증 완료',
    description: ['인증된 번호로 변경하시겠습니까?'],
    submit: '휴대폰 번호 변경',
  },
  placeholder: '(-)하이픈 없이 입력해 주세요',
  mobileConfirm: '인증번호 전송',
  mobileConfirmResend: '인증번호 재발송',
  submit: '확인',
  success: '휴대폰 번호가 변경되었습니다.',
  confirmed: '인증완료',
  alert: ['휴대폰 번호 변경이 취소됩니다.', '페이지를 나가시겠습니까?'],
  expired: '인증 시간이 초과했습니다.\n인증번호를 다시 요청해 주세요.',
};

export const KAKAO_VALIDATION_TIME = 5 * 60; // 5분

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography, { TypographyProps } from 'components/Typography';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

type Props = {
  className?: string;
  showIcon?: boolean;
  onClick?: () => void;
  label: string;
  time: TypographyProps['children'];
};

const FieldButton = ({ className, showIcon, label, time, onClick }: Props) => {
  return (
    <StyledButton
      className={`${className ?? 'operation-field-button'}`}
      showIcon={showIcon}
      iconName="time"
      gap={12}
      onClick={onClick}>
      <Typography span textColor="gray2">
        {label}
      </Typography>
      <Typography span size={15} weight={500}>
        {time}
      </Typography>
    </StyledButton>
  );
};

export default FieldButton;

const StyledButton = styled(FormFieldLinkButton)<{ showIcon?: boolean }>`
  height: 73px;

  .link-button-contents-wrapper {
    ${theme.flex('column', '', '', 2)};
  }

  svg {
    opacity: ${({ showIcon }) => (showIcon ? 1 : 0)};
  }
`;

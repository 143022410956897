import instance from 'api/axios';
import { AxiosResponse } from 'axios';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

import { InfiniteLinksType, InfiniteMetaType } from './useInfiniteQueryFnWithCommonParams';

const useInfiniteCustomQueryFnWithCommonParams = <TResponse, TParams = undefined>(axiosInfo: {
  url: string;
  params?: TParams;
}) => {
  const studio_id = useRecoilValue(studioIdAtom);
  const staff_id = useRecoilValue(staffIdAtom);

  const queryFn: ({ pageParam }: { pageParam: number }) => Promise<AxiosResponse<InfiniteCustomData<TResponse>>> = async ({
    pageParam,
  }) => {
    const { url, params } = axiosInfo;
    return await instance({
      method: 'get',
      url,
      params: {
        studio_id,
        staff_id,
        paginate_type: 'simple',
        page: pageParam,
        limit: 20,
        ...params,
      },
    });
  };

  return { queryFn };
};

export default useInfiniteCustomQueryFnWithCommonParams;

export type InfiniteCustomData<T> = T & {
  links: InfiniteLinksType;
  meta: InfiniteMetaType;
};

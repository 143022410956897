import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { TICKET_PAYMENT_TEXT } from 'pages/TicketDetail/constants';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { selectedPaymentAtom } from 'recoil/TicketDetail';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import getIsUnpaid from '../utils/getIsUnpaid';
import PaymentForm from './PaymentForm';
import PaymentList from './PaymentList';

const PaymentDetail = ({ canUpdateUserTicket, userTicket }: UserTicketProps) => {
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useRecoilState(selectedPaymentAtom);

  const { getAllSearchParams } = useQueryString();
  const queryStringParsed = getAllSearchParams();
  const paymentStatus = queryStringParsed.mode as string;

  const headerTitle = useMemo(() => {
    if (!paymentStatus || !selectedPayment) {
      return TICKET_PAYMENT_TEXT.title.detail;
    } else if (paymentStatus === 'unpaid') {
      return TICKET_PAYMENT_TEXT.title.unpaidPayment;
    } else if (paymentStatus === 'update' && getIsUnpaid(selectedPayment.status)) {
      return TICKET_PAYMENT_TEXT.title.unpaidUpdate;
    } else {
      return TICKET_PAYMENT_TEXT.title.paymentUpdate;
    }
  }, [paymentStatus, selectedPayment]);

  const clickBack = useCallback(() => {
    setSelectedPayment(null);
    navigate(-1);
  }, [navigate, setSelectedPayment]);

  return (
    <>
      <MainLayout
        header={{
          title: headerTitle,
          leftButton: (
            <IconButton onClick={clickBack}>
              <Icon name="headerBack" />
            </IconButton>
          ),
        }}>
        <Container>
          {paymentStatus && selectedPayment ? (
            <PaymentForm
              userTicket={userTicket}
              selectedPayment={selectedPayment}
              paymentStatus={paymentStatus}
              goBack={clickBack}
            />
          ) : (
            <ApiBoundary>
              <PaymentList canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} />
            </ApiBoundary>
          )}
        </Container>
      </MainLayout>
    </>
  );
};

export default PaymentDetail;

const Container = styled.div`
  height: 100%;
`;

import { CounselingDetail } from 'hooks/service/queries/useGetCounseling';
import filters from 'utils/filters';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';

type Props = {
  currentCounseling?: CounselingDetail | null;
};
type Result = Omit<MemberFormType, 'studio_id' | 'staff_id' | 'address'>;

const convertFormDefaultValues = ({ currentCounseling }: Props): Result => {
  const commonValues = {
    tickets: [],
    updateUserTickets: [],
    search: '',
    tempSearchKeyword: '',
    selectedTicketId: null,
    selectedTicket: null,
    selectedCreatedTicket: undefined,
    currentMemberPoint: 0,
  };

  const initialValues = {
    ...commonValues,

    userTickets: [],
    image: '',
    beforeName: '',
    mobile: '',
    userGradeId: MEMBER_FORM_TEXT.userGrade.notSelected.value,
    vaccination_yn: false,
    profile: {
      name: '',
      registered_at: filters.dateDash(),
      gender: 'F',
      birthday: undefined,
    },
  };

  /** 상담 비회원 등록 */
  if (currentCounseling) {
    const { name, phone } = currentCounseling;
    const { beforeName, mobile, profile, ...rest } = initialValues;
    return {
      ...rest,
      counseling_id: currentCounseling.id,
      beforeName: name,
      mobile: phone || '',
      profile: {
        ...profile,
        name,
      },
    };
  }

  /** 회원 생성 */
  return initialValues;
};

export default convertFormDefaultValues;

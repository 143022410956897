import useQueryString from 'hooks/useQueryString';
import { LectureOperationFormType } from 'pages/Booking/types';
import { stringTime } from 'pages/Booking/utils/convertTimes';
import { useFormContext } from 'react-hook-form';

import FieldButton from '../../AvailableTime/FieldButton';

const BookingAvailableField = () => {
  const { getValues } = useFormContext<LectureOperationFormType>();
  const {
    policy: { booking, bookingCancel },
  } = getValues();

  const { getAllSearchParams, setSearchParams } = useQueryString();
  const clickField = (detail: 'booking' | 'bookingCancel') => {
    setSearchParams({
      ...getAllSearchParams(),
      type: 'bookingCancelTime',
      detail,
    });
  };

  const bookingTime = stringTime(booking);
  const bookingCancelTime = stringTime(bookingCancel);

  return (
    <>
      <FieldButton
        className="booking-available-button"
        showIcon
        label="예약 가능 시간"
        time={bookingTime}
        onClick={() => clickField('booking')}
      />
      <FieldButton
        className="booking-cancel-available-button"
        label="예약취소 가능 시간"
        time={bookingCancelTime}
        onClick={() => clickField('bookingCancel')}
      />
    </>
  );
};

export default BookingAvailableField;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import TextFieldBase from 'components/TextFieldBase';
import Typography from 'components/Typography';
import React, { useState } from 'react';

import { ITextFieldOptions } from '../types';
import Suffix from './Suffix';

export type OutlinedOptions = {
  /**
   * 해당 input의 타이틀 텍스트
   */
  label?: string;
  /**
   * TextField의 높이
   */
  height?: 'small' | 'large';
  /**
   * "인증됨" 등의 컨펌 메시지
   */
  infoMessage?: string;
  /**
   * 에러 메시지
   */
  errorMessage?: string;
};

type OutlinedPropTypes = OutlinedOptions & Omit<ITextFieldOptions, 'left' | 'description'>;

const OutlinedTextField = React.forwardRef<HTMLInputElement, OutlinedPropTypes>(
  (
    {
      id,
      type = 'text',
      label,
      suffix,
      suffixMarginRight,
      infoMessage,
      errorMessage,
      onClear,
      value,
      height = 'large',
      ...props
    },
    ref,
  ) => {
    const { required, disabled, readOnly } = props;

    const [focus, setFocus] = useState(false);

    const suffixProps = {
      disabled,
      value,
      readOnly,
      suffix,
      suffixMarginRight: suffixMarginRight ?? 12,
      onClear,
      focus,
    };

    return (
      <div className="outlined-text-field">
        {label && (
          <LabelWrapper required={required}>
            <label htmlFor={id}>
              <Typography size={13} weight={500} textColor="gray2">
                {label}
              </Typography>
            </label>
          </LabelWrapper>
        )}

        <OutlinedInputWrapper>
          <OutlinedStyledInput
            id={id}
            type={type}
            ref={ref}
            hasError={!!errorMessage}
            value={value}
            onFocus={() => setFocus(true)}
            onBlurCapture={() => setFocus(false)}
            height={height}
            {...props}
          />
          <div className="empty" />
          <Suffix {...suffixProps} />
        </OutlinedInputWrapper>

        {infoMessage && !errorMessage && (
          <InfoTextWrapper>
            <Icon name="selectCheck" size={16} fillColor={theme.color.primary} />
            <Typography size={13} textColor="primary" span>
              {infoMessage}
            </Typography>
          </InfoTextWrapper>
        )}

        {!disabled && !readOnly && errorMessage && (
          <ErrorTextWrapper className="outline-errormessage-wrapper">
            <Typography size={13} textColor="error" className="error">
              {errorMessage}
            </Typography>
          </ErrorTextWrapper>
        )}
      </div>
    );
  },
);

export default OutlinedTextField;
OutlinedTextField.displayName = 'TextField';

const LabelWrapper = styled.div<{ required?: boolean }>(
  { marginBottom: '8px' },

  /** required 상태일 때 별모양 기호 노출 */
  ({ required }) =>
    !!required &&
    css`
      .typography {
        &::after {
          content: ' *';
          color: ${theme.color.error};
        }
      }
    `,
);

export const OutlinedInputWrapper = styled.div`
  position: relative;
  ${theme.flex('', 'center', '')};
  width: 100%;
`;

export const OutlinedStyledInput = styled(TextFieldBase)<{ hasError?: boolean; height?: 'large' | 'small' }>`
  flex: 1;
  height: ${({ height = 'large' }) => (height === 'small' ? '40px' : '48px')};
  padding: 13px 4px 13px 16px;
  background-color: unset;
  font-size: ${({ height = 'large' }) => (height === 'small' ? '1.4rem' : '1.5rem')};
  font-weight: 500;

  /** 상태에 따른 border style 적용을 위한 빈 태그  */
  & + div {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border: 1px solid ${({ hasError }) => (hasError ? theme.color.error : theme.color.gray5)};
    border-radius: 10px;
  }

  &:focus {
    & + div {
      border-color: ${({ hasError }) => (hasError ? theme.color.error : theme.color.primary)};
    }
  }

  &:read-only {
    color: ${theme.color.gray3};
    z-index: 1;

    & + div {
      border-color: ${theme.color.gray6};
      background-color: ${({ disabled }) => (disabled ? 'unset' : theme.color.gray6)};
    }
  }
`;

const ErrorTextWrapper = styled.div`
  margin-top: 4px;
`;

const InfoTextWrapper = styled(ErrorTextWrapper)`
  ${theme.flex('', 'center', '')};

  svg {
    margin-right: 2px;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';
import getEllipsisByLength from 'utils/getEllipsisByLength';
import getImageUrl from 'utils/getImageUrl';
import getRemainingDaysText from 'utils/getRemainingDaysText';
import getTicketInfoByType from 'utils/getTicketInfoByType';

type Props = {
  member: MemberResponse;
  isSelected?: boolean;
  isFinalConfirm?: boolean;
};

const MemberCard = ({ member, isSelected, isFinalConfirm }: Props) => {
  const { avatar, account_id, mobile, userTicket } = member;

  return (
    <Container className="member-card">
      <div className="image-wrapper">
        <Avatar size={28} imgUrl={avatar ? getImageUrl(avatar.image) : undefined} />
      </div>

      <div className="text-wrapper">
        <Typography size={15} weight={600} textColor={isSelected ? 'primary' : 'gray1'}>
          {member.name}
        </Typography>

        <div className="phone-wrapper">
          <Typography>{mobile ? `${filters.mobile(mobile)}` : '-'}</Typography>
          {mobile && !account_id && <Icon name="connectOff" size={16} color="gray3" />}
        </div>

        <TicketsWrapper isFinalConfirm={isFinalConfirm} gap={isFinalConfirm ? 6 : 4}>
          {userTicket.map(({ id, ticket, max_coupon, remaining_coupon, availability_start_at, expire_at }) => {
            const remainingDays = getRemainingDaysText(expire_at, availability_start_at);
            const { showCoupon, showPeriod } = getTicketInfoByType(ticket.type);
            const text = `${ticket.title} ${showCoupon ? `(${max_coupon}회)` : ''}`;

            return (
              <TypographyWrapper className="bottom-text-wrapper" key={id}>
                <Typography span textColor="gray2" opacity={0.72}>
                  {getEllipsisByLength({ text, max: 12 })}
                </Typography>
                <Typography span textColor="gray2" opacity={0.72}>
                  {`${showCoupon ? `${remaining_coupon}회` : ''}`}
                  {`${showCoupon && showPeriod ? 'ㆍ' : ''}`}
                  {`${showPeriod ? remainingDays : ''}`}
                </Typography>
              </TypographyWrapper>
            );
          })}
        </TicketsWrapper>
      </div>
    </Container>
  );
};

export default MemberCard;

const Container = styled.div`
  ${theme.flex('', '', '', 12)};

  .image-wrapper {
    margin-top: 7px;
  }

  .phone-wrapper {
    ${theme.flex('', '', '')};
  }
`;

const TicketsWrapper = styled(InfoFlexWrapper)<Pick<Props, 'isFinalConfirm'>>`
  margin-top: ${({ isFinalConfirm }) => (isFinalConfirm ? 6 : 2)}px;
`;

const TypographyWrapper = styled(ArrangeTypographyWrapper)`
  ${theme.flex('row', 'center', '')}
  .test {
    max-width: 150px;
  }
`;

import { TabDataElementType } from 'components/Tabs';

export const MESSAGE_IDENTIFIER = 'message';
export const MESSAGE_LIST_TEXT = {
  title: '메시지',
  placeholder: '이름, 제목 검색',
};

export const MESSAGE_TABS: TabDataElementType[] = [
  { label: '문자', value: 'mobile' },
  { label: '앱 푸시', value: 'push' },
];

export const MESSAGE_PREVIEW_TEXT = {
  title: '미리보기',
  description: '미리보기와 실제 발송된 메시지는 기기마다 다를 수 있습니다.',
  more: '더보기',

  sms: 'SMS',
  lms: 'LMS',
  limit: '/2,000 바이트',
  suffix: '건',
};

export const MESSAGE_RESERVATION_TEXT = {
  formLabel: '보내기 예약',
  placeholder: '예약일시 선택',
  drawerTitle: '예약일시',
  description: '10분 이후부터 예약 가능',
  reservation: '예약',
  format: 'YYYY.MM.DD (ddd) HH:mm',
};

export const MESSAGE_RESERVATION_ALERT = {
  title: '보내기 예약 OFF',
  description: '보내기 예약 종료 시,\n즉시 발송 메시지로 변경됩니다.\n계속 하시겠습니까?',
  ok: '계속',
  cancel: '취소',
};

export const MESSAGE_DETAIL_TEXT = {
  delete: {
    button: '삭제하기',
    dialogTitle: '메시지를 삭제하시겠습니까?',
    dialogDescription: '삭제된 메시지는 복구할 수 없습니다.',
    ok: '삭제',
    cancel: '취소',
    success: '메시지가 삭제되었습니다.',
  },
  edit: {
    button: '수정하기',
  },
  cancel: {
    button: '취소하기',
    count: '건수',
    sendOn: '발송일시',
    refundPoint: '환불 포인트',
    cancel: '취소',
    ok: '예약 취소',
    success: '예약 메시지가 취소되었습니다.',
  },
};

import { QNA_LOCAL_STORAGE_KEY } from 'pages/MoreDetails/Qna/List/constants';

const localStorage = {
  get(key: string) {
    try {
      const value = window.localStorage.getItem(key);
      if (value) return JSON.parse(value);
    } catch (error) {
      // parse 실패시 삭제 및 null 반환
      window.localStorage.removeItem(key);
      return null;
    }
  },

  set<T>(key: string, value: T) {
    const parsedValue = JSON.stringify(value);
    window.localStorage.setItem(key, parsedValue);
  },

  remove(key: string) {
    window.localStorage.removeItem(key);
  },

  clear() {
    const protectedKeys = ['tutorialShow', 'onboardingShow', QNA_LOCAL_STORAGE_KEY];

    Object.keys(window.localStorage).forEach(key => {
      if (!protectedKeys.includes(key)) {
        window.localStorage.removeItem(key);
      }
    });
  },

  reset() {
    window.localStorage.clear();
  },
};

export default localStorage;

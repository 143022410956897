import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { forwardRef, useMemo } from 'react';
import { ColorType } from 'types/colorTypes';

export interface TypographyProps {
  /**
   * 적용되는 텍스트
   */
  children: React.ReactNode;
  /**
   * 색 지정 (default: gray1)
   */
  textColor?: ColorType;
  /**
   * 폰트의 사이즈 (default: 14)
   */
  size?: number;
  /**
   * 폰트의 두께 (default: 400)
   */
  weight?: 700 | 600 | 500 | 400;
  /**
   * 줄간격 (default: 18)
   */
  lineHeight?: number;
  /**
   * 0보다 클 때 말줄임 기능 작동
   */
  ellipsis?: number;
  /**
   * 텍스트의 투명도
   */
  opacity?: number;
  /**
   * 밑줄 노출/비노출
   */
  underline?: boolean;
  /**
   * span 태그인지 여부
   */
  span?: boolean;
  /**
   * className 커스텀 용도
   */
  className?: string;
  /**
   * 필요한 HTML tag 세팅 용도
   */
  tag?: 'pre';
}

const Typography = forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ children, tag, span, lineHeight, className, ...props }, ref) => {
    const { size } = props;

    const as = useMemo(() => {
      if (tag) return tag;
      if (span) return 'span';
      else if (size === 24 || size === 21) return 'h1';
      else if (size === 19) return 'h2';
    }, [tag, span, size]);

    const textLineHeight = useMemo(() => {
      if (lineHeight) return lineHeight;
      switch (size) {
        case 12:
          return 12;
        case 13:
          return 16;
        case 14:
          return 18;
        case 15:
          return 22;
        case 17:
          return 24;
        case 19:
          return 26;
        case 21:
          return 28;
        case 24:
          return 32;
      }
    }, [lineHeight, size]);

    return (
      <Text
        as={as}
        {...props}
        lineHeight={textLineHeight}
        className={className ? `typography ${className}` : `typography`}
        ref={ref}>
        {children}
      </Text>
    );
  },
);

Typography.displayName = 'Typography';
export default Typography;

const Text = styled.p<Omit<TypographyProps, 'children' | 'span'>>(
  ({ ellipsis, size, weight, textColor, opacity, underline, lineHeight }) => css`
    ${!!ellipsis && theme.textEllipsis(ellipsis)};

    font-size: ${(size || 14) / 10}rem;
    font-weight: ${weight || 400};
    color: ${theme.color[(textColor as ColorType) || 'gray1']};
    text-decoration: ${underline ? 'underline' : 'none'};
    line-height: ${lineHeight || 18}px;
    opacity: ${opacity || 1};
  `,
  ({ as }) =>
    as === 'pre' &&
    css`
      white-space: pre-wrap;
    `,
);

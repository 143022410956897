import styled from '@emotion/styled';
import Button from 'components/Button';
import { useFormContext } from 'react-hook-form';

import { CHANGE_PHONE_NUMBER_TEXT } from '../constants';
import { ChangePhoneNumberFormType } from '../types';

const ChangePhoneFooterButton = () => {
  const { watch, formState } = useFormContext<ChangePhoneNumberFormType>();
  const step = watch('step');

  const disabledByStep = () => {
    switch (step) {
      case 'number':
        return !!formState.errors.mobileRequired || !formState.dirtyFields.mobileRequired;
      case 'validationCode':
        return !!formState.errors.mobileConfirmCode || !formState.dirtyFields.mobileConfirmCode;
    }
  };

  return (
    <StyledButton type="submit" size="medium48" variant="contained" color="point" disabled={disabledByStep()}>
      {CHANGE_PHONE_NUMBER_TEXT[step].submit}
    </StyledButton>
  );
};

export default ChangePhoneFooterButton;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

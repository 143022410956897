import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { useFormContext } from 'react-hook-form';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import PaymentTextField from './PaymentTextField';
import { PaymentRow } from './StyledComponents';

/** 티켓 환불 시 위약금 */
const RefundPenalty = () => {
  const { control, setValue } = useFormContext<TicketPaymentFormType>();

  return (
    <PaymentRow>
      <PaymentTextField
        control={control}
        setValue={setValue}
        keyValue={'payment.unpaid_amount'}
        textColor="secondary3"
        left={{
          prefix: <Icon name={'money'} fillColor={theme.color.gray2} />,
          text: TICKET_PAYMENT_FORM_TEXT.refund.label,
        }}
      />
    </PaymentRow>
  );
};

export default RefundPenalty;

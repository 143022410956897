import styled from '@emotion/styled';
import Typography from 'components/Typography';
import {
  PrimaryColorTypography,
  RuleTypeAccordionHeader,
  StyledRuleTypeTimeTypography,
} from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TEXT } from 'pages/Booking/constants';
import { RuleTypeCommonProps } from 'pages/Booking/types';
import { addZero } from 'utils/getNumberWheel';

type Props = {
  hour: number;
  min: number;
  isOpen: boolean;
  textType: RuleTypeCommonProps['textType'] | 'dailyChange' | 'waiting' | 'autoClose';
};

/** '0시간 0분 전까지 ~' 텍스트 */
const RTypeAccordionHeader = ({ isOpen, hour, min, textType }: Props) => {
  return (
    <RuleTypeAccordionHeader>
      <StyledTypography className="others-setting-text" span size={15}>
        수업 시작
      </StyledTypography>
      <StyledRuleTypeTimeTypography>
        <PrimaryColorTypography isOpen={isOpen}>{addZero(hour)}</PrimaryColorTypography>
        <Typography span size={15}>
          시간
        </Typography>
      </StyledRuleTypeTimeTypography>
      <StyledRuleTypeTimeTypography>
        <PrimaryColorTypography isOpen={isOpen}>{addZero(min)}</PrimaryColorTypography>
        <Typography className="last-text" size={15}>
          분 전{BOOKING_AVAILABLE_TIME_SETTING_TEXT[textType]}
        </Typography>
      </StyledRuleTypeTimeTypography>
    </RuleTypeAccordionHeader>
  );
};

export default RTypeAccordionHeader;

const StyledTypography = styled(Typography)``;

export const NOTICE_LIST_TEXT = {
  title: '공지사항',
  placeholder: '제목, 내용 검색',
  noSearchResult: '의 검색 결과가 없습니다.',
  noNotice: '등록된 공지가 없습니다.',
  updatedAt: '작성일시',
  postingPeriod: '게시기간',
  topFixed: '상단고정',
  popup: '팝업사용',
} as const;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useGetMessageMobileDetail from 'hooks/service/queries/useGetMessageMobileDetail';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import convertTargetsText from 'utils/convertTargetsText';
import filters from 'utils/filters';

import { MOBILE_DETAIL_TEXT } from '../constants';
import MobileDetailCommonInfo from './MobileDetailCommonInfo';
import MobileDetailContents from './MobileDetailContents';
import MobileMenuButton from './MobileMenuButton';
import StatusDetail from './StatusDetail';
import TargetsDetailDrawerContents from './TargetsDetailDrawerContents';
import TypographyWithTooltip from './TypographyWithTooltip';

type Props = {
  id: number;
};

const MobileDetail = ({ id }: Props) => {
  const { data: message } = useGetMessageMobileDetail(id);

  const status = message.is_sent ? 'send' : message.canceled_at ? 'cancel' : 'request';
  return (
    <MainLayout
      header={{
        title: MOBILE_DETAIL_TEXT.title,
        rightButton: (
          <ApiBoundary>
            <MobileMenuButton message={message} />
          </ApiBoundary>
        ),
      }}>
      <MobileDetailCommonInfo
        status={status}
        sendOn={message?.send_on}
        targetText={convertTargetsText(message.targets)}
        targetContents={<TargetsDetailDrawerContents messageFilters={message.filter} targets={message.targets} />}
        count={message.targets.length}
      />
      <Row>
        <Typography size={15} textColor="gray2">
          {MOBILE_DETAIL_TEXT.point}
        </Typography>
        <Typography size={15} weight={500}>
          {filters.numberComma(Math.abs(message.used_points))}P
        </Typography>
      </Row>
      <Divider thin thinColor="gray8" />
      <Row>
        <TypographyWithTooltip
          size={15}
          textColor="gray2"
          tooltipMessages={MOBILE_DETAIL_TEXT.status.tooltip}
          tooltipIcon={<Icon name="information" size={16} color="gray3" />}>
          {MOBILE_DETAIL_TEXT.status.text}
        </TypographyWithTooltip>
        <StatusDetail status={message.status} />
      </Row>
      <Divider />
      <MobileDetailContents title={message.title} message={message.message} />
    </MainLayout>
  );
};

export default MobileDetail;

export const Row = styled.div`
  ${theme.flex('', 'start', 'space-between')}
  padding: 17px 20px;
`;

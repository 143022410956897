import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { FormatTicketCard } from 'sharedComponents/TicketCard/types';
import formatSmallUserTicketCard, { getCurrentUserTicketInfo } from 'utils/formatSmallUserTicketCard';

/** 업그레이드 단계에서 선택한 수강권 비교 페이지에서 현재 보유 수강권 정보 세팅 */
const formatSmallUpgradeCard = (userTicket: UserTicketResponse): FormatTicketCard => {
  const { bottomOptions, ...rest } = formatSmallUserTicketCard(userTicket);
  const { totalDays, dailyBookingChangeLimit, remainingCancel, remainingCoupon, maxCoupon } =
    getCurrentUserTicketInfo(userTicket);

  return {
    ...rest,
    bottomOptions: [totalDays, dailyBookingChangeLimit, remainingCoupon, maxCoupon, remainingCancel],
  };
};

export default formatSmallUpgradeCard;

import { TabDataElementType } from 'components/Tabs';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isEqual } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import FilterDrawer from 'sharedComponents/Filters/FilterDrawer';
import FilterList from 'sharedComponents/Filters/FilterList';
import { FilterOptionsProps, FilterOptionsType, FilterType, ParamsType } from 'sharedComponents/Filters/types';

import { convertTabs } from './utils';
import WholeFilterPopup from './WholeFilterPopup';

type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>; // atom 값
  filters: FilterType[]; // 필터 상세 내용
  defaultFilter?: T; // atom 생성시 default에 넣은 객체(기본값 빈객체 {} 일 경우에는 안넣어도 됨)
  defaultTabLabels: { [key: string]: string };
  renderDrawerOptions: (props: FilterOptionsProps) => ReactNode;
  renderWholeFilterPopupOptions: (props: FilterOptionsProps) => ReactNode;
};

const Filter = <T extends ParamsType>({
  filterAtom,
  filters,
  defaultTabLabels,
  defaultFilter,
  renderDrawerOptions,
  renderWholeFilterPopupOptions,
}: Props<T>) => {
  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0;
  const { setPopup } = usePopup();
  const { setToast } = useToast();

  // 리스트에 반영되는 필터, 필터 적용버튼 누르면 변경됨
  const [selectedFilter, setSelectedFilter] = useRecoilState(filterAtom);
  // 필터 UI용 상태 (개별 필터 Drawer에서 변경된 내역)
  const [filterChanged, setFilterChanged] = useState(selectedFilter);

  const [openedDrawer, setOpenedDrawer] = useState<TabDataElementType>();

  useEffect(() => {
    // 전체 필터 팝업에서 변경된 필터를 Drawer에 전달하기 위함
    if (!openedDrawer) {
      setFilterChanged(selectedFilter);
    }
  }, [selectedFilter, openedDrawer]);

  const resetSelectedFilter = () => {
    const openedFilter = openedDrawer?.value || '';
    const defaultValue = defaultFilter?.[openedFilter];

    setFilterChanged({
      ...filterChanged,
      [openedFilter]: defaultValue,
    });
    setSelectedFilter({
      ...selectedFilter,
      [openedFilter]: defaultValue,
    });

    if (!isEqual(defaultValue, filterChanged[openedFilter]) || !isEqual(defaultValue, selectedFilter[openedFilter])) {
      setToast({ type: 'success', message: '필터가 초기화되었습니다.', bottom: 68 });
    }
  };

  const saveChangedFilter = () => {
    setSelectedFilter(filterChanged);
    setOpenedDrawer(undefined);
    if (!isEqual(selectedFilter, filterChanged)) {
      setToast({ type: 'success', message: '필터가 적용되었습니다.', bottom: 68 });
    }
  };

  const closeDrawerWithReset = () => {
    setFilterChanged(selectedFilter);
    setOpenedDrawer(undefined);
  };

  const openDrawer = (tab: TabDataElementType) => {
    if (isStudioSelected) {
      setOpenedDrawer(tab);
    } else {
      setOpenedDrawer(undefined);
    }
  };

  const openWholeDrawer = () => {
    if (isStudioSelected) {
      setPopup(
        <WholeFilterPopup
          filterAtom={filterAtom}
          filters={filters}
          renderWholeFilterPopupOptions={renderWholeFilterPopupOptions}
        />,
      );
    } else {
      setPopup(null);
    }
  };

  const changeOption = (option: FilterOptionsType, key: string) => {
    setFilterChanged({ ...filterChanged, [key]: option.value });
  };

  // 필터 변경사항 카운트
  const countChanges = (changes: ParamsType) =>
    Object.entries(changes)
      .filter(([key, value]) => !key.includes('sort')) // sort 관련 필터는 제외
      .filter(([key, value]) => !key.includes('register_type')) // register_type'관련 필터는 제외
      .filter(([key, value]) => value).length; // 기본값(undefined, null)인 경우 카운트 제외

  return (
    <>
      <FilterList
        selectedCount={countChanges(selectedFilter)}
        filterTabs={convertTabs(filters, selectedFilter, defaultTabLabels)}
        filterValues={selectedFilter}
        onOpenWholeFilters={openWholeDrawer}
        onOpenSelectedDrawerFilter={tab => openDrawer(tab)}
      />
      <FilterDrawer
        isOpen={!!openedDrawer}
        onClose={closeDrawerWithReset}
        currentFilterTab={openedDrawer || { value: '', label: '' }}
        onReset={resetSelectedFilter}
        onSave={saveChangedFilter}
        onChange={tab => openDrawer(tab)}
        onOpenWholeFilters={openWholeDrawer}
        filterValues={filterChanged}
        filterTabs={convertTabs(filters, filterChanged, defaultTabLabels)}
        selectedCount={countChanges(filterChanged)}>
        {renderDrawerOptions({ filters, filterChanged, changeOption, currentFilterTab: openedDrawer })}
      </FilterDrawer>
    </>
  );
};

export default Filter;

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';
import { LectureValidationError } from './usePatchLecturePrivate';
import { LectureGroupParams } from './usePostLectureGroup';

const usePatchLectureGroupBulk = (lecture: LectureDetailResponse) => {
  const { course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LectureGroupBulkParams>({
    method: `patch`,
    url: `/api/lecture/group/bulk/${course_id}`,
  });

  return useCustomMutation<AxiosResponse, AxiosError<LectureValidationError>, LectureGroupBulkParams | undefined, unknown>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture'],
      });
    },
  });
};

export default usePatchLectureGroupBulk;

export type LectureGroupBulkParams = LectureGroupParams & {
  start: string;
  is_force?: boolean;
};

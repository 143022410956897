import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import useToast from 'hooks/useToast';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { calendarSettingsAtom } from 'recoil/calendarSettings';

import { CALENDAR_SETTING_TEXT } from '../constants';
import { CalendarSettingsFormType } from '../types';
import ClosedClassBackground from './formField/ClosedClassBackground';
import DayRange from './formField/DayRange';
import DefaultViewType from './formField/DefaultViewType';
import EtcScheduleHide from './formField/EtcScheduleHide';
import TimeRange from './formField/TimeRange';

const CalendarSettingForm = () => {
  const navigate = useNavigate();
  const setCalendarSettings = useSetRecoilState(calendarSettingsAtom);

  const { handleSubmit } = useFormContext<CalendarSettingsFormType>();
  const { setToast } = useToast();

  const submit: SubmitHandler<CalendarSettingsFormType> = inputValues => {
    const { timeRange } = inputValues;
    setCalendarSettings({ ...inputValues, timeRange });
    setToast({ type: 'success', message: CALENDAR_SETTING_TEXT.main.toastMessage, bottom: 76 });
    navigate('/schedule');
  };

  return (
    <Container onSubmit={handleSubmit(submit)} footerButton={{ text: '설정 완료' }}>
      <div className="form-content">
        <TimeRange />
        <DefaultViewType />
        <DayRange />
        <ClosedClassBackground />
        <EtcScheduleHide />
      </div>
    </Container>
  );
};

const Container = styled(Form)`
  .form-content {
    ${theme.flex('column', '', '', 8)};
    padding: 16px 20px 32px;
  }

  footer {
    background: transparent;
  }
`;

export default CalendarSettingForm;

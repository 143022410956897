import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { TicketCommon } from './useInfinityUserTicketProduct';

const useInfinityBookingList = (lectureId: number) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<LectureBookingListResponse>({
    url: `/api/booking/list/${lectureId}`,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['booking', 'list', lectureId],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityBookingList;

export type LectureBookingListResponse = {
  id: number;
  lecture_id: number;
  status: 'booked' | 'booking_confirmed' | 'booking_waiting' | 'booking_pending' | 'attendance' | 'absence' | 'noshow' | 'cancel';
  member: {
    id: number;
    user_grade_id: number | null;
    name: string;
    account_type: string | null;
    account_id: number | null;
    mobile: string | null;
    vaccination_yn: 'Y' | 'N';
    image: Image | null;
  };
  user_ticket: {
    id: number;
    is_shared: boolean;
    usable_coupon: number;
    availability_start_at: string;
    expire_at: string;
    ticket: Pick<TicketCommon, 'title' | 'type'> & {
      id: number;
    };
  };
};

export type Image = {
  path: string;
  name: string;
  extension: string;
};

import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useGetStaffEventSchedules from 'hooks/service/queries/useGetStaffEventSchedules';
import useParamsId from 'hooks/useParamsId';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';
import getEventTimeDatasetNew from 'utils/getEventTimeDatasetNew';

import EventScheduleUpdateForm from './components/EventScheduleUpdateForm';

const EventScheduleUpdate = () => {
  const navigate = useNavigate();

  const targetStaffId = useParamsId();
  const { data: eventSchedules } = useGetStaffEventSchedules({ targetStaffId });
  const eventTimesDataset = getEventTimeDatasetNew(eventSchedules);

  return (
    <MainLayout
      header={{
        title: '특정일 근무시간 추가',
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <Container>
        <ApiBoundary>
          <EventScheduleUpdateForm eventTimesDataset={eventTimesDataset} />
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default EventScheduleUpdate;

const Container = styled.div`
  padding-bottom: 40px;
  height: 100%;

  .sub-title {
    margin: 8px 0 40px;
    padding: 0 20px;
  }
`;

import { yupResolver } from '@hookform/resolvers/yup';
import useGetMemberDetail from 'hooks/service/queries/useGetMemberDetail';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import useInfinityUserTicketProduct from 'hooks/service/queries/useInfinityUserTicketProduct';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import getImageUrl from 'utils/getImageUrl';
import { memberNameRequired, mobile } from 'utils/validate';
import { object } from 'yup';

import MemberFormMainLayout from '../components/MemberFormMainLayout';
import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';
import MemberUpdateForm from './MemberUpdateForm';

type Props = {
  currentMemberId: number;
};

const yupSchema = object().shape({ mobile, profile: object({ name: memberNameRequired }) });

const FormMain = ({ currentMemberId }: Props) => {
  const currentStudioId = useRecoilValue(studioIdAtom);
  const {
    data: {
      mobile,
      address,
      user_grade,
      vaccination_yn,
      avatar,
      profile: { name, registered_at, gender, birthday, reward_point, paid_point },
    },
  } = useGetMemberDetail(currentMemberId);
  const { data: currentUserTickets } = useInfinityUserTicketProduct({
    user_id: currentMemberId,
    product_type: 'all',
    status: 'active',
  });
  const {
    data: {
      policy: { is_use_user_grade },
    },
  } = useGetStudioInfo(currentStudioId);

  const methods = useForm<MemberFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    shouldFocusError: false,
    defaultValues: {
      image: !avatar ? '' : getImageUrl(avatar.image),
      beforeName: name,
      mobile: mobile || '',
      address: address
        ? {
            address: address.address ?? '',
            detail_address: address.detail_address ?? '',
          }
        : undefined,
      userGradeId: !user_grade ? MEMBER_FORM_TEXT.userGrade.notSelected.value : String(user_grade.id),
      vaccination_yn: vaccination_yn === 'Y',
      profile: {
        name,
        registered_at,
        gender: gender || 'F',
        birthday: birthday ?? undefined,
      },
      userTickets: currentUserTickets.map(userTicket => {
        return {
          userTicketId: userTicket.id,
          ...userTicket,
        };
      }),
      tickets: [],
      updateUserTickets: [],
      search: '',
      tempSearchKeyword: '',
      selectedTicket: null,
      selectedCreatedTicket: undefined,
      currentMemberPoint: reward_point + paid_point,
    },
  });

  return (
    <MemberFormMainLayout pageMode="update" {...methods}>
      <MemberUpdateForm currentMemberId={currentMemberId} isUserGrade={!!is_use_user_grade} />
    </MemberFormMainLayout>
  );
};

export default FormMain;

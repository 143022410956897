import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import Typography from 'components/Typography';
import useGetBookingCount from 'hooks/service/queries/useGetBookingCount';
import { isArray } from 'lodash';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { AllPageModeType } from 'pages/Booking/types';
import { convertRepeatWeekdayNum } from 'pages/Booking/utils';
import { containsExactElements, getIds } from 'pages/Booking/utils/selectMembers';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import filters from 'utils/filters';

import ValidErrorDrawer from '../ValidErrorDrawer';
import List from './List';
import ReserveUserTickets from './ReserveUserTickets';
import SaveButton from './SaveButton';

type Props = {
  pageMode: AllPageModeType;
};

const SelectMemberForm = ({ pageMode }: Props) => {
  const { getValues, setValue } = useFormContext<BookingPrivateFormType>();
  const navigate = useNavigate();

  const { userTicketIds, originUserTicketIds, selectedUserTickets, startDate, endDate, repeat, repeatCustomWeekday } =
    getValues();
  const [isRequestCountValid, setIsRequestCountValid] = useState(false);
  const [isOpenValidationDrawer, setIsOpenValidationDrawer] = useState(false);

  const queryClient = useQueryClient();
  const { data: bookingCountValidation } = useGetBookingCount(isRequestCountValid, {
    start_date: filters.dateDashTimeSecondZero(startDate),
    end_date: filters.dateDashTimeSecondZero(endDate),
    user_ticket_ids: getIds({ userTicketIds, originUserTicketIds }),
    member_ids: selectedUserTickets.map(({ member }) => member.id),
    weekday: convertRepeatWeekdayNum(repeat, repeatCustomWeekday),
  });

  const removeQuery = () => {
    queryClient.removeQueries({
      queryKey: ['booking/count'],
    });
  };

  const submit = () => {
    removeQuery();
    // 하단 완료 버튼 클릭하면 수업 생성때나 기존 선택된 아이디와 다른 아이디일 때 검증 요청
    if (pageMode === 'create' || !containsExactElements({ userTicketIds, originUserTicketIds })) {
      setIsRequestCountValid(true);
    } else {
      navigate(-1);
    }
  };

  const confirm = () => {
    setIsRequestCountValid(false);
    setIsOpenValidationDrawer(false);
    removeQuery();
    navigate(-1);
  };

  useEffect(() => {
    if (!bookingCountValidation) return;
    setIsRequestCountValid(false);

    /** 초과 예약 검증 걸린 것이 없어서 바로 예약하면 될 때 (반환값으로 빈배열이 옴) */
    if (isArray(bookingCountValidation)) {
      return navigate(-1);
    }

    /** 초과 예약 걸렸을 때 확인 dialog 노출 */
    if (bookingCountValidation) {
      setIsOpenValidationDrawer(true);
    }
  }, [bookingCountValidation, navigate]);

  const savePrevUserTickets = () => {
    setValue('prevSelectedUserTickets', [...selectedUserTickets]);
  };

  useEffect(() => {
    savePrevUserTickets();
    return () => {
      removeQuery();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormContainer>
        <ReserveUserTickets />
        <ListTitleTypography size={13} weight={500} textColor="gray2">
          회원목록
        </ListTitleTypography>
        <ApiBoundary>
          <List />
        </ApiBoundary>
        <SaveButton onClick={submit} />
      </FormContainer>

      {bookingCountValidation && (
        <ValidErrorDrawer
          bookingCountValidation={bookingCountValidation.limit}
          isOpen={isOpenValidationDrawer}
          onClose={() => {
            setIsOpenValidationDrawer(false);
          }}
          onClick={confirm}
        />
      )}
    </>
  );
};

export default SelectMemberForm;

const FormContainer = styled(Form)`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 76px;
  ${theme.flex('column', '', '')};
`;

const ListTitleTypography = styled(Typography)`
  width: 100%;
  flex: 0 0 auto;
  padding: 11px 20px 4px;
`;

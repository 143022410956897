import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Switch from 'components/Switch';
import { Controller, useFormContext } from 'react-hook-form';

import { BOOKING_GROUP_FORM_TEXT } from '../../constants';
import { BookingGroupCreateFormType } from '../../types';

const BookingRequiredSwitch = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();

  return (
    <Controller
      name="is_booking_only"
      control={control}
      render={({ field: { value, ...field } }) => (
        <StyledSwitch
          id="is_booking_only"
          label={BOOKING_GROUP_FORM_TEXT.switch.label}
          fontSize={13}
          fontWeight={500}
          textColor="gray2"
          defaultChecked={value}
          {...field}
        />
      )}
    />
  );
};

export default BookingRequiredSwitch;

const StyledSwitch = styled(Switch)`
  background-color: ${theme.color.gray8};
  height: 56px;

  .switch-contents-wrapper {
    padding: 16px 20px;
  }
`;

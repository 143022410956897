import { ControllerRenderProps, FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { Props as UnderlinedTextareaOptions } from './type';
import UnderlinedTextarea from './UnderlinedTextarea';

type Props<T extends FieldValues> = Omit<Exclude<UnderlinedTextareaOptions, ControllerRenderProps<T>>, 'id'> & {
  controllerProps: UseControllerProps<T>;
};
const UnderlinedTextareaController = <T extends FieldValues>({ controllerProps, ...inputProps }: Props<T>) => {
  const { field } = useController(controllerProps);
  return <UnderlinedTextarea id={field.name} {...field} {...inputProps} />;
};

export default UnderlinedTextareaController;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MainBackgroundColorWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import StudioExceptionCalendarFallback from './StudioExceptionCalendarFallback';

const ScheduleStudioExceptionLayout = () => {
  return (
    <Container>
      <section className="exception-header">
        <Typography size={15} weight={600} textColor="white" opacity={0.64}>
          시설 없음
        </Typography>

        <div className="setting-button">
          <IconButton onClick={() => null}>
            <Icon name="headerSetting" color="white" />
          </IconButton>
        </div>
      </section>

      <StudioExceptionCalendarFallback />
    </Container>
  );
};

export default ScheduleStudioExceptionLayout;

const Container = styled(MainBackgroundColorWrapper)`
  .exception-header {
    ${theme.flex()};
    padding: 17px 20px;

    .setting-button {
      position: absolute;
      right: 20px;
    }
  }
`;

import { IHeaderProps } from 'components/Header';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import StudioList from '../../../sharedComponents/Studio/StudioList';
import DeleteButton from './components/DeleteButton';
import { STUDIO_DETAIL_TEXTS } from './constants';

const MoreDetailsStudio = () => {
  const headerOptions: IHeaderProps = {
    title: STUDIO_DETAIL_TEXTS.title,
    rightButton: (
      <ApiBoundary>
        <DeleteButton />
      </ApiBoundary>
    ),
  };
  return (
    <MainLayout header={headerOptions}>
      <ApiBoundary>
        <StudioList type="detail" />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsStudio;

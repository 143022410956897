import styled from '@emotion/styled';
import { queryClient } from 'api/queryClient';
import { theme } from 'assets/styles';
import { AxiosResponse } from 'axios';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import { StudioInfoResponse } from 'hooks/service/queries/useGetStudioInfo';
import MessagePreview from 'pages/MoreDetails/Message/components/MessagePreview';
import { MessageType } from 'pages/MoreDetails/Message/types';
import { Fragment } from 'react/jsx-runtime';
import { useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';

import { APP_PUSH_DUMMY_LENGTH } from '../constants';

const AppPushPreview = () => {
  /**
   * 미리보기 화면에서 시설이름을 띄우기 위해 앱푸시 보낼때마다 api 호출하는 건 비효율적
   * 더보기 화면을 거쳐왔다면 남아있을 캐시를 활용함, 캐시가 없으면 [시설 이름]으로 노출
   */
  const studioId = useRecoilValue(studioIdAtom);
  const studioName =
    queryClient.getQueryData<AxiosResponse<StudioInfoResponse>>(['studio_info', studioId])?.data.name || '시설 이름';

  const { control } = useFormContext<MessageType>();
  const title = useWatch({ control, name: 'title' });
  const message = useWatch({ control, name: 'message' });

  const dummyArray = Array.from({ length: APP_PUSH_DUMMY_LENGTH }, (_, i) => i);

  return (
    <MessagePreview>
      <Push>
        <IconWrapper className="icon">
          <Icon name="alertFill" size={18} color="gray2" />
        </IconWrapper>
        <div>
          <Typography size={14} weight={700} ellipsis={1}>
            {`[${studioName}] ${title}`}
          </Typography>
          <Typography size={14} weight={500} textColor="gray2" ellipsis={2} className="message">
            {message}
          </Typography>
          <Typography size={13} weight={500} textColor="gray3">
            더보기
          </Typography>
        </div>
      </Push>
      {dummyArray.map(i => (
        <Fragment key={i}>
          <DividerWithMargin thin thinColor="gray6" />
          <Push>
            <Skeleton width={32} height={32} type="circle" className="icon" />
            <Dummy>
              <Skeleton height={22} type="round" />
              <Skeleton height={44} type="round" />
            </Dummy>
          </Push>
        </Fragment>
      ))}
    </MessagePreview>
  );
};

export default AppPushPreview;

const Push = styled.div`
  ${theme.flex('', '', '', 14)}
  width: 100%;
  .message {
    margin: 2px 0 8px;
  }
  .icon {
    flex-shrink: 0;
  }
`;

const IconWrapper = styled.div`
  background-color: ${theme.color.gray5};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  ${theme.flex()}
  svg {
    opacity: 0.4;
  }

  // 아이콘 내부 알람표시 숨김
  circle {
    display: none;
  }
`;

const DividerWithMargin = styled(Divider)`
  margin: 24px 0;
`;

const Dummy = styled.div`
  width: 100%;
  ${theme.flex('column', '', '', 8)}
  margin-top: 4px;
`;

import useInfinityMember from 'hooks/service/queries/useInfinityMember';
import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { useFormContext, useWatch } from 'react-hook-form';

import MemberList from './MemberList';

const AllMemberList = () => {
  const { control, getValues } = useFormContext<TicketTransferFormType>();
  const search = useWatch({ control, name: 'search' });

  const {
    data: { members },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityMember({
    transferor_id: getValues('currentMemberId'),
    tag: 'all',
    sort_target: 'name',
    sort_type: 'asc',
    search,
  });

  return <MemberList members={members} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage} />;
};

export default AllMemberList;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetDivision = () => {
  const { queryFn } = useQueryFnWithCommonParams<Array<DivisionResponse>>({
    url: `/api/division`,
  });

  return useSuspenseQuery({
    queryKey: [`division`],
    queryFn,
    select: data => data.data,
  });
};

export default useGetDivision;

export type DivisionResponse = {
  id: number;
  studio_id: number;
  title: string;
  updated_at: string;
  created_at: string;
  deleted_at: string | null;
  /** 업데이트 한 강사 아이디 */
  updated_for: number;
};

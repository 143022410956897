import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LecturePrivatePatchParams, LecturePrivatePatchResponse, LectureValidationError } from './usePatchLecturePrivate';

/** 그룹 수업 1개 수정 */
const usePatchLectureGroup = (lectureId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LectureGroupPatchParams, LecturePrivatePatchResponse>({
    method: `patch`,
    url: `/api/lecture/group/${lectureId}`,
  });

  return useCustomMutation<AxiosResponse, AxiosError<LectureValidationError>, LectureGroupPatchParams | undefined, unknown>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail', lectureId],
      });
    },
  });
};

export default usePatchLectureGroup;

export type LectureGroupPatchParams = LecturePrivatePatchParams & {
  description?: string;
  division_id?: number;
  max_trainee?: number;
  min_trainee?: number;
  waiting_trainee_limit?: number;
  is_booking_only: boolean;
  /** 예약 변경 가능 시간 */
  daily_change_booking_end_at?: string;
  /** 자동 폐강 시간 */
  autoclose_at?: string;
  /** 예약 대기 자동 예약 시간 */
  booking_auto_shift_available_minutes_from_start?: string;
};

import { useIsFetching } from '@tanstack/react-query';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';

import MessageFloatingButton from './components/MessageFloatingButton';
import MessageListPage from './components/MessageListPage';
import { MESSAGE_IDENTIFIER, MESSAGE_LIST_TEXT } from './constants';

const MoreDetailsMessageList = () => {
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
      <MainLayout
        header={{
          title: MESSAGE_LIST_TEXT.title,
          bgColor: 'white',
          rightButton: <HeaderSearchButton identifier={MESSAGE_IDENTIFIER} placeholder={MESSAGE_LIST_TEXT.placeholder} />,
          noUnderline: true,
        }}
        backgroundColor="gray6"
        childrenFloatingButton={
          <ApiBoundary>
            <MessageFloatingButton />
          </ApiBoundary>
        }>
        <MessageListPage />
      </MainLayout>
    </ApiBoundary>
  );
};

export default MoreDetailsMessageList;

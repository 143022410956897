import { TabsCommonProps } from '.';
import FilterTab, { FilterTabProps } from './FilterTab';
import TabGroup from './TabGroup';

export type FilterTabsProps = TabsCommonProps &
  Omit<FilterTabProps, 'isSelected' | 'onClick' | 'data'> & {
    /** Tabs 가로 스크롤 처리 */
    scrollable?: boolean;
  };

const FilterTabs = ({ data, value, scrollable = true, onChange, className, heightSize = 30, gap, ...props }: FilterTabsProps) => {
  return (
    <TabGroup scrollable={scrollable} gap={gap ?? 6}>
      {data.map(tab => {
        const isSelected = tab.value === value;
        return (
          <FilterTab
            {...props}
            className={`${className ?? ''}${isSelected ? ' selected' : ''}`}
            key={tab.value}
            data={tab}
            isSelected={isSelected}
            heightSize={heightSize}
            onClick={onChange}
          />
        );
      })}
    </TabGroup>
  );
};

export default FilterTabs;

type Result = {
  [key: string]: string | string[];
};

const getAllSearchParamsUtils = (searchParams: URLSearchParams) => {
  const result: Result = {};

  for (const [key, value] of searchParams.entries()) {
    // 파라미터가 이미 존재한다면 값을 배열로 바꾼 후에 값을 추가
    if (key in result) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key] as string];
      }
      (result[key] as string[]).push(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

export default getAllSearchParamsUtils;

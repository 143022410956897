import styled from '@emotion/styled';
import Typography from 'components/Typography';

type Props = {
  label: string;
  description?: string;
};

const MenusLabel = ({ label, description }: Props) => {
  return (
    <Container>
      <Typography size={15} weight={600}>
        {label}
      </Typography>

      <Typography textColor="gray2">{description}</Typography>
    </Container>
  );
};

export default MenusLabel;

const Container = styled.div`
  > p:first-of-type {
    padding-bottom: 2px;
  }
`;

import styled from '@emotion/styled';
import { MbscDatepickerOptions } from '@mobiscroll/react';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';

import DateCalendarPicker from '.';

type Props = MbscDatepickerOptions & {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  select?: 'range' | 'preset-range' | 'date';
  zIndex?: number;
};

const DateCalendarPickerDrawer = ({ isOpen, onClose, onSave, zIndex, ...props }: Props) => {
  return (
    <StyledDoubleButtonDrawer
      type={3}
      hideHandle
      zIndex={zIndex}
      isOpen={isOpen}
      onClose={onClose}
      leftButton={{
        text: '취소',
        onClick: onClose,
      }}
      rightButton={{
        text: '완료',
        onClick: onSave,
      }}>
      <DateCalendarPicker {...props} />
    </StyledDoubleButtonDrawer>
  );
};

export default DateCalendarPickerDrawer;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  /** 캘린더 Drawer는 기본 Drawer와 패딩이 달라서 따로 커스텀  */
  padding: 24px 0 calc(16px + 76px);
  && .date-range-picker {
    padding: 0;
  }

  /** 바텀시트 형태에서 변형되는 스타일들 */
  /** YYYY년 M월 타이틀 */
  .title > .typography {
    font-size: 1.9rem;
    line-height: 26px;
  }

  /** 기간선택 segment 와의 간격 */
  && .mbsc-ios.mbsc-range-control-wrapper {
    padding-bottom: 16px;
  }
`;

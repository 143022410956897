/**
 * JSON.parse 실행전 문자열이 JSON 형식인지 확인
 *  @param str - JSON 형식인지 확인할 문자열, 옵셔널로 전달 가능
 */
export const isParsableToJson = (str: string | undefined | null) => {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    const parsed = JSON.parse(str);
    if (parsed && typeof parsed === 'object') {
      return true;
    }
  } catch {
    return false;
  }
  return false;
};

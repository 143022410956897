import FloatingActionButton, { ActionsType } from 'components/FloatingActionButton';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router-dom';
import { PermissionType } from 'types/permissionTypes';

const MessageFloatingButton = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const actions: Array<ActionsType & { permission: PermissionType }> = [
    {
      label: '앱푸시 보내기',
      icon: 'alert',
      onClick: () => {
        checkPermission(PERMISSION.message.push.send.id, {
          onSuccess: () => navigate(`push/form`),
        });
      },
      permission: PERMISSION.message.push.send,
    },
    {
      label: '문자 보내기',
      icon: 'message',
      onClick: () => {
        checkPermission(PERMISSION.message.sms.send.id, {
          onSuccess: () => navigate(`mobile/form`),
        });
      },
      permission: PERMISSION.message.sms.send,
    },
  ];

  const allowedActions = actions.filter(action => hasPermission(action.permission));

  if (!allowedActions.length) {
    return null;
  }
  return <FloatingActionButton actions={allowedActions} bottom={24} />;
};

export default MessageFloatingButton;

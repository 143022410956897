import styled from '@emotion/styled';
import Accordion from 'components/Accordion';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import RangeTimePicker from 'designedComponents/Pickers/TimePicker/RangeTimePicker';
import { useMemo, useState } from 'react';
import filters from 'utils/filters';

import { CommonProps } from '../../../types';
import { DEFAULT_RANGE } from '../constants';

type Props = Pick<CommonProps, 'workTimeField' | 'setWorkTimeField' | 'isAccordionOpen' | 'setIsAccordionOpen'>;

const WorkTimeAccordion = ({ workTimeField, setWorkTimeField, isAccordionOpen, setIsAccordionOpen }: Props) => {
  const [timeRange, setTimeRange] = useState<[Date, Date]>(DEFAULT_RANGE);

  const { start_time, end_time } = workTimeField;

  const timeRangeDataset = useMemo(() => {
    const startHour = start_time?.slice(0, 2);
    const startMinute = start_time?.slice(3, 5);
    const endHour = end_time?.slice(0, 5);
    const endMinute = end_time?.slice(3, 5);

    return {
      start: {
        hour: parseInt(startHour || '09'),
        minute: parseInt(startMinute || '00'),
      },
      end: {
        hour: parseInt(endHour || '18'),
        minute: parseInt(endMinute || '00'),
      },
    };
  }, [start_time, end_time]);

  const timeText = useMemo(() => {
    return filters.timePeriodForOnlyTime(start_time, end_time);
  }, [start_time, end_time]);

  const endMinTime = useMemo(() => {
    const formatDate = dayjs(timeRange[0]).add(5, 'minute');
    return dayjs(formatDate).format('HH:mm:00');
  }, [timeRange]);

  return (
    <div>
      <Accordion
        isOpen={isAccordionOpen}
        onChange={() => {
          setIsAccordionOpen(!isAccordionOpen);
          const { start, end } = timeRangeDataset;
          setTimeRange([
            dayjs().set('hour', start.hour).set('minute', start.minute).toDate(),
            dayjs().set('hour', end.hour).set('minute', end.minute).toDate(),
          ]);
        }}
        header={
          <AcordionHeader>
            <Typography size={15} weight={600} textColor={isAccordionOpen ? 'primary' : 'gray2'}>
              {timeText}
            </Typography>
          </AcordionHeader>
        }
        showArrow={false}>
        <Divider thin />
        <RangeTimePicker
          stepMinute={5}
          timeRange={timeRange}
          onChange={value => {
            setTimeRange(value);
            setWorkTimeField({
              ...workTimeField,
              start_time: dayjs(value[0]).format('HH:mm:00'),
              end_time: dayjs(value[1]).format('HH:mm:00'),
            });
          }}
          startMaxTime="23:50"
          endMinTime={endMinTime}
        />
      </Accordion>
    </div>
  );
};

export default WorkTimeAccordion;

const AcordionHeader = styled.div`
  padding: 0 20px;
`;

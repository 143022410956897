import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import FindPwdCheckMobile from './components/FindPwdCheckMobile';
import FindPwdCheckPassword from './components/FindPwdCheckPassword';

const FindPassword = () => {
  const navigate = useNavigate();

  const setCustomBackHandlers = useSetRecoilState(customBackHandlersAtom);
  const { getSearchParams, setSearchParams } = useQueryString();

  // 1: 비밀번호 찾기, 2: 비밀번호 변경
  const currentStep = Number(getSearchParams('step'));

  const nextStep = useCallback(() => {
    if (currentStep !== 3) {
      setSearchParams({ step: String(currentStep + 1) });
    }
  }, [setSearchParams, currentStep]);

  const prevStep = useCallback(() => {
    switch (currentStep) {
      case 1:
      default:
        navigate('/login');
        break;
      case 2:
      case 3:
        setSearchParams({ step: String(1) });
    }
  }, [currentStep, navigate, setSearchParams]);

  useEffect(() => {
    setCustomBackHandlers(prev => [...prev, { key: 'password-back', handler: prevStep }]);
    return () => {
      setCustomBackHandlers(prev => prev.filter(({ key }) => key !== 'password-back'));
    };
  }, [prevStep, setCustomBackHandlers]);

  const headerProps = {
    leftButton: (
      <IconButton onClick={prevStep}>
        <Icon name="headerBack" />
      </IconButton>
    ),
  };

  return (
    <MainLayout header={headerProps}>
      <Container>
        <ApiBoundary>
          {currentStep !== 3 ? (
            <FindPwdCheckMobile currentStep={currentStep} nextStep={nextStep} />
          ) : (
            <FindPwdCheckPassword currentStep={currentStep} />
          )}
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default FindPassword;

const Container = styled.div`
  padding: 8px 20px 0;

  form {
    .submit-btn {
      margin-top: 8px;
    }
  }
`;

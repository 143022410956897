import styled from '@emotion/styled';
import React from 'react';

export type IInputFileProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'accept'>;

const InputFile = React.forwardRef<HTMLInputElement, IInputFileProps>(({ ...props }, ref) => {
  return <StyledInput type="file" ref={ref} multiple accept="image/*" {...props} />;
});

const StyledInput = styled.input`
  display: none;
`;

InputFile.displayName = 'InputFile';
export default InputFile;

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { PLACEHOLDER } from 'constants/text';
import TitleStep from 'pages/FindPassword/components/TitleStep';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { password, passwordConfirmation } from 'utils/validate';
import { object } from 'yup';

import { SUB_TITLE } from '../constants';
import { PasswordDataType, PasswordFormType } from '../types';

type Props = {
  nextStep: (data: PasswordDataType) => void;
};

const yupSchema = object().shape({ password, passwordConfirmation });

const PasswordStep = ({ nextStep }: Props) => {
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [confirmedShowPwd, setConfirmedShowPwd] = useState(false);

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
    control,
    trigger,
    watch,
  } = useForm<PasswordFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const save: SubmitHandler<PasswordFormType> = async inputValues => {
    const { password, passwordConfirmation } = inputValues;

    const addData = {
      password,
      password_confirmation: passwordConfirmation,
    };
    nextStep(addData);
  };

  const subTitleOptions = {
    bottom: 40,
    title: SUB_TITLE.ENTER_PASSWORD,
    message: { position: 'bottom', text: '영문, 숫자를 포함한 8~32자로 입력해 주세요.' },
  };

  return (
    <>
      <TitleStep currentStep={2} subTitleOptions={subTitleOptions} />
      <Form onSubmit={handleSubmit(save)}>
        <OutlinedTextFieldController
          controllerProps={{ control, name: 'password' }}
          type={showPwd ? 'text' : 'password'}
          placeholder={PLACEHOLDER.PASSWORD}
          suffix={
            <IconButton onClick={() => setShowPwd(!showPwd)}>
              <Icon name={showPwd ? 'showOff' : 'showOn'} fillColor={theme.color.gray3} />
            </IconButton>
          }
          onChange={() => {
            if (watch('passwordConfirmation')) {
              trigger('passwordConfirmation');
            }
          }}
        />

        <OutlinedTextFieldController
          controllerProps={{ control, name: 'passwordConfirmation' }}
          type={confirmedShowPwd ? 'text' : 'password'}
          placeholder={PLACEHOLDER.CONFIRM_PASSWORD}
          suffix={
            <IconButton onClick={() => setConfirmedShowPwd(!confirmedShowPwd)}>
              <Icon name={confirmedShowPwd ? 'showOff' : 'showOn'} fillColor={theme.color.gray3} />
            </IconButton>
          }
        />

        <PasswordConfirmButton
          type="submit"
          variant="contained"
          color="point"
          fullWidth
          size="medium48"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit(save)}>
          다음
        </PasswordConfirmButton>
      </Form>
    </>
  );
};

export default PasswordStep;

const PasswordConfirmButton = styled(Button)`
  margin-top: 16px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Typography from 'components/Typography';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

type Props = {
  data: InfoValueRowType[];
  settlementAt: string;
  leftButton: Pick<ButtonProps, 'children' | 'onClick'> | null;
  /** 수정 버튼 클릭 이벤트 */
  onClick?: () => void;
};

const PaymentDetailCard = ({ data, settlementAt, leftButton, onClick }: Props) => {
  return (
    <Container showBoxShadow={false}>
      <InfoFlexWrapper gap={4} className="date-text-wrapper">
        <Typography size={13} textColor="gray2">
          결제일시
        </Typography>
        <Typography size={15} weight={600}>
          {filters.dateTimeSecondWeekday(settlementAt)}
        </Typography>
      </InfoFlexWrapper>

      <InfoFlexWrapper gap={8}>
        {data.map(({ label, value, textColor }) => (
          <InfoFlexRow key={`${label}-${value}`}>
            <InfoTypography>{label}</InfoTypography>
            <InfoTypography weight={500} textColor={textColor || 'gray2'}>
              {value}
            </InfoTypography>
          </InfoFlexRow>
        ))}
      </InfoFlexWrapper>

      {!!onClick && (
        <ButtonGroup>
          {leftButton && (
            <Button fullWidth size="medium40" variant="outlined" onClick={leftButton.onClick}>
              {leftButton.children}
            </Button>
          )}
          <Button fullWidth size="medium40" variant="contained" color="gray" onClick={onClick}>
            수정
          </Button>
        </ButtonGroup>
      )}
    </Container>
  );
};

export default PaymentDetailCard;

const Container = styled(CommonStyleCard)`
  border: 1px solid ${theme.color.gray5};

  .date-text-wrapper {
    margin-bottom: 12px;
  }

  .button-group {
    margin-top: 16px;
  }
`;

import styled from '@emotion/styled';
import SearchBar from 'components/SearchBar';
import { BOOKING_PRIVATE_FORM_TEXT } from 'pages/Booking/Private/constants';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BookingSelectMemberFormType } from '../types';

const Search = () => {
  const { setValue } = useFormContext<BookingSelectMemberFormType>();
  const [tempSearch, setTempSearch] = useState('');

  return (
    <Container>
      <SearchBar
        id="booking-select-member-search-bar"
        placeholder={BOOKING_PRIVATE_FORM_TEXT.selectMember.search.placeholder}
        value={tempSearch}
        onClear={() => setTempSearch('')}
        onCancel={() => {
          setTempSearch('');
          setValue('search', undefined);
        }}
        onChange={e => setTempSearch(e.target.value)}
        onSubmitForm={() => setValue('search', tempSearch)}
      />
    </Container>
  );
};

export default Search;

const Container = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding: 0 20px 16px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import OutlinedTextField from 'components/TextField/components/OutlinedTextField';
import Typography from 'components/Typography';
import { useMemo, useState } from 'react';

import { DisplayNameType, RoleRemoveType, RoleUpdateType } from '../types';
import IconGroup from './IconGroup';
import ModifyIconGroup from './ModifyIconGroup';

type Props = {
  roleIndex: number;
  role: DisplayNameType;
  remove: RoleRemoveType;
  update: RoleUpdateType;
};

const RoleItem = ({ roleIndex, role, ...props }: Props) => {
  const [inputName, setInputName] = useState('');

  const isFixedRole = useMemo(() => {
    switch (role.displayName) {
      case '스튜디오 오너':
      case '매니저':
      case '강사':
      case '고객':
        return true;
      default:
        return false;
    }
  }, [role.displayName]);

  return (
    <Container>
      {role.isModify ? (
        <>
          <OutlinedTextField
            placeholder="최대 8자 입력"
            id={roleIndex.toString()}
            defaultValue={role.displayName}
            onChange={e => setInputName(e.target.value)}
            autoFocus
          />
          <ModifyIconGroup roleIndex={roleIndex} inputName={inputName} role={role} {...props} />
        </>
      ) : (
        <>
          <Typography size={15} weight={500}>
            {role.displayName}
          </Typography>
          {!isFixedRole && <IconGroup roleIndex={roleIndex} role={role} {...props} />}
        </>
      )}
    </Container>
  );
};

export default RoleItem;

const Container = styled.li`
  ${theme.flex('row', 'center', 'space-between', 17)};
  height: 38px;

  .outlined-text-field {
    width: 231px;
    .empty {
      background-color: ${theme.color.white};
    }

    input {
      z-index: 1;
      padding: 13px 16px;
    }
  }
`;

import { BOOKING_FORM_DEFAULT_HEADER_TITLE_TEXT, BOOKING_FORM_DETAIL_HEADER_TITLE_TEXT } from '../constants';
import { BookingType } from '../types';

type Props = {
  bookingType: BookingType;
  mode?: string | string[];
  type?: string | string[];
  setting?: string | string[];
  hasTitle?: boolean;
};

/** 수업 예약 폼의 페이지 별 header title 반환 */
const getFormHeaderTitle = ({ bookingType, mode, type, setting, hasTitle = true }: Props) => {
  if (!hasTitle) {
    return '';
  }

  /** 폼 메인 화면 */
  if (mode && !type && !setting) {
    return BOOKING_FORM_DEFAULT_HEADER_TITLE_TEXT[bookingType][mode as string];
  }

  /** 폼에서 디테일 설정 페이지 들어갈 때 */
  const key = (setting || type) as keyof typeof BOOKING_FORM_DETAIL_HEADER_TITLE_TEXT;
  return BOOKING_FORM_DETAIL_HEADER_TITLE_TEXT[key];
};

export default getFormHeaderTitle;

import Typography from 'components/Typography';

const FilterTitle = ({ title }: { title: string }) => {
  return (
    <Typography className="filter-title" size={15} weight={700}>
      {title}
    </Typography>
  );
};

export default FilterTitle;

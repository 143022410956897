import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { TicketTransferCommonProps } from 'pages/TicketDetail/types';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import TicketSubTitle from '../../TicketSubTitle';
import AllMemberList from './AllMemberList';
import AssignedMemberList from './AssignedMemberList';
import NextButton from './NextButton';
import Search from './Search';

type Props = TicketTransferCommonProps & {
  canViewMembers: boolean;
};

const SelectMember = ({ canViewMembers, productTypeText, onClickNextPage, title, subTitle }: Props) => {
  return (
    <Container>
      <>
        <TicketSubTitle title={title} message={`${productTypeText}${subTitle}`} />
        <Search />
      </>

      <ApiBoundary>{canViewMembers ? <AllMemberList /> : <AssignedMemberList />}</ApiBoundary>

      <NextButton onClickNextPage={onClickNextPage} />
    </Container>
  );
};

export default SelectMember;

const Container = styled.div`
  height: 100%;
  padding-bottom: 76px;
  position: relative;
  ${theme.flex('column', '', '')};
`;

import useQueryString from 'hooks/useQueryString';
import { useMemo } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import PermissionSettingsProvider from './components/PermissionSettingsProvider';
import { HEADER_TEXT, HEADER_TITLE } from './constants';
import { PermissionPagesType } from './types';

const MoreDetailsStaffPermissionSettings = () => {
  const { getSearchParams } = useQueryString();

  const pageType = getSearchParams('type');

  const title = useMemo(() => {
    if (!pageType) return HEADER_TEXT.permissionSettings;
    return HEADER_TITLE[pageType as PermissionPagesType];
  }, [pageType]);

  return (
    <MainLayout header={{ title }}>
      <ApiBoundary>
        <PermissionSettingsProvider />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsStaffPermissionSettings;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import React from 'react';
import { ColorType } from 'types/colorTypes';

export interface ButtonBaseProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  /** 버튼의 클래스네임 */
  className?: string;
  /** 버튼 텍스트 또는 아이콘 컴포넌트 */
  children: React.ReactNode;
  /** 버튼 타입 */
  variant?: 'contained' | 'outlined';
  /** 버튼 색상 */
  color?: 'primary' | 'point' | 'blue' | 'gray';
  /** border 색상 */
  outlineColor?: ColorType;
  /** 가장자리 스타일 */
  edge?: 'square' | 'circular';
  /** 버튼 사이즈 (높이) */
  size?: 'tiny' | 'tiny2' | 'small' | 'medium40' | 'medium48' | 'large56' | 'large60';
  /** 버튼이 차지하는 너비 */
  fullWidth?: boolean;
  /** 버튼의 너비 px */
  widthSize?: number;
  /** 버튼의 높이 px (size 로 스타일 줄 수 없을 때) */
  heightSize?: number;
  /** 가장자리 스타일 number는 px, '50%'는 정원형
   * (edge, size 속성에 따른 가장자리 스타일을 줄 수 없을 때)
   * */
  borderRadius?: number | '50%';
  /** 양옆 padding */
  padding?: { left?: number; right?: number };
  /** 메인 텍스트와 메인 텍스트 양옆 요소 사이의 간격 */
  gap?: number;
  /** 버튼의 타입 */
  type?: 'button' | 'submit';
}

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({ className, children, type = 'button', ...props }, ref) => {
    return (
      <StyledButton className={className} ref={ref} type={type} {...props}>
        {children}
      </StyledButton>
    );
  },
);

export const StyledButton = styled.button<ButtonBaseProps>(
  /** 공통 스타일 */
  ({ widthSize, heightSize, fullWidth, padding, gap }) => css`
    ${theme.flex('', 'center', 'center', gap)};
    width: ${fullWidth ? '100%' : widthSize ? `${widthSize}px` : 'auto'};
    min-height: ${heightSize ? `${heightSize}px` : 'auto'};
    background-color: transparent;
    padding-left: ${padding?.left || 0}px;
    padding-right: ${padding?.right || 0}px;
    border-radius: 8px;
  `,

  /** 버튼 크기 */
  ({ size }) =>
    !!size &&
    css`
      min-height: ${theme.buttonHeight[size]}px;
    `,

  /** 가장자리 스타일 */
  ({ size }) => {
    switch (size) {
      case 'tiny':
        return css`
          border-radius: 2px;
        `;
      case 'medium40':
      case 'small':
        return css`
          border-radius: 4px;
        `;
    }
  },
  ({ edge }) => {
    switch (edge) {
      case 'circular':
        return css`
          border-radius: 999px;
        `;
      case 'square':
        return css`
          border-radius: 0px;
        `;
    }
  },
  ({ borderRadius }) =>
    !!borderRadius &&
    css`
      border-radius: ${borderRadius === '50%' ? borderRadius : `${borderRadius}px`};
    `,

  /** 버튼 색상 */
  ({ color }) =>
    color === 'primary' && {
      backgroundColor: theme.color[color],
    },

  /** variant === 'contained' gray 이외 스타일 */
  ({ color, variant }) =>
    variant === 'contained' &&
    (color === 'point' || color === 'blue') &&
    css`
      background-color: ${theme.buttonColor[color][variant]};

      &:active {
        background-color: ${theme.buttonColor[color]['active']};
      }
    `,

  /** variant === 'contained' size 가 있을 때 gray 스타일 */
  ({ color, variant, size }) => {
    if (size && color === 'gray' && variant === 'contained') {
      switch (size) {
        case 'large56':
        case 'medium48':
        case 'medium40':
          return css`
            background-color: ${theme.color.gray7};
            &:active {
              background-color: ${theme.color.gray5};
            }
          `;
        case 'small':
        case 'tiny':
          return css`
            background-color: ${theme.color.gray6};
            &:active {
              background-color: ${theme.color.gray5};
            }
          `;
      }
    }
  },

  /** variant === 'contained' 이면서 disabled */
  ({ variant, size }) => {
    if (size && variant === 'contained') {
      switch (size) {
        case 'large56':
        case 'medium48':
        case 'medium40':
          return css`
            &:disabled {
              background-color: ${theme.color.gray6};
            }
          `;
        case 'small':
        case 'tiny':
          return css`
            &:disabled {
              background-color: ${theme.color.gray5};
            }
          `;
      }
    }
  },

  /** variant === 'outlined' 스타일 */
  ({ variant, outlineColor }) =>
    variant === 'outlined' &&
    css`
      border-width: 1px;
      border-style: solid;
      border-color: ${outlineColor ? `${theme.color[outlineColor]}` : `${theme.color.gray5}`};
      background-color: ${theme.color.white};

      &:active {
        background-color: ${theme.color.gray7};
        border-color: ${theme.color.gray5};
      }
    `,

  /** variant === 'outlined' 이면서 disabled */
  ({ variant, size }) => {
    if (size && variant === 'outlined') {
      switch (size) {
        case 'large56':
        case 'medium48':
        case 'medium40':
          return css`
            &:disabled {
              border-color: ${theme.color.gray6};
            }
          `;
        case 'small':
        case 'tiny':
          return css`
            &:disabled {
              border-color: ${theme.color.gray5};
            }
          `;
      }
    }
  },
);

ButtonBase.displayName = 'ButtonBase';
export default ButtonBase;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';

const SkeletonList = () => {
  const length = 8;
  const dummyList = Array.from({ length }, (_, index) => index);
  return (
    <Container>
      {dummyList.map(i => (
        <Item key={i}>
          <Skeleton height={20} width={210} type="round" />
          <Skeleton height={14} width={300} type="round" />
        </Item>
      ))}
    </Container>
  );
};

export default SkeletonList;

const Container = styled.ul`
  ${theme.flex('column', '', '')}
  padding: 0 20px;
  [type='round'] {
    border-radius: 3px;
  }
`;

const Item = styled.li`
  border-bottom: 1px solid ${theme.color.gray7};
  padding: 18px 0;
  ${theme.flex('column', 'start', 'start', 8)}
`;

import { yupResolver } from '@hookform/resolvers/yup';
import { BOOKING_FORM_VALIDATE } from 'pages/Booking/constants';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

const useCustomBookingForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext, TTransformedValues> => {
  return useForm<TFieldValues, TContext, TTransformedValues>({
    resolver: yupResolver(BOOKING_FORM_VALIDATE),
    shouldFocusError: false, // 유효성 에러 발생했을 때 해당 input 에 자동 포커스 방지 (ios 키보드가 에러 토스트 가림)
    reValidateMode: 'onSubmit', // 에러 발생 후 재검증도 제출 시에만
    ...props,
  });
};

export default useCustomBookingForm;

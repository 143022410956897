export const QNA_LIST_TEXT = {
  title: '1:1 문의',
  noSearchResult: '의 검색 결과가 없습니다.',
  noQna: '등록된 문의가 없습니다.',
  searchPlaceholder: '제목, 작성자 검색',
};

export const QNA_LOCAL_STORAGE_KEY = 'qnaLastVisitedList';
// 첫 진입시 new표시를 위한 기준 날짜, 회사 창립일로 함
export const BASE_DATE = '2018-10-17';

export const QNA_IDENTIFIER = 'qna';
export const QNA_SORT_IDENTIFIER = 'qnaSort';

import useQueryString from 'hooks/useQueryString';
import { SelectTicketFormType } from 'pages/ProductForm/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

const NextButton = () => {
  const { control } = useFormContext<SelectTicketFormType>();
  const selectedTicket = useWatch({ control, name: 'selectedTicket' });

  const { getAllSearchParams, setSearchParams } = useQueryString();

  const clickNext = () => {
    setSearchParams({
      ...getAllSearchParams(),
      step: '2',
    });
  };

  return (
    <FooterButton disabled={!selectedTicket} onClick={clickNext}>
      다음
    </FooterButton>
  );
};

export default NextButton;

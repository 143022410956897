import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostUserTicket = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<{ user_tickets: Array<TicketCreateParams> }, TicketResponse>({
    method: 'post',
    url: '/api/userTicket',
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePostUserTicket;

export type TicketCreateParams = {
  staff_id: number;
  studio_id: number;
  user_id: number;
  staffs?: number[] | null;
  max_coupon: number;
  remaining_coupon: number;
  remaining_cancel: number;
  availability_start_at: string;
  expire_at: string;
  is_shared: boolean;
  ticket_id: number;
  payment: TicketPaymentParams;
};

export type TicketPaymentParams = {
  amount: number;
  method: string;
  card_amount: number;
  cash_amount: number;
  wiretransfer_amount: number;
  unpaid_amount: number;
  point_amount: number;
  reward_point: number;
  installment_period: number;
  settlement_at: string;
};

export type TicketResponse = {
  name: string;
};

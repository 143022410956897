import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

type Props = {
  type?: 'member' | 'counsel';
};

const DUMMY_LENGTH = 4;
const TargetMemberSkeletonList = ({ type = 'member' }: Props) => {
  const array = Array.from({ length: DUMMY_LENGTH }, (_, index) => index);
  return (
    <Container>
      {array.map(i => (
        <Item key={i}>
          <Row>
            <CheckBox disabled id="skeleton" />
            {type === 'member' && <Skeleton width={40} height={40} type="circle" />}
            <Column className="grow">
              <Skeleton width={39} height={20} type="round" />
              <Skeleton width={122} height={16} type="round" />
            </Column>
            <Skeleton width={42} height={22} type="round" />
          </Row>
          <DividerWithMargin thin />
          <Skeleton height={90} type="round" />
        </Item>
      ))}
    </Container>
  );
};

export default TargetMemberSkeletonList;

const Container = styled.div`
  padding: 20px;
  ${theme.flex('column', '', '', 12)}
`;

const Item = styled.div`
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  padding: 14px 20px;
  [type='round'] {
    border-radius: 3px;
  }
`;

const Row = styled.div`
  width: 100%;
  ${theme.flex('', '', '', 12)}
  margin-bottom: 4px;
  :not(.grow) {
    flex: 0 0 auto;
  }
  .input-span-style {
    background-color: unset !important;
  }
`;

const Column = styled.div`
  ${theme.flex('column', '', 'flex-start', 4)}
  flex-grow: 1;
`;

const DividerWithMargin = styled(Divider)`
  margin: 14px 0;
`;

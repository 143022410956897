import { ControllerRenderProps, FieldValues, useController, UseControllerProps } from 'react-hook-form';

import UnderlinedTextField, { UnderlinedOptions } from './components/UnderlinedTextField';
import { ITextFieldOptions } from './types';

type UnderlinedPropTypes = ITextFieldOptions & UnderlinedOptions;

type UnderlinedTextFieldType<T extends FieldValues> = Omit<Exclude<UnderlinedPropTypes, ControllerRenderProps<T>>, 'id'> & {
  controllerProps: UseControllerProps<T>;
};

const UnderlinedTextFieldController = <T extends FieldValues>({ controllerProps, ...inputProps }: UnderlinedTextFieldType<T>) => {
  const { field } = useController(controllerProps);
  return <UnderlinedTextField id={field.name} {...field} {...inputProps} />;
};

export default UnderlinedTextFieldController;

import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 수강권 정지 */
const usePostHolding = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<HoldingPostParams, HoldingPostResponse>({
    method: `post`,
    url: `/api/holding`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePostHolding;

export type HoldingPostParams = {
  user_ticket_id: number;
  start_on: string;
  end_on: string;
  auto_calculation: boolean;
};

export type HoldingPostResponse = HoldingPostParams & {
  id: number;
  studio_user_id: number;
  created_at: string;
  updated_at: string;
};

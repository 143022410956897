import styled from '@emotion/styled';
import { theme } from 'assets/styles';

export interface IProgressProps {
  /**
   * progress max length
   */
  max: number;
  /**
   * progress step
   */
  step: number;
}

const Progress = ({ max, step }: IProgressProps) => {
  return (
    <Container>
      <progress max={max} value={step} />
    </Container>
  );
};

export default Progress;

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;

  progress {
    width: 100%;
    height: 2px;
    vertical-align: top;

    &::-webkit-progress-bar {
      background-color: ${theme.color.gray7};
    }

    &::-webkit-progress-value {
      transition: width 0.3s;
      background-color: ${theme.color.primary};
    }
  }
`;

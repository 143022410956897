import { TicketCommon } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { CARD_COLOR, OLD_CARD_COLOR } from 'sharedComponents/TicketCard/color';

export type CommonTicketInfo = {
  /** 그룹, 프라이빗 타입 */
  classType: string;
  /** 티켓 유형 */
  convertTicketType: string | null;
  /** 최대 수강 인원 */
  maxTrainee: string | null;
  /** 티켓 색상 */
  ticketColor: string[];
};

/**
 * 부모 수강권, 회원이 보유한 수강권에 공통으로 필요한 정보 세팅 함수
 */
const formatCommonTicketInfo = (ticket: Omit<TicketCommon, 'actual_price'>): CommonTicketInfo => {
  const { available_class_type, type, use_weekly_auto_coupon_balance, colors, max_trainee } = ticket;

  // 상품의 경우
  const isItem = available_class_type === 'I';

  const isPrivate = available_class_type === 'P';
  const isGroup = available_class_type === 'G';
  const isSell = type === 'S';

  const classType = isPrivate ? '프라이빗형' : isGroup ? '그룹형' : isSell ? '판매형' : '대여형';

  let convertTicketType = null;
  if (type === 'T' || type === 'RT') {
    if (use_weekly_auto_coupon_balance) {
      convertTicketType = '차감제';
    } else {
      convertTicketType = '횟수제';
    }
  }
  switch (type) {
    case 'P':
    case 'RP':
      convertTicketType = '기간제';
      break;
    case 'RM':
      convertTicketType = '혼합';
  }

  const colorKey = Object.keys(OLD_CARD_COLOR).find(key => OLD_CARD_COLOR[key][0] === colors[0]);
  const ticketColor = CARD_COLOR[colorKey || available_class_type];

  return {
    classType,
    convertTicketType,
    ticketColor,
    maxTrainee: isItem ? null : `${max_trainee}:1`,
  };
};

export default formatCommonTicketInfo;

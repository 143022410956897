import { FieldValues } from 'react-hook-form';

import Drawer, { DrawerProps } from '..';
import OneLinedTitle from '../DrawerTitle/OneLinedTitle';
import CheckRadioFilterContents, { CheckRadioFilterContentsProps } from './CheckRadioFilterContents';

export type CheckRadioDrawerProps<T extends FieldValues> = Omit<DrawerProps, 'children'> & CheckRadioFilterContentsProps<T>;

const CheckRadioDrawer = <T extends FieldValues>({
  header,
  name,
  data,
  control,
  showLabelAll,
  onClick,
  ...props
}: CheckRadioDrawerProps<T>) => {
  const { onClose } = props;

  const drawerHeader = typeof header === 'string' ? <OneLinedTitle title={header} /> : header;

  return (
    <Drawer header={drawerHeader} {...props}>
      <CheckRadioFilterContents
        control={control}
        name={name}
        data={data}
        showLabelAll={showLabelAll}
        onClick={onClick}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default CheckRadioDrawer;

import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { ComponentProps } from 'react';
import FormField from 'sharedComponents/FormField';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

type Props = Omit<ComponentProps<typeof FormFieldLinkButton>, 'iconName'>;

const DivisionLinkButton = ({ children, ...props }: Props) => {
  return (
    <StyledFormField height={112}>
      <FormFieldLinkButton iconName="class" {...props}>
        {children}
      </FormFieldLinkButton>
      <StyledTypography size={13} textColor="gray2">
        필라테스, 요가 등 수업의 종목을 선택할 수 있습니다.
        <br />
        수업 종목을 설정하여 특정 종목의 수강권을 가진 회원만
        <br />
        수업을 예약하게 할 수 있습니다.
      </StyledTypography>
    </StyledFormField>
  );
};

export default DivisionLinkButton;

const StyledFormField = styled(FormField)`
  position: relative;
  padding: 0;

  .link-button {
    border-bottom: none;

    .link-button-arrow-wrapper {
      flex: 0 0 auto;
    }
  }
`;

const StyledTypography = styled(Typography)`
  position: absolute;
  left: 54px;
  bottom: 15px;
`;

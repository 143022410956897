import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import { ReactNode, useState } from 'react';

type Props = {
  labelText: ReactNode;
  hasTooltip?: boolean;
  tooltipMessages?: string[];
  className?: string;
};

const MessageFormLabel = ({ labelText, hasTooltip, tooltipMessages = [], className }: Props) => {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  return (
    <Row className={className}>
      <Typography size={13} weight={500} textColor="gray2">
        {labelText}
      </Typography>

      {hasTooltip && (
        <Tooltip
          isOpen={isTooltipOpened}
          showCloseButton
          position="left"
          message={tooltipMessages}
          onClose={() => setIsTooltipOpened(false)}>
          <IconButton onClick={() => setIsTooltipOpened(true)}>
            <Icon name="information" size={14} color="gray3" />
          </IconButton>
        </Tooltip>
      )}
    </Row>
  );
};

export default MessageFormLabel;

const Row = styled.div`
  ${theme.flex('', 'center', 'start', 2)}
  margin: 32px 0 8px;
`;

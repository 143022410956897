import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import SubTitle from 'components/SubTitle';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import LoginForm from './LoginForm';

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const setCustomBackHandler = useSetRecoilState(customBackHandlersAtom);

  const goBackIntro = useCallback(() => {
    if (state?.isLookAround) {
      navigate(-1);
      return;
    }
    navigate('/intro');
  }, [state, navigate]);

  /** 안드로이드 물리 백버튼 Drawer 대응 */
  useEffect(() => {
    setCustomBackHandler(prev => [...prev, { handler: goBackIntro, key: 'login' }]);

    return () => {
      setCustomBackHandler(prev => prev.filter(item => item.key !== 'login'));
    };
  }, [goBackIntro, setCustomBackHandler]);

  const goNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={goBackIntro}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <Container>
        <SubTitle title="로그인" />
        <ApiBoundary>
          <LoginForm />
        </ApiBoundary>
        {state?.isLookAround ? (
          <div className="look-around-btn-wrapper">
            <Button textColor="gray2" onClick={() => goNavigate('/join?step=0')}>
              회원가입
            </Button>
            <span className="vertical-line" />
            <Button textColor="gray2" onClick={() => goNavigate('/find-password?step=1')}>
              비밀번호 찾기
            </Button>
          </div>
        ) : (
          <Button className="find-password-btn" onClick={() => goNavigate('/find-password?step=1')} textUnderline>
            비밀번호를 잊어버렸나요?
          </Button>
        )}
      </Container>
    </MainLayout>
  );
};

export default Login;

const Container = styled.div`
  padding: 8px 20px 0;

  h2 {
    margin-bottom: 56px;
  }

  form {
    ${theme.flex('column', '', '', 8)}

    .submit-btn {
      margin-top: 16px;
    }
  }

  .find-password-btn {
    margin: 16px auto 0;
  }

  .look-around-btn-wrapper {
    ${theme.flex('row', 'center', 'center', 12)};

    .vertical-line {
      margin-top: 16px;
      width: 1px;
      height: 10px;
      background-color: ${theme.color.gray4};
    }

    .text-button {
      margin-top: 16px;
    }
  }
`;

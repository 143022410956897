import { PERMISSION } from 'constants/permission';

import { MenuType } from './types';

/**
 * @description
 * permission : 해당 강사에게 권한이 있는지
 * policiesNeeded : 해당 시설 정책상 허용되어 있는지
 */
export const MENUS: { basic: MenuType[]; sub: MenuType[] } = {
  basic: [
    { name: '일정 관리', path: '/more-details/schedule', permissions: [], policies: [] },
    {
      name: '메시지',
      path: '/more-details/message',
      permissions: [PERMISSION.message.push.view, PERMISSION.message.sms.view],
      policies: [],
    },
    { name: '강사', path: '/more-details/staff', permissions: [PERMISSION.staff.view], policies: [] },
    { name: '매출', path: '/more-details/report', permissions: [PERMISSION.sales.view], policies: [] },
    { name: '공지사항', path: '/more-details/notice', permissions: [PERMISSION.board.notice.view], policies: [] },
    { name: '1:1 문의', path: '/more-details/qna', permissions: [PERMISSION.board.qna.view], policies: ['is_visible_qna'] },
  ],
  sub: [
    { name: '시설조회', path: '/more-details/studio', permissions: [], policies: [] },
    { name: '내 계정 정보', path: '/more-details/account', permissions: [], policies: [] },
  ],
};

export const MORE_DETAILS_TEXT = {
  notConnected: {
    profile: '이용 중인 시설에\n강사 등록을 요청해 주세요.',
    select: '시설 없음',
    toast: '시설과 연동이 되어있지 않습니다.',
  },
  notSelected: {
    profile: '이용 중인 시설을\n선택해 주세요.',
    select: '시설 선택',
    toast: '시설을 선택해 주세요.',
  },
  loginRequired: {
    title: '로그인 후\n이용할 수 있습니다.',
    button: '로그인 하러 가기',
    toast: '로그인이 필요한 메뉴입니다.',
  },
  myStaffInfo: '내 강사 정보',
  changed: '시설이 변경되었습니다.',
};

import { useSuspenseQuery } from '@tanstack/react-query';
import { StudiomateNoticeType } from 'pages/MoreDetails/Settings/Notice/List/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStudiomateNoticeRecent = () => {
  const { queryFn } = useQueryFnWithCommonParams<StudiomateNoticeRecentResponse, StudiomateNoticeRecentParams>({
    url: `/api/studiomate-notice/recent`,
    params: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: [`studiomate-notice`, 'recent'],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetStudiomateNoticeRecent;

type StudiomateNoticeRecentParams = {
  studio_id: undefined;
  staff_id: undefined;
};

type StudiomateNoticeRecentResponse = {
  data: StudiomateNoticeType[];
};

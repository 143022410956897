import styled from '@emotion/styled';
import Typography from 'components/Typography';

type Props = {
  title: string;
};

const FilterTitle = ({ title }: Props) => {
  return (
    <StyledTypography size={15} weight={700}>
      {title}
    </StyledTypography>
  );
};

export default FilterTitle;

const StyledTypography = styled(Typography)`
  margin-bottom: 12px;
`;

import styled from '@emotion/styled';
import useInfinityMember from 'hooks/service/queries/useInfinityMember';
import useInfinityMemberAssigned from 'hooks/service/queries/useInfinityMemberAssigned';
import useSearchKeywords from 'hooks/useSearchKeywords';
import NoData from 'pages/Member/components/NoData';
import { convertAllMemberFilterToParams } from 'pages/Member/utils';
import { MessageTargetFormType } from 'pages/MoreDetails/Message/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { messageTargetMemberSelectedFilter } from 'recoil/message';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import { MESSAGE_TARGET_IDENTIFIER } from '../../constants';
import MemberListItem from './MemberListItem';

type Props = {
  messageType: 'mobile' | 'push';
  assignedOnly: boolean;
};

const MemberList = ({ messageType, assignedOnly }: Props) => {
  const selectedFilter = useRecoilValue(messageTargetMemberSelectedFilter);
  const { searchKeyword } = useSearchKeywords(MESSAGE_TARGET_IDENTIFIER);

  const useInfinityHook = assignedOnly ? useInfinityMemberAssigned : useInfinityMember;

  const {
    data: { members, total },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityHook({
    ...convertAllMemberFilterToParams(selectedFilter),
    search: searchKeyword,
    has_mobile: messageType === 'mobile' ? 1 : undefined,
    is_app_linked: messageType === 'push' ? 1 : undefined,
  });

  const { setValue } = useFormContext<MessageTargetFormType>();
  useEffect(() => {
    setValue('total', total);
  }, [total, setValue]);

  if (!total) return <NoData currentTabLabel="등록된" currentKeyword={searchKeyword} />;
  return (
    <StyledInfiniteScroll gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      {members.map(member => (
        <MemberListItem key={member.id} member={member} />
      ))}
    </StyledInfiniteScroll>
  );
};

export default MemberList;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding: 20px 20px 80px 20px;
`;

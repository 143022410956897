import styled from '@emotion/styled';
import CheckInFieldButton from 'pages/Booking/components/AvailableTime/CheckInFieldButton';
import { BookingGroupCreateFormType } from 'pages/Booking/Group/types';
import { numToTimeKor } from 'pages/Booking/utils/convertTimes';
import { useFormContext } from 'react-hook-form';

import GroupBookingAvailableField from '../GroupBookingAvailableField';
import OthersAvailableField from '../OthersAvailableField';

const CreateOperationAccordionContents = () => {
  const { getValues } = useFormContext<BookingGroupCreateFormType>();
  const {
    showEnterField,
    policy: { autocloseDeadline, bookingAutoShiftDeadline, dailyBookingChangeDeadline },
  } = getValues();

  const dailyChangeTime = `${numToTimeKor(dailyBookingChangeDeadline)} 전까지`;
  const autoCloseTime = `${numToTimeKor(autocloseDeadline)} 전까지`;
  const autoWaitingTime = `${numToTimeKor(bookingAutoShiftDeadline)} 전까지`;

  return (
    <>
      <GroupBookingAvailableField />
      <OthersAvailableField dailyChangeTime={dailyChangeTime} autoCloseTime={autoCloseTime} autoWaitingTime={autoWaitingTime} />
      {showEnterField && (
        <StyledCheckInButton>
          수업시작 {numToTimeKor(getValues('policy.enterStartline'))} 전부터 <br />
          수업종료 {numToTimeKor(getValues('policy.enterDeadline'))} 후까지
        </StyledCheckInButton>
      )}
    </>
  );
};

export default CreateOperationAccordionContents;

const StyledCheckInButton = styled(CheckInFieldButton)`
  height: 99px;
`;

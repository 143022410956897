import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';

const SkeletonDetail = () => {
  return (
    <Container>
      <Header>
        <Skeleton width={250} height={22} type="round" />
        <Skeleton width={320} height={16} type="round" />
      </Header>
      <Contents>
        <Skeleton width={320} height={16} type="round" />
        <Skeleton width={200} height={16} type="round" />
        <Skeleton width={300} height={16} type="round" />
      </Contents>
      <Attachment>
        <Skeleton height={250} type="round" />
      </Attachment>
    </Container>
  );
};

export default SkeletonDetail;

const Container = styled.div`
  [type='round'] {
    border-radius: 3px;
  }
`;
const Header = styled.div`
  ${theme.flex('column', '', '', 8)}
  padding: 16px 20px;
`;

const Contents = styled.div`
  ${theme.flex('column', '', '', 6)}
  padding: 14px 20px;
`;

const Attachment = styled.div`
  padding: 16px 20px;
`;

import styled from '@emotion/styled';
import Icon, { IconProps } from 'components/Icon';

type Props = Omit<IconProps, 'name'>;

/** 박스 여백없는 컬러칩 아이콘 */
const ColorChip = ({ ...props }: Props) => {
  return <Container name="colorChips" {...props} />;
};

export default ColorChip;

const Container = styled(Icon)<Props>`
  background-color: ${({ fillColor }) => fillColor};
  border-radius: 2px;
`;

import styled from '@emotion/styled';
import AdTextExample from 'assets/images/adTextExample.webp';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { useState } from 'react';

import { AD_TEXT } from '../constants';

const AdTextMandatory = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  return (
    <>
      <Button
        className="ad-text-mandatory"
        onClick={() => setIsDrawerOpened(true)}
        widthSize={144}
        size="medium40"
        variant="contained"
        color="gray"
        leftIcon={<Icon name="information2Bold" color="red0" size={16} />}
        textColor="gray1"
        fontWeight={400}
        padding={{ left: 8, right: 8 }}
        borderRadius={8}
        gap={8}>
        {AD_TEXT.buttonText}
      </Button>
      <StyledDrawer
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        header={<OneLinedTitle title={AD_TEXT.title} />}>
        <Container>
          <Image src={AdTextExample} alt={AD_TEXT.title} />
          <Descriptions>
            {AD_TEXT.descriptions.map((description, index) => (
              <Numbering key={description.title} index={index}>
                <Typography size={15} weight={600}>
                  {description.title}
                </Typography>
                {description.contents.map(content => (
                  <Typography key={content} size={15} textColor="gray2">
                    {content}
                  </Typography>
                ))}
              </Numbering>
            ))}
          </Descriptions>
        </Container>
        <StyledDivider />
        <Container>
          <Typography size={15} weight={600} className="warning-title">
            {AD_TEXT.warningTitle}
          </Typography>
          {AD_TEXT.warning.map(text => (
            <Typography key={text} size={15} textColor="gray2" className="warning" span>
              {text}
            </Typography>
          ))}
        </Container>
      </StyledDrawer>
    </>
  );
};

export default AdTextMandatory;

const StyledDrawer = styled(Drawer)`
  padding-left: 0;
  padding-right: 0;
`;

const Container = styled.div`
  padding: 0 20px;

  .warning-title {
    margin-bottom: 8px;
  }

  .warning:nth-last-of-type(2) {
    color: ${theme.color.error};
    font-weight: 500;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const Descriptions = styled.div`
  ${theme.flex('column', '', '', 16)}
`;

const Numbering = styled.div<{ index: number }>`
  margin-left: 30px;
  position: relative;
  &::before {
    content: '${props => props.index + 1}';
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    line-height: 18px;
    position: absolute;
    top: 2px;
    left: -30px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 24px 0;
`;

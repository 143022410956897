import { TabDataElementType } from 'components/Tabs';
import useInfinityTickets, { TicketsParams } from 'hooks/service/queries/useInfinityTickets';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { SelectTicketFormType } from 'pages/ProductForm/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';

import NoDataText from './NoDataText';
import TicketListItem from './TicketListItem';

type Props = {
  productType: TicketsParams['product_type'];
  tabData: TabDataElementType[];
  currentTabValue: string;
};

const TicketList = ({ productType, tabData, currentTabValue }: Props) => {
  const { control } = useFormContext<SelectTicketFormType>();
  const search = useWatch({ control, name: 'search' });

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfinityTickets({
    product_type: productType,
    ticket_type: productType === 'userTicket' ? currentTabValue : null,
    item_type: productType === 'item' ? currentTabValue : null,
    search,
  });

  useScrollRestoration('.infinite-scroll');

  if (!data.length) {
    return <NoDataText search={search} productType={productType} />;
  }

  return (
    <StyledSelectInfiniteScroll hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
      {tabData.map(
        tab =>
          tab.value === currentTabValue && (
            <ul key={tab.value}>
              {data.map(ticket => (
                <TicketListItem key={ticket.id} ticket={ticket} tabValue={tab.value} />
              ))}
            </ul>
          ),
      )}
    </StyledSelectInfiniteScroll>
  );
};

export default TicketList;

import styled from '@emotion/styled';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import { useEffect, useMemo, useState } from 'react';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import getImageUrl from 'utils/getImageUrl';

import { ReportCommonParamType } from '../type';
import ReportMultiStaffOptionDrawer from './ReportMultiStaffOptionDrawer';

const MultiStaffFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const prevSelectedStaffs = selectedOption as ReportCommonParamType['staffs'];
  const [isOpen, setIsOpen] = useState(false);
  const [staffOptions, setStaffOptions] = useState<FilterOptionsType[]>(currentFilter.options);
  const { data: staffs } = useGetFilterStaffs();

  const label = useMemo(() => {
    if (prevSelectedStaffs?.length === staffs.length || !prevSelectedStaffs?.length) {
      return '전체';
    } else {
      return prevSelectedStaffs.length === 1
        ? prevSelectedStaffs[0].name
        : `${prevSelectedStaffs[0].name} 외 ${prevSelectedStaffs.length - 1}명`;
    }
  }, [prevSelectedStaffs]);

  const onClose = () => {
    setIsOpen(false);
  };

  // currentFilter.option 타입 가드
  const isStaffOption = (option: any): option is { value: { id: number } } => {
    return option?.value && typeof option.value.id === 'number';
  };

  useEffect(() => {
    const convertedOptions = staffs.map(staff => ({
      value: {
        id: staff.id,
        imgUrl: getImageUrl(staff.avatar),
        name: staff.name,
        totalStaff: staffs.length,
      },
      label: staff.name,
    }));

    // 새로운 배열을 만들어 중복된 값을 제거
    const newOptions = [...staffOptions, ...convertedOptions].filter(
      (option, index, self) =>
        isStaffOption(option) && index === self.findIndex(o => isStaffOption(o) && o.value.id === option.value.id),
    );

    setStaffOptions(newOptions);
  }, [staffs]);

  return (
    <Container>
      <Typography size={15} weight={700}>
        강사
      </Typography>
      <StaffSelectButton icon={<Icon name="arrowBottom" color="gray2" />} onClick={() => setIsOpen(true)}>
        {label}
      </StaffSelectButton>
      <ReportMultiStaffOptionDrawer
        selectedOption={selectedOption}
        isOpen={isOpen}
        multiSelectedOptions={staffOptions}
        onClose={onClose}
        onSave={changeOptions}
      />
    </Container>
  );
};

export default MultiStaffFilter;

const Container = styled.div`
  padding: 20px 20px 24px;

  .select-drawer {
    margin-top: 16px;
  }
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;

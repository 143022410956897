import useGetEtcScheduleEtcSchedule from 'hooks/service/queries/useGetEtcScheduleEtcSchedule';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';
import convertBulkData from 'pages/EtcSchedule/Form/utils/convertBulkData';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const BulkDetail = ({ currentEtcScheduleId }: Props) => {
  const { data } = useGetEtcScheduleEtcSchedule(currentEtcScheduleId);
  const { canViewEtcSchedule, canCreateEtcSchedule, canUpdateEtcSchedule, canDeleteEtcSchedule, staffType } =
    useCheckPermissionEtcDetail(data.staffs);

  return (
    <DetailMain
      staffType={staffType}
      currentEtcScheduleId={currentEtcScheduleId}
      data={convertBulkData(data)}
      canViewEtcSchedule={canViewEtcSchedule}
      canCreateEtcSchedule={canCreateEtcSchedule}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default BulkDetail;

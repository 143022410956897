import useGetLectureCourse from 'hooks/service/queries/useGetLectureCourse';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import useQueryString from 'hooks/useQueryString';
import BookingAvailableSetting from 'pages/Booking/components/AvailableTime/BookingAvailableSetting';
import CheckInAvailableSetting from 'pages/Booking/components/AvailableTime/CheckInAvailableSetting';
import { convertBookingFormCommonState, getFormHeaderTitle } from 'pages/Booking/utils';
import { FormProvider } from 'react-hook-form';
import LeaveDialog from 'sharedComponents/LeaveDialog';
import MainLayout from 'sharedComponents/MainLayout';

import { BookingPrivateUpdateFormType } from '../../types';
import PrivateUpdateForm from './PrivateUpdateForm';

type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: courseData } = useGetLectureCourse(lectureData.course_id);

  const methods = useCustomBookingForm<BookingPrivateUpdateFormType>({
    defaultValues: {
      ...convertBookingFormCommonState(lectureData),
      currentLecture: lectureData,
      ruleType: {
        booking: courseData.private_booking_rule_type,
        bookingCancel: courseData.private_booking_cancel_rule_type,
      },
      lectureDate: lectureData.start_on,
      lectureEndAt: lectureData.end_on,
      booking_start_at: lectureData.booking_start_at ?? undefined,
      booking_end_at: lectureData.booking_end_at ?? undefined,
      booking_cancel_start_at: lectureData.booking_cancel_start_at ?? undefined,
      booking_cancel_end_at: lectureData.booking_cancel_end_at ?? undefined,
      enter_start_at: lectureData.enter_start_at ?? undefined,
      enter_end_at: lectureData.enter_end_at ?? undefined,
    },
  });

  const { getAllSearchParams } = useQueryString();
  const { type, setting } = getAllSearchParams();
  const headerTitle = getFormHeaderTitle({ bookingType: 'private', mode: 'update', type, setting });

  return (
    <MainLayout
      header={{
        title: headerTitle,
      }}>
      <FormProvider {...methods}>
        {type === 'bookingCancelTime' && <BookingAvailableSetting />}
        {type === 'checkInTime' && <CheckInAvailableSetting />}
        {!type && <PrivateUpdateForm currentLecture={lectureData} />}
        <LeaveDialog />
      </FormProvider>
    </MainLayout>
  );
};

export default FormMain;

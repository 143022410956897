import BookingAvailableSetting from 'pages/Booking/components/Operation/Setting/BookingAvailableSetting';
import CheckInAvailableSetting from 'pages/Booking/components/Operation/Setting/CheckInAvailableSetting';
import Repeat from 'pages/Booking/components/Repeat';
import { AllPageModeType } from 'pages/Booking/types';

import SelectMembers from './SelectMembers';

type Props = {
  type: string | string[];
  pageMode: AllPageModeType;
};

/** 프라이빗 수정 폼 디테일 타입별 페이지 */
const FormDetailSetting = ({ type, pageMode }: Props) => {
  switch (type) {
    case 'repeat':
      return <Repeat pageMode={pageMode} />;
    case 'selectMember':
      return <SelectMembers pageMode={pageMode} />;
    case 'bookingCancelTime':
      return <BookingAvailableSetting />;
    case 'checkInTime':
      return <CheckInAvailableSetting />;
  }
};

export default FormDetailSetting;

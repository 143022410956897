import { yupResolver } from '@hookform/resolvers/yup';
import usePostEtcSchedule from 'hooks/service/mutations/usePostEtcSchedule';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { isArray } from 'lodash';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import { ETC_FORM_TEXT, ETC_FORM_VALIDATE } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useNavigate } from 'react-router-dom';
import { StaffType } from 'types/staffTypes';

import EtcFormMainLayout from '../components/EtcFormMainLayout';
import convertFormState from '../utils/convertFormState';
import formatEtcParams from '../utils/formatEtcParams';

type Props = {
  data: EtcScheduleDetailResponse;
  staff: StaffType;
};

const CopyForm = ({ data, staff }: Props) => {
  const methods = useCustomBookingForm<EtcScheduleCreateFormType>({
    resolver: yupResolver(ETC_FORM_VALIDATE),
    defaultValues: {
      ...convertFormState(data, staff),
    },
  });

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { setPopup } = usePopup();
  const { mutate: createEtcMutate } = usePostEtcSchedule();

  const submit = (values: EtcScheduleCreateFormType) => {
    const { is_repeat, ...rest } = formatEtcParams(values);
    const params = {
      ...rest,
      is_repeat: false,
    };
    createEtcMutate(params, {
      onSuccess: () => {
        navigate(-1);
        setToast({ type: 'success', message: ETC_FORM_TEXT.successMessage.create, bottom: 76 });
      },
      onError: error => {
        if (isArray(error.response?.data)) {
          setPopup(
            <FailAllBooking
              fails={error.response?.data}
              closePopup={() => {
                navigate(-2);
              }}
            />,
          );
          return false;
        }
        return true;
      },
    });
  };

  return (
    <EtcFormMainLayout {...methods} pageMode="copy" onSubmit={submit}>
      <BookingRangeDate isRange isInitialOpen />
    </EtcFormMainLayout>
  );
};

export default CopyForm;

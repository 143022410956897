import { ComponentProps } from 'react';
import { FieldValues } from 'react-hook-form';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import Drawer, { DrawerProps } from '..';
import OneLinedTitle from '../DrawerTitle/OneLinedTitle';
import CheckRadioFilterContents, { CheckRadioFilterContentsProps } from './CheckRadioFilterContents';

export type CheckRadioInfiniteDrawerProps<T extends FieldValues> = Omit<DrawerProps, 'children'> &
  CheckRadioFilterContentsProps<T> &
  Omit<ComponentProps<typeof InfiniteScroll>, 'children'>;

const CheckRadioInfiniteDrawer = <T extends FieldValues>({
  header,
  name,
  data,
  control,
  showLabelAll,
  hasMore,
  isLoading,
  loadMore,
  onClick,
  ...props
}: CheckRadioInfiniteDrawerProps<T>) => {
  const { onClose } = props;

  const drawerHeader = typeof header === 'string' ? <OneLinedTitle title={header} /> : header;

  return (
    <Drawer header={drawerHeader} {...props}>
      <InfiniteScroll isLoading={isLoading} hasMore={hasMore} loadMore={loadMore}>
        <CheckRadioFilterContents
          control={control}
          name={name}
          data={data}
          showLabelAll={showLabelAll}
          onClick={onClick}
          onClose={onClose}
        />
      </InfiniteScroll>
    </Drawer>
  );
};

export default CheckRadioInfiniteDrawer;

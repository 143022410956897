export const PROFILE_FIELD_TEXT = {
  mobile: {
    placeholder: '휴대폰 번호 입력',
  },
};

export const GENDER_DATA = [
  { id: 'female', label: '여성', value: 'F' },
  { id: 'male', label: '남성', value: 'M' },
];

import styled from '@emotion/styled';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useInfinityBookingList from 'hooks/service/queries/useInfinityBookingList';
import { BookingType } from 'pages/Booking/types';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import BookingList from './BookingList';
import TraineeCount from './TraineeCount';

type Props = {
  isMe: boolean;
  currentLecture: LectureDetailResponse;
  bookingType: BookingType;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
};

const Bookings = ({ isMe, currentLecture, bookingType, canChangeBooking, canCancelBooking }: Props) => {
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityBookingList(currentLecture.id);
  const { current_trainee_count } = currentLecture;

  return (
    <>
      <TraineeCount
        bookings={data}
        bookingType={bookingType}
        currentLecture={currentLecture}
        canChangeBooking={canChangeBooking}
      />
      <StyledInfiniteScroll
        showMoreButton
        moreButtonColor="gray7"
        hasMore={hasNextPage}
        isLoading={isFetchingNextPage}
        loadMore={fetchNextPage}>
        <BookingList
          isMe={isMe}
          bookings={data}
          bookingType={bookingType}
          currentBookingTraineeCount={current_trainee_count}
          canCancelBooking={canCancelBooking}
          canChangeBooking={canChangeBooking}
        />
      </StyledInfiniteScroll>
    </>
  );
};

export default Bookings;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding-bottom: 40px;

  .more-button {
    width: calc(100% - 40px);
    margin: 20px auto 0;
  }
`;

export const TAB_LIST = [
  { value: 'palette', label: '팔레트로 보기' },
  { value: 'list', label: '목록으로 보기' },
];

const COLOR_CLASS = [
  { value: 'red', label: '레드' },
  { value: 'pink', label: '핑크' },
  { value: 'softRed', label: '소프트 레드' },
  { value: 'orange', label: '오렌지' },
  { value: 'softYellow', label: '소프트 옐로우' },
  { value: 'green', label: '그린' },
  { value: 'emeraldGreen', label: '에메랄드 그린' },
  { value: 'modernCyan', label: '모던 사이안' },
  { value: 'skyBlue', label: '스카이 블루' },
  { value: 'blueViolet', label: '블루 바이올렛' },
  { value: 'softViolet', label: '소프트 바이올렛' },
] as const;

export const REPRESENTATIVE_COLOR_LIST: Array<{ id: string; value: string; label: string }> = [];

// COLOR_CLASS를 기준으로 각 색상 분류별 1~5까지의 단계 추가
COLOR_CLASS.forEach(color => {
  Array.from({ length: 5 }).forEach((_, index) => {
    const value = `${color.value}${index}`;
    const label = `${color.label} ${index}`;
    REPRESENTATIVE_COLOR_LIST.push({ id: value, value, label });
  });
});

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Tabs, { TabDataElementType } from 'components/Tabs';
import useGetScheduleManagementUseCheckin from 'hooks/service/queries/useGetScheduleManagementUseCheckin';
import { ReactNode, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';
import { MainBackgroundColorWrapper, StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import { BASIC_SCHEDULE_TABS, SCHEDULE_TABS } from '../constant';
import ScheduleFilters from './ScheduleFilters';

type Props = {
  currentTab: string;
  children: ReactNode;
  changeTab: (tabData: TabDataElementType) => void;
};

const ScheduleLayout = ({ currentTab, children, changeTab }: Props) => {
  const studio_id = useRecoilValue(studioIdAtom);
  const [tabs, setTabs] = useState(SCHEDULE_TABS);

  const {
    data: { result: isUseCheckin },
  } = useGetScheduleManagementUseCheckin(studio_id.toString());

  useEffect(() => {
    if (isUseCheckin) {
      setTabs(SCHEDULE_TABS);
    } else {
      setTabs(BASIC_SCHEDULE_TABS);
    }
  }, [isUseCheckin]);

  return (
    <MainLayout
      scrollable
      header={{
        title: '일정 관리',
        rightButton: <HeaderSearchButton identifier="schedule" placeholder="회원명, 휴대폰 번호 검색" />,
        noUnderline: true,
      }}>
      <StickyWrapper>
        <Tabs data={tabs} value={currentTab} onChange={changeTab} />
      </StickyWrapper>
      <ScheduleFilters currentTab={currentTab} />
      <Divider thin />
      <Container>
        <ContentsSection>{children}</ContentsSection>
      </Container>
    </MainLayout>
  );
};

export default ScheduleLayout;

export const Container = styled(MainBackgroundColorWrapper)`
  ${theme.flex('column', '', '')};
  height: 100%;
  background: ${theme.color.gray6};
`;

const ContentsSection = styled.section``;

import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import { useFormContext } from 'react-hook-form';

import { PROFILE_FIELD_TEXT } from '../constants';
import { CommonProfileFormType } from '../types';

type Props = {
  desc: string[];
};

const Mobile = ({ desc }: Props) => {
  const { control, setValue } = useFormContext<CommonProfileFormType>();

  return (
    <UnderlinedTextFieldController
      controllerProps={{ control, name: 'mobile' }}
      inputMode="numeric"
      placeholder={PROFILE_FIELD_TEXT.mobile.placeholder}
      left={{ prefix: <Icon name="phone" fillColor={theme.color.gray2} /> }}
      description={{
        type: 'normal',
        text: desc,
      }}
      onClear={() => {
        setValue('mobile', '', { shouldDirty: true });
      }}
    />
  );
};

export default Mobile;

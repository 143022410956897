import { TicketPaymentParams } from 'hooks/service/mutations/usePostUserTicket';
import { TicketPaymentFormType } from 'sharedComponents/TicketForm/types';

/**
 * ticket payment 의 각 금액 input 타입이 number가 아니라서 (천단위 콤마 표기는 string 이기 때문)
 * number로 변환하여 params 로 넘기기 위한 함수
 */
type Props = Pick<TicketPaymentFormType, 'payment' | 'installment'>;
const getPaymentFormParams = (data: Props) => {
  const {
    installment,
    payment: { card_amount, cash_amount, wiretransfer_amount, point_amount, reward_point, installment_period, discount, ...rest },
  } = data;

  const installmentPeriod = installment === 'single' ? 1 : installment_period;

  let method: string | null = 'mixed';
  if (card_amount && !cash_amount && !wiretransfer_amount) {
    method = 'card';
  }
  /** 현금만 or 계좌이체만일 때, 'cash' */
  if (!card_amount && cash_amount && !wiretransfer_amount) {
    method = 'cash';
  }
  if (!card_amount && !cash_amount && wiretransfer_amount) {
    method = 'cash';
  }

  const numberTypePayment = {
    ...rest,
    card_amount,
    cash_amount: cash_amount || 0,
    wiretransfer_amount: wiretransfer_amount || 0,
    reward_point: reward_point || 0,
    point_amount: point_amount || 0,
    method,
    installment_period: installmentPeriod,
  } as TicketPaymentParams;

  return numberTypePayment;
};

export default getPaymentFormParams;

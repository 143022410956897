import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';

const MemberTicketSkeleton = () => {
  return (
    <Container>
      <StyledSkeleton width={311} height={188} />
      <InActiveCardSkeleton width={256} height={156} />
    </Container>
  );
};

export default MemberTicketSkeleton;

const Container = styled.div`
  padding: 24px 0 31px;
  margin: 0 auto;
  position: relative;
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 12px;
`;

const InActiveCardSkeleton = styled(StyledSkeleton)`
  position: absolute;
  top: 40px;
  right: -268px;
`;

import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/** 기타 일정 삭제 (이후 모든 일정 삭제) */
const useDeleteEtcScheduleBulk = (id: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<EtcScheduleDeleteBulkParams>({
    method: `delete`,
    url: `/api/etcSchedule/bulk/${id}`,
  });

  return useCustomMutation({
    mutationFn,
    mutationKey: ['etc/schedule', 'delete', 'bulk', id],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['etc/schedule', 'detail', id],
      });
    },
  });
};

export default useDeleteEtcScheduleBulk;

export type EtcScheduleDeleteBulkParams = {
  start: string; // 2024-06-20 12:00:00
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { hexToRgba } from 'utils/styles';

import FilterCount from './FilterCount';
import { FilterListProps } from './types';

const FilterList = ({
  selectedCount,
  filterTabs,
  filterValues,
  onOpenWholeFilters,
  onOpenDrawerFilters,
  onOpenSelectedDrawerFilter,
}: FilterListProps) => {
  return (
    <Container className="filter-list">
      <FilterCount selectedCount={selectedCount} onClick={onOpenWholeFilters} />

      <StyledUl onClick={onOpenDrawerFilters}>
        {filterTabs.map(({ value, label }) => {
          const isSelected = !!filterValues[value];

          return (
            <StyledLi key={value} isSelected={isSelected} onClick={() => onOpenSelectedDrawerFilter?.({ value, label })}>
              <Typography size={13} weight={isSelected ? 600 : 400} textColor={isSelected ? 'primary' : 'gray2'}>
                {label}
              </Typography>
              <Icon name="arrowBottomBold" size={12} fillColor={isSelected ? theme.color.primary : theme.color.gray3} />
            </StyledLi>
          );
        })}
      </StyledUl>
    </Container>
  );
};

export default FilterList;

const Container = styled.div`
  ${theme.flex('row', 'center', '')};
  padding-left: 20px;
  margin: 14px 0;
  position: relative;
  background-color: ${theme.color.white};
`;

const StyledUl = styled.ul`
  ${theme.flex('', 'center', '', 6)};
  padding-left: 6px;
  padding-right: 20px;
  margin: 0;
  white-space: nowrap;
  overflow: auto hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledLi = styled.li<{ isSelected: boolean }>(
  css`
    ${theme.flex('', 'center', '', 6)};
    padding: 8px 10px 8px 12px;
    border-radius: 999px;
    background-color: ${theme.color.gray7};
  `,

  ({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${hexToRgba(theme.color.primary, 0.12)};
    `,
);

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { CareerType } from 'types/staffTypes';

import TabContentHeader from './components/TabContentHeader';
import { TITLE } from './constants';

type Props = {
  selfIntroduction: string;
  careers: CareerType[];
};

const BasicInfo = ({ selfIntroduction, careers }: Props) => {
  return (
    <Container>
      <TabContentHeader title={TITLE.header.profile} selfIntroduction={selfIntroduction} careers={careers} />

      <section>
        <Typography className="sub-title" weight={600} textColor="gray2">
          {TITLE.sub.basicInfo.introduce}
        </Typography>

        <Typography className="self-introduction" size={15} textColor="gray2">
          {selfIntroduction || '없음'}
        </Typography>
      </section>

      <Divider thin />

      <section>
        <Typography className="sub-title" weight={600} textColor="gray2">
          {TITLE.sub.basicInfo.careers}
        </Typography>

        {careers.length ? (
          <ol className="careers-list">
            {careers.map(({ id, career }: CareerType) => (
              <li key={id}>
                <Typography size={15} textColor="gray2">
                  {career}
                </Typography>
              </li>
            ))}
          </ol>
        ) : (
          <Typography size={15} textColor="gray2">
            없음
          </Typography>
        )}
      </section>
    </Container>
  );
};

export default BasicInfo;

const Container = styled.section`
  padding: 20px;

  .sub-title {
    margin-bottom: 8px;
  }

  hr {
    margin: 24px 0;
  }

  .careers-list {
    ${theme.flex('column', '', '', 6)}

    .typography::before {
      content: 'ㆍ';
      margin-left: -4px;
      color: ${theme.color.gray3};
    }
  }

  .self-introduction {
    white-space: pre-wrap;
  }
`;

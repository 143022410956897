import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';

export interface ITextFieldBasePropTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * 해당 input의 id(label 연결)
   */
  id: string;
  /**
   * input의 텍스트 정렬
   */
  textAlign?: string;
  /**
   * input의 배경 색상
   */
  backgroundColor?: boolean;
}

const TextFieldBase = React.forwardRef<HTMLInputElement, ITextFieldBasePropTypes>(({ id, type = 'text', ...props }, ref) => {
  const textFieldRef = useRef<HTMLInputElement | null>(null);
  const handleRef = (element: HTMLInputElement | null) => {
    if (typeof ref === 'function') {
      ref(element);
    }
    textFieldRef.current = element;
  };

  const isKeyboardOpen = useRecoilValue(isKeyboardShowAtom);

  useEffect(() => {
    if (!isKeyboardOpen) {
      textFieldRef.current?.blur();
    }
  }, [isKeyboardOpen]);

  return <StyledInput id={id} type={type} ref={handleRef} {...props} />;
});

const StyledInput = styled.input<Omit<ITextFieldBasePropTypes, 'id'>>`
  padding: 10px 0;
  width: 100%;
  caret-color: ${theme.color.primary};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  background-color: ${({ backgroundColor }) => (backgroundColor ? theme.color.gray7 : 'white')};
  color: ${theme.color.gray1};

  &::placeholder {
    color: ${theme.color.gray4};
    font-size: 1.5rem;
  }

  /** 텍스트 자동완성 내역 클릭 시 input 배경 색상 바뀌는 것 방지 */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border-radius: 10px;
    box-shadow: 0 0 0 30px ${({ backgroundColor }) => (backgroundColor ? theme.color.gray7 : 'white')} inset !important;
    -webkit-text-fill-color: ${theme.color.gray1};
  }

  &:-webkit-autofill:read-only {
    box-shadow: 0 0 0 30px ${theme.color.gray6} inset !important;
    -webkit-text-fill-color: ${theme.color.gray3};
  }
`;

TextFieldBase.displayName = 'TextFieldBase';
export default TextFieldBase;

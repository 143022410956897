import Tabs from 'components/Tabs';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { useNavigate } from 'react-router-dom';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import { MESSAGE_TABS } from '../constants';
import MoreDetailsMessageMobileList from '../Mobile/List';
import MobileFilter from '../Mobile/List/components/MobileFilter';
import MoreDetailsMessagePushList from '../Push/List';
import AppPushFilter from '../Push/List/components/AppPushFilter';

const MessageListPage = () => {
  const { getSearchParams } = useQueryString();
  const { hasPermission } = usePermission();

  const defaultTab = hasPermission(PERMISSION.message.sms.view) ? 'mobile' : 'push';
  const tab = getSearchParams('tab') ?? defaultTab;
  const currentTab = MESSAGE_TABS.find(tabData => tabData.value === tab) || MESSAGE_TABS[0];

  const navigate = useNavigate();
  return (
    <>
      <StickyWrapper>
        <Tabs data={MESSAGE_TABS} value={currentTab.value} onChange={tab => navigate(`?tab=${tab.value}`, { replace: true })} />
      </StickyWrapper>
      {currentTab.value === 'mobile' ? <MobileFilter /> : <AppPushFilter />}
      {currentTab.value === 'mobile' ? (
        <MoreDetailsMessageMobileList canView={hasPermission(PERMISSION.message.sms.view)} />
      ) : (
        <MoreDetailsMessagePushList canView={hasPermission(PERMISSION.message.push.view)} />
      )}
    </>
  );
};

export default MessageListPage;

export const BOOKING_GROUP_FORM_TEXT = {
  switch: {
    label: '예약 필수 수업',
  },
  description: {
    placeholder: '수업소개',
  },
  division: {
    placeholder: '수업구분',
    disabled: '수업구분 없음',
    drawer: {
      title: '수업구분',
    },
    notSelected: {
      value: 'division-id-notSelected',
      label: '선택 안함',
    },
  },
};

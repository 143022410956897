import { checkinParamsAtom } from 'recoil/moreDetail/atoms';

import { DEFAULT_CHECKIN_FILTER_OPTIONS, DEFAULT_CHECKIN_FILTER_TABS, DEFAULT_SCHEDULE_FILTER } from '../../constant';
import ScheduleCommonFilter from '../ScheduleCommonFilter';
import CheckinDrawerFilterOptions from './CheckinDrawerFilterOptions';
import CheckinWholeFilterOptions from './CheckinWholeFilterOptions';

const CheckinFilter = () => {
  return (
    <ScheduleCommonFilter
      filterAtom={checkinParamsAtom}
      filters={DEFAULT_CHECKIN_FILTER_OPTIONS}
      defaultTabLabels={DEFAULT_CHECKIN_FILTER_TABS}
      defaultFilter={DEFAULT_SCHEDULE_FILTER}
      renderDrawerOptions={CheckinDrawerFilterOptions}
      renderWholeFilterPopupOptions={CheckinWholeFilterOptions}
    />
  );
};

export default CheckinFilter;

import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

type Props = {
  mobile: string;
  enabled: boolean;
};

const useGetStaffMobile = ({ mobile, enabled }: Props) => {
  const { queryFn } = useQueryFnWithCommonParams({
    url: `/api/staff/mobile?mobile=${mobile}`,
  });

  return useQuery({
    queryKey: ['staff/mobile'],
    queryFn,
    enabled,
  });
};

export default useGetStaffMobile;

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { mobileConfirmCode, mobileRequired } from 'utils/validate';
import { object } from 'yup';

import ChangePhoneForm from './components/ChangePhoneForm';
import HeaderAndTitle from './components/HeaderAndTitle';
import KakaoDrawerDrawer from './components/KakaoDrawerDrawer';
import LeaveAlertDialog from './components/LeaveAlertDialog';
import { ChangePhoneNumberFormType } from './types';

const yupSchema = object().shape({ mobileRequired, mobileConfirmCode });

const MoreDetailsAccountPhoneNumber = () => {
  const methods = useForm<ChangePhoneNumberFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      step: 'number',
      mobileRequired: '',
      mobileConfirmCode: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <HeaderAndTitle>
        <ChangePhoneForm />
        <KakaoDrawerDrawer />
        <LeaveAlertDialog />
      </HeaderAndTitle>
    </FormProvider>
  );
};

export default MoreDetailsAccountPhoneNumber;

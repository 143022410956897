import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import useStatusBarColor from 'hooks/useStatusBarColor';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import localStorage from 'utils/localStorage';

import OnboardingFooterButton from './components/OnboardingFooterButton';
import OnboardingItem from './components/OnboardingItem';
import { ONBOARDING_CONTENT } from './constant';

const Onboarding = () => {
  useStatusBarColor(theme.color.gray6);

  const accessToken = localStorage.get('access_token');
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const changeSwiper = (newSwiper: SwiperType) => {
    setSwiper(newSwiper);
  };

  const slideNext = () => {
    if (page === ONBOARDING_CONTENT.length - 1) {
      accessToken ? navigate('/select-studio') : navigate('/intro');
    }
    swiper?.slideNext();
  };

  const slidePrev = () => {
    swiper?.slidePrev();
  };

  useEffect(() => {
    localStorage.set('onboardingShow', true);
  }, []);

  return (
    <Container>
      <Swiper
        onSlideChange={swiper => {
          setPage(swiper.activeIndex);
        }}>
        {ONBOARDING_CONTENT.map(content => (
          <SwiperSlide key={content.page}>
            <OnboardingItem content={content} changeSwiper={changeSwiper} />
          </SwiperSlide>
        ))}
      </Swiper>
      <FooterSection>
        <div>
          <div className="pagination">
            <div className="dots">
              {ONBOARDING_CONTENT.map(content => {
                return <div className={`dot ${page === content.page ? 'active' : ''}`} key={content.page} />;
              })}
            </div>
          </div>
          <OnboardingFooterButton page={page} clickNext={slideNext} clickPrev={page > 0 ? slidePrev : undefined} />
        </div>
      </FooterSection>
    </Container>
  );
};

export default Onboarding;

const Container = styled.div`
  height: 100vh;
  background-color: ${theme.color.gray6};
  overflow: hidden;

  .swiper {
    width: 100vw;
  }
`;

const FooterSection = styled.section`
  position: fixed;
  width: 100%;
  bottom: 0rem;
  z-index: ${Z_INDEX.stickyTab};
  background: linear-gradient(180deg, rgba(238, 240, 243, 0) 0%, ${theme.color.gray6} 18%);

  .pagination {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dots {
      display: flex;
      gap: 7px;
      .dot {
        width: 5px;
        height: 5px;
        background-color: rgba(53, 61, 73, 0.16);
        border-radius: 50%;
      }
      .active {
        background-color: ${theme.color.point};
        opacity: 1;
      }
    }
  }
`;

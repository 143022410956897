import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePatchStaffNoticeCommonReadAll = () => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `patch`,
    url: `/api/staff/notice/common-read-all`,
  });

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['staff/notice', 'common'] });
    },
  });
};

export default usePatchStaffNoticeCommonReadAll;

import usePostAuthMeInstanceTokenRegister from 'hooks/service/mutations/usePostAuthMeInstanceTokenRegister';
import useHardwareBackKey from 'hooks/useHardwareBackKey';
import useToast from 'hooks/useToast';
import Josa from 'josa-js';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import { currentVersionAtom } from 'recoil/moreDetail';
import { mediaPermissionAtom, type MediaPermissionType } from 'recoil/permission';
import { ReceiveDataType } from 'utils/communicateWithNative';
import { isParsableToJson } from 'utils/isParsableToJson';

import { IToastProps } from './Toast';

const AppListener = () => {
  const navigate = useNavigate();

  const { setToast } = useToast();
  const setPermission = useSetRecoilState(mediaPermissionAtom);
  const setCurrentVersion = useSetRecoilState(currentVersionAtom);
  const setIsKeyboardShow = useSetRecoilState(isKeyboardShowAtom);
  const handleBackKey = useHardwareBackKey();

  const { mutate: postFcmTokenMutate } = usePostAuthMeInstanceTokenRegister();

  const postFcmToken = useCallback(
    (fcmToken: string) => {
      const params = { instance_token: fcmToken };
      postFcmTokenMutate(params);
    },
    [postFcmTokenMutate],
  );

  useEffect(() => {
    const receiveEvent = (event: Event) => {
      // window, document 이벤트 타입불일치로 인한 타입 캐스팅
      const messageEvent = event as MessageEvent<string>;

      // 앱에서 받은 메세지 JSON 파싱
      if (!isParsableToJson(messageEvent?.data)) return;
      const data: ReceiveDataType = JSON.parse(messageEvent.data);

      // 메세지 타입별 처리
      switch (data?.type) {
        case 'FCM_TOKEN':
          postFcmToken(data.data as string);
          break;
        case 'UPDATE_MEDIA_PERMISSION':
          data?.data && setPermission(data.data as MediaPermissionType);
          break;
        case 'HARDWARE_BACK_KEY':
          handleBackKey();
          break;
        case 'NOTIFICATION_OPEN_APP':
          navigate('/notification');
          break;
        case 'CURRENT_VERSION':
          setCurrentVersion(data.data as string);
          break;
        case 'IS_KEYBOARD_SHOW':
          setIsKeyboardShow(data.data as boolean);
          break;
        case 'COPY_CLIP_BOARD_RESULT':
          {
            const res = data.data as { type: IToastProps['type']; target: string; bottom?: number };
            const message =
              res.type === 'success' ? `${Josa.r(res.target, '이/가')} 복사되었습니다.` : `${res.target} 복사에 실패했습니다.`;
            setToast({ type: res.type, message, bottom: res.bottom });
          }
          break;
      }
    };

    // iOS는 window, Android는 document에서 메세지 수신
    window.addEventListener('message', receiveEvent);
    document.addEventListener('message', receiveEvent);
    return () => {
      window.removeEventListener('message', receiveEvent);
      document.removeEventListener('message', receiveEvent);
    };
  }, [handleBackKey, setPermission, postFcmToken, navigate, setCurrentVersion, setIsKeyboardShow, setToast]);

  return null;
};

export default AppListener;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

const SkeletomItem = () => {
  return (
    <li>
      <Skeleton height={22} width={39} type="round" />
      <div className="item">
        <Skeleton height={20} width={93} type="round" />
        <Skeleton height={16} width={82} type="round" />
      </div>
    </li>
  );
};

const SkeletonTimeSchedule = () => {
  return (
    <Container>
      <div className="header">
        <Skeleton height={22} width={82} type="round" />
        <Skeleton className="custom-round" height={20} width={40} type="round" />
      </div>

      <div className="middle">
        <ul>
          <li>
            <Skeleton height={24} width={193} type="round" />
          </li>
          <SkeletomItem />
          <SkeletomItem />
        </ul>
      </div>

      <Divider thin />

      <div className="bottom">
        <ul>
          <li>
            <Skeleton height={24} width={78} type="round" />
          </li>
          <SkeletomItem />
          <SkeletomItem />
          <SkeletomItem />
        </ul>
      </div>
    </Container>
  );
};

export default SkeletonTimeSchedule;

const Container = styled.div`
  [type='round'] {
    border-radius: 6px;

    &.custom-round {
      border-radius: 10px;
    }
  }

  .header {
    ${theme.flex('row', 'center', 'space-between')};
    padding: 20px 20px 0;
    width: 100%;
  }

  .middle,
  .bottom {
    padding: 23px 20px;

    ul {
      li {
        ${theme.flex('row', 'flex-start', 'flex-start', 37)};

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        .item > div:first-of-type {
          margin-bottom: 6px;
        }
      }
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { TimeDatasetType } from 'pages/MoreDetails/Staff/Form/types';
import { useMemo } from 'react';
import filters from 'utils/filters';

type Props = {
  label: string;
  time: string;
  detailText?: string;
  restTimes?: TimeDatasetType[];
  gap?: number;
};

const TimeScheduleTable = ({ label, time, detailText, restTimes, gap }: Props) => {
  const showClosedTimeText = label !== '오전' && label !== '오후';

  const isEventWorkTime = label.length > 3;

  const detailTextElement = useMemo(() => {
    if (!detailText) return;
    return (
      <Typography className="detail-text" weight={500} textColor="gray3">
        {detailText}
      </Typography>
    );
  }, [detailText]);

  const restTimesTextElement = useMemo(() => {
    return restTimes?.map((schedule, index) => (
      <Typography key={`${schedule.day_of_week}-${index}`} className="detail-text" textColor="gray3">
        {filters.timePeriodForOnlyTime(schedule.start_time, schedule.end_time)}
      </Typography>
    ));
  }, [restTimes]);

  const isClosedDay = time === '휴무일';

  return (
    <Container gap={gap} showClosedTimeText={showClosedTimeText}>
      <div className="table">
        <Typography className={isEventWorkTime ? 'event-date-text' : undefined} size={15} textColor="gray2">
          {label}
        </Typography>
        <div>
          <Typography className="detail-time" size={15} weight={500}>
            {time}
          </Typography>

          {restTimes && !isClosedDay && showClosedTimeText && (
            <Typography className="closed-time" weight={500} textColor="gray2">
              휴식시간
            </Typography>
          )}
          {!isClosedDay && (detailText ? detailTextElement : restTimesTextElement)}
        </div>
      </div>
    </Container>
  );
};

export default TimeScheduleTable;

const Container = styled.div<{ gap?: number; showClosedTimeText?: boolean }>`
  .table {
    ${theme.flex('row', 'flex-start', 'flex-start')};
    gap: ${({ gap }) => gap || 50}px;

    > div:has(.detail-text) {
      .detail-time {
        margin-bottom: ${({ showClosedTimeText }) => (showClosedTimeText ? 4 : 2)}px;
      }
    }

    .closed-time {
      margin-bottom: 2px;
    }

    .event-date-text {
      width: 108px;
    }
  }
`;

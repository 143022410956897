import styled from '@emotion/styled';
import Typography from 'components/Typography';

type Props = {
  total: number;
};

const Total = ({ total }: Props) => {
  return (
    <Container>
      <Typography span size={13} weight={500} lineHeight={16} textColor="gray3">
        총
      </Typography>
      <Typography span size={13} weight={600} lineHeight={16} textColor="primary">
        {total}
      </Typography>
      <Typography span size={13} weight={500} lineHeight={16} textColor="gray3">
        명
      </Typography>
    </Container>
  );
};

export default Total;

const Container = styled.div`
  .typography:first-of-type {
    margin-right: 4px;
  }
`;

import dayjs from 'dayjs';

export const EVENT_WORK_TIME_TITLE = {
  work: '근무',
  rest: '휴식',
  closed: '휴무',
} as const;

export const LABEL = {
  closedDay: '휴무',
  workTime: '근무',
} as const;

export const ALL_DAYS = [
  { value: 1, label: '월요일' },
  { value: 2, label: '화요일' },
  { value: 3, label: '수요일' },
  { value: 4, label: '목요일' },
  { value: 5, label: '금요일' },
  { value: 6, label: '토요일' },
  { value: 0, label: '일요일' },
] as const;

export const DEFAULT_RANGE: [Date, Date] = [
  dayjs().set('hour', 9).set('minute', 0).toDate(),
  dayjs().set('hour', 18).set('minute', 0).toDate(),
];

export const NO_CONTENT_TEXT = '선택된 날짜가 없습니다.';

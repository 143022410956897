import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClick: () => void;
};

const BottomWrapper = ({ onClick }: Props) => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="help-text">
        <Typography size={14} weight={500} textColor="gray3">
          등록이 되어도 시설이 계속 보이지 않나요?
        </Typography>
        <Button fontSize={14} fontWeight={500} textColor="primary" onClick={() => navigate('/more-details/settings/help')}>
          고객 센터
        </Button>
      </div>

      <div className="button-wrapper">
        <Button
          type="submit"
          form="select-studio-form"
          size="large56"
          color="point"
          variant="contained"
          fullWidth
          onClick={onClick}>
          건너뛰기
        </Button>
      </div>
    </Container>
  );
};

export default BottomWrapper;

const Container = styled.section`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${theme.color.white};
  z-index: ${Z_INDEX.footerButton};

  .help-text {
    ${theme.flex('column', 'center', 'center', 4)};
    margin-bottom: 8px;
  }

  .button-wrapper {
    padding: 10px 20px;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import { IHeaderProps } from 'components/Header';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useGetRole from 'hooks/service/queries/useGetRole';
import { findIndex } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';

import { HEADER_TEXT } from '../constants';
import RoleList from './components/RoleList';
import { RoleManagementFormType } from './types';

const RoleManagement = () => {
  const { checkPermission } = useGetPermissionDoHavePermission();
  const { data: rolesResponse } = useGetRole();
  const roles = rolesResponse.map(({ id, display_name }) => {
    return {
      roleId: id,
      displayName: display_name,
      isModify: false,
      isNew: false,
    };
  });

  const { control } = useForm<RoleManagementFormType>({
    defaultValues: { roles },
  });

  const {
    fields: roleFields,
    append: roleAppendField,
    remove: roleRemoveField,
    update: roleUpdateField,
  } = useFieldArray({ control, name: 'roles' });

  const appendRole = useCallback(() => {
    checkPermission(PERMISSION.role.edit.id, {
      onSuccess: () => {
        roleAppendField({
          roleId: 0,
          displayName: '',
          isModify: true,
          isNew: true,
        });
      },
    });
  }, [roleAppendField, checkPermission]);

  const headerOptions: IHeaderProps = useMemo(() => {
    const isAlreadyInput = findIndex(roleFields, { isModify: true }) > -1;
    return {
      title: HEADER_TEXT.roleManagement,
      rightButton: !isAlreadyInput && (
        <IconButton onClick={appendRole}>
          <Icon name="headerPlus" />
        </IconButton>
      ),
    };
  }, [roleFields, appendRole]);

  return (
    <MainLayout header={headerOptions}>
      <Container>
        <RoleList roles={roleFields} remove={roleRemoveField} update={roleUpdateField} />
      </Container>
    </MainLayout>
  );
};

export default RoleManagement;

const Container = styled.section`
  padding: 16px 20px;

  .role-list {
    padding: 12px 16px;
    border-radius: 12px;
    background-color: ${theme.color.gray7};

    hr {
      margin: 12px 0;
      width: 100%;
      background-color: ${theme.color.gray5};
      opacity: 0.8;
    }
  }
`;

import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useDeleteCounseling from 'hooks/service/mutations/useDeleteCounseling';
import useGetCounseling from 'hooks/service/queries/useGetCounseling';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import useToast from 'hooks/useToast';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { currentCounselingAtom } from 'recoil/counseling';
import MainLayout from 'sharedComponents/MainLayout';
import { ColorType } from 'types/colorTypes';
import getColor from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import CounselDetailInfo from './CounselDetailInfo';
import CounselDetailRightButton from './CounselDetailRightButton';

const CounselDetailLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const counselId = useParamsId();
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();
  const resetCounselRecoilState = useResetAllRecoilState([currentCounselingAtom]);

  const [isOpen, setIsOpen] = useState(false);
  const {
    data: { data: counsel },
  } = useGetCounseling(counselId);

  const { mutate: deleteCounselMutate } = useDeleteCounseling(counselId);

  const deleteCounsel = () => {
    if (!counsel) return;

    deleteCounselMutate(undefined, {
      onSuccess: () => {
        setToast({ type: 'success', message: '상담이 삭제되었습니다.' });
        /** 상담 폼에서 등록 후 상세보기 페이지로 넘어왔을 때 (prevUrl 있음) */
        if (location.search) {
          navigate('/member?tab=counsel');
        } else {
          navigate('/schedule');
        }
        resetCounselRecoilState();
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  const representativeColor = getColor(counsel.staff.color) as ColorType;
  const currentContrastColor = getContrastTextColor(representativeColor) as ColorType;

  const closeCounselDetail = useCallback(() => {
    /** 상담 폼에서 등록 후 상세보기 페이지로 넘어왔을 때 (prevUrl 있음) */
    if (location.search) {
      navigate('/member?tab=counsel');
    } else {
      navigate('/schedule');
    }
    resetCounselRecoilState();
  }, [location.search, navigate, resetCounselRecoilState]);

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={closeCounselDetail}>
            <Icon name="headerClose" color={currentContrastColor} />
          </IconButton>
        ),
        rightButton: <CounselDetailRightButton setIsOpen={setIsOpen} iconColor={currentContrastColor} />,
        title: '상담 상세보기',
        bgColor: representativeColor,
        textColor: currentContrastColor,
      }}>
      <CounselDetailInfo counsel={counsel} isOpen={isOpen} setIsOpen={setIsOpen} deleteCounsel={deleteCounsel} />
    </MainLayout>
  );
};

export default CounselDetailLayout;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import React from 'react';

import Button from '.';
import IconButton from './IconButton';

export interface ButtonGroupProps {
  /** Button 컴포넌트 */
  children: React.ReactNode;
  /** 버튼 사이 간격 */
  gap?: number;
  /** 양쪽 버튼 크기 다를 때, 왼쪽 버튼 width size */
  leftButtonWidth?: number;
  className?: string;
}

const ButtonGroup = ({ children, className, ...props }: ButtonGroupProps) => {
  return (
    <Container className={className ? `button-group ${className}` : `button-group`} {...props}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child) && (child.type === Button || child.type === IconButton)) {
          return React.cloneElement(child);
        }
        return null;
      })}
    </Container>
  );
};

const Container = styled.div<ButtonGroupProps>(
  ({ gap }) => css`
    ${theme.flex('row', '', '', gap || 8)};
  `,
  ({ leftButtonWidth }) =>
    leftButtonWidth &&
    css`
      & button:first-of-type {
        width: ${leftButtonWidth}px;
      }
      & button:last-of-type {
        flex: 1;
      }
    `,
);

export default ButtonGroup;

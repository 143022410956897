import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostLogin = () => {
  const { mutationFn } = useMutationFnWithCommonParams<Params, Response>({
    method: 'post',
    url: '/api/auth/login',
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostLogin;

type Params = {
  mobile: string;
  password: string;
};

export type Response = {
  account_token: string;
  is_name_nickname: boolean;
};

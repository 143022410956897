import styled from '@emotion/styled';
import { Player } from '@lottiefiles/react-lottie-player';
/** Lottie 애니메이션 json 파일 경로  */
import loadingSrc from 'assets/animation/lottie_processing.json';

/** 작업 진행중 애니메이션 로딩 */
const Processing = () => {
  return (
    <Container>
      <Player src={loadingSrc} loop autoplay />
    </Container>
  );
};

export default Processing;

const Container = styled.div`
  .lf-player-container {
    width: 180px;
  }
`;

import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { AMOUNT_FIELDS, TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import { PaymentCheckboxRow } from './StyledComponents';

/** 환불 시, 환불금을 포인트로 받을 때 (사실상 포인트 적립의 개념) */
const PointRefund = () => {
  const { control, setValue, getValues } = useFormContext<TicketPaymentFormType>();
  const isCheckedPoint = useWatch({ control, name: 'isCheckedPoint' });
  const rewardPoint = useWatch({ control, name: 'payment.reward_point' });

  /**
   * 포인트 전액 클릭 시, 포인트로 총 결제 금액 전체 환불 받도록
   * 카드, 현금, 계좌이체 0원으로 세팅
   */
  const clickAllApplyButton = () => {
    AMOUNT_FIELDS.map(methodsKeyValue => setValue(methodsKeyValue, 0, { shouldDirty: true }));
    setValue('payment.reward_point', getValues('salePrice') || 0, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  /** 포인트 금액이 0원이면 체크해제 */
  useEffect(() => {
    if (!rewardPoint) {
      setValue('isCheckedPoint', false, { shouldDirty: true });
    }
  }, [rewardPoint, setValue]);

  return (
    <>
      <PaymentCheckboxRow>
        <div className="left">
          <Controller
            name="isCheckedPoint"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <CheckBox
                gap={10}
                id="point"
                label={TICKET_PAYMENT_FORM_TEXT.point.label}
                checked={value}
                value={'point'}
                onChange={e => {
                  if (!e.target.checked) {
                    setValue('payment.reward_point', 0, { shouldDirty: true });
                  }
                  onChange(e);
                }}
                {...field}
              />
            )}
          />
        </div>
        {isCheckedPoint && (
          <div className="apply-all">
            <Button
              size="tiny"
              variant="outlined"
              edge="circular"
              padding={{ left: 8, right: 8 }}
              textColor="gray2"
              onClick={clickAllApplyButton}>
              포인트전액
            </Button>
          </div>
        )}
        <div className="right">
          <UnderlinedTextFieldController
            controllerProps={{ control, name: 'payment.reward_point' }}
            inputMode="numeric"
            placeholder="0"
            textAlign="right"
            suffix={
              <Typography span size={15} weight={500}>
                P
              </Typography>
            }
            disabled={!isCheckedPoint}
            value={filters.numberComma(rewardPoint)}
            onChange={e => {
              setValue('payment.reward_point', filters.numberComma(e.target.value, { reverse: true }), {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
          />
        </div>
      </PaymentCheckboxRow>
    </>
  );
};

export default PointRefund;

import { MEMBER_FORM_HEADER_TITLE_TEXT, PRODUCT_FORM_HEADER_TITLE_TEXT } from '../constants';

type Props = {
  hasTitle?: boolean;
  [key: string]: string | string[] | boolean | undefined;
};

/** 회원 등록/수정 폼의 페이지 별 header title 반환 */
const getFormHeaderTitle = ({ mode, type, productMode, productType, hasTitle = true }: Props) => {
  if (!hasTitle) {
    return '';
  }

  /** 회원 폼 내 수강권 폼 헤더 */
  if (productMode && productType) {
    return PRODUCT_FORM_HEADER_TITLE_TEXT[productType as string][productMode as string];
  }

  const key = (type || mode) as keyof typeof MEMBER_FORM_HEADER_TITLE_TEXT;
  return MEMBER_FORM_HEADER_TITLE_TEXT[key];
};

export default getFormHeaderTitle;

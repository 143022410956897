import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { AttachmentResponse } from '../mutations/usePostAttachment';

const useInfinityMemo = (params: MemoParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MemoResponse, MemoParams>({
    url: `/api/memo`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['memo', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityMemo;

export type MemoCommonParams = {
  ref_id: number;
  ref_type: 'tickets' | 'user_tickets' | 'lectures' | 'member' | 'studios';
};

export type MemoParams = MemoCommonParams & {
  order_by: 'asc' | 'desc';
};

export type MemoResponse = MemoCommonParams & {
  id: number;
  studio_user_id: number;
  staff_name: string;
  type: string;
  memo: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  attachments: AttachmentResponse[];
};

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Form from 'components/Form';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Timer from 'components/Timer';
import Typography from 'components/Typography';
import { PLACEHOLDER } from 'constants/text';
import usePostCodeConfirm from 'hooks/service/mutations/usePostCodeConfirm';
import usePostSendMobileConfirmCode from 'hooks/service/mutations/usePostSendMobileConfirmCode';
import TitleStep from 'pages/FindPassword/components/TitleStep';
import { CHANGE_PHONE_NUMBER_TEXT } from 'pages/MoreDetails/Account/ChangePhoneNumber/constants';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { validationIdCodeAtom } from 'recoil/validationIdCode';
import SendMobileKakaoDrawer from 'sharedComponents/SendMobileKakaoDrawer';
import { CustomError } from 'types/errorTypes';
import { mobileConfirmCode, mobileRequired } from 'utils/validate';
import { object } from 'yup';

import { SUB_TITLE } from '../constants';
import { MobileDataType, MobileFormType } from '../types';

type Props = {
  nextStep: (data: MobileDataType) => void;
};

const yupSchema = object().shape({ mobileRequired, mobileConfirmCode });
const params = {
  studio_id: undefined,
  staff_id: undefined,
};

const MobileStep = ({ nextStep }: Props) => {
  const navigate = useNavigate();
  const [isMobilePass, setIsMobilePass] = useState(false);
  const [isKakaoDrawerOpen, setIsKakaoDrawerOpen] = useState(false);
  const [isShowFindPassword, setIsShowFindPassword] = useState(false);

  const [validationIdCode, setValidationIdCode] = useRecoilState(validationIdCodeAtom);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    watch,
    formState: { isValid, dirtyFields, isSubmitting },
  } = useForm<MobileFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      mobileRequired: '',
      mobileConfirmCode: '',
    },
  });

  const { mutate: postSendMobileConfirmCodeMutate, isPending: isSendMobileConfirmCodeLoading } = usePostSendMobileConfirmCode({
    mobile: getValues('mobileRequired'),
  });

  const { mutate: postCodeConfirmMutate } = usePostCodeConfirm({ type: 'mobile-confirm' });

  const sendMobileConfirmCode = () => {
    postSendMobileConfirmCodeMutate(undefined, {
      onSuccess: ({ data }) => {
        const validationId = data.validation_code;
        setValidationIdCode(prev => ({ ...prev, id: validationId }));
        setIsMobilePass(true);
        setIsKakaoDrawerOpen(true);
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('mobileRequired', { message });
        setIsShowFindPassword(prev => !prev);
        return false;
      },
    });
  };

  const save: SubmitHandler<MobileFormType> = inputValues => {
    const { mobileRequired } = inputValues;

    /** 휴대폰 번호 확인이 끝나지 않았으면, 인증코드 발송 */
    if (!isMobilePass) {
      sendMobileConfirmCode();
      return;
    }

    setValidationIdCode({
      id: validationIdCode.id,
      code: getValues('mobileConfirmCode'),
    });

    postCodeConfirmMutate(params, {
      onSuccess: () => {
        nextStep({ mobile: mobileRequired });
      },
      onError: (error: CustomError) => {
        const message = error.response?.data.message;
        setError('mobileConfirmCode', { message });
      },
    });
  };

  const bottomButtonText = useMemo(() => (isMobilePass ? '확인' : '인증번호 전송'), [isMobilePass]);

  const handleClear = (name: 'mobileRequired') => {
    setValue(name, '', { shouldValidate: true });
  };

  const subTitleOptions = {
    bottom: 40,
    title: isMobilePass ? SUB_TITLE.CHECK_MOBILE : SUB_TITLE.ENTER_MOBILE,
    message: { position: 'bottom', text: '로그인 시 아이디로 사용됩니다.' },
  };

  const timeoutError = () => {
    setError('mobileConfirmCode', { type: 'timeout', message: CHANGE_PHONE_NUMBER_TEXT.expired });
  };

  return (
    <>
      <TitleStep currentStep={1} subTitleOptions={subTitleOptions} />
      <Form>
        <OutlinedTextFieldController
          inputMode="numeric"
          controllerProps={{ control, name: 'mobileRequired' }}
          placeholder={PLACEHOLDER.MOBILE}
          onClear={() => handleClear('mobileRequired')}
        />
        {isMobilePass && (
          <MobileConfirmWrapper>
            <OutlinedTextFieldController
              inputMode="numeric"
              controllerProps={{ control, name: 'mobileConfirmCode' }}
              suffixMarginRight={8}
              suffix={
                <Button
                  color="gray"
                  fontSize={13}
                  fontWeight={500}
                  textColor="gray2"
                  size="small"
                  variant="contained"
                  onClick={sendMobileConfirmCode}
                  padding={{ left: 8, right: 8 }}
                  disabled={isSendMobileConfirmCodeLoading}>
                  인증번호 재발송
                </Button>
              }
            />
            {!isSendMobileConfirmCodeLoading && <Timer count={60 * 5} onTimeOut={timeoutError} />}
          </MobileConfirmWrapper>
        )}
        <MobileConfirmButton
          type="submit"
          variant="contained"
          color="point"
          fullWidth
          size="medium48"
          disabled={
            !isValid ||
            isSubmitting ||
            (isMobilePass && (watch('mobileConfirmCode').length !== 6 || !dirtyFields.mobileConfirmCode))
          }
          onClick={handleSubmit(save)}>
          {bottomButtonText}
        </MobileConfirmButton>
      </Form>
      {isShowFindPassword && (
        <FindPasswordLinkWrapper>
          <Typography textColor="gray3" weight={500}>
            비밀번호를 잊으셨나요?
          </Typography>
          <Button onClick={() => navigate('/find-password?step=1')}>
            <Typography textColor="primary" weight={500}>
              비밀번호 찾기
            </Typography>
          </Button>
        </FindPasswordLinkWrapper>
      )}

      <SendMobileKakaoDrawer isOpen={isKakaoDrawerOpen} onClose={() => setIsKakaoDrawerOpen(false)} />
    </>
  );
};

export default MobileStep;

const MobileConfirmWrapper = styled.div`
  .outline-errormessage-wrapper {
    white-space: pre-wrap;
  }
`;

const MobileConfirmButton = styled(Button)`
  margin-top: 16px;
`;

const FindPasswordLinkWrapper = styled.div`
  ${theme.flex('row', 'center', 'center', 4)}
  padding-top: 24px;
`;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 그룹 수업 예약대기 취소
 */
const usePatchBookingGroupCancelWaiting = (bookingId: number) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'patch',
    url: `/api/booking/group/cancel/waiting/${bookingId}`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['booking'],
      });
    },
  });
};

export default usePatchBookingGroupCancelWaiting;

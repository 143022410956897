import { get } from 'lodash';
import { clickOptionMultiple } from 'pages/MemberDetail/utils/memberDetailFilters';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps, FilterOptionsType } from 'sharedComponents/Filters/types';

import PeriodSelectFilter from './PeriodSelectFilter';
import StaffSelectFilter from './StaffSelectFilter';

const DrawerFilterOptions = ({ filters, filterChanged, currentFilterTab, changeOption }: FilterOptionsProps) => {
  const currentFilter = filters.find(filter => filter.key === currentFilterTab?.value);

  /** 버튼 필터 다중 선택 */
  const changeOptionMultiple = (option: FilterOptionsType, key: string) => {
    const prevOptionValues = filterChanged[key] as string[] | undefined;
    const newOption = clickOptionMultiple({ key, option, prevOptionValues });
    changeOption(newOption, key);
  };

  return (
    <>
      {currentFilter?.type === 'button' && (
        <ButtonFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOptionMultiple}
        />
      )}
      {currentFilter?.type === 'period' && (
        <PeriodSelectFilter currentFilter={currentFilter} selectedOption={get(filterChanged, 'period')} onClick={changeOption} />
      )}
      {currentFilter?.type === 'select' && (
        <StaffSelectFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, 'selectedStaffs')}
          onClick={changeOption}
        />
      )}
    </>
  );
};

export default DrawerFilterOptions;

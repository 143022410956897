export const HELP_BASIC_TEXT = {
  title: '고객센터',
  subTitles: ['고객님,', '무엇을 도와드릴까요?'],
};

export const HELP_NAVIGATIONS = [
  { name: '스튜디오메이트 공지', path: '/more-details/settings/notice' },
  { name: '자주 묻는 질문', path: '/more-details/settings/faq' },
  { name: '이메일 문의', path: 'mailto:support@studiomate.kr' },
];

export const HELP_NOTICE_SUMMARY_LIMIT = 3;

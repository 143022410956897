import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckRadio from 'components/Radio/CheckRadio';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CALENDAR_SETTING_TEXT, CALENDER_SETTING_MENUS } from '../../constants';
import { CalendarSettingsFormType, DayToggleType } from '../../types';
import getDayLabel from '../../utils/getDayLabel';
import DayLabel from '../DayLabel';
import SettingInfoTooltip from '../SettingInfoTooltip';

const DayRange = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { control, setValue } = useFormContext<CalendarSettingsFormType>();
  /** 현재 설정하는 Drawer가 시작요일인지, 끝요일인지 판단 */
  const [dayToggle, setDayToggle] = useState<DayToggleType>('start');

  const [currentStartDay, currentEndDay] = useWatch({ control, name: 'dayRange' });

  const openDayDrawer = (type: DayToggleType) => {
    setDayToggle(type);
    setIsDrawerOpen(true);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Container>
        <div className="title-wrapper">
          <Typography size={15} weight={500} textColor="gray2">
            {CALENDAR_SETTING_TEXT.dayRange.title}
          </Typography>

          <SettingInfoTooltip
            isOpen={isTooltipOpen}
            setIsOpen={setIsTooltipOpen}
            message={CALENDAR_SETTING_TEXT.dayRange.tooltipMessage}
          />
        </div>

        <div className="label-wrapper">
          <DayLabel label={getDayLabel(currentStartDay, '월요일')} onClick={() => openDayDrawer('start')} />
          <Typography size={15} weight={500} textColor="gray3">
            부터
          </Typography>
          <DayLabel label={getDayLabel(currentEndDay, '일요일')} onClick={() => openDayDrawer('end')} />
        </div>
      </Container>

      <Drawer isOpen={isDrawerOpen} onClose={onClose}>
        <DayDrawerContainer>
          {CALENDER_SETTING_MENUS.dayRange.map(({ id, label, value }) => {
            const isEndDay = dayToggle === 'end';
            const isChecked = isEndDay ? value === currentEndDay : value === currentStartDay;
            const isDisabled = isEndDay && value !== 0 && currentStartDay > value;

            return (
              <CheckRadio
                key={id}
                id={id}
                label={
                  <Typography
                    className={isDisabled ? 'day-disabled' : ''}
                    size={17}
                    weight={isChecked ? 600 : 400}
                    textColor={isChecked ? 'primary' : 'gray1'}>
                    {label}
                  </Typography>
                }
                value={value}
                checked={isChecked}
                /**
                 * 끝요일은 시작요일에서 선택한 요일과 같거나 이후 요일만 선택 가능
                 * 일요일(0)은 제외
                 */
                disabled={isDisabled}
                onChange={e => {
                  const dayNumber = Number(e.target.value);
                  if (isEndDay) setValue('dayRange.1', dayNumber);
                  else {
                    setValue('dayRange.0', dayNumber);
                    /** 시작요일 설정 시, 끝요일을 최소 시작요일로 변경 */
                    if (currentEndDay !== 0 && dayNumber > currentEndDay) {
                      setValue('dayRange.1', dayNumber);
                    }
                  }
                  onClose();
                }}
              />
            );
          })}
        </DayDrawerContainer>
      </Drawer>
    </>
  );
};

export default DayRange;

const Container = styled.div`
  ${theme.flex('row', '', 'space-between')};
  padding: 18px 16px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 3px 10px 0px rgba(145, 145, 145, 0.12);

  .title-wrapper {
    ${theme.flex('row', 'center', 'flex-start', 2)};
  }

  .label-wrapper {
    ${theme.flex('row', 'center', '', 16)};
  }

  .tooltip-contents-wrapper {
    width: 222px !important;
    gap: 0 !important;
  }
`;

const DayDrawerContainer = styled.div`
  ${theme.flex('column', '', '', 8)};

  .radio-check-label {
    min-height: 40px;
  }

  .day-disabled {
    color: ${theme.color.gray4};
  }
`;

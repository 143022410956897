// 리뉴얼관리자 회원필터와 시설웹 필터를 맞추기 위한 값
export const FILTER_MEMBER_TAG_ADAPTER: {
  message: Record<string, 'active' | 'inHolding' | 'inactive' | 'unpaid'>;
  member: Record<string, 'active' | 'holding' | 'expired' | 'unpaid'>;
} = {
  message: {
    active: 'active',
    holding: 'inHolding',
    expired: 'inactive',
    unpaid: 'unpaid',
  },
  member: {
    active: 'active',
    inHolding: 'holding',
    inactive: 'expired',
    unpaid: 'unpaid',
  },
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import CheckRadioTypography from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioTypography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import { StaffForFilter } from 'hooks/service/queries/useGetFilterStaffs';
import { PointStaffsSelectFormType, PointStaffType } from 'pages/MemberDetail/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  allStaffs: Array<PointStaffType>;
  data: StaffForFilter[];
  isOpen: boolean;
  onClose: () => void;
  saveFilter: (selectedStaffs: PointStaffType[]) => void;
};

const SelectStaffDrawerContents = ({ data, allStaffs, isOpen, onClose, saveFilter }: Props) => {
  const { control, setValue } = useFormContext<PointStaffsSelectFormType>();

  const currentStaffId = useRecoilValue(staffIdAtom);
  const selectedStaffs = useWatch({ control, name: 'selectedStaffs' });
  const tempSelectedStaffs = useWatch({ control, name: 'tempSelectedStaffs' });
  const isAllChecked = tempSelectedStaffs.length === data.length;
  const isOnlySomeChecked = !isAllChecked && !!tempSelectedStaffs.length;

  const closeDrawer = () => {
    setValue('tempSelectedStaffs', selectedStaffs);
    onClose();
  };

  const save = () => {
    setValue('selectedStaffs', tempSelectedStaffs);
    saveFilter(tempSelectedStaffs);
    onClose();
  };

  return (
    <StyledDrawer
      isOpen={isOpen}
      onClose={closeDrawer}
      header={<OneLinedTitle title="강사 선택" />}
      button={{ text: '선택 완료', onClick: save, disabled: !tempSelectedStaffs.length }}>
      <Container isOnlySomeChecked={isOnlySomeChecked}>
        <Controller
          control={control}
          name="tempSelectedStaffs"
          render={({ field: { value, onChange } }) => {
            return (
              <CheckBox
                id="point-filter-staff-all"
                className="checkbox-all"
                label={
                  <CheckRadioTypography weight={500} isSelected={false}>
                    전체
                  </CheckRadioTypography>
                }
                gap={16}
                value="all"
                iconName={isAllChecked || !value.length ? 'selectCheckBold' : 'lineBold'}
                checkColor={isOnlySomeChecked || isAllChecked ? 'white' : 'gray5'}
                checked={isAllChecked}
                onChange={e => {
                  if (!e.target.checked) {
                    onChange([]);
                  } else {
                    onChange(allStaffs);
                  }
                }}
              />
            );
          }}
        />
        <Divider thin thinColor="gray6" />
        <StyledUl>
          {data.map(({ id, name, avatar }) => {
            return (
              <li key={id}>
                <Controller
                  control={control}
                  name="tempSelectedStaffs"
                  render={({ field: { value, onChange } }) => {
                    const checked = value.some(field => field.value === id);
                    return (
                      <CheckBox
                        id={`${id}`}
                        label={
                          <AvatarLabel
                            id={`${id}`}
                            label={name}
                            imgUrl={avatar}
                            isMe={currentStaffId === id}
                            weight={500}
                            gap={8}
                          />
                        }
                        gap={16}
                        checked={checked}
                        onChange={e => {
                          const staffValue = {
                            value: id,
                            label: name,
                            isMe: currentStaffId === id,
                            staffImgUrl: getImageUrl(avatar),
                          };
                          if (e.target.checked) {
                            onChange([...value, staffValue]);
                          } else {
                            onChange([...value.filter(staff => staff.value !== id)]);
                          }
                        }}
                      />
                    );
                  }}
                />
              </li>
            );
          })}
        </StyledUl>
      </Container>
    </StyledDrawer>
  );
};

export default SelectStaffDrawerContents;

const StyledDrawer = styled(SingleButtonDrawer)`
  padding-top: 0;
`;

const Container = styled.div<{ isOnlySomeChecked: boolean }>(
  css`
    .checkbox-all {
      padding: 8px 0;
    }
    hr {
      margin-top: 12px;
    }
  `,
  ({ isOnlySomeChecked }) =>
    isOnlySomeChecked &&
    css`
      .checkbox-all {
        .input-select-base-input-wrapper input + span {
          border-color: ${theme.color.primary};
          background-color: ${theme.color.primary};
        }
      }
    `,
);

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding-top: 12px;
  padding-left: 20px;

  li {
    padding: 8px 0;

    .input-select-base-input-wrapper {
      flex: 0 0 auto;
    }
  }
`;

import { useSuspenseQuery } from '@tanstack/react-query';

import { StaffType } from 'types/staffTypes';
import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

type Props = {
  targetStaffId: number;
};

const useGetStaffEventSchedules = ({ targetStaffId }: Props) => {
  const { queryFn } = useQueryFnWithCommonParams<StaffEventSchedulesResponse>({
    url: `/api/staff/eventSchedules/${targetStaffId}`,
  });

  return useSuspenseQuery({
    queryKey: ['event-schedules'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetStaffEventSchedules;

export type StaffEventSchedulesResponse = Record<'event_rest_time' | 'event_work_time', StaffType['schedules']>;

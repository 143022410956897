import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { AllPageModeType } from 'pages/Booking/types';
import getFormHeaderTitle from 'pages/Booking/utils/getFormHeaderTitle';
import { isCompareArrayDifferent } from 'pages/Booking/utils/selectMembers';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';

import { BookingPrivateFormType } from '../../types';
import SelectMemberLeaveDialog from '../components/SelectMembers/SelectMemberLeaveDialog';
import FormDetailSetting from './FormDetailSetting';
import LeaveDialog from './LeaveDialog';

type Props<T extends BookingPrivateFormType> = UseFormReturn<T> & {
  pageMode: Exclude<AllPageModeType, 'update'>;
};

/** 프라이빗 수업 폼에서 회원 선택이 가능한 생성/복사/이후 모든 수정 폼에서 사용 */
const PrivateFormLayout = <T extends BookingPrivateFormType>({ children, pageMode, ...methods }: PropsWithChildren<Props<T>>) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const setCustomBackHandler = useSetRecoilState(customBackHandlersAtom);

  const { getAllSearchParams } = useQueryString();
  const { type, setting, step } = getAllSearchParams();
  const isConfirm = !type && step === '2';

  const headerTitle = getFormHeaderTitle({ bookingType: 'private', mode: pageMode, type, setting, hasTitle: !isConfirm });

  const clickBack = useCallback(() => {
    const { prevSelectedUserTickets, selectedUserTickets } = methods.watch();
    const userTicketIds = selectedUserTickets.map(({ id }) => id);
    const prevUserTicketIds = prevSelectedUserTickets.map(({ id }) => id);
    if (isCompareArrayDifferent({ userTicketIds, prevUserTicketIds }) && type === 'selectMember') {
      setIsOpen(true);
      return;
    }
    navigate(-1);
  }, [methods, navigate, type]);

  /** 안드로이드 물리 백버튼 클릭 시 실행되도록 로직 저장 */
  useEffect(() => {
    // 회원 선택일 때만 등록
    if (type === 'selectMember' && !isOpen) {
      setCustomBackHandler(prev => [...prev, { handler: clickBack, key: 'selectMember' }]);
    }
    return () => {
      setCustomBackHandler(prev => prev.filter(handler => handler.key !== 'selectMember'));
    };
  }, [setCustomBackHandler, type, isOpen, clickBack]);

  return (
    <MainLayout
      header={{
        title: headerTitle,
        leftButton: (
          <IconButton onClick={clickBack}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        <FormDetailSetting pageMode={pageMode} type={type} />
        {!type && children}
        <LeaveDialog pageMode={pageMode} />
        {isOpen && <SelectMemberLeaveDialog onClose={() => setIsOpen(false)} />}
      </FormProvider>
    </MainLayout>
  );
};

export default PrivateFormLayout;

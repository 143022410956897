import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostOverlapNickname = () => {
  const { mutationFn } = useMutationFnWithCommonParams<Params, Response>({
    method: 'post',
    url: '/api/auth/overlap-nickname',
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostOverlapNickname;

type Params = {
  nickname: string;
};

type Response = {
  code: number;
  message: string;
};

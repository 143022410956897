import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { TICKET_INACTIVE_TEXT } from 'pages/TicketDetail/constants';
import { TicketInactiveFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { FooterButtonWrapper } from 'sharedComponents/FooterButton';
import { InfoTypography } from 'sharedComponents/InfoTypography';

const SubmitButton = () => {
  const {
    formState: { isValid },
  } = useFormContext<TicketInactiveFormType>();
  const isMutating = useIsMutating() > 0;

  return (
    <Container>
      <TextWrapper>
        <Icon name="information" size={16} color="gray3" />
        <InfoTypography size={14}>예약되어 있는 수업은 자동으로 취소됩니다.</InfoTypography>
      </TextWrapper>
      <Button
        type="submit"
        fullWidth
        color="point"
        size="large56"
        fontSize={15}
        variant="contained"
        disabled={!isValid || isMutating}>
        {TICKET_INACTIVE_TEXT.form.footerButton}
      </Button>
    </Container>
  );
};

export default SubmitButton;

const Container = styled(FooterButtonWrapper)`
  padding: 40px 20px 10px;
  margin-top: auto;
`;

const TextWrapper = styled.div`
  ${theme.flex('', 'center', '', 5)};
  margin-bottom: 12px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';

type Props = {
  placeholder?: string;
};

const FallbackStaffField = ({ placeholder = '담당강사' }: Props) => {
  return (
    <Container>
      <Avatar />
      <Typography size={15} textColor="gray3">
        {placeholder}
      </Typography>
    </Container>
  );
};

export default FallbackStaffField;

const Container = styled.div`
  padding: 0 20px;
  height: 56px;
  ${theme.flex('', 'center', 'flex-start', 10)};
  border-bottom: 1px solid ${theme.color.gray8};
`;

import { MOBILE_DEFAULT_PERIOD } from './constants';
import { MobileSelectedFilterType } from './type';

export const convertToMobileParams = (selectedFilter: MobileSelectedFilterType, searchKeyword: string) => {
  const { message_status, message_type, ...filter } = selectedFilter;

  return {
    message_status,
    message_type,
    start_date: filter.period?.start || MOBILE_DEFAULT_PERIOD.start,
    end_date: filter.period?.end || MOBILE_DEFAULT_PERIOD.end,
    search: searchKeyword,
  };
};

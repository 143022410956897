import { ETC_SCHEDULE_COLOR, MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import ScheduleColorCheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer/ScheduleColorCheckRadioDrawer';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { ETC_COLOR_DATA } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

const SelectColor = () => {
  const { control } = useFormContext<EtcScheduleCreateFormType>();
  const color = useWatch({ control, name: 'color' });
  const representativeColor = useWatch({ control, name: 'representativeColor' });

  const [isOpen, setIsOpen] = useState(false);
  const buttonText = ETC_COLOR_DATA.find(item => item.value === color)?.label || '대표 컬러';

  return (
    <>
      <FormFieldLinkButton
        hasValue={!!color}
        iconName="colorChips"
        iconColor={MATCH_REPRESENTATIVE_COLOR[color] || ETC_SCHEDULE_COLOR[color]}
        onClick={() => setIsOpen(true)}>
        {buttonText}
      </FormFieldLinkButton>

      <ScheduleColorCheckRadioDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={<OneLinedTitle title="일정 색상 선택" />}
        control={control}
        name="color"
        color={color}
        representativeColor={representativeColor}
      />
    </>
  );
};

export default SelectColor;

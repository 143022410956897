import styled from '@emotion/styled';
import Divider from 'components/Divider';
import useQueryString from 'hooks/useQueryString';
import { BookingAvailableFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import filters from 'utils/filters';

import SettingByRuleType from './RuleType/SettingByRuleType';

const BookingAvailableSetting = () => {
  const { getValues, setValue } = useFormContext<BookingAvailableFormType>();
  const { ruleType, lectureDate, booking_start_at, booking_end_at, booking_cancel_start_at, booking_cancel_end_at } = getValues();

  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const [expanded, setExpanded] = useState<string | false>(getSearchParams('detail') as string);

  const [tempBookingDate, setTempBookingDate] = useState([booking_start_at, booking_end_at]);
  const [tempBookingCancelDate, setTempBookingCancelDate] = useState([booking_cancel_start_at, booking_cancel_end_at]);

  const changeAccordionOpen = (panel: string) => {
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  const changeBookingAvailable = (e: { value: Date }, type?: 'start' | 'end') => {
    const changedDate = filters.dateDashTimeSecondZero(e.value);
    if (!type) {
      setTempBookingDate([booking_start_at, changedDate]);
    } else {
      const array = type === 'start' ? [changedDate, tempBookingDate[1]] : [tempBookingDate[0], changedDate];
      setTempBookingDate(array);
    }
  };

  const changeBookingCancelAvailable = (e: { value: Date }, type?: 'start' | 'end') => {
    const changedDate = filters.dateDashTimeSecondZero(e.value);
    if (!type) {
      setTempBookingCancelDate([booking_cancel_start_at, changedDate]);
    } else {
      const array = type === 'start' ? [changedDate, tempBookingCancelDate[1]] : [tempBookingCancelDate[0], changedDate];
      setTempBookingCancelDate(array);
    }
  };

  const saveDate = () => {
    setValue('booking_start_at', tempBookingDate[0], { shouldDirty: true });
    setValue('booking_end_at', tempBookingDate[1], { shouldDirty: true });
    setValue('booking_cancel_start_at', tempBookingCancelDate[0], { shouldDirty: true });
    setValue('booking_cancel_end_at', tempBookingCancelDate[1], { shouldDirty: true });
    navigate(-1);
  };

  return (
    <Container>
      <SettingByRuleType
        textType="booking"
        iconName="possible"
        ruleType={ruleType.booking}
        lectureDate={lectureDate}
        startAt={tempBookingDate[0]}
        endAt={tempBookingDate[1]}
        isOpen={expanded === 'booking' || expanded === 'bookingEndAt'}
        target="booking"
        onToggle={panel => changeAccordionOpen(typeof panel === 'string' ? panel : 'booking')}
        changeDate={changeBookingAvailable}
      />
      <Divider />
      <SettingByRuleType
        textType="cancel"
        iconName="roomCancel"
        ruleType={ruleType.bookingCancel}
        lectureDate={lectureDate}
        startAt={tempBookingCancelDate[0]}
        endAt={tempBookingCancelDate[1]}
        isOpen={expanded === 'bookingCancel' || expanded === 'bookingCancelEndAt'}
        target="bookingCancel"
        onToggle={panel => changeAccordionOpen(typeof panel === 'string' ? panel : 'bookingCancel')}
        changeDate={changeBookingCancelAvailable}
      />
      <FooterButton onClick={saveDate}>완료</FooterButton>
    </Container>
  );
};

export default BookingAvailableSetting;

const Container = styled.div`
  padding-bottom: 116px;
`;

import Typography from 'components/Typography';
import {
  PrimaryColorTypography,
  RuleTypeAccordionHeader,
  StyledRuleTypeTimeTypography,
} from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TEXT } from 'pages/Booking/constants';
import { RuleTypeCommonProps } from 'pages/Booking/types';
import { numToTimeColon } from 'pages/Booking/utils/convertTimes';

type Props = Pick<RuleTypeCommonProps, 'isOpen' | 'textType'> & {
  endDays: number;
  endTime: number;
};

const ATypeAccordionHeader = ({ isOpen, textType, endDays, endTime }: Props) => {
  return (
    <RuleTypeAccordionHeader>
      <StyledRuleTypeTimeTypography>
        <PrimaryColorTypography isOpen={isOpen}>{endDays}</PrimaryColorTypography>
        <Typography span size={15}>
          일 전,
        </Typography>
      </StyledRuleTypeTimeTypography>
      <StyledRuleTypeTimeTypography>
        <PrimaryColorTypography isOpen={isOpen}>{numToTimeColon(endTime)}</PrimaryColorTypography>
        <Typography span size={15}>
          {BOOKING_AVAILABLE_TIME_SETTING_TEXT[textType]}
        </Typography>
      </StyledRuleTypeTimeTypography>
    </RuleTypeAccordionHeader>
  );
};

export default ATypeAccordionHeader;

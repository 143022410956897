import usePostHolding, { HoldingPostParams, HoldingPostResponse } from 'hooks/service/mutations/usePostHolding';
import { MutationCallbackType, TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';

import TicketHoldingForm from './TicketHoldingForm';

type Props = UserTicketProps & {
  mutateCallback: MutationCallbackType<HoldingPostResponse, HoldingPostParams>;
};

const HoldingCreate = ({ canUpdateUserTicket, userTicket, mutateCallback }: Props) => {
  const { mutate: holdingMutate } = usePostHolding();

  const submit = (values: TicketHoldingFormType) => {
    const { params } = values;
    holdingMutate({ ...params }, mutateCallback);
  };

  return <TicketHoldingForm canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} onSubmit={submit} />;
};

export default HoldingCreate;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetMessageMobilePoint = () => {
  const { queryFn } = useQueryFnWithCommonParams<StudioPointResponse, Params>({
    url: `/api/message/mobile/point`,
    params: {
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: ['studio', 'message-point'],
    queryFn,
    select: data => data.data.point,
  });
};

export default useGetMessageMobilePoint;

export type StudioPointResponse = {
  point: number;
};

type Params = {
  staff_id: undefined;
};

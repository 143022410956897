import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 1:1 문의 답글
 * @param id 문의 게시글 id
 */
const usePostBoardQna = (questionId: number) => {
  const queryClient = useQueryClient();
  const { mutationFn } = useMutationFnWithCommonParams<BoardQnaParams>({
    method: `post`,
    url: `/api/board/qna/${questionId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`board/qna`, true, questionId] });
    },
  });
};

export default usePostBoardQna;

type BoardQnaParams = {
  contents: string;
};

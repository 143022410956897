import { AccordionProps } from 'components/Accordion';
import AvailableTimePicker, { AvailableTimeType } from 'designedComponents/Pickers/NumberPicker/AvailableTimePicker';
import RuleTypeAccordion from 'pages/Booking/components/AvailableTime/RuleType/RuleTypeAccordion';
import { RuleTypeContainer } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TITLE } from 'pages/Booking/constants';
import { SmallTitleTypography } from 'pages/Booking/Group/Form/components/Operation/Create/OthersAvailableSetting';
import { RuleTypeCommonProps } from 'pages/Booking/types';
import { splitHourMinTypeR } from 'pages/Booking/utils/convertTimes';

import RTypeAccordionHeader from './header/RTypeAccordionHeader';

type Props = Omit<RuleTypeCommonProps, 'textType'> & {
  textType: RuleTypeCommonProps['textType'] | 'dailyChange' | 'waiting' | 'autoClose';
  accordionHeader?: AccordionProps['header'];
  deadline: number;
  changeDate: (value: AvailableTimeType) => void;
};
const noBottomBorderList = ['booking', 'dailyChange', 'autoClose'];
const customHeightList = ['dailyChange', 'waiting', 'autoClose'];
/**
 * 수업시간 기준 - 마감시간,
 * 당일예약 변경 가능 시간, 폐강시간, 예약대기 가능 시간
 */
const RType = ({ iconName, isOpen, onToggle, textType, deadline, accordionHeader, changeDate }: Props) => {
  const { hour, min } = splitHourMinTypeR(deadline);

  return (
    <RuleTypeContainer className={`rule-type R ${textType}`}>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={isOpen}
        onChange={() => onToggle(!isOpen)}
        isLast
        noBottomBorder={noBottomBorderList.includes(textType)}
        height={customHeightList.includes(textType) ? 100 : 56}
        header={
          accordionHeader ? (
            accordionHeader
          ) : (
            <div>
              <SmallTitleTypography>{BOOKING_AVAILABLE_TIME_SETTING_TITLE[textType]}</SmallTitleTypography>
              <RTypeAccordionHeader isOpen={isOpen} hour={hour} min={min} textType={textType} />
            </div>
          )
        }>
        <AvailableTimePicker deadline={{ hour, min }} onChangeDeadline={changeDate} />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default RType;

import { InfoUpdateFormType } from 'pages/MoreDetails/Staff/Form/types';
import { useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { dialogAtom } from 'recoil/dialog';
import getColor, { Color } from 'utils/getColor';

import useGetStaffColor from './service/queries/useGetStaffColor';

const useRepresentativeColor = () => {
  const [count, setCount] = useState<number | undefined>(0);
  const setIsOpen = useSetRecoilState(dialogAtom);

  const navigate = useNavigate();
  const saveColorRef = useRef<Color>('red0');

  const { control, setValue } = useFormContext<InfoUpdateFormType>();
  const representativeColor = useWatch({ control, name: 'profile.representative_color' });

  const { data: usedColors } = useGetStaffColor();
  const originColors = useMemo(() => usedColors.map(({ color }) => color), [usedColors]);

  const changeValue = (value: Color) => {
    const includedColor = usedColors.find(({ color }) => getColor(color) === value);
    saveColorRef.current = representativeColor;

    setValue('profile.representative_color', value, { shouldDirty: true });
    if (includedColor) {
      setCount(includedColor.count);
      setIsOpen(true);
    } else {
      navigate(-1);
    }
  };

  const dialogAction = (type: 'confirm' | 'cancel') => {
    const isConfirm = type === 'confirm';
    const currentValue = isConfirm ? representativeColor : saveColorRef.current;
    setValue('profile.representative_color', currentValue, { shouldDirty: true });
    setIsOpen(false);
    if (isConfirm) {
      navigate(-1);
    }
  };

  return {
    representativeColor,
    changeValue,
    dialogAction,
    count: count || 0,
    colorKeys: originColors.map(color => getColor(color)),
  };
};

export default useRepresentativeColor;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FooterButtonWrapper } from 'sharedComponents/FooterButton';
import filters from 'utils/filters';

import { MESSAGE_RESERVATION_TEXT } from '../constants';
import { MessageType } from '../types';

type Props = {
  children: ReactNode;
};

const MessageFooter = ({ children }: Props) => {
  const { control } = useFormContext<MessageType>();
  const isReserve = useWatch({ control, name: 'isReserve' });
  const reservationDate = useWatch({ control, name: 'reservationDate' });

  return (
    <FooterContainer isReserve={isReserve}>
      {isReserve && (
        <ReservationText>
          {/* 등록수정폼에서는 목록상세와 다른 라벨 */}
          <Label
            size="regular"
            fontSize={13}
            textColor="secondary1"
            color="secondary1"
            opacity={0.12}
            minWidth={44}
            height={24}
            borderRadius={4}>
            {MESSAGE_RESERVATION_TEXT.reservation}
          </Label>
          {reservationDate && (
            <Typography size={15} weight={600}>
              {filters.dateTimeWithWeekday(reservationDate)}
            </Typography>
          )}
        </ReservationText>
      )}
      <Buttons>{children}</Buttons>
    </FooterContainer>
  );
};

export default MessageFooter;

const FooterContainer = styled(FooterButtonWrapper)<{ isReserve: boolean }>(
  ({ isReserve }) =>
    isReserve &&
    css`
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      padding-top: 15px;
    `,
);

const ReservationText = styled.div`
  ${theme.flex('', 'center', 'start', 12)}
  margin-bottom: 15px;
`;

const Buttons = styled.div`
  ${theme.flex('', 'center', '', 8)}

  button {
    width: 100%;
    :first-of-type {
      width: 115px;
    }
  }
`;

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const useDeleteMessagePush = (id: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/message/push/${id}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default useDeleteMessagePush;

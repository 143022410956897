import styled from '@emotion/styled';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';

import { ScheduleCheckinParamType } from '../../type';

const CheckinDayFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const prevSelectedDays = selectedOption as ScheduleCheckinParamType['day'];

  const clickMultiple = (option: FilterOptionsType, key: string) => {
    const selectedDay = option.value as string;
    const currentDays = !prevSelectedDays ? [] : prevSelectedDays;
    const updateValue = currentDays.includes(selectedDay)
      ? currentDays.filter(day => day !== selectedDay)
      : [...currentDays, selectedDay];

    if (!option.value) {
      changeOptions({ value: undefined, label: '요일 전체' }, 'day');
    } else {
      changeOptions({ value: updateValue.length === 7 ? undefined : updateValue, label: '요일 전체' }, 'day');
    }
  };

  return (
    <Container>
      <ButtonFilter currentFilter={currentFilter} selectedOption={selectedOption} onClick={clickMultiple} />
    </Container>
  );
};

export default CheckinDayFilter;

const Container = styled.div`
  .filter-option-button-wrapper {
    gap: 8px 6px;
  }

  button {
    min-width: 58px;
  }
`;

import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';

import FoldableSettings from './FoldableSettings';

const SkeletonDetail = () => {
  return (
    <Container>
      <StyledSkeleton height={24} marginBottom={8} />
      <StyledSkeleton width={214} height={18} />
      <FoldableSettings setting="-" period="-" target="-" />
      <StyledSkeleton height={20} marginBottom={6} width={214} />
      <StyledSkeleton height={20} marginBottom={6} />
      <StyledSkeleton height={20} marginBottom={6} />
      <StyledSkeleton height={20} marginBottom={6} width={266} />
      <StyledSkeleton height={20} marginBottom={6} />
      <StyledSkeleton height={20} marginBottom={40} width={214} />
      <StyledSkeleton height={335} />
    </Container>
  );
};

export default SkeletonDetail;

const Container = styled.div`
  padding: 16px 20px;
`;

const StyledSkeleton = styled(Skeleton)<{ marginBottom?: number }>`
  border-radius: 3px;
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom}px;
`;

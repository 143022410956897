import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePatchStaffNoticeUpdateIsChecked = (noticeId: number, type: 'checkIn' | 'push') => {
  const queryClient = useQueryClient();
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `patch`,
    url: `/api/staff/notice/${noticeId}/update-is-checked`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      const target = type === 'push' ? 'common' : 'check-in';
      queryClient.invalidateQueries({ queryKey: ['staff/notice', target] });
    },
  });
};

export default usePatchStaffNoticeUpdateIsChecked;

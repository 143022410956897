import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import CheckBox from 'components/CheckBox';
import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import { Controller, useFormContext } from 'react-hook-form';

import { BookingSelectMemberFormType } from '../types';
import UserTicketCard from './UserTicketCard';

type Props = {
  data: UserTicketLectureResponse;
  disabled?: boolean;
  duplicateUserTicketIds?: Array<number>;
};

const UserTicketListItem = ({ data, disabled, duplicateUserTicketIds }: Props) => {
  const { control, getValues } = useFormContext<BookingSelectMemberFormType>();
  const isWaitingType = getValues('type') && getValues('type').includes('waiting');
  const queryClient = useQueryClient();

  const removeQuery = () => {
    queryClient.removeQueries({
      queryKey: ['booking/count'],
    });
  };

  return (
    <Container>
      <Controller
        control={control}
        name="selectedUserTickets"
        render={({ field: { value: selectedUserTickets, onChange } }) => {
          const userTicketId = data.id;
          const checked = selectedUserTickets.some(field => field.id === userTicketId);
          const isDuplicated = !!duplicateUserTicketIds?.includes(data.id) && !data.is_shared;

          return (
            <CheckBox
              id={String(userTicketId)}
              label={
                <UserTicketCard
                  data={data}
                  isDuplicated={isDuplicated}
                  showWaitingLabel={isWaitingType && checked}
                  waitingNumber={selectedUserTickets.findIndex(({ id }) => id === userTicketId) + 1}
                />
              }
              gap={16}
              checked={checked}
              disabled={disabled}
              onChange={e => {
                if (e.target.checked) {
                  onChange([...selectedUserTickets, data]);
                } else {
                  removeQuery();
                  onChange([...selectedUserTickets].filter(selectedTicket => data.id !== selectedTicket.id));
                }
              }}
            />
          );
        }}
      />
    </Container>
  );
};

export default UserTicketListItem;

const Container = styled.li`
  .checkbox {
    flex-direction: row-reverse;
  }
  .input-select-base-input-wrapper {
    flex: 0 0 auto;
  }
  .check-box-label-wrapper {
    flex: 1;
  }
`;

import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import { currentCounselingAtom } from 'recoil/counseling';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';
import MainLayout from 'sharedComponents/MainLayout';
import { mobile, userName } from 'utils/validate';
import { array, date, object, string } from 'yup';

import CounselCreateForm from './components/CounselCreateForm';
import CounselUpdateForm from './components/CounselUpdateForm';
import { CounselingFormType } from './type';

const yupSchema = object().shape({
  date: date().required(),
  time: array().of(date().required()).required(),
  name: userName,
  phone: mobile,
  is_registered: string().oneOf(['registered', 'notRegistered']).required(),
  channel: string().oneOf(['phone', 'chat', 'visit', 'etc']).required(),
  funnel: string().oneOf(['search', 'introduction', 'sign', 'flyer', 'sns', 'etc', 'none']),
  contents: string().required(),
  target_staff_id: string().required(),
});

const CounselForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentStudioId = useRecoilValue(studioIdAtom);
  const currentStaffId = useRecoilValue(staffIdAtom);
  const currentCounseling = useRecoilValue(currentCounselingAtom);
  const resetCounselForm = useResetRecoilState(currentCounselingAtom);

  const [searchParams] = useSearchParams();

  const typeText = useMemo(() => (searchParams.get('mode') === 'update' ? '수정' : '등록'), [searchParams]);
  const { state } = location;

  const defaultValues: CounselingFormType = useMemo(() => {
    /** 상담 수정 */
    if (currentCounseling) {
      const { id, user_id, start_on, end_on, name, phone, channel, funnel, contents, staff_id } = currentCounseling;
      return {
        studio_id: currentStudioId,
        staff_id: currentStaffId,
        id,
        user_id,
        name,
        phone: phone || '',
        channel,
        funnel,
        contents: contents || '',
        is_registered: !user_id ? 'notRegistered' : 'registered',
        date: dayjs(start_on).toDate(),
        time: [dayjs(start_on).toDate(), dayjs(end_on).toDate()],
        target_staff_id: staff_id.toString(),
      };
    }

    /** 상담 등록 */
    const roundedStartTime = dayjs().minute(Math.round(dayjs().minute() / 60) * 60);
    const endTime = roundedStartTime.add(50, 'minutes');
    const selectedDate = state?.selectedDate ? state.selectedDate : dayjs().toDate(); // state.selectedDate가 있을 때와 없을 때를 체크
    return {
      studio_id: currentStudioId,
      staff_id: currentStaffId,
      id: undefined,
      user_id: undefined,
      name: '',
      phone: '',
      channel: state?.from === 'floating' ? 'visit' : 'phone',
      funnel: 'search',
      contents: '',
      is_registered: 'notRegistered',
      date: selectedDate,
      time: [roundedStartTime.toDate(), endTime.toDate()],
      target_staff_id: currentStaffId.toString(),
    };
  }, [currentCounseling, currentStaffId, currentStudioId, state?.from, state?.selectedDate]);

  const methods = useForm<CounselingFormType>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(yupSchema),
    defaultValues,
    shouldFocusError: false,
  });

  const {
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const [isOpen, setIsOpen] = useState(false);

  const goToBack = useCallback(() => {
    if (isDirty) {
      setIsOpen(true);
    }
    navigate(-1);
  }, [isDirty, navigate]);

  useEffect(() => {
    if (searchParams.get('mode') !== 'update') {
      // form 값도 초기화
      methods.reset(defaultValues);
      // recoil 상태 초기화
      resetCounselForm();
    }

    return () => resetCounselForm();
  }, [location.key, methods, resetCounselForm, defaultValues]);

  return (
    <MainLayout
      header={{
        title: `상담 ${typeText}`,
        leftButton: (
          <IconButton onClick={goToBack}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <FormProvider {...methods}>
        {currentCounseling ? (
          <CounselUpdateForm typeText={typeText} currentCounseling={currentCounseling} />
        ) : (
          <CounselCreateForm typeText={typeText} />
        )}
      </FormProvider>
      <DialogBeforeLeave isBlocked={isOpen || (isDirty && !isSubmitSuccessful)} />
    </MainLayout>
  );
};

export default CounselForm;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { StudiomateNoticeType } from 'pages/MoreDetails/Settings/Notice/List/types';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityStudiomateNotice = (search: string) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<StudiomateNoticeResponse, StudiomateNoticeParams>({
    url: `/api/studiomate-notice`,
    params: {
      search,
      paginate_type: 'simple',
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`studiomate-notice`, search],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityStudiomateNotice;

type StudiomateNoticeParams = {
  studio_id: undefined;
  staff_id: undefined;
  search: string;
  paginate_type: 'simple' | 'detail';
};

type StudiomateNoticeResponse = StudiomateNoticeType;

import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import { CommonProfileFormType, ProfileProps } from 'sharedComponents/ProfileField/types';

type Props = ProfileProps & {
  className?: string;
};

const Profile = ({ label = '회원명', onClick, cautionText = true, className }: Props) => {
  const methods = useFormContext<CommonProfileFormType>();
  const profileName = useWatch({ control: methods.control, name: 'profile.name' });

  return (
    <Container className={className || undefined}>
      <FormFieldLinkButton iconName="privateClass" hasValue={!!profileName} onClick={onClick}>
        {`${profileName || `프로필 (사진, ${label})`}`}
      </FormFieldLinkButton>

      {cautionText && (
        <Typography span size={13} weight={500} textColor="secondary3">
          *필수입력
        </Typography>
      )}
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  position: relative;

  .link-button {
    padding-top: 17px;
    padding-bottom: 38px;
  }

  > .typography {
    position: absolute;
    left: 54px;
    bottom: 17px;
  }
`;

import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { StaffParams, StaffResponse } from './useInfinityStaff';

/** 모든 강사목록 가져오기 */
const useGetAllStaff = (params: Omit<StaffParams, 'paginate_type'>, enabled = true) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllStaffResponse, AllStaffParams>({
    url: `/api/staff`,
    params: { ...params, limit: 200, paginate_type: 'detail' },
  });

  return useQuery({
    queryKey: [`staff`, 'all', { ...params }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllStaff;

type AllStaffParams = StaffParams & { limit: number };
type AllStaffResponse = StaffResponse[];

import Typography from 'components/Typography';

type Props = {
  title: string;
};

const IntervalSubTitle = ({ title }: Props) => {
  return (
    <Typography className="sub-title" weight={600} textColor="gray2" opacity={0.88}>
      {title}
    </Typography>
  );
};

export default IntervalSubTitle;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { isEqual } from 'lodash';
import { HTMLAttributes, memo } from 'react';

import StudioIcon from './StudioIcon';

type Props = HTMLAttributes<HTMLButtonElement> & {
  studio: {
    id: number;
    name: string;
  };
  isShowIcon: boolean;
  rightIconName?: 'arrowRight' | 'deleteFill';
};

const StudioItem = ({ studio, isShowIcon, rightIconName = 'arrowRight', ...props }: Props) => {
  return (
    <Container {...props}>
      <StudioIcon />
      <Name size={15} weight={500}>
        {studio.name}
      </Name>
      {isShowIcon && <Icon name={rightIconName} size={24} fillColor={theme.color.gray3} />}
    </Container>
  );
};

const StudioListItem = memo(StudioItem, (prev, next) => {
  return isEqual(prev.studio, next.studio) && prev.rightIconName === next.rightIconName && prev.isShowIcon === next.isShowIcon;
});

export default StudioListItem;

const Container = styled.button`
  ${theme.flex('row', 'center', 'space-between', 16)}
  width: 100%;
  padding: 8px 20px;
  & > svg {
    flex: 0 0 auto;
  }
`;

const Name = styled(Typography)`
  flex-grow: 1;
  text-align: left;
`;

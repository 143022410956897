import ReactDom from 'react-dom';

interface Props {
  children: React.ReactNode;
}

const DialogPortal = ({ children }: Props) => {
  const element = document.getElementById('dialog-root') as HTMLElement;

  return element && children ? ReactDom.createPortal(children, element) : null;
};

export default DialogPortal;

import Typography from 'components/Typography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const MoreInfoDrawer = ({ isOpen, onClose }: Props) => {
  return (
    <SingleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      header={<OneLinedTitle title="계정 통합" />}
      button={{ text: '확인', onClick: onClose }}>
      <section>
        <Typography size={15}>
          스튜디오메이트의 다양한 서비스를 <b>하나의 아이디</b>로 편리하게 이용할 수 있도록 여러개의 아이디를 하나로 통합합니다.
        </Typography>
      </section>
    </SingleButtonDrawer>
  );
};

export default MoreInfoDrawer;

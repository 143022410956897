import dayjs from 'dayjs';
import { StaffEventSchedulesResponse } from 'hooks/service/queries/useGetStaffEventSchedules';
import { CLOSED_DAY_TIME } from 'sharedComponents/ProfileField/components/WorkTime/constants';

import filters from './filters';

/** 기존과 완전히 다른 구조로 가공하며, 특정일 근무시간 수정 페이지에서 사용됨 */
const getEventTimeDatasetNew = ({ event_work_time, event_rest_time }: StaffEventSchedulesResponse) => {
  /** 휴일 가공 */
  const closeTimes = event_rest_time.filter(restTime => {
    return restTime.start_time === CLOSED_DAY_TIME.start && restTime.end_time === CLOSED_DAY_TIME.end;
  });

  /** 휴무일을 판단할 수 있는 부분이 따로 없고, start_time, end_time 고정값으로 판단해야함 */
  const resultWorkTimes = event_work_time.map(({ date, day_of_week, end_time, start_time, id, type }) => {
    const filteredRestTimes = event_rest_time.filter(restTime => restTime.date === date);
    const restTimes = filteredRestTimes.map(({ created_at, updated_at, ...rest }) => {
      return rest;
    });

    const today = filters.dateDash();
    restTimes.sort((a, b) => {
      return dayjs(`${today} ${a.start_time}`).diff(dayjs(`${today} ${b.start_time}`));
    });

    return {
      date,
      day_of_week,
      end_time,
      start_time,
      id,
      type,
      restTimes,
    };
  });

  return [...resultWorkTimes, ...closeTimes];
};

export default getEventTimeDatasetNew;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { TICKET_HISTORY_TEXT } from 'pages/TicketDetail/constants';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { FormProvider, useForm } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { CardWrapper } from 'sharedComponents/InfoTypography';
import MainLayout from 'sharedComponents/MainLayout';
import { SortFormType } from 'sharedComponents/MemoForm/types';

import Filter from './Filter';
import HistoryList from './HistoryList';
import Sorting from './Sorting';

const History = ({ userTicket }: UserTicketProps) => {
  const methods = useForm<SortFormType>({
    defaultValues: {
      sort: 'asc',
    },
  });

  return (
    <MainLayout header={{ title: TICKET_HISTORY_TEXT.title }}>
      <FormProvider {...methods}>
        <Container>
          <Filter />
          <Sorting />

          <ListWrapper>
            <ApiBoundary>
              <HistoryList userTicket={userTicket} />
            </ApiBoundary>
          </ListWrapper>
        </Container>
      </FormProvider>
    </MainLayout>
  );
};

export default History;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  height: 100%;

  .sort-button {
    padding: 12px 20px;
  }

  .infinite-scroll {
    flex: 1;
  }
`;

const ListWrapper = styled(CardWrapper)`
  padding: 20px;
  flex: 1;
  background-color: ${theme.color.gray6};
`;

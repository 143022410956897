import useGetHoldingCount from 'hooks/service/queries/useGetHoldingCount';
import { TicketHoldingFormType } from 'pages/TicketDetail/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import CheckBookingDrawer from './CheckBookingDrawer';

type Props = {
  onSubmit: (values: TicketHoldingFormType) => void;
};

/** 정지 전 해당 수강권에 예약/예약대기 수업 내역이 있는지 확인 */
const CheckBookingBeforeHolding = ({ onSubmit }: Props) => {
  const { watch, setValue } = useFormContext<TicketHoldingFormType>();
  const {
    params: { auto_calculation, ...rest },
    hasBooking,
  } = watch();
  const { data } = useGetHoldingCount({ ...rest });

  useEffect(() => {
    setValue('bookingCount', data?.count);
  }, [data, setValue]);

  return <CheckBookingDrawer isOpen={hasBooking} count={data?.count} onSubmit={onSubmit} />;
};

export default CheckBookingBeforeHolding;

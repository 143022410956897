import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { QnaListType } from 'pages/MoreDetails/Qna/List/types';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityBoardQna = (search?: string, sort?: BoardQnaParams['sort_type']) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<BoardQnaResponse, BoardQnaParams>({
    url: `/api/board/qna`,
    params: {
      search,
      sort_type: sort ?? 'desc',
      paginate_type: 'simple',
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`board/qna`, { search, sort }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityBoardQna;

type BoardQnaParams = {
  search?: string;
  sort_type: 'asc' | 'desc';
  paginate_type: 'simple' | 'detail';
};

export type BoardQnaResponse = QnaListType;

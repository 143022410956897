import { useIsFetching } from '@tanstack/react-query';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import BookingMemoMain from './BookingMemoMain';

const BookingMemo = () => {
  const isFetching = useIsFetching() > 0;
  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <BookingMemoMain />
    </ApiBoundary>
  );
};

export default BookingMemo;

import dayjs from 'dayjs';
import { MOBILE_EMOJI_ERROR_TEXT, TITLE_EMOJI_ALLOWED_REGEX } from 'pages/MoreDetails/Message/Mobile/Form/constants';
import { MessageType } from 'pages/MoreDetails/Message/types';
import { useNavigate } from 'react-router-dom';
import { hasEmojiCharacters } from 'utils/emojiCheck';
import { safeDayjs } from 'utils/safeDayjs';
import { recordErrorWithSentry } from 'utils/sentry';

import useErrorDialog from './useErrorDialog';
import useToast from './useToast';

const useMessage = () => {
  const { setToast } = useToast();

  // 이벤트 당시 시각에 예약 설정이 가능한지 확인
  const checkReservationTime = (reservationDate: string) => {
    const limitTime = dayjs().add(10, 'minutes').second(0).millisecond(0);
    const isValidReservation = safeDayjs(reservationDate).isSameOrAfter(limitTime);

    if (!isValidReservation) {
      setToast({ type: 'error', message: '예약 시간을 확인해 주세요.', bottom: 136 });
    }
    return isValidReservation;
  };

  const errorDialog = useErrorDialog();
  const navigate = useNavigate();

  // 문자 메시지 발송 직전 검증
  const validateSmsForm = ({
    title,
    message,
    isReserve,
    reservationDate,
  }: Pick<MessageType, 'title' | 'message' | 'isReserve' | 'reservationDate'>): boolean => {
    try {
      if (!TITLE_EMOJI_ALLOWED_REGEX.test(title)) {
        errorDialog.open(MOBILE_EMOJI_ERROR_TEXT.title);
        return false;
      }

      if (hasEmojiCharacters(message)) {
        errorDialog.open(MOBILE_EMOJI_ERROR_TEXT.content);
        return false;
      }

      if (isReserve && !checkReservationTime(reservationDate)) {
        return false;
      }
    } catch (e) {
      recordErrorWithSentry(e);
      navigate('/error');
    }
    return true;
  };

  return { checkReservationTime, validateSmsForm };
};

export default useMessage;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

type Props = {
  search?: string;
  sort_type?: 'asc' | 'desc';
  sort_target?: 'name' | 'registered_at';
};

const useInfinityStaff = ({ search, sort_target = 'registered_at', sort_type = 'desc' }: Props = {}) => {
  const params = {
    search,
    sort_target,
    sort_type,
  };

  const { queryFn } = useInfiniteQueryFnWithCommonParams<StaffResponse, StaffParams>({
    url: `/api/staff`,
    params: {
      ...params,
      paginate_type: 'detail',
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['staffs', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        staffs: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
      };
    },
  });
};

export default useInfinityStaff;

export type StaffResponse = {
  account_id: number | null;
  avatar: Avatar | null;
  color: string;
  id: number;
  mobile: string | null;
  name: string;
  role: string;
  studio_id: number;
};

export type StaffParams = Props & {
  paginate_type: 'detail';
};

type Avatar = {
  extension: string;
  name: string;
  path: string;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import CheckRadioFilterContents from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioFilterContents';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CALENDAR_SETTING_TEXT, CALENDER_SETTING_MENUS } from '../../constants';
import { CalendarSettingsFormType } from '../../types';
import SettingInfoTooltip from '../SettingInfoTooltip';

const DefaultViewType = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { control } = useFormContext<CalendarSettingsFormType>();

  const currentDefaultViewType = useWatch({ control, name: 'defaultViewType' });

  const openDrawer = () => {
    if (isTooltipOpen) setIsTooltipOpen(false);
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => setIsDrawerOpen(false);

  const labelText = useMemo(() => {
    return CALENDER_SETTING_MENUS.defaultViewType.find(item => item.value === currentDefaultViewType)?.label;
  }, [currentDefaultViewType]);

  return (
    <>
      <Container>
        <div className="tooltip-wrapper">
          <Typography size={15} weight={500} textColor="gray2">
            {CALENDAR_SETTING_TEXT.defaultViewType.title}
          </Typography>

          <SettingInfoTooltip
            isOpen={isTooltipOpen}
            setIsOpen={setIsTooltipOpen}
            message={CALENDAR_SETTING_TEXT.defaultViewType.tooltipMessage}
          />
        </div>

        <IconButton onClick={openDrawer}>
          <Typography className="view-type-label" size={15} weight={600} textColor="gray1">
            {labelText}
          </Typography>
          <Icon name="arrowRightBold" fillColor={theme.color.gray1} size={16} />
        </IconButton>
      </Container>

      <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
        <CheckRadioFilterContents
          control={control}
          name="defaultViewType"
          data={CALENDER_SETTING_MENUS.defaultViewType}
          onClose={closeDrawer}
        />
      </Drawer>
    </>
  );
};

export default DefaultViewType;

const Container = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 0 16px;
  height: 60px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 3px 10px 0px rgba(145, 145, 145, 0.12);

  .tooltip-wrapper {
    ${theme.flex('row', 'center', '', 2)}
  }

  .view-type-label {
    margin-right: 4px;
  }

  .tooltip-contents-wrapper {
    width: 222px !important;
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { MemberDetailResponse } from 'hooks/service/queries/useGetMemberDetail';
import usePopup from 'hooks/usePopup';
import ProfileImgExtension from 'sharedComponents/ProfileField/components/ProfileImgExtension';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import AppLinkMessage from './AppLinkMessage';

type Props = {
  member: MemberDetailResponse;
  onClickCopyEmail: (text: string) => void;
};

const MainTopInfo = ({ member, onClickCopyEmail }: Props) => {
  const { setPopup } = usePopup();
  const {
    name,
    vaccination_yn,
    mobile,
    account,
    account_id,
    profile: { registered_at, last_attendance_at },
    avatar,
  } = member;

  const clickProfileImage = () => {
    setPopup(<ProfileImgExtension image={avatar ? avatar.image : null} />);
  };

  return (
    <Container className="main-info">
      <div className="avatar-wrapper" onClick={clickProfileImage}>
        <Avatar size={96} imgUrl={avatar ? getImageUrl(avatar.image) : undefined} />
      </div>

      <FlexRow>
        <NameTypography size={21} weight={700}>
          {name}
        </NameTypography>
        {vaccination_yn === 'Y' && <Icon name="vaccinateBold" size={16} fillColor={theme.color.secondary1} />}
      </FlexRow>

      <FlexRow>
        <Typography size={14} textColor="gray2">
          {mobile ? `${filters.mobile(mobile)}` : '-'}
        </Typography>
        {!account_id && <Icon name="connectOff" size={16} fillColor={theme.color.gray3} />}
      </FlexRow>

      {!account_id && mobile && (
        <Button
          size="tiny"
          variant="outlined"
          edge="circular"
          textColor="gray2"
          padding={{ left: 8, right: 8 }}
          onClick={() => setPopup(<AppLinkMessage member={member} />)}>
          앱 링크 문자보내기
        </Button>
      )}

      {account?.email && (
        <Button
          className="email-button"
          textUnderline
          textColor="gray3"
          fontWeight={400}
          onClick={() => onClickCopyEmail(account?.email)}>
          {account?.email}
        </Button>
      )}

      <DateWrapper>
        <div>
          <Typography size={13} textColor="gray2">
            등록일
          </Typography>
          <Typography size={13} weight={500} textColor="gray2">
            {filters.date(registered_at)}
          </Typography>
        </div>
        <div>
          <Typography size={13} textColor="gray2">
            최근 출석일
          </Typography>
          <Typography size={13} weight={500} textColor="gray2">
            {last_attendance_at ? filters.date(last_attendance_at) : '-'}
          </Typography>
        </div>
      </DateWrapper>
    </Container>
  );
};

export default MainTopInfo;

const Container = styled.div`
  padding: 0 20px 20px;

  .avatar-wrapper {
    width: 96px;
    margin: 0 auto;
  }

  .avatar {
    margin: 0 auto 16px;
  }

  .text-button {
    margin: 6px auto 0;
  }

  .email-button {
    word-break: break-all;
    max-width: 150px;
  }
`;

const NameTypography = styled(Typography)`
  max-width: 182px;
  text-align: center;
`;

const FlexRow = styled.div`
  ${theme.flex('', 'center', 'center', 2)};
  margin-bottom: 2px;
`;

const DateWrapper = styled(FlexRow)`
  margin-top: 24px;
  background-color: ${theme.color.gray7};
  border-radius: 8px;

  > div {
    flex: 1;
    text-align: center;
    padding: 15px;

    &:first-of-type {
      position: relative;
      :after {
        content: '';
        width: 1px;
        height: 28px;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -14px;
        background-color: ${theme.color.gray5};
      }
    }

    .typography:first-of-type {
      margin-bottom: 2px;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useInfinityMemberCounsel, { MemberCounselParams } from 'hooks/service/queries/useInfinityMemberCounsel';
import useScrollRestoration from 'hooks/useScrollRestoration';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { counselSelectedFiltersAtom } from 'recoil/Member';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import CheckMember from './CheckMember';
import CounselCard from './CounselCard';
import NoData from './NoData';
import Total from './Total';

const CounselList = () => {
  useScrollRestoration();
  const navigate = useNavigate();
  const [counselParams] = useRecoilState(counselSelectedFiltersAtom);
  const { searchKeyword } = useSearchKeywords('member');
  const { period, staffs, ...rest } = counselParams;

  const params: MemberCounselParams = {
    ...rest,
    start_on: period?.start,
    end_on: period?.end,
    search: searchKeyword,
    target_staff_id: staffs?.id === 'all' ? null : staffs?.id,
    paginate_type: 'detail',
  };

  const {
    data: { counsels, total },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isRefetching,
    refetch,
  } = useInfinityMemberCounsel(params);

  const goToCounselDetail = (counselId: number) => {
    navigate(`/counsel/detail/${counselId}?prevUrl=member`);
  };

  return (
    <PullToRefresh isRefetching={isRefetching} onRefresh={refetch}>
      <TotalWrapper>
        <Total total={total} />
        <CheckMember />
      </TotalWrapper>
      {total === 0 ? (
        <NoData currentTabLabel="상담고객" currentKeyword={searchKeyword} />
      ) : (
        <InfiniteScroll gap={12} hasMore={hasNextPage} isLoading={isFetchingNextPage} loadMore={fetchNextPage}>
          {counsels.map(counsel => (
            <CounselCard key={counsel.id} customer={counsel} onClick={() => goToCounselDetail(counsel.id)} />
          ))}
        </InfiniteScroll>
      )}
    </PullToRefresh>
  );
};

export default CounselList;

const TotalWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  margin-bottom: 16px;
  padding-top: 24px;
`;

import styled from '@emotion/styled';
import Divider from 'components/Divider';
import { get } from 'lodash';
import { Fragment } from 'react/jsx-runtime';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MobilePeriodFilter from './MobilePeriodFilter';

const MobileWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  const buttonFilters = filters.filter(filter => filter.type === 'button');
  return (
    <Container>
      <MobilePeriodFilter currentFilter={filters[0]} selectedOption={get(filterChanged, 'period')} onClick={changeOption} />
      <Divider />
      {buttonFilters.map((filter, index) => (
        <Fragment key={filter.title}>
          <ButtonFilter currentFilter={filter} selectedOption={get(filterChanged, filter.key)} onClick={changeOption} />
          {index !== buttonFilters.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Container>
  );
};

export default MobileWholeFilterOptions;

const Container = styled.div`
  > div:first-of-type {
    padding-top: 16px;
  }
`;

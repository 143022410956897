import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { Z_INDEX } from 'constants/zIndex';
import { useRecoilValue } from 'recoil';
import { popupAtom } from 'recoil/popup';

const Popup = () => {
  const popupContents = useRecoilValue(popupAtom);

  return <>{popupContents && <Container id="popup">{popupContents}</Container>}</>;
};

export default Popup;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.popup};
  width: 100%;
  height: 100%;
  background-color: ${theme.color.white};
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { get } from 'lodash';
import PeriodPicker from 'sharedComponents/PeriodPicker';

import ButtonFilterBase from './ButtonFilterBase';
import FilterTitle from './FilterTitle';
import { FilterWrap } from './StyledComponents';
import { PeriodFilterProps } from './types';

const PeriodFilter = ({
  title = '기간선택',
  description,
  currentFilter,
  selectedOption,
  onClick,
  initialDate,
  onSave,
  className,
  ...props
}: PeriodFilterProps) => {
  const periodType = get(selectedOption, 'type') ?? selectedOption;

  return (
    <Container className={`period-filter ${className ?? ''}`}>
      <FilterTitle title={title} />
      <ButtonFilterBase currentFilter={currentFilter} selectedOption={selectedOption} onClick={onClick} />

      <PeriodSelectWrapper>
        <PeriodPicker
          {...props}
          initialDate={initialDate}
          startDateDisabled={periodType !== 'custom'}
          endDateDisabled={periodType !== 'custom'}
          onSave={onSave}
        />

        <Typography size={13} textColor="gray2">
          {description}
        </Typography>
      </PeriodSelectWrapper>
    </Container>
  );
};

export default PeriodFilter;

const Container = styled(FilterWrap)`
  .button-group {
    margin-top: 12px;
    gap: 6px;

    button {
      min-width: 58px;
    }
  }
`;

const PeriodSelectWrapper = styled.div`
  margin-top: 12px;

  > div {
    ${theme.flex('', 'center', 'space-between', 9)};
    margin-bottom: 8px;
  }
`;

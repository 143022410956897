import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import { STUDIO_DETAIL_TEXTS } from 'pages/MoreDetails/Studio/constants';

const StudioDetailSkeleton = () => {
  return (
    <>
      <Container>
        <Typography size={15} textColor="gray2" opacity={0.8}>
          {STUDIO_DETAIL_TEXTS.contact}
        </Typography>
        <Skeleton width={90} height={22} />
        <Typography size={15} textColor="gray2" opacity={0.8}>
          {STUDIO_DETAIL_TEXTS.openHours}
        </Typography>
        <Row>
          <Skeleton width={130} height={22} />
          <Skeleton width={130} height={22} />
          <Skeleton width={130} height={22} />
          <Skeleton width={130} height={22} />
          <Skeleton width={130} height={22} />
          <Skeleton width={130} height={22} />
          <Skeleton width={130} height={22} />
        </Row>
        <Typography size={15} textColor="gray2" opacity={0.8}>
          {STUDIO_DETAIL_TEXTS.address}
        </Typography>
        <Skeleton height={22} />
      </Container>
      <BottomButton size="large56" variant="contained" color="point" fontSize={15} fontWeight={500} fullWidth>
        {STUDIO_DETAIL_TEXTS.call}
      </BottomButton>
    </>
  );
};

export default StudioDetailSkeleton;

const Container = styled.div`
  width: 100%;
  padding: 12px 30px 32px 0;
  display: grid;
  grid-template-columns: 52px 1fr;
  row-gap: 16px;
  column-gap: 24px;
`;

const Row = styled.div`
  ${theme.flex('column', '', '', 4)}
`;
const BottomButton = styled(Button)`
  margin: 10px 0;
`;

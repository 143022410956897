import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Typography from 'components/Typography';
import React, { useMemo, useState } from 'react';

import { ITextFieldOptions } from '../types';
import { OutlinedInputWrapper, OutlinedStyledInput } from './OutlinedTextField';
import Suffix from './Suffix';

export type UnderlinedOptions = {
  textColor?: keyof typeof color;
  /**
   * input 내부 왼쪽 요소
   * prefix: 아이콘 or 체크박스 / text: 왼쪽 고정 문구
   */
  left?: {
    prefix: React.ReactNode;
    text?: string;
  };
  /**
   * input 하단 설명 문구
   * text: 줄바꿈은 배열 인덱스로 구분
   */
  description?: { type?: 'normal' | 'error'; text: string[] };
};

type UnderlinedPropTypes = UnderlinedOptions & Omit<ITextFieldOptions, 'label' | 'infoMessage' | 'errorMessage' | 'height'>;

const UnderlinedTextField = React.forwardRef<HTMLInputElement, UnderlinedPropTypes>(
  ({ id, type = 'text', suffix, suffixMarginRight, description, onClear, ...props }, ref) => {
    const { disabled, value, readOnly, left } = props;

    const [focus, setFocus] = useState(false);

    const suffixProps = { disabled, value, readOnly, suffix, suffixMarginRight, onClear, focus };

    const currentDescriptionText = useMemo(() => {
      return description?.text.map((item, idx) => (
        <React.Fragment key={idx}>
          {item}
          <br />
        </React.Fragment>
      ));
    }, [description]);

    return (
      <Container className="underlined-text-field">
        <UnderlinedInputWrapper readOnly={readOnly} disabled={disabled}>
          {left && (
            <section className="prefix-wrapper">
              {left.prefix}
              {left.text && (
                <Typography size={15} weight={400} span>
                  {left.text}
                </Typography>
              )}
            </section>
          )}

          <UnderlinedStyledInput
            id={id}
            type={type}
            ref={ref}
            onFocus={() => setFocus(true)}
            onBlurCapture={() => setFocus(false)}
            {...props}
          />
          <div />
          <Suffix {...suffixProps} />
        </UnderlinedInputWrapper>

        {description && (
          <section className="description-wrapper">
            <Typography size={13} textColor={description?.type === 'error' ? 'error' : 'gray2'}>
              {currentDescriptionText}
            </Typography>
          </section>
        )}
      </Container>
    );
  },
);

export default UnderlinedTextField;
UnderlinedTextField.displayName = 'TextField';

const Container = styled.div`
  border-bottom: 1px solid ${theme.color.gray8};

  .description-wrapper > .typography {
    position: relative;
    margin: -10px 0 16px;
    padding-left: 54px;
  }
`;

const UnderlinedInputWrapper = styled(OutlinedInputWrapper)<{ readOnly?: boolean; disabled?: boolean }>(
  css`
    .prefix-wrapper {
      ${theme.flex('', 'center', '', 10)}
      padding: 0 0 0 20px;
      z-index: 1;

      span {
        white-space: nowrap;
      }
    }
  `,

  ({ readOnly }) =>
    readOnly &&
    css`
      .prefix-wrapper {
        .typography {
          color: ${theme.color.gray3};
        }

        svg {
          fill: ${theme.color.gray3} !important;
        }
      }
    `,

  ({ disabled }) =>
    disabled &&
    css`
      .prefix-wrapper {
        .typography {
          color: ${theme.color.gray4};
        }

        svg {
          fill: ${theme.color.gray4} !important;
        }
      }
    `,
);

const UnderlinedStyledInput = styled(OutlinedStyledInput)<Pick<UnderlinedPropTypes, 'disabled' | 'textColor'>>(
  css`
    height: 56px;
    padding-left: 10px;

    & + div {
      position: unset;
      width: max-content;
      border: 0;
      border-radius: 0;
      pointer-events: unset;
    }

    &::placeholder {
      color: ${theme.color.gray3};
    }
  `,

  ({ textColor }) =>
    textColor &&
    css`
      color: ${theme.color[textColor]};
    `,

  ({ disabled }) =>
    disabled &&
    css`
      &:disabled {
        color: ${theme.color.gray4};

        & + div {
          position: absolute;
          width: 100%;
          pointer-events: none;
          background-color: ${theme.color.gray8};
        }
      }
    `,
);

import { PERMISSION } from 'constants/permission';
import useGetUserTicket from 'hooks/service/queries/useGetUserTicket';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { CAN_VIEW_TICKET_DETAIL } from 'pages/TicketDetail/constants';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import History from '../History';
import PaymentDetail from '../PaymentDetail';
import TicketEdit from '../TicketEdit';
import TicketHolding from '../TicketHolding';
import TicketInactive from '../TicketInactive';
import TicketMemo from '../TicketMemo';
import TicketRefund from '../TicketRefund';
import TicketTransfer from '../TicketTransfer';
import TicketUpgrade from '../TicketUpgrade';

type Props = {
  currentTicketId: number;
  currentMemberId: number;
};

const DetailPages = ({ currentTicketId, currentMemberId }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);

  const { hasPermission } = usePermission();
  const canViewMembers = hasPermission(PERMISSION.member.view);
  const canUpdateUserTicket = hasPermission(MEMBER_PERMISSION.memberTicketEdit);
  const { data: userTicket } = useGetUserTicket({
    ticket_id: currentTicketId,
    user_id: currentMemberId,
  });

  const navigate = useNavigate();
  const { getSearchParams, getAllSearchParams, setSearchParams } = useQueryString();
  const type = getSearchParams('type') as string;
  const step = Number(getSearchParams('step'));

  const clickNextPage = () => {
    const next = step + 1;
    setSearchParams({
      ...getAllSearchParams(),
      step: `${next}`,
    });
  };

  useLayoutEffect(() => {
    if (CAN_VIEW_TICKET_DETAIL.includes(type)) return;
    if (!canUpdateUserTicket) {
      navigate('/error?type=403', { replace: true });
    }
  }, [canUpdateUserTicket, navigate, type]);

  switch (type) {
    case 'payment':
      return <PaymentDetail canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} />;
    case 'holding':
      return <TicketHolding canUpdateUserTicket={canUpdateUserTicket} userTicket={userTicket} />;
    case 'history':
      return <History userTicket={userTicket} />;
    case 'memo':
      return (
        <ApiBoundary>
          <TicketMemo productId={userTicket.id} />
        </ApiBoundary>
      );
    case 'edit':
      return <TicketEdit userTicket={userTicket} currentStaffId={currentStaffId} />;
    case 'upgrade':
      return <TicketUpgrade userTicket={userTicket} step={step} onClickNextPage={clickNextPage} />;
    case 'transfer':
      return (
        <TicketTransfer
          canViewMembers={canViewMembers}
          userTicket={userTicket}
          currentMemberId={currentMemberId}
          step={step}
          onClickNextPage={clickNextPage}
        />
      );
    case 'refund':
      return <TicketRefund userTicket={userTicket} />;
    case 'not_usable':
      return <TicketInactive userTicket={userTicket} />;
    default:
      return null;
  }
};

export default DetailPages;

import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureValidationError } from './usePatchLecturePrivate';

const usePostBooking = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingParams>({
    method: `post`,
    url: `/api/booking`,
  });

  return useCustomMutation<AxiosResponse, AxiosError<BookingError>, BookingParams | undefined, unknown>({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lecture', 'detail'],
      });
    },
  });
};

export default usePostBooking;

export type BookingParams = {
  bookings: Array<{ status: string; user_ticket_id: number }>;
  changes: Array<{ status: string; booking_id: number }>; // 예약 변경할 상태와 예약 아이디
  cancel_bookings: Array<{ status: string; booking_id: number }>; // 취소할 상태와 예약 아이디
  is_pass_limit?: boolean; // 주/월간 제한 걸려도 예약하려면 true
  is_booking_over?: boolean; // 인원초과 검증 걸리고 초과예약 하려면 true
  is_waiting_over?: boolean; // 대기 인원초과 검증 걸리고 초과대기예약 하려면 true
  lecture_id: number;
};

export type BookingSpecificErrorType = { code: string; message: string; name: string };

export type BookingError = LectureValidationError & {
  booked: Array<BookingSpecificErrorType>; // 이미 다른데서 예약되었을 때 반환
  cancelled: Array<BookingSpecificErrorType>; // 이미 다른데서 예약 취소되었을 때 반환
  waited: Array<BookingSpecificErrorType>; // 이미 다른데서 예약 대기 취소되었을 때 반환
  waiting_cancelled: Array<BookingSpecificErrorType>; // 이미 다른데서 예약 대기 취소되었을 때 반환
  not_changeable: Array<BookingSpecificErrorType>; // 예약 상태를 변경할 수 없을 때 반환 (ex/ 예약대기 상태에서 예약으로 바꾸려는데 횟수가 0회 남은 경우 등)
  code?: number;
  message?: string;
};

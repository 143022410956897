import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';
import FormFieldDisabled from 'sharedComponents/FormField/FormFieldDisabled';
import filters from 'utils/filters';

const BookingStartDate = () => {
  const { getValues } = useFormContext<BookingCommonFormType>();

  return <FormFieldDisabled iconName="dateStart">{filters.dateWithWeekday(getValues('startDate'))}</FormFieldDisabled>;
};

export default BookingStartDate;

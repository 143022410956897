import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { useEffect, useRef } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

type Props<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
};

const AutoResizeTextarea = <T extends FieldValues>({ controllerProps }: Props<T>) => {
  const { field } = useController(controllerProps); // React Hook Form의 필드 제어
  const maxHeight = 92; // 텍스트 영역의 최대 높이 설정
  const textareaRef = useRef<HTMLTextAreaElement>(null); // 텍스트 영역에 대한 참조 생성

  // 텍스트 영역의 높이를 자동으로 조정하는 함수
  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '48px'; // 기본 높이를 48px로 설정
      const scrollHeight = textarea.scrollHeight; // 스크롤 높이 계산
      textarea.style.height = `${Math.min(scrollHeight, 92)}px`; // 최대 92px까지 높이 조정
    }
  };

  // 값이 변경될 때마다 텍스트 영역의 높이 조정
  useEffect(() => {
    adjustHeight();
  }, [field.value]);

  return (
    <StyledTextarea
      ref={textareaRef}
      value={field.value}
      placeholder="매너있는 댓글을 남겨주세요 :)"
      maxHeight={maxHeight}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
};

// 스타일이 적용된 텍스트 영역
const StyledTextarea = styled.textarea<{ maxHeight: number }>`
  width: 100%;
  min-height: 48px;
  max-height: ${props => props.maxHeight}px;
  padding: 13px 16px;
  border: 1px solid ${theme.color.gray5};
  border-radius: 10px;
  font-size: 1.5rem;
  color: ${theme.color.gray1};
  resize: none;

  &:focus {
    outline: none;
    border-color: ${theme.color.primary};
  }

  &::placeholder {
    color: ${theme.color.gray4};
  }
`;

export default AutoResizeTextarea;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { BOARD_SORT } from './constants';

/**
 * 작성일 정렬 필터
 *
 * 현재 적용중인 정렬 표시 + 클릭시 변경 drawer 노출
 * 별도의 recoil Atom을 만들기 애매해서 searchKeywords를 활용해 정렬 상태를 관리함
 * 시설 공지사항, 1:1 문의 등에서 활용됨
 */

type Props = {
  identifier: string;
  className?: string;
};

const SortKeyword = ({ className, identifier }: Props) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { searchKeyword, setSearchKeyword } = useSearchKeywords(identifier);
  const currentSort = searchKeyword === BOARD_SORT.asc.value ? BOARD_SORT.asc.value : BOARD_SORT.desc.value;

  const { control } = useForm<{ sort: keyof typeof BOARD_SORT }>({
    defaultValues: {
      sort: currentSort,
    },
  });

  return (
    <Container className={className}>
      <Button
        fontWeight={400}
        textColor="gray2"
        rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
        onClick={() => setDrawerOpen(true)}>
        {BOARD_SORT[currentSort].label}
      </Button>
      <CheckRadioDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        name="sort"
        control={control}
        data={Object.values(BOARD_SORT)}
        onClick={e => setSearchKeyword(e.currentTarget.value)}
      />
    </Container>
  );
};

export default SortKeyword;

const Container = styled.section`
  background-color: ${theme.color.white};
  position: sticky;
  top: 0;
  margin-top: -1px;
  width: 100%;
  padding: 12px 20px;
  ${theme.flex('row', '', 'flex-end')};
  z-index: 1;
  border-bottom: 1px solid ${theme.color.gray6};
`;

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'api/queryClient';
import GlobalStyles from 'components/GlobalStyles';
import Toast from 'components/Toast';
import Router from 'pages/Router';
import ErrorDialog from 'sharedComponents/Boundaries/components/ErrorDialog';
import GlobalErrorBoundary from 'sharedComponents/Boundaries/GlobalErrorBoundary';
import { SWRConfig } from 'swr';

function App() {
  return (
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        {/* TODO SWR 훅 활용코드 모두 없애면 config provider 코드도 삭제해야 */}
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            shouldRetryOnError: false,
          }}>
          <GlobalStyles />
          <Router />
          <Toast />
          <ErrorDialog />
        </SWRConfig>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </GlobalErrorBoundary>
  );
}

export default App;

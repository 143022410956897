import styled from '@emotion/styled';
import Button, { ButtonProps } from 'components/Button';

type Props = {
  buttonProps: Omit<ButtonProps, 'children'> & { text: string };
};

const SubmitButton = ({ buttonProps }: Props) => {
  return (
    <Container>
      <Button type="submit" color="point" fontSize={15} fullWidth size="medium48" variant="contained" {...buttonProps}>
        {buttonProps.text}
      </Button>
    </Container>
  );
};

export default SubmitButton;

const Container = styled.div`
  margin-top: 24px;
`;

import { useEffect, useLayoutEffect, useRef } from 'react';

type FieldIdType = string;
type FieldType = HTMLInputElement;

/** 두 번째 input이 생성될 시점 트리거 */
let isFirstCreateSecondInput = true;

const useAutoFocusTracking = (field1Id: FieldIdType, field2Id: FieldIdType) => {
  /** 여백 클릭하기 전 포커싱 되어 있던 요소의 id */
  const lastFocusedElementId = useRef<FieldIdType | null>(field1Id);

  useLayoutEffect(() => {
    /** 첫 렌더링 시점 포커스 */
    const field1 = document.getElementById(field1Id) as FieldType;
    if (field1) {
      field1.focus();
    }
  }, [field1Id]);

  useEffect(() => {
    const field1 = document.getElementById(field1Id) as FieldType;
    let field2 = document.getElementById(field2Id) as FieldType;

    if (!field1) return;

    /** focusin 이벤트 함수. lastFocusedElementId 저장 */
    const getLastFocus = () => {
      if (document.activeElement === field1) {
        lastFocusedElementId.current = field1Id;
      } else if (document.activeElement === field2) {
        lastFocusedElementId.current = field2Id;
      }
    };

    const focusEvent = (e: MouseEvent) => {
      const isBackAreaClick = e.target !== field1 && e.target !== field2;
      if (isBackAreaClick) {
        const activeElementId = lastFocusedElementId.current === field1Id ? field1Id : field2Id;
        const activeElement = document.getElementById(activeElementId) as FieldType;
        if (activeElement) {
          activeElement.focus();
        }
      }
    };

    /** 두 번째 input은 동적으로 생성될 때 삽입 */
    const observer = new MutationObserver(() => {
      const updatedField = document.getElementById(field2Id) as FieldType;
      if (updatedField) {
        field2 = updatedField;
        if (isFirstCreateSecondInput) {
          isFirstCreateSecondInput = false;
          field2.focus();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    document.addEventListener('focusin', getLastFocus);
    document.addEventListener('click', focusEvent);
    return () => {
      document.removeEventListener('focusin', getLastFocus);
      document.removeEventListener('click', focusEvent);
      observer.disconnect();
    };
  }, [field1Id, field2Id, lastFocusedElementId]);

  return null;
};

export default useAutoFocusTracking;

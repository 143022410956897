import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

type Props = {
  total: number;
  groupTotal: number;
  privateTotal: number;
};

const ScheduleTotal = ({ total, groupTotal, privateTotal }: Props) => {
  return (
    <Container>
      <TotalAllWrapper>
        <Typography span size={15} weight={500} textColor="gray2">
          총
        </Typography>
        <Typography span size={15} weight={700} textColor="primary">
          {total}
        </Typography>
        <Typography span size={15} weight={500} textColor="gray2">
          건
        </Typography>
      </TotalAllWrapper>
      <TotalByClassWrapper>
        <div>
          <Typography span size={13} weight={500} textColor="gray3">
            그룹
          </Typography>
          <Typography span size={13} weight={500} textColor="gray3">
            {groupTotal}
          </Typography>
          <Typography span size={13} weight={500} textColor="gray3">
            명
          </Typography>
        </div>
        <VerticalDivider />
        <div>
          <Typography span size={13} weight={500} textColor="gray3">
            개인
          </Typography>
          <Typography span size={13} weight={500} textColor="gray3">
            {privateTotal}
          </Typography>
          <Typography span size={13} weight={500} textColor="gray3">
            명
          </Typography>
        </div>
      </TotalByClassWrapper>
    </Container>
  );
};

export default ScheduleTotal;

const Container = styled.div`
  ${theme.flex('', 'center', '', 8)}
  padding: 12px 20px;
  background-color: ${theme.color.white};

  .typography:first-of-type {
    margin-right: 4px;
  }
`;
const TotalAllWrapper = styled.div``;

const TotalByClassWrapper = styled.div`
  ${theme.flex('', 'center', '', 6)}
`;

const VerticalDivider = styled.div`
  border-left: 1px solid ${theme.color.gray4};
  height: 10px;
`;

import UpdateSingleBookingAvailableSetting from 'pages/Booking/components/AvailableTime/BookingAvailableSetting';
import UpdateSingleCheckInAvailableSetting from 'pages/Booking/components/AvailableTime/CheckInAvailableSetting';
import BookingAvailableSetting from 'pages/Booking/components/Operation/Setting/BookingAvailableSetting';
import CheckInAvailableSetting from 'pages/Booking/components/Operation/Setting/CheckInAvailableSetting';
import Repeat from 'pages/Booking/components/Repeat';
import { AllPageModeType } from 'pages/Booking/types';

import OthersAvailableSetting from './Operation/Create/OthersAvailableSetting';
import UpdateOthersSetting from './Operation/UpdateSingle/UpdateOthersSetting';

type Props = {
  type: string | string[];
  pageMode: AllPageModeType;
};

/** 그룹 수업 폼 > 타입별 설정 페이지 */
const FormDetailSetting = ({ type, pageMode }: Props) => {
  const isUpdateSingle = pageMode === 'update'; // 1개 수정

  switch (type) {
    case 'repeat':
      return <Repeat pageMode={pageMode} />;
    case 'bookingCancelTime':
      if (isUpdateSingle) {
        return <UpdateSingleBookingAvailableSetting />;
      } else {
        return <BookingAvailableSetting />;
      }
    case 'checkInTime':
      if (isUpdateSingle) {
        return <UpdateSingleCheckInAvailableSetting />;
      } else {
        return <CheckInAvailableSetting />;
      }
    case 'otherDetailTime':
      if (isUpdateSingle) {
        return <UpdateOthersSetting />;
      } else {
        return <OthersAvailableSetting />;
      }
  }
};

export default FormDetailSetting;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import SubTitle, { Props as SubTitlePropsType } from 'components/SubTitle';
import Typography from 'components/Typography';

type Props = {
  currentStep: number;
  subTitleOptions: SubTitlePropsType;
};

const TitleStep = ({ currentStep, subTitleOptions }: Props) => {
  return (
    <TitleContainer>
      <SubTitle {...subTitleOptions} bottom={40} />
      <Typography className="step" size={13} weight={600} textColor="gray4">
        <b>{currentStep}</b>/3
      </Typography>
    </TitleContainer>
  );
};

export default TitleStep;

const TitleContainer = styled.div`
  position: relative;

  .step {
    position: absolute;
    top: 4px;
    right: 0;

    b {
      color: ${theme.color.gray2};
    }
  }
`;

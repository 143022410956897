import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { MemberParams, MemberResponse } from './useInfinityMember';

/** 필터된 회원 전체 데이터 가져오기 */
const useGetAllMember = (params: MemberParams, enabled: boolean) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllMemberResponse, AllMemberParams>({
    url: `/api/member`,
    params: { ...params, limit: 200 },
  });

  return useQuery({
    queryKey: [`member`, { ...params }, 'all', enabled],
    queryFn,
    enabled,
    select: data => data.data,
  });
};

export default useGetAllMember;

type AllMemberParams = MemberParams & { limit: number };
type AllMemberResponse = MemberResponse[];

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import ButtonBase from 'components/Button/ButtonBase';
import Icon from 'components/Icon';
import { useState } from 'react';

type Props = {
  children: ({ isOpen }: { isOpen?: boolean }) => JSX.Element;
  className?: string;
};

const Foldable = ({ children, className }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container onClick={() => setIsOpen(prev => !prev)} className={className}>
      <div className="foldable-content">{children({ isOpen })}</div>
      <Icon name={isOpen ? 'arrowTop' : 'arrowBottom'} fillColor={theme.color.gray2} className="icon" />
    </Container>
  );
};

export default Foldable;

const Container = styled(ButtonBase)`
  padding: 14px 16px;
  box-shadow: inset 0 0 0 1px ${theme.color.gray5};
  border-radius: 10px;
  width: 100%;
  ${theme.flex('row', 'start', 'space-between')}

  .icon {
    flex: 0 0 auto;
  }
`;

import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';

import { ReportSaleParamType } from '../type';

type Props = {
  selectedOptionKey: 'paymentMethod' | 'type' | 'status';
  currentAllOptionCount: number;
} & FilterProps;

const ReportMultiButtonFilter = ({
  selectedOptionKey,
  currentAllOptionCount,
  currentFilter,
  selectedOption,
  onClick: changeOptions,
}: Props) => {
  const prevSelected = (selectedOption as ReportSaleParamType[typeof selectedOptionKey]) || [];

  const clickMultiple = (option: FilterOptionsType, key: string) => {
    const selectedValue = option.value as string;
    const currentValues = !prevSelected ? [] : prevSelected;
    const updateValue = currentValues.includes(selectedValue)
      ? currentValues.filter(item => item !== selectedValue)
      : [...currentValues, selectedValue];

    if (!option.value) {
      changeOptions({ value: undefined, label: '전체' }, selectedOptionKey);
    } else {
      changeOptions(
        { value: updateValue.length === currentAllOptionCount ? undefined : updateValue, label: '' },
        selectedOptionKey,
      );
    }
  };

  return <ButtonFilter currentFilter={currentFilter} selectedOption={selectedOption} onClick={clickMultiple} />;
};

export default ReportMultiButtonFilter;

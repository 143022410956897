import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { MemberCounselResponse } from 'hooks/service/queries/useInfinityMemberCounsel';
import filters from 'utils/filters';

import { MessageTargetType } from './types';

export const convertTargetsText = (targets?: Array<MessageTargetType>, placeholder = '') => {
  if (!targets || !targets.length) {
    return placeholder;
  }
  return targets.length === 1 ? targets[0].name : `${targets[0].name} 외 ${filters.numberComma(targets.length - 1)}명`;
};

export const convertMembersToTargets = (member: MemberResponse[]): MessageTargetType[] => {
  return member.map(({ id, name, mobile, avatar }) => ({ id, name, mobile: mobile ?? '', avatar: avatar?.image }));
};

export const convertCounselToTargets = (counsel: MemberCounselResponse[]): MessageTargetType[] => {
  return counsel.map(({ id, name, phone }) => ({ id, name, mobile: phone ?? '', avatar: null }));
};

/**
 * 컨벤션에 따른 z-index 상수값
 * @description https://tlxstudio.atlassian.net/wiki/spaces/WIKI/pages/1891598353/z-index
 */
export const Z_INDEX = {
  /** 9001 */
  errorDrawer: 9001,
  /** 9000 */
  errorDialog: 9000,
  /** 8000 */
  dialog: 8000,
  /** 7000 */
  toast: 7000,
  /** 6000 */
  drawer: 6000,
  /** 5000 */
  popup: 5000,
  /** 4000 */
  footerButton: 4000,
  /** 3500 */
  floatingButton: 3500,
  /** 3250 */
  dropdown: 3250,
  /** 3000 */
  tooltip: 3000,
  /** 2000 */
  stickyTab: 2000,
  /**
   * check, radio 등의 input 요소
   * 1000
   * 아이콘은 1001
   */
  input: 1000,
};

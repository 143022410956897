import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Form from 'components/Form';
import SubTitle from 'components/SubTitle';
import usePostAuthChangeAuthenticatedPassword from 'hooks/service/mutations/usePostAuthChangeAuthenticatedPassword';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { PasswordFormType } from 'pages/Join/types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { password, passwordConfirmation } from 'utils/validate';
import { object } from 'yup';

import { CHANGE_PASSWORD_TEXT } from '../constants';
import PasswordFieldController from './PasswordFieldController';

type Props = {
  validatedPassword: string;
};

const yupSchema = object().shape({ password, passwordConfirmation });

const NewPasswordForm = ({ validatedPassword }: Props) => {
  const { handleSubmit, control, formState, setError, getValues, clearErrors } = useForm<PasswordFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const { mutate: changeMutate, isPending } = usePostAuthChangeAuthenticatedPassword();
  const { setToast } = useToast();
  const navigate = useNavigate();

  const errorDialog = useErrorDialog();

  const changePassword = ({ password, passwordConfirmation }: PasswordFormType) => {
    if (getValues('password') === validatedPassword) {
      setError('password', { type: 'validate' });
      errorDialog.open(CHANGE_PASSWORD_TEXT.duplicated);
      return;
    }
    const params = {
      password,
      password_confirmation: passwordConfirmation,
    };
    changeMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: CHANGE_PASSWORD_TEXT.success });
        navigate(-1);
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <>
      <SubTitle
        title={CHANGE_PASSWORD_TEXT.change.title}
        message={{ position: 'bottom', text: CHANGE_PASSWORD_TEXT.change.description }}
        bottom={40}
      />
      <FormContainer onSubmit={handleSubmit(changePassword)}>
        <PasswordFieldController
          label={CHANGE_PASSWORD_TEXT.label}
          controllerProps={{ control, name: 'password' }}
          placeholder={CHANGE_PASSWORD_TEXT.change.placeholder1}
          onChange={() => clearErrors('password')}
        />
        <PasswordFieldController
          label={CHANGE_PASSWORD_TEXT.change.placeholder2}
          controllerProps={{ control, name: 'passwordConfirmation' }}
          placeholder={CHANGE_PASSWORD_TEXT.change.placeholder2}
        />
        <StyledButton
          type="submit"
          size="medium48"
          variant="contained"
          color="point"
          disabled={!formState.isValid || formState.isSubmitting || isPending || !!formState.errors.password}>
          {CHANGE_PASSWORD_TEXT.change.submit}
        </StyledButton>
      </FormContainer>
    </>
  );
};

export default NewPasswordForm;

const FormContainer = styled(Form)`
  ${theme.flex('column', '', '', 20)}

  .outlined-text-field label p {
    opacity: 0.8;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 4px;
`;

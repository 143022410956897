import { BOOKING_DETAIL_UPDATE_DRAWER_LIST } from '../../constants';
import MoreButtonListDrawer from './MoreButtonListDrawer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  goBookingUpdateForm: (mode: string) => void;
};

const LectureUpdateListDrawer = ({ isOpen, onClose, goBookingUpdateForm }: Props) => {
  return (
    <MoreButtonListDrawer
      buttonList={BOOKING_DETAIL_UPDATE_DRAWER_LIST}
      onClick={goBookingUpdateForm}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default LectureUpdateListDrawer;

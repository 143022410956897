import Tabs, { TabDataElementType } from 'components/Tabs';
import useQueryString from 'hooks/useQueryString';
import FilterList from 'sharedComponents/Filters/FilterList';
import { convertTabs } from 'sharedComponents/Filters/utils';
import MainLayout from 'sharedComponents/MainLayout';

import {
  DEFAULT_COUNSEL_FILTER_OPTIONS,
  DEFAULT_COUNSEL_FILTER_TABS,
  DEFAULT_MEMBER_FILTER_OPTIONS,
  MAIN_TAB_DATA,
  MEMBER_FILTER_DEFAULT_TAB_LABEL,
} from '../constants';
import { Container, ContentsContainer, ContentsWrapper, StickyWrapper } from './MemberLayout';
import MemberSkeletonList from './MemberSkeletonList';
import Search from './Search';

const MemberSkeleton = () => {
  const { getSearchParams, setSearchParams } = useQueryString();
  const tab = getSearchParams('tab');
  const currentTab = MAIN_TAB_DATA.find(tabData => tabData.value === tab) || MAIN_TAB_DATA[0];
  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value });
  };

  const isCounsel = currentTab.value === 'counsel';

  const allFilters = isCounsel ? DEFAULT_COUNSEL_FILTER_OPTIONS : DEFAULT_MEMBER_FILTER_OPTIONS;
  const defaultFilterLabel = isCounsel ? DEFAULT_COUNSEL_FILTER_TABS : MEMBER_FILTER_DEFAULT_TAB_LABEL;

  return (
    <MainLayout bottomNav scrollable>
      <Container>
        <Search />
        <ContentsContainer>
          <StickyWrapper>
            <Tabs data={MAIN_TAB_DATA} value={currentTab.value} onChange={changeTab} />
            <FilterList
              selectedCount={0}
              filterTabs={convertTabs(allFilters, {}, defaultFilterLabel)}
              filterValues={{}}
              onOpenWholeFilters={() => null}
            />
          </StickyWrapper>
          <ContentsWrapper>
            <MemberSkeletonList currentTab={currentTab.value} />
          </ContentsWrapper>
        </ContentsContainer>
      </Container>
    </MainLayout>
  );
};

export default MemberSkeleton;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

type Props = {
  keyword: string;
};

const NoSearchDataTypography = ({ keyword }: Props) => {
  return (
    <Container>
      <Typography span size={15} weight={600} textColor="primary">
        “{keyword}”
      </Typography>
      <Typography span size={15} textColor="gray2" opacity={0.8}>
        의 검색 결과가 없습니다.
      </Typography>
    </Container>
  );
};

export default NoSearchDataTypography;

const Container = styled.div`
  ${theme.flex('', '', '', 2)}
`;

import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import useGetDivision from 'hooks/service/queries/useGetDivision';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { BOOKING_GROUP_FORM_TEXT } from '../../../constants';
import { BookingGroupCreateFormType } from '../../../types';
import DivisionLinkButton from './DivisionLinkButton';

/** 수업 구분 */
const DivisionSetting = () => {
  const { control } = useFormContext<BookingGroupCreateFormType>();
  const divisionId = useWatch({ control, name: 'divisionId' });
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const { data } = useGetDivision();
  const disabled = !data.length;

  const divisionDataArray = useMemo(() => {
    const defaultItem = {
      id: BOOKING_GROUP_FORM_TEXT.division.notSelected.value,
      label: BOOKING_GROUP_FORM_TEXT.division.notSelected.label,
      value: BOOKING_GROUP_FORM_TEXT.division.notSelected.value,
    };
    const divisionDataItems = data.map(item => {
      return { id: `${item.id}`, label: item.title, value: `${item.id}` };
    });

    return [defaultItem, ...divisionDataItems];
  }, [data]);

  const label = useMemo(() => {
    if (disabled) {
      return BOOKING_GROUP_FORM_TEXT.division.disabled;
    }
    if (!divisionId) {
      return BOOKING_GROUP_FORM_TEXT.division.placeholder;
    }
    return divisionDataArray.find(item => item.value === divisionId)?.label;
  }, [disabled, divisionDataArray, divisionId]);

  return (
    <>
      <DivisionLinkButton
        ellipsis={1}
        disabled={disabled}
        showArrow={!disabled}
        hasValue={!!divisionId}
        onClick={() => setIsOpenDrawer(true)}>
        {label}
      </DivisionLinkButton>
      <CheckRadioDrawer
        isOpen={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        header={BOOKING_GROUP_FORM_TEXT.division.drawer.title}
        data={divisionDataArray}
        name="divisionId"
        control={control}
      />
    </>
  );
};

export default DivisionSetting;

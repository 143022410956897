import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';

type Props = {
  onClick: () => void;
  onClose: () => void;
};

/** 룸 선택없이 다음 버튼 누를 때 노출 */
const ConfirmNoRoomDialog = ({ onClick, onClose }: Props) => {
  return (
    <Dialog
      onClose={onClose}
      negativeAction={{
        text: BOOKING_COMMON_FORM_TEXT.room.dialog.negativeText,
      }}
      positiveAction={{
        text: BOOKING_COMMON_FORM_TEXT.room.dialog.positiveText,
        onClick,
      }}>
      <DialogDescription tag="pre">{BOOKING_COMMON_FORM_TEXT.room.dialog.description.notSelectedConfirm}</DialogDescription>
    </Dialog>
  );
};

export default ConfirmNoRoomDialog;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { MemberCardProps } from 'types/memberTypes';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

type Props = {
  target_staff_id: number;
  search?: string;
  sort_target?: string;
  sort_type?: string;
};

const useInfinityStaffMember = (params: Props) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<StaffMemberResponse, StaffMemberParams>({
    url: '/api/staff/member',
    params: {
      ...params,
      paginate_type: 'detail',
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['staff/member', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links?.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        members: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
      };
    },
  });
};

export default useInfinityStaffMember;

type StaffMemberResponse = MemberCardProps;

type StaffMemberParams = Props & {
  paginate_type: 'detail';
};

import styled from '@emotion/styled';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';
import { ReactNode, useState } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

type Props = {
  children: ReactNode;
};

const TargetsDetailButton = ({ children }: Props) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <>
      <DetailButton textColor="primary" fontSize={12} fontWeight={500} onClick={() => setIsDrawerOpened(true)}>
        상세보기
      </DetailButton>
      <ApiBoundary>
        <Drawer isOpen={isDrawerOpened} onClose={() => setIsDrawerOpened(false)}>
          {children}
        </Drawer>
      </ApiBoundary>
    </>
  );
};

export default TargetsDetailButton;

const DetailButton = styled(Button)`
  padding-top: 6px;
  span {
    text-decoration: underline;
    letter-spacing: -0.25px;
  }
`;

import { useIsMutating } from '@tanstack/react-query';
import { TicketPaymentUpdateFormType } from 'pages/TicketDetail/types';
import { PropsWithChildren, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { PaymentSubmitButton } from 'sharedComponents/TicketForm';

import getIsUnpaid from '../utils/getIsUnpaid';

const SubmitButton = ({ children }: PropsWithChildren) => {
  const {
    watch,
    formState: { dirtyFields },
    getValues,
  } = useFormContext<TicketPaymentUpdateFormType>();
  const isDirty = !!Object.keys(dirtyFields).length;
  const { status } = watch();
  const isMutating = useIsMutating() > 0;
  const isUpdate = getValues('paymentStatus') === 'update'; // 결제정보 or 미수금 수정 폼
  const isUnpaidStatus = getIsUnpaid(status);

  const disabled = useMemo(() => {
    if (isMutating) return true;
    // 결제정보 수정 폼에서 수정사항 없을 때
    if (isUpdate && !isUnpaidStatus && !isDirty) return true;
    return false;
  }, [isDirty, isMutating, isUnpaidStatus, isUpdate]);

  return <PaymentSubmitButton disabled={disabled}>{children}</PaymentSubmitButton>;
};

export default SubmitButton;

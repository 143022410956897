import { TabDataElementType } from 'components/Tabs';
import { ArrowFilterValuesType, KeyStringObjType } from 'components/Tabs/ArrowFilterTabs';
import { get, has } from 'lodash';

import { FilterType } from './types';

// 필터 탭 데이터로 변환, 주로 라벨 표시를 위해 사용
export const convertTabs = <T extends KeyStringObjType>(
  allFilters: FilterType[],
  selectedFilter: T,
  defaultTabLabels: { [key: string]: string },
): TabDataElementType[] => {
  return allFilters.map(({ key, options, tabConverter }) => {
    const defaultLabel = get(defaultTabLabels, key);
    const hasSelectedOption = has(selectedFilter, key) && !!selectedFilter[key];
    const selectedOption = hasSelectedOption ? options.find(({ value }) => value === selectedFilter[key]) : undefined;

    /**
     * 선택된 옵션이 있고, 탭 가공함수가 있으면 가공함수 활용
     */
    if (hasSelectedOption && tabConverter) {
      const value = selectedFilter[key] as ArrowFilterValuesType;
      return {
        value: key,
        label: tabConverter?.(value),
      };
    }

    /**
     * 선택된 옵션이 있고, 탭 가공함수가 없으면 옵션의 라벨 활용
     */
    if (hasSelectedOption && selectedOption) {
      return {
        value: key,
        label: selectedOption.label,
      };
    }

    /**
     * 선택된 옵션이 없으면 기본 라벨을 사용
     */
    return {
      value: key,
      label: defaultLabel,
    };
  });
};

import { useSuspenseQuery } from '@tanstack/react-query';

import { StaffType } from 'types/staffTypes';
import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

type Props = {
  targetStaffId: number;
};

const useGetStaffWorkSchedules = ({ targetStaffId }: Props) => {
  const { queryFn } = useQueryFnWithCommonParams<StaffWorkSchedulesResponse>({
    url: `/api/staff/workSchedules/${targetStaffId}`,
  });

  return useSuspenseQuery({
    queryKey: ['work-schedules'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetStaffWorkSchedules;

export type StaffWorkSchedulesResponse = Record<'rest_time' | 'work_time', StaffType['schedules']>;

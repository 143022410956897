import { useIsFetching } from '@tanstack/react-query';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import DetailPages from './components/DetailPages';
import Main from './Main';

const TicketDetail = () => {
  const currentTicketId = useParamsId();
  const { getSearchParams } = useQueryString();

  const currentMemberId = Number(getSearchParams('memberId'));
  const detailPageType = getSearchParams('type');
  const isFetching = useIsFetching() > 0;

  return (
    <>
      {detailPageType ? (
        <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
          <DetailPages currentTicketId={currentTicketId} currentMemberId={currentMemberId} />
        </ApiBoundary>
      ) : (
        <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
          <Main currentTicketId={currentTicketId} currentMemberId={currentMemberId} />
        </ApiBoundary>
      )}
    </>
  );
};

export default TicketDetail;

import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { AllDayEtcScheduleResponse, Props } from './useGetAllScheduleAllDayEtcSchedule';
import { EtcScheduleParams } from './useGetAllScheduleEtcSchedule';

const useGetAllScheduleAllDayEtcScheduleTime = ({ enabled, studioId, ...params }: Props) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllDayEtcScheduleResponse, EtcScheduleParams>({
    url: '/api/schedule/allDayEtcScheduleTime',
    params: {
      ...params,
    },
  });

  return useQuery({
    queryKey: ['schedule/allDayEtcScheduleTime', { ...params, enabled, studioId }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllScheduleAllDayEtcScheduleTime;

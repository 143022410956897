import { useIsFetching } from '@tanstack/react-query';
import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import MobileDetail from './components/MobileDetail';

const MoreDetailsMessageMobileDetail = () => {
  const id = useParamsId();
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
      <MobileDetail id={id} />
    </ApiBoundary>
  );
};

export default MoreDetailsMessageMobileDetail;

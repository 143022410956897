import { localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { mobile, teacherNameRequired as name } from 'utils/validate';
import { boolean, number, object, string } from 'yup';

export const STAFF_PROFILE_FORM_TEXT = {
  headerTitle: {
    profile: '프로필 수정',
    schedule: '시간정보 수정',
    staffInfoUpdate: '강사 정보 수정',
    myInfoUpdate: '내 강사 정보 수정',
  },
  subTitle: {
    selfIntroduction: '자기소개',
    careers: '주요이력',
    required: '필수입력 항목',
    optional: '선택입력 항목',
    eventSchedule: '특정일 근무시간',
  },
  placeholer: {
    profile: '강사명 입력',
    selfIntroduction: '자기소개를 입력해 주세요.',
  },
  desc: {
    mobile: ['관리자앱과 연결되는 중요한 정보입니다.', '신중히 입력해 주세요.'],
    eventSchedule: ['날짜를 지정하여 근무시간, 휴식시간', '또는 휴일을 설정할 수 있습니다.'],
  },
  success: {
    eventSchedulesAdd: '특정일 근무시간이 추가되었습니다.',
    eventSchedulesUpdate: '특정일 근무시간이 수정되었습니다.',
  },
};

export const RESPONSE_SUCCESS = {
  profile: '프로필이 수정되었습니다.',
  schedule: '시간정보가 수정되었습니다.',
  staffInfoUpdate: '강사 정보가 수정되었습니다.',
};

export const CALENDAR_DEFAULT_PROPS: MbscDatepickerOptions = {
  theme: 'ios',
  themeVariant: 'light',
  locale: localeKo,
  display: 'inline',
  dateFormat: 'YYYY.MM.DD',
  selectMultiple: true,
};

export const staffInfoYupSchema = object({
  profile: object({
    name,
    gender: string().notRequired(),
    birthday: string().notRequired(),
    get_noti_all: boolean().notRequired(),
    registered_at: string().required(),
    representative_color: string().required(),
  }),
  mobile,
  role_id: number().required(),
});

export const STAFF_CREATE_STEP = [
  { step: 1, label: '기본정보 입력' },
  { step: 2, label: '프로필 입력' },
  { step: 3, label: '시간정보 설정' },
];

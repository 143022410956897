import { BoardNoticeType } from 'hooks/service/queries/useGetBoardNotice';
import filters from 'utils/filters';

import { NOTICE_TEXT } from './constants';

export const periodText = (notice: BoardNoticeType) => {
  if (notice.is_used_period) {
    return filters.period(notice.start_on, notice.end_on);
  }
  if (notice.is_unlimited_period) {
    return NOTICE_TEXT.periodUnlimited;
  }
  return NOTICE_TEXT.periodNone;
};

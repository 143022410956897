export const NOTICE_TEXT = {
  settings: '공지설정',
  popup: '팝업사용',
  topFixed: '상단고정',
  target: '공지대상',
  targetActive: '유효회원',
  targetExpired: '만료회원',
  period: '게시기간',
  periodLimited: '설정',
  periodUnlimited: '제한없음',
  periodNone: '미설정',
  title: '제목',
  contents: '내용',
};

export const NOTICE_IDENTIFIER = 'notice';
export const NOTICE_SORT_IDENTIFIER = 'noticeSort';

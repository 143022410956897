import usePostCounsel, { PostCounselingParamsType } from 'hooks/service/mutations/usePostCounseling';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { CustomError } from 'types/errorTypes';
import filters from 'utils/filters';

import { CounselingFormType } from '../type';
import CounselCreateUpdateForm from './CounselCreateUpdateForm';

type Props = {
  typeText: string;
};

const CounselCreateForm = ({ typeText }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  const { mutate: postCounselMutate } = usePostCounsel();

  const handleSubmit = async (values: CounselingFormType) => {
    const { id, date, time, is_registered, target_staff_id, phone, ...rest } = values;

    const combinedDateStartTime = `${filters.dateDash(date)} ${filters.timeSecondZero(time[0])}`;
    const combinedDateEndTime = `${filters.dateDash(date)} ${filters.timeSecondZero(time[1])}`;

    const params: PostCounselingParamsType = {
      ...rest,
      phone,
      target_staff_id: Number(target_staff_id),
      start_on: combinedDateStartTime,
      end_on: combinedDateEndTime,
      is_registered: is_registered === 'registered',
    };

    postCounselMutate(params, {
      onSuccess: res => {
        setToast({ type: 'success', message: `상담이 ${typeText}되었습니다.` });
        navigate(`/counsel/detail/${res.data.id}${location.search}`);
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <ApiBoundary>
      <CounselCreateUpdateForm typeText={typeText} onSubmit={handleSubmit} />
    </ApiBoundary>
  );
};

export default CounselCreateForm;

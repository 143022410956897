import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import filters from 'utils/filters';

import { TicketPaymentFormType } from '../types';

type Props = {
  text?: string;
  isRefund?: boolean;
};

const ToTalAmount = ({ text = '총 결제금액', isRefund }: Props) => {
  const { setValue, watch } = useFormContext<TicketPaymentFormType>();

  const {
    payment: { amount, card_amount, cash_amount, wiretransfer_amount, point_amount, reward_point },
  } = watch();

  useEffect(() => {
    const point = isRefund ? reward_point : point_amount;
    const totalAmount = Number(card_amount) + Number(cash_amount) + Number(wiretransfer_amount) + Number(point);

    setValue('payment.amount', totalAmount, { shouldDirty: true });
  }, [setValue, card_amount, cash_amount, wiretransfer_amount, point_amount, isRefund, reward_point]);

  return (
    <Container>
      <Typography span size={15} weight={500} textColor="gray2">
        {text}
      </Typography>
      <Typography className="amount-text" span size={17} weight={700}>
        {filters.numberComma(amount)}
        <span>원</span>
      </Typography>
    </Container>
  );
};

export default ToTalAmount;

const Container = styled.div`
  ${theme.flex('', '', 'space-between')};
  margin-bottom: 15px;
  .amount-text {
    span {
      display: inline-block;
      width: 15px;
      margin-left: 2px;
    }
  }
`;

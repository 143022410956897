import styled from '@emotion/styled';
import Accordion from 'components/Accordion';
import { BookingGroupCommonFormType } from 'pages/Booking/Group/types';
import { AllPageModeType } from 'pages/Booking/types';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import CreateOperationAccordionContents from './Create/CreateOperationAccordionContents';
import UpdateOperationAccordionContents from './UpdateSingle/UpdateOperationAccordionContents';

type Props = {
  pageMode: AllPageModeType;
};

/** 운영정보 설정 아코디언 */
const OperationAccordion = ({ pageMode }: Props) => {
  const { watch, setValue } = useFormContext<BookingGroupCommonFormType>();
  const { isOpenOperationAccordion } = watch();
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpenOperationAccordion) {
      setTimeout(() => {
        observerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 300);
    }
  }, [isOpenOperationAccordion]);

  return (
    <Container isOpen={isOpenOperationAccordion}>
      <Accordion
        isOpen={isOpenOperationAccordion}
        onChange={() => setValue('isOpenOperationAccordion', !isOpenOperationAccordion)}
        header="운영정보 설정">
        {pageMode === 'update' ? <UpdateOperationAccordionContents /> : <CreateOperationAccordionContents />}
      </Accordion>
      <div className="target" ref={observerRef} />
    </Container>
  );
};

export default OperationAccordion;

const Container = styled.div<{ isOpen?: boolean }>`
  padding-bottom: ${({ isOpen }) => (isOpen ? 44 : 115)}px;

  .target {
    background: transparent;
    height: ${({ isOpen }) => (isOpen ? 88 : 1)}px;
  }
`;

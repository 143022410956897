import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostMemberDeductPoint = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemberDeductPointParams>({
    method: 'post',
    url: '/api/point/deduct',
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['member', 'point'],
      });
    },
  });
};

export default usePostMemberDeductPoint;

type MemberDeductPointParams = {
  member_id: number;
  point: number;
  updated_for?: string;
};

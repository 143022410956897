import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { CommonStyleCard, CommonStyledCardTopWrapper } from 'sharedComponents/StyledComponents';
import getImageUrl from 'utils/getImageUrl';

import { BOOKING_LABEL_PROPS, BOOKING_TYPE } from '../constants';
import { BookingInfoType, LectureCardCancelParamsType, LectureCardNavigateParamsType } from '../types';

type Props = {
  data: BookingInfoType;
  onClickNavigateBookingDetail?: ({ lectureId, isDeletedLecture }: LectureCardNavigateParamsType) => void;
  onClickPastHistory?: (id: number) => void;
  onCancel?: ({ bookingId, status, classType }: LectureCardCancelParamsType) => void;
};

const LectureCard = ({ data, onClickNavigateBookingDetail, onClickPastHistory, onCancel }: Props) => {
  const {
    bookingId,
    lectureId,
    title,
    status,
    userTicketTitle,
    avatar,
    staffName,
    classType,
    classStartTime,
    bookingCompletedTime,
    waitBookingCount,
    isDeletedLecture,
  } = data;

  const labelText = useMemo(() => {
    if (status === 'booking_waiting') {
      return `대기 ${waitBookingCount}`;
    }
    return BOOKING_TYPE[status];
  }, [status, waitBookingCount]);

  const buttonText = useMemo(() => {
    if (status === 'cancel') {
      return '삭제';
    }
    return `${BOOKING_TYPE[status]} 취소`;
  }, [status]);

  return (
    <Container className="lecture-card">
      <div onClick={() => onClickNavigateBookingDetail?.({ lectureId, isDeletedLecture })}>
        <CardTopWrapper>
          <div className="label-wrapper">
            <Typography size={17} weight={700} ellipsis={1}>
              {`${isDeletedLecture ? '[삭제된 수업]' : ''} ${title}`}
            </Typography>
            <Label size="large" borderRadius={4} fontSize={13} minWidth={57} {...BOOKING_LABEL_PROPS[status]}>
              {labelText}
            </Label>
          </div>

          <BookingInfoWrapper>
            <Typography className="date" textColor="gray2" size={15} opacity={0.88}>
              {classStartTime}
            </Typography>
            <div className="teacher-info-wrapper">
              <Avatar size={16} imgUrl={avatar ? getImageUrl(avatar.image) : undefined} />
              <div className="text-wrapper">
                <Typography textColor="gray2" size={15} ellipsis={1} opacity={0.88}>
                  {staffName}
                </Typography>
                <Typography textColor="gray2" size={15} opacity={0.88}>
                  강사
                </Typography>
              </div>
            </div>
          </BookingInfoWrapper>
        </CardTopWrapper>

        <InfoFlexWrapper className="info-flex-wrapper">
          <InfoFlexRow>
            <InfoTypography>사용된 수강권</InfoTypography>
            <InfoTypography weight={500} ellipsis={1}>
              {userTicketTitle}
            </InfoTypography>
          </InfoFlexRow>
          <InfoFlexRow>
            <InfoTypography>{BOOKING_TYPE[status]} 일시</InfoTypography>
            <InfoTypography weight={500}>{bookingCompletedTime}</InfoTypography>
          </InfoFlexRow>
        </InfoFlexWrapper>
      </div>

      {onClickPastHistory && onCancel && (
        <ButtonGroup gap={8}>
          <Button fullWidth variant="outlined" size="medium40" textColor="gray2" onClick={() => onClickPastHistory(bookingId)}>
            지난 내역 보기
          </Button>
          <Button
            fullWidth
            variant="outlined"
            size="medium40"
            textColor="secondary3"
            onClick={() => onCancel({ bookingId, status, classType })}>
            {buttonText}
          </Button>
        </ButtonGroup>
      )}
    </Container>
  );
};

export default LectureCard;

const Container = styled(CommonStyleCard)`
  .button-group {
    margin-top: 20px;
  }
`;

const CardTopWrapper = styled(CommonStyledCardTopWrapper)`
  padding-bottom: 16px;
  margin-bottom: 16px;

  .label-wrapper {
    ${theme.flex('', 'center', 'space-between', 8)};
    margin-bottom: 4px;
  }
`;

const BookingInfoWrapper = styled.div`
  ${theme.flex('', 'center', '', 20)};

  .date {
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 10px;
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      background-color: ${theme.color.gray4};
    }
  }

  .teacher-info-wrapper {
    ${theme.flex('', 'center', '', 4)};
    flex: 1;
    overflow: hidden;

    .text-wrapper {
      ${theme.flex('', 'center', '', 4)};
    }

    .typography:last-of-type {
      flex: 0 0 auto;
    }
  }
`;

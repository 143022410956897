import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Tabs, { TabsCommonProps } from 'components/Tabs';
import { TabBaseProps } from 'components/Tabs/TabBase';
import Typography from 'components/Typography';
import { Z_INDEX } from 'constants/zIndex';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorType } from 'types/colorTypes';

export interface IHeaderProps {
  /**
   * Header안에 탭이 있는 경우 사용.
   */
  tabs?: {
    /** 탭의 메뉴 리스트 */
    data: TabsCommonProps['data'];
    /** 선택된 탭 value */
    value: string;
    onChange: TabBaseProps['onClick'];
  };
  /**
   * 타이틀 문구
   */
  title?: string;
  /**
   * 타이틀 문구의 정렬 방향
   */
  leftAlign?: boolean;
  /**
   * 왼쪽 아이콘
   */
  leftButton?: React.ReactNode;
  /**
   * 오른쪽 아이콘 or 아이콘 그룹 및 텍스트 버튼
   */
  rightButton?: React.ReactNode;
  /**
   * 헤더의 배경색 지정
   */
  bgColor?: ColorType;
  /**
   * Header 박스의 밑줄 노출 유무
   */
  underLine?: boolean;
  /**
   * Header 타이틀 색상
   */
  textColor?: ColorType;
}

const Header = ({ tabs, title, leftAlign, leftButton, rightButton, bgColor, underLine, textColor }: IHeaderProps) => {
  const navigate = useNavigate();

  const defaultButton = useMemo(() => {
    return (
      <IconButton onClick={() => navigate(-1)}>
        <Icon name="headerBack" />
      </IconButton>
    );
  }, [navigate]);

  const currentBgColor = useMemo(() => {
    if (bgColor === 'white' || !bgColor) {
      return 'gray1';
    } else if (textColor) {
      return textColor;
    }
    return 'white';
  }, [bgColor, textColor]);

  return (
    <Container bgColor={bgColor} underLine={underLine}>
      <HeaderWrapper leftAlign={leftAlign}>
        {/* 탭이 있을 경우 우선적으로 적용 */}
        {tabs && <Tabs isHeaderType {...tabs} />}

        {/* 탭이 아니라면 일반적인 Header 형태 적용 */}
        {!tabs && (
          <>
            {!leftAlign && <div className="button-wrapper">{leftButton || defaultButton}</div>}

            <Typography size={leftAlign ? 18 : 17} weight={700} textColor={currentBgColor}>
              {title}
            </Typography>
          </>
        )}

        {rightButton && <div className="button-wrapper right">{rightButton}</div>}
      </HeaderWrapper>
    </Container>
  );
};

export default Header;

const Container = styled.header<Pick<IHeaderProps, 'underLine' | 'bgColor'>>`
  ${theme.flex('', 'center', '')};
  padding: 0 20px;
  height: 56px;
  ${({ underLine }) => underLine && `border-bottom: 1px solid ${theme.color.gray7}`};
  ${({ bgColor }) => bgColor && `background-color: ${theme.color[bgColor]}`};
  z-index: ${Z_INDEX.stickyTab};
`;

const HeaderWrapper = styled.div<Pick<IHeaderProps, 'leftAlign'>>`
  flex: 1;
  position: relative;
  ${({ leftAlign }) => leftAlign || `text-align: center`};

  .button-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .right {
    right: 0;
    z-index: ${Z_INDEX.dropdown};

    > button {
      padding: 0;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';
import getEllipsisByLength from 'utils/getEllipsisByLength';
import getImageUrl from 'utils/getImageUrl';
import getRemainingDaysText from 'utils/getRemainingDaysText';
import getTicketInfoByType from 'utils/getTicketInfoByType';

type Props = {
  data: UserTicketLectureResponse;
  isDuplicated?: boolean;
  showWaitingLabel?: boolean;
  waitingNumber?: number;
};

const UserTicketCard = ({ data, isDuplicated, showWaitingLabel, waitingNumber }: Props) => {
  const {
    member: { name, vaccination_yn, mobile, image, account_id },
    ticket,
    expire_at,
    usable_coupon,
  } = data;
  const hasVaccinate = vaccination_yn === 'Y';
  const { showCoupon, showPeriod } = getTicketInfoByType(ticket.type);
  const daysRemainingText = getRemainingDaysText(expire_at, filters.dateDash());
  const ticketTitle = getEllipsisByLength({
    text: ticket.title,
    max: 12,
  });

  return (
    <Container>
      <Avatar size={28} imgUrl={getImageUrl(image)} />
      <div className="detail-info-wrapper">
        <div className="top-wrapper">
          <div className="left">
            {showWaitingLabel && waitingNumber && (
              <StyledLabel
                size="small"
                height={20}
                color="primary"
                textColor="primary"
                opacity={0.2}
                padding={4}
                borderRadius={3}>
                대기 {waitingNumber}
              </StyledLabel>
            )}
            <Typography size={15} weight={600} ellipsis={1}>
              {name}
            </Typography>
            {hasVaccinate && <Icon name="vaccinateBold" size={16} color="secondary1" />}
          </div>
          {mobile && (
            <div className="right">
              <Typography size={13}>{filters.mobile(mobile)}</Typography>
              {!account_id && <Icon name="connectOff" size={16} color="gray3" />}
            </div>
          )}
          {isDuplicated && (
            <Typography className="duplicate-text" textColor="error" weight={600}>
              중복예약
            </Typography>
          )}
        </div>
        <TypographyWrapper>
          <Typography span size={13} textColor="gray2" opacity={0.72}>
            {ticketTitle}
          </Typography>
          {(showCoupon || showPeriod) && (
            <Typography span size={13} textColor="gray2" opacity={0.72}>
              {showCoupon ? `${usable_coupon}회` : ''}
              {showCoupon && showPeriod ? 'ㆍ' : ''}
              {showPeriod ? `${daysRemainingText}` : ''}
            </Typography>
          )}
        </TypographyWrapper>
      </div>
    </Container>
  );
};

export default UserTicketCard;

const Container = styled.div`
  ${theme.flex('', 'center', 'flex-start', 10)};
  padding: 9px 0;

  .avatar,
  .duplicate-text {
    flex: 0 0 auto;
  }

  .detail-info-wrapper {
    flex: 1;
  }
  .top-wrapper {
    ${theme.flex('', 'center', '', 6)};

    .left,
    .right {
      ${theme.flex('', 'center', '')};
    }
    .right {
      flex: 0 0 auto;
    }
  }
`;

const StyledLabel = styled(Label)`
  margin-right: 6px;
  flex: 0 0 auto;
  .typography {
    font-weight: 700;
  }
`;

const TypographyWrapper = styled(ArrangeTypographyWrapper)`
  ${theme.textEllipsis(1)};
`;

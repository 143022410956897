import filters from 'utils/filters';

import { CLOSED_DAY_TIME } from './components/WorkTime/constants';
import { ScheduleFormType } from './types';

const checkProcess = (workTimeField: ScheduleFormType) => {
  let isPeriodCheck = true;

  const { start_time, end_time, restTimes } = workTimeField;

  /** 휴무일 및 휴식 시간이 없을 때는 검증 하지 않음 */
  const isClosed = start_time === CLOSED_DAY_TIME.start && end_time === CLOSED_DAY_TIME.end;
  if (!restTimes?.length || isClosed) return true;

  /** 휴식 시간이 있을 때만 근무 시간이 벗어나는지 검증 */
  restTimes.forEach(restTime => {
    const { timeToDayjs } = filters;
    /** 시간 문자열을 dayjs 객체로 변경 */
    const currentRest = [timeToDayjs(restTime.start_time), timeToDayjs(restTime.end_time)];
    const currentWork = [timeToDayjs(start_time), timeToDayjs(end_time)];

    isPeriodCheck = currentRest[0].isSameOrAfter(currentWork[0]) && currentRest[1].isSameOrBefore(currentWork[1]);
  });
  return isPeriodCheck;
};

/** 특정일 근무시간 단일폼) 근무시간과는 데이터 관리 구조가 달라서 유틸 별도로 만듬 */
export const getEventSingleIsPeriodCheck = (workTimeField: ScheduleFormType) => {
  return checkProcess(workTimeField);
};

/** 특정일 근무시간 다중폼 */
export const getEventMultiIsPeriodCheck = (selectDateFields: ScheduleFormType[]) => {
  let isPeriodCheck = true;

  selectDateFields.forEach(workTimeField => {
    if (!checkProcess(workTimeField)) {
      isPeriodCheck = false;
      return;
    }
  });

  return isPeriodCheck;
};

import 'swiper/css';

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { ComponentProps, useState } from 'react';
import MainLayout from 'sharedComponents/MainLayout';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import ThumbnailPagination from './ThumbnailPagination';

export type Props = {
  selectedIndex: number;
  onClose: () => void;
  files: ComponentProps<typeof ThumbnailPagination>['files'];
};

const ImageDetail = ({ files, selectedIndex, onClose }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(selectedIndex);

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={onClose}>
            <Icon name="headerClose" />
          </IconButton>
        ),
        title: '상세이미지',
      }}>
      <Container>
        <Swiper
          onSwiper={(swiper: SwiperClass) => swiper.slideTo(currentIndex, 0)}
          onSlideChange={(swiper: SwiperClass) => setCurrentIndex(swiper.activeIndex)}>
          {files.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={item} alt="첨부 이미지" />
              </SwiperSlide>
            );
          })}

          <ThumbnailPagination files={files} currentIndex={currentIndex} />
        </Swiper>
      </Container>
    </MainLayout>
  );
};

export default ImageDetail;

const Container = styled.div`
  height: 100%;

  .swiper {
    height: 100%;
    ${theme.flex('column', 'flex-start', 'flex-end', 100)}

    .swiper-wrapper {
      min-height: 0;

      .swiper-slide {
        ${theme.flex('column', 'center', 'center')};
        width: 100%;
        overflow: hidden;
        border-radius: 2px;

        img {
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
`;

import { TabDataElementType } from 'components/Tabs';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { ReactNode, useEffect, useState } from 'react';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import FilterDrawer from 'sharedComponents/Filters/FilterDrawer';
import FilterList from 'sharedComponents/Filters/FilterList';
import { FilterOptionsProps, FilterOptionsType, FilterType, ParamsType } from 'sharedComponents/Filters/types';
import { convertTabs } from 'sharedComponents/Filters/utils';

import { moreDetailSchedulePermissionsMap } from '../constant';
import ScheduleWholeFilterPopup from './ScheduleWholeFilterPopup';

/**
 * NOTE
 * sharedComponents에 있는 Filter 컴포넌트와 완전 일치.
 * 하지만 필터를 바꿀 때마다 발생하는 클릭이벤트에서 토스트 메세지를 띄어 주어야 해서
 * 공통 필터 컴포넌트인 Filter에 props를 추가하는 것보다 중복 코드가 발생하더라도
 * 더보기 > 일정용 필터를 만드는 것을 선택
 */
type Props<T extends ParamsType> = {
  filterAtom: RecoilState<T>;
  filters: FilterType[];
  defaultFilter?: T; // atom 생성시 default에 넣은 객체(기본값 빈객체 {} 일 경우에는 안넣어도 됨)
  defaultTabLabels: { [key: string]: string };
  renderDrawerOptions: (props: FilterOptionsProps) => ReactNode;
  renderWholeFilterPopupOptions: (props: FilterOptionsProps) => ReactNode;
};

const ScheduleCommonFilter = <T extends ParamsType>({
  filterAtom,
  filters,
  defaultTabLabels,
  defaultFilter,
  renderDrawerOptions,
  renderWholeFilterPopupOptions,
}: Props<T>) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0;
  const { data: staffs } = useGetFilterStaffs();
  const { setPopup } = usePopup();
  const { setToast } = useToast();
  const { everyPermissions } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  // 리스트에 반영되는 필터, 필터 적용버튼 누르면 변경됨
  const [selectedFilter, setSelectedFilter] = useRecoilState(filterAtom);

  // 필터 UI용 상태 (개별 필터 Drawer에서 변경된 내역)
  const [filterChanged, setFilterChanged] = useState(selectedFilter);

  const [openedDrawer, setOpenedDrawer] = useState<TabDataElementType>();

  // 현재 스태프 정보 찾기
  const currentStaff = staffs?.find(staff => staff.id === currentStaffId);

  const isStaffArray = (staffs: unknown): staffs is Array<{ id: number | string }> => {
    return Array.isArray(staffs) && staffs.every(staff => staff && 'id' in staff);
  };

  // 권한 체크를 위한 함수
  const getPermissionsForFilter = (filterValues: T) => {
    if (!('type' in filterValues)) {
      return [moreDetailSchedulePermissionsMap['group'], moreDetailSchedulePermissionsMap['private']];
    } else if (filterValues.type === 'G') {
      return [moreDetailSchedulePermissionsMap['group']];
    } else {
      return [moreDetailSchedulePermissionsMap['private']];
    }
  };
  const saveChangedFilter = async () => {
    const futurePermissions = getPermissionsForFilter(filterChanged);
    const hasPermissionForChanges = everyPermissions(futurePermissions);

    // 선택된 강사가 본인만 있는지 체크
    const isOnlyCurrentStaffSelected =
      isStaffArray(filterChanged.staffs) && filterChanged.staffs.length === 1 && filterChanged.staffs[0].id === currentStaffId;

    // 본인만 선택된 경우 권한 체크 없이 바로 적용
    if (isOnlyCurrentStaffSelected) {
      setSelectedFilter(filterChanged);
      setToast({
        type: 'success',
        message: '필터가 적용되었습니다.',
        bottom: 68,
      });
      setOpenedDrawer(undefined);
      return;
    }

    // 다른 강사가 포함된 경우의 처리
    const hasOtherStaffs = isStaffArray(filterChanged.staffs) && filterChanged.staffs.some(staff => staff.id !== currentStaffId);

    try {
      // 타입에 따른 권한 체크
      const permissionToCheck = (() => {
        if (filterChanged.type === 'G') {
          // 그룹 타입인 경우
          return [moreDetailSchedulePermissionsMap['group'].id];
        } else if (filterChanged.type === 'P') {
          // 개인 타입인 경우
          return [moreDetailSchedulePermissionsMap['private'].id];
        }
        return [moreDetailSchedulePermissionsMap['group'].id, moreDetailSchedulePermissionsMap['private'].id];
      })();

      // 실시간 권한 체크
      await checkPermission(permissionToCheck, {
        onError: error => {
          throw error;
        },
      });

      // 실시간 권한 체크 성공
      if (hasOtherStaffs) {
        if (hasPermissionForChanges) {
          // Case : 다른 강사 선택 O, 현재 권한 O
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        } else {
          // Case : 다른 강사 선택 O, 현재 권한 X (but 실시간 권한 체크 성공)
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        }
      } else {
        if (hasPermissionForChanges) {
          // Case: 다른 강사 선택 X, 현재 권한 O
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        } else {
          // Case: 다른 강사 선택 X, 현재 권한 X (but 실시간 권한 체크 성공)
          setToast({
            type: 'success',
            message: '필터가 적용되었습니다.',
            bottom: 68,
          });
        }
      }

      setSelectedFilter(filterChanged);
    } catch (error) {
      // 실시간 권한 체크 실패
      if (hasOtherStaffs) {
        setToast({
          type: 'error',
          message: '다른 강사 조회 권한이 없습니다.',
          bottom: 68,
        });
      } else {
        setToast({
          type: 'error',
          message: '다른 강사 조회 권한이 없습니다.',
          bottom: 68,
        });
      }
      return;
    }

    setOpenedDrawer(undefined);
  };

  const closeDrawerWithReset = () => {
    setFilterChanged(selectedFilter);
    setSelectedFilter(selectedFilter);
    setOpenedDrawer(undefined);
  };

  const openDrawer = (tab: TabDataElementType) => {
    if (isStudioSelected) {
      setOpenedDrawer(tab);
    } else {
      setOpenedDrawer(undefined);
    }
  };

  const openWholeDrawer = () => {
    if (isStudioSelected) {
      setPopup(
        <ScheduleWholeFilterPopup
          filterAtom={filterAtom}
          filters={filters}
          defaultFilter={defaultFilter}
          renderWholeFilterPopupOptions={renderWholeFilterPopupOptions}
        />,
      );
    } else {
      setPopup(null);
    }
  };

  const changeOption = (option: FilterOptionsType, key: string) => {
    setFilterChanged({ ...filterChanged, [key]: option.value });
  };

  const resetSelectedFilter = () => {
    const openedFilter = openedDrawer?.value || '';
    const defaultValue = defaultFilter?.[openedFilter];
    const futurePermissions = getPermissionsForFilter(filterChanged);
    const hasPermissionForChanges = everyPermissions(futurePermissions);

    // 권한이 없을 경우 현재 강사 정보로 초기화
    const resetValue = hasPermissionForChanges
      ? defaultValue
      : openedFilter === 'staffs' && currentStaff
        ? [
            {
              id: currentStaff.id,
              name: currentStaff.name,
              imgUrl: currentStaff.avatar,
            },
          ]
        : defaultValue;

    // 상태 업데이트
    const updatedFilter = {
      ...filterChanged,
      [openedFilter]: resetValue,
    };

    setFilterChanged(updatedFilter);
    setSelectedFilter(updatedFilter);

    setToast({
      type: 'success',
      message: '필터가 초기화되었습니다.',
      bottom: 68,
    });
  };

  // 필터 변경사항 카운트
  const countChanges = (changes: ParamsType) =>
    Object.entries(changes)
      .filter(([key, value]) => !key.includes('sort')) // sort 관련 필터는 제외
      .filter(([key, value]) => !key.includes('register_type')) // register_type'관련 필터는 제외
      .filter(([key, value]) => value).length; // 기본값(undefined, null)인 경우 카운트 제외

  useEffect(() => {
    setFilterChanged(selectedFilter);
  }, [selectedFilter]);

  return (
    <>
      <FilterList
        selectedCount={countChanges(selectedFilter)}
        filterTabs={convertTabs(filters, selectedFilter, defaultTabLabels)}
        filterValues={selectedFilter}
        onOpenWholeFilters={openWholeDrawer}
        onOpenSelectedDrawerFilter={tab => openDrawer(tab)}
      />
      <FilterDrawer
        isOpen={!!openedDrawer}
        onClose={closeDrawerWithReset}
        currentFilterTab={openedDrawer || { value: '', label: '' }}
        onReset={resetSelectedFilter}
        onSave={saveChangedFilter}
        onChange={tab => openDrawer(tab)}
        onOpenWholeFilters={openWholeDrawer}
        filterValues={selectedFilter}
        filterTabs={convertTabs(filters, selectedFilter, defaultTabLabels)}
        selectedCount={countChanges(selectedFilter)}>
        {renderDrawerOptions({ filters, filterChanged, changeOption, currentFilterTab: openedDrawer })}
      </FilterDrawer>
    </>
  );
};

export default ScheduleCommonFilter;

import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { HISTORY_SORTING_DATA } from 'pages/MemberDetail/constants';
import { HistoryFilterFormType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import SortButton from 'sharedComponents/SortButton';

const Sorting = () => {
  const { control } = useFormContext<HistoryFilterFormType>();
  const sort = useWatch({ control, name: 'sort' });

  const [isOpen, setIsOpen] = useState(false);
  const label = HISTORY_SORTING_DATA.find(({ value }) => value === sort)?.label;

  return (
    <>
      <SortButton onClick={() => setIsOpen(true)}>{label}</SortButton>
      <CheckRadioDrawer
        name="sort"
        control={control}
        data={HISTORY_SORTING_DATA}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default Sorting;

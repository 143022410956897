import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';

type Props = {
  loadMore: () => void;
  hasMore: boolean;
  isLoading?: boolean;
  loader?: React.ReactNode;
  showMoreButton?: boolean;
  moreButtonColor?: 'gray5' | 'gray7';
  gap?: number;
  className?: string;
};

const InfiniteScroll = ({
  className,
  children,
  hasMore,
  isLoading,
  loader,
  loadMore,
  showMoreButton,
  moreButtonColor,
  gap,
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  const onIntersect: IntersectionObserverCallback = useCallback(
    ([entry]) => {
      if (isLoading) return;

      if (entry.isIntersecting && hasMore) {
        loadMore();
      }
    },
    [hasMore, isLoading, loadMore],
  );

  useEffect(() => {
    if (showMoreButton) return;

    const targetRef = ref.current;
    if (!targetRef) return;

    const observer = new IntersectionObserver(onIntersect, { rootMargin: '100px' });

    observer.observe(targetRef);

    return () => observer && observer.disconnect();
  }, [hasMore, isLoading, showMoreButton, loadMore, onIntersect]);

  return (
    <Container className={`infinite-scroll ${className}`} gap={gap} moreButtonColor={moreButtonColor}>
      {children}

      {!isLoading && hasMore && showMoreButton && (
        <Button
          className="more-button"
          fullWidth
          size="medium40"
          edge="circular"
          fontSize={13}
          fontWeight={600}
          textColor="gray2"
          rightIcon={<Icon name="arrowBottomBold" size={16} color="gray3" />}
          onClick={() => loadMore()}>
          더보기
        </Button>
      )}

      {isLoading && !!loader && loader}

      <Target className="infinite-scroll-observer-target" ref={ref} />
    </Container>
  );
};

export default InfiniteScroll;

const Container = styled.div<Pick<Props, 'gap' | 'moreButtonColor'>>(
  /** 공통 스타일 */
  ({ gap, moreButtonColor = 'gray5' }) => css`
    ${theme.flex('column', '', '', gap)}
    flex: 1;

    .more-button {
      background-color: ${theme.color[moreButtonColor]};
      gap: 2px;
    }
  `,
);

const Target = styled.div`
  padding: 1px 0;
  visibility: visible;
  display: block;
`;

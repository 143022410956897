import _ from 'lodash';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';
import filters from 'utils/filters';

export const getSchedulesParam = (schedules: ScheduleFormType[]) => {
  return schedules.flatMap(({ isClosed, type, day_of_week, start_time, end_time }, __, list) => {
    // 휴무일 관련 데이터는 아예 전송하지 않아야함
    if (isClosed) return [];
    if (type === 'restTime') {
      const filteredList = list.filter(item => !!item.isClosed);
      // 남아있는 데이터 중, 해당 요일의 workTime이 없다면 없는 요일의 restTime 삭제
      const isCheckApplyDay = _.findIndex(filteredList, { day_of_week }) > -1;
      if (isCheckApplyDay) return [];
    }
    return {
      type,
      date: null,
      day_of_week,
      start_time,
      end_time,
    };
  });
};

export const getIsPeriodCheck = (schedules: Record<'workTimes' | 'restTimes', ScheduleFormType[]>) => {
  const { workTimes, restTimes } = schedules;

  /** 휴식 시간이 있을 때만 근무 시간이 벗어나는지 검증 */
  let isPeriodCheck = true;
  if (!restTimes.length) return true;

  workTimes.forEach(workTime => {
    /** 휴무일 일 때는 검증 하지 않음 */
    if (workTime.isClosed) return;

    restTimes.forEach(restTime => {
      if (workTime.day_of_week === restTime.day_of_week) {
        const { timeToDayjs } = filters;
        /** 시간 문자열을 dayjs 객체로 변경 */
        const currentRest = [timeToDayjs(restTime.start_time), timeToDayjs(restTime.end_time)];
        const currentWork = [timeToDayjs(workTime.start_time), timeToDayjs(workTime.end_time)];

        const isPass = currentRest[0].isSameOrAfter(currentWork[0]) && currentRest[1].isSameOrBefore(currentWork[1]);

        if (!isPass) {
          isPeriodCheck = false;
          return;
        }
      }
    });
  });

  return isPeriodCheck;
};

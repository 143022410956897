import usePermission from 'hooks/usePermission';
import usePolicy from 'hooks/usePolicy';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { PermissionType } from 'types/permissionTypes';
import { PolicyType } from 'types/policyTypes';

type Props = {
  renderEnabled: ReactNode;
  renderDisabled: ReactNode;
  permissions: PermissionType[];
  policies: (keyof PolicyType)[];
};

const AccessControl = ({ permissions, policies, renderEnabled, renderDisabled }: Props) => {
  const { everyPolicies } = usePolicy();
  const { somePermissions } = usePermission();

  const policyActivated = isEmpty(policies) || everyPolicies(policies);
  const permissionAllowed = isEmpty(permissions) || somePermissions(permissions);

  return policyActivated && permissionAllowed ? renderEnabled : renderDisabled;
};

export default AccessControl;

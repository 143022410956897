import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import { BookingType } from 'pages/Booking/types';
import { useMatch } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import CheckPermissionSelectStaff from './CheckPermissionSelectStaff';
import FallbackStaffField from './FallbackStaffField';

type Props = {
  /** 수업 수정 폼에서 보여줄 수업 담당 강사 */
  lectureStaff?: LectureDetailResponse['staff'];
};

const SelectStaff = ({ lectureStaff }: Props) => {
  const match = useMatch('/booking/:bookingType/form/:mode/:id?');
  const bookingType = match?.params.bookingType as BookingType;
  const pageMode = match?.params.mode === 'create' ? 'create' : 'update';

  return (
    <ApiBoundary fallback={<FallbackStaffField />}>
      <CheckPermissionSelectStaff bookingType={bookingType} pageMode={pageMode} lectureStaff={lectureStaff} />
    </ApiBoundary>
  );
};

export default SelectStaff;

import styled from '@emotion/styled';
import SubTitle from 'components/SubTitle';
import { TicketsParams } from 'hooks/service/queries/useInfinityTickets';
import { PRODUCT_TYPE_TEXT, SELECT_TICKET_TEXT } from 'pages/ProductForm/constants';

type Props = {
  productType: TicketsParams['product_type'];
};

const Title = ({ productType }: Props) => {
  return (
    <Container>
      <SubTitle
        title={`${PRODUCT_TYPE_TEXT[productType]} 선택`}
        message={{ position: 'bottom', text: SELECT_TICKET_TEXT[productType].subTitle }}
      />
    </Container>
  );
};

export default Title;

const Container = styled.div`
  position: relative;
  padding: 8px 20px 0;
`;

type Params1 = {
  userTicketIds: Array<number>;
  prevUserTicketIds: Array<number>;
};
/** 기존 선택된 회원과 달라진 점 있는 지 비교하여 뒤로가기 했을 때 dialog 노출 여부 */
export const isCompareArrayDifferent = ({ userTicketIds, prevUserTicketIds }: Params1) => {
  if (userTicketIds.length !== prevUserTicketIds.length) {
    return true;
  }

  const sortedIds = [...userTicketIds].sort();
  const sortedTempIds = [...prevUserTicketIds].sort();

  for (let i = 0; i < sortedIds.length; i++) {
    if (sortedIds[i] !== sortedTempIds[i]) {
      return true;
    }
  }

  // 모든 요소가 같음
  return false;
};

type Params2 = {
  userTicketIds: Array<number>;
  originUserTicketIds: Array<number>;
};
/**
 * 새로 선택한 수강권이 생성 때 선택한 수강권 아이디에 포함되어 있으면
 * 주/월간 초과검증 할 필요 없어서 패스할지 말지 여부
 */
export const containsExactElements = ({ userTicketIds, originUserTicketIds }: Params2) => {
  const originSet = new Set(originUserTicketIds);
  const realSet = new Set(userTicketIds);

  if (originSet.size !== realSet.size) return false;

  for (const element of originSet) {
    if (!realSet.has(element)) {
      return false;
    }
  }
  return true;
};

/**
 * 주/월간 초과 검증 요청 보낼 수강권 아이디 배열
 * - 수업 생성: 선택한 아이디 모두
 * - 이후 모든 수정/복사: 생성 때 선택된 아이디 제외한 선택한 아이디
 */
export const getIds = ({ userTicketIds, originUserTicketIds }: Params2) => {
  const resultArray = userTicketIds.filter(id => !originUserTicketIds.includes(id));

  // 수업 생성
  if (resultArray.length === originUserTicketIds.length) {
    return userTicketIds;
  }

  // 이후 모든 수정/복사
  return resultArray.length ? resultArray : originUserTicketIds;
};

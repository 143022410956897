import { useIsMutating } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { BookingType } from '../types';
import { getNumberUserTicketId } from '../utils/bookings';
import { BookingFormType } from './types';

type Props = {
  bookingType: BookingType;
};

const SubmitButton = ({ bookingType }: Props) => {
  const {
    watch,
    formState: { dirtyFields },
  } = useFormContext<BookingFormType>();
  const isMutating = useIsMutating() > 0;
  const isDirty = !!Object.keys(dirtyFields).length;
  const { overlapTickets, selectedUserTickets } = watch();
  const isExitingOverlap =
    !!overlapTickets && selectedUserTickets.some(({ id }) => overlapTickets.includes(getNumberUserTicketId(id)));

  const disabled = useMemo(() => {
    if (isMutating || !isDirty || isExitingOverlap) return true;
    if (bookingType === 'private' && !selectedUserTickets.length) return true;
    return false;
  }, [bookingType, isDirty, isExitingOverlap, isMutating, selectedUserTickets.length]);

  return (
    <FooterButton type="submit" disabled={disabled}>
      변경 완료
    </FooterButton>
  );
};

export default SubmitButton;

import dayjs from 'dayjs';
import { ref, string } from 'yup';

export const mobile = string()
  .test('only numbers', '숫자만 입력해 주세요.', value => typeof value === 'string' && !/\D/.test(value))
  .matches(/^$|^01[0,1,6,7,8,9](\d{3,4}){2}$/, `올바른 휴대폰 번호를 입력해 주세요.`); // '01'로 시작하고 세그먼트별로 숫자 3~4자리 제한

export const mobileRequired = mobile.required('휴대폰 번호를 입력해 주세요.');

export const password = string()
  .required('비밀번호를 입력해 주세요.')
  .matches(
    /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?!.*[\uD800-\uDFFF\uD83C-\uDBFF\uDC00-\uDFFF])(?!.*\s).{8,32}$/u,
    '영문, 숫자를 포함한 8~32자로 입력해 주세요.',
  );

export const passwordConfirmation = string()
  .required('비밀번호를 확인해 주세요.')
  .oneOf([ref('password')], '비밀번호가 일치하지 않습니다.');

export const email = string().matches(
  /^$|^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,3}$/,
  '이메일 양식을 확인해 주세요.',
);

export const confirmedEmail = string()
  .required('이메일을 확인해 주세요.')
  .oneOf([ref('email')], '이메일이 일치하지 않습니다.');

export const money = string().matches(/^$|^[0-9,]+$/, '숫자만 입력해 주세요.');

export const domain = string()
  .required('서브 도메인 주소를 입력해 주세요.')
  .matches(/^$|^[a-z|A-Z|0-9-|\s]+$/, '영문, 숫자만 입력해 주세요.');

export const name = string()
  .test('check', '한글, 영문, 숫자, 특수문자만 입력해 주세요.', value => (value && /[\u3131-\u314E]/g.test(value) ? false : true))
  .max(20, '최대 20자 이내로 입력해 주세요.');
export const teacherNameRequired = name.required('강사명을 입력해 주세요.');

export const userName = string()
  .test('check', '한글, 영문, 숫자, 특수문자만 입력해 주세요.', value => (value && /[\u3131-\u314E]/g.test(value) ? false : true))
  .max(20, '고객명은 최대 20자 이내로 입력해 주세요.');

export const nickname = string()
  .test('space check', '한글, 영문, 숫자만 입력해 주세요.', value =>
    value && /[\u3131-\u314E\s\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\\\=\\(\\'\\"]/g.test(value) ? false : true,
  )
  .max(20, '최대 20자 이내로 입력해 주세요.');

export const nickNameRequired = nickname.required('닉네임을 입력해 주세요.');

/** 회원명 한글, 영문, 숫자, 띄어쓰기 가능 */
export const memberName = string()
  .matches(/^[가-힣a-zA-Z0-9\s]+$/, '한글, 영문, 숫자만 입력해 주세요.')
  .max(20, '최대 20자 이내로 입력해 주세요.');
export const memberNameRequired = memberName.required('회원명을 입력해 주세요.');

export const mobileConfirmCode = string().matches(/^$|^[0-9]+$/, '숫자만 입력해 주세요.');

export const birth = string().required('생년월일을 입력해 주세요.');

/**
 * ex: 회원가입은 픽커가 아닌 일반 텍스트 필드 사용
 * 생년월일 텍스트는 월 숫자에 따라 검증이 달라져야 해서 javascript 사용
 */
export const birthNumber = string()
  .required('생년월일을 입력해 주세요.')
  .test('valid-date', '유효한 날짜가 아닙니다.', value => {
    // 연도(YY), 월(MM), 일(DD)
    const year = parseInt(value.slice(0, 2), 10);
    const month = parseInt(value.slice(2, 4), 10);
    const day = parseInt(value.slice(4, 6), 10);

    // 월 검증 (1~12)
    if (month < 1 || month > 12) return false;

    // dayjs를 사용해 유효한 날짜인지 검증
    const fullYear = 2000 + year; // 2000년대로 간주, 임시값
    const date = dayjs(`${fullYear}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
    return date.isValid() && date.date() === day;
  })
  .max(6, '생년월일을 확인해 주세요.');

export const ticketInactiveReason = string()
  .matches(/^$|^[가-힣ㄱ-ㅎㅏ-ㅣ|a-z|A-Z|0-9|\s]+$/, '한글, 영문, 숫자만 입력해 주세요.')
  .max(20, '최대 20자 이내로 입력해 주세요.');

export const maximumLength = (length: number) => string().max(length, `최대 ${length}자 이내로 입력해 주세요.`);

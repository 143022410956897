import useGetRoom from 'hooks/service/queries/useGetRoom';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import FormFieldDisabled from 'sharedComponents/FormField/FormFieldDisabled';

import RoomSetting from './RoomSetting';

/** 룸 설정 기능을 사용하는 시설인지 아닌지 판단 */
const CheckRoomUsable = () => {
  const { setValue } = useFormContext<BookingCommonFormType>();
  const currentStudioId = useRecoilValue(studioIdAtom);
  const {
    data: { policy },
  } = useGetStudioInfo(currentStudioId);

  const isEnabled = !!policy.is_use_rooms;
  const { data } = useGetRoom({ isEnabled });

  useEffect(() => {
    if (!isEnabled) {
      setValue('selectedRoom', { id: -1, name: BOOKING_COMMON_FORM_TEXT.room.notSelected.label });
    }
  }, [isEnabled, setValue]);

  if (!isEnabled || !data) {
    return <FormFieldDisabled iconName="room">룸 설정 안함</FormFieldDisabled>;
  }
  return <RoomSetting roomData={data} isUseRooms={isEnabled} />;
};

export default CheckRoomUsable;

import FoldingFilterTabs from 'components/Tabs/FoldingFilterTabs';
import useTab from 'hooks/useTab';

const CountTabSkeleton = () => {
  const tabData = [
    { value: 'all', label: '전체 0' },
    { value: 'booked_list', label: '예약 0' },
    { value: 'attendance', label: '출석 0' },
    { value: 'absence', label: '결석 0' },
    { value: 'noshow', label: '노쇼 0' },
    { value: 'cancel', label: '취소 0' },
  ];
  const { onChange } = useTab(tabData);
  return <FoldingFilterTabs data={tabData} value={'all'} onChange={onChange} />;
};

export default CountTabSkeleton;

import useGetLectureCourse from 'hooks/service/queries/useGetLectureCourse';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import { convertBookingGroupUpdateCommonFormState } from 'pages/Booking/utils';

import { BookingGroupUpdateFormType } from '../../types';
import GroupFormMainLayout from '../components/GroupFormMainLayout';
import GroupUpdateForm from './GroupUpdateForm';

type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: courseData } = useGetLectureCourse(lectureData.course_id);

  const methods = useCustomBookingForm<BookingGroupUpdateFormType>({
    defaultValues: {
      ...convertBookingGroupUpdateCommonFormState(lectureData),
      currentLecture: lectureData,
      description: courseData.description ?? undefined,
      ruleType: {
        booking: courseData.group_booking_rule_type,
        bookingCancel: courseData.group_booking_cancel_rule_type,
      },
      lectureDate: lectureData.start_on,
      lectureEndAt: lectureData.end_on,
      booking_start_at: lectureData.booking_start_at ?? undefined,
      booking_end_at: lectureData.booking_end_at ?? undefined,
      booking_cancel_start_at: lectureData.booking_cancel_start_at ?? undefined,
      booking_cancel_end_at: lectureData.booking_cancel_end_at ?? undefined,
      enter_start_at: lectureData.policy.is_enter ? lectureData.enter_start_at : undefined,
      enter_end_at: lectureData.policy.is_enter ? lectureData.enter_end_at : undefined,
      autoclose_at: lectureData.autoclose_at ?? undefined,
      daily_change_booking_end_at: lectureData.daily_change_booking_end_at ?? undefined,
      booking_auto_shift_available_minutes_from_start: lectureData.booking_auto_shift_available_minutes_from_start ?? undefined,
    },
  });

  return (
    <GroupFormMainLayout pageMode="update" {...methods}>
      <GroupUpdateForm currentLecture={lectureData} />
    </GroupFormMainLayout>
  );
};

export default FormMain;

import { useLocation } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import AppPushForm from './components/AppPushForm';
import { APP_PUSH_FORM_TEXT } from './constants';
import { AppPushFormType } from './types';

const MoreDetailsMessageAppPushForm = () => {
  const { state } = useLocation() as { state?: AppPushFormType };

  const mode = state?.mode || 'create';

  const title = `${APP_PUSH_FORM_TEXT.title}${mode === 'update' ? APP_PUSH_FORM_TEXT.titleEditSuffix : ''}`;
  return (
    <MainLayout header={{ title }}>
      <ApiBoundary>
        <AppPushForm defaultValues={state} />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsMessageAppPushForm;

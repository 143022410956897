import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import filters from 'utils/filters';

type Props = {
  paymentSummary: {
    totalAmount: string | number;
    unpaidAmount: string | number;
  };
};

const PaymentSummary = ({ paymentSummary }: Props) => {
  return (
    <Container>
      <div className="total">
        <Typography size={13} textColor="gray2">
          총 결제금액
        </Typography>
        <Typography size={24} weight={700}>
          {filters.numberComma(paymentSummary.totalAmount)}원
        </Typography>
      </div>
      <div className="unpaid">
        <Typography size={13}>미수금</Typography>
        <Typography size={13} weight={600} textColor="secondary3">
          {filters.numberComma(paymentSummary.unpaidAmount)}원
        </Typography>
      </div>
    </Container>
  );
};

export default PaymentSummary;

const Container = styled.div`
  ${theme.flex('', 'flex-end', 'space-between')};
  padding: 24px 20px 20px;

  .total {
    ${theme.flex('column', '', '', 4)};
  }

  .unpaid {
    ${theme.flex('', '', '', 8)};
    margin-bottom: 2px;
    padding: 5px 8px;
    border-radius: 4px;
    border: 1px solid ${theme.color.gray5};
  }
`;

import styled from '@emotion/styled';
import profileNone from 'assets/images/profile_none.webp';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useNavigate } from 'react-router-dom';

const ContentWrapper = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <img className="profile-none-image" src={profileNone} alt="profile_none" />

      <Typography className="title" size={19} weight={700}>
        이용중인 시설에
        <br />
        &apos;강사 등록&apos;을 요청해 주세요!
      </Typography>

      <Typography className="sub-title" size={15} textColor="gray2">
        시설에서 등록한 강사님의 휴대폰 번호와
        <br />
        관리자 앱 가입 시 입력한 휴대폰 번호가
        <br />
        일치해야 앱을 사용할 수 있어요.
      </Typography>

      <Button
        className="refresh-but  ton"
        size="medium48"
        widthSize={160}
        variant="outlined"
        leftIcon={<Icon className="refresh-icon" name="resetBold" size={16} fillColor={theme.color.gray2} />}
        onClick={() => {
          navigate('/select-studio');
        }}>
        새로고침
      </Button>
    </Container>
  );
};

export default ContentWrapper;

const Container = styled.section`
  ${theme.flex('column')};
  text-align: center;

  .profile-none-image {
    margin-bottom: 24px;
    width: 80px;
  }

  .title {
    margin-bottom: 14px;
  }

  .sub-title {
    margin-bottom: 32px;
  }

  .refresh-button {
    gap: 2px;
  }
`;

import styled from '@emotion/styled';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useInfinityTickets from 'hooks/service/queries/useInfinityTickets';
import { useEffect, useMemo, useState } from 'react';
import ButtonWithRightIconOutlined from 'sharedComponents/ButtonWithRightIconOutlined';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import { ReportCommonParamType } from '../type';
import ReportMultiOptionDrawer from './ReportMultiTicketOptionDrawer';

const MultiTicketFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const prevSelectedTickets = selectedOption as ReportCommonParamType['tickets'];
  const [isOpen, setIsOpen] = useState(false);
  const [ticketOptions, setTicketOptions] = useState<FilterOptionsType[]>(currentFilter.options);

  const {
    data: tickets,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfinityTickets({
    product_type: 'userTicket',
    ticket_type: 'A',
    item_type: null,
  });

  const label = useMemo(() => {
    if (prevSelectedTickets?.length === tickets.length || !prevSelectedTickets?.length) {
      return '전체';
    } else {
      return prevSelectedTickets.length === 1
        ? prevSelectedTickets[0].title
        : `${prevSelectedTickets[0].title} 외 ${prevSelectedTickets.length - 1}개`;
    }
  }, [prevSelectedTickets, tickets.length]);

  const onClose = () => {
    setIsOpen(false);
  };

  // currentFilter.option 타입 가드
  const isStaffOption = (option: any): option is { value: { id: number } } => {
    return option?.value && typeof option.value.id === 'number';
  };

  useEffect(() => {
    const convertedOptions = tickets.map(ticket => ({
      value: {
        id: ticket.id,
        title: ticket.title,
      },
      label: ticket.title,
    }));

    // 새로운 배열을 만들어 중복된 값을 제거
    const newOptions = [...ticketOptions, ...convertedOptions].filter(
      (option, index, self) =>
        isStaffOption(option) && index === self.findIndex(o => isStaffOption(o) && o.value.id === option.value.id),
    );

    setTicketOptions(newOptions);
  }, [tickets]);
  return (
    <Container>
      <Typography size={15} weight={700}>
        수강권
      </Typography>
      <StaffSelectButton icon={<Icon name="arrowBottom" color="gray2" />} onClick={() => setIsOpen(true)}>
        {label}
      </StaffSelectButton>
      <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
        <ReportMultiOptionDrawer
          selectedOption={selectedOption}
          isOpen={isOpen}
          multiSelectedOptions={ticketOptions}
          onClose={onClose}
          onSave={changeOptions}
        />
      </InfiniteScroll>
    </Container>
  );
};

export default MultiTicketFilter;

const Container = styled.div`
  padding: 20px 20px 24px;

  .select-drawer {
    margin-top: 16px;
  }
`;

const StaffSelectButton = styled(ButtonWithRightIconOutlined)`
  margin-top: 12px;
`;

import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import OutlinedTextField from 'components/TextField/components/OutlinedTextField';
import { OutlinedTextFieldType } from 'components/TextField/OutlinedTextFieldController';
import React, { useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';

type Props<T extends FieldValues> = Omit<OutlinedTextFieldType<T>, 'type' | 'suffix'>;

/** OutlinedTextFieldController에 show버튼만 추가 */
const PasswordFieldController = <T extends FieldValues>({ controllerProps, ...inputProps }: Props<T>) => {
  const { field, fieldState } = useController(controllerProps);
  const [isShow, setIsShow] = useState(false);

  return (
    <OutlinedTextField
      id={field.name}
      errorMessage={fieldState.error?.message}
      type={isShow ? 'text' : 'password'}
      suffixMarginRight={16}
      suffix={
        <IconButton onClick={() => setIsShow(!isShow)}>
          <Icon name={isShow ? 'showOff' : 'showOn'} fillColor={theme.color.gray3} />
        </IconButton>
      }
      {...field}
      {...inputProps}
      onChange={e => {
        field.onChange(e);
        inputProps.onChange?.(e);
      }}
    />
  );
};

export default PasswordFieldController;

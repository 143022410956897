import styled from '@emotion/styled';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import usePopup from 'hooks/usePopup';
import { MEMBER_FORM_TEXT } from 'pages/MemberForm/constants';
import { MemberFormType } from 'pages/MemberForm/types';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

import FindAddress from './FindAddress';

const Address = () => {
  const { control, setValue } = useFormContext<MemberFormType>();
  const address = useWatch({ control, name: 'address' });
  const { setPopup } = usePopup();

  const saveAddress = (data: string) => {
    setValue('address.address', data, { shouldDirty: true });
  };

  return (
    <Container>
      <FormFieldLinkButton
        iconName="location"
        hasValue={!!address?.address}
        onClick={() => setPopup(<FindAddress saveAddress={saveAddress} />)}>
        {address?.address || '주소'}
      </FormFieldLinkButton>
      <UnderlinedTextareaController
        controllerProps={{ control, name: 'address.detail_address' }}
        placeholder={MEMBER_FORM_TEXT.address.placeholder}
        onClear={() => setValue('address.detail_address', '', { shouldDirty: true })}
      />
    </Container>
  );
};

export default Address;

const Container = styled.div`
  .underline-textarea {
    padding-left: 44px;
  }
`;

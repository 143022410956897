import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import SudamateDetail from './components/SudamateDetail';

const LoungeDetail = () => {
  const navigate = useNavigate();

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerBack" />
          </IconButton>
        ),
      }}>
      <ApiBoundary>
        <SudamateDetail />
      </ApiBoundary>
    </MainLayout>
  );
};

export default LoungeDetail;

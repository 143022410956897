import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 회원 상세 > 이용내역 > 예약 상태 필터 탭 세팅용 count */
const useGetHistoryCount = (params: HistoryCountParams) => {
  const { queryFn } = useQueryFnWithCommonParams<HistoryCountResponse, HistoryCountParams>({
    url: '/api/history/count',
    params,
  });

  return useSuspenseQuery({
    queryKey: ['booking', 'history', 'count', { ...params }],
    queryFn,
    select: data => data.data,
  });
};

export default useGetHistoryCount;

export type HistoryCountResponse = {
  total: number;
  booked: number;
  attendance: number;
  absence: number;
  noshow: number;
  cancel: number;
};

export type HistoryCountParams = {
  member_id: number;
  user_ticket_id?: number;
};

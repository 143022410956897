import styled from '@emotion/styled';
import { Datepicker, localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';

type Props = MbscDatepickerOptions;

const TimePicker = ({ ...props }: Props) => {
  return (
    <Container className="time-picker">
      <Datepicker
        theme="material"
        locale={localeKo}
        display="inline"
        controls={['time']}
        touchUi={true}
        rows={3}
        timeFormat="HH:mm"
        itemHeight={32}
        minWheelWidth={32}
        {...props}
      />
    </Container>
  );
};

export default TimePicker;

const Container = styled(CommonWheelStyles)`
  .mbsc-scroller-wheel-group {
    gap: 40px;
  }

  /** 시간과 분 사이 콜론(:) */
  .mbsc-scroller-wheel-line::before {
    content: ':';
    color: #aaabaf;
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 1px));
  }
`;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

/** 회원별 보유 수강권/상품 리스트 */
const useInfinityHistoryTickets = (params: HistoryTicketParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<HistoryTicketResponse, HistoryTicketParams>({
    url: `/api/history/tickets`,
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket-history', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityHistoryTickets;

export type HistoryTicketResponse = {
  id: number;
  status: string;
  title: string;
};

export type HistoryTicketParams = {
  member_id: number;
};

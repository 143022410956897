import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { messageTargetMemberSelectedFilter } from 'recoil/message';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';

import { MessageTargetFormType, MessageType } from '../../types';
import { MESSAGE_TARGET_IDENTIFIER, MESSAGE_TARGET_TEXT } from '../constants';
import CompleteButton from './components/CompleteButton';
import MemberList from './components/MemberList';
import StickyHeader from './components/StickyHeader';
import TargetMemberSkeletonList from './components/TargetMemberSkeletonList';
import { convertMessageFilterToMemberFilter } from './utils';

type Props = {
  goToForm: (updater?: (state: MessageType) => MessageType) => void;
};

const TargetMember = ({ goToForm }: Props) => {
  const state = useLocation().state as MessageType | undefined;
  const methods = useForm<MessageTargetFormType>({
    defaultValues: {
      selected: state?.users || [],
      isAllSelected: false,
    },
  });

  const setSelectedFilter = useSetRecoilState(messageTargetMemberSelectedFilter);

  useEffect(() => {
    if (!state?.filter) return;

    // 발송당시 검색필터 적용, 검색어 제외
    const { keyword, ...filter } = state.filter;
    setSelectedFilter(convertMessageFilterToMemberFilter(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { hasPermission } = usePermission();
  const assignedOnly = !hasPermission(PERMISSION.member.view);

  return (
    <MainLayout
      header={{
        title: '회원 목록',
        noUnderline: true,
        leftButton: (
          <IconButton onClick={() => goToForm()}>
            <Icon name="headerBack" />
          </IconButton>
        ),
        rightButton: <HeaderSearchButton identifier={MESSAGE_TARGET_IDENTIFIER} placeholder={MESSAGE_TARGET_TEXT.placeholder} />,
        bgColor: 'white',
      }}
      backgroundColor="gray6">
      <FormProvider {...methods}>
        <StickyHeader messageType={state?.type ?? 'mobile'} assignedOnly={assignedOnly} />
        <ApiBoundary fallback={<TargetMemberSkeletonList />}>
          <MemberList messageType={state?.type ?? 'mobile'} assignedOnly={assignedOnly} />
        </ApiBoundary>
        <CompleteButton goToForm={goToForm} messageType={state?.type ?? 'mobile'} assignedOnly={assignedOnly} />
      </FormProvider>
    </MainLayout>
  );
};

export default TargetMember;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';

import { ITextFieldOptions } from '../types';

type SuffixPropOptions = 'suffix' | 'suffixMarginRight' | 'onClear' | 'value' | 'readOnly' | 'disabled';

type SuffixPropTypes = Pick<ITextFieldOptions, SuffixPropOptions> & { focus: boolean };

const Suffix = ({ suffix, suffixMarginRight, onClear, readOnly, disabled, value, focus }: SuffixPropTypes) => {
  /** (readOnly && !suffix)는 기본 suffix가 적용되었을 경우를 말함 */
  const isReadOnlyOrSuffix = (readOnly && !suffix) || suffix;

  return (
    <Container suffixMarginRight={suffixMarginRight} readOnly={readOnly} disabled={disabled} className="suffix">
      {isReadOnlyOrSuffix ||
        (value && focus && (
          <IconButton
            onMouseDown={e => {
              e.preventDefault();
              onClear?.();
            }}>
            <Icon name="clearFill" fillColor={theme.color.gray3} />
          </IconButton>
        ))}
    </Container>
  );
};

export default Suffix;

const Container = styled.div<{ suffixMarginRight?: number; readOnly?: boolean; disabled?: boolean }>`
  ${({ suffixMarginRight }) => `margin-right: ${suffixMarginRight || 16}px`};
  z-index: 1;

  .typography {
    ${({ readOnly }) => readOnly && `color: ${theme.color.gray3}`};
    ${({ disabled }) => disabled && `color: ${theme.color.gray4}`};
  }
`;

import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import CounselDetailLayout from './components/CounselDetailLayout';

const CounselDetail = () => {
  return (
    <ApiBoundary>
      <CounselDetailLayout />
    </ApiBoundary>
  );
};

export default CounselDetail;

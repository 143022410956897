import { BookingCommonParams } from 'hooks/service/mutations/usePostBookingPrivate';

import { CONVERT_WEEKDAY_TO_NUM } from '../constants';
import { RepeatCustomWeekdayType, RepeatType } from '../types';

/** 선택한 반복일정 각 요일에 해당하는 숫자 배열로 변환 */
const convertRepeatWeekdayNum = (repeat: RepeatType, repeatCustomWeekday: Array<RepeatCustomWeekdayType>) => {
  let array: BookingCommonParams['weekday'] = [];
  switch (repeat) {
    case 'everyMonday':
      array = [1];
      break;
    case 'weekday':
      array = [1, 2, 3, 4, 5];
      break;
    case 'custom':
      array = Object.keys(CONVERT_WEEKDAY_TO_NUM)
        .filter(key => repeatCustomWeekday.some(item => item.id === key))
        .map(key => CONVERT_WEEKDAY_TO_NUM[key]);
      break;
    case null:
      array = [];
  }
  return array;
};

export default convertRepeatWeekdayNum;

import { MobileDetailStatusCountType } from './type';

export const MOBILE_DETAIL_TEXT = {
  title: '문자 메시지 상세',
  sendOn: '발송일시',
  target: '대상회원',
  targetDrawer: {
    condition: '회원검색 조건',
    members: '대상회원',
  },
  count: '발송건수',
  point: '차감포인트',
  status: {
    text: '처리현황',
    tooltip: ['회원별 상세 결과는 PC 웹페이지에서', '확인할 수 있습니다.'],
  },
  subTitle: '발송 메시지',
  message: {
    title: '제목',
    content: '내용',
  },
};

export const MOBILE_STATUS_COUNT_TEXT: MobileDetailStatusCountType = {
  success_count: { text: '성공', color: 'primary' },
  failed_count: { text: '실패', color: 'secondary3' },
  processing_count: { text: '대기중', color: 'gray3' },
};

// 시설웹에서 보낸 메세지의 filter에서 들어오는 키와 값
export const STUDIO_WEB_FILTER_LABEL = {
  type: {
    active: '이용회원',
    inHolding: '정지회원',
    inactive: '만료회원',
    unpaid: '미결제회원',
  },
  channel: {
    phone: '전화상담',
    visit: '방문상담',
    chat: '채팅상담',
    etc: '기타상담',
  },
  register_type: {
    registered: '회원',
    not_registered: '비회원',
  },
};

import Button from 'components/Button';
import { PERMISSION } from 'constants/permission';
import usePostMessageMobileCheck, { MessageMobileCheckResponse } from 'hooks/service/mutations/usePostMessageMobileCheck';
import usePostMessageMobileUpdateCheck from 'hooks/service/mutations/usePostMessageMobileUpdateCheck';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useMessage from 'hooks/useMessage';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import NoPointDrawer from 'sharedComponents/Message/NoPointDrawer';

import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';
import MobileSendConfirmDrawer from './MobileSendConfirmDrawer';

const MobileConfirmButton = () => {
  const [checkResponse, setCheckedResponse] = useState<MessageMobileCheckResponse>();
  const { watch, formState } = useFormContext<MobileFormType>();
  const { mode, title, message, target, isReserve, reservationDate, originalMessage, ...formValues } = watch();

  const targetCount = target === 'member' ? formValues.users.length : formValues.counseling_logs.length;
  const isRequirementsFilled = target && message.length > 0 && title.length > 0 && targetCount > 0;
  const isValidReservation = !isReserve || reservationDate; // 예약 선택 후 예약 날짜가 없으면 false
  const disabled = !isRequirementsFilled || !isValidReservation || !!formState.errors.title || !!formState.errors.message;

  const { checkPermission } = useGetPermissionDoHavePermission();
  const { validateSmsForm } = useMessage();

  const { mutate: checkToCreate, isPending: isPendingCreate } = usePostMessageMobileCheck();
  const { mutate: checkToUpdate, isPending: isPendingUpdate } = usePostMessageMobileUpdateCheck(originalMessage?.id);

  const checkReadyToSend = () => {
    if (isPendingCreate || isPendingUpdate) return;
    if (!validateSmsForm({ title, message, isReserve, reservationDate })) return;

    const checkParams = {
      message,
      users: formValues.users.map(user => user.id),
      counseling_logs: formValues.counseling_logs.map(log => log.id),
    };

    const mutate = mode === 'create' ? checkToCreate : checkToUpdate;

    mutate(checkParams, {
      onSuccess: response => setCheckedResponse(response.data),
    });
  };

  const isPointEnough = !!checkResponse && checkResponse?.message === null;
  const closeDrawer = () => setCheckedResponse(undefined);

  useEffect(() => {
    if (!!checkResponse && formState.isSubmitted) {
      setCheckedResponse(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSubmitted]);

  const usedPoint = mode === 'update' && originalMessage?.usedPoints ? originalMessage.usedPoints : 0;

  return (
    <>
      <Button
        variant="contained"
        color="point"
        size="large56"
        onClick={() => checkPermission(PERMISSION.message.sms.send.id, { onSuccess: checkReadyToSend })}
        disabled={disabled}>
        {MOBILE_FORM_TEXT[mode].confirmButton}
      </Button>
      {isPointEnough ? (
        <MobileSendConfirmDrawer
          isOpen={!!checkResponse}
          onClose={closeDrawer}
          count={checkResponse.count}
          mobileType={checkResponse.type}
          deductPoint={checkResponse.required_point - usedPoint}
        />
      ) : (
        <NoPointDrawer
          isOpen={!!checkResponse}
          onClose={closeDrawer}
          currentPoint={checkResponse?.studio_point || 0}
          deductPoint={checkResponse?.required_point || 0 - usedPoint}
        />
      )}
    </>
  );
};

export default MobileConfirmButton;

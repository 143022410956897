import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { APP_LINK_TEXT } from 'pages/MemberDetail/constants';
import { AppLinkMessageCommonConfirm, AppLinkMessageFormType } from 'pages/MemberDetail/types';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { InfoValueRowType } from 'sharedComponents/InfoTypography/types';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

type Props = AppLinkMessageCommonConfirm & {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  messageType: string;
};

const SendConfirmDrawer = ({ isOpen, onClose, onSubmit, currentPoint, messageType, deductPoint }: Props) => {
  const { control, getValues } = useFormContext<AppLinkMessageFormType>();
  const isReserve = useWatch({ control, name: 'isReserve' });
  const reservationDate = useWatch({ control, name: 'reservationDate' });
  const isMutating = useIsMutating() > 0;
  const type = isReserve ? 'reserved' : 'send';

  const data: InfoValueRowType[] = useMemo(() => {
    const dateIndex = { label: '발송일시', value: `[예약] ${filters.dateTimeWithWeekday(reservationDate)}` };
    const defaultData = [
      { label: '받는사람', value: getValues('name') },
      { label: '유형', value: messageType },
      { label: '건수', value: '1건' },
      { label: '보유 포인트 ', value: `${currentPoint}P` },
    ];

    if (isReserve) {
      defaultData.splice(3, 0, dateIndex);
    }

    return defaultData;
  }, [currentPoint, getValues, isReserve, messageType, reservationDate]);

  return (
    <DoubleButtonDrawer
      isOpen={isOpen}
      onClose={onClose}
      type={3}
      header={
        <TwoLinedTitle
          title={
            <>
              <StyledTitle size={19} weight={700}>
                총 <b>1</b>건의
              </StyledTitle>
              <Typography size={19} weight={700}>
                {APP_LINK_TEXT.mobileMessage.confirm.title[type]}
              </Typography>
            </>
          }
        />
      }
      leftButton={{ text: '취소', onClick: onClose, disabled: isMutating }}
      rightButton={{ text: APP_LINK_TEXT.mobileMessage.confirm.footerButton[type], onClick: onSubmit, disabled: isMutating }}>
      <CommonStyleCard bgColor="gray7" showBoxShadow={false}>
        <ContentsWrapper>
          {data.map(({ label, value }, index) => (
            <InfoFlexRow key={`${index}-${value}`}>
              <InfoTypography>{label}</InfoTypography>
              <InfoTypography weight={500}>{value}</InfoTypography>
            </InfoFlexRow>
          ))}
          <InfoFlexRow className="deduct-point-row">
            <InfoTypography>차감 포인트</InfoTypography>
            <Tooltip isOpen={isReserve} position="right" message={APP_LINK_TEXT.mobileMessage.confirm.tooltipMessage}>
              <InfoTypography textColor="primary" weight={500}>
                {deductPoint}P
              </InfoTypography>
            </Tooltip>
          </InfoFlexRow>
        </ContentsWrapper>
      </CommonStyleCard>
    </DoubleButtonDrawer>
  );
};

export default SendConfirmDrawer;

const StyledTitle = styled(Typography)`
  b {
    color: ${theme.color.primary};
  }
`;

const ContentsWrapper = styled(InfoFlexWrapper)`
  .deduct-point-row {
    > .typography:first-of-type {
      text-align: left;
    }

    .tooltip-contents-container {
      right: -16px;
      transform: none;
    }
  }
`;

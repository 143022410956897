import styled from '@emotion/styled';
import { theme } from 'assets/styles';

export const CommonWheelStyles = styled.div`
  .mbsc-scroller-bar-cont {
    display: none;
  }

  .mbsc-datepicker-tab-wrapper {
    flex-wrap: nowrap;

    /** 날짜, 시간 wrapper */
    .mbsc-datepicker-tab {
      padding: 24px 0;
    }
  }

  .mbsc-scroller-wheel-wrapper {
    padding: 0;
  }

  /** selected bar */
  .mbsc-scroller-wheel-line {
    display: block;
    margin: 0 8px;
    border-radius: 4px;
    background: ${`${theme.color.primary}1a`};
  }

  /** 선택된 날짜(시간) 포함 전체 개별 날짜(시간) 스타일 */
  .mbsc-scroller-wheel-item {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 1.9rem;
    color: ${theme.color.gray3};
    opacity: 0.8;

    /** 선택된 날짜(시간) 텍스트 스타일 */
    &.mbsc-selected {
      color: ${theme.color.primary};
      font-weight: 500;
      opacity: 1;
    }

    /** 아이템 hover 스타일 제거 */
    &.mbsc-hover {
      background: transparent;
    }
  }
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';

type Props = {
  max: number;
  step: number;
};

const ProgressCircle = ({ max, step }: Props) => {
  const progressLength = Array.from({ length: max }, (__, i) => i + 1);

  return (
    <Container>
      {progressLength.map(num => (
        <StyledTypography key={num} size={13} weight={600} span isActive={step === num}>
          {step > num ? <Icon name="selectCheckBold" size={12} fillColor={theme.color.gray3} /> : num}
        </StyledTypography>
      ))}

      <div className="line">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="3" viewBox="0 0 42 3">
          <path d="M0 1.5H41.5" stroke={theme.color.gray6} strokeWidth="2" />
        </svg>
      </div>
    </Container>
  );
};

export default ProgressCircle;

const Container = styled.div`
  position: relative;
  ${theme.flex('row', 'center', 'flex-start', 8)};
  margin: 8px 0 12px;

  .line {
    position: absolute;
    top: 40%;
    left: 11px;
    transform: translateY(-50%);
    z-index: 0;
  }
`;

const StyledTypography = styled(Typography)<{ isActive: boolean }>(
  css`
    ${theme.flex()};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${theme.color.gray6};
    color: ${theme.color.gray4};
    z-index: 1;
  `,

  ({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.color.primary};
      color: ${theme.color.white};
    `,
);

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { MemberPointHistoryResponse } from 'hooks/service/queries/useInfinityPointHistory';
import { POINT_PAYMENT_STATUS_DATA, POINT_TYPE_DATA } from 'pages/MemberDetail/constants';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

type Props = {
  pointData: MemberPointHistoryResponse;
};

const PointHistoryCard = ({ pointData }: Props) => {
  const { reward_point, point_amount, updated_for, settlement_at, staff, status, point_type } = pointData;

  return (
    <Container>
      <InfoFlexWrapper gap={4}>
        <InfoFlexRow>
          <Typography size={15} weight={600}>
            {POINT_PAYMENT_STATUS_DATA[status]}
          </Typography>

          {point_type !== 'mix' ? (
            <Typography size={15} weight={700}>
              {reward_point ? '+' : ''}
              {filters.numberComma(reward_point || point_amount)}P
            </Typography>
          ) : (
            <Typography size={15} weight={700}>
              {`+${filters.numberComma(reward_point)}`}P / {filters.numberComma(point_amount)}P
            </Typography>
          )}
        </InfoFlexRow>
        <div className="detail">
          <ArrangeTypographyWrapper>
            <Typography span className="date-text" size={15} textColor="gray2" opacity={0.8}>
              {filters.dateTimeWithWeekday(settlement_at || undefined)}
            </Typography>
            <Typography span size={15} textColor="gray2" opacity={0.8} ellipsis={1}>
              {staff.name}
            </Typography>
          </ArrangeTypographyWrapper>

          {point_type !== 'mix' ? (
            <Typography className="type-text" span size={15} weight={600} textColor={reward_point ? 'primary' : 'gray3'}>
              {POINT_TYPE_DATA[point_type]}
            </Typography>
          ) : (
            <div>
              <Typography span size={15} weight={600} textColor="primary">
                {POINT_TYPE_DATA.reward} {''}
              </Typography>
              <Typography span size={15} weight={600} textColor="gray3">
                / {POINT_TYPE_DATA.deduct}
              </Typography>
            </div>
          )}
        </div>
      </InfoFlexWrapper>
      {updated_for && (
        <Typography className="reason" size={13} weight={500} textColor="gray2">
          {updated_for}
        </Typography>
      )}
    </Container>
  );
};

export default PointHistoryCard;

const Container = styled.div`
  ${theme.flex('column', '', '', 16)};
  padding: 20px;

  &:not(:first-of-type) {
    border-top: 1px solid ${theme.color.gray6};
  }

  .detail {
    ${theme.flex('', '', 'space-between', 10)};

    > div {
      ${theme.flex('', '', 'space-between')};
    }
    .date-text,
    .type-text {
      flex: 0 0 auto;
    }
  }

  .reason {
    background-color: ${theme.color.gray7};
    padding: 12px;
    border-radius: 8px;
  }
`;

import dayjs from 'dayjs';
import { WeekdayNum } from 'hooks/service/mutations/usePostBookingPrivate';

import { CONVERT_NUM_TO_WEEKDAY, REPEAT_CUSTOM_SETTING_WEEKDAY } from '../constants';
import { RepeatCustomWeekdayType, RepeatType } from '../types';

type Response = {
  repeat: RepeatType;
  repeatCustomWeekday: Array<RepeatCustomWeekdayType>;
};

const weekday: Array<1 | 2 | 3 | 4 | 5> = [1, 2, 3, 4, 5];

/** course 데이터로 모든 수업 수정의 반복설정 hook form state 세팅 */
const convertRepeatFormState = <T extends Array<{ weekday: WeekdayNum }>>(
  schedules: T,
  courseStartDate: string,
  courseEndDate: string,
): Response => {
  const diffInDays = dayjs(courseEndDate).diff(dayjs(courseStartDate), 'day');
  const numArray = schedules.map(({ weekday }) => weekday);
  const courseNumArray = numArray[0] === 0 ? [...numArray.slice(1), numArray[0]] : numArray;

  const hasNoRepeat = courseNumArray.length === 7 || diffInDays <= 7; // 반복안함

  let repeat: RepeatType = 'custom';
  if (hasNoRepeat) {
    repeat = null;
  } else if (courseNumArray.length === 1 && courseNumArray[0] === 1) {
    repeat = 'everyMonday';
  } else if (courseNumArray.length === 5 && weekday.every(num => courseNumArray.includes(num))) {
    repeat = 'weekday';
  }

  const repeatCustomWeekday = hasNoRepeat
    ? []
    : courseNumArray.map(num => {
        const weekdayId = CONVERT_NUM_TO_WEEKDAY[num];
        return REPEAT_CUSTOM_SETTING_WEEKDAY.filter(weekday => weekday.id === weekdayId)[0];
      });

  return {
    repeat,
    repeatCustomWeekday,
  };
};

export default convertRepeatFormState;

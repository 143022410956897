import { CustomErrorType } from 'hooks/useErrorDialog';
import { atom } from 'recoil';

export const errorDialogAtom = atom<{ error?: CustomErrorType; show: boolean; customClose?: () => void; buttonText?: string }>({
  key: 'errorDialogAtom',
  default: { show: false, buttonText: '확인' },
});

export const dialogAtom = atom({
  key: 'dialogAtom',
  default: false,
});

import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { MemberParams, MemberResponse } from './useInfinityMember';

const useGetAllMemberAssigned = (params: MemberParams, enabled: boolean) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllMemberAssignedResponse, AllMemberAssignedParams>({
    url: `/api/member/assigned`,
    params: { ...params, limit: 200 },
  });

  return useQuery({
    queryKey: [`member`, `assigned`, { ...params }, enabled],
    queryFn,
    enabled,
    select: data => data.data,
  });
};

export default useGetAllMemberAssigned;

type AllMemberAssignedParams = MemberParams & { limit: number };
type AllMemberAssignedResponse = MemberResponse[];

import { ArrowFilterValuesType } from 'components/Tabs/ArrowFilterTabs';
import { PERMISSION } from 'constants/permission';
import { FilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { ScheduleBookingParamType, ScheduleCheckinParamType, ScheduleDeletedLectureParamType, ScheduleParamType } from './type';
import { makeDayTabLabel } from './util';

export const moreDetailSchedulePermissionsMap = {
  group: PERMISSION.lecture.others.view.group,
  private: PERMISSION.lecture.others.view.private,
} as const;

export const SCHEDULE_TABS = [
  { value: 'booking', label: '예약내역' },
  { value: 'checkin', label: '체크인내역' },
  { value: 'deletedLecture', label: '삭제된 수업' },
];

export const BASIC_SCHEDULE_TABS = [
  { value: 'booking', label: '예약내역' },
  { value: 'deletedLecture', label: '삭제된 수업' },
];

export const SCHEDULE_SORTING_FILTERS = [
  { id: 'asc', label: '수업일시 오름차순', value: 'asc' },
  { id: 'desc', label: '수업일시 내림차순', value: 'desc' },
];

export const SCHEDULE_NODATA_TEXT = {
  booking: '예약 내역이 없습니다.',
  checkin: '체크인 내역이 없습니다.',
  deletedLecture: '삭제 내역이 없습니다.',
};

export const SCHEDULE_DEFAULT_PERIOD = {
  start: filters.dateDash(),
  end: filters.dateDash(),
};

// 요일 값을 문자열로 변환하기 위한 매핑
export const SCHEDULE_DEFAULT_DAYS = [
  { value: '1', label: '월' },
  { value: '2', label: '화' },
  { value: '3', label: '수' },
  { value: '4', label: '목' },
  { value: '5', label: '금' },
  { value: '6', label: '토' },
  { value: '0', label: '일' },
];

/** 예약내역 */
export const DEFAULT_BOOKING_FILTER_TABS = {
  period: '기간선택',
  staffs: '강사 전체',
  type: '수업구분 전체',
  status: '예약상태 전체',
};

export const DEFAULT_BOOKING_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      {
        value: 'today',
        label: '오늘',
      },
      {
        value: 'isoWeek',
        label: '이번주',
      },
      {
        value: 'month',
        label: '이번달',
      },
      {
        value: 'custom',
        label: '직접선택',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const periodValue = value as ScheduleBookingParamType['period'];
      if (!periodValue) return '';
      if (periodValue.type === 'today') return '오늘';
      if (periodValue.type === 'isoWeek') return '이번주';
      if (periodValue.type === 'month') return '이번달';
      return filters.period(periodValue.start, periodValue.end);
    },
  },
  {
    key: 'staffs',
    type: 'select',
    title: '강사',
    options: [],
    tabConverter: (value: ArrowFilterValuesType) => {
      const staffsValue = value as ScheduleParamType['staffs'];
      if (!staffsValue || staffsValue.length === 0) {
        return '강사 전체';
      }
      const staffCount = staffsValue.length;
      const staffName = staffsValue[0].name;
      const isAllChecked = staffsValue.find(value => value.name === 'all');
      if (isAllChecked) {
        return '강사 전체';
      }
      if (staffsValue.length === 1) {
        return `${staffName ?? '강사 전체'}`;
      }

      return `${staffName} 외 ${staffCount - 1}명`;
    },
  },
  {
    key: 'type',
    type: 'button',
    title: '수업구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
    ],
  },
  {
    key: 'status',
    type: 'button',
    title: '예약상태',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'booked_list',
        label: '예약(대기/확정)',
      },
      {
        value: 'cancel',
        label: '취소',
      },
      {
        value: 'attendance',
        label: '출석',
      },
      {
        value: 'absence',
        label: '결석',
      },
      {
        value: 'noshow',
        label: '노쇼',
      },
    ],
  },
];

/** 체크인 */
export const DEFAULT_CHECKIN_FILTER_TABS = {
  period: '기간선택',
  day: '요일 전체',
  staffs: '강사 전체',
  type: '수업구분 전체',
  status: '예약상태 전체',
  classTime: '수업시간 전체',
};

export const DEFAULT_CHECKIN_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      {
        value: 'today',
        label: '오늘',
      },
      {
        value: 'isoWeek',
        label: '이번주',
      },
      {
        value: 'month',
        label: '이번달',
      },
      {
        value: 'custom',
        label: '직접선택',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const periodValue = value as ScheduleCheckinParamType['period'];
      if (!periodValue) return '';
      if (periodValue.type === 'today') return '오늘';
      if (periodValue.type === 'isoWeek') return '이번주';
      if (periodValue.type === 'month') return '이번달';
      return filters.period(periodValue.start, periodValue.end);
    },
  },
  {
    key: 'day',
    type: 'multiButton',
    title: '요일',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: '1',
        label: '월',
      },
      {
        value: '2',
        label: '화',
      },
      {
        value: '3',
        label: '수',
      },
      {
        value: '4',
        label: '목',
      },
      {
        value: '5',
        label: '금',
      },
      {
        value: '6',
        label: '토',
      },
      {
        value: '0',
        label: '일',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const dayValue = value as ScheduleCheckinParamType['day'];
      if (dayValue && dayValue.length > 0) {
        return makeDayTabLabel(dayValue);
      }
      return '요일 전체';
    },
  },
  {
    key: 'staffs',
    type: 'select',
    title: '강사',
    options: [],
    tabConverter: (value: ArrowFilterValuesType) => {
      const staffsValue = value as ScheduleParamType['staffs'];
      if (!staffsValue || staffsValue.length === 0) {
        return '강사 전체';
      }
      const staffCount = staffsValue.length;
      const staffName = staffsValue[0].name;
      const isAllChecked = staffsValue.find(value => value.name === 'all');
      if (isAllChecked) {
        return '강사 전체';
      }
      if (staffsValue.length === 1) {
        return `${staffName}`;
      }
      return `${staffName} 외 ${staffCount - 1}명`;
    },
  },
  {
    key: 'type',
    type: 'button',
    title: '수업구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
    ],
  },
  {
    key: 'status',
    type: 'button',
    title: '예약상태',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'attendance',
        label: '출석',
      },
      {
        value: 'absence',
        label: '결석',
      },
    ],
  },
  {
    key: 'classTime',
    type: 'time',
    title: '수업시간',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'lectureTime',
        label: '시간설정',
      },
      {
        value: 'timeRange',
        label: '범위로검색',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const classTimeValue = value as ScheduleCheckinParamType['classTime'];
      if (!classTimeValue || !classTimeValue.type) return '전체';
      if (classTimeValue.type === 'lectureTime' && !classTimeValue.start) return '시간설정';
      if (classTimeValue.type === 'timeRange' && !classTimeValue.start) return '범위로검색';
      return filters.timePeriod(classTimeValue.start, classTimeValue.end);
    },
  },
];

/** 삭제된 수업 */
export const DEFAULT_DELETED_CLASS_FILTER_TABS = {
  period: '기간선택',
  staffs: '강사 전체',
  type: '수업구분 전체',
};

export const DEFAULT_DELETED_CLASS_FILTER_OPTIONS: Array<FilterType> = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      {
        value: 'today',
        label: '오늘',
      },
      {
        value: 'isoWeek',
        label: '이번주',
      },
      {
        value: 'month',
        label: '이번달',
      },
      {
        value: 'custom',
        label: '직접선택',
      },
    ],
    tabConverter: (value: ArrowFilterValuesType) => {
      const periodValue = value as ScheduleDeletedLectureParamType['period'];
      if (!periodValue) return '';
      if (periodValue.type === 'today') return '오늘';
      if (periodValue.type === 'isoWeek') return '이번주';
      if (periodValue.type === 'month') return '이번달';
      return filters.period(periodValue.start, periodValue.end);
    },
  },
  {
    key: 'staffs',
    type: 'select',
    title: '강사',
    options: [],
    tabConverter: (value: ArrowFilterValuesType) => {
      const staffsValue = value as ScheduleParamType['staffs'];
      if (!staffsValue || staffsValue.length === 0) {
        return '강사 전체';
      }
      const staffCount = staffsValue.length;
      const staffName = staffsValue[0].name;
      const isAllChecked = staffsValue.find(value => value.name === 'all');
      if (isAllChecked) {
        return '강사 전체';
      }
      if (staffsValue.length === 1) {
        return `${staffName}`;
      }
      return `${staffName} 외 ${staffCount - 1}명`;
    },
  },
  {
    key: 'type',
    type: 'button',
    title: '수업구분',
    options: [
      {
        value: undefined,
        label: '전체',
      },
      {
        value: 'G',
        label: '그룹',
      },
      {
        value: 'P',
        label: '프라이빗',
      },
    ],
  },
];

export const DEFAULT_SCHEDULE_FILTER = {
  period: {
    type: 'today',
    start: filters.dateDash(),
    end: filters.dateDash(),
  },
};

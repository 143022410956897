import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Dialog from 'components/Dialog';
import { DialogInfoContent, DialogInfoItem } from 'components/Dialog/DialogContents';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import Typography from 'components/Typography';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TICKET_FORM_TEXT } from './constants';
import { TicketDetailInfoFormType } from './types';

/** 시설웹의 패밀리 수강권이 다중 예약 설정으로 변경됨 */
const ShareTicketSwitch = () => {
  const { control } = useFormContext<TicketDetailInfoFormType>();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Controller
        name="detail.is_shared"
        control={control}
        render={({ field: { value, ...field } }) => (
          <Switch
            id="is_shared"
            label={TICKET_FORM_TEXT.shareSwitch.label}
            switchSize="small"
            gap={10}
            fontWeight={400}
            leftIcon={<Icon name="checkIn2" color="gray2" />}
            onClickInfo={() => setIsOpen(true)}
            checked={value}
            {...field}
          />
        )}
      />

      {isOpen && (
        <Dialog isInfo showCloseButton title={TICKET_FORM_TEXT.shareSwitch.title} onClose={() => setIsOpen(false)}>
          <DialogInfoContent>
            <ul>
              <DialogInfoItem>한 수업을 여러번 예약할 때, 일일 예약 가능한 횟수 제한을 받지 않아요.</DialogInfoItem>
              <DialogInfoItem>같은 시간대에 서로 다른 수업을 예약할 수 있어요.</DialogInfoItem>
            </ul>
            <Typography size={14} textColor="gray2">
              * 단, 다른 회원님과 수강권을 공유할 수 없어요.
            </Typography>
          </DialogInfoContent>
        </Dialog>
      )}
    </Container>
  );
};

export default ShareTicketSwitch;

const Container = styled.div`
  border-bottom: 1px solid ${theme.color.gray8};

  .switch {
    padding: 0 20px;
    height: 56px;
    .switch-contents-wrapper {
      height: 100%;
    }
  }
`;

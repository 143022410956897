import Divider from 'components/Divider';
import { get } from 'lodash';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MultiStaffFilter from '../MultiStaffFilter';
import ReportMultiButtonFilter from '../ReportMultiButtonFilter';

const ReportPointWholeFilterOptions = ({ filters, filterChanged, changeOption }: FilterOptionsProps) => {
  return (
    <>
      <ApiBoundary>
        <MultiStaffFilter currentFilter={filters[0]} selectedOption={get(filterChanged, filters[0].key)} onClick={changeOption} />
      </ApiBoundary>
      <Divider />
      <ReportMultiButtonFilter
        selectedOptionKey={filters[1].key as 'type'}
        currentAllOptionCount={filters[1].options.length - 1}
        currentFilter={filters[1]}
        selectedOption={get(filterChanged, filters[1].key)}
        onClick={changeOption}
      />
      <Divider />
      <ReportMultiButtonFilter
        selectedOptionKey={filters[2].key as 'status'}
        currentAllOptionCount={filters[2].options.length - 1}
        currentFilter={filters[2]}
        selectedOption={get(filterChanged, filters[2].key)}
        onClick={changeOption}
      />
    </>
  );
};

export default ReportPointWholeFilterOptions;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 메세지 발송용 대표번호 확인 */
const useGetMessageMobileMainContact = () => {
  const { queryFn } = useQueryFnWithCommonParams<MessageMobileMainContactResponse>({
    url: `/api/message/mobile/main-contact`,
  });

  return useSuspenseQuery({
    queryKey: ['studio', 'message-main-contact'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetMessageMobileMainContact;

type MessageMobileMainContactResponse = {
  contact: string;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import { ScheduleManagementBookings } from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import useInfinityScheduleManagementCheckin, {
  ScheduleManagementCheckin,
  ScheduleManagementCheckinParams,
} from 'hooks/service/queries/useInfinityScheduleManagementCheckin';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { checkinParamsAtom, isEveryPermissionMoreDetailScheduleAtom, staffListForScheduleFilter } from 'recoil/moreDetail/atoms';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import PullToRefresh from 'sharedComponents/PullToRefresh';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { SCHEDULE_NODATA_TEXT } from '../../constant';
import { ScheduleStaff } from '../../type';
import { getScheduleGroupByStartOn, getStaffIds } from '../../util';
import NoData from '../NoData';
import ScheduleCard from '../ScheduleCard';
import ScheduleSorting from '../ScheduleSorting';
import ScheduleTotal from '../ScheduleTotal';

type Props = {
  currentTab: 'booking' | 'checkin' | 'deletedLecture';
};

const CheckinScheduleList = ({ currentTab }: Props) => {
  const currentStaffId = useRecoilValue(staffIdAtom);
  const staffs = useRecoilValue(staffListForScheduleFilter);
  const isEveryPermission = useRecoilValue(isEveryPermissionMoreDetailScheduleAtom);
  const [scheduleCheckin, setScheduleCheckin] = useRecoilState(checkinParamsAtom);
  const { searchKeyword } = useSearchKeywords('schedule');
  const [filteredCheckins, setFilteredCheckins] = useState<ScheduleManagementCheckin[][]>([]);

  const scheduleCheckinParams: ScheduleManagementCheckinParams = {
    staff_ids: isEveryPermission ? getStaffIds(scheduleCheckin.staffs || []) : [currentStaffId],
    start_date: scheduleCheckin.period?.start || filters.dateDash(),
    end_date: scheduleCheckin.period?.end || filters.dateDash(),
    week: scheduleCheckin.day || [],
    type: scheduleCheckin.type,
    paginate_type: 'detail',
    status: scheduleCheckin.status,
    start_time: scheduleCheckin.classTime?.start
      ? filters.time(scheduleCheckin.classTime?.start)
      : scheduleCheckin.classTime?.start,
    end_time: scheduleCheckin.classTime?.end ? filters.time(scheduleCheckin.classTime?.end) : scheduleCheckin.classTime?.end,
    is_range: scheduleCheckin.classTime?.type === 'timeRange' ? 1 : 0,
    order_by: scheduleCheckin.sort ?? 'asc',
    search: searchKeyword,
  };

  const {
    data: { checkins, total, groupBookingCount, privateBookingCount },
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isRefetching,
    refetch,
  } = useInfinityScheduleManagementCheckin(scheduleCheckinParams);

  useEffect(() => {
    const groupedSchedules = getScheduleGroupByStartOn(checkins);
    setFilteredCheckins(groupedSchedules as ScheduleManagementBookings[][]);
  }, [searchKeyword, checkins, currentTab]);

  useEffect(() => {
    if (!isEveryPermission) {
      const currentStaff = staffs.filter(staff => staff.id === currentStaffId)[0];
      const staffInfo = {
        id: currentStaff?.id,
        imgUrl: getImageUrl(currentStaff?.avatar),
        name: currentStaff?.name,
      } as ScheduleStaff;

      setScheduleCheckin(prev => ({ ...prev, staffs: [staffInfo] }));
    }
  }, [currentStaffId, isEveryPermission, setScheduleCheckin, staffs]);

  if (total === 0 || filteredCheckins.length === 0)
    return (
      <>
        <FilterSection>
          <ScheduleTotal total={0} groupTotal={groupBookingCount} privateTotal={privateBookingCount} />
          <Divider thin />
          <ScheduleSorting currentTab={currentTab} />
        </FilterSection>
        <NoData currentKeyword={searchKeyword} noDataText={SCHEDULE_NODATA_TEXT.checkin} />
      </>
    );

  return (
    <Container>
      <FilterSection>
        <ScheduleTotal total={total} groupTotal={groupBookingCount} privateTotal={privateBookingCount} />
        <Divider thin />
        <ScheduleSorting currentTab={currentTab} />
      </FilterSection>
      <PullToRefresh isRefetching={isRefetching} onRefresh={refetch}>
        <ContentSection>
          <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
            <ScheduleCard schedules={filteredCheckins} currentTab={currentTab} />
          </InfiniteScroll>
        </ContentSection>
      </PullToRefresh>
    </Container>
  );
};

export default CheckinScheduleList;

const Container = styled.div`
  & > .typography {
    padding-bottom: 20px;
  }
`;

const FilterSection = styled.section`
  background-color: ${theme.color.white};
`;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;

import CheckBox from 'components/CheckBox';
import { useContext } from 'react';

import { TargetSelectContext } from './TargetSelectable';

type Props = {
  id: number;
};

const TargetCheckBox = ({ id }: Props) => {
  const { isSelected, disabled } = useContext(TargetSelectContext);

  if (disabled) return null;
  return <CheckBox id={`${id}`} checked={isSelected} onChange={() => null} />;
};

export default TargetCheckBox;

import dayjs from 'dayjs';
import useToast from 'hooks/useToast';
import { POINT_FILTER_TEXT } from 'pages/MemberDetail/constants';
import { PointPeriodParamsType } from 'pages/MemberDetail/types';
import { useState } from 'react';
import { FilterProps } from 'sharedComponents/Filters/types';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

type Props = Pick<FilterProps, 'onClick'> & {
  previousOptions: PointPeriodParamsType;
};

const PeriodSelectCustom = ({ previousOptions, onClick: changeOptions }: Props) => {
  const [date] = useState([previousOptions.start_date, previousOptions.end_date]);
  const { setToast } = useToast();

  const changeDate = (value: string[]) => {
    changeOptions(
      {
        value: {
          type: 'custom',
          start_date: filters.dateDash(value[0]),
          end_date: filters.dateDash(value[1]),
        },
        label: '',
      },
      'period',
    );
  };

  const save = (period: string[]) => {
    // 포인트내역 기간선택 최대 5개월까지 조회 가능
    if (dayjs(period[0]).isBefore(dayjs(period[1]).subtract(5, 'month'))) {
      setToast({ message: POINT_FILTER_TEXT.periodDescription, bottom: 92 });
      return true;
    }
    return false;
  };

  return <PeriodPicker initialDate={date} max={dayjs().toString()} onChangeDate={changeDate} onSave={save} />;
};

export default PeriodSelectCustom;

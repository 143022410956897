import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 룸 목록 반환 */
const useGetRoom = ({ isEnabled }: RoomParams) => {
  const { queryFn } = useQueryFnWithCommonParams<Array<RoomResponse>>({
    url: `/api/room`,
  });

  return useSuspenseQuery({
    queryKey: ['room', isEnabled],
    queryFn: () => (isEnabled ? queryFn() : null),
    select: data => data?.data,
  });
};

export default useGetRoom;

export type RoomResponse = {
  id: number;
  studio_id: number;
  name: string;
  updated_for: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export type RoomParams = {
  /** 수업 생성 시, 룸 설정 기능을 사용하는 시설일 때만 룸 목록 호출 */
  isEnabled: boolean;
};

import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';
import usePopup from 'hooks/usePopup';
import { formatFailList } from 'pages/Booking/utils/formatFailCard';
import { useNavigate } from 'react-router-dom';
import LeftSmallButtonGroup from 'sharedComponents/LeftSmallButtonGroup';
import MainLayout from 'sharedComponents/MainLayout';

import BookingFailList from './BookingFailList';
import { FailBookingContainer } from './FailAllBooking';

type Props = {
  fails: Array<BookingFailResponse>;
  onSubmit: () => void;
};

const LectureOverlap = ({ onSubmit, fails }: Props) => {
  const { setPopup } = usePopup();
  const navigate = useNavigate();
  const isPending = useIsMutating() > 0;
  const data = formatFailList(fails);

  const closePopup = () => {
    navigate('/schedule', { replace: true });
    setPopup(null);
  };

  const submit = () => {
    onSubmit();
    closePopup();
  };

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={closePopup}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <Container>
        <Icon size={54} name="errorFill" color="gray4" />
        <Typography size={19} weight={700}>
          <Typography size={19} weight={700} textColor="error" span>
            {data.length}
          </Typography>
          개의 수업과 일정이 중복됩니다. <br />
          계속 하시겠습니까?
        </Typography>

        <BookingFailList data={data} />

        <StyledButtonGroup
          leftButton={{
            text: '돌아가기',
            disabled: isPending,
            onClick: closePopup,
          }}
          rightButton={{
            text: '계속',
            disabled: isPending,
            onClick: submit,
          }}
        />
      </Container>
    </MainLayout>
  );
};

export default LectureOverlap;

const Container = styled(FailBookingContainer)`
  min-height: 100%;
  padding-bottom: 86px;
`;

const StyledButtonGroup = styled(LeftSmallButtonGroup)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Divider from 'components/Divider';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { LectureDataResponse } from 'hooks/service/queries/useInfinityReportLecture';
import _ from 'lodash';
import { useState } from 'react';
import filters from 'utils/filters';
import getEllipsisByLength from 'utils/getEllipsisByLength';

import { REPORT_LECTURE_STATUS_LABEL } from '../../constants';
import ReportLectureDetail from './ReportLectureDetail';

type Props = {
  lectureData: LectureDataResponse;
};

const ReportLectureAccordion = ({ lectureData }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const isGroupClass = lectureData.class_type === 'G';
  const isAutoTicket = _.isNull(lectureData.status);
  const memberName = getEllipsisByLength({ text: lectureData.member.name, max: 10, hasEllipsis: true });
  const lectureStatus = _.isNull(lectureData.status)
    ? ''
    : REPORT_LECTURE_STATUS_LABEL[lectureData.status as keyof typeof REPORT_LECTURE_STATUS_LABEL].name;

  return (
    <Accordion
      isOpen={isOpen}
      showArrow={false}
      size="none"
      header={
        <CardWrapper isOpenLectureInfo={isOpen} onClick={toggleAccordion}>
          <div className="member-info-wrapper">
            <div>
              <div className="member-name">
                <Typography ellipsis={1} size={15} weight={700} textColor="gray1">
                  {memberName}
                </Typography>
                <Typography size={15} weight={700} textColor="gray1">
                  회원
                </Typography>
              </div>
              <Label size="small" borderColor={isGroupClass ? 'primary' : 'pink1'} textColor={isGroupClass ? 'primary' : 'pink1'}>
                {isGroupClass ? '그룹' : '프라이빗'}
              </Label>
            </div>
            <Typography size={15} weight={500} textColor="gray2">
              {isAutoTicket ? '차감' : lectureStatus}
            </Typography>
          </div>
          {!isAutoTicket && (
            <div className="staff-info-wrapper">
              <div>
                <Typography className="member-name" span size={15} textColor="gray2">
                  {`${filters.timePeriod(lectureData.lecture?.start_on, lectureData.lecture?.end_on)}`}
                </Typography>
              </div>
              <div className="staff-name">
                <Typography ellipsis={1} span size={15} textColor="gray3">
                  {lectureData.staff?.name}
                </Typography>
                <Typography span size={15} textColor="gray3">
                  강사
                </Typography>
              </div>
            </div>
          )}
        </CardWrapper>
      }>
      {isOpen && (
        <>
          <Divider thin thinColor="gray6" />
          <ReportLectureDetail lectureData={lectureData} isOpen={isOpen} isAutoTicket={isAutoTicket} />
        </>
      )}
    </Accordion>
  );
};

export default ReportLectureAccordion;

const CardWrapper = styled.div<{ isOpenLectureInfo: boolean }>(
  ({ isOpenLectureInfo }) => css`
    width: 100%;
    padding: 18px 20px;
    border-radius: ${isOpenLectureInfo ? '16px 16px 0px 0px' : '16px'};
    background: ${theme.color.white};
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

    .class-info-wrapper {
      ${theme.flex('row', '', 'space-between')}
    }

    .member-info-wrapper {
      ${theme.flex('row', '', 'space-between')}
      padding-bottom: 4px;
      div {
        ${theme.flex('row', 'center', '', 6)}
      }
      .member-name {
        ${theme.flex('row', 'center', '', 2)}
      }
    }

    .staff-info-wrapper {
      ${theme.flex('row', '', 'space-between')}
      .staff-name {
        ${theme.flex('row', 'center', '')}
        padding-left: 8px;
        span:first-of-type {
          max-width: 148px;
          padding-right: 1px;
          text-align: right;
        }

        span:last-of-type {
          flex-shrink: 0;
        }
      }
    }
  `,
);

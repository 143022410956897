import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Radio from 'components/Radio';
import Typography from 'components/Typography';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { NOTICE_TEXT } from '../../constants';
import { PERIOD_RADIO_OPTIONS } from '../constants';
import { NoticeFormType } from '../types';
import NoticePeriodPicker from './NoticePeriodPicker';

const NoticeSettings = () => {
  const { control, setValue } = useFormContext<NoticeFormType>();
  const period = useWatch({ control, name: 'period' });

  const isPrivate = period === 'unset';

  const changePeriod = (e: { target: { value: string } }) => {
    const currentPeriod = e.target.value as NoticeFormType['period'];

    if (currentPeriod !== 'limited') {
      setValue('end_on', undefined, { shouldDirty: true });
    }
    if (currentPeriod === 'unset') {
      setValue('is_top_fixed', false, { shouldDirty: true });
      setValue('popup_on', false, { shouldDirty: true });
    }
    setValue('period', currentPeriod, { shouldDirty: true });
  };

  return (
    <>
      <LabelTypography size={12} weight={500} textColor="gray2">
        {NOTICE_TEXT.settings}
      </LabelTypography>
      <FlexRow>
        <Controller
          name="popup_on"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <CheckBox
              id="popup"
              label={NOTICE_TEXT.popup}
              gap={10}
              checked={value}
              onChange={onChange}
              {...field}
              disabled={isPrivate}
            />
          )}
        />
        <Controller
          name="is_top_fixed"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <CheckBox
              id="topFixed"
              label={NOTICE_TEXT.topFixed}
              gap={10}
              checked={value}
              onChange={onChange}
              disabled={isPrivate}
              {...field}
            />
          )}
        />
      </FlexRow>
      <GapDivider thin />
      <LabelTypography size={12} weight={500} textColor="gray2">
        {NOTICE_TEXT.target}
      </LabelTypography>
      <FlexRow>
        <Controller
          name="isTargetActive"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <CheckBox id="active" label={NOTICE_TEXT.targetActive} gap={10} checked={value} onChange={onChange} {...field} />
          )}
        />
        <Controller
          name="isTargetExpired"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <CheckBox id="expired" label={NOTICE_TEXT.targetExpired} gap={10} checked={value} onChange={onChange} {...field} />
          )}
        />
      </FlexRow>
      <GapDivider thin />
      <LabelTypography size={12} weight={500} textColor="gray2">
        {NOTICE_TEXT.period}
      </LabelTypography>
      <Controller
        name="period"
        control={control}
        render={({ field: { value, onChange, ...field } }) => (
          <FlexRow>
            {PERIOD_RADIO_OPTIONS.map(option => (
              <Radio
                key={option.value}
                id={option.value}
                checked={value === option.value}
                {...option}
                {...field}
                onChange={changePeriod}
              />
            ))}
          </FlexRow>
        )}
      />
      <NoticePeriodPicker />
      <GapDivider />
    </>
  );
};

export default NoticeSettings;

const LabelTypography = styled(Typography)`
  padding: 16px 20px 0;
`;

const FlexRow = styled.div`
  padding: 12px 20px 0;
  ${theme.flex('row', 'center', 'flex-start', 20)}
  .checkbox > .typography {
    color: ${theme.color.gray1};
  }
`;

const GapDivider = styled(Divider)`
  margin-top: 24px;
  margin-bottom: 8px;
`;

import Button from 'components/Button';
import useQueryString from 'hooks/useQueryString';
import { useCallback } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';

import { AMOUNT_FIELDS } from '../constants';
import { TicketPaymentFormType } from '../types';

export type PaymentMethodsKeyType = Extract<
  FieldPath<TicketPaymentFormType>,
  'payment.card_amount' | 'payment.cash_amount' | 'payment.wiretransfer_amount'
>;

type Props = {
  text: string;
  keyValue: PaymentMethodsKeyType;
};

/**
 * 카드전액 등 각 결제 방법 필드에 판매가 전액을 적용하는 버튼.
 * 각 결제 수단에 체크가 되어 있는 경우에만 노출.
 */
const ApplyAllAmountButton = ({ text, keyValue }: Props) => {
  const { getValues, setValue } = useFormContext<TicketPaymentFormType>();
  const { getSearchParams } = useQueryString();
  const isRefund = getSearchParams('type') === 'refund';

  const setZeroOtherFields = useCallback(() => {
    let allFields = [...AMOUNT_FIELDS];

    /** 환불 폼에서 포인트 환불 0으로 세팅 */
    if (isRefund) {
      allFields = [...allFields, 'payment.reward_point'];
    } else {
      /** 그 외 결제 폼에서는 미수금 0으로 세팅 */
      allFields = [...allFields, 'payment.unpaid_amount'];
    }
    const otherFields = allFields.filter(item => item !== keyValue);
    otherFields.map(otherKeyValue => setValue(otherKeyValue, 0, { shouldDirty: true }));
  }, [isRefund, keyValue, setValue]);

  const click = useCallback(() => {
    const {
      payment: { discount },
      salePrice,
    } = getValues();
    const discountedAmount = Math.ceil(salePrice * (1 - discount / 100));

    setValue(keyValue, discountedAmount, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setZeroOtherFields();
  }, [getValues, keyValue, setValue, setZeroOtherFields]);

  return (
    <Button size="tiny" variant="outlined" edge="circular" padding={{ left: 8, right: 8 }} textColor="gray2" onClick={click}>
      {text}전액
    </Button>
  );
};

export default ApplyAllAmountButton;

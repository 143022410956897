import useGetEtcScheduleEtcSchedule from 'hooks/service/queries/useGetEtcScheduleEtcSchedule';
import useGetStaffMe from 'hooks/service/queries/useGetStaffMe';

import convertBulkData from '../utils/convertBulkData';
import CopyForm from './CopyForm';
import UpdateForm from './UpdateForm';

type Props = {
  currentEtcScheduleId: number;
  mode: 'copy' | 'update';
};

const EtcBulk = ({ currentEtcScheduleId, mode }: Props) => {
  const { data: staff } = useGetStaffMe();
  const { data } = useGetEtcScheduleEtcSchedule(currentEtcScheduleId);

  return (
    <>
      {mode === 'update' ? (
        <UpdateForm data={convertBulkData(data)} staff={staff} />
      ) : (
        <CopyForm data={convertBulkData(data)} staff={staff} />
      )}
    </>
  );
};

export default EtcBulk;

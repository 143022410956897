import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import MiniTitle from 'designedComponents/Drawers/DrawerTitle/MiniTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import useGetMobilePoint from 'hooks/service/queries/useGetMobilePoint';
import { useState } from 'react';
import filters from 'utils/filters';

import { MOBILE_POINT_TEXT } from '../constants';

const CurrentPoint = () => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const {
    data: { point },
  } = useGetMobilePoint();
  const [isTooltipOpened, setIsTooltipOpened] = useState(point === 0);

  return (
    <>
      <StyledTooltip
        isOpen={isTooltipOpened}
        position="right"
        message={MOBILE_POINT_TEXT.notEnoughTooltip}
        showCloseButton
        onClose={() => setIsTooltipOpened(false)}>
        <CurrentPointButton
          size="medium40"
          variant="contained"
          color="gray"
          borderRadius={8}
          gap={8}
          onClick={() => setIsDrawerOpened(true)}
          padding={{ left: 16, right: 16 }}
          leftIcon={<Typography className="text">{MOBILE_POINT_TEXT.point}</Typography>}
          rightIcon={
            <Typography className="suffix" textColor="primary" weight={600}>
              {MOBILE_POINT_TEXT.suffix}
            </Typography>
          }
          fontWeight={600}
          textColor="primary"
          ellipsis={1}>
          {filters.numberComma(point)}
        </CurrentPointButton>
      </StyledTooltip>
      <SingleButtonDrawer
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
        header={
          <MiniTitle subTitle={MOBILE_POINT_TEXT.point} title={`${filters.numberComma(point)}${MOBILE_POINT_TEXT.suffix}`} />
        }
        button={{ text: MOBILE_POINT_TEXT.confirm, onClick: () => setIsDrawerOpened(false) }}>
        <Typography size={15} textColor="gray2" className="point-description">
          {MOBILE_POINT_TEXT.description}
        </Typography>
      </SingleButtonDrawer>
    </>
  );
};

export default CurrentPoint;

const StyledTooltip = styled(Tooltip)`
  min-width: 183px;
  .tooltip-contents-container {
    right: 0;
    margin-top: -8px;
    transform: none;
  }
`;

const CurrentPointButton = styled(Button)`
  width: 100%;
  ${theme.flex('', 'center', 'space-between')}
  span {
    flex-grow: 1;
    text-align: right;
  }
  .text {
    flex: 0 0 65px;
    width: 65px;
    text-align: left;
  }
  .suffix {
    width: 9px;
    flex: 0 0 9px;
  }
`;

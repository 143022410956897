import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import { HoldingResponse } from 'hooks/service/queries/useInfinityHolding';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { ArrangeTypographyWrapper, CommonStyleCard } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';
import getDaysDiff from 'utils/getDaysDiff';

type Props = Pick<UserTicketProps, 'canUpdateUserTicket'> & {
  history: HoldingResponse;
  onCancel?: (holdingId: number) => void;
};

const HistoryCard = ({ canUpdateUserTicket, history, onCancel }: Props) => {
  const { auto_calculation, start_on, end_on, id } = history;

  const holdingDays = getDaysDiff(end_on, start_on) + 1;
  const isFutureHolding = dayjs(start_on).isAfter(dayjs());

  return (
    <Container>
      <div>
        <Typography size={15} weight={500}>
          {filters.period(start_on, end_on)}
        </Typography>
        <TextWrapper>
          {!!auto_calculation && (
            <Typography span textColor="gray2">
              자동계산
            </Typography>
          )}
          <Typography span textColor="gray2">
            {holdingDays}일 정지
          </Typography>
          {isFutureHolding && (
            <Typography span textColor="primary">
              예정
            </Typography>
          )}
        </TextWrapper>
      </div>
      {canUpdateUserTicket && isFutureHolding && (
        <Button size="tiny" variant="outlined" textColor="gray2" padding={{ left: 6, right: 6 }} onClick={() => onCancel?.(id)}>
          취소
        </Button>
      )}
    </Container>
  );
};

export default HistoryCard;

const Container = styled(CommonStyleCard)`
  ${theme.flex('', 'center', 'space-between')}
`;

const TextWrapper = styled(ArrangeTypographyWrapper)`
  margin-top: 2px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { ScheduleManagementDeletedLecture } from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import filters from 'utils/filters';

import CommonDetailInfo from './CommonDetailInfo';

type Props = {
  isOpen: boolean;
  scheduleInfo: ScheduleManagementDeletedLecture;
  onClose: () => void;
};

const DeletedNoBookingDetailInfo = ({ isOpen, scheduleInfo, onClose }: Props) => {
  return (
    <StyledSingleButtonDrawer isOpen={isOpen} onClose={onClose} header={<OneLinedTitle title={scheduleInfo.title} />}>
      <CommonDetailInfo scheduleInfo={scheduleInfo} bookingInfo={null} />
      <Divider />
      <InfoWrapper>
        <Typography textColor="gray2" size={15} weight={400}>
          삭제일시
        </Typography>
        <Typography textColor="secondary3" size={15} weight={500}>
          {filters.dateTimeWithWeekday(scheduleInfo.deleted_at)}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper>
        <Typography textColor="gray2" size={15} weight={400}>
          삭제한 사람
        </Typography>
        <Typography textColor="secondary3" size={15} weight={500}>
          {scheduleInfo.deleted_by ? scheduleInfo.deleted_by.name : '-'}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper className="last-info-wrapper">
        <Typography textColor="gray2" size={15} weight={400}>
          삭제사유
        </Typography>
        <Typography textColor="secondary3" size={15} weight={500}>
          {scheduleInfo.deleted_for ? scheduleInfo.deleted_for : '-'}
        </Typography>
      </InfoWrapper>
    </StyledSingleButtonDrawer>
  );
};

export default DeletedNoBookingDetailInfo;

const StyledSingleButtonDrawer = styled(Drawer)`
  padding-left: 0px;
  padding-right: 0px;
`;
const InfoWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between')}
  height: 56px;
  padding: 17px 20px;
`;

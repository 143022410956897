import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import usePostBookingPrivate from 'hooks/service/mutations/usePostBookingPrivate';
import usePopup from 'hooks/usePopup';
import FailAllBooking from 'pages/Booking/components/AfterRequestBooking/FailAllBooking';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { AllPageModeType } from 'pages/Booking/types';
import {
  convertRepeatWeekdayNum,
  formatBookingPrivateParams,
  formatConfirmBookingInfo,
  getTotalLectureCount,
} from 'pages/Booking/utils';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import LeftSmallButtonGroup from 'sharedComponents/LeftSmallButtonGroup';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
};

const ConfirmBeforeBooking = ({ pageMode }: Props) => {
  const { getValues } = useFormContext<BookingPrivateFormType>();
  const navigate = useNavigate();
  const { setPopup } = usePopup();

  const { repeat, repeatCustomWeekday, startDate, endDate } = getValues();

  const totalCount = getTotalLectureCount({
    repeat: convertRepeatWeekdayNum(repeat, repeatCustomWeekday),
    startDate,
    endDate,
  });
  const data = formatConfirmBookingInfo(getValues());

  const { mutate: bookingPrivateMutate, isPending } = usePostBookingPrivate();

  const submit = () => {
    const params = formatBookingPrivateParams(getValues());
    bookingPrivateMutate(params, {
      onSuccess: res => {
        if (!res.data.success) {
          navigate(-1);
          setPopup(
            <FailAllBooking
              fails={res.data.fail}
              closePopup={() => {
                if (pageMode === 'create') {
                  navigate(-1);
                } else {
                  navigate('/schedule', { replace: true });
                }
              }}
            />,
          );
        } else {
          navigate(`/booking/private/complete?from=${pageMode}`, {
            replace: true,
            state: { data: res.data },
          });
        }
      },
    });
  };

  return (
    <Container>
      <Typography size={21} weight={700}>
        총
        <Typography className="count-typography" size={21} weight={700} textColor="primary" span>
          {totalCount}
        </Typography>
        개의 수업을 <br />
        일괄 예약하시겠습니까?
      </Typography>
      <Typography className="info-title" size={15} weight={600}>
        수업정보
      </Typography>
      <InfoFlexWrapper className="info-flex-wrapper" gap={8}>
        {data.map(({ value, label }) => (
          <StyledFlexRow gap={20} key={label}>
            <InfoTypography className="label-text">{label}</InfoTypography>
            <InfoTypography weight={500} textColor="gray1">
              {value}
            </InfoTypography>
          </StyledFlexRow>
        ))}
      </InfoFlexWrapper>

      <StyledButtonGroup
        leftButton={{
          text: '수정',
          disabled: isPending,
          onClick: () => navigate(-1),
        }}
        rightButton={{
          text: '예약',
          disabled: isPending || !totalCount,
          onClick: submit,
        }}
      />
    </Container>
  );
};

export default ConfirmBeforeBooking;

const Container = styled.div`
  height: 100%;
  padding: 16px 20px 0;
  ${theme.flex('column', 'flex-start', 'flex-start')};

  .count-typography {
    display: inline-block;
    margin-left: 3px;
  }

  .info-title {
    margin: 40px 0 16px;
  }

  .info-flex-wrapper {
    width: 100%;
    padding-bottom: 40px;
  }
`;

const StyledButtonGroup = styled(LeftSmallButtonGroup)`
  margin-top: auto;
  padding: 10px 0;
`;

const StyledFlexRow = styled(InfoFlexRow)`
  align-items: flex-start;

  .label-text {
    min-width: 56px;
  }
`;

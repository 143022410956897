import { useIsFetching } from '@tanstack/react-query';
import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import Main from './components/Main';

const MemberDetail = () => {
  const currentMemberId = useParamsId();
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <Main currentMemberId={currentMemberId} />
    </ApiBoundary>
  );
};

export default MemberDetail;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { StudioInfoResponse } from 'hooks/service/queries/useGetStudioInfo';
import { Fragment } from 'react/jsx-runtime';

import { STUDIO_DETAIL_TEXTS, WEEKDAYS_KO } from '../../pages/MoreDetails/Studio/constants';

type Props = {
  title: string;
  openEvents: StudioInfoResponse['openEvents'];
};

const StudioDetailOpenHours = ({ title, openEvents }: Props) => {
  return (
    <Container>
      <InfoTitle size={15} textColor="gray2" opacity={0.8}>
        {title}
      </InfoTitle>
      <Grid>
        {WEEKDAYS_KO.map(day => {
          const openEvent = openEvents.find(openEvent => openEvent.day_of_week_ko === day);

          return (
            <Fragment key={day}>
              <Typography size={15}>{day}</Typography>
              {!openEvent || !openEvent?.start_time?.slice || !openEvent?.end_time?.slice ? (
                <Typography key={day} size={15} textColor="secondary3">
                  {STUDIO_DETAIL_TEXTS.closed}
                </Typography>
              ) : (
                <Typography key={day} size={15}>
                  {openEvent.start_time.slice(0, 5)} ~ {openEvent.end_time.slice(0, 5)}
                </Typography>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Container>
  );
};

export default StudioDetailOpenHours;

const Container = styled.div`
  ${theme.flex('row', '', '', 24)}
`;

const InfoTitle = styled(Typography)`
  flex-shrink: 0;
  opacity: 0.8;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 13px 1fr;
  row-gap: 4px;
  column-gap: 16px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Drawer from 'components/Drawer';
import Typography from 'components/Typography';
import { useNavigate } from 'react-router-dom';

import { CALENDAR_SETTING_DRAWER } from './constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CalendarSettingDrawer = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} headerText="설정">
      <DrawerContainer>
        {CALENDAR_SETTING_DRAWER.map(({ id, link, title, subTitle }) => (
          <LinkButton key={id} fontWeight={600} onClick={() => navigate(link)} showArrow={false} showBorder={false}>
            <Typography size={17} weight={600}>
              {title}
            </Typography>
            <Typography size={14} textColor="gray2">
              {subTitle}
            </Typography>
          </LinkButton>
        ))}
      </DrawerContainer>
    </Drawer>
  );
};

const DrawerContainer = styled.div`
  ${theme.flex('column', '', '', 8)};
  margin: 20px 0 40px;

  .link-button-contents-wrapper {
    ${theme.flex('column', '', '', 2)};
  }
`;

export default CalendarSettingDrawer;

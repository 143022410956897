export const UNIFIED_ACCOUNT_TEXT = {
  commonTop: '👋 안녕하세요!',
  unified: {
    title: ['이름과 닉네임을 추가해 주세요'],
    bottom: "닉네임은 '라운지'에서만 사용됩니다.",
  },
  selectId: {
    title: ['계정 통합을 위해', '아이디를 선택해 주세요'],
    bottom: '보다 편리한 이용을 위해 하나의 아이디로 통합됩니다.',
  },
};

export const DEFAULT_VALUE = {
  selectedId: null,
  teacherNameRequired: '',
  nickNameRequired: '',
};

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import usePostReportVerify from 'hooks/service/mutations/usePostReportVerify';
import useErrorDialog from 'hooks/useErrorDialog';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import { ReportPasswordFormType } from '../type';

const yupSchema = object().shape({ password: string().required('비밀번호를 입력해주세요.') });

const ReportPassword = () => {
  const navigate = useNavigate();
  const errorDialog = useErrorDialog();
  // 비밀번호 필드 타입 변경 컨트롤
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const { mutate: postReportVerity } = usePostReportVerify();
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { isValid, isSubmitting },
  } = useForm<ReportPasswordFormType>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = (value: ReportPasswordFormType) => {
    const { password } = value;
    const param = {
      password,
    };

    postReportVerity(param, {
      onSuccess: ({ data }) => {
        const { result } = data;
        if (!result) {
          setError('password', { message: '비밀번호가 일치하지 않습니다.' });
        } else {
          navigate('/more-details/report-list', { replace: true });
        }
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <OutlinedTextFieldController
        controllerProps={{ control, name: 'password' }}
        type={showPwd ? 'text' : 'password'}
        placeholder="비밀번호 입력"
        suffix={
          <IconButton onClick={() => setShowPwd(!showPwd)}>
            <Icon name={showPwd ? 'showOff' : 'showOn'} fillColor={theme.color.gray3} />
          </IconButton>
        }
        onClear={() => setValue('password', '')}
      />
      <SubmitButtonWrapper>
        <Button
          type="submit"
          color="point"
          fontSize={15}
          fullWidth
          size="medium48"
          variant="contained"
          disabled={!isValid || isSubmitting}>
          완료
        </Button>
      </SubmitButtonWrapper>
    </Form>
  );
};

export default ReportPassword;

const SubmitButtonWrapper = styled.div`
  padding-top: 24px;
`;

import ReactDOM from 'react-dom';

type Props = {
  children: React.ReactNode;
};

const TutorialPortal = ({ children }: Props) => {
  const element = document.getElementById('tutorial-root') as HTMLElement;

  return element && children ? ReactDOM.createPortal(children, element) : null;
};

export default TutorialPortal;

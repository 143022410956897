import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  mobile: string;
};

const usePostSendMobileConfirmCodeForPasswordChange = ({ mobile }: Props) => {
  const { mutationFn } = useMutationFnWithCommonParams<NoParams, Response>({
    method: 'post',
    url: `/api/auth/send-mobile-confirm-code-for-password-change/${mobile}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostSendMobileConfirmCodeForPasswordChange;

type NoParams = {
  studio_id: undefined;
  staff_id: undefined;
};

type Response = {
  validation_code: string;
};

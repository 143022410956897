import { MemberSelectedFilterType } from 'pages/Member/types';

import { MessageDetailType } from '../../types';
import { FILTER_MEMBER_TAG_ADAPTER } from './constants';

// 회원필터 => 시설웹 공용 필터로 변환
export const convertMemberFilterToMessageFilter = (
  memberFilter: MemberSelectedFilterType,
  keyword: string,
): MessageDetailType['filter'] => {
  const { user_grade_id, tag, search, ticket_type, ...filter } = memberFilter;

  return {
    ...filter,
    type: tag ? FILTER_MEMBER_TAG_ADAPTER.message[tag] : null,
    keyword: keyword ?? null,
    course_type: ticket_type ?? null,
    user_grade: user_grade_id ?? null,
  };
};

/**
 * 시설웹 공용필터 => 회원 필터로 변환
 * 회원 필터는 값이 있는 속성갯수를 보이는 UI요소 때문에 필요한 속성만 주입
 */
export const convertMessageFilterToMemberFilter = (messageFilter: MessageDetailType['filter']): MemberSelectedFilterType => {
  if (!messageFilter) return {} as MemberSelectedFilterType;

  const {
    type,
    course_type,
    user_grade,
    remaining_coupon,
    remaining_start_date,
    remaining_end_date,
    attendance_count_start_date,
    attendance_count_end_date,
    sort_type,
    sort_target,
    staff,
  } = messageFilter;

  return {
    tag: type ? FILTER_MEMBER_TAG_ADAPTER.member[type] : undefined,
    user_grade_id: user_grade ?? undefined,
    ticket_type: course_type ?? undefined,
    ticket_remaining:
      remaining_start_date && remaining_end_date
        ? {
            date: [remaining_start_date, remaining_end_date],
            count: remaining_coupon ?? undefined,
          }
        : undefined,
    attendance_count:
      attendance_count_start_date && attendance_count_end_date
        ? [attendance_count_start_date, attendance_count_end_date]
        : undefined,
    sort_type: sort_type ?? undefined,
    sort_target: sort_target ?? undefined,
    staff: staff ?? undefined,
  };
};

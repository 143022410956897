import styled from '@emotion/styled';
import CheckRadio from 'components/Radio/CheckRadio';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';

import MemberCard from '../MemberCard';

type Props = Pick<ComponentProps<typeof InfiniteScroll>, 'hasMore' | 'isLoading' | 'loadMore'> & {
  members: MemberResponse[];
};

const MemberList = ({ members, hasMore, isLoading, loadMore }: Props) => {
  const { control, setValue } = useFormContext<TicketTransferFormType>();
  useScrollRestoration('.infinite-scroll');

  if (!members.length) {
    return <NodataTypography>회원이 없습니다.</NodataTypography>;
  }

  return (
    <InfiniteScrollContainer hasMore={hasMore} isLoading={isLoading} loadMore={loadMore}>
      <ul>
        {members.map(member => (
          <li key={member.id}>
            <Controller
              control={control}
              name="selectedMemberId"
              render={({ field: { value } }) => (
                <CheckRadio
                  id={`${member.id}`}
                  value={member.id}
                  checked={member.id === Number(value)}
                  onChange={e => {
                    setValue('selectedMemberId', Number(e.target.value), { shouldDirty: true });
                    setValue('selectedMember', member, { shouldDirty: true });
                  }}
                  label={<MemberCard member={member} isSelected={member.id === Number(value)} />}
                />
              )}
            />
          </li>
        ))}
      </ul>
    </InfiniteScrollContainer>
  );
};

export default MemberList;

const InfiniteScrollContainer = styled(StyledSelectInfiniteScroll)`
  li {
    padding: 14px 0;

    .radio-check-label {
      gap: 8px;
    }
  }
`;

import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import NextButton from 'pages/ProductForm/components/SelectTicket/NextButton';
import Search from 'pages/ProductForm/components/SelectTicket/Search';
import { UserTicketProps } from 'pages/TicketDetail/types';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import UpgradeTicketList from './UpgradeTicketList';

const SelectUpgradeTicket = ({ userTicket }: UserTicketProps) => {
  const queryClient = useQueryClient();

  const cancelSearch = () => {
    queryClient.invalidateQueries({
      queryKey: ['user-ticket', 'upgrade'],
    });
  };

  return (
    <Container>
      <Search productType="userTicket" onCancel={cancelSearch} />

      <ApiBoundary>
        <UpgradeTicketList userTicket={userTicket} />
      </ApiBoundary>

      <NextButton />
    </Container>
  );
};

export default SelectUpgradeTicket;

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 76px;
  position: relative;
  ${theme.flex('column', '', '')};
`;

import { TabDataElementType } from 'components/Tabs';
import FoldingFilterTabs from 'components/Tabs/FoldingFilterTabs';
import useGetHistoryCount from 'hooks/service/queries/useGetHistoryCount';
import useTab from 'hooks/useTab';
import { CurrentMemberIdProps, HistoryFilterFormType } from 'pages/MemberDetail/types';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const HistoryCountTab = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { control, setValue } = useFormContext<HistoryFilterFormType>();
  const ticketId = useWatch({ control, name: 'ticketId' });

  const { data } = useGetHistoryCount({
    member_id: currentMemberId,
    user_ticket_id: ticketId === 'all' ? undefined : Number(ticketId),
  });

  /** 예약 상태 필터 탭 */
  const tabData: TabDataElementType[] = useMemo(() => {
    return [
      { value: 'all', label: `전체 ${data.total}` },
      { value: 'booked_list', label: `예약 ${data.booked}` },
      { value: 'attendance', label: `출석 ${data.attendance}` },
      { value: 'absence', label: `결석 ${data.absence}` },
      { value: 'noshow', label: `노쇼 ${data.noshow}` },
      { value: 'cancel', label: `취소 ${data.cancel}` },
    ];
  }, [data]);

  const { currentTab, onChange } = useTab(tabData);

  useEffect(() => {
    const value = currentTab.value as HistoryFilterFormType['status'];
    setValue('status', value, { shouldValidate: true });
  }, [currentTab.value, setValue]);

  return <FoldingFilterTabs data={tabData} value={currentTab.value} onChange={onChange} />;
};

export default HistoryCountTab;

import { FieldPath } from 'react-hook-form';

import { TicketPaymentFormType } from './types';

export const TICKET_FORM_TEXT = {
  shareSwitch: {
    label: '다중 예약 설정',
    title: '다중 예약 설정이란?',
  },
};

export const TICKET_PAYMENT_FORM_TEXT = {
  salePrice: { label: '판매가' },
  discount: { label: '할인율', placeholder: '0' },
  card: { label: '카드' },
  cash: { label: '현금' },
  wiretransfer: { label: '계좌이체' },
  unpaid: { label: '미수금' },
  refund: { label: '위약금' },
  point: {
    label: '포인트',
    accumulationLabel: '포인트 적립',
    withdrawLabel: '포인트 회수',
  },
};

export const AMOUNT_FIELDS: FieldPath<TicketPaymentFormType>[] = [
  'payment.card_amount',
  'payment.cash_amount',
  'payment.wiretransfer_amount',
  'payment.point_amount',
];

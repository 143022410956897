import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { UserTicketLectureResponse } from 'hooks/service/queries/useInfinityUserTicketLecture';
import UserTicketListItem from 'pages/Booking/SelectMember/UserTicketListItem';

type Props = {
  label?: string;
  selectedCount: number;
  selectedUserTickets: Array<UserTicketLectureResponse>;
  showMaxCount?: boolean;
  maxCount: number;
  duplicateUserTicketIds?: Array<number>;
};

/**
 * 프라이빗 수업 등록/이후 모든 수정/복사,
 * 수업 상세 > 회원추가 에서 공통으로 쓰이는 선택된 회원 UI
 */
const ReserveTicketsUI = ({
  label = '예약인원',
  selectedCount,
  selectedUserTickets,
  showMaxCount,
  maxCount,
  duplicateUserTicketIds,
}: Props) => {
  return (
    <>
      <Divider thin />
      <Container>
        <CountWrapper>
          <Typography span size={13} weight={500} textColor="gray2">
            {label}
          </Typography>
          <Typography span size={13} weight={500} textColor="gray3">
            <Typography span size={13} weight={600} textColor="primary">
              {selectedCount}
            </Typography>
            <>{showMaxCount && `/${maxCount}`}</>
          </Typography>
        </CountWrapper>
        {!selectedCount ? (
          <NoMemberTypography textColor="gray3">선택된 예약인원이 없습니다.</NoMemberTypography>
        ) : (
          <ul>
            {selectedUserTickets.map(ticket => (
              <UserTicketListItem key={ticket.id} data={ticket} duplicateUserTicketIds={duplicateUserTicketIds} />
            ))}
          </ul>
        )}
      </Container>
      <Divider thin />
    </>
  );
};

export default ReserveTicketsUI;

const Container = styled.div`
  width: 100%;
  flex: 0 0 auto;
  padding-top: 12px;

  .label-text {
    padding-left: 20px;
  }

  ul {
    padding: 0 20px;
    max-height: 220px;
    overflow-y: auto;
  }
`;

const CountWrapper = styled.div`
  padding: 0 20px;
  margin-bottom: 4px;
  ${theme.flex('', 'center', 'flex-start', 4)};
`;

const NoMemberTypography = styled(Typography)`
  ${theme.flex('column', 'center', 'center')};
  height: 124px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';

type Props = {
  title: string;
  infoText: string;
  hasLine?: boolean;
};

const TitleWithImage = ({ title, infoText, hasLine = false }: Props) => {
  return (
    <Container hasLine={hasLine}>
      <div className="studio-icon-wrapper">
        <Icon name="centerFill" size={24} fillColor={theme.color.gray3} />
      </div>
      <div className="info-wrapper">
        <Typography textColor="gray2" opacity={0.8}>
          {infoText}
        </Typography>
        <Typography size={17} weight={700}>
          {title}
        </Typography>
      </div>
    </Container>
  );
};

export default TitleWithImage;

const Container = styled.div<{ hasLine: boolean }>`
  ${theme.flex('row', 'center', 'flex-start', 16)};
  padding: 16px 20px 16px;

  .studio-icon-wrapper {
    ${theme.flex()};
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: ${theme.color.gray6};
  }

  .info-wrapper {
    ${theme.flex('column', 'flex-start', 'center', 2)};
  }

  ${({ hasLine }) =>
    hasLine &&
    `position: relative;
    :after {
      content: '';
      position: absolute;
      width: calc(100% - 40px);
      height: 1px;
      background-color: ${theme.color.gray6};
      bottom: 1px;
    }`}
`;

import styled from '@emotion/styled';
import { TicketsResponse } from 'hooks/service/queries/useInfinityTickets';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import TicketCard from 'sharedComponents/TicketCard';
import formatTicketCard from 'utils/formatTicketCard';

import getUpgradeTicketInfo from '../../utils/getUpgradeTicketInfo';
import { UpgradeInfoCardContainer } from './CurrentTicketCard';

type Props = UserTicketProps & {
  upgradeTicket: TicketsResponse;
};

const UpgradeTicketCard = ({ userTicket, upgradeTicket }: Props) => {
  const ticketDetail = getUpgradeTicketInfo({ userTicket, upgradeTicket });

  return (
    <Container className="upgrade-ticket-card">
      <TicketCard ticket={formatTicketCard(upgradeTicket)} />
      <InfoFlexWrapper className="info-flex-wrapper" gap={8}>
        {ticketDetail.map(({ label, diff, value }) => (
          <InfoFlexRow key={label}>
            <InfoFlexRow gap={2}>
              <InfoTypography size={14} opacity={0.88}>
                {label}
              </InfoTypography>
              {diff && (
                <InfoTypography size={14} weight={600} textColor="primary">
                  {diff}
                </InfoTypography>
              )}
            </InfoFlexRow>
            <InfoTypography size={14} weight={500}>
              {value}
            </InfoTypography>
          </InfoFlexRow>
        ))}
      </InfoFlexWrapper>
    </Container>
  );
};

export default UpgradeTicketCard;

const Container = styled(UpgradeInfoCardContainer)`
  background: rgba(108, 133, 251, 0.12);
`;

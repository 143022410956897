import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import usePatchLectureClose from 'hooks/service/mutations/usePatchLectureClose';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { BOOKING_DETAIL_OPEN_CLOSE_TEXT } from 'pages/Booking/constants';

type Props = {
  currentLectureId: number;
  openCloseDialog: 'open' | 'close';
  onClose: () => void;
};

const BookingOpenCloseDialog = ({ currentLectureId, openCloseDialog, onClose }: Props) => {
  const TEXT = BOOKING_DETAIL_OPEN_CLOSE_TEXT[openCloseDialog];

  const errorDialog = useErrorDialog();
  const { setToast } = useToast();
  const { mutate: bookingCloseMutate, isPending } = usePatchLectureClose(currentLectureId);

  /** 예약마감, 마감해제 동일한 api로 요청 */
  const clickPositiveAction = () => {
    bookingCloseMutate(undefined, {
      onSettled: onClose,
      onSuccess: () => {
        setToast({ type: 'success', message: TEXT.successMessage });
      },
      onError: error => {
        errorDialog.open(error);
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      title={TEXT.dialog.title}
      negativeAction={{
        text: TEXT.dialog.negativeText,
        disabled: isPending,
      }}
      positiveAction={{
        text: TEXT.dialog.positiveText,
        disabled: isPending,
        onClick: clickPositiveAction,
      }}>
      <DialogDescription tag="pre">{TEXT.dialog.description}</DialogDescription>
    </Dialog>
  );
};

export default BookingOpenCloseDialog;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { VALID_ERROR_TYPE_TEXT } from 'pages/Booking/Private/constants';
import { useState } from 'react';
import { InfoFlexRow, InfoFlexWrapper, InfoTypography } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';

type Props = {
  name: string;
  type: 'daily' | 'weekly' | 'monthly';
  typeLimit: number;
  dateList: Array<{ date: string; count: number }>;
};

const ValidErrorCard = ({ name, type, typeLimit, dateList }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const showMoreButton = dateList.length > 4;
  const displayList = isOpen ? dateList : dateList.slice(0, 4);

  return (
    <Container bgColor="gray7" showBoxShadow={false}>
      <div className="wrapper">
        <div className="top-wrapper">
          <Typography size={17} weight={600} ellipsis={1}>
            {name}
          </Typography>
          <Typography className="count-text" textColor="gray2" opacity={0.8}>
            ({VALID_ERROR_TYPE_TEXT.total[type]}
            {typeLimit}회)
          </Typography>
        </div>
        <StyledWrapper gap={8}>
          {displayList.map(({ date, count }) => (
            <StyledRow key={date}>
              <InfoTypography>{date}</InfoTypography>
              <Label
                size="regular"
                height={22}
                minWidth={52}
                textColor="secondary3"
                color="secondary3"
                opacity={0.12}
                borderRadius={3}
                padding={6}>
                {VALID_ERROR_TYPE_TEXT.count[type]}
                {count}회 초과
              </Label>
            </StyledRow>
          ))}
        </StyledWrapper>
      </div>

      {showMoreButton && (
        <StyledMoreButton
          fullWidth
          textColor="gray2"
          rightIcon={<Icon name={isOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} color="gray3" />}
          onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? '접기' : '더보기'}
        </StyledMoreButton>
      )}
    </Container>
  );
};

export default ValidErrorCard;

const Container = styled(CommonStyleCard)`
  padding: 0;

  .wrapper {
    padding: 18px 20px;
  }
  .top-wrapper {
    ${theme.flex('row', 'center', '', 4)};
    padding-bottom: 14px;
    border-bottom: 1px solid ${theme.color.gray5};

    .count-text {
      flex: 0 0 auto;
    }
  }
`;

const StyledWrapper = styled(InfoFlexWrapper)`
  padding-top: 14px;

  .label {
    flex: 0 0 auto;
  }
`;

const StyledRow = styled(InfoFlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;

const StyledMoreButton = styled(Button)`
  padding: 18px 0;
  position: relative;
  border-top: 1px solid ${theme.color.gray5};
  border-radius: 0;
`;

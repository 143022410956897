import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const useDeleteCounseling = (counselId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/counseling/${counselId}`,
  });

  return useMutation({
    mutationFn,
  });
};

export default useDeleteCounseling;

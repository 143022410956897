import { useSuspenseQuery } from '@tanstack/react-query';
import { StaffType } from 'types/staffTypes';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStaffMe = () => {
  const { queryFn } = useQueryFnWithCommonParams<StaffMeResponse>({
    url: '/api/staff/me',
  });

  return useSuspenseQuery({
    queryKey: ['staff/me'],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetStaffMe;

export type StaffMeResponse = {
  data: StaffType;
};

import styled from '@emotion/styled';
import Textarea from 'components/Textarea';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import Typography from 'components/Typography';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhotoArea from 'sharedComponents/MemoForm/PhotoArea';

import { NOTICE_TEXT } from '../../constants';
import { NOTICE_FORM_TEXT } from '../constants';
import { NoticeFormType } from '../types';

const TitleContents = () => {
  const { control, setValue } = useFormContext<NoticeFormType>();
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={containerRef}>
      <LabelTypography size={12} weight={500} textColor="gray2">
        {NOTICE_TEXT.title}
      </LabelTypography>
      <OutlinedTextFieldController
        controllerProps={{ control, name: 'title' }}
        placeholder={NOTICE_FORM_TEXT.titlePlaceholder}
        onClear={() => setValue('title', '', { shouldValidate: true })}
        onFocus={() => containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })}
      />
      <LabelTypography size={12} weight={500} textColor="gray2">
        {NOTICE_TEXT.contents}
      </LabelTypography>
      <Controller
        name="contents"
        control={control}
        render={({ field }) => <Textarea {...field} height={224} placeholder={NOTICE_FORM_TEXT.contentsPlaceholder} />}
      />
      <StyledPhotoArea />
    </Container>
  );
};

export default TitleContents;

const Container = styled.div`
  padding: 0 20px;
`;

const LabelTypography = styled(Typography)<{ top?: number; bottom?: number }>`
  margin: 32px 0 8px;
`;

const StyledPhotoArea = styled(PhotoArea)`
  padding-bottom: 24px;
`;

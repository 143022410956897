import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import UnderlinedTextFieldController from 'components/TextField/UnderlinedTextFieldController';
import Typography from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import { ChangeEvent, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import filters from 'utils/filters';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import { AMOUNT_LIMIT, isNotValidAmount } from './PaymentTextField';
import { PaymentCheckboxRow } from './StyledComponents';

const Point = () => {
  const { control, setValue, watch } = useFormContext<TicketPaymentFormType>();
  const {
    isCheckedPoint,
    remainingPoint,
    payment: { amount, point_amount: pointAmount },
  } = watch();

  const { getSearchParams } = useQueryString();
  // 양도할 때
  const isTransfer = getSearchParams('type') === 'transfer';
  // 결제 정보 수정
  const isEdit = getSearchParams('type') === 'payment';

  const pointAmountControllerValue = useMemo(() => {
    if (isCheckedPoint && (isTransfer || isEdit)) {
      return pointAmount;
    } else if (!remainingPoint || !pointAmount) {
      return 0;
    }
    // 입력한 포인트가 가격 보다 많으면서 가격이 잔여보인트보다 적을 때
    else if (Number(pointAmount) > amount && amount < remainingPoint) {
      return amount;
    } else {
      return pointAmount;
    }
  }, [amount, isCheckedPoint, isEdit, isTransfer, pointAmount, remainingPoint]);

  const clickAllApplyButton = () => {
    setValue('payment.point_amount', remainingPoint, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const changePoint = (e: ChangeEvent<HTMLInputElement>) => {
    const pointNum = filters.numberComma(e.target.value, { reverse: true });
    const currentPointAmount = Number(pointNum); // 현재 입력하는 포인트금액
    const isNotValid = isNotValidAmount(pointNum);
    const value =
      currentPointAmount > remainingPoint
        ? remainingPoint
        : currentPointAmount > AMOUNT_LIMIT
          ? AMOUNT_LIMIT
          : currentPointAmount;
    setValue('payment.point_amount', isNotValid ? 0 : value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <>
      <PaymentCheckboxRow>
        <div className="left">
          <Controller
            name="isCheckedPoint"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <CheckBox
                gap={10}
                id="point"
                label={TICKET_PAYMENT_FORM_TEXT.point.label}
                checked={value}
                value={'point'}
                onChange={e => {
                  if (!e.target.checked) {
                    setValue('payment.point_amount', 0, { shouldDirty: true });
                  }
                  onChange(e);
                }}
                {...field}
              />
            )}
          />
        </div>
        <div className="right">
          <UnderlinedTextFieldController
            controllerProps={{ control, name: 'payment.point_amount' }}
            inputMode="numeric"
            placeholder="0"
            textAlign="right"
            suffix={
              <Typography span size={15} weight={500}>
                P
              </Typography>
            }
            disabled={!isCheckedPoint}
            value={filters.numberComma(pointAmountControllerValue)}
            onChange={changePoint}
          />
        </div>
      </PaymentCheckboxRow>
      <RemainingPointRow>
        <div className="left">
          <Typography span textColor="gray2">
            잔여 포인트
          </Typography>
        </div>
        {isCheckedPoint && (
          <div className="apply-all">
            <Button
              size="tiny"
              variant="outlined"
              edge="circular"
              padding={{ left: 8, right: 8 }}
              textColor="gray2"
              onClick={clickAllApplyButton}>
              전액입력
            </Button>
          </div>
        )}
        <div className="right">
          <Typography span textColor="gray2">
            {filters.numberComma(remainingPoint || 0)}
          </Typography>
          <Typography span textColor="gray2" className="suffix">
            P
          </Typography>
        </div>
      </RemainingPointRow>
    </>
  );
};

export default Point;

const RemainingPointRow = styled(PaymentCheckboxRow)`
  padding: 16px 20px 16px 54px;
  height: 52px;
  gap: 8px;

  .apply-all {
    margin-right: 4px;
  }

  .right {
    ${theme.flex('row', 'center', 'flex-end', 2)};

    .suffix {
      min-width: 13px;
      text-align: center;
    }
  }
`;

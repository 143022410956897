import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import AvatarLabel from 'designedComponents/Drawers/CheckRadioDrawer/AvatarLabel';
import CheckRadioTypography from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioTypography';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import SingleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/SingleButtonDrawer';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import getImageUrl from 'utils/getImageUrl';

import StaffLinkButton from './StaffLinkButton';

const Staffs = () => {
  const { control, setValue } = useFormContext<EtcScheduleCreateFormType>();
  const currentStaffId = useRecoilValue(staffIdAtom);
  const selectedStaffs = useWatch({ control, name: 'selectedStaffs' });
  const tempSelectedStaffs = useWatch({ control, name: 'tempSelectedStaffs' });

  const { data } = useGetFilterStaffs();
  const allStaffs = data.map(({ id, name, avatar }) => ({ id, name, imgUrl: getImageUrl(avatar) }));
  const [isOpen, setIsOpen] = useState(false);
  const isAllChecked = tempSelectedStaffs.length === data.length;
  const isOnlySomeChecked = !isAllChecked && !!tempSelectedStaffs.length;

  const closeDrawer = () => {
    setValue('tempSelectedStaffs', selectedStaffs);
    setIsOpen(false);
  };

  const save = () => {
    setValue('selectedStaffs', tempSelectedStaffs);
    setIsOpen(false);
  };

  return (
    <>
      <StaffLinkButton onClick={() => setIsOpen(true)} />

      <StyledDrawer
        isOpen={isOpen}
        onClose={closeDrawer}
        header={<OneLinedTitle title="강사 선택" />}
        button={{ text: '선택 완료', onClick: save, disabled: !tempSelectedStaffs.length }}>
        <Container isOnlySomeChecked={isOnlySomeChecked}>
          <Controller
            control={control}
            name="tempSelectedStaffs"
            render={({ field: { value, onChange } }) => {
              return (
                <CheckBox
                  id="all"
                  className="checkbox-all"
                  label={
                    <CheckRadioTypography weight={500} isSelected={false}>
                      전체
                    </CheckRadioTypography>
                  }
                  gap={16}
                  value="all"
                  iconName={isAllChecked || !value.length ? 'selectCheckBold' : 'lineBold'}
                  checkColor={isOnlySomeChecked || isAllChecked ? 'white' : 'gray5'}
                  checked={isAllChecked}
                  onChange={e => {
                    if (!e.target.checked) {
                      onChange([]);
                    } else {
                      onChange(allStaffs);
                    }
                  }}
                />
              );
            }}
          />
          <Divider thin thinColor="gray6" />
          <StyledUl>
            {data.map(({ id, name, avatar }) => {
              return (
                <li key={id}>
                  <Controller
                    control={control}
                    name="tempSelectedStaffs"
                    render={({ field: { value, onChange } }) => {
                      const checked = value.some(field => field.id === id);
                      return (
                        <CheckBox
                          id={`${id}`}
                          label={<AvatarLabel id={`${id}`} label={name} imgUrl={avatar} isMe={currentStaffId === id} />}
                          gap={16}
                          checked={checked}
                          onChange={e => {
                            const staffValue = {
                              id: id,
                              name: name,
                              avatar,
                            };
                            if (e.target.checked) {
                              onChange([...value, staffValue]);
                            } else {
                              onChange([...value.filter(field => field.id !== id)]);
                            }
                          }}
                        />
                      );
                    }}
                  />
                </li>
              );
            })}
          </StyledUl>
        </Container>
      </StyledDrawer>
    </>
  );
};

export default Staffs;

const StyledDrawer = styled(SingleButtonDrawer)`
  padding-top: 0;
`;

const Container = styled.div<{ isOnlySomeChecked: boolean }>(
  css`
    .checkbox-all {
      padding: 8px 0;
    }
    hr {
      margin-top: 12px;
    }
  `,
  ({ isOnlySomeChecked }) =>
    isOnlySomeChecked &&
    css`
      .checkbox-all {
        .input-select-base-input-wrapper input + span {
          border-color: ${theme.color.primary};
          background-color: ${theme.color.primary};
        }
      }
    `,
);

const StyledUl = styled.ul`
  ${theme.flex('column', '', '', 8)};
  padding-top: 12px;
  padding-left: 20px;

  li {
    padding: 8px 0;

    .input-select-base-input-wrapper {
      flex: 0 0 auto;
    }
  }
`;

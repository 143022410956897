import styled from '@emotion/styled';
import downArrow from 'assets/images/tutorial6_arrow.svg';
import settingImg from 'assets/images/tutorial6_setting.svg';
import Typography from 'components/Typography';

const TutorialSixth = () => {
  return (
    <Container>
      <ContentWrapper>
        <SettingWrapper>
          <img src={settingImg} alt="설정이미지" />
        </SettingWrapper>
        <ArrowTopRightWrapper>
          <img src={downArrow} alt="화살표이미지" />
        </ArrowTopRightWrapper>
        <TextWrapper>
          <Typography textColor="white" size={19} weight={600}>
            시간/요일, 기타일정,
          </Typography>
          <Typography span textColor="white" size={19} weight={600}>
            휴일 등의 &nbsp;
          </Typography>
          <span className="highlight-color">상세한 설정</span>
          <Typography span textColor="white" size={19} weight={600}>
            이
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            가능합니다.
          </Typography>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default TutorialSixth;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
`;

const SettingWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const ArrowTopRightWrapper = styled.div`
  position: absolute;
  top: 6rem;
  right: 3rem;
  img {
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 20rem;
  position: absolute;
  top: 9rem;
  right: 4.5rem;
  text-align: center;

  .highlight-color {
    font-size: 19px;
    font-weight: 600;
    color: #2cead7;
  }
`;

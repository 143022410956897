/**
 * 함수 지연 실행 시키고 싶을 때
 * @param ms 지연시킬 시간 (ms)
 */
const delay = (ms: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(); // ms 시간이 지난 후에 resolve 호출
    }, ms);
  });
};

export default delay;

import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Icon from 'components/Icon';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CheckRadioFilterContents from 'sharedComponents/CheckRadioFilterContents';

import { REGISTERED_DATA } from '../constants';
import { CounselingFormType } from '../type';

const IsRegistered = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { control } = useFormContext<CounselingFormType>();
  const isRegistered = useWatch({ control, name: 'is_registered' });

  const label = useMemo(() => {
    if (!isRegistered) return '회원등록 여부';
    return REGISTERED_DATA.find(item => item.value === isRegistered)?.label;
  }, [isRegistered]);

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <LinkButton
        gap={10}
        arrowBold
        arrowSize={16}
        fontWeight={500}
        leftIcon={<Icon name="possible" fillColor={theme.color.gray2} />}
        textColor={isRegistered ? 'gray1' : 'gray3'}
        onClick={() => setIsOpen(true)}>
        {label}
      </LinkButton>

      <Drawer header={<OneLinedTitle title="회원등록 여부" />} isOpen={isOpen} onClose={closeDrawer}>
        <CheckRadioFilterContents name="is_registered" control={control} data={REGISTERED_DATA} onClick={closeDrawer} />
      </Drawer>
    </>
  );
};

export default IsRegistered;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import LinkButton, { LinkButtonProps } from 'components/Button/LinkButton';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { ColorType } from 'types/colorTypes';

import AccessControl from './AccessControl';
import { MenuType } from './types';

const commonProps: Omit<LinkButtonProps, 'children'> = {
  padding: {},
  showArrow: false,
  fontWeight: 500,
};

type Props = {
  menus: MenuType[];
  isStudioOwner: boolean;
  renderMenu?: (menu: MenuType) => ReactNode;
};

const PermissionNavigation = ({ menus, isStudioOwner }: Props) => {
  const navigate = useNavigate();
  const studioId = useRecoilValue(studioIdAtom);
  const staffId = useRecoilValue(staffIdAtom);

  const { checkPermission } = useGetPermissionDoHavePermission();

  const renderDisabled = (menu: MenuType, index: number) => {
    const { name, icon } = menu;
    return (
      <BasicLinkButton>
        <LinkButton
          {...commonProps}
          textColor="gray3"
          leftIcon={icon && <Icon className="link-left-icon" name={icon} fillColor={theme.color.gray3 as ColorType} />}
          showBorder={menus.length - 1 > index}>
          {name}
        </LinkButton>
      </BasicLinkButton>
    );
  };

  const renderMenu = (menu: MenuType, index: number) => {
    const { name, icon, query } = menu;
    return (
      <BasicLinkButton
        onClick={() => {
          checkPermission(PERMISSION.role.edit.id, {
            onSuccess: () => {
              navigate(`?type=${query}`);
            },
          });
        }}>
        <LinkButton
          {...commonProps}
          leftIcon={
            icon && (
              <Icon
                className="link-left-icon"
                name={icon}
                fillColor={theme.color.gray2 as ColorType}
                color={theme.color.gray2 as ColorType}
              />
            )
          }
          showArrow
          arrowBold
          arrowSize={16}
          showBorder={menus.length - 1 > index}>
          {name}
        </LinkButton>
      </BasicLinkButton>
    );
  };

  return (
    <>
      {menus.map((menu, index) => {
        // 시설이 없거나 선택되어 있지 않으면 비활성화
        if (!studioId || !staffId) return renderDisabled;

        return (
          <ApiBoundary key={menu.name}>
            <AccessControl
              permissions={menu.permissions}
              policies={menu.policies}
              renderEnabled={isStudioOwner ? renderDisabled(menu, index) : renderMenu(menu, index)}
              renderDisabled={renderDisabled(menu, index)}
            />
          </ApiBoundary>
        );
      })}
    </>
  );
};

export default PermissionNavigation;

const BasicLinkButton = styled(Button)`
  .link-button {
    padding: 0 20px;
  }
  .link-left-icon {
    margin-right: 10px;
  }
`;

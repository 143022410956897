import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';
import { ReportCommonType, Ticket, UserTicket } from 'pages/MoreDetails/Report/type';

const useInfinityReportUnpaid = (params: ReportUnpaidParams) => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<ReportUnpaidResponse, ReportUnpaidParams>({
    url: `/api/report/unpaid`,
    params: { ...params },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`report/unpaid`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        unpaids: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
        total_amount: data.pages[0].data.total_amount,
      };
    },
  });
};

export default useInfinityReportUnpaid;

type UnpaidInfo = {
  /** 총 결제금액 */
  paid_amount: number;
  /** 남은 미수금 */
  unpaid_amount: number;
};

export type Payment = {
  /** id */
  id: number;
  /**  결제금액 */
  amount: number;
  /**  커미션 비용 */
  commission_amount: number;
  /**  카드 결제 금액 */
  card_amount: number;
  /**  현금 결제 금액 */
  cash_amount: number;
  /**  미수금 금액*/
  unpaid_amount: number;
  /**  포인트 금액 */
  point_amount: number;
  /**  직전 결제 금액 */
  before_user_ticket_amount: number;
  /**  양도금 비용 */
  transfer_amount: number;
  /**  커미션 비용 */
  wiretransfer_amount: number;
  /**  할부 기간 */
  installment_period: number;
  /**  결제일 */
  settlement_at: string;
  /**생성일 */
  created_at: string;
  /** 수정일 */
  updated_at: string;
};

export type UnpaidDataResponse = {
  /** 결제 정보 */
  payments: Payment[];
  /** 수강권 결제 구분 */
  label: 'new' | 're-take' | 'experience' | 'refund' | 'upgrade' | 'installment_payment' | 'commission' | 'transfer' | 'unpaid';
  /** 결제 구분
   * 'cash' : 현금 ,'card' : 카드 ,'mixed' : 복합
   */
  method: 'cash' | 'card' | 'mixed';
  /** 수강권 */
  ticket: Ticket;
  /** 유저 수강권 */
  user_ticket: UserTicket;
  /** 미수금 정보 */
  unpaid_info: UnpaidInfo;
  /** 결제일 */
  settlement_at: string;
} & ReportCommonType;

export type ReportUnpaidParams = {
  /** 이름검색 */
  search?: string;
  /** 상품 구분 */
  product_type?: 'S' | 'R' | 'G' | 'P';
  /** 결제 방법 */
  payment_method?: string[];
  /** 페이지네이션 종류 */
  paginate_type: string;
  /** 리스트 솔트 기준 */
  order_by?: string;
};

export type ReportUnpaidResponse = {
  data: UnpaidDataResponse[];
  /** 미수금 총액 */
  total_amount: number;
};

import dayjs, { Dayjs } from 'dayjs';

import { safeDayjs } from './safeDayjs';

type ValueType = string | Date | Dayjs;

/**
 * 날짜 형식
 */
const date = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY.MM.DD');
};

const dateDash = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY-MM-DD');
};

const dateWithWeekday = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY.MM.DD (ddd)');
};

const dateYearMonth = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY.MM');
};

const dateMonthDay = (value?: ValueType) => {
  return safeDayjs(value).format('MM.DD');
};

const dateYearMonthKor = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY년 MM월');
};

const dateYearMonthDayKor = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY년 M월 D일 ');
};

const dateMonthDayKor = (value?: ValueType) => {
  return safeDayjs(value).format('M월 D일 ddd요일');
};

const dateYearMonthKorCompact = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY년 M월');
};

/**
 * 시간 형식
 */
const time = (value?: ValueType) => {
  return safeDayjs(value).format('HH:mm');
};

const timeSecond = (value?: ValueType) => {
  return safeDayjs(value).format('HH:mm:ss');
};

const timeSecondZero = (value?: ValueType) => {
  return safeDayjs(value).format('HH:mm:00');
};

const dateDashTime = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY-MM-DD HH:mm');
};

const dateTime = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY.MM.DD HH:mm');
};

const dateTimeWithWeekday = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY.MM.DD (ddd) HH:mm');
};

const dateTimeSecondWeekday = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY.MM.DD (ddd) HH:mm:ss');
};

const dateDashTimeSecond = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY-MM-DD HH:mm:ss');
};

const dateDashTimeSecondZero = (value?: ValueType) => {
  return safeDayjs(value).format('YYYY-MM-DD HH:mm:00');
};

/**
 * 기간 형식
 */
const period = (start?: ValueType, end?: ValueType) => {
  return `${start ? `${date(start)}` : ''} ~ ${end ? `${date(end)}` : ''}`;
};

const periodWithWeekday = (start?: ValueType, end?: ValueType) => {
  const startDate = start ? `${dateWithWeekday(start)}` : '';
  const endDate = end ? `${dateWithWeekday(end)}` : '';

  return startDate === endDate ? startDate : `${startDate} ~ ${endDate}`;
};

const timePeriod = (start?: ValueType, end?: ValueType) => {
  const startTime = start ? `${time(start)}` : '';
  const endTime = end ? `${time(end)}` : '';

  return startTime === endTime ? startTime : `${startTime} ~ ${endTime}`;
};

const dateTimePeriod = (start?: ValueType, end?: ValueType) => {
  const startTime = start ? `${dateTime(start)}` : '';
  const endTime = end ? `${dateTime(end)}` : '';

  return startTime && endTime ? `${startTime} ~ ${endTime}` : startTime || endTime || '';
};

/**
 * @param startTime 09:00:00
 * @param endTime 18:00:00
 * @returns 09:00 ~ 18:00
 */
const timePeriodForOnlyTime = (startTime?: string, endTime?: string) => {
  if (!startTime || !endTime) return '09:00 ~ 18:00';

  const start = startTime.slice(0, -3);
  const end = endTime.slice(0, -3);
  return `${start} ~ ${end}`;
};

/**
 * 휴대폰 번호 형식 (000-0000-0000 | 000-000-0000)
 */
const mobile = (mobile: string | null) => {
  if (!mobile) return;

  const endIndex = mobile.length === 11 ? 7 : 6;
  return `${mobile.slice(0, 3)}-${mobile.slice(3, endIndex)}-${mobile.slice(endIndex)}`;
};

/**
 * 전화번호 형식 (지역번호 및 전국대표번호 포함)
 */

const contact = (contact: string | null) => {
  if (!contact || contact.includes('-')) return contact; // 이미 하이픈이 있는 경우

  if (contact.startsWith('02')) {
    // 서울 지역번호만 두 자리 02-123-4567 혹은 02-1234-5678
    const endIndex = contact.length === 10 ? 6 : 5;
    return `${contact.slice(0, 2)}-${contact.slice(2, endIndex)}-${contact.slice(endIndex)}`;
  } else if (contact.startsWith('1') && contact.length === 8)
    // 1588, 1577 등 전국번호 1588-1234
    return `${contact.slice(0, 4)}-${contact.slice(4, 8)}`;
  else {
    // 나머지는 3자리 070-123-4567 등은 휴대폰과 동일
    return mobile(contact);
  }
};

/**
 * 천 단위 콤마
 */
const numberComma = (value: string | number, reverse?: { reverse: boolean }) => {
  if (!value || (typeof value === 'string' && value.length < 4)) {
    return Number(value);
  }

  const regex = /\B(?=(\d{3})+(?!\d))/g;
  const numValue = String(value).replaceAll(',', '');

  if (reverse) {
    return Number(numValue);
  }
  return numValue.replace(regex, ',');
};

/**
 * 2개 이상의 문자열이 있을 때만 구분자 추가
 * e.g separator([isTopFixed ? '상단고정' : '', isPopupOn ? '팝업사용' : '']) => '상단고정/팝업사용' | "상단고정" | "팝업사용" | ""
 */
const separator = (texts: string[], separator?: string) => texts.filter(Boolean).join(separator || '/');

/**
 * @param date HH:mm:ss
 * @returns 오늘 날짜의 dayjs 객체
 * 오늘로 지정한 이유는 그냥 시간 계산이 필요한데, 시간 만으로는 검증이 어려움
 */
const timeToDayjs = (date: string) => {
  return dayjs(dayjs().format(`YYYY-MM-DD ${date}`));
};

const filters = {
  date,
  dateDash,
  dateWithWeekday,
  dateYearMonth,
  dateMonthDay,
  dateYearMonthKor,
  dateYearMonthDayKor,
  dateMonthDayKor,
  dateYearMonthKorCompact,
  time,
  timeSecond,
  timeSecondZero,
  dateTime,
  dateDashTime,
  dateTimeWithWeekday,
  dateTimeSecondWeekday,
  dateDashTimeSecond,
  dateDashTimeSecondZero,
  period,
  periodWithWeekday,
  timePeriod,
  dateTimePeriod,
  timePeriodForOnlyTime,
  mobile,
  contact,
  numberComma,
  separator,
  timeToDayjs,
};

export default filters;

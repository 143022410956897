import { useSuspenseQuery } from '@tanstack/react-query';
import { MobileDetailType } from 'pages/MoreDetails/Message/Mobile/Detail/type';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetMessageMobileDetail = (id: number) => {
  const { queryFn } = useQueryFnWithCommonParams<MessageMobileResponse>({
    url: `/api/message/mobile/${id}`,
  });

  return useSuspenseQuery({
    queryKey: [`message/mobile`, id],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetMessageMobileDetail;

type MessageMobileResponse = {
  data: MobileDetailType;
};

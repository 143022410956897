import { useSuspenseQuery } from '@tanstack/react-query';
import { ChannelType, FunnelType } from 'api/modules/counseling2';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { CounselStaffAvatarType } from '../mutations/usePostCounseling';

const useGetCounseling = (counseling: number) => {
  const { queryFn } = useQueryFnWithCommonParams<CounselingResponse, CounselingParams>({
    url: `/api/counseling/${counseling}`,
  });

  return useSuspenseQuery({
    queryKey: [`counseling`],
    queryFn,
    select: data => data.data,
  });
};

export default useGetCounseling;

type CounselingParams = {
  counseling: number;
};

type CounselingStaff = {
  id: number;
  name: string;
  color: string;
  staff_avatar: CounselStaffAvatarType;
};

type CounselingResponse = {
  data: CounselingDetail;
};

export type CounselingDetail = {
  id: number;
  studio_id: number;
  staff_id: number;
  staff: CounselingStaff;
  user_id: number;
  name: string;
  phone: string;
  channel: ChannelType;
  funnel: FunnelType;
  contents: string;
  start_on: string;
  end_on: string;
  created_at?: string;
  updated_at?: string;
};

import styled from '@emotion/styled';
import Divider from 'components/Divider';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import useToast from 'hooks/useToast';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import { MORE_DETAILS_TEXT } from '../constants';
import FakeNavigation from './FakeNavigation';
import Profile from './Profile';
import StudioChangeSelect from './StudioChangeSelect';
import StudioSelectButton from './StudioSelectButton';

const StudioUnknown = () => {
  const { data: studioData } = useGetStudio();
  const isStudioConnected = studioData.studios.length > 0;

  const { setToast } = useToast();

  const text = isStudioConnected ? MORE_DETAILS_TEXT.notSelected : MORE_DETAILS_TEXT.notConnected;
  const alertMessage = () => {
    setToast({ message: text.toast, bottom: 76 });
  };

  return (
    <>
      <StickyWrapper>
        <Profile substituteName={text.profile} />
        {isStudioConnected ? (
          <StudioChangeSelect />
        ) : (
          <StudioSelectButton onClick={alertMessage} placeholder={text.select} noArrow />
        )}
        <DividerWithMargin />
      </StickyWrapper>
      <FakeNavigation onBasicMenuClick={alertMessage} />
    </>
  );
};

export default StudioUnknown;

const DividerWithMargin = styled(Divider)`
  margin-bottom: 12px;
`;

import DayTimePicker, { DayTimePickerProps } from 'designedComponents/Pickers/DayTimePicker';
import RuleTypeAccordion from 'pages/Booking/components/AvailableTime/RuleType/RuleTypeAccordion';
import { RuleTypeContainer } from 'pages/Booking/components/AvailableTime/StyledComponents';
import { BOOKING_AVAILABLE_TIME_SETTING_TITLE } from 'pages/Booking/constants';
import { SmallTitleTypography } from 'pages/Booking/Group/Form/components/Operation/Create/OthersAvailableSetting';
import { RuleTypeCommonProps } from 'pages/Booking/types';
import { numToTimeColon } from 'pages/Booking/utils/convertTimes';

import ATypeAccordionHeader from './header/ATypeAccordionHeader';

type Props = RuleTypeCommonProps & {
  endDays: number;
  endTime: number;
  changeDate: DayTimePickerProps['onChange'];
};

/** 수업일 기준 - 마감시간  */
const AType = ({ iconName, isOpen, onToggle, textType, endDays, endTime, changeDate }: Props) => {
  return (
    <RuleTypeContainer className={`rule-type A ${textType}`}>
      <RuleTypeAccordion
        iconName={iconName}
        isOpen={isOpen}
        isLast={textType === 'cancel'}
        noBottomBorder={textType === 'booking'}
        onChange={() => onToggle(!isOpen)}
        height={56}
        header={
          <div>
            <SmallTitleTypography>{BOOKING_AVAILABLE_TIME_SETTING_TITLE[textType]}</SmallTitleTypography>
            <ATypeAccordionHeader isOpen={isOpen} textType={textType} endDays={endDays} endTime={endTime} />
          </div>
        }>
        <DayTimePicker endValues={{ day: endDays, time: numToTimeColon(endTime) }} onChange={changeDate} />
      </RuleTypeAccordion>
    </RuleTypeContainer>
  );
};

export default AType;

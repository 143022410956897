import styled from '@emotion/styled';
import CheckBox from 'components/CheckBox';
import {
  addNewTicket,
  arrangeCurrentSelectedUserTickets,
  getBookingCheckboxState,
  getNumberUserTicketId,
  removeSelectedTicket,
} from 'pages/Booking/utils/bookings';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import BookingCancelDialog from './BookingCancelDialog';
import ExceedWaitingDialog from './group/ExceedWaitingDialog';
import WaitingExceedDialog from './group/WaitingExceedDialog';
import MemberUserTicketCard from './MemberUserTicketCard';
import { BookingFormType, BookingUserTicket } from './types';

type Props = {
  userTicket: BookingUserTicket;
  disabled?: boolean;
};

const SelectUserTicketItem = ({ userTicket, disabled }: Props) => {
  const { control, getValues, watch, setValue } = useFormContext<BookingFormType>();
  const {
    canCancelBooking,
    currentBookingUserTickets,
    currentLecture: { max_trainee },
  } = getValues();
  const { overlapTickets } = watch();

  // 기존 예약된 회원 예약 취소하려고 선택한 수강권
  const [cancelUserTicket, setCancelUserTicket] = useState<BookingUserTicket | null>(null);
  // 그룹수업에서 초과/대기예약 하려고 선택한 수강권
  const [overBookingTicket, setOverBookingTicket] = useState<BookingUserTicket | null>(null);
  const [isOpenWaitingClose, setIsOpenWaitingClose] = useState(false);

  return (
    <>
      <Container>
        <Controller
          control={control}
          name="selectedUserTickets"
          render={({ field: { value: selectedUserTickets, onChange } }) => {
            const { checked, cancelDisabled, isDuplicated, currentBooking, alreadyBooked } = getBookingCheckboxState({
              selectedUserTickets,
              currentBookingUserTickets,
              userTicket,
              overlapTickets,
              canCancelBooking,
            });
            const userTicketId = userTicket.id;

            return (
              <CheckBox
                id={String(userTicketId)}
                label={
                  <MemberUserTicketCard
                    data={userTicket}
                    isDuplicated={isDuplicated}
                    showWaitingLabel={!!userTicket.waitingNumber}
                    waitingNumber={userTicket.waitingNumber}
                  />
                }
                gap={16}
                checked={checked}
                disabled={cancelDisabled || disabled}
                onChange={e => {
                  if (e.target.checked) {
                    // 인원초과 시 초과/대기 예약 dialog 노출
                    if (currentBooking.length >= max_trainee) {
                      setOverBookingTicket(userTicket);
                    } else {
                      const ticket = addNewTicket({ selectedUserTickets, userTicket, status: 'new_booked' });
                      onChange([...selectedUserTickets, ticket]);
                    }
                  } else {
                    // 기존 예약된 회원 선택해제 했을 때 예약취소 확인 dialog 노출
                    if (alreadyBooked) {
                      setCancelUserTicket(userTicket);
                    } else {
                      if (overlapTickets && overlapTickets.length) {
                        const removedOverlapTicket = overlapTickets.filter(
                          ticketId => ticketId !== getNumberUserTicketId(userTicket.id),
                        );
                        setValue('overlapTickets', removedOverlapTicket, { shouldDirty: true });
                      }
                      const removedSelectedTicket = removeSelectedTicket({ selectedUserTickets, userTicket });
                      const newArray = arrangeCurrentSelectedUserTickets(removedSelectedTicket, max_trainee);
                      onChange(newArray);
                    }
                  }
                }}
              />
            );
          }}
        />
      </Container>
      {cancelUserTicket && <BookingCancelDialog onClose={() => setCancelUserTicket(null)} cancelUserTicket={cancelUserTicket} />}

      {overBookingTicket && (
        <ExceedWaitingDialog
          overBookingTicket={overBookingTicket}
          onClose={() => setOverBookingTicket(null)}
          openWaitingCloseDialog={() => setIsOpenWaitingClose(true)}
        />
      )}

      {isOpenWaitingClose && (
        <WaitingExceedDialog
          overBookingTicket={overBookingTicket}
          onClose={() => {
            setIsOpenWaitingClose(false);
            setOverBookingTicket(null);
          }}
        />
      )}
    </>
  );
};

export default SelectUserTicketItem;

const Container = styled.li`
  .checkbox {
    flex-direction: row-reverse;
  }
  .input-select-base-input-wrapper {
    flex: 0 0 auto;
  }
  .check-box-label-wrapper {
    flex: 1;
  }
`;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetSfaffSudamateDetail = (params: SfaffSudamateDetailParams) => {
  const { queryFn } = useQueryFnWithCommonParams<SfaffSudamateDetailResponse, SfaffSudamateDetailParams>({
    url: `api/staff-sudamate/${params.id}`,
    params,
  });

  return useSuspenseQuery({
    queryKey: [`sfaff-sudamate/detail`, { ...params }],
    queryFn,
    select: data => {
      return data.data.data;
    },
  });
};

export default useGetSfaffSudamateDetail;

type Attachment = {
  id: number;
  path: {
    path: string;
    name: string;
    extension: string;
  };
  updated_at: string;
  created_at: string;
};

export type SfaffSudamateDetailParams = {
  id: number;
  /** local storage에 저장된 자신이 본글이 있으면 1, 아니면 0 */
  is_view: number;
  staff_id: undefined;
};

export type SfaffSudamateDetail = {
  id: number;
  /** 공지 우선 순위  */
  priority: number;

  staff_account: {
    id: number;
    name: string;
  };
  /** 공지 설정 유뮤 */
  is_notice: boolean;
  /** 제목 */
  title: string;
  /** 내용 */
  contents: string;
  /** 숨김처리 */
  is_hide: boolean;
  /** 조회수 */
  view_count: number;
  updated_at: string;
  created_at: string;
  attachments: Attachment;
};
export type SfaffSudamateDetailResponse = {
  data: SfaffSudamateDetail;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Processing from 'components/Processing';
import Typography from 'components/Typography';

type Props = {
  isReserve?: boolean;
};

const SendMessageLoading = ({ isReserve }: Props) => {
  const title = isReserve ? '메시지 예약중' : '메시지 발송중';

  return (
    <Container>
      <Processing />
      <Typography size={21} weight={700}>
        {title}
      </Typography>
      <Typography size={15} textColor="gray2">
        잠시만 기다려 주세요
      </Typography>
    </Container>
  );
};

export default SendMessageLoading;

const Container = styled.div`
  ${theme.flex('column', 'center', 'center', 8)};
  margin-top: -80px;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.white};
`;

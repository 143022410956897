import styled from '@emotion/styled';
import Button, { ButtonProps } from 'components/Button';
import { ReactNode } from 'react';

type Props = ButtonProps & {
  showIcon?: boolean;
  icon: ReactNode;
};

/** 날짜 선택 버튼 같이 회색 테두리에 오른쪽 아이콘 있는 버튼 스타일 */
const ButtonWithRightIconOutlined = ({ children, showIcon = true, icon, ...props }: Props) => {
  return (
    <StyledButton
      className="button-with-right-icon-outlined"
      fullWidth
      size="medium48"
      variant="outlined"
      fontSize={15}
      fontWeight={500}
      padding={{ left: 16, right: 12 }}
      rightIcon={showIcon ? icon : undefined}
      borderRadius={10}
      {...props}>
      {children}
    </StyledButton>
  );
};

export default ButtonWithRightIconOutlined;

const StyledButton = styled(Button)`
  justify-content: space-between;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useQueryString from 'hooks/useQueryString';
import { useEffect, useState } from 'react';

import SudamateSearch from './SudamateSearch';

const SudamateSubHeader = () => {
  const { getSearchParams } = useQueryString();
  const search = getSearchParams('search');
  const [isActiveSearch, setIsActiveSearch] = useState(false);

  useEffect(() => {
    if (search) {
      setIsActiveSearch(true);
    }
  }, []);

  return (
    <Container isActiveSearch={isActiveSearch}>
      {!isActiveSearch && (
        <Typography size={17} weight={700}>
          수다메이트
        </Typography>
      )}
      <SudamateSearch search={search} isActiveSearch={isActiveSearch} setIsActiveSearch={setIsActiveSearch} />
    </Container>
  );
};

export default SudamateSubHeader;

const Container = styled.div<{ isActiveSearch: boolean }>`
  ${theme.flex('', 'center', 'space-between')}
  position: sticky;
  height: 56px;
  padding: 0 20px;
  top: -1px;
  z-index: 10;
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${theme.color.gray7};

  .search-bar {
    width: ${({ isActiveSearch }) => (isActiveSearch ? '100%' : '70px')};

    .search-bar-input-wrapper {
      height: ${({ isActiveSearch }) => (isActiveSearch ? '40px' : '32px')};
      padding: ${({ isActiveSearch }) => (isActiveSearch ? '10px 16px' : '8px 12px')};
      gap: 4px;

      #sudamate-search {
        padding: 0px;
        font-size: 13px;
      }
    }
  }
`;

import FloatingActionButton from 'components/FloatingActionButton';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';

const CreateButton = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();

  const setBackStep = useSetRecoilState(errorBackStepAtom);
  const { checkPermission } = useGetPermissionDoHavePermission();

  const navigateIfHasPermission = () => {
    checkPermission(PERMISSION.board.notice.create.id, {
      onSuccess: () => navigate('form'),
      onError: () => setBackStep({ to: '/more-details/notice', options: { replace: true, state: { permissionDenied: true } } }),
    });
  };

  if (hasPermission(PERMISSION.board.notice.create)) {
    return <FloatingActionButton type="single" onClick={navigateIfHasPermission} bottom={24} />;
  }
  return null;
};

export default CreateButton;

import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { QnaDetailType } from 'pages/MoreDetails/Qna/Detail/types';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

/**
 * 1:1 문의 댓글 삭제
 */
const useDeleteBoardQna = (questionId: number, commentId: number) => {
  const queryClient = useQueryClient();
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `delete`,
    url: `/api/board/qna/${commentId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      // UI 즉시반영을 위한 캐시 조작
      queryClient.setQueryData<AxiosResponse<QnaDetailType>>(['board/qna', true, questionId], prev => {
        if (!prev) return prev;
        return {
          ...prev,
          data: {
            ...prev.data,
            comments: prev.data.comments.map(comment => {
              if (comment.id !== commentId) return comment;
              return { ...comment, contents: '삭제된 댓글입니다.', deleted_at: new Date().toISOString() };
            }),
          },
        };
      });
      // 캐시 업데이트
      queryClient.invalidateQueries({ queryKey: ['board/qna', true, questionId] });
    },
  });
};

export default useDeleteBoardQna;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { hexToRgba } from 'utils/styles';

import FilterTab from './FilterTab';
import { FilterTabsProps } from './FilterTabs';
import TabGroup from './TabGroup';

export type KeyStringObjType = { [key: string]: unknown };

export type ArrowFilterValuesType = boolean | string | number | null | undefined | Array<string | number | KeyStringObjType>;

export type ArrowFilterTabsProps = FilterTabsProps & {
  /**
   * 필터 옵션 중 '전체'가 아닌 필터가 선택되어 있을 때,
   * defaultColor 속성을 blue로 주기 위한 비교 데이터
   */
  filterValues: { [key: string]: ArrowFilterValuesType | { [key: string]: ArrowFilterValuesType } };
};

const ArrowFilterTabs = ({ data, filterValues, value, onChange }: ArrowFilterTabsProps) => {
  return (
    <TabGroup scrollable gap={6}>
      {data.map(tab => {
        const isSelected = tab.value === value;
        const rightIconDefaultFillColor = isSelected
          ? theme.color.white
          : filterValues[tab.value]
            ? theme.color.primary
            : theme.color.gray3;

        const textColor = isSelected ? 'white' : filterValues[tab.value] ? 'primary' : 'gray2';
        const fontWeight = isSelected || filterValues[tab.value] ? 600 : 400;

        return (
          <StyledTab
            key={tab.value}
            data={tab}
            isSelected={isSelected}
            padding={{ left: 12, right: 10 }}
            selectedColor="primary"
            hasSelectedValue={!!filterValues[tab.value]}
            defaultColor={filterValues[tab.value] ? 'blue' : 'gray'}
            textColor={textColor}
            fontWeight={fontWeight}
            lineHeight={16}
            gap={6}
            rightIcon={
              <Icon name={isSelected ? 'arrowTopBold' : 'arrowBottomBold'} size={12} fillColor={rightIconDefaultFillColor} />
            }
            onClick={onChange}
          />
        );
      })}
    </TabGroup>
  );
};

export default ArrowFilterTabs;

const StyledTab = styled(FilterTab)<{ hasSelectedValue: boolean; isSelected: boolean }>`
  ${({ isSelected, hasSelectedValue }) =>
    !isSelected &&
    hasSelectedValue &&
    css`
      background-color: ${hexToRgba(theme.color.primary, 0.12)};
    `}
`;

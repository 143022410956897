import { useQueryClient } from '@tanstack/react-query';
import { ProfileType, ScheduleType } from 'types/staffTypes';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  targetStaffId: number;
};

const usePatchStaffWorkTime = ({ targetStaffId }: Props) => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<StaffWorkTimeParams>({
    method: 'patch',
    url: `/api/staff/workTime/${targetStaffId}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['staff'],
      });
    },
  });
};

export default usePatchStaffWorkTime;

export type StaffWorkTimeParams = {
  profile?: Partial<WorkTimeProfileType>;
  schedules?: WorkTimeSchedulesType[];
};

type WorkTimeProfileType = Pick<
  ProfileType,
  'private_start_interval_on_off' | 'private_start_interval_am' | 'private_start_interval_pm'
>;

type WorkTimeSchedulesType = Omit<ScheduleType, 'id' | 'created_at' | 'updated_at'>;

import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import { useFormContext, useWatch } from 'react-hook-form';

import { arrangeCurrentSelectedUserTickets, removeSelectedTicket } from '../utils/bookings';
import { BookingFormType, BookingUserTicket } from './types';

type Props = {
  onClose: () => void;
  cancelUserTicket: BookingUserTicket;
};

const BookingCancelDialog = ({ cancelUserTicket, onClose }: Props) => {
  const { setValue, control, getValues } = useFormContext<BookingFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const {
    currentLecture: { max_trainee },
  } = getValues();
  const {
    member: { name },
  } = cancelUserTicket;

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: '취소', style: 'outlined', onClick: onClose }}
      positiveAction={{
        text: '확인',
        onClick: () => {
          const removedSelectedTicket = removeSelectedTicket({ selectedUserTickets, userTicket: cancelUserTicket });
          const newArray = arrangeCurrentSelectedUserTickets(removedSelectedTicket, max_trainee);
          setValue('selectedUserTickets', newArray, { shouldDirty: true });
          onClose();
        },
      }}>
      <DialogDescription>
        <Typography span size={15} weight={600}>
          [{name}]
        </Typography>
        님의 예약을 취소하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default BookingCancelDialog;

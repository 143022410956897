import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  target: string;
  onContinue: () => void;
  onCancel: () => void;
};
const BookingResetConfirmDialog = ({ target, onCancel, onContinue }: Props) => {
  return (
    <Dialog
      onClose={onCancel}
      positiveAction={{
        text: '계속',
        onClick: onContinue,
      }}
      negativeAction={{
        text: '취소',
        onClick: onCancel,
        style: 'outlined',
      }}>
      <DialogDescription>
        {target} 변경 시, <br />
        회원의 예약은 취소되고 횟수는 복구됩니다. <br />
        계속 하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default BookingResetConfirmDialog;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import { Z_INDEX } from 'constants/zIndex';
import usePatchStaffNoticeCheckInReadAll from 'hooks/service/mutations/usePatchStaffNoticeCheckInReadAll';
import usePatchStaffNoticeCommonReadAll from 'hooks/service/mutations/usePatchStaffNoticeCommonReadAll';
import usePolicy from 'hooks/usePolicy';
import useQueryString from 'hooks/useQueryString';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { NOTIFICATION_TABS, NOTIFICATION_TEXT } from '../constants';
import CheckInDot from './CheckIn/CheckInDot';
import NotificationTabs from './NotificationTabs';
import PushDot from './Push/PushDot';

const NotificationHeader = () => {
  const { getSearchParams } = useQueryString();
  const currentTab = getSearchParams('type') ?? NOTIFICATION_TABS.push.value;

  const { isPolicyAllowed } = usePolicy();
  const isCheckInTabAllowed = isPolicyAllowed('is_enter');

  const { mutate: readPushAllMutate } = usePatchStaffNoticeCommonReadAll();
  const { mutate: readCheckInAllMutate } = usePatchStaffNoticeCheckInReadAll();
  const readMutate = currentTab === 'push' ? readPushAllMutate : readCheckInAllMutate;

  const readAllNotification = () => {
    readMutate();
  };

  return (
    <HeaderWrapper>
      <ApiBoundary>
        <PushDot />
      </ApiBoundary>
      <ApiBoundary>{isCheckInTabAllowed && <CheckInDot />}</ApiBoundary>
      <NotificationTabs isNoCheckIn={!isCheckInTabAllowed} />
      <Button fontSize={13} textColor="gray2" onClick={readAllNotification}>
        {NOTIFICATION_TEXT.allRead}
      </Button>
    </HeaderWrapper>
  );
};

export default NotificationHeader;

export const HeaderWrapper = styled.div`
  padding-right: 20px;
  ${theme.flex('row', 'center', 'space-between')}
  position: sticky;
  top: 0;
  background-color: ${theme.color.white};
  border-bottom: 1px solid ${theme.color.gray6};
  z-index: ${Z_INDEX.stickyTab};
`;

import useGetEtcScheduleEtcScheduleTime from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useCheckPermissionEtcDetail from 'hooks/useCheckPermissionEtcDetail';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DetailMain from '../components/DetailMain';

type Props = {
  currentEtcScheduleId: number;
};

const IndividualDetail = ({ currentEtcScheduleId }: Props) => {
  const { data } = useGetEtcScheduleEtcScheduleTime(currentEtcScheduleId);
  const { canViewEtcSchedule, canCreateEtcSchedule, canUpdateEtcSchedule, canDeleteEtcSchedule, staffType } =
    useCheckPermissionEtcDetail(data.staffs);
  const navigate = useNavigate();

  useEffect(() => {
    if (!canViewEtcSchedule) {
      navigate('/error?type=403', { replace: true });
    }
  }, [canViewEtcSchedule, navigate]);

  return (
    <DetailMain
      staffType={staffType}
      currentEtcScheduleId={currentEtcScheduleId}
      data={data}
      canViewEtcSchedule={canViewEtcSchedule}
      canCreateEtcSchedule={canCreateEtcSchedule}
      canUpdateEtcSchedule={canUpdateEtcSchedule}
      canDeleteEtcSchedule={canDeleteEtcSchedule}
    />
  );
};

export default IndividualDetail;

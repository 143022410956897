import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetMobilePoint = () => {
  const { queryFn } = useQueryFnWithCommonParams<MobilePointResponse, MobilePointParams>({
    url: `/api/message/mobile/point`,
    params: { staff_id: undefined },
  });

  return useSuspenseQuery({
    queryKey: ['message', `mobile/point`],
    queryFn,
    select: data => data.data,
  });
};

export default useGetMobilePoint;

type MobilePointParams = {
  staff_id: undefined;
};

type MobilePointResponse = {
  point: number;
};

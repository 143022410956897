import DateCalendarPickerDrawer from 'designedComponents/Pickers/DateCalendarPicker/DateCalendarPickerDrawer';
import PickerButtonOutlined from 'designedComponents/Pickers/PickerButton/PickerButtonOutlined';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

import { HolidaySettingFormType } from '../../type';

const HolidayDate = () => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [tempDate, setTempDate] = useState('');

  const { control, setValue } = useFormContext<HolidaySettingFormType>();

  const holidayDate = useWatch({ control, name: 'holidayDate' });

  const openDatePicker = () => setIsDatePickerOpen(true);

  const changeDate = (e: { value: Date }) => setTempDate(filters.date(e.value));

  const closeDatePicker = () => {
    setTempDate(holidayDate);
    setIsDatePickerOpen(false);
  };

  const saveDate = () => {
    setValue('holidayDate', tempDate);
    setIsDatePickerOpen(false);
  };

  return (
    <>
      <PickerButtonOutlined onClick={openDatePicker} placeholder="날짜 선택">
        {tempDate}
      </PickerButtonOutlined>

      <DateCalendarPickerDrawer
        min={new Date()}
        isOpen={isDatePickerOpen}
        onClose={closeDatePicker}
        onChange={changeDate}
        onSave={saveDate}
      />
    </>
  );
};

export default HolidayDate;

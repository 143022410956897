import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

const SkeletonAssignedMemberList = () => {
  const length = 3;
  const dummyList = Array.from({ length }, (_, index) => index);
  return (
    <Container>
      {dummyList.map(i => (
        <Item key={i}>
          <div className="profile">
            <Skeleton height={40} width={40} type="circle" />
            <div className="info">
              <Skeleton height={20} width={245} type="round" />
              <Skeleton height={16} width={102} type="round" />
            </div>
          </div>

          <Divider thin />

          <div className="bottom">
            <Skeleton height={16} width={102} type="round" />
            <Skeleton height={14} width={152} type="round" />
            <Skeleton className="last" height={59} width={295} type="round" />
          </div>
        </Item>
      ))}
    </Container>
  );
};

export default SkeletonAssignedMemberList;

const Container = styled.ul`
  ${theme.flex('column', '', '', 12)};
  padding: 0 20px 20px;

  [type='round'] {
    border-radius: 3px;
  }
`;

const Item = styled.li`
  ${theme.flex('column', 'start', 'start')};
  padding: 18px 20px;
  border-radius: 16px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
  background-color: white;

  hr {
    width: 100%;
  }

  .profile {
    ${theme.flex('row', 'center', 'flex-start', 10)};
    margin-bottom: 14px;

    .info {
      ${theme.flex('column', 'flex-start', 'center', 4)};
    }
  }

  .bottom {
    ${theme.flex('column', 'flex-start', 'center', 5)};
    margin-top: 14px;

    .last {
      margin-top: 9px;
    }
  }
`;

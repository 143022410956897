import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import MessagePreview from 'pages/MoreDetails/Message/components/MessagePreview';
import { MESSAGE_PREVIEW_TEXT } from 'pages/MoreDetails/Message/constants';
import { MessageType } from 'pages/MoreDetails/Message/types';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';
import { smsByteCheck } from 'utils/smsByteCheck';

const MobilePreview = () => {
  const { control } = useFormContext<MessageType>();
  const message = useWatch({ control, name: 'message' });
  const target = useWatch({ control, name: 'target' });
  const users = useWatch({ control, name: 'users' });
  const counselingLogs = useWatch({ control, name: 'counseling_logs' });

  const messageLength = smsByteCheck(message ?? '');
  const smsType = messageLength > 90 ? 'lms' : 'sms';
  const targets = target === 'member' ? users : counselingLogs;

  return (
    <MessagePreview
      summary={
        <SmsDetails>
          <Typography weight={500} textColor="gray2">
            {MESSAGE_PREVIEW_TEXT[smsType]}
          </Typography>
          <Typography weight={500} textColor="gray2">
            {filters.numberComma(messageLength)}
            {MESSAGE_PREVIEW_TEXT.limit}
          </Typography>
          <Typography weight={500} textColor="gray2">
            {filters.numberComma(targets?.length || 0)}
            {MESSAGE_PREVIEW_TEXT.suffix}
          </Typography>
        </SmsDetails>
      }
      scrollable>
      <Typography size={15} weight={500} tag="pre">
        {message}
      </Typography>
    </MessagePreview>
  );
};

export default MobilePreview;

const SmsDetails = styled.div`
  ${theme.flex('', 'center', 'space-between')}
  border-top: 1px solid ${theme.color.gray6};
  border-bottom: 1px solid ${theme.color.gray6};
  padding: 9px;
  margin-top: 8px;
  > .typography {
    display: inline-block;
    flex: 1 0 auto;
    text-align: center;
    position: relative;
    :not(:first-of-type):after {
      content: ' ';
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 0;
      width: 1px;
      background-color: ${theme.color.gray5};
    }
    padding: 0 24px;
  }
`;

import DatePicker from 'designedComponents/Pickers/DatePicker';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

import { CommonProfileFormType } from '../types';

const Birthday = () => {
  const { control, setValue } = useFormContext<CommonProfileFormType>();
  const birthday = useWatch({ control, name: 'profile.birthday' });

  const changeDate = (e: { value: Date }) => {
    const date = filters.dateDash(e.value);
    setValue('profile.birthday', date, { shouldDirty: true });
  };

  return (
    <FormFieldAccordion iconName="birthday" header={birthday ? filters.date(birthday) : '생년월일'} hasValue={!!birthday}>
      <DatePicker value={birthday} defaultValue={new Date()} onChange={changeDate} />
    </FormFieldAccordion>
  );
};

export default Birthday;

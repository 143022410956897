import { ScheduleType } from 'types/staffTypes';
import filters from 'utils/filters';

import { StaffCreateFormType } from '../types';

const DAYS = [1, 2, 3, 4, 5, 6, 0] as Array<1 | 2 | 3 | 4 | 5 | 6 | 0>;
export const WORK_TIME_DEFAULT: ScheduleType[] = DAYS.map(day => {
  return {
    id: 0,
    date: '',
    type: 'workTime',
    day_of_week: day,
    start_time: '09:00:00',
    end_time: '18:00:00',
    created_at: '',
    updated_at: '',
  };
});

export const DEFAULT_VALUES_INIT: StaffCreateFormType = {
  profile: {
    name: '',
    registered_at: filters.dateDash(),
    representative_color: 'red0',
    gender: 'F',
    birthday: undefined,
    get_noti_all: false,
    self_introduction: '',
    private_start_interval_on_off: false,
    private_start_interval_am: 60,
    private_start_interval_pm: 60,
  },
  beforeName: '',
  mobile: '',
  role_id: 0,
  careers: [{ career: '' }],
  schedules: {
    workTimes: WORK_TIME_DEFAULT,
    restTimes: [],
  },
};

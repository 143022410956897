import { useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

/**
 * React-router-dom의 useHistory를 대체
 * navigate 이동 시 path 기록
 *
 * @returns path[] - 브라우저 히스토리 스택
 */

const useHistory = () => {
  const [navigationStack, setStack] = useState<string[]>([]);

  const type = useNavigationType();
  const { pathname, search } = useLocation();
  const stack = `${pathname}${search}`;

  useEffect(() => {
    if (type === 'PUSH') {
      setStack(prev => [...prev, stack]);
    } else if (type === 'POP') {
      setStack(prev => prev.slice(0, -1));
    } else {
      // REPLACE
      setStack(prev => [...prev.slice(0, -1), stack]);
    }
  }, [stack, type]);

  return navigationStack;
};

export default useHistory;

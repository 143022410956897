import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useQueryString from 'hooks/useQueryString';
import useSearchKeywords from 'hooks/useSearchKeywords';
import { useMemo } from 'react';
import NoSearchDataTypography from 'sharedComponents/NoSearchDataTypography';

import { FAQ_DATA, FAQ_IDENTIFIER } from '../constants';
import FaqItem from './FaqItem';

const FaqList = () => {
  const { searchKeyword } = useSearchKeywords(FAQ_IDENTIFIER);
  const { getAllSearchParams } = useQueryString();
  const params = getAllSearchParams();

  const filteredFaq = useMemo(() => {
    const isAll = !params?.category || params.category === '전체';
    return FAQ_DATA.filter(
      faq =>
        (isAll || params.category === faq.category) &&
        (!searchKeyword || faq.title.includes(searchKeyword) || JSON.stringify(faq.contents).includes(searchKeyword)),
    );
  }, [params.category, searchKeyword]);

  return (
    <Container>
      {!filteredFaq.length && searchKeyword && (
        <NoSearchWrapper>
          <NoSearchDataTypography keyword={searchKeyword} />
        </NoSearchWrapper>
      )}
      {filteredFaq.map(faq => (
        <FaqItem key={faq.title} faq={faq} />
      ))}
    </Container>
  );
};

export default FaqList;

const Container = styled.div`
  padding: 20px;
  height: auto;
  ${theme.flex('column', '', '', 12)}
`;

const NoSearchWrapper = styled.div`
  margin-top: 120px;
  ${theme.flex()}
`;

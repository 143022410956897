import styled from '@emotion/styled';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import { BookingType } from 'pages/Booking/types';

import BookingListItem from './BookingListItem';

type Props = {
  isMe: boolean;
  bookings: Array<LectureBookingListResponse>;
  bookingType: BookingType;
  currentBookingTraineeCount: number;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
};

const BookingList = ({ isMe, bookings, bookingType, currentBookingTraineeCount, canCancelBooking, canChangeBooking }: Props) => {
  const exceptWaitingList = bookings.filter(({ status }) => status !== 'booking_waiting');
  const waitingList = bookings.filter(({ status }) => status === 'booking_waiting');

  if (!bookings.length) {
    return null;
  }
  return (
    <>
      <Container>
        {exceptWaitingList.map(booking => (
          <BookingListItem
            key={booking.id}
            isMe={isMe}
            booking={booking}
            bookingType={bookingType}
            currentBookingTraineeCount={currentBookingTraineeCount}
            canCancelBooking={canCancelBooking}
            canChangeBooking={canChangeBooking}
          />
        ))}
        {waitingList.map((booking, index) => {
          const waitingOrder = index + 1;
          return (
            <BookingListItem
              key={booking.id}
              isMe={isMe}
              booking={booking}
              bookingType={bookingType}
              waitingOrder={waitingOrder}
              currentBookingTraineeCount={currentBookingTraineeCount}
              canCancelBooking={canCancelBooking}
              canChangeBooking={canChangeBooking}
            />
          );
        })}
      </Container>
    </>
  );
};

export default BookingList;

const Container = styled.ul`
  padding: 0 20px;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Tooltip from 'components/Tooltip';
import Typography, { TypographyProps } from 'components/Typography';
import { ReactNode, useState } from 'react';

type Props = TypographyProps & {
  tooltipMessages?: string[];
  tooltipIcon?: ReactNode;
  iconGap?: number;
};

const TypographyWithTooltip = ({ tooltipMessages, tooltipIcon, iconGap, className, ...props }: Props) => {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  return (
    <Row className={className}>
      <Typography {...props} />

      {tooltipMessages && tooltipMessages.length && (
        <Tooltip
          isOpen={isTooltipOpened}
          showCloseButton
          position="left"
          message={tooltipMessages}
          onClose={() => setIsTooltipOpened(false)}>
          <IconButton onClick={() => setIsTooltipOpened(true)}>{tooltipIcon}</IconButton>
        </Tooltip>
      )}
    </Row>
  );
};

export default TypographyWithTooltip;
const Row = styled.div<{ iconGap?: number }>`
  ${({ iconGap }) => theme.flex('', 'center', 'start', iconGap || 3)}
`;

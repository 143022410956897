import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { StaffSudamateCommentResponse } from 'hooks/service/queries/useInfinityStaffSudamateComment';

import { SUDAMATE_COMMENT_SORTING_FILTERS } from '../constant';
import Comment from './Comment';
import NoData from './NoData';
import Sorting from './Sorting';

type Props = {
  comments: StaffSudamateCommentResponse[];
  total: number;
};

const SudamateComments = ({ comments, total }: Props) => {
  return (
    <Container>
      <HeaderSection>
        <div className="comment-total">
          <Typography span weight={500} textColor="gray3" lineHeight={18}>
            댓글
          </Typography>
          <Typography span weight={500} textColor="primary" lineHeight={18}>
            {total}
          </Typography>
        </div>
        <Sorting sortOptions={SUDAMATE_COMMENT_SORTING_FILTERS} />
      </HeaderSection>
      <Divider thin />
      {total === 0 && <NoData />}
      {comments.map(comment => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </Container>
  );
};

export default SudamateComments;

const Container = styled.div``;

const HeaderSection = styled.section`
  ${theme.flex('row', 'center', 'space-between')}
  padding: 18px 20px;

  .comment-total {
    span:first-of-type {
      padding-right: 2px;
    }
  }
`;

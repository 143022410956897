import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import {
  Props as DefaultScheduleManagementParams,
  ScheduleManagementBookings,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';

type Props = {
  /**
   * 요일
   * 일요일 = 0, 월요일 = 1 , 화요일 = 2, 수요일 = 3, 목요일 = 4, 금요일 = 5, 토요일 = 6 */
  week?: string[];
  /**
   * 예약 상태
   *  출석, 결석, 노쇼
   */
  status?: 'attendance' | 'absence' | 'noshow';
  /**
   * 시작시간
   */
  start_time?: string;
  /**
   * 종료시간
   */
  end_time?: string;
  /**
   * 범위 검색여부
   * 0: 범위검색 안함
   * 1: 범위검색
   */
  is_range?: 0 | 1;
} & Omit<DefaultScheduleManagementParams, 'status'>;

const useInfinityScheduleManagementCheckin = (params: ScheduleManagementCheckinParams) => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<
    ScheduleManagementCheckinResponse,
    ScheduleManagementCheckinParams
  >({
    url: `/api/schedule-management/check-in`,
    params: {
      ...params,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`/schedule/management/check-in`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        checkins: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
        groupBookingCount: data.pages[0].data.group_booking_count,
        privateBookingCount: data.pages[0].data.private_booking_count,
      };
    },
  });
};

export default useInfinityScheduleManagementCheckin;

export type ScheduleManagementCheckinParams = Props & {
  paginate_type: string;
};

export type ScheduleManagementCheckin = ScheduleManagementBookings;

export type ScheduleManagementCheckinResponse = {
  data: ScheduleManagementCheckin[];
  /** 그룹수업 예약 인원 */
  group_booking_count: number;
  /** 프라이빗 수업 예약 인원 */
  private_booking_count: number;
};

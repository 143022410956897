import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import usePatchStaffNoticeUpdateIsChecked from 'hooks/service/mutations/usePatchStaffNoticeUpdateIsChecked';
import usePopup from 'hooks/usePopup';
import { MORE_BUTTON_LIMIT, PUSH_ICONS } from 'pages/Notification/constants';
import { PushItemType } from 'pages/Notification/types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorType } from 'types/colorTypes';

import MoreButton from '../MoreButton';
import NotificationDate from '../NotificationDate';
import PushListItemDetail from './PushListItemDetail';
import { determinePushIcon } from './utils';

type Props = {
  pushItem: PushItemType;
};

const PushListItem = ({ pushItem }: Props) => {
  const iconProps = PUSH_ICONS[determinePushIcon(pushItem.msg_type)];
  const isUnread = pushItem.is_checked === 0;
  const { mutate: readNotificationMutate } = usePatchStaffNoticeUpdateIsChecked(pushItem.id, 'push');

  const { setPopup } = usePopup();
  const navigate = useNavigate();

  const readNotification = () => {
    if (isUnread) {
      readNotificationMutate();
    }

    // 문의, 수다메이트일 때만 상세로 이동
    if (pushItem.msg_type.includes('.qna.')) {
      navigate(`/more-details/qna/${pushItem.ref.id}`);
      return;
    }
    if (pushItem.msg_type.includes('.lounge.')) {
      // TODO 글이 아니라 댓글로 이동해야 함 (라운지 상세페이지 구현 후 수정 필요)
      navigate(`/lounge/detail/${pushItem.ref.id}`);
      return;
    }
    setPopup(<PushListItemDetail pushItem={pushItem} />);
  };

  const [isLongMessage, setIsLongMessage] = useState(false);
  const messageRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (messageRef.current) {
      const limitHeight = MORE_BUTTON_LIMIT.line * MORE_BUTTON_LIMIT.lineHeight;
      setIsLongMessage(messageRef.current.scrollHeight > limitHeight);
    }
  }, []);

  return (
    <PushContainer isUnread={isUnread} onClick={readNotification}>
      <IconWrapper color={iconProps.color}>
        <Icon name={iconProps.name} color={iconProps.color === 'white' ? 'primary' : 'white'} />
      </IconWrapper>
      <PushDetails>
        <Typography size={15} weight={600}>
          {pushItem.label}
        </Typography>
        <Typography size={15} textColor="gray2" className="message" ref={messageRef}>
          {pushItem.message}
        </Typography>
        <Row>
          <NotificationDate dateTime={pushItem.created_at} />
          {isLongMessage ? <MoreButton targetRef={messageRef} lineLimit={MORE_BUTTON_LIMIT.line} /> : null}
        </Row>
      </PushDetails>
    </PushContainer>
  );
};

export default PushListItem;

const PushContainer = styled.div<{ isUnread: boolean }>`
  width: 100%;
  ${theme.flex('row', '', '', 14)}
  text-align: left;
  padding: 20px 20px 0 20px;
  ${({ isUnread }) => isUnread && `background-color: rgba(108, 133, 251, 0.08);`}
`;

// border-bottom: solid 1px rgba(46, 46, 46, 0.06);
const PushDetails = styled.div`
  width: 100%;
  .message {
    margin: 4px 0 8px;
  }
  :after {
    content: '';
    display: block;
    width: calc(100% + 50px);
    margin-left: -50px;
    margin-top: 20px;

    height: 1px;
    background-color: rgba(46, 46, 46, 0.06);
  }
`;

const IconWrapper = styled.div<{ color: ColorType }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ color }) => theme.color[color]};
  border: ${({ color }) => (color === 'white' ? `1px solid ${theme.color.gray5}` : 'none')};
  ${theme.flex()}
  flex-shrink: 0;
  margin-top: 4px;
`;

const Row = styled.div`
  ${theme.flex('row', 'center', 'space-between', 8)}
`;

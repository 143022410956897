import { ControllerRenderProps, FieldValues, useController, UseControllerProps } from 'react-hook-form';

import OutlinedTextField, { OutlinedOptions } from './components/OutlinedTextField';
import { ITextFieldOptions } from './types';

type OutlinedPropTypes = ITextFieldOptions & OutlinedOptions;

export type OutlinedTextFieldType<T extends FieldValues> = Omit<Exclude<OutlinedPropTypes, ControllerRenderProps<T>>, 'id'> & {
  controllerProps: UseControllerProps<T>;
};

const OutlinedTextFieldController = <T extends FieldValues>({ controllerProps, ...inputProps }: OutlinedTextFieldType<T>) => {
  const { field, fieldState } = useController(controllerProps);
  return (
    <OutlinedTextField
      id={field.name}
      errorMessage={fieldState.error?.message}
      {...field}
      {...inputProps}
      onChange={e => {
        field.onChange(e);
        inputProps.onChange?.(e);
      }}
    />
  );
};

export default OutlinedTextFieldController;

import Icon from 'components/Icon';
import { useFormContext } from 'react-hook-form';

import { TICKET_PAYMENT_FORM_TEXT } from '../constants';
import { TicketPaymentFormType } from '../types';
import PaymentTextField from './PaymentTextField';
import { PaymentRow } from './StyledComponents';

/** 적립할 포인트 입력 필드 */
const PointAccumulation = () => {
  const { control, setValue } = useFormContext<TicketPaymentFormType>();

  return (
    <PaymentRow>
      <PaymentTextField
        control={control}
        setValue={setValue}
        keyValue={'payment.reward_point'}
        suffix="P"
        left={{
          prefix: <Icon name="point" color="gray2" />,
          text: TICKET_PAYMENT_FORM_TEXT.point.accumulationLabel,
        }}
      />
    </PaymentRow>
  );
};

export default PointAccumulation;

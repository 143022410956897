import styled from '@emotion/styled';
import { ReactNode, useEffect, useRef, useState } from 'react';

type Props<T extends { id: number }> = {
  padding: number;
  itemHeight: number;
  itemGap?: number;
  containerHeight: number;
  className?: string;
  items: T[];
  renderItem: (item: T) => ReactNode;
};

// item 높이가 일정할 때 보여지는 스크롤 위치의 아이템만 렌더링
const VirtualScroll = <T extends { id: number }>({
  itemHeight,
  itemGap = 0,
  padding,
  containerHeight,
  className,
  items,
  renderItem,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  // 보여지는 아이템의 시작 인덱스
  const [startIndex, setStartIndex] = useState(0);

  const listBuffer = 2; // 자연스러운 스크롤을 위한 buffer 설정 (위 아래로 n개씩 더 랜더링)
  const itemSize = itemHeight + itemGap;
  const renderingRange = Math.ceil(containerHeight / itemSize) + listBuffer * 2;
  const paddedIndex = Math.max(0, startIndex - listBuffer); // 위 n개 버퍼 넣을 때 음수값 방지
  const slicedItems = items.slice(paddedIndex, startIndex + renderingRange);

  useEffect(() => {
    if (!ref.current) return;
    const container = ref.current;
    const onScroll = () => {
      const scrollTop = Math.max(container.scrollTop - padding);
      const newStartIndex = Math.floor(scrollTop / itemSize);
      setStartIndex(newStartIndex);
    };
    container.addEventListener('scroll', onScroll);
    return () => container.removeEventListener('scroll', onScroll);
  }, [itemSize, padding]);

  return (
    <Container className={`virtual-list ${className}`} ref={ref} containerHeight={containerHeight} padding={padding}>
      {/* 랜더링되는 요소와 상관없이 전체 길이 유지용 */}
      <Items height={items.length * itemSize} padding={padding}>
        {/* 스크롤에 따라 랜더링되지 않는 부분 여백 */}
        <PaddingTop height={Math.max(0, paddedIndex * itemSize)} />
        {slicedItems.map(item => (
          <StyledItem key={item.id} itemGap={itemGap}>
            {renderItem(item)}
          </StyledItem>
        ))}
      </Items>
    </Container>
  );
};

export default VirtualScroll;

const Container = styled.div<{
  containerHeight: number;
  padding?: number;
}>`
  ${({ containerHeight }) => `height: ${containerHeight}px`};
  ${({ padding }) => (padding ? `padding: ${padding}px` : '')};
  overflow-y: auto;
`;

const Items = styled.div<{ height: number; padding: number }>`
  ${({ height, padding }) => `height: ${height + padding}px`};
  overflow: hidden;
`;

const PaddingTop = styled.div<{ height: number }>`
  width: 100%;
  ${({ height }) => `height: ${height}px`};
`;

const StyledItem = styled.div<{ itemGap?: number }>`
  ${({ itemGap }) => `margin-bottom: ${itemGap || 0}px`};
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarViewTypeAtom } from 'recoil/schedule';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

type Props = {
  title: string;
  isPrivate: boolean;
  cardColor: string;
  isAllDay?: boolean;
  sameTimeEvents: number;
};

const EventEtcScheduleCard = ({ ...props }: Props) => {
  const { title, isPrivate, cardColor, isAllDay, sameTimeEvents } = props;

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);

  const isDayView = useMemo(() => calendarViewType === 'day', [calendarViewType]);
  const isWeekView = useMemo(() => calendarViewType === 'week', [calendarViewType]);

  const currentContrastColor = getContrastTextColor(cardColor) as Color;

  return (
    <Container cardColor={cardColor} isWeekView={isWeekView} sameTimeEvents={sameTimeEvents} isAllDay={!!isAllDay}>
      <div className="title-wrapper">
        {isPrivate && <Icon name="lockBold" size={16} fillColor={theme.color[currentContrastColor]} />}
        <Typography
          className="title"
          size={isDayView ? 14 : 12}
          weight={600}
          textColor={currentContrastColor}
          ellipsis={isDayView ? 1 : 0}>
          {title}
        </Typography>
      </div>
    </Container>
  );
};

export default EventEtcScheduleCard;

const Container = styled.div<{ cardColor: string; isWeekView: boolean; sameTimeEvents: number; isAllDay: boolean }>(
  ({ cardColor, isAllDay }) => css`
    height: 100%;
    padding: 5px 8px;
    border-radius: 4px;
    background-color: ${cardColor};
    overflow: hidden;

    .title-wrapper {
      ${theme.flex('row', isAllDay ? 'center' : 'flex-start', '', 4)};
    }
  `,

  /** 주간(week) 기타 일정 카드 */
  ({ isWeekView, sameTimeEvents, isAllDay }) =>
    isWeekView &&
    css`
      margin-right: 3px !important;
      padding: ${isAllDay ? '2px' : sameTimeEvents === 2 ? '4px 5px' : '4px 2px'} !important;
      border-radius: 2px;

      .title-wrapper {
        gap: 0;

        > .title {
          width: max-content;
        }
      }
    `,
);

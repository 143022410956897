import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

import { FilterResetDialogProps } from './types';

const FilterResetDialog = ({ onClose, onReset }: FilterResetDialogProps) => {
  return (
    <Dialog onClose={onClose} negativeAction={{ text: '취소' }} positiveAction={{ text: '초기화', onClick: onReset }}>
      <DialogDescription>모든 필터가 초기화됩니다.</DialogDescription>
    </Dialog>
  );
};

export default FilterResetDialog;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

/** 수강권 정지 이력 */
const useInfinityHolding = (userTicketId: number) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<HoldingResponse, HoldingParams>({
    url: `/api/holding`,
    params: {
      user_ticket_id: userTicketId,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', 'holding', userTicketId],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityHolding;

export type HoldingParams = {
  user_ticket_id: number;
};

export type HoldingResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  start_on: string;
  end_on: string;
  auto_calculation: number;
};

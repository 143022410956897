import { MessageMobileResponse } from 'hooks/service/queries/useInfinityMessageMobile';
import MessageListItem from 'pages/MoreDetails/Message/components/MessageListItem';

type Props = {
  message: MessageMobileResponse;
};

const MobileMessageListItem = ({ message }: Props) => {
  return (
    <MessageListItem
      to={`mobile/${message.id}`}
      send_on={message.send_on}
      send_person={message.send_person}
      message_type={message.message_type}
      all_count={message.all_count}
      title={message.title}
      status={message.status}
    />
  );
};

export default MobileMessageListItem;

import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext, useWatch } from 'react-hook-form';

/** 이후 모든 수정 시 종료일을 과거로 선택할 때 노출 */
const ChangePastDialog = () => {
  const { control, setValue, getValues } = useFormContext<BookingCommonFormType>();
  const tempEndDate = useWatch({ control, name: 'tempEndDate' });
  const isSelectedPastDate = useWatch({ control, name: 'isSelectedPastDate' });

  const close = (shouldDirty?: boolean) => {
    setValue('isSelectedPastDate', false, { shouldDirty });
  };

  const clickCancel = () => {
    setValue('endDate', tempEndDate || getValues('endDate'));
    close();
  };

  const clickContinue = () => {
    close(true);
  };

  return (
    <>
      {isSelectedPastDate && (
        <Dialog
          onClose={clickCancel}
          positiveAction={{
            text: '계속',
            onClick: clickContinue,
          }}
          negativeAction={{
            text: '취소',
            onClick: clickCancel,
            style: 'outlined',
          }}>
          <DialogDescription>
            종료일 변경 시, <br />
            종료일 이후의 모든 수업은 삭제되며 <br />
            회원의 예약도 취소됩니다. <br />
            계속 하시겠습니까?
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default ChangePastDialog;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';
import SelectedTargetItem from './SelectedTargetItem';
import VirtualScroll from './VirtualScroll';

const SelectedTargetList = () => {
  const { control } = useFormContext<MobileFormType>();
  const target = useWatch({ control, name: 'target' });
  const targets = useWatch({ control, name: target === 'member' ? 'users' : 'counseling_logs' });

  return (
    <SelectedTargetsWrapper>
      {!targets.length && (
        <Center>
          <Typography textColor="gray2" opacity={0.8} className="no-target">
            {MOBILE_FORM_TEXT.noTarget}
          </Typography>
        </Center>
      )}
      <VirtualScroll
        items={targets}
        renderItem={target => <SelectedTargetItem target={target} />}
        itemHeight={24}
        itemGap={14}
        containerHeight={180}
        padding={16}
      />
    </SelectedTargetsWrapper>
  );
};

export default SelectedTargetList;

const SelectedTargetsWrapper = styled.div`
  height: 180px;
  border-radius: 10px;
  box-shadow: 0 0 0 1px ${theme.color.gray5}; // border로 하면 virtual-list랑 사이즈가 달라짐
  .virtual-list {
    padding: 16px 16px 0;
  }
`;

const Center = styled.div`
  ${theme.flex('column', 'center', 'center')}
  height: 100%;
`;

import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';

import { BOOKING_STATUS } from '../../constants';
import { BookingStatusType } from '../../types';

type Props = {
  booking: LectureBookingListResponse;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
};

const formatChangeStatusList = ({ booking, canCancelBooking, canChangeBooking }: Props) => {
  if (!canCancelBooking && !canChangeBooking) return [];

  let array: Array<BookingStatusType> = [];
  switch (booking.status) {
    case 'booked':
      array = filteredArray(['cancel']);
      break;
    case 'cancel':
      array = filteredArray(['absence', 'noshow']);
      break;
    case 'booking_confirmed':
      array = filteredArray(['cancel', 'absence', 'noshow', 'attendance']);
      break;
    case 'absence':
      array = filteredArray(['cancel', 'noshow', 'attendance']);
      break;
    case 'noshow':
      array = filteredArray(['cancel', 'absence', 'attendance']);
      break;
    case 'attendance':
      array = filteredArray(['cancel', 'absence', 'noshow']);
      break;
    case 'booking_waiting':
      array = filteredArray(['booked', 'waiting_cancel']);
  }

  if (canCancelBooking && !canChangeBooking) {
    return array.filter(item => item.value === 'cancel');
  } else {
    return array;
  }
};

const filteredArray = (includedArray: string[]) => {
  return BOOKING_STATUS.filter(item => includedArray.includes(item.value));
};

export default formatChangeStatusList;

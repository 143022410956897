import { get } from 'lodash';
import { Fragment } from 'react/jsx-runtime';
import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsProps } from 'sharedComponents/Filters/types';

import MobilePeriodFilter from './MobilePeriodFilter';

const MobileDrawerFilterOptions = ({ filters, filterChanged, currentFilterTab, changeOption }: FilterOptionsProps) => {
  const currentFilter = filters.find(filter => filter.key === currentFilterTab?.value);

  if (!currentFilterTab || !currentFilter) return null;
  return (
    <>
      {currentFilter.key === 'period' && (
        <MobilePeriodFilter currentFilter={currentFilter} selectedOption={get(filterChanged, 'period')} onClick={changeOption} />
      )}
      {currentFilter.type === 'button' && (
        <ButtonFilter
          currentFilter={currentFilter}
          selectedOption={get(filterChanged, currentFilter.key)}
          onClick={changeOption}
        />
      )}
    </>
  );
};

export default MobileDrawerFilterOptions;

import ButtonFilter from 'sharedComponents/Filters/ButtonFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';

import { ReportSaleParamType } from '../../type';

type Props = {
  selectedOptionKey: 'paymentType';
  currentAllOptionCount?: number;
} & FilterProps;

const PaymentTypeButtonFilter = ({
  selectedOptionKey,
  currentAllOptionCount,
  currentFilter,
  selectedOption,
  onClick: changeOptions,
}: Props) => {
  const prevSelected = (selectedOption as ReportSaleParamType[typeof selectedOptionKey]) || [];

  const clickFilterOption = (option: FilterOptionsType, key: string) => {
    const selectedValue = option.value as string;

    const currentValues = prevSelected.slice();
    currentValues[0] = selectedValue;

    if (!option.value) {
      changeOptions({ value: undefined, label: '전체' }, selectedOptionKey);
    } else {
      changeOptions({ value: currentValues, label: '' }, selectedOptionKey);
    }
  };

  return <ButtonFilter currentFilter={currentFilter} selectedOption={selectedOption} onClick={clickFilterOption} />;
};

export default PaymentTypeButtonFilter;

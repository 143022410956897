import styled from '@emotion/styled';
import { theme } from 'assets/styles';

import ButtonFilterBase from './ButtonFilterBase';
import FilterTitle from './FilterTitle';
import { FilterWrap } from './StyledComponents';
import { FilterProps } from './types';

const ButtonFilter = ({ currentFilter, selectedOption, onClick }: FilterProps) => {
  return (
    <Container className="button-filter">
      <FilterTitle title={currentFilter.title} />
      <ButtonFilterBase currentFilter={currentFilter} selectedOption={selectedOption} onClick={onClick} />
    </Container>
  );
};

export default ButtonFilter;

const Container = styled(FilterWrap)`
  .filter-option-button-wrapper {
    flex-wrap: wrap;
    margin-top: 12px;
    background-color: ${theme.color.white};
  }

  .button-group {
    gap: 8px 6px;
  }
`;

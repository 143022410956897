import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

import { MenuType } from '../types';
import AccessControl from './AccessControl';

type Props = {
  menus: MenuType[];
  renderMenu: (menu: MenuType) => ReactNode;
  renderDisabledMenu?: (menu: MenuType) => ReactNode;
};

const Navigation = ({ menus, renderMenu, renderDisabledMenu }: Props) => {
  const studioId = useRecoilValue(studioIdAtom);
  const staffId = useRecoilValue(staffIdAtom);

  return menus.map(menu => {
    const renderDisabled = renderDisabledMenu ? renderDisabledMenu(menu) : null;
    // 시설이 없거나 선택되어 있지 않으면 비활성화
    if (!studioId || !staffId) return renderDisabled;

    return (
      <AccessControl
        key={menu.name}
        permissions={menu.permissions}
        policies={menu.policies}
        renderEnabled={renderMenu(menu)}
        renderDisabled={renderDisabled}
      />
    );
  });
};

export default Navigation;

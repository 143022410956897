import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetPointDetail = (memberId: number) => {
  const { queryFn } = useQueryFnWithCommonParams<MemberPointDetailResponse, MemberPointDetailParams>({
    url: `/api/point/detail`,
    params: { member_id: memberId },
  });

  return useSuspenseQuery({
    queryKey: ['member', 'point', 'detail', memberId],
    queryFn,
    select: data => data.data,
  });
};

export default useGetPointDetail;

export type MemberPointDetailParams = {
  member_id: number;
};

export type MemberPointDetailResponse = {
  current_point: number;
  reward_point: number;
  paid_point: number;
};

import { allMemberSelectedFiltersAtom, assignedMemberSelectedFiltersAtom, counselSelectedFiltersAtom } from 'recoil/Member';
import { messageTargetCounselSelectedFilter, messageTargetMemberSelectedFilter } from 'recoil/message';
import {
  bookingParamsAtom,
  checkinParamsAtom,
  currentPermissionMoreDetailScheduleAtom,
  deletedLectureParamsAtom,
  isEveryPermissionMoreDetailScheduleAtom,
} from 'recoil/moreDetail';
import {
  reportLectureParamAtom,
  reportLecturePeriodAtom,
  reportPointParamAtom,
  reportPointPeriodAtom,
  reportSalePeriodAtom,
  reportUnpaidParamAtom,
} from 'recoil/report/atom';
import { searchKeywordsAtom } from 'recoil/searchKeywords';
import { staffListPageAtom } from 'recoil/staff';

// 시설 변경, 로그아웃 시 초기화 할 recoil 상태
export const resetRecoilState = [
  searchKeywordsAtom,
  staffListPageAtom,
  allMemberSelectedFiltersAtom,
  assignedMemberSelectedFiltersAtom,
  counselSelectedFiltersAtom,
  messageTargetMemberSelectedFilter,
  messageTargetCounselSelectedFilter,
  reportSalePeriodAtom,
  reportLecturePeriodAtom,
  reportPointPeriodAtom,
  reportLectureParamAtom,
  reportUnpaidParamAtom,
  reportPointParamAtom,
  bookingParamsAtom,
  checkinParamsAtom,
  deletedLectureParamsAtom,
  currentPermissionMoreDetailScheduleAtom,
  isEveryPermissionMoreDetailScheduleAtom,
];

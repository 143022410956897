import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import Button from 'components/Button';
import { ComponentProps } from 'react';
import FooterButton, { FooterButtonWrapper } from 'sharedComponents/FooterButton';

import TotalAmount from './TotalAmount';

type Props = ComponentProps<typeof FooterButton> & ComponentProps<typeof TotalAmount>;

const PaymentSubmitButton = ({ children, text, isRefund, ...props }: Props) => {
  const isMutating = useIsMutating() > 0;

  return (
    <Container>
      <TotalAmount text={text} isRefund={isRefund} />
      <Button
        type="submit"
        fullWidth
        color="point"
        size="large56"
        variant="contained"
        disabled={isMutating || props.disabled}
        {...props}>
        {children}
      </Button>
    </Container>
  );
};

export default PaymentSubmitButton;

const Container = styled(FooterButtonWrapper)`
  padding: 15px 20px 10px;
  box-shadow: 0px -2px 3px 0px rgba(145, 145, 145, 0.12);
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { reportPointParamAtom } from 'recoil/report/atom';
import Filter from 'sharedComponents/Filters';

import { DEFAULT_POINT_FILTER_TABS, DEFAULT_REPORT_POINT_FILTER_OPTIONS, REPORT_POINT_SORTING_FILTERS } from '../../constants';
import Sorting from '../Sorting';
import ReportPointDrawerFilterOptions from './ReportPointDrawerFilterOptions';
import ReportPointWholeFilterOptions from './ReportPointWholeFilterOptions';

type Props = {
  total: number;
};

const ReportPointFilterOptions = ({ total }: Props) => {
  return (
    <>
      <FilterWrapper>
        <Typography size={17} weight={700} textColor="gray1">
          포인트 상세내역
        </Typography>
        <Filter
          filterAtom={reportPointParamAtom}
          filters={DEFAULT_REPORT_POINT_FILTER_OPTIONS}
          defaultTabLabels={DEFAULT_POINT_FILTER_TABS}
          renderDrawerOptions={ReportPointDrawerFilterOptions}
          renderWholeFilterPopupOptions={ReportPointWholeFilterOptions}
        />
      </FilterWrapper>
      <Divider thin thinColor="gray6" />
      <Sorting filterAtom={reportPointParamAtom} isShowTotal={true} total={total} sortOptions={REPORT_POINT_SORTING_FILTERS} />
    </>
  );
};

export default ReportPointFilterOptions;

const FilterWrapper = styled.div`
  background: ${theme.color.white};

  > .typography {
    padding: 32px 20px 0px 20px;
  }

  .filter-list {
    margin: 0;
    padding-top: 16px;
    padding-bottom: 12px;
  }
`;

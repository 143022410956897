export const COMPLETE_POPUP_CONTENTS = {
  join: {
    title: '회원가입 완료',
    description: '스튜디오메이트를 이용해 주셔서 감사합니다.\n서비스는 로그인 후 이용할 수 있습니다.',
    hasCloseButton: true,
    bottomButtonText: '로그인 하러 가기',
  },
  findPassword: {
    title: '비밀번호 변경 완료',
    description: '비밀번호 변경이 완료되었습니다.\n변경된 비밀번호로 로그인해 주세요.',
    hasCloseButton: true,
    bottomButtonText: '로그인 하러 가기',
  },
  withdraw: {
    title: '계정 탈퇴 완료',
    description: '지금까지 스튜디오메이트를\n이용해 주셔서 감사합니다.',
    hasCloseButton: false,
    bottomButtonText: '닫기',
  },
  createStaff: {
    title: '등록 완료',
    description: '강사 등록이 완료되었습니다',
    hasCloseButton: false,
    bottomButtonText: '확인',
  },
};

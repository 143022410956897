import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { Z_INDEX } from 'constants/zIndex';

import Drawer, { DrawerProps } from '..';

type Props = DrawerProps &
  Record<'leftButton' | 'rightButton', Omit<ButtonProps, 'children'> & { text: string }> & {
    /**
     * DSG 버튼 2개 케이스의 type과 동일
     * 1: 1:1 비율 버튼 (왼쪽버튼 gray)
     * 2: 왼쪽 버튼 115px 고정 (왼쪽버튼 variant="contained" 형태)
     * 3: 2번과 동일 (왼쪽버튼 variant="outlined" 형태)
     */
    type?: 1 | 2 | 3;
  };

const DoubleButtonDrawer = ({
  children,
  type = 1,
  leftButton: { text: leftButtonText, ...leftButton },
  rightButton: { text: rightButtonText, ...rightButton },
  ...props
}: Props) => {
  const isTopDrawer = props.direction === 'top';
  return (
    <DrawerContainer {...props}>
      {children}
      <ButtonWrapper isTopDrawer={isTopDrawer}>
        <ButtonGroup leftButtonWidth={type !== 1 ? 115 : undefined}>
          <Button
            fullWidth={type === 1}
            size={isTopDrawer ? 'medium48' : 'large56'}
            variant={type === 3 ? 'outlined' : 'contained'}
            color={type === 3 ? undefined : 'gray'}
            {...leftButton}>
            {leftButtonText}
          </Button>
          <Button fullWidth size={isTopDrawer ? 'medium48' : 'large56'} variant="contained" color="point" {...rightButton}>
            {rightButtonText}
          </Button>
        </ButtonGroup>
      </ButtonWrapper>
    </DrawerContainer>
  );
};

export default DoubleButtonDrawer;

const DrawerContainer = styled(Drawer)`
  padding-bottom: calc(32px + 76px); // 버튼 있을 때의 bottom + 하단 버튼 영역 높이
`;

const ButtonWrapper = styled.section<{ isTopDrawer: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: ${({ isTopDrawer }) => (isTopDrawer ? '14px' : '10px')} 20px;
  width: 100%;
  background-color: ${({ isTopDrawer }) => theme.color[isTopDrawer ? 'gray7' : 'white']};
  z-index: ${Z_INDEX.footerButton};
`;

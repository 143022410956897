import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Typography from 'components/Typography';
import React, { useMemo } from 'react';

import Message from './Message';

export type TitleType = string | string[] | React.ReactNode;

export type Props = {
  /**
   * [타이틀 문구]
   * 기본 문자열 사용, 줄바꿈은 배열 타입 인덱스로 구분,
   * ReactNode 타입 디테일한 커스텀용
   */
  title: TitleType;
  /**
   * 타이틀 위 아래 노출하는 서브 문구
   * 기본 문자열 사용, 줄바꿈은 배열 타입 인덱스로 구분,
   * bottomMessage는 위아래 전부 텍스트가 있는 케이스에 사용하는 커스텀 용도
   */
  message?: { position: string; text: Extract<TitleType, string | string[]>; bottomMessage?: string };
  /**
   * 타이틀 문구 margin-bottom 값
   */
  bottom?: number;
};

const SubTitle = ({ title, message, bottom }: Props) => {
  const currentTitle = useMemo(() => {
    if (Array.isArray(title)) {
      return (
        <Typography size={19} weight={700}>
          {title.map((item, idx) => (
            <React.Fragment key={idx}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      );
    }
    if (typeof title === 'object') return title;

    return (
      <Typography size={19} weight={700}>
        {title}
      </Typography>
    );
  }, [title]);

  const bottomMessage = useMemo(() => {
    if (message?.position === 'bottom') return message?.text;
    return message?.bottomMessage;
  }, [message]);

  return (
    <Container className="sub-title" bottom={bottom} messagePosition={message?.position}>
      {message?.position === 'top' && <Message position="top" message={message?.text} />}
      <section>{currentTitle}</section>
      {(message?.position === 'bottom' || !!message?.bottomMessage) && (
        <Message className="bottom-message" position="bottom" message={bottomMessage} />
      )}
    </Container>
  );
};

export default SubTitle;

const Container = styled.div<Pick<Props, 'bottom'> & { messagePosition?: string }>(
  ({ bottom }) =>
    bottom &&
    css`
      margin-bottom: ${bottom}px;

      .bottom-message {
        margin-top: 4px;
      }
    `,

  ({ messagePosition }) => {
    if (!messagePosition) return;
    return css`
      section {
        ${messagePosition === 'top' ? 'margin-top: 8px' : 'margin-bottom: 4px'}
      }
    `;
  },
);

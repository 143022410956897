import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { accessTokenAtom } from 'recoil/common';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetAuthMeInstanceTokenCheck = () => {
  const accessToken = useRecoilValue(accessTokenAtom);

  const { queryFn } = useQueryFnWithCommonParams<AuthMeInstanceTokenRegisterResponse>({
    url: '/api/auth/me/instance-token-check',
  });

  return useQuery({
    queryKey: ['auth/me/instance-token-check', accessToken],
    queryFn,
    select: data => data.data,
    enabled: !!accessToken,
  });
};

export default useGetAuthMeInstanceTokenCheck;

type AuthMeInstanceTokenRegisterResponse = {
  validate: boolean;
};

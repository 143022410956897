import dayjs from 'dayjs';
import { FilterType } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

import { MobileSelectedFilterType } from '../../Mobile/List/type';

export const APP_PUSH_LIST_TEXT = {
  empty: '앱 푸시 내역이 없습니다.',
  noPermission: '앱 푸시 메시지 조회 권한이 없습니다.',
};

export const APP_PUSH_FILTER_TAB_LABELS = {
  period: '이번달',
  push_status: '메시지 상태 전체',
};

export const APP_PUSH_DEFAULT_PERIOD = {
  start: filters.dateDash(dayjs().startOf('month')),
  end: filters.dateDash(dayjs().endOf('month')),
};

export const APP_PUSH_FILTERS: FilterType[] = [
  {
    key: 'period',
    type: 'period',
    title: '기간선택',
    options: [
      { label: '오늘', value: 'day' },
      { label: '이번주', value: 'isoWeek' },
      { label: '이번달', value: 'month' }, // 기본값 이번달
      { label: '직접선택', value: 'custom' },
    ],
    tabConverter: value => {
      const periodValue = value as MobileSelectedFilterType['period'];
      if (!periodValue || periodValue.type === 'month') return '이번달';
      if (periodValue.type === 'day') return '오늘';
      if (periodValue.type === 'isoWeek') return '이번주';
      return filters.period(periodValue.start, periodValue.end);
    },
  },
  {
    key: 'push_status',
    title: '메시지 상태',
    type: 'button',
    options: [
      { label: '전체', value: undefined },
      { label: '예약', value: 'scheduled' },
      { label: '발송', value: 'sended' },
    ],
  },
];

// mobile status와 통일시키기 위한 adapter
export const MESSAGE_PUSH_STATUS_ADAPTER = {
  scheduled: 'request',
  sended: 'sent',
  canceled: 'cancel',
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { Link } from 'react-router-dom';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';

import { QnaListType } from '../types';
import NewQnaMark from './NewQnaMark';

type Props = {
  qna: QnaListType;
  isNew: boolean;
};

const QnaListItem = ({ qna, isNew }: Props) => {
  return (
    <QnaLink to={`${qna.id}`}>
      <QnaSummary>
        <TitleWrapper>
          <Typography size={15} weight={600} span ellipsis={1}>
            {qna.title}
          </Typography>
          {isNew && <NewQnaMark />}
        </TitleWrapper>
        <ArrangeTypographyWrapper>
          <NameEllipsis size={13} weight={500} textColor="gray3" span>
            {qna.member.name}
          </NameEllipsis>
          <Typography size={13} textColor="gray3" span>
            {filters.dateTimeWithWeekday(qna.updated_at)}
          </Typography>
        </ArrangeTypographyWrapper>
      </QnaSummary>
      <Comments>
        <Icon name="chatBold" size={16} color="gray3" />
        <Typography span size={13} weight={600} textColor="gray3">
          {qna.comments_count}
        </Typography>
      </Comments>
    </QnaLink>
  );
};

export default QnaListItem;

const QnaLink = styled(Link)`
  display: block;
  padding: 16px 0;
  width: 100%;
  border-bottom: 1px solid ${theme.color.gray7};
`;

const QnaSummary = styled.div`
  margin-bottom: 20px;
  p {
    margin-bottom: 4px;
  }
`;

const Comments = styled.div`
  ${theme.flex('row', 'end', 'start', 3)}
`;

const TitleWrapper = styled.div`
  ${theme.flex('row', 'center', 'start', 4)}
  margin-bottom: 4px;
`;

const NameEllipsis = styled(Typography)`
  display: inline-block;
  max-width: 124px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import CheckBox from 'components/CheckBox';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BackgroundLectureType } from 'recoil/calendarSettings/types';

import { CALENDER_SETTING_MENUS } from '../../constants';
import { CalendarSettingsFormType } from '../../types';

const ClosedClassBackground = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { control, setValue } = useFormContext<CalendarSettingsFormType>();
  const currentClosedClassBackground = useWatch({ control, name: 'closedClassBackground' });

  const changeLectureSetting = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentType = e.target.value as BackgroundLectureType;
      const isAleadyType = currentClosedClassBackground.includes(currentType);

      const newValue = isAleadyType
        ? currentClosedClassBackground.filter(type => type !== currentType)
        : [...currentClosedClassBackground, currentType];
      setValue('closedClassBackground', newValue);
    },
    [currentClosedClassBackground, setValue],
  );

  return (
    <Container>
      <div className="tooltip-wrapper">
        <Typography textColor="gray2" size={15} weight={500}>
          마감수업 배경 설정
        </Typography>
        <Tooltip
          isOpen={isTooltipOpen}
          showCloseButton
          onClose={() => setIsTooltipOpen(false)}
          message={['최대수강인원이 다 찼거나 스태프가', '수업을 예약마감 한 경우 수업의', '배경색을 채우는 설정입니다.']}
          position="left">
          <IconButton onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
            <Icon name="information" size={16} fillColor={theme.color.gray3} />
          </IconButton>
        </Tooltip>
      </div>

      <div className="checkbox-wrapper">
        {CALENDER_SETTING_MENUS.closedClassBackground.map(({ id, label, value }) => (
          <CheckBox
            key={id}
            id={id}
            variant="box"
            gap={8}
            label={label}
            value={value}
            checked={currentClosedClassBackground.includes(value as BackgroundLectureType)}
            onChange={changeLectureSetting}
          />
        ))}
      </div>
    </Container>
  );
};

export default ClosedClassBackground;

const Container = styled.div`
  ${theme.flex('column', 'flex-start', 'center')};
  padding: 18px 16px;
  border-radius: 12px;
  background-color: ${theme.color.white};
  box-shadow: 1px 3px 10px 0px rgba(145, 145, 145, 0.12);

  .tooltip-wrapper {
    ${theme.flex('row', 'center', '', 2)}
  }

  .checkbox-wrapper {
    ${theme.flex('row', 'center', '', 16)}
    margin-top: 18px;

    .checkbox > .typography {
      color: ${theme.color.gray1};
    }
  }

  .tooltip-contents-wrapper {
    width: 230px !important;
    gap: 0 !important;
  }
`;

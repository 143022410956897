import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import useQueryString from 'hooks/useQueryString';
import { BOOKING_PRIVATE_FORM_TEXT } from 'pages/Booking/Private/constants';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import convertTargetsText from 'utils/convertTargetsText';

const SelectMembersField = () => {
  const { control, getValues } = useFormContext<BookingPrivateFormType>();
  const lectureSize = useWatch({ control, name: 'lectureSize' });

  const [isOpen, setIsOpen] = useState(false);
  const { getAllSearchParams, setSearchParams } = useQueryString();
  const label = convertTargetsText(
    getValues('selectedUserTickets').map(({ member }) => member),
    '수강회원',
  );

  const click = () => {
    if (!lectureSize) {
      return setIsOpen(true);
    }
    setSearchParams({
      ...getAllSearchParams(),
      type: 'selectMember',
    });
  };

  return (
    <>
      <FormFieldLinkButton iconName="privateClass" onClick={click} hasValue={!!getValues('selectedUserTickets').length}>
        {label}
      </FormFieldLinkButton>

      {isOpen && (
        <Dialog
          isError
          onClose={() => setIsOpen(false)}
          positiveAction={{
            text: BOOKING_PRIVATE_FORM_TEXT.selectMember.dialog.positiveText,
            onClick: () => setIsOpen(false),
          }}>
          <DialogDescription tag="pre">{BOOKING_PRIVATE_FORM_TEXT.selectMember.dialog.description}</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default SelectMembersField;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Tabs, { TabDataElementType } from 'components/Tabs';
import TabPanel from 'components/Tabs/TabPanel';
import useQueryString from 'hooks/useQueryString';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { useMemo } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import { DETAIL_INFO_TAB_DATA } from '../constants';
import { CurrentMemberIdProps } from '../types';
import Basic from './Basic';
import History from './History';
import Memo from './Memo';
import Payment from './Payment';
import Point from './Point';

const DetailInfo = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { setSearchParams, getSearchParams } = useQueryString();
  const tab = getSearchParams('tab');
  const currentTab = DETAIL_INFO_TAB_DATA.find(tabData => tabData.value === tab) || DETAIL_INFO_TAB_DATA[0];

  useScrollRestoration('#scrollableTarget', true);

  const TabContents = useMemo(() => {
    switch (currentTab.value) {
      case 'basic':
        return <Basic currentMemberId={currentMemberId} />;
      case 'history':
        return <History currentMemberId={currentMemberId} />;
      case 'point':
        return <Point currentMemberId={currentMemberId} />;
      case 'payment':
        return <Payment currentMemberId={currentMemberId} />;
      case 'memo':
        return (
          <ApiBoundary>
            <Memo currentMemberId={currentMemberId} />
          </ApiBoundary>
        );
    }
  }, [currentMemberId, currentTab.value]);

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value }, { replace: true });
  };

  return (
    <Container className="member-detail-info">
      <StickyWrapper>
        <Tabs fullWidth={false} data={DETAIL_INFO_TAB_DATA} value={currentTab.value} onChange={changeTab} />
      </StickyWrapper>

      <TabPanel>{TabContents}</TabPanel>
    </Container>
  );
};

export default DetailInfo;

const Container = styled.div`
  flex: 1;
  ${theme.flex('column', '', '')};

  .tab-panel {
    flex: 1;
    ${theme.flex('column', '', '')}
  }
`;

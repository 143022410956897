import styled from '@emotion/styled';
import LinkButton from 'components/Button/LinkButton';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';

import { StudiomateNoticeType } from '../types';

type Props = {
  notice: StudiomateNoticeType;
  isLastItem: boolean;
};

const Item = ({ notice, isLastItem }: Props) => {
  const navigate = useNavigate();

  return (
    <StyledLinkButton
      key={notice.id}
      showBorder={false}
      showArrow={false}
      onClick={() => navigate(`${notice.id}`)}
      isLastItem={isLastItem}>
      <Typography size={15} weight={600} ellipsis={1}>
        {notice.title}
      </Typography>
      <Typography size={13} textColor="gray3" ellipsis={1}>
        {filters.dateTimeWithWeekday(notice?.updated_at || notice?.created_at)}
      </Typography>
      <Divider thin />
    </StyledLinkButton>
  );
};

const ListItem = memo(Item);
export default ListItem;

const StyledLinkButton = styled(LinkButton)<{ isLastItem: boolean }>`
  padding: 18px 20px;
  position: relative;

  .typography:first-of-type {
    margin-bottom: 4px;
  }

  hr {
    ${({ isLastItem }) => isLastItem && 'display: none;'}
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
  }

  ${({ isLastItem }) => isLastItem && 'margin-bottom: 40px;'}
`;

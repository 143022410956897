import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStudio = () => {
  const hasToken = !!localStorage.getItem('access_token');

  const { queryFn } = useQueryFnWithCommonParams<StudioResponse, StudioParams>({
    url: '/api/studio',
    params: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: ['studio', { hasToken }],
    queryFn: () => (hasToken ? queryFn() : null),
    select: data => data?.data ?? { studios: [], accountName: '' },
  });
};

export default useGetStudio;

export type StudioResponse = { studios: Array<Studio>; accountName: string };

export type Studio = {
  id: number;
  name: string;
  subdomain: string;
  disable: boolean;
  expire_on: string;
  staff_id: number;
  staff: StaffType;
};

type StudioParams = {
  studio_id: undefined;
  staff_id: undefined;
};

type StaffType = {
  account_id: number | null;
  account_type: 'userAccount' | 'staffAccount' | null;
  created_at: string;
  deleted_at: null;
  id: number;
  mobile: string | null;
  name: string;
  registered_at: string;
  roles: RoleType[];
  studio_id: number;
  updated_at: string;
  user_grade_id: number | null;
  vaccination_yn: 'Y' | 'N';
};

type RoleType = {
  created_at: string;
  description: string | null;
  display_name: string;
  id: number;
  is_immutable: boolean;
  name: string;
  pivot: PivotType;
  studio_id: number;
  updated_at: string;
};

type PivotType = {
  studio_user_id: number;
  role_id: number;
};

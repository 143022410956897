import { ETC_SCHEDULE_COLOR, MATCH_REPRESENTATIVE_COLOR } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { DropdownType } from 'pages/Booking/types';
import { ETC_SCHEDULE_PERMISSION } from 'pages/EtcSchedule/constants';
import { StaffPermissionType } from 'pages/EtcSchedule/types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { errorBackStepAtom, staffIdAtom } from 'recoil/common';
import MainLayout from 'sharedComponents/MainLayout';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import ColoredDetailInfo from './ColoredDetailInfo';
import EtcDeleteListDrawer from './EtcDeleteListDrawer';
import EtcDetailDropDown from './EtcDetailDropDown';
import EtcUpdateListDrawer from './EtcUpdateLIstDrawer';

type Props = {
  staffType: StaffPermissionType;
  currentEtcScheduleId: number;
  data: EtcScheduleDetailResponse;
  canViewEtcSchedule: boolean;
  canCreateEtcSchedule: boolean;
  canUpdateEtcSchedule: boolean;
  canDeleteEtcSchedule: boolean;
};

const DetailMain = ({
  staffType,
  currentEtcScheduleId,
  data,
  canViewEtcSchedule,
  canCreateEtcSchedule,
  canUpdateEtcSchedule,
  canDeleteEtcSchedule,
}: Props) => {
  const bgColor = ETC_SCHEDULE_COLOR[data.color] || MATCH_REPRESENTATIVE_COLOR[data.color];
  const contrastColor = getContrastTextColor(bgColor) as Color;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isBulk = pathname.includes('bulk');

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<'update' | 'delete' | null>(null);
  const currentStaffId = useRecoilValue(staffIdAtom);
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);
  const { checkPermission } = useGetPermissionDoHavePermission();

  const goEtcUpdateForm = (mode: string) => {
    const id = isBulk ? data.etc_schedule_id : data.id;
    navigate(`/schedule/etc/form/${id}?mode=${mode}${isBulk ? '&isBulk=true' : ''}`);
  };

  const getPermissionIds = (action: Omit<DropdownType['value'], 'open' | 'close'>) => {
    const currentAction = (action === 'copy' ? 'create' : action) as 'create' | 'update' | 'delete';
    const permissions = [ETC_SCHEDULE_PERMISSION[staffType][currentAction]].flat();
    const resultPermissions = permissions.map(permission => {
      return permission.id;
    });
    return resultPermissions;
  };

  const onError = () => {
    const isIncludeMe = !!data.staffs.filter(staff => staff.id === currentStaffId).length;
    if (isIncludeMe) {
      setErrorBackStep({
        to: pathname,
        options: { replace: true },
      });
    } else {
      checkPermission(PERMISSION.etcSchedule.others.view.id, {
        onError: () => {
          setErrorBackStep({
            to: '/schedule',
            options: { replace: true },
          });
        },
      });
    }
  };

  const clickDropDownItem = (value: DropdownType['value']) => {
    switch (value) {
      case 'update':
        checkPermission(getPermissionIds(value), {
          onSuccess: () => {
            if (data.is_single_schedule) {
              goEtcUpdateForm('update-all');
            } else {
              setIsOpenDrawer('update');
            }
          },
          onError,
        });
        break;
      case 'copy':
        checkPermission(getPermissionIds(value), {
          onSuccess: () => navigate(`/schedule/etc/form/${currentEtcScheduleId}?mode=copy${isBulk ? '&isBulk=true' : ''}`),
          onError,
        });
        break;
      case 'delete':
        checkPermission(getPermissionIds(value), {
          onSuccess: () => setIsOpenDrawer('delete'),
          onError,
        });
        break;
    }
    setIsOpenDropDown(false);
  };

  const isShowHeaderMore = canCreateEtcSchedule || canUpdateEtcSchedule || canDeleteEtcSchedule;

  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => navigate(-1)}>
            <Icon name="headerClose" color={contrastColor} />
          </IconButton>
        ),
        rightButton: isShowHeaderMore ? (
          <EtcDetailDropDown
            contrastColor={contrastColor}
            isOpen={isOpenDropDown}
            onToggle={setIsOpenDropDown}
            onClick={clickDropDownItem}
            canCreateEtcSchedule={canCreateEtcSchedule}
            canUpdateEtcSchedule={canUpdateEtcSchedule}
            canDeleteEtcSchedule={canDeleteEtcSchedule}
          />
        ) : undefined,
        bgColor,
      }}>
      <ColoredDetailInfo
        currentEtcSchedule={data}
        isBulk={isBulk}
        bgColor={bgColor}
        contrastColor={contrastColor}
        canViewEtcSchedule={canViewEtcSchedule}
      />

      <EtcUpdateListDrawer
        isBulk={isBulk}
        isOpen={isOpenDrawer === 'update'}
        onClose={() => setIsOpenDrawer(null)}
        goEtcUpdateForm={goEtcUpdateForm}
      />

      <EtcDeleteListDrawer
        currentEtcSchedule={data}
        isBulk={isBulk}
        isOpen={isOpenDrawer === 'delete'}
        onClose={() => setIsOpenDrawer(null)}
      />
    </MainLayout>
  );
};

export default DetailMain;

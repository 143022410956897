import styled from '@emotion/styled';
import arrowImg from 'assets/images/tutorial3_arrow.svg';
import filterImg from 'assets/images/tutorial3_filter.svg';
import Typography from 'components/Typography';

const TutorialThird = () => {
  return (
    <Container>
      <ContentWrapper>
        <FilterWrapper>
          <img src={filterImg} alt="필터설명이미지" />
        </FilterWrapper>
        <ArrowTopRightWrapper>
          <img src={arrowImg} alt="화살표이미지" />
        </ArrowTopRightWrapper>
        <TextWrapper>
          <span className="highlight-color">필터를 설정</span>
          <Typography span textColor="white" size={19} weight={600}>
            하여
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            조건별 수업을
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            확인할 수 있습니다.
          </Typography>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default TutorialThird;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 6.5rem;
  right: 5rem;
`;

const FilterWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const ArrowTopRightWrapper = styled.div`
  position: absolute;
  top: 5rem;
  img {
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 20rem;
  position: absolute;
  top: 7rem;
  right: 30%;
  text-align: center;

  .highlight-color {
    font-size: 19px;
    font-weight: 600;
    color: #2cead7;
  }
`;

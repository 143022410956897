import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Divider from 'components/Divider';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import { BookingType } from 'pages/Booking/types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import TraineeCountInfo from './TraineeCountInfo';

type Props = {
  bookings: Array<LectureBookingListResponse>;
  bookingType: BookingType;
  currentLecture: LectureDetailResponse;
  canChangeBooking: boolean;
};

const TraineeCount = ({ bookings, bookingType, currentLecture, canChangeBooking }: Props) => {
  const { id, max_trainee, current_trainee_count, waiting_trainee_limit } = currentLecture;
  const currentWaitingTraineeCount = bookings.filter(({ status }) => status === 'booking_waiting').length;
  const canBookable = current_trainee_count < max_trainee;

  const showAddButton = useMemo(() => {
    if (!canChangeBooking) return false;
    if (bookingType === 'group') return true;
    if (canBookable) return true;
    return false;
  }, [bookingType, canBookable, canChangeBooking]);

  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <TraineeCountInfo
          showWaitingCount={bookingType === 'group'}
          currentBookingTraineeCount={current_trainee_count}
          currentWaitingTraineeCount={currentWaitingTraineeCount}
          maxTrainee={max_trainee}
          waitingTraineeLimit={waiting_trainee_limit}
        />
        {showAddButton && (
          <Button size="small" variant="contained" color="gray" widthSize={65} onClick={() => navigate(`/booking/member/${id}`)}>
            회원 변경
          </Button>
        )}
      </Wrapper>
      <Divider thin />
    </Container>
  );
};

export default TraineeCount;

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${theme.color.white};
`;

const Wrapper = styled.div`
  padding: 17px 20px;
  ${theme.flex('', 'center', '', 8)};
  min-height: 62px;

  button {
    margin-left: auto;
  }
`;

import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useInfinityBookingList from 'hooks/service/queries/useInfinityBookingList';
import useCheckPermissionBookingDetail from 'hooks/useCheckPermissionBookingDetail';
import { formatSelectedUserTicket } from 'pages/Booking/utils/bookings';
import { FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';

import BookingForm from './BookingForm';
import { BookingFormType } from './types';

type Props = {
  currentLectureId: number;
};

const Main = ({ currentLectureId }: Props) => {
  const { data: currentLecture } = useGetLectureDetail(currentLectureId);
  const { data: bookings } = useInfinityBookingList(currentLectureId);
  const bookingType = currentLecture.type === 'G' ? 'group' : 'private';
  const { canCancelBooking } = useCheckPermissionBookingDetail(currentLecture.staff.id, bookingType);
  const selectedUserTickets = formatSelectedUserTicket(bookings);

  const methods = useForm<BookingFormType>({
    defaultValues: {
      canCancelBooking,
      currentLecture,
      selectedUserTickets,
      currentBookingUserTickets: selectedUserTickets,
    },
  });

  return (
    <MainLayout header={{ title: '회원 변경' }}>
      <FormProvider {...methods}>
        <BookingForm bookingType={bookingType} />
      </FormProvider>
    </MainLayout>
  );
};

export default Main;

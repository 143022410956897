import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';
import { LecturePrivateDeleteBulkParams } from './useDeleteLecturePrivateBulk';

/** 그룹 수업 중 특정 날짜 이후 모든 수업 삭제 */
const useDeleteLectureGroupBulk = (lecture: LectureDetailResponse) => {
  const { id, course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LecturePrivateDeleteBulkParams>({
    method: `delete`,
    url: `/api/lecture/group/bulk/${course_id}`,
  });

  return useCustomMutation({
    mutationFn,
    mutationKey: ['lecture', 'delete', 'group', 'bulk', course_id],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', id],
      });
    },
  });
};

export default useDeleteLectureGroupBulk;

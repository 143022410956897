import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

import FakeNavigation from './FakeNavigation';

const MoreDetailSkeleton = () => {
  return (
    <>
      <StickyWrapper>
        <ProfileSkeleton>
          <Row>
            <Skeleton width={56} height={56} type="circle" />
            <Column>
              <Skeleton width={164} height={24} type="round" />
              <Skeleton width={120} height={18} type="round" />
            </Column>
            <Skeleton width={79} height={28} type="round" />
          </Row>
          <Skeleton height={48} type="round" />
        </ProfileSkeleton>
        <DividerWithMargin />
      </StickyWrapper>
      <FakeNavigation />
    </>
  );
};

export default MoreDetailSkeleton;

const ProfileSkeleton = styled.div`
  margin: 0 20px 20px;
`;

const Row = styled.div`
  ${theme.flex('row', 'center', 'start', 16)}
  [type="round"] {
    border-radius: 3px;
  }
  [type='circle'] {
    flex-shrink: 0;
  }
  margin-bottom: 24px;
`;

const Column = styled.div`
  ${theme.flex('column', '', '', 4)}
  flex-grow: 1;
`;

const DividerWithMargin = styled(Divider)`
  margin-bottom: 12px;
`;

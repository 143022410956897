import dayjs, { Dayjs } from 'dayjs';

/**
 * 날짜 차이 계산
 * @return {number}
 * */
const getDaysDiff = (to: string, from: Dayjs | string = dayjs(), type = 'day') => {
  from = dayjs(from).startOf('day');
  return dayjs(to)
    .startOf('day')
    .diff(from, type === 'day' ? 'days' : 'months');
};

export default getDaysDiff;

import { AxiosError } from 'axios';
import { BookingErrorDialogType, BookingFormType } from 'pages/Booking/BookingMember/types';
import { formatBookingParams, getErrorMembersName } from 'pages/Booking/utils/bookings';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import usePostBooking, { BookingError, BookingParams } from './service/mutations/usePostBooking';
import { BookingCountResponse } from './service/queries/useGetBookingCount';
import useToast from './useToast';

const useBooking = (methods: UseFormReturn<BookingFormType>) => {
  const { setValue, getValues } = methods;
  const [error, setError] = useState<BookingErrorDialogType>(null);
  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { mutate: bookingMutate } = usePostBooking();

  const refresh = useCallback(() => {
    navigate(0);
    setError(null);
  }, [navigate]);

  const bookingMember = useCallback(
    (params: BookingParams) => {
      setIsLoading(true);
      bookingMutate(params, {
        onSettled: () => setIsLoading(false),
        onSuccess: () => {
          navigate(-1);
          setToast({ type: 'success', message: '예약 인원이 변경되었습니다.' });
        },
        onError: (error: AxiosError<BookingError>) => {
          if (!error.response?.data) return;
          const errorData = error.response.data;
          const validationUserTicket = [10020, 10021, 10621];
          if (!!errorData.code && validationUserTicket.includes(errorData.code)) {
            setError({
              message: errorData?.message || '',
              isRefresh: true,
              onClick: refresh,
            });
            return false;
          }
          const memberNameError =
            errorData.booked ||
            errorData.cancelled ||
            errorData.waited ||
            errorData.waiting_cancelled ||
            errorData.not_changeable;
          if (memberNameError) {
            setError({
              message: memberNameError[0].message,
              bold: getErrorMembersName(memberNameError),
              isRefresh: true,
              onClick: refresh,
            });
            return false;
          }
          if (errorData.limit) {
            setBookingCountValidation(errorData.limit);
            return false;
          }
          if (errorData.overlap) {
            setError({
              message: '해당 시간대에 다른 예약이 있습니다.',
              onClick: () => setError(null),
            });
            const overlapIds = errorData.overlap.map(({ user_ticket_id }) => user_ticket_id);
            setValue('overlapTickets', overlapIds);
            return false;
          }
          if (errorData.code === 10006) {
            setError({
              message: '예약 가능한 인원이 초과되었습니다.',
              isRefresh: true,
              onClick: refresh,
            });
            return false;
          }
        },
      });
    },
    [bookingMutate, navigate, refresh, setToast, setValue],
  );

  const clickPassLimit = useCallback(() => {
    setBookingCountValidation(null);
    const params = {
      ...formatBookingParams(getValues()),
      is_pass_limit: true,
    };
    bookingMember(params);
  }, [bookingMember, getValues]);

  const closeBookingCountValidation = useCallback(() => {
    setBookingCountValidation(null);
  }, []);

  const closeError = useCallback(() => {
    setError(null);
  }, []);

  return {
    bookingMember,
    clickPassLimit,
    closeBookingCountValidation,
    closeError,
    bookingCountValidation,
    error,
    isPending: isLoading,
  };
};

export default useBooking;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Icon from 'components/Icon';
import { PropsWithChildren, useState } from 'react';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';
import TicketCard from 'sharedComponents/TicketCard';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';

import { UserTicketProps } from '../types';
import formatSmallTransferCard from './utils/formatSmallTransferCard';

type Props = PropsWithChildren<UserTicketProps> & {
  /** 양도 페이지에서 수강권 정보 노출이 다름 */
  isTransfer?: boolean;
};

const GrayFoldingCard = ({ children, userTicket, isTransfer = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const ticket = isTransfer ? formatSmallTransferCard(userTicket) : formatSmallUserTicketCard(userTicket);

  return (
    <FoldingCard className="gray-folding-card" bgColor="gray7" showBoxShadow={false}>
      <TicketCard ticket={ticket} />
      <HiddenInfoWrapper className="hidden-info-wrapper" isOpen={isOpen}>
        {children}
      </HiddenInfoWrapper>
      <ButtonGroup>
        <Button
          textColor="gray2"
          rightIcon={<Icon name={isOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} color="gray3" />}
          onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? '접기' : '자세히 보기'}
        </Button>
      </ButtonGroup>
    </FoldingCard>
  );
};

export default GrayFoldingCard;

const FoldingCard = styled(CommonStyleCard)`
  margin-top: 12px;
  padding: 0;

  .ticket-card {
    padding: 16px 16px 12px;
  }

  .button-group {
    border-top: 1px solid ${theme.color.gray5};

    .text-button {
      padding: 16px;
      margin: auto;
    }
  }
`;

const HiddenInfoWrapper = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  > div {
    padding: 12px 20px;
    border-top: 1px solid ${theme.color.gray5};
  }
`;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostMemberRewardPoint = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemberRewardPointParams>({
    method: `post`,
    url: `/api/point/reward`,
  });

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['member', 'point'],
      });
    },
  });
};

export default usePostMemberRewardPoint;

type MemberRewardPointParams = {
  member_id: number;
  point: number;
  updated_for?: string;
};

import usePatchStaffProfile from 'hooks/service/mutations/usePatchStaffProfile';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import { SubmitHandler, useFieldArray, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import StaffProfileForm from '../../components/StaffProfileForm';
import { RESPONSE_SUCCESS } from '../../constants';
import { ProfileUpdateFormType } from '../../types';

const StaffUpdateForm = () => {
  const navigate = useNavigate();
  const { setToast } = useToast();
  const targetStaffId = useParamsId();
  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isValid, isSubmitting, isDirty },
  } = useFormContext<ProfileUpdateFormType>();

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'careers',
  });

  const { mutate: patchStaffProfileMutate } = usePatchStaffProfile({ targetStaffId });

  const submit: SubmitHandler<ProfileUpdateFormType> = inputValues => {
    const { profile, careers } = inputValues;
    const submitCareers = careers.filter(({ career }) => !!career);

    const params = {
      profile: {
        self_introduction: dirtyFields.profile?.self_introduction ? profile.self_introduction : undefined,
      },
      careers: submitCareers,
    };

    patchStaffProfileMutate(params, {
      onSuccess: () => {
        setToast({ type: 'success', message: RESPONSE_SUCCESS.profile });
        navigate(-1);
      },
    });
  };

  return (
    <StaffProfileForm
      onSubmit={handleSubmit(submit)}
      careerFields={fields}
      prepend={prepend}
      remove={remove}
      footerButton={{ text: '수정 완료', disabled: !isValid || isSubmitting || !isDirty }}
    />
  );
};

export default StaffUpdateForm;

import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import usePopup from 'hooks/usePopup';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import { STUDIO_DELETE_TEXTS } from '../../pages/MoreDetails/Studio/constants';
import StudioList from './StudioList';

const StudioDeletePopup = () => {
  const { setPopup } = usePopup();
  return (
    <MainLayout
      header={{
        leftButton: (
          <IconButton onClick={() => setPopup(null)}>
            <Icon name="headerClose" size={24} />
          </IconButton>
        ),
      }}>
      <Title size={19} weight={700}>
        {STUDIO_DELETE_TEXTS.deletionTitle}
        <br />
        {STUDIO_DELETE_TEXTS.deletionTitle2}
      </Title>
      <ApiBoundary fallback={<p>loading</p>}>
        <StudioList type="delete" />
      </ApiBoundary>
    </MainLayout>
  );
};

export default StudioDeletePopup;

const Title = styled(Typography)`
  padding: 8px 20px 24px;
`;

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import usePostStaffSudamateCommentStore, {
  StaffSudamateCommentStoreParams,
} from 'hooks/service/mutations/usePostStaffSudamateCommentStore';
import { StaffSudamateCommentResponse } from 'hooks/service/queries/useInfinityStaffSudamateComment';
import useErrorDialog from 'hooks/useErrorDialog';
import useParamsId from 'hooks/useParamsId';
import useToast from 'hooks/useToast';
import { useForm } from 'react-hook-form';
import localStorage from 'utils/localStorage';
import * as yup from 'yup';

import AutoResizeTextarea from './AutoResizeTextarea';

const yupSchema = yup.object().shape({
  contents: yup.string().required('댓글을 입력해 주세요.'),
});

type SudamateCommentForm = {
  contents: string;
  isSecret: boolean;
};

type Props = {
  refetchComments: (options?: RefetchOptions | undefined) => Promise<
    QueryObserverResult<
      {
        commenets: StaffSudamateCommentResponse[];
        total: number;
      },
      Error
    >
  >;
};

const CommentForm = ({ refetchComments }: Props) => {
  const postId = useParamsId();
  const currentStaffAccountId = localStorage.get('account_id');
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();
  const { mutate: postStaffSudamateCommentMutate } = usePostStaffSudamateCommentStore();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, isSubmitting },
  } = useForm<SudamateCommentForm>({
    mode: 'onChange',
    resolver: yupResolver(yupSchema),
    defaultValues: {
      contents: '',
      isSecret: false,
    },
  });

  const isSecret = watch('isSecret');

  const clickIsSecret = () => {
    setValue('isSecret', !getValues('isSecret'));
  };

  const onSubmit = (values: SudamateCommentForm) => {
    const requestBody: StaffSudamateCommentStoreParams = {
      staff_sudamate_id: postId,
      contents: values.contents,
      parent_staff_account_id: currentStaffAccountId,
      depth: 0,
    };
    postStaffSudamateCommentMutate(requestBody, {
      onSuccess: () => {
        setToast({ type: 'success', message: '댓글이 등록되었습니다.', bottom: 86 });
        refetchComments();
      },
      onError: error => errorDialog.open(error),
    });
  };

  return (
    <Container isDisabled={!isValid || isSubmitting}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AutoResizeTextarea controllerProps={{ control, name: 'contents' }} />
        <div className="right">
          <IconButton onClick={clickIsSecret}>
            <Icon name={!isSecret ? 'lockOpenRegular' : 'lockRegular'} color={!isSecret ? 'gray4' : 'gray2'} size={24} />
          </IconButton>
          <IconButton type="submit" className="submit-comment-button" disabled={!isValid || isSubmitting}>
            <Icon name="sortingDownBold" color={!isValid || isSubmitting ? 'gray4' : 'white'} size={24} />
          </IconButton>
        </div>
      </Form>
    </Container>
  );
};

export default CommentForm;

const Container = styled.div<{ isDisabled: boolean }>`
  position: fixed;
  width: 100%;
  padding: 12px 20px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.color.white};
  box-shadow: 0px -2px 3px 0px rgba(145, 145, 145, 0.12);
  form {
    ${theme.flex('row', 'center', '', 12)}
    width: 100%;
  }
  .right {
    ${theme.flex('row', 'center', '', 12)}

    .submit-comment-button {
      width: 40px;
      height: 40px;
      padding: 17px 16px;
      border-radius: 50%;
      background-color: ${({ isDisabled }) => (isDisabled ? theme.color.gray7 : theme.color.point)};
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import DateCalendarPickerDrawer from 'designedComponents/Pickers/DateCalendarPicker/DateCalendarPickerDrawer';
import PickerButtonOutlined from 'designedComponents/Pickers/PickerButton/PickerButtonOutlined';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import filters from 'utils/filters';

type Props = Pick<UserTicketProps, 'canUpdateUserTicket'>;

/** 정지 기간 수정 폼에서 종료일 변경 picker */
const HoldingEndPicker = ({ canUpdateUserTicket }: Props) => {
  const { control, setValue } = useFormContext<TicketHoldingFormType>();
  const startOn = useWatch({ control, name: 'params.start_on' });
  const endOn = useWatch({ control, name: 'params.end_on' });

  const [isOpen, setIsOpen] = useState(false);
  const [tempEndDate, setTempEndDate] = useState(endOn);

  const changeDate = (e: { value: Date }) => {
    setTempEndDate(filters.dateDash(e.value));
  };

  const closeCalendar = () => {
    setTempEndDate(endOn);
    setIsOpen(false);
  };

  const saveDate = () => {
    setValue('params.end_on', tempEndDate, { shouldDirty: true });
    setIsOpen(false);
  };

  return (
    <>
      <Container>
        <PickerButtonOutlined disabled={true}>{startOn ? filters.date(startOn) : '시작일'}</PickerButtonOutlined>
        <Typography span size={15} weight={500} textColor="gray2">
          ~
        </Typography>
        <PickerButtonOutlined
          disabled={!canUpdateUserTicket}
          onClick={() => {
            setIsOpen(true);
          }}>
          {endOn ? filters.date(endOn) : '종료일'}
        </PickerButtonOutlined>
      </Container>

      <DateCalendarPickerDrawer
        isOpen={isOpen}
        onClose={closeCalendar}
        min={startOn}
        value={tempEndDate}
        onSave={saveDate}
        onChange={changeDate}
      />
    </>
  );
};

export default HoldingEndPicker;

const Container = styled.div`
  ${theme.flex('', 'center', 'space-between', 8)};
`;

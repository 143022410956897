import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Textarea from 'components/Textarea';
import OutlinedTextField from 'components/TextField/components/OutlinedTextField';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { APP_LINK_TEXT } from 'pages/MemberDetail/constants';
import { AppLinkMessageFormType } from 'pages/MemberDetail/types';
import { Controller, useFormContext } from 'react-hook-form';
import SendReservation from 'sharedComponents/Message/SendReservation';
import { smsByteCheck } from 'utils/smsByteCheck';

import SendCheck from './SendCheck';

type Props = {
  onSubmit: (values: AppLinkMessageFormType) => void;
};

const AppLinkMessageForm = ({ onSubmit }: Props) => {
  const { control, getValues, setValue } = useFormContext<AppLinkMessageFormType>();

  return (
    <>
      <Container>
        <TextWrapper>
          <OutlinedTextField id="name" label={APP_LINK_TEXT.form.label.member} value={getValues('name')} readOnly />
          <OutlinedTextFieldController
            controllerProps={{ control, name: 'title' }}
            label={APP_LINK_TEXT.form.label.title}
            onClear={() => setValue('title', '', { shouldDirty: true })}
          />
          <Controller
            name="message"
            control={control}
            render={({ field, fieldState }) => (
              <Textarea
                {...field}
                height={210}
                label={APP_LINK_TEXT.form.label.message}
                maxLength={2000}
                currentLength={smsByteCheck(field.value)}
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </TextWrapper>
        <Divider />
        <SendReservation />
      </Container>

      {/* 보내기, 예약발송 버튼과 상태에 따른 Drawer 노출  */}
      <SendCheck onSubmit={onSubmit} />
    </>
  );
};

export default AppLinkMessageForm;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  min-height: 100%;

  > div:not(:first-of-type) {
    flex: 1;
  }

  hr {
    flex: 0 0 auto;
  }
`;

const TextWrapper = styled.div`
  padding: 16px 20px 56px;
  flex: 0 0 auto;

  .outlined-text-field {
    margin-bottom: 32px;
  }
  .textarea {
    min-height: 210px;
  }
`;

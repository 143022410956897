import { useIsFetching } from '@tanstack/react-query';
import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import FormMain from './FormMain';

/** 프라이빗 이후 모든 수업 수정 (코스 수정) */
const BookingPrivateUpdateAll = () => {
  const currentLectureId = useParamsId();
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <FormMain currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingPrivateUpdateAll;

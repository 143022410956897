import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  deleteSubmit: () => void;
};

const EventScheduleDialog = ({ isOpen, onClose, deleteSubmit }: Props) => {
  if (!isOpen) return null;

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: '취소', onClick: onClose, style: 'outlined' }}
      positiveAction={{ text: '삭제', onClick: deleteSubmit }}>
      <DialogDescription>일정을 삭제하시겠습니까?</DialogDescription>
    </Dialog>
  );
};

export default EventScheduleDialog;

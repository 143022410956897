import useInfinityStaffNoticeCommon from 'hooks/service/queries/useInfinityStaffNoticeCommon';
import useQueryString from 'hooks/useQueryString';
import { PUSH_TEXT } from 'pages/Notification/constants';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';

import PushListItem from './PushListItem';
import { getPushFilter } from './utils';

const PushList = () => {
  const { getSearchParams } = useQueryString();
  const filter = getPushFilter(getSearchParams('filter') ?? '');

  const { data: pushItems, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityStaffNoticeCommon(filter);
  if (pushItems?.length === 0) {
    return <NodataTypography>{PUSH_TEXT.noData}</NodataTypography>;
  }
  return (
    <InfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage}>
      {pushItems?.map(pushItem => <PushListItem key={pushItem.id} pushItem={pushItem} />)}
    </InfiniteScroll>
  );
};

export default PushList;

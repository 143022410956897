export const studio = [
  {
    id: 0,
    name: '디오 요가&필라테스',
    subdomain: 'studiomate',
    disable: false,
    expire_on: '2030-05-27',
  },
  {
    id: 9,
    name: '만료된 스튜디오',
    subdomain: 'expired-studio',
    disable: true,
    expire_on: '2022-05-27',
  },
];

export const studioHolidays = [
  {
    date: '2024-02-27',
    color: 'f95454',
    reason: '휴일 입니다',
  },
];

export const studioInfo = [
  {
    id: 8,
    name: 'studiomate',
    contactInfos: [
      {
        id: 43485,
        contact: '01012341234',
        is_representative: true,
        type: 'cellphone',
        created_at: '2022-06-24 04:30:23',
        updated_at: '2022-06-24 04:30:23',
      },
    ],
    openEvents: [
      {
        id: 43485,
        day_of_week: 1,
        day_of_week_ko: '월',
        is_full_day_event: false,
        start_date: 'string',
        start_on: 'string',
        start_time: '09:00:00',
        end_date: 'string',
        end_on: 'string',
        end_time: '18:00:00',
        created_at: '2022-06-24 04:30:23',
        updated_at: '2022-06-24 04:30:23',
      },
    ],
    address: {
      id: 1234,
      studio_id: 17352,
      postcode: '06140',
      address: '서울 강남구 논현로 98길',
      sido: '서울',
      sigungu: '강남구',
      roadname: '논현로 98길',
      detail_address: '스튜디오메이트',
      lat: '11.12',
      lng: '14.15',
      deleted_at: '2022-06-24 04:30:23',
      created_at: '2022-06-24 04:30:23',
      updated_at: '2022-06-24 04:30:23',
    },
    holidays: {
      id: 43485,
      studio_id: '8',
      date: '2024-03-21',
      color: 'f95454',
      reason: '정기 휴일',
      created_at: '2022-06-24 04:30:23',
    },
  },
  {
    id: 9,
    name: '디오 요가 & 필라테스',
    contactInfos: [
      {
        id: 43485,
        contact: '02-999-4444',
        is_representative: true,
        type: 'cellphone',
        created_at: '2022-06-24 04:30:23',
        updated_at: '2022-06-24 04:30:23',
      },
    ],
    openEvents: [
      {
        id: 43485,
        day_of_week: 1,
        day_of_week_ko: '월',
        is_full_day_event: false,
        start_date: 'string',
        start_on: 'string',
        start_time: '09:00:00',
        end_date: 'string',
        end_on: 'string',
        end_time: '18:00:00',
        created_at: '2022-06-24 04:30:23',
        updated_at: '2022-06-24 04:30:23',
      },
      {
        id: 43485,
        day_of_week: 2,
        day_of_week_ko: '화',
        is_full_day_event: false,
        start_date: 'string',
        start_on: 'string',
        start_time: '09:00:00',
        end_date: 'string',
        end_on: 'string',
        end_time: '18:00:00',
        created_at: '2022-06-24 04:30:23',
        updated_at: '2022-06-24 04:30:23',
      },
      {
        id: 43485,
        day_of_week: 3,
        day_of_week_ko: '수',
        is_full_day_event: false,
        start_date: 'string',
        start_on: 'string',
        start_time: '09:00:00',
        end_date: 'string',
        end_on: 'string',
        end_time: '18:00:00',
        created_at: '2022-06-24 04:30:23',
        updated_at: '2022-06-24 04:30:23',
      },
    ],
    address: {
      id: 1234,
      studio_id: 17352,
      postcode: '06140',
      address: '서울 중구 퇴계로 123길',
      sido: '서울',
      sigungu: '강남구',
      roadname: '논현로 98길',
      detail_address: '디오 요가 & 필라테스',
      lat: '11.12',
      lng: '14.15',
      deleted_at: '2022-06-24 04:30:23',
      created_at: '2022-06-24 04:30:23',
      updated_at: '2022-06-24 04:30:23',
    },
    holidays: {
      id: 43485,
      studio_id: '8',
      date: '2024-03-21',
      color: 'f95454',
      reason: '정기 휴일',
      created_at: '2022-06-24 04:30:23',
    },
  },
];

import styled from '@emotion/styled';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { EVENT_CARD_TYPE } from 'pages/Schedule/ScheduleMain/constants';
import { useMemo } from 'react';

type Props = {
  original: MbscCalendarEvent;
};

const MonthAgendaCard = ({ original }: Props) => {
  const { type, title, isPrivate, currentTraineeCount, maxTrainee } = original;

  const isEtcSchedule = type === EVENT_CARD_TYPE.etcSchedule || type === EVENT_CARD_TYPE.allDayEtcSchedule;

  const etcLength = isEtcSchedule ? original.staffs.length : 0;

  const currentStaffName = useMemo(() => {
    if (isEtcSchedule) {
      const firstStaffName = etcLength && original.staffs[0];
      return `${firstStaffName}`;
    }
    return `${original.staffName}`;
  }, [isEtcSchedule, etcLength, original]);

  const currentInfoText = useMemo(() => {
    switch (type) {
      case 'G': {
        return `(${currentTraineeCount}/${maxTrainee}) 그룹 수업`;
      }
      case 'P': {
        const { maxTrainee } = original;
        if (maxTrainee === 2) return '(1:2) 듀엣';
        if (maxTrainee === 3) return '(1:3) 트리플';
        return '(1:1) 개인';
      }
      case 'C':
        return '상담';
      default:
        return '';
    }
  }, [original, type, currentTraineeCount, maxTrainee]);

  return (
    <Container>
      <div className="title-wrapper">
        {isPrivate && <Icon name="lockBold" size={16} fillColor={theme.color.gray2} />}
        <Typography size={15} weight={600} ellipsis={1}>
          {title}
        </Typography>
      </div>

      <div className="info-wrapper">
        {!isEtcSchedule && (
          <>
            <Typography className="info-text" textColor="gray2">
              {currentInfoText}
            </Typography>
            <span className="vertical-divide" />
          </>
        )}

        <Typography textColor="gray2" ellipsis={1}>
          {currentStaffName}
        </Typography>

        <Typography className="no-ellipsis" textColor="gray2">
          {' 강사'}
        </Typography>
        {etcLength > 1 && <Typography className="no-ellipsis" textColor="gray2">{` 외 ${etcLength - 1}명`}</Typography>}
      </div>
    </Container>
  );
};

export default MonthAgendaCard;

const Container = styled.div`
  .title-wrapper {
    ${theme.flex('row', 'center', 'flex-start', 4)};
    margin-bottom: 4px;
  }

  .info-wrapper {
    ${theme.flex('row', 'center', 'flex-start', 6)};

    .vertical-divide {
      width: 1px;
      height: 10px;
      background-color: ${theme.color.gray4};
    }

    .no-ellipsis {
      min-width: max-content;
    }

    .info-text {
      min-width: max-content;
    }
  }
`;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import Icon from 'components/Icon';

type Props = Omit<ButtonProps, 'padding' | 'rightIcon' | 'fullWidth' | 'value' | 'children'> & {
  placeholder?: string;
  value?: string;
  noArrow?: boolean;
  isActive?: boolean;
};

const StudioSelectButton = ({ value, placeholder, noArrow = false, isActive = false, ...props }: Props) => {
  return (
    <StyledButton
      fullWidth
      variant="outlined"
      size="medium48"
      fontSize={14}
      fontWeight={500}
      textColor={value ? 'gray1' : 'gray4'}
      rightIcon={!noArrow ? <Icon name="arrowBottomBold" color="gray3" size={16} /> : undefined}
      borderRadius={8}
      isActive={isActive}
      {...props}>
      {value || placeholder}
    </StyledButton>
  );
};

export default StudioSelectButton;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  ${theme.flex('row', 'center', 'space-between', 8)}
  padding: 0 20px;
  margin: 24px 20px 20px;
  width: calc(100% - 40px);

  ${({ isActive }) => isActive && `svg { transform: rotate(180deg); }`}

  :hover {
    background-color: unset;
  }
`;

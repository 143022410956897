import { useSuspenseQuery } from '@tanstack/react-query';
import { StudiomateNoticeDetailType } from 'pages/MoreDetails/Settings/Notice/Detail/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStudiomateNoticeDetail = (id: number) => {
  const { queryFn } = useQueryFnWithCommonParams<StudiomateNoticeDetailResponse, StudiomateNoticeDetailParams>({
    url: `/api/studiomate-notice/${id}`,
    params: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useSuspenseQuery({
    queryKey: [`studiomate-notice`, id],
    queryFn,
    select: data => data.data,
  });
};

export default useGetStudiomateNoticeDetail;

type StudiomateNoticeDetailParams = {
  studio_id: undefined;
  staff_id: undefined;
};
type StudiomateNoticeDetailResponse = StudiomateNoticeDetailType;

import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

/** 알림용, 체크인 메세지 안읽음 개수 */
const useGetStaffNoticeCheckInNoReadCount = () => {
  const { queryFn } = useQueryFnWithCommonParams<StaffNoticeCheckInNoReadCountResponse>({
    url: `/api/staff/notice/check-in-no-read-count`,
  });

  return useSuspenseQuery({
    queryKey: ['staff/notice', 'check-in', 'no-read-count'],
    queryFn,
    select: data => data.data,
  });
};

export default useGetStaffNoticeCheckInNoReadCount;

type StaffNoticeCheckInNoReadCountResponse = {
  is_checked_check_in_false_count: number;
};

import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import DivisionLinkButton from './DivisionLinkButton';
import DivisionSetting from './DivisionSetting';

const DivisionField = () => {
  return (
    <ApiBoundary fallback={<DivisionLinkButton disabled>수업구분</DivisionLinkButton>}>
      <DivisionSetting />
    </ApiBoundary>
  );
};

export default DivisionField;

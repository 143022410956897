import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  onClose: () => void;
};

const PastLectureUpdateDialog = ({ onClose }: Props) => {
  return (
    <Dialog isError onClose={onClose} positiveAction={{ text: '확인', onClick: onClose }}>
      <DialogDescription>과거 수업은 수정할 수 없습니다.</DialogDescription>
    </Dialog>
  );
};

export default PastLectureUpdateDialog;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { PUSH_STATUS_COUNT_TEXT } from 'pages/MoreDetails/Message/Push/Detail/constants';
import filters from 'utils/filters';

import { MOBILE_STATUS_COUNT_TEXT } from '../constants';
import { MobileDetailStatusCountType, MobileDetailType } from '../type';

type Props = {
  type?: 'mobile' | 'push';
  status: MobileDetailType['status'];
};

const StatusDetail = ({ type = 'mobile', status }: Props) => {
  const TEXT = type === 'mobile' ? MOBILE_STATUS_COUNT_TEXT : PUSH_STATUS_COUNT_TEXT;
  const statusKeys = Object.keys(TEXT) as (keyof MobileDetailStatusCountType)[];

  return (
    <Row>
      {statusKeys.map(statusKey => (
        <Status key={statusKey}>
          <Typography size={15} weight={500}>
            {MOBILE_STATUS_COUNT_TEXT[statusKey].text}
          </Typography>
          <Typography size={15} weight={600} textColor={MOBILE_STATUS_COUNT_TEXT[statusKey].color}>
            {filters.numberComma(status[statusKey])}
          </Typography>
        </Status>
      ))}
    </Row>
  );
};

export default StatusDetail;

const Row = styled.div`
  ${theme.flex('', 'center', '')}
`;

const Status = styled.div`
  ${theme.flex('', 'center', '', 4)}

  position: relative;
  &:not(:last-of-type) {
    margin-right: 13px;
    :after {
      content: ' ';

      background-color: ${theme.color.gray4};
      width: 1px;
      position: absolute;
      right: -7px;
      top: 6px;
      bottom: 6px;
    }
  }
`;

import Divider from 'components/Divider';
import useQueryString from 'hooks/useQueryString';
import { LectureOperationFormType, LecturePolicyFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';

import SettingByRuleType from './SettingByRuleType';

/**
 * 그룹 수업 등록/복사/이후모든수정 폼, 프라이빗 이후모든수정 폼의
 * 예약/예약취소 가능시간 설정
 */
const BookingAvailableSetting = () => {
  const { getValues, setValue } = useFormContext<LectureOperationFormType>();
  const {
    policy: { booking, bookingCancel },
  } = getValues();

  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const [expanded, setExpanded] = useState<string | false>(getSearchParams('detail') as string);
  const [tempBooking, setTempBooking] = useState(booking);
  const [tempBookingCancel, setTempBookingCancel] = useState(bookingCancel);

  const changeAccordionOpen = (panel: string) => {
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  const changeBooking = (value: LecturePolicyFormType) => {
    setTempBooking(value);
  };

  const changeBookingCancel = (value: LecturePolicyFormType) => {
    setTempBookingCancel(value);
  };

  const saveDate = () => {
    setValue('policy.booking', tempBooking, { shouldDirty: true });
    setValue('policy.bookingCancel', tempBookingCancel, { shouldDirty: true });
    navigate(-1);
  };

  return (
    <>
      <SettingByRuleType
        textType="booking"
        iconName="possible"
        isOpen={expanded === 'booking'}
        onToggle={() => changeAccordionOpen('booking')}
        tempPolicy={tempBooking}
        changeTempPolicy={changeBooking}
      />
      <Divider />
      <SettingByRuleType
        textType="cancel"
        iconName="roomCancel"
        isOpen={expanded === 'bookingCancel'}
        onToggle={() => changeAccordionOpen('bookingCancel')}
        tempPolicy={tempBookingCancel}
        changeTempPolicy={changeBookingCancel}
      />
      <FooterButton onClick={saveDate}>완료</FooterButton>
    </>
  );
};

export default BookingAvailableSetting;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteCustomQueryFnWithCommonParams from '../common/useInfiniteCustomQueryFnWithCommonParams';
import { ScheduleManagementBookingParams, ScheduleManagementBookings } from './useInfinityScheduleManagementBooking';

const useInfinityScheduleManagementDeletedLecture = (params: ScheduleManagementDeletedLectureParams) => {
  const { queryFn } = useInfiniteCustomQueryFnWithCommonParams<
    ScheduleManagementDeletedLectureResponse,
    ScheduleManagementDeletedLectureParams
  >({
    url: `/api/schedule-management/deleted-lecture`,
    params: {
      ...params,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`schedule/management/deleted/lecture`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        deletedLectures: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
        groupBookingCount: data.pages[0].data.group_booking_count,
        privateBookingCount: data.pages[0].data.private_booking_count,
      };
    },
  });
};

export default useInfinityScheduleManagementDeletedLecture;

export type ScheduleManagementDeletedLectureParams = Omit<ScheduleManagementBookingParams, 'status'>;
export type ScheduleManagementDeletedLecture = Omit<ScheduleManagementBookings, 'deleted_by'> & {
  /** 삭제한 사람 정보 */
  deleted_by: {
    /** 스태프 아이디 */
    id: number;
    /** 시설 아이디 */
    studio_id: number;
    /** 스테프 이름  */
    name: string;
  };
};

export type ScheduleManagementDeletedLectureResponse = {
  data: ScheduleManagementDeletedLecture[];
  /** 그룹수업 예약 인원 */
  group_booking_count: number;
  /** 프라이빗 수업 예약 인원 */
  private_booking_count: number;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { useRecoilValue } from 'recoil';
import { staffListPageAtom } from 'recoil/staff';

const MoreDetailStaffNoList = () => {
  const { search } = useRecoilValue(staffListPageAtom);

  return (
    <Container>
      <Typography size={15} lineHeight={22} textColor="gray2">
        {search ? (
          <>
            <span>{`"${search}"`}</span>의 검색 결과가 없습니다.
          </>
        ) : (
          '담당회원 정보가 없습니다.'
        )}
      </Typography>
    </Container>
  );
};

export default MoreDetailStaffNoList;

const Container = styled.div`
  ${theme.flex()};
  height: 240px;

  .typography > span {
    font-weight: 600;
    color: ${theme.color.primary};
  }
`;

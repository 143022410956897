import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import { useState } from 'react';
import SubHeader from 'sharedComponents/ProfileField/components/EventWorkTime/components/SubHeader';
import { EVENT_WORK_TIME_TITLE } from 'sharedComponents/ProfileField/components/EventWorkTime/constants';
import filters from 'utils/filters';
import staffUtils from 'utils/staffSchedules';

import { CommonProps } from '../types';
import RestTimeAccordion from './WorkTime/components/RestTimeAccordion';
import WorkTimeAccordion from './WorkTime/components/WorkTimeAccordion';
import { CLOSED_DAY_TIME } from './WorkTime/constants';

type Props = CommonProps;

const EventWorkTimeCard = ({
  workTimeField,
  setWorkTimeField,
  isFieldClosedDay,
  setIsFieldClosedDay,
  isAccordionOpen,
  setIsAccordionOpen,
}: Props) => {
  const [tempTimes, setTempTimes] = useState<[string, string]>([workTimeField.start_time, workTimeField.end_time]);

  const appendRestTimeField = () => {
    const end_time = dayjs(`${dayjs().format('YYYY-MM-DD')} ${workTimeField.start_time}`)
      .add(30, 'minute')
      .format('HH:mm:ss');

    setWorkTimeField(prev => {
      return {
        ...prev,
        restTimes: [
          ...(prev.restTimes || []),
          {
            ...omit(prev, 'restTimes'),
            id: 0,
            type: 'eventRestTime',
            end_time,
          },
        ],
      };
    });
  };

  return (
    <Container>
      <section className="card-header">
        <Typography size={15} weight={600} textColor="gray1">
          {filters.dateWithWeekday(workTimeField.date)}
        </Typography>

        <CheckBox
          id={`closed-checkbox-${workTimeField.id}`}
          label={EVENT_WORK_TIME_TITLE.closed}
          gap={8}
          checked={isFieldClosedDay}
          labelFontWeight={500}
          onChange={({ target: { checked } }) => {
            if (checked) {
              setTempTimes([workTimeField.start_time, workTimeField.end_time]);
              setIsAccordionOpen(false);
            }

            const defaultTimes = {
              start: tempTimes[0] === CLOSED_DAY_TIME.start ? '09:00:00' : tempTimes[0],
              end: tempTimes[1] === CLOSED_DAY_TIME.end ? '18:00:00' : tempTimes[1],
            };

            setWorkTimeField({
              ...workTimeField,
              type: checked ? 'eventRestTime' : 'eventWorkTime',
              start_time: checked ? CLOSED_DAY_TIME.start : defaultTimes.start,
              end_time: checked ? CLOSED_DAY_TIME.end : defaultTimes.end,
            });
            setIsFieldClosedDay(checked);
          }}
        />
      </section>

      {!isFieldClosedDay && (
        <>
          <section className="work-time-info">
            <SubHeader
              title={EVENT_WORK_TIME_TITLE.work}
              diffText={staffUtils.workTimeDiff(workTimeField.start_time, workTimeField.end_time)}
            />
            <WorkTimeAccordion
              workTimeField={workTimeField}
              setWorkTimeField={setWorkTimeField}
              isAccordionOpen={isAccordionOpen}
              setIsAccordionOpen={setIsAccordionOpen}
            />
          </section>

          <Divider thin />

          <section className="rest-time-info">
            <SubHeader
              title={EVENT_WORK_TIME_TITLE.rest}
              diffText={workTimeField.restTimes ? staffUtils.restTimeDiffTest(workTimeField.restTimes, workTimeField) : '0'}
              onClick={() => {
                appendRestTimeField();
              }}
            />
            <ul className="rest-time-list">
              {workTimeField.restTimes &&
                workTimeField.restTimes.map((restTimeField, index, list) => {
                  const isLastIndex = index === list.length - 1;

                  return (
                    <li key={`${restTimeField.day_of_week}-${index}`}>
                      {index > 0 && <Divider thin />}

                      <div className="rest-time-accordion-wrapper">
                        <RestTimeAccordion
                          restTimeField={restTimeField}
                          setWorkTimeField={setWorkTimeField}
                          startWorkTime={workTimeField.start_time}
                          endWorkTime={workTimeField.end_time}
                          isLastIndex={isLastIndex}
                          appendRestTimeField={appendRestTimeField}
                          restTimeIndex={index}
                        />
                      </div>
                    </li>
                  );
                })}
            </ul>
          </section>
        </>
      )}
    </Container>
  );
};

export default EventWorkTimeCard;

const Container = styled.section`
  width: 100%;
  border-radius: 16px;
  background-color: ${theme.color.white};
  box-shadow: 0;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  .card-header {
    ${theme.flex('row', 'center', 'space-between')};
    padding: 0 20px;

    .checkbox > .typography {
      font-size: 1.4rem;
      color: ${theme.color.gray2} !important;
    }
  }

  .work-time-info {
    margin-top: 32px;
  }

  .rest-time-info {
    margin-top: 16px;
  }

  .work-time-info,
  .rest-time-info {
    > div {
      ${theme.flex('row', 'center', 'space-between')};

      &:first-of-type {
        margin-bottom: 8px;
      }

      .accordion {
        .header-box,
        .rest-time-info {
          width: 100%;
        }

        .rest-time-info {
          ${theme.flex('row', 'center', 'space-between')};
        }

        .header-wrapper {
          margin-bottom: 16px;
        }

        .header-wrapper.accordion__large {
          padding: 0;
          height: 22px;
        }
      }
    }

    .accordion {
      width: 100%;
    }

    .add-rest-time {
      margin-top: 16px;
    }
  }

  .rest-time-info {
    .header-wrapper {
      margin-bottom: 12px;
      padding: 0;
      height: inherit;
    }

    li:not(:first-of-type) > hr {
      margin-bottom: 12px;
    }

    li:is(:last-of-type) {
      .header-wrapper {
        margin-bottom: 0;
      }
    }
  }
`;

import styled from '@emotion/styled';
import useObserver, { ObserverTarget } from 'hooks/useObserver';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { useRef, useState } from 'react';
import SortKeyword from 'sharedComponents/Board/SortKeyword';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import HeaderSearchButton from 'sharedComponents/HeaderSearchButton';
import MainLayout from 'sharedComponents/MainLayout';

import QnaList from './components/QnaList';
import SkeletonList from './components/SkeletonList';
import { QNA_IDENTIFIER, QNA_LIST_TEXT, QNA_SORT_IDENTIFIER } from './constants';

const MoreDetailsQnaList = () => {
  const [isScrollTop, setIsScrollTop] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  useObserver({ ref, show: () => setIsScrollTop(true), hide: () => setIsScrollTop(false) });
  useScrollRestoration();

  return (
    <MainLayout
      header={{
        title: QNA_LIST_TEXT.title,
        rightButton: <HeaderSearchButton placeholder={QNA_LIST_TEXT.searchPlaceholder} identifier={QNA_IDENTIFIER} />,
        noUnderline: true,
      }}>
      <StyledSortKeyword identifier={QNA_SORT_IDENTIFIER} borderHidden={isScrollTop} />
      <ObserverTarget ref={ref} />
      <ApiBoundary fallback={<SkeletonList />}>
        <QnaList />
      </ApiBoundary>
    </MainLayout>
  );
};

export default MoreDetailsQnaList;

const StyledSortKeyword = styled(SortKeyword)<{ borderHidden: boolean }>`
  ${({ borderHidden }) => borderHidden && `border-bottom: none;`}
`;

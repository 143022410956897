import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostMember = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<MemberCreateParams, MemberCreateResponse>({
    method: 'post',
    url: '/api/member',
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePostMember;

export type MemberCreateParams = {
  profile: {
    name: string;
    gender?: string | null;
    birthday?: string | null;
    registered_at?: string;
  };
  mobile?: string;
  user_grade_id?: number | null;
  vaccination_yn?: string;
  image?: string;
  address?: {
    address: string | null; // 서울 강남구 논현로98길 28 (역삼동)
    detail_address: string | null; // 1층 스튜디오메이트 (textarea 입력값)
  };
  counseling_id?: number;
};

export type MemberCreateResponse = {
  id: number;
};

import styled from '@emotion/styled';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { useNavigate } from 'react-router-dom';

import { MENUS } from '../constants';
import { MenuType } from '../types';

type Props = {
  onBasicMenuClick?: () => void;
  onSubMenuClick?: (menu: MenuType) => void;
};

/**
 * 시설미연동, fallback 등 상황에서 활용
 */
const FakeNavigation = ({ onBasicMenuClick, onSubMenuClick: propsOnSubMenuClick }: Props) => {
  const navigate = useNavigate();

  const onSubMenuClick = (menu: MenuType) => {
    propsOnSubMenuClick?.(menu);

    // onClick props가 없으면 path로 이동
    if (!propsOnSubMenuClick && menu?.path) {
      navigate(menu.path);
    }
  };

  return (
    <>
      {MENUS.basic.map(menu => (
        <BasicMenu key={menu.name} onClick={onBasicMenuClick}>
          <Typography size={15} weight={600}>
            {menu.name}
          </Typography>
        </BasicMenu>
      ))}
      <DividerWithMargin thin thinColor="gray6" />
      {MENUS.sub.map(menu => (
        <SubMenu key={menu.name} onClick={() => onSubMenuClick(menu)}>
          <Typography weight={500} textColor="gray2">
            {menu.name}
          </Typography>
        </SubMenu>
      ))}
    </>
  );
};

export default FakeNavigation;

const BasicMenu = styled(Button)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 13px 20px 13px;
`;

const DividerWithMargin = styled(Divider)`
  margin: 12px 0;
`;

const SubMenu = styled(Button)`
  display: block;
  width: 100%;
  text-align: left;
  padding: 12px 20px 12px;
`;

import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { UserTicketResponse } from '../queries/useInfinityUserTicketProduct';
import { TicketPaymentParams } from './usePostUserTicket';

const usePatchUserTicketUpdate = () => {
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<{ user_tickets: Array<UserTicketUpdateParams> }, UserTicketResponse>({
    method: 'patch',
    url: '/api/userTicket/update',
    params: {
      staff_id: undefined,
      studio_id: undefined,
    },
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['user-ticket'],
      });
    },
  });
};

export default usePatchUserTicketUpdate;

export type UserTicketCommonParams = {
  staff_id: number;
  studio_id: number;
  user_id: number;
};

export type UserTicketUpdateParams = UserTicketCommonParams &
  Pick<
    UserTicketResponse,
    'max_coupon' | 'remaining_cancel' | 'remaining_coupon' | 'availability_start_at' | 'expire_at' | 'is_shared'
  > & {
    staffs?: number[] | null;
    payment?: TicketPaymentParams & {
      id: number;
      status: string;
    };
    user_ticket_id: number;
  };

import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import ReserveTicketsUI from 'pages/Booking/SelectMember/ReserveTicketsUI';
import { useFormContext, useWatch } from 'react-hook-form';

const ReserveUserTickets = () => {
  const { control, getValues } = useFormContext<BookingPrivateFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const selectedCount = selectedUserTickets.length;

  return (
    <ReserveTicketsUI
      selectedCount={selectedCount}
      selectedUserTickets={selectedUserTickets}
      showMaxCount
      maxCount={getValues('lectureSize') ? Number(getValues('lectureSize')) : 0}
    />
  );
};

export default ReserveUserTickets;

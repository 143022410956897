import dayjs from 'dayjs';
import filters from 'utils/filters';

import { FilterType, PeriodFilterType } from './types';

export const DEFAULT_PERIOD_FILTER: FilterType = {
  key: 'period',
  type: 'period',
  title: '기간선택',
  options: [
    { label: '3개월', value: undefined },
    { label: '6개월', value: '6months' },
    { label: '12개월', value: '12months' },
    { label: '직접선택', value: 'custom' },
  ],
  tabConverter: value => {
    const periodValue = value as PeriodFilterType['period'];
    if (!periodValue || !periodValue.type) return '3개월';
    if (periodValue.type === '6months') return '6개월';
    if (periodValue.type === '12months') return '12개월';
    return '직접선택';
  },
};

export const DEFAULT_PERIOD = {
  start: filters.dateDash(dayjs().subtract(3, 'month')),
  end: filters.dateDash(),
};

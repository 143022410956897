import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import { TicketCommon } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useQueryString from 'hooks/useQueryString';
import { MemberFormType } from 'pages/MemberForm/types';
import { ProductCreateFormType } from 'pages/ProductForm/types';
import { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CardWrapper } from 'sharedComponents/InfoTypography';
import TicketCard from 'sharedComponents/TicketCard';
import formatTicketCard from 'utils/formatTicketCard';
import getDaysDiff from 'utils/getDaysDiff';

const TicketsList = () => {
  const { control, setValue } = useFormContext<MemberFormType>();
  const { fields, remove } = useFieldArray({ control, name: 'tickets' });

  const { setSearchParams } = useQueryString();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRemoveTicketIndex, setSelectedRemoveTicketIndex] = useState<number | null>(null);
  const [selectedRemoveTicket, setSelectedRemoveTicket] = useState<TicketCommon['available_class_type']>('I');

  const clickUpdate = (state: ProductCreateFormType, index: number) => {
    const productType = state.ticket.available_class_type === 'I' ? 'item' : 'userTicket';

    setValue('selectedCreatedTicket', state, { shouldDirty: true });
    setValue('selectedTicket', state.ticket, { shouldDirty: true });

    setSearchParams({
      type: 'product',
      productMode: 'update',
      updateIndex: `${index}`,
      productType: `${productType}`,
      step: '2',
    });
  };

  const clickRemove = (state: ProductCreateFormType, index: number) => {
    setIsOpen(true);
    setSelectedRemoveTicketIndex(index);
    setSelectedRemoveTicket(state.ticket.available_class_type);
  };

  const removeAddedNewTicket = useCallback(() => {
    if (selectedRemoveTicketIndex !== null) {
      remove(selectedRemoveTicketIndex);
    }
    setIsOpen(false);
  }, [remove, selectedRemoveTicketIndex]);

  const getNewTicket = (values: ProductCreateFormType) => {
    const {
      count: { max_coupon, remaining_coupon, remaining_cancel },
      period: { availability_start_at, expire_at },
    } = values;
    const period = getDaysDiff(expire_at, availability_start_at) + 1;

    return {
      ...values.ticket,
      max_coupon,
      remaining_coupon,
      max_cancel: remaining_cancel,
      available_period: period,
    };
  };

  if (!fields.length) return <></>;

  return (
    <>
      <Container className="ticket-list">
        {fields.map((state, index) => {
          const ticket = { ...getNewTicket(state) };

          return (
            <MemberFormTicketWrapper key={state.id}>
              <TicketCard ticket={formatTicketCard(ticket)} />
              <ButtonGroup gap={4}>
                <Button
                  padding={{ left: 8, right: 8 }}
                  widthSize={64}
                  size="small"
                  variant="outlined"
                  textColor="gray2"
                  onClick={() => clickUpdate(state, index)}>
                  수정
                </Button>
                <Button
                  padding={{ left: 8, right: 8 }}
                  widthSize={64}
                  size="small"
                  variant="outlined"
                  textColor="gray2"
                  onClick={() => clickRemove(state, index)}>
                  삭제
                </Button>
              </ButtonGroup>
            </MemberFormTicketWrapper>
          );
        })}
      </Container>

      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          negativeAction={{ text: '취소' }}
          positiveAction={{ text: '삭제', onClick: removeAddedNewTicket }}>
          <DialogDescription>{PRODUCT_TYPE_TEXT[selectedRemoveTicket]}을 삭제하시겠습니까?</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default TicketsList;

const Container = styled(CardWrapper)`
  padding: 0 20px;
`;

export const MemberFormTicketWrapper = styled.div`
  border-radius: 12px;
  padding: 4px 20px 16px;
  background-color: ${theme.color.gray7};

  .button-group {
    margin-top: 1px;
    padding-left: 65px;
  }
`;

import { useIsMutating } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';

type Props = {
  pageMode: 'create' | 'update';
};

const SubmitButton = ({ pageMode }: Props) => {
  const {
    control,
    formState: { dirtyFields },
  } = useFormContext<MemberFormType>();
  const name = useWatch({ control, name: 'profile.name' });
  const isDirty = !!Object.keys(dirtyFields).length;
  const isMutating = useIsMutating() > 0;

  const disabled = useMemo(() => {
    if (isMutating) return true;
    if (pageMode === 'create' && !isDirty && !!name) return false; // 상담 상세에서 회원 등록으로 넘어온 경우
    if (!isDirty) return true;
    return false;
  }, [isDirty, isMutating, name, pageMode]);

  return (
    <FooterButton type="submit" disabled={disabled}>
      {MEMBER_FORM_TEXT.footerButton[pageMode]}
    </FooterButton>
  );
};

export default SubmitButton;

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentVersionAtom } from 'recoil/moreDetail';
import { postMessageToApp } from 'utils/communicateWithNative';

import { SETTINGS_VERSION } from '../constants';
import SettingItem from './SettingItem';

const CurrentVersion = () => {
  const currentVersion = useRecoilValue(currentVersionAtom);

  useEffect(() => {
    if (!currentVersion) {
      postMessageToApp('REQUEST_CURRENT_VERSION');
    }
  }, [currentVersion]);

  return <SettingItem label={SETTINGS_VERSION} value={currentVersion} />;
};

export default CurrentVersion;

import useQueryString from 'hooks/useQueryString';
import { MemberFormType } from 'pages/MemberForm/types';
import ProductCreateUpdateForm from 'pages/ProductForm/components/ProductCreateUpdateForm';
import { ProductCreateFormType } from 'pages/ProductForm/types';
import { getCreateTicketDefaultValues } from 'pages/ProductForm/utils';
import { useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

/**
 * 회원 등록(수정) 시, 새로운 수강권(상품)을 추가 및 수정할 때
 */
const ProductCreate = () => {
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();

  const { control, getValues, setValue } = useFormContext<MemberFormType>();
  const { append: addTicket, update: updateTicket } = useFieldArray({ control, name: 'tickets' });
  const selectedTicket = useWatch({ control, name: 'selectedTicket' });

  const ticketWithCurrentPoint = useMemo(() => {
    if (!selectedTicket) return;

    return getCreateTicketDefaultValues({
      ticket: selectedTicket,
      currentMemberPoint: getValues('currentMemberPoint'),
    });
  }, [getValues, selectedTicket]);

  const addNewTicketOnList = useCallback(
    (values: ProductCreateFormType) => {
      const updateIndex = getSearchParams('updateIndex');

      if (updateIndex) {
        updateTicket(Number(updateIndex), values);
        navigate(-1);
      } else {
        addTicket(values);
        navigate(-2);
      }

      setValue('selectedCreatedTicket', undefined);
      setValue('selectedTicket', null);
    },
    [getSearchParams, setValue, updateTicket, navigate, addTicket],
  );

  if (!ticketWithCurrentPoint) {
    return null;
  }

  return (
    <ProductCreateUpdateForm
      ticketWithCurrentPoint={ticketWithCurrentPoint}
      onSubmit={addNewTicketOnList}
      selectedCreatedTicket={getValues('selectedCreatedTicket')}
    />
  );
};

export default ProductCreate;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useGetMinHeight from 'hooks/useGetMinHeight';
import { TicketEditFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import { SelectStaffs, ShareTicketSwitch, TicketCountForm, TicketPeriodForm } from 'sharedComponents/TicketForm';

import SubmitButton from './SubmitButton';

type Props = {
  userTicket: UserTicketResponse;
  onSubmit: (values: TicketEditFormType) => void;
};

const TicketEditForm = ({ userTicket, onSubmit }: Props) => {
  const {
    is_show_cancel_count,
    lower_limit_max_coupon,
    lower_limit_remaining_coupon,
    ticket: { type, available_class_type },
  } = userTicket;

  const fullHeight = useGetMinHeight();
  const minHeight = fullHeight - 56 - 86 - 8 + 40; // 전체 - form 상단 영역 + 푸터버튼과 폼 여백
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);
  const { handleSubmit } = useFormContext<TicketEditFormType>();

  return (
    <StyleForm onSubmit={handleSubmit(onSubmit)} isKeyboardShow={isKeyboardShow} minHeight={minHeight}>
      <TicketPeriodForm ticketType={type} />
      <TicketCountForm
        ticketType={type}
        isShowCancelCount={!!is_show_cancel_count}
        minMaxCoupon={lower_limit_max_coupon}
        minRemainingCoupon={lower_limit_remaining_coupon}
      />
      {available_class_type !== 'I' && <ShareTicketSwitch />}
      <SelectStaffs />
      <SubmitButton />
    </StyleForm>
  );
};

export default TicketEditForm;

const StyleForm = styled(Form)<{ isKeyboardShow: boolean; minHeight: number }>`
  ${theme.flex('column', '', '')};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  padding-bottom: ${({ isKeyboardShow }) => (isKeyboardShow ? '0px' : '116px')};

  footer {
    margin-top: ${({ isKeyboardShow }) => (isKeyboardShow ? 'auto' : 0)};
  }
`;

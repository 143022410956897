import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetScheduleManagementUseCheckin = (studioId: string) => {
  const { queryFn } = useQueryFnWithCommonParams<ScheduleManagementUseCheckinResponse>({
    url: `/api/schedule-management/check-in/whether-to-use-check-in/${studioId}`,
  });

  return useSuspenseQuery({
    queryKey: [`schedule/management/use/checkin`],
    queryFn,
    select: data => data.data,
  });
};

export default useGetScheduleManagementUseCheckin;

export type ScheduleManagementUseCheckinResponse = {
  result: boolean;
};

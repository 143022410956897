import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';

import { BookingErrorDialogType } from './types';

type Props = {
  onClose: () => void;
  error: Exclude<BookingErrorDialogType, null>;
};

const BookingErrorDialog = ({ onClose, error }: Props) => {
  const text = error.isRefresh ? '새로고침' : '확인';

  return (
    <Dialog
      isError
      onClose={onClose}
      positiveAction={{
        text,
        onClick: error.onClick,
      }}>
      <DialogDescription tag="pre">
        {error.message}
        {!!error.bold && (
          <Wrapper>
            {error.bold.map((text, index) => (
              <Typography span size={15} weight={600} key={`${text}-${index}`}>
                [{text}]
              </Typography>
            ))}
            <br />
          </Wrapper>
        )}
        {error?.isRefresh ? '페이지를 새로고침 하세요.' : null}
      </DialogDescription>
    </Dialog>
  );
};

export default BookingErrorDialog;

const Wrapper = styled.div`
  ${theme.flex('', 'center', 'center', 3)};
  flex-wrap: wrap;

  span {
    display: inline-block;
    white-space: nowrap;
  }
`;

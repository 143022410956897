import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityStaffSudamateComment = (params: StaffSudamateCommentParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<StaffSudamateCommentResponse, StaffSudamateCommentParams>({
    url: `/api/staff-sudamate/comment/index`,
    params: {
      ...params,
      staff_id: undefined,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`staff-sudamate-comment`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        commenets: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
      };
    },
  });
};

export default useInfinityStaffSudamateComment;

type StaffAccount = {
  id: number;
  name: string;
};

type StaffSudamate = {
  id: number;
  staff_account_id: string;
};

export type StaffSudamateCommentParams = {
  staff_sudamate_id: number;
  sort_by: 'asc' | 'desc';
  paginate_type: 'simple' | 'detail';
  staff_id?: number;
};

export type StaffSudamateCommentResponse = {
  id: number;
  staff_account: StaffAccount;
  staff_sudamate: StaffSudamate;
  staff_sudamate_id: string;
  contents: string;
  depth: number;
  order: number;
  parent_staff_account_id: number;
  parent_id: number;
  group_num: number;
  is_secret: number;
  is_hide: boolean;
  report: string;
  updated_at: string;
  replies?: StaffSudamateCommentResponse[]; // 재귀적 구조
};

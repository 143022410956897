export const QNA_DETAIL_TEXT = {
  title: '1:1 문의 상세',
  member: '회원',
  comments: '댓글',
  placeholder: '댓글 입력',
  edit: '수정하기',
  delete: '삭제하기',
  editTitle: '댓글 수정',
  editButton: '수정 완료',
  editSuccess: '댓글이 수정되었습니다.',
  deleteConfirm: '댓글을 삭제하시겠습니까?',
  deleteCancel: '취소',
  deleteButton: '삭제',
  deleteSuccess: '댓글이 삭제되었습니다.',
};

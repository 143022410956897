import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { useRecoilState } from 'recoil';
import { dialogAtom } from 'recoil/dialog';

type Props = {
  dialogAction: (type: 'confirm' | 'cancel') => void;
  count: number;
};

const UsingColorDialog = ({ dialogAction, count = 0 }: Props) => {
  const [isOpen, setIsOpen] = useRecoilState(dialogAtom);

  return (
    <>
      {isOpen && (
        <Dialog
          onClose={() => setIsOpen(false)}
          negativeAction={{ text: '취소', onClick: () => dialogAction('cancel'), style: 'outlined' }}
          positiveAction={{ text: '사용', onClick: () => dialogAction('confirm') }}>
          <DialogDescription>{count}명의 강사가 사용중인 컬러입니다.</DialogDescription>
          <DialogDescription>같은 컬러를 사용하시겠습니까?</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default UsingColorDialog;

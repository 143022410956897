import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { MAX_VISIBLE_PAGES, PAGE_BUFFER, PAGE_LIMIT, PAGE_OFFSET } from '../constant';

const Pagination = ({ lastIndex, movePage }: { lastIndex?: number; movePage: (page: number) => void }) => {
  const [searchParams] = useSearchParams();
  const currentIndex = Number(searchParams.get('page')) || 1;

  const numArray = useMemo(() => [...Array.from({ length: lastIndex || 1 }, (_, i) => i + 1)], [lastIndex]);

  const pageNumbers = useMemo(() => {
    if (!lastIndex) return;

    if (lastIndex === 1) return numArray.slice(0, 1);
    if (lastIndex < MAX_VISIBLE_PAGES) return numArray.slice(0, lastIndex);

    if (currentIndex > PAGE_OFFSET) {
      if (currentIndex + PAGE_BUFFER > lastIndex) return numArray.slice(lastIndex - PAGE_LIMIT, lastIndex);
      return numArray.slice(currentIndex - PAGE_OFFSET, currentIndex + PAGE_BUFFER);
    }

    return numArray.slice(0, PAGE_LIMIT);
  }, [currentIndex, lastIndex, numArray]);

  const leftArrowColor = useMemo(() => (currentIndex === 1 ? 'gray5' : 'gray2'), [currentIndex]);
  const rightArrowColor = useMemo(() => (currentIndex === lastIndex ? 'gray5' : 'gray2'), [currentIndex, lastIndex]);

  return (
    <Container leftArrowColor={leftArrowColor} rightArrowColor={rightArrowColor}>
      {pageNumbers && (
        <>
          <button
            className="arrow-left"
            onClick={() => {
              if (currentIndex === 1) return;
              movePage(currentIndex - 1);
            }}>
            <Icon name="arrowLeftBold" color={leftArrowColor} />
          </button>

          <ul>
            {pageNumbers?.map(num => {
              return (
                <li key={num} className={currentIndex === num ? 'active' : ''} onClick={() => movePage(num)}>
                  {num}
                </li>
              );
            })}
          </ul>

          <button
            className="arrow-right"
            onClick={() => {
              if (currentIndex === lastIndex) return;
              movePage(currentIndex + 1);
            }}>
            <Icon name="arrowRightBold" color={rightArrowColor} />
          </button>
        </>
      )}
    </Container>
  );
};

export default Pagination;

const Container = styled.section<{ leftArrowColor: string; rightArrowColor: string }>`
  ${theme.flex()};
  gap: 12px;
  margin: 40px 0 30px;

  > button {
    ${theme.flex()};
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid ${theme.color.gray4};
    background: ${theme.color.white};
  }

  .arrow-left {
    padding-right: 2px;
    border-color: ${({ leftArrowColor }) => leftArrowColor};
  }

  .arrow-right {
    padding-left: 2px;
    border-color: ${({ rightArrowColor }) => rightArrowColor};
  }

  ul {
    ${theme.flex()};
    gap: 2px;

    li {
      ${theme.flex()};
      width: 28px;
      height: 28px;
      font-size: 14px;
      font-weight: 500;
      color: ${theme.color.gray3};
    }

    .active {
      font-size: 15px;
      font-weight: 700;
      color: ${theme.color.primary};
    }
  }
`;

import SearchBar from 'components/SearchBar';
import useQueryString from 'hooks/useQueryString';
import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  search: string | null;
  isActiveSearch: boolean;
  onClear?: () => void;
  setIsActiveSearch: Dispatch<React.SetStateAction<boolean>>;
};
const SudamateSearch = ({ search, isActiveSearch, setIsActiveSearch }: Props) => {
  const { setSearchParams } = useQueryString();
  const [keyword, setKeyword] = useState(search);

  const seachPlaceholder = useMemo(() => (isActiveSearch ? '제목 또는 내용 검색 (2글자 이상 입력)' : '검색'), [isActiveSearch]);

  const changeKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const submitKeyword = () => {
    setSearchParams({ search: keyword || '' });
  };

  const clearSearchParam = () => {
    setKeyword('');
    setSearchParams('');
  };

  const handleFocus = useCallback(() => {
    setIsActiveSearch(true);
  }, [setIsActiveSearch]);

  const handleCancel = useCallback(() => {
    setIsActiveSearch(false);
    setSearchParams('');
  }, [setIsActiveSearch]);

  useEffect(() => {
    const target = document.querySelector('#scrollableTarget');
    if (!target) return;

    if (isActiveSearch && target.scrollTop < 148) {
      target.scrollTo({ top: 148, behavior: 'smooth' });
    }
  }, [isActiveSearch]);

  return (
    <SearchBar
      placeholder={seachPlaceholder}
      onClear={clearSearchParam}
      onSubmitForm={submitKeyword}
      onChange={changeKeyword}
      onFocus={handleFocus}
      onCancel={handleCancel}
      value={keyword || ''}
      id={'sudamate-search'}
    />
  );
};

export default SudamateSearch;

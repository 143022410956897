import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { RefObject, useEffect, useState } from 'react';

type Props = {
  targetRef: RefObject<HTMLParagraphElement>;
  lineLimit: number;
};

const MoreButton = ({ targetRef, lineLimit }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const collapseButtonText = isCollapsed ? '더보기' : '접기';
  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (targetRef.current) {
      // 타겟 요소에 text ellipsis 적용
      targetRef.current.style.overflow = isCollapsed ? 'hidden' : 'visible';
      targetRef.current.style.textOverflow = isCollapsed ? 'ellipsis' : 'clip';
      targetRef.current.style.display = isCollapsed ? '-webkit-box' : 'block';
      targetRef.current.style.webkitLineClamp = isCollapsed ? `${lineLimit}` : 'unset';
      targetRef.current.style.webkitBoxOrient = isCollapsed ? 'vertical' : 'unset';
    }
  }, [isCollapsed, lineLimit, targetRef]);

  return (
    <Button
      onClick={e => {
        e.stopPropagation();
        toggleCollapsed();
      }}
      textColor="primary"
      rightIcon={<StyledIcon name="arrowBottomBold" size={16} color="primary" rotate={!isCollapsed} />}>
      {collapseButtonText}
    </Button>
  );
};

export default MoreButton;

const StyledIcon = styled(Icon)<{ rotate: boolean }>`
  transform: rotate(${({ rotate }) => (rotate ? '180deg' : '0')});
`;

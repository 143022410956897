import Switch from 'components/Switch';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import { ETC_FORM_TEXT } from 'pages/EtcSchedule/constants';
import { EtcScheduleCreateFormType } from 'pages/EtcSchedule/types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormField from 'sharedComponents/FormField';

const AllDayField = () => {
  const { control } = useFormContext<EtcScheduleCreateFormType>();
  const isAllDay = useWatch({ control, name: 'is_all_day' });

  return (
    <>
      <FormField iconName="time">
        <Controller
          name="is_all_day"
          control={control}
          render={({ field: { value, ...field } }) => (
            <Switch id="is_all_day" label={ETC_FORM_TEXT.isAllDay.label} defaultChecked={value} {...field} />
          )}
        />
      </FormField>
      {!isAllDay && <BookingRangeTime pageMode="create" />}
    </>
  );
};

export default AllDayField;

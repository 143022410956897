import { useSuspenseQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetHoldingCount = (params: HoldingCountParams) => {
  /** params의 값이 전부 있을 때만 get 요청 */
  const isEnabled = !Object.values(params).some(value => !value);

  const { queryFn } = useQueryFnWithCommonParams<HoldingCountResponse, HoldingCountParams>({
    url: `/api/holding/count`,
    params,
  });

  return useSuspenseQuery({
    queryKey: ['user-tickets', 'holding', { ...params }, isEnabled],
    queryFn: () => (isEnabled ? queryFn() : null),
    select: data => data?.data,
  });
};

export default useGetHoldingCount;

export type HoldingCountParams = {
  user_ticket_id: number;
  start_on: string;
  end_on: string;
};

export type HoldingCountResponse = {
  count: number;
};

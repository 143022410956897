export const postMessageToApp = (type: PostMessageType, data?: unknown) => {
  if (!window.ReactNativeWebView) return;
  window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
};

type PostMessageType =
  | 'EXIT_APP'
  | 'OPEN_URL'
  | 'OPEN_SETTING'
  | 'REQUEST_MEDIA_PERMISSION'
  | 'REQUEST_NOTIFICATION_PERMISSION'
  | 'REQUEST_FCM_TOKEN'
  | 'REQUEST_CURRENT_VERSION'
  | 'COPY_CLIPBOARD'
  | 'CHANGE_STATUSBAR_COLOR';

type ReceiveMessageType =
  | 'FCM_TOKEN'
  | 'UPDATE_MEDIA_PERMISSION'
  | 'HARDWARE_BACK_KEY'
  | 'NOTIFICATION_OPEN_APP'
  | 'CURRENT_VERSION'
  | 'IS_KEYBOARD_SHOW'
  | 'COPY_CLIP_BOARD_RESULT';

export type ReceiveDataType = {
  type: ReceiveMessageType;
  data?: unknown;
};

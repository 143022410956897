import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';

type Props = {
  onClose: () => void;
  onClick: () => void;
};

const OverlapDialog = ({ onClose, onClick }: Props) => {
  return (
    <Dialog onClose={onClose} negativeAction={{ text: '취소' }} positiveAction={{ text: '계속', onClick }}>
      <DialogDescription>
        해당 시간대에 다른 수업이 있습니다.
        <br />
        계속 하시겠습니까?
      </DialogDescription>
    </Dialog>
  );
};

export default OverlapDialog;

import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';

/** 프라이빗 수업 중 특정 날짜 이후 모든 수업 삭제 */
const useDeleteLecturePrivateBulk = (lecture: LectureDetailResponse) => {
  const { id, course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<LecturePrivateDeleteBulkParams>({
    method: `delete`,
    url: `/api/lecture/private/bulk/${course_id}`,
  });

  return useCustomMutation({
    mutationFn,
    mutationKey: ['lecture', 'delete', 'private', 'bulk', course_id],
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', id],
      });
    },
  });
};

export default useDeleteLecturePrivateBulk;

export type LecturePrivateDeleteBulkParams = {
  start: string; // 2024-06-20 12:00:00
};

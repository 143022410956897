const sessionStorage = {
  get(key: string) {
    try {
      const value = window.sessionStorage.getItem(key);
      if (value) return JSON.parse(value);
    } catch (error) {
      // parse 실패시 삭제 및 null 반환
      window.sessionStorage.removeItem(key);
      return null;
    }
  },

  set<T>(key: string, value: T) {
    const parsedValue = JSON.stringify(value);
    window.sessionStorage.setItem(key, parsedValue);
  },

  reset() {
    window.sessionStorage.clear();
  },
};

export default sessionStorage;

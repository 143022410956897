import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import SortButton from 'sharedComponents/SortButton';

import HistoryTicketFilter from './HistoryTicketFilter';
import Sorting from './Sorting';

const Filters = ({ currentMemberId }: CurrentMemberIdProps) => {
  return (
    <Container>
      <ApiBoundary fallback={<SortButton disabled>수강권 전체</SortButton>}>
        <HistoryTicketFilter currentMemberId={currentMemberId} />
      </ApiBoundary>
      <Sorting />
    </Container>
  );
};

export default Filters;

const Container = styled.div`
  ${theme.flex('', '', 'flex-end', 16)};
  padding: 12px 20px;

  .text-button {
    margin-left: 0;

    :first-of-type {
      max-width: 200px;
    }

    :last-of-type {
      flex: 0 0 auto;
    }
  }
`;

import styled from '@emotion/styled';
import Divider from 'components/Divider';
import useGetRole from 'hooks/service/queries/useGetRole';
import useQueryString from 'hooks/useQueryString';
import { FormProvider, useForm } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import SettingsCheckForm from '../SettingsCheckForm';
import { PERMISSION_CHECK_LIST } from '../SettingsCheckForm/constants';
import { PermissionPagesType } from '../types';
import MenuNavigations from './MenuNavigations';
import RoleSelect from './RoleSelect';

const PermissionSettingsProvider = () => {
  const { getSearchParams } = useQueryString();

  const pageType = getSearchParams('type');

  const { data: roleResponse } = useGetRole();
  const roles = roleResponse.map(({ id, display_name }) => {
    return {
      id: id.toString(),
      label: display_name,
      value: id.toString(),
    };
  });

  const methods = useForm<{ role: string }>({
    defaultValues: {
      role: roles.filter(role => role.label === '스튜디오 오너')[0].id,
    },
  });

  return (
    <FormProvider {...methods}>
      {!pageType && (
        <>
          <ApiBoundary>
            <RoleSelect roles={roles} />
          </ApiBoundary>
          <Divider />
          <MenuNavigations roles={roles} />
        </>
      )}

      <Container>
        {pageType && (
          <ApiBoundary>
            <SettingsCheckForm checkList={PERMISSION_CHECK_LIST[pageType as PermissionPagesType]} />
          </ApiBoundary>
        )}
      </Container>
    </FormProvider>
  );
};

export default PermissionSettingsProvider;

const Container = styled.section`
  position: relative;
`;

import styled from '@emotion/styled';
import Thumbnail, { ThumbnailCommonWrap } from 'sharedComponents/Thumbnail';
import { useSwiper } from 'swiper/react';

export type Props = {
  currentIndex: number;
  files: string[];
};

const ThumbnailPagination = ({ files, currentIndex }: Props) => {
  const swiper = useSwiper();
  const handleClick = (index: number) => {
    swiper.slideTo(index);
  };

  return (
    <Container>
      {files.map((item, index) => (
        <Thumbnail key={item} imgSrc={item} isActive={index === currentIndex} onClick={() => handleClick(index)} />
      ))}
    </Container>
  );
};

export default ThumbnailPagination;

const Container = styled(ThumbnailCommonWrap)`
  width: 100%;
  margin-bottom: 40px;
  padding: 0 20px;
`;

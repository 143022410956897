export const CALENDAR_VIEW_TYPES = [
  { value: 'week', title: '주간' },
  { value: 'day', title: '일간' },
  { value: 'month', title: '월간' },
];

export const EVENT_CARD_TYPE = {
  group: 'G',
  private: 'P',
  counsel: 'C',
  etcSchedule: 'E',
  allDayEtcSchedule: 'AE',
};

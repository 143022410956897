import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Icon from 'components/Icon';
import CheckRadio from 'components/Radio/CheckRadio';
import Typography from 'components/Typography';
import useRepresentativeColor from 'hooks/useRepresentativeColor';
import { useEffect, useRef } from 'react';
import { Color } from 'utils/getColor';

import { REPRESENTATIVE_COLOR_LIST } from '../constants';
import UsingColorDialog from './UsingColorDialog';

const ListTypeContent = () => {
  const checkedRef = useRef<HTMLLIElement>(null);

  const { representativeColor, changeValue, dialogAction, count, colorKeys } = useRepresentativeColor();

  useEffect(() => {
    if (checkedRef.current) {
      checkedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  return (
    <Container>
      <UsingColorDialog dialogAction={dialogAction} count={count} />

      {REPRESENTATIVE_COLOR_LIST.map(({ id, value, label }) => {
        const isChecked = value === representativeColor;
        const isIncludeColor = colorKeys.includes(value);

        const className = isChecked ? 'checked' : '';
        const usingColorClassName = isIncludeColor ? ' using-color' : '';

        return (
          <ColorItem
            ref={isChecked ? checkedRef : null}
            key={id}
            className={className + usingColorClassName}
            isPrimary={isChecked && !isIncludeColor}>
            <div className="icon-container">
              {isIncludeColor && <span className="overlay-white" />}
              <Icon name="colorChips" fillColor={color[value as keyof typeof color]} />
            </div>
            <CheckRadio
              id={id}
              name="ticket"
              label={
                <Typography className="label-text" size={17} textColor="gray2" weight={isChecked && !isIncludeColor ? 600 : 400}>
                  {label}
                </Typography>
              }
              value={value}
              checked={isChecked}
              onChange={(e: { currentTarget: { value: string } }) => {
                changeValue(e.currentTarget.value as Color);
              }}
            />
          </ColorItem>
        );
      })}
    </Container>
  );
};

export default ListTypeContent;

const Container = styled.ul`
  ${theme.flex('column', 'flex-start')};
  gap: 8px;
  padding: 12px 0;

  .icon-container {
    ${theme.flex()};
    position: relative;

    .overlay-white {
      position: absolute;
      background-color: ${theme.color.white};
      width: 100%;
      height: 100%;
      opacity: 0.68;
    }
  }

  .using-color,
  .checked {
    p {
      color: ${theme.color.gray4};
    }
  }
`;

const ColorItem = styled.li<{ isPrimary: boolean }>`
  ${theme.flex()};
  gap: 12px;
  padding: 8px 20px;
  width: 100%;
  max-height: 40px;

  .label-text {
    ${({ isPrimary }) => isPrimary && `color: ${theme.color.primary} !important`};
  }
`;

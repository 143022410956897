import { useIsFetching } from '@tanstack/react-query';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import FormMain from './FormMain';

/** 회원 등록 폼 */
const MemberCreate = () => {
  const isFetching = useIsFetching() > 0;

  return (
    <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
      <FormMain />
    </ApiBoundary>
  );
};

export default MemberCreate;

import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityMemberCounsel = (params: MemberCounselParams) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MemberCounselResponse, MemberCounselParams>({
    url: '/api/member/counsel',
    params,
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`use/infinity/member/counsel`, { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => {
      return {
        counsels: data.pages.flatMap(page => page.data.data),
        total: data.pages[0].data.meta.total,
      };
    },
  });
};

export default useInfinityMemberCounsel;

export type MemberCounselSelectedFilterType = {
  target_staff_id?: string | number | null;
  start_on?: string;
  end_on?: string;
  channel?: 'visit' | 'phone' | 'chat' | 'etc' | null;
  funnel?: 'etc' | 'search' | 'introduction' | 'sign' | 'flyer' | 'sns' | null;
  register_type: 'both' | 'registered' | 'not_registered' | 'none';
  search?: string | null;
  /** 휴대폰 번호 존재여부 메세지 전송시 사용 */
  has_mobile?: 0 | 1;
};

export type MemberCounselParams = MemberCounselSelectedFilterType & {
  paginate_type: string;
};

export type MemberCounselResponse = {
  id: number;
  studio_id: number;
  staff_id: number;
  user_id: number | null;
  name: string;
  phone: string;
  gender: string | null;
  channel: string;
  funnel: string;
  contents: string | null;
  created_at: string;
  start_on: string;
  end_on: string;
  updated_at: string;
  deleted_at: null;
  staff: {
    id: number;
    studio_id: number;
    user_grade_id: number | null;
    name: string;
  };
  member: boolean;
};

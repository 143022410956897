import useParamsId from 'hooks/useParamsId';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import FormMain from './FormMain';

/** 프라이빗 수업 1개 수정 */
const BookingPrivateUpdate = () => {
  const currentLectureId = useParamsId();

  return (
    <ApiBoundary>
      <FormMain currentLectureId={currentLectureId} />
    </ApiBoundary>
  );
};

export default BookingPrivateUpdate;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import { IHeaderProps } from 'components/Header';
import Icon from 'components/Icon';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import MainLayout from 'sharedComponents/MainLayout';
import { StickyWrapper } from 'sharedComponents/MainLayout/StyledComponents';

type Props = {
  tab: ReactNode;
  children: ReactNode;
};

const ReportLayout = ({ tab, children }: Props) => {
  const navigate = useNavigate();

  const renderHeader = () => {
    return {
      title: '매출',
      leftButton: (
        <IconButton onClick={() => navigate(-1)}>
          <Icon name="headerBack" />
        </IconButton>
      ),
      noUnderline: true,
    } as IHeaderProps;
  };

  return (
    <MainLayout scrollable header={renderHeader()}>
      <StickyWrapper>
        <TabSection>{tab}</TabSection>
      </StickyWrapper>
      <ContentSection>{children}</ContentSection>
    </MainLayout>
  );
};

export default ReportLayout;

const TabSection = styled.section``;

const ContentSection = styled.div`
  height: 100%;
  background-color: ${theme.color.gray6};
`;

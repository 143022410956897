import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Skeleton from 'components/Skeleton';

const SkeletonList = () => {
  const DUMMY_LENGTH = 10;
  const dummyArray = Array.from({ length: DUMMY_LENGTH }, (_, i) => i);
  return (
    <Container>
      {dummyArray.map((_, index) => (
        <ItemContainer key={index}>
          <Skeleton height={20} type="round" />
          <Skeleton height={16} width={214} type="round" />
          <Skeleton height={16} width={44} type="round" />
          <Divider thin />
        </ItemContainer>
      ))}
    </Container>
  );
};

export default SkeletonList;

const Container = styled.div`
  padding: 0 20px;
`;

const ItemContainer = styled.div`
  padding: 16px 0 0;

  div {
    margin-bottom: 6px;
    border-radius: 3px;
    :last-of-type {
      margin: 14px 0 16px;
    }
  }
`;

import { useQuery } from '@tanstack/react-query';
import { QnaDetailType } from 'pages/MoreDetails/Qna/Detail/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetBoardQnaDetail = (id: number, enabled: boolean) => {
  const { queryFn } = useQueryFnWithCommonParams<BoardQnaDetailResponse, BoardQnaDetailParams>({
    url: `/api/board/qna/${id}`,
  });

  return useQuery({
    queryKey: [`board/qna`, enabled, id],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetBoardQnaDetail;

type BoardQnaDetailParams = {
  id: number;
};

type BoardQnaDetailResponse = QnaDetailType;

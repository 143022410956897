import { useMutation } from '@tanstack/react-query';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostStaffSudamateCommentStore = () => {
  const { mutationFn } = useMutationFnWithCommonParams<StaffSudamateCommentStoreParams, StaffSudamateCommentStoreResponse>({
    method: `post`,
    url: `/api/staff-sudamate/comment/store`,
    params: {
      staff_id: undefined,
    },
  });

  return useMutation({
    mutationFn,
  });
};

export default usePostStaffSudamateCommentStore;

type StaffAccount = {
  id: number;
  name: string;
};

export type StaffSudamateCommentStoreParams = {
  staff_sudamate_id: number;
  contents: string;
  depth: 0 | 1 | 2;
  parent_staff_account_id: number;
  is_secret?: number | null;
  parent_id?: number | null;
  group_num?: number | null;
};

export type StaffSudamateCommentStoreResponse = {
  id: number;
  staff_sudamate_id: number;
  staff_account: StaffAccount;
  contents: string;
  depth: number;
  /**
   * 상위댓글의 유저어카운트아이디, 최상위는 수다메이트 유저어카운트 아이디
   */
  parent_staff_account_id: number;
  /** 부모댓글 */
  parent_id: number | null;
  /** 댓글순서 */
  order: number;
  /** 댓글그룹 */
  group_num: number;
  report: number;
  is_hide: boolean;
  is_secret: boolean;
  updated_at: string;
  created_at: string;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Loading from 'components/Loading';
import { Z_INDEX } from 'constants/zIndex';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof Loading>;

/** 전체화면에 한 가운데 있는 로딩 */
const FullScreenLoading = ({ type, loop }: Props) => {
  return (
    <Container className="full-screen-loading">
      <Loading type={type} loop={loop} />
    </Container>
  );
};

export default FullScreenLoading;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: ${Z_INDEX.popup + 1};
  ${theme.flex('column', 'center', 'center')};
`;

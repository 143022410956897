export const USER_TICKET_TAB_DATA = [
  { value: 'in_active', label: '전체' },
  { value: 'expired', label: '이용만료' },
  { value: 'transfer', label: '양도' },
  { value: 'not_usable', label: '사용불가' },
  { value: 'refund', label: '환불' },
];

export const ITEM_TAB_DATA = [
  { value: 'in_active', label: '전체' },
  { value: 'expired', label: '이용만료' },
  { value: 'transfer', label: '양도' },
  { value: 'not_usable', label: '사용불가' },
  { value: 'refund', label: '환불' },
  { value: 'sell', label: '판매' },
];

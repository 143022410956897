import dayjs from 'dayjs';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import filters from 'utils/filters';

import { BOOKING_COMMON_FORM_TEXT } from '../constants';
import { BookingCommonFormType } from '../types';

type Response = Omit<BookingCommonFormType, 'repeat' | 'repeatCustomWeekday' | 'tempRepeatCustomWeekday'>;

const convertBookingFormCommonState = (lectureData: LectureDetailResponse): Response => {
  const { title, start_on, end_on, staff, policy, room } = lectureData;

  const selectedStaff = {
    id: staff.id,
    name: staff.name,
    avatar: staff.image,
    color: staff.representative_color,
  };

  return {
    title,
    startDate: filters.dateDash(start_on),
    endDate: filters.dateDash(end_on),
    startTime: dayjs(start_on).toDate(),
    endTime: dayjs(end_on).toDate(),
    selectedStaffId: String(staff.id),
    selectedStaff,
    roomId: !policy.is_use_rooms || !room ? BOOKING_COMMON_FORM_TEXT.room.notSelected.value : `${room.id}`,
    selectedRoom: undefined, // 폼에서 직접 선택한 룸정보
  };
};

export default convertBookingFormCommonState;

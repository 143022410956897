import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon, { IconType } from 'components/Icon';
import { Z_INDEX } from 'constants/zIndex';
import { forwardRef } from 'react';
import { ColorType } from 'types/colorTypes';

export interface IInputSelectBaseProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'id'> {
  type: 'radio' | 'checkbox';
  /** input의 id */
  id: string;
  /** CheckRadio 여부 */
  isCheckRadio?: boolean;
  /** 체크 색상 */
  checkColor?: ColorType;
  /** 체크 아이콘 크기  */
  checkSize?: number;
  /** 체크 아이콘 두께  */
  checkBold?: boolean;
  /** 커스터 icon name */
  iconName?: IconType;
}

const InputSelectBase = forwardRef<HTMLInputElement, IInputSelectBaseProps>(
  ({ type, id, isCheckRadio, checkColor, checkSize, checkBold, iconName, ...props }, ref) => {
    return (
      <Container className="input-select-base-input-wrapper" isCheckRadio={isCheckRadio}>
        <input type={type} id={id} ref={ref} {...props} />
        <span className="input-span-style" />
        {(type === 'checkbox' || isCheckRadio) && (
          <span className="input-span-check-style">
            <Icon
              name={iconName || (checkBold ? 'selectCheckBold' : 'selectCheck')}
              size={checkSize}
              fillColor={theme.color[checkColor || 'primary']}
            />
          </span>
        )}
      </Container>
    );
  },
);

export const Container = styled.span<Pick<IInputSelectBaseProps, 'isCheckRadio'>>`
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
  }

  .input-span-style {
    display: ${({ isCheckRadio }) => !!isCheckRadio && 'none'};
    z-index: ${Z_INDEX.input};
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.color.gray5};
  }

  .input-span-check-style {
    ${theme.flex('', 'center', '')};
    z-index: ${Z_INDEX.input + 1};
    // icon이라 z-index를 하나 높게 설정
  }
`;

export const StyledLabel = styled.label`
  display: inline-block;
  position: relative;
`;

InputSelectBase.displayName = 'InputSelectBase';
export default InputSelectBase;

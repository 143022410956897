import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { ReactNode } from 'react';
import { Color } from 'utils/getColor';

type Props = {
  text: string | ReactNode;
  top?: number;
  textColor?: Color;
};

const NoContentText = ({ text, top = 120, textColor = 'gray2' }: Props) => {
  return (
    <Container top={top}>
      {typeof text === 'string' ? (
        <Typography size={15} textColor={textColor}>
          {text}
        </Typography>
      ) : (
        text
      )}
    </Container>
  );
};

export default NoContentText;

const Container = styled.section<{ top?: number }>`
  ${theme.flex()};
  margin: ${({ top }) => top}px 0;
  padding: 0 20px;
  width: 100%;

  b {
    margin-right: 2px;
    color: ${theme.color.primary};
  }
`;

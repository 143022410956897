import Divider from 'components/Divider';
import { Fragment } from 'react/jsx-runtime';

import { DisplayNameType, RoleRemoveType, RoleUpdateType } from '../types';
import RoleItem from './RoleItem';

type Props = {
  roles: DisplayNameType[];
  remove: RoleRemoveType;
  update: RoleUpdateType;
};

const RoleList = ({ roles, ...props }: Props) => {
  return (
    <ul className="role-list">
      {roles.map((role, index, list) => {
        const lastIndex = index === list.length - 1;
        return (
          <Fragment key={index}>
            <RoleItem roleIndex={index} role={role} {...props} />
            {!lastIndex && <Divider thin />}
          </Fragment>
        );
      })}
    </ul>
  );
};

export default RoleList;

import { every, some } from 'lodash';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import { PolicyType } from 'types/policyTypes';

import useGetStudioInfo from './service/queries/useGetStudioInfo';

/**
 * 해당 시설의 정책이 허용되어 있는지 확인
 * true, 1이면 허용
 */
const usePolicy = () => {
  const studioId = useRecoilValue(studioIdAtom);
  const { data: studio } = useGetStudioInfo(studioId);

  const isPolicyAllowed = useCallback(
    (target: keyof PolicyType) => {
      if (!studio?.policy) return false;
      if (studio.policy[target] === null) return false;
      return !!studio.policy[target];
    },
    [studio],
  );

  const everyPoliciesAllowed = useCallback(
    (targetKeys: (keyof PolicyType)[]) => {
      return every(targetKeys, key => isPolicyAllowed(key));
    },
    [isPolicyAllowed],
  );

  const somePoliciesAllowed = useCallback(
    (targetKeys: (keyof PolicyType)[]) => {
      return some(targetKeys, key => isPolicyAllowed(key));
    },
    [isPolicyAllowed],
  );

  return { isPolicyAllowed, everyPolicies: everyPoliciesAllowed, somePolicies: somePoliciesAllowed };
};

export default usePolicy;

import { MessagePushResponse } from 'hooks/service/queries/useInfinityMessagePush';
import MessageListItem from 'pages/MoreDetails/Message/components/MessageListItem';

import { MESSAGE_PUSH_STATUS_ADAPTER } from '../constants';

type Props = {
  push: MessagePushResponse;
};

const AppPushListItem = ({ push }: Props) => {
  return (
    <MessageListItem
      to={`push/${push.id}`}
      send_on={push.send_on}
      send_person={push.send_person}
      all_count={push.all_count}
      title={push.title}
      status={MESSAGE_PUSH_STATUS_ADAPTER[push.status]}
    />
  );
};

export default AppPushListItem;

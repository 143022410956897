import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import CheckBox from 'components/CheckBox';
import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { TICKET_HOLDING_TEXT } from 'pages/TicketDetail/constants';
import { TicketHoldingFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const CheckAutoCalculation = ({ userTicket }: UserTicketProps) => {
  const { control } = useFormContext<TicketHoldingFormType>();
  const [isOpen, setIsOpen] = useState(false);
  const disabled = !(userTicket.user_ticket_status === 'using' || userTicket.user_ticket_status === 'to_use');

  return (
    <Container>
      <Controller
        name="params.auto_calculation"
        control={control}
        render={({ field: { value, ...rest } }) => (
          <CheckBox
            id="params.auto_calculation"
            variant="simple"
            gap={4}
            labelFontsize={13}
            label={TICKET_HOLDING_TEXT.form.checkbox}
            checked={value}
            disabled={disabled}
            {...rest}
          />
        )}
      />
      <Tooltip
        isOpen={isOpen}
        message={TICKET_HOLDING_TEXT.form.tooltip}
        position="left"
        showCloseButton
        maxWidth={222}
        onClose={() => setIsOpen(false)}>
        <IconButton onClick={() => setIsOpen(true)}>
          <Icon name="information" size={16} color="gray3" />
        </IconButton>
      </Tooltip>
    </Container>
  );
};

export default CheckAutoCalculation;

const Container = styled.div`
  ${theme.flex('', 'center', '', 4)};
`;

import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import usePatchLectureGroup, { LectureGroupPatchParams } from 'hooks/service/mutations/usePatchLectureGroup';
import { LectureValidationError } from 'hooks/service/mutations/usePatchLecturePrivate';
import { BookingCountResponse } from 'hooks/service/queries/useGetBookingCount';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useToast from 'hooks/useToast';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import OverlapDialog from 'pages/Booking/components/OverlapDialog';
import PastLectureUpdateDialog from 'pages/Booking/components/PastLectureUpdateDialog';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import LimitValidationDialog from 'pages/Booking/SelectMember/LimitValidationDialog';
import { formatBookingGroupUpdateParams } from 'pages/Booking/utils';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { BookingGroupUpdateFormType } from '../../types';
import CommonGroupForm from '../components/CommonGroupForm';

type Props = {
  currentLecture: LectureDetailResponse;
};

const GroupUpdateForm = ({ currentLecture }: Props) => {
  const { getValues } = useFormContext<BookingGroupUpdateFormType>();
  const [bookingCountValidation, setBookingCountValidation] = useState<BookingCountResponse | null>(null);
  const [isOverlap, setIsOverlap] = useState(false);
  const [isPastLecture, setIsPastLecture] = useState(false);

  const navigate = useNavigate();
  const { setToast } = useToast();
  const { mutate: groupUpdateMutate } = usePatchLectureGroup(currentLecture.id);

  const updateGroup = useCallback(
    (params: LectureGroupPatchParams) => {
      groupUpdateMutate(params, {
        onSuccess: () => {
          navigate(-1);
          setToast({ type: 'success', message: BOOKING_COMMON_FORM_TEXT.successMessage.update });
        },
        onError: (error: AxiosError<LectureValidationError>) => {
          if (!error.response?.data) return;
          const errorData = error.response.data;
          if (errorData.limit) {
            setBookingCountValidation(errorData.limit);
            return false;
          }
          if (errorData.overlap) {
            setIsOverlap(true);
            return false;
          }
        },
      });
    },
    [groupUpdateMutate, navigate, setToast],
  );

  // 1. 주/월간 초과해도 수정
  const clickPassLimit = useCallback(() => {
    const params = {
      ...formatBookingGroupUpdateParams(getValues()),
      is_pass_limit: true,
    };
    updateGroup(params);
    setBookingCountValidation(null);
  }, [getValues, updateGroup]);

  // 2. 수업 수정 시에는 중복 수업도 등록 허용 (정책)
  const clickOverlapBooking = useCallback(() => {
    const params = {
      ...formatBookingGroupUpdateParams(getValues()),
      is_pass_limit: true,
      is_force: true,
    };
    updateGroup(params);
    setIsOverlap(false);
  }, [getValues, updateGroup]);

  const submit = useCallback(
    (values: BookingGroupUpdateFormType) => {
      if (dayjs(currentLecture.start_on).isBefore()) {
        setIsPastLecture(true);
        return;
      }
      const params = formatBookingGroupUpdateParams(values);
      updateGroup(params);
    },
    [currentLecture.start_on, updateGroup],
  );

  return (
    <>
      <CommonGroupForm pageMode="update" currentLecture={currentLecture} onSubmit={submit}>
        <BookingRangeDate isRange={false} />
        <BookingRangeTime pageMode="update" />
      </CommonGroupForm>

      {bookingCountValidation && (
        <LimitValidationDialog
          bookingCountValidation={bookingCountValidation}
          onClickBooking={clickPassLimit}
          onClose={() => {
            setBookingCountValidation(null);
          }}
        />
      )}
      {isOverlap && <OverlapDialog onClick={clickOverlapBooking} onClose={() => setIsOverlap(false)} />}
      {isPastLecture && <PastLectureUpdateDialog onClose={() => setIsPastLecture(false)} />}
    </>
  );
};

export default GroupUpdateForm;

import Form from 'components/Form';
import SelectBoxSquare from 'components/SelectBox/SelectBoxSquare';
import { StudioResponse } from 'hooks/service/queries/useGetStudio';
import { useNavigate } from 'react-router-dom';

type Props = {
  studios: StudioResponse['studios'];
  selectStudio: string;
  onChange: (e: { target: { value: string } }) => void;
  updateCommonIds: (studioId: number, staffId: number) => void;
};

const Studios = ({ studios, selectStudio, onChange, updateCommonIds }: Props) => {
  const navigate = useNavigate();

  const confirmStudio = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!selectStudio) return;

    const currentStudio = studios.find(studio => studio.name === selectStudio);
    if (currentStudio) {
      const { id, staff_id } = currentStudio;
      updateCommonIds(id, staff_id);
      navigate('/schedule');
    }
  };

  return (
    <Form id="select-studio-form" onSubmit={confirmStudio}>
      {studios.map(({ id, subdomain, name }) => {
        return (
          <SelectBoxSquare
            key={id}
            ref={el => el}
            type="radio"
            id={subdomain}
            name="studio"
            label={name}
            value={name}
            checked={name === selectStudio}
            onChange={onChange}
          />
        );
      })}
    </Form>
  );
};

export default Studios;

import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import { theme } from 'assets/styles';
import { StyledInfoLi } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { TicketHoldingFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { InfoTypography } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';

type Props = {
  isOpen: boolean;
  count?: number;
  onSubmit: (values: TicketHoldingFormType) => void;
};

/**
 * 정지 요청 보낸 후 정지기간 내에 예약/예약대기한 수업이 포함된 경우에 노출
 */
const CheckBookingDrawer = ({ isOpen, count, onSubmit }: Props) => {
  const { setValue, handleSubmit } = useFormContext<TicketHoldingFormType>();
  const isMutating = useIsMutating() > 0;

  const closeDrawer = () => {
    setValue('hasBooking', false);
  };

  const submit = () => {
    handleSubmit(onSubmit)();
    closeDrawer();
  };

  return (
    <DoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={closeDrawer}
      leftButton={{
        text: '취소',
        onClick: closeDrawer,
        disabled: isMutating,
      }}
      rightButton={{
        text: '정지',
        onClick: submit,
        disabled: isMutating,
      }}
      header={
        <TwoLinedTitle
          title={
            <>
              <Typography size={19} weight={700}>
                설정한 정지기간 내에
                <br />
                <Typography span size={19} weight={700} textColor="primary">
                  {count}
                </Typography>
                건의 예약/예약대기가 존재합니다.
              </Typography>
            </>
          }
        />
      }>
      <GrayWrapper bgColor="gray7" showBoxShadow={false}>
        <ul>
          <StyledInfoLi>
            <InfoTypography>
              정지 설정시 기간 내의 모든 예약은
              <br />
              <InfoTypography weight={600}>자동으로 취소</InfoTypography>됩니다.
            </InfoTypography>
          </StyledInfoLi>
          <StyledInfoLi>
            <InfoTypography>
              취소된 예약은 정지 설정을 해제해도
              <br />
              <InfoTypography weight={600}>복구되지 않습니다.</InfoTypography>
            </InfoTypography>
          </StyledInfoLi>
        </ul>
      </GrayWrapper>
      <Typography size={15} weight={600}>
        수강권을 정지하시겠습니까?
      </Typography>
    </DoubleButtonDrawer>
  );
};

export default CheckBookingDrawer;

const GrayWrapper = styled(CommonStyleCard)`
  padding: 16px;
  margin-bottom: 20px;

  ul {
    ${theme.flex('column', '', '', 12)};
  }
`;

import getPaymentFormParams from 'utils/getPaymentFormParams';

import { ProductUpdateFormType } from '../types';

type Props = {
  userTickets: ProductUpdateFormType[];
  currentMemberId: number;
  currentStaffId: number;
  currentStudioId: number;
};

const getUpdateTicketParams = ({ userTickets, currentMemberId, currentStaffId, currentStudioId }: Props) => {
  return userTickets.map(values => {
    const {
      userTicket,
      detail: { staffs, ...rest },
      period,
      count,
      paymentId,
      paymentStatus,
      ...payment
    } = values;

    const paymentParams = getPaymentFormParams({ ...payment });

    return {
      ...rest,
      ...period,
      ...count,
      payment: { ...paymentParams, id: paymentId, status: paymentStatus },
      staffs: staffs.length ? staffs.map(({ id }) => id) : undefined,
      user_id: currentMemberId,
      staff_id: currentStaffId,
      studio_id: currentStudioId,
      user_ticket_id: userTicket.id,
    };
  });
};

export default getUpdateTicketParams;

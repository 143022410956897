import { productUserTicket } from '_mocks/fixtures/product';
import { HttpResponse } from 'msw';

import { api } from '../utils';

export const handlers = [
  api.get('/product/user-ticket', () => {
    return HttpResponse.json(productUserTicket);
  }),
];

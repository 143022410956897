import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

type Props = {
  mobile: string;
};

const usePostSendMobileConfirmCode = ({ mobile }: Props) => {
  const { mutationFn } = useMutationFnWithCommonParams<undefined, SendMobileConfirmCodeResponse>({
    method: 'post',
    url: `/api/auth/send-mobile-confirm-code/${mobile}`,
    data: {
      studio_id: undefined,
      staff_id: undefined,
    },
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostSendMobileConfirmCode;

type SendMobileConfirmCodeResponse = {
  validation_code: string;
};

import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import StaffDetail from './components/StaffDetail';

const MoreDetailsStaffDetail = () => {
  return (
    <ApiBoundary>
      <StaffDetail />
    </ApiBoundary>
  );
};

export default MoreDetailsStaffDetail;

import { atom } from 'recoil';
import localStorageEffect from 'utils/localStorageEffect';

// 일반 팝업
export const popupAtom = atom<React.ReactNode | null>({
  key: 'popupAtom',
  default: null,
});

// 스튜디오메이트 공지사항 팝업 닫기여부
export const isClosedNoticePopupAtom = atom<boolean>({
  key: 'isClosedNoticePopupAtom',
  default: false,
});

// 스튜디오메이트 공지사항 팝업 다시보지않기 체크한 아이디 목록
export const noticeCheckedListAtom = atom<number[]>({
  key: 'noticeCheckedListAtom',
  default: [],
  effects: [localStorageEffect('noticePopupCheckedList')],
});

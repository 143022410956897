import DateCalendarPicker from 'designedComponents/Pickers/DateCalendarPicker';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

import { CommonProfileFormType } from '../types';

const RegisteredDate = () => {
  const { control, setValue } = useFormContext<CommonProfileFormType>();
  const registeredAt = useWatch({ control, name: 'profile.registered_at' });

  const changeDate = (e: { value: Date }) => {
    const date = filters.dateDash(e.value);
    setValue('profile.registered_at', date, { shouldDirty: true });
  };

  return (
    <FormFieldAccordion
      iconName="roundedCreateText"
      header={filters.date(registeredAt)}
      hasValue={!!registeredAt}
      closeTrigger={registeredAt}>
      <DateCalendarPicker defaultValue={registeredAt} value={registeredAt} onChange={changeDate} />
    </FormFieldAccordion>
  );
};

export default RegisteredDate;

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Typography from 'components/Typography';
import useGetAuthAccountMe from 'hooks/service/queries/useGetAuthAccountMe';
import { useNavigate } from 'react-router-dom';
import filters from 'utils/filters';

import { ACCOUNT_MENU, ACCOUNT_TEXT } from '../constants';

const MyAccount = () => {
  const { data: account } = useGetAuthAccountMe();

  const navigate = useNavigate();
  const goToAccountMenu = (menu: keyof typeof ACCOUNT_MENU, currentValue?: string) => {
    navigate(`/more-details/account/${ACCOUNT_MENU[menu]}${currentValue ? `?currentValue=${currentValue}` : ''}`, {});
  };

  return (
    <>
      <StyledLinkButton showArrow size="large60" onClick={() => goToAccountMenu('changeName', account.name)}>
        <Typography size={15} weight={600}>
          {ACCOUNT_TEXT.name}
        </Typography>
        <Typography size={15} weight={500} textColor="gray2" ellipsis={1}>
          {account.name}
        </Typography>
      </StyledLinkButton>
      <StyledLinkButton showArrow size="large60" onClick={() => goToAccountMenu('changePhone')}>
        <Typography size={15} weight={600}>
          {ACCOUNT_TEXT.phone}
        </Typography>
        <Typography size={15} weight={500} textColor="gray2">
          {filters.contact(account.mobile)}
        </Typography>
      </StyledLinkButton>
      <StyledLinkButton showArrow size="large60" onClick={() => goToAccountMenu('changePassword')}>
        <Typography size={15} weight={600}>
          {ACCOUNT_TEXT.passwordReset}
        </Typography>
      </StyledLinkButton>
    </>
  );
};

export default MyAccount;

const StyledLinkButton = styled(LinkButton)`
  .link-button-contents-wrapper {
    ${theme.flex('', 'center', 'space-between', 16)}
    p:first-of-type {
      flex-shrink: 0;
    }
  }
`;

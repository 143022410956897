import Icon from 'components/Icon';
import UnderlinedTextarea from 'components/Textarea/UnderlinedTextarea';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import useToast from 'hooks/useToast';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { ComponentProps, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  placeholder?: string;
  description?: ComponentProps<typeof UnderlinedTextarea>['description'];
};

const ClassTitle = ({ placeholder = BOOKING_COMMON_FORM_TEXT.title.placeholder, description }: Props) => {
  const { control, formState } = useFormContext<BookingCommonFormType>();
  const { setToast } = useToast();

  useEffect(() => {
    if (!formState.errors.title) return;
    setToast({ type: 'error', message: formState.errors.title.message ?? null, bottom: 92 });
  }, [formState.errors.title, setToast]);

  return (
    <UnderlinedTextareaController
      controllerProps={{ control, name: 'title' }}
      placeholder={placeholder}
      left={<Icon name="title" color="gray2" />}
      maxLength={200}
      showBottom={false}
      description={description}
    />
  );
};

export default ClassTitle;

import { MbscCalendarEvent } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import { EVENT_CARD_TYPE } from 'pages/Schedule/ScheduleMain/constants';

import EventCounselCard from './EventCounselCard';
import EventEtcScheduleCard from './EventEtcScheduleCard';
import EventLectureCard from './EventLectureCard';

type Props = {
  original: MbscCalendarEvent;
};

const MonthEventCard = ({ original }: Props) => {
  const { id, title, color = theme.color.red0, isPrivate, isBookingClosed, maxTrainee, currentTraineeCount } = original;

  if (!id || !title) return null;

  const lectureCardProps = {
    lectureType: original.type,
    title: title,
    cardColor: color,
    isBookingClosed,
    maxTrainee,
    currentTraineeCount,
  };

  const counselCardProps = {
    title,
    cardColor: color,
  };

  const etcScheduleCardProps = {
    title,
    isPrivate,
    cardColor: color,
  };

  /** G(그룹), P(프라이빗), C(상담), E(기타 일정) */
  switch (original.type) {
    case EVENT_CARD_TYPE.group:
    case EVENT_CARD_TYPE.private:
      return <EventLectureCard {...lectureCardProps} />;
    case EVENT_CARD_TYPE.counsel:
      return <EventCounselCard {...counselCardProps} />;
    case EVENT_CARD_TYPE.etcSchedule:
    case EVENT_CARD_TYPE.allDayEtcSchedule:
      return <EventEtcScheduleCard {...etcScheduleCardProps} />;
  }
};

export default MonthEventCard;

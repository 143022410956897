import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import useGetMinHeight from 'hooks/useGetMinHeight';
import { ComponentProps } from 'react';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';

type Props = ComponentProps<typeof Form>;

const StyledPrivateForm = ({ children, ...props }: Props) => {
  const fullHeight = useGetMinHeight();
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  return (
    <FormContainer {...props} isKeyboardShow={isKeyboardShow} minHeight={fullHeight}>
      {children}
    </FormContainer>
  );
};

export default StyledPrivateForm;

const FormContainer = styled(Form)<{ isKeyboardShow: boolean; minHeight: number }>`
  ${theme.flex('column', '', '')};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  padding-bottom: ${({ isKeyboardShow }) => (isKeyboardShow ? '0px' : '116px')};

  footer {
    margin-top: ${({ isKeyboardShow }) => (isKeyboardShow ? 'auto' : 0)};
  }
`;

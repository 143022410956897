import styled from '@emotion/styled';
import { AttachmentType } from 'api/modules/attachment';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import getImageUrl from 'utils/getImageUrl';

interface IAvatarLabelProps {
  imgUrl: AttachmentType['path'] | null;
  label: string;
  id: number;
  selectStaffId: string;
}

const AvatarLabel = ({ imgUrl, label, id, selectStaffId }: IAvatarLabelProps) => {
  return (
    <Container>
      <Avatar imgUrl={getImageUrl(imgUrl)} />
      <Typography
        span
        size={16}
        lineHeight={24}
        weight={id === Number(selectStaffId) ? 600 : 400}
        textColor={id === Number(selectStaffId) ? 'primary' : 'gray2'}>
        {label}
      </Typography>
    </Container>
  );
};

const Container = styled.div`
  ${theme.flex('row', 'center', 'start', 12)}
`;

export default AvatarLabel;

import dayjs from 'dayjs';
import _ from 'lodash';
import { ScheduleFormType as ScheduleFormTypeTest } from 'pages/MoreDetails/Staff/Detail/EventScheduleSingle/types';
import { ScheduleFormType } from 'sharedComponents/ProfileField/types';

const getMinute = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return 0;

  const [startHour, startMinute] = startTime.split(':');
  const [endHour, endMinute] = endTime.split(':');
  const start = dayjs().set('hour', parseInt(startHour)).set('minute', parseInt(startMinute));
  const end = dayjs().set('hour', parseInt(endHour)).set('minute', parseInt(endMinute));
  return end.diff(start, 'minutes') / 60;
};

const workTimeDiff = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return '9.0';

  const minute = staffUtils.getMinute(startTime, endTime);
  return minute.toFixed(1).toString();
};

const restTimeDiff = (restTimes: ScheduleFormType[], workTime: ScheduleFormType) => {
  if (!restTimes.length || !!workTime?.isClosed) return '0';

  // 요일 모든 휴식시간의 diff 합계
  const diffArray: number[] = [];
  restTimes.forEach(restTime => {
    const minute = staffUtils.getMinute(restTime.start_time, restTime.end_time);
    diffArray.push(minute);
  });
  const sumDiff = _.sum(diffArray);
  return sumDiff.toFixed(1).toString();
};

const restTimeDiffTest = (restTimes: ScheduleFormTypeTest[], workTime: ScheduleFormTypeTest) => {
  if (!restTimes.length || !!workTime?.isClosed) return '0';

  // 요일 모든 휴식시간의 diff 합계
  const diffArray: number[] = [];
  restTimes.forEach(restTime => {
    const minute = staffUtils.getMinute(restTime.start_time, restTime.end_time);
    diffArray.push(minute);
  });
  const sumDiff = _.sum(diffArray);
  return sumDiff.toFixed(1).toString();
};

const staffUtils = {
  getMinute,
  workTimeDiff,
  restTimeDiff,
  restTimeDiffTest,
};

export default staffUtils;

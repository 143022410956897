import styled from '@emotion/styled';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import { CardWrapper } from 'sharedComponents/InfoTypography';
import MainLayout from 'sharedComponents/MainLayout';

import PastHistoryList from './PastHistoryList';

type Props = {
  onClose: () => void;
  selectedBookingId: number;
};

const PastHistory = ({ onClose, selectedBookingId }: Props) => {
  return (
    <MainLayout
      header={{
        title: '지난 내역 보기',
        leftButton: (
          <IconButton onClick={onClose}>
            <Icon name="headerClose" />
          </IconButton>
        ),
      }}>
      <Container>
        <ApiBoundary>
          <PastHistoryList selectedBookingId={selectedBookingId} />
        </ApiBoundary>
      </Container>
    </MainLayout>
  );
};

export default PastHistory;

const Container = styled(CardWrapper)`
  padding: 16px 20px;
  height: 100%;
`;

import { BookingAvailableFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';

import CheckInFieldButton from './CheckInFieldButton';
import { UpdateDateTypography } from './StyledComponents';

/** 수업 1개 수정 체크인 가능 시간 필드 */
const CheckInAvailableField = () => {
  const { getValues } = useFormContext<BookingAvailableFormType>();
  const { enter_start_at, enter_end_at } = getValues();

  return (
    <CheckInFieldButton>
      <UpdateDateTypography startAt={enter_start_at} endAt={enter_end_at} />
    </CheckInFieldButton>
  );
};

export default CheckInAvailableField;

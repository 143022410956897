import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';
import { TicketsResponse } from './useInfinityTickets';

/** 수강권 업그레이드 시, 업그레이드 가능한 부모 수강권 목록 */
const useInfinityUserTicketUpgrade = (params: Params) => {
  const { userTicketId, ...rest } = params;
  const { queryFn } = useInfiniteQueryFnWithCommonParams<TicketsResponse, UserTicketUpgradeParams>({
    url: `/api/userTicket/upgrade/${userTicketId}`,
    params: {
      ...rest,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['user-ticket', 'upgrade', { ...params }],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityUserTicketUpgrade;

export type UserTicketUpgradeParams = {
  user_id: number;
  search?: string;
};

type Params = UserTicketUpgradeParams & {
  userTicketId: number;
};

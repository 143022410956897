import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import {
  ScheduleManagementBooking,
  ScheduleManagementBookings,
} from 'hooks/service/queries/useInfinityScheduleManagementBooking';
import { ScheduleManagementDeletedLecture } from 'hooks/service/queries/useInfinityScheduleManagementDeletedLecture';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  scheduleInfo: ScheduleManagementBookings | ScheduleManagementDeletedLecture;
  bookingInfo: ScheduleManagementBooking | null;
};
const CommonDetailInfo = ({ scheduleInfo, bookingInfo }: Props) => {
  return (
    <Container>
      <InfoWrapper>
        <Typography className="info-label" textColor="gray2" size={15} weight={400}>
          수업일시
        </Typography>
        <Typography textColor="gray1" size={15} weight={500}>
          {`${filters.dateWithWeekday(scheduleInfo.start_on)} ${filters.timePeriod(scheduleInfo.start_on, scheduleInfo.end_on)}`}
        </Typography>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper>
        <Typography className="info-label" textColor="gray2" size={15} weight={400}>
          담당강사
        </Typography>
        <div className="avatar-wrapper">
          <Avatar
            imgUrl={scheduleInfo.staff.staff_avatar?.image ? getImageUrl(scheduleInfo.staff.staff_avatar?.image) : undefined}
            size={24}
          />
          <Typography ellipsis={1} textColor="gray1" size={15} weight={500}>
            {`${scheduleInfo.staff.name}`}
          </Typography>
          <Typography className="no-shrink" textColor="gray1" size={15} weight={500}>
            강사
          </Typography>
        </div>
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      <InfoWrapper className="room-wrapper">
        <Typography className="info-label" textColor="gray2" size={15} weight={400}>
          룸
        </Typography>
        {scheduleInfo.room_id ? (
          <div>
            <Typography ellipsis={1} textColor="gray1" size={15} weight={500}>
              {scheduleInfo.room?.name}
            </Typography>
            <Typography textColor="gray1" size={15} weight={500}>
              룸
            </Typography>
          </div>
        ) : (
          <Typography textColor="gray1" size={15} weight={500}>
            선택 안함
          </Typography>
        )}
      </InfoWrapper>
      <Divider thin thinColor="gray8" />
      {bookingInfo && (
        <>
          <InfoWrapper>
            <Typography className="info-label" textColor="gray2" size={15} weight={400}>
              회원명
            </Typography>
            <div className="avatar-wrapper">
              <Avatar imgUrl={bookingInfo.member.avatar?.image ? getImageUrl(bookingInfo.member.avatar?.image) : undefined} />
              <Typography ellipsis={1} textColor="gray1" size={15} weight={500}>
                {`${bookingInfo.member.name}`}
              </Typography>
            </div>
          </InfoWrapper>
          <Divider thin thinColor="gray8" />
          <InfoWrapper>
            <Typography className="info-label" textColor="gray2" size={15} weight={400}>
              휴대폰번호
            </Typography>
            <Typography textColor="gray1" size={15} weight={500}>
              {`${filters.mobile(bookingInfo.member.mobile) || '-'}`}
            </Typography>
          </InfoWrapper>
          <Divider thin thinColor="gray8" />
        </>
      )}
    </Container>
  );
};

export default CommonDetailInfo;

const Container = styled.div`
  .room-wrapper {
    div {
      ${theme.flex('', '', '')}
    }
  }

  .last-info-item {
    margin-bottom: 32px;
  }
  .schedule-bottom-button {
    padding: 10px 20px;
  }
`;

const InfoWrapper = styled.div`
  ${theme.flex('row', 'center', 'space-between', 20)}
  height: 56px;
  padding: 17px 20px;

  > p:first-of-type {
    flex-shrink: 0;
  }

  .info-label {
    min-width: 82px;
  }

  .avatar-wrapper {
    ${theme.flex('row', '', '', 3)}
    padding-left: 20px;

    .avatar {
      margin-right: 3px;
    }

    .no-shrink {
      flex-shrink: 0;
    }
  }
`;

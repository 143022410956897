import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Datepicker, localeKo, Select } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';
import getNumberWheel from 'utils/getNumberWheel';

export type DayTimePickerValueType = { day: number; time: string };
export type DayTimePickerChangeParamsType = {
  start: DayTimePickerValueType | null;
  end: DayTimePickerValueType;
};

export type DayTimePickerProps = {
  /** 시작 value (1개만 선택 해야할 때, undefined) */
  startValues?: DayTimePickerValueType;
  /** 종료 value  */
  endValues: DayTimePickerValueType;
  onChange: (values: DayTimePickerChangeParamsType) => void;
};

const data = getNumberWheel({ min: 0, max: 99, suffix: '일' });
const commonProps = {
  display: 'inline' as const,
  theme: 'material',
  touchUi: true,
  locale: localeKo,
  rows: 3,
  itemHeight: 32,
};

const DayTimePicker = ({
  startValues,
  endValues = { day: 0, time: dayjs().startOf('day').format('HH:mm') },
  onChange,
}: DayTimePickerProps) => {
  const [tempStartDay, setTempStartDay] = useState(startValues?.day);
  const [tempStartTime, setTempStartTime] = useState(startValues?.time);

  const changeStartDay = (e: { value: number }) => {
    if (!startValues) return;

    setTempStartDay(e.value);
    onChange({
      start: { ...startValues, day: e.value },
      end: { ...endValues },
    });
  };

  const changeStartTime = (e: { value: Date }) => {
    if (!startValues) return;

    const newTime = dayjs(e.value).format('HH:mm');
    setTempStartTime(newTime);
    onChange({
      start: { ...startValues, time: newTime },
      end: { ...endValues },
    });
  };

  const changeEndDay = (e: { value: number }) => {
    onChange({
      start: !startValues ? null : { ...startValues },
      end: { ...endValues, day: e.value },
    });
  };

  const changeEndTime = (e: { value: Date }) => {
    const newTime = dayjs(e.value).format('HH:mm');
    onChange({
      start: !startValues ? null : { ...startValues },
      end: { ...endValues, time: newTime },
    });
  };

  /**
   * 시작일 보다 큰 값의 종료일을 선택하지 못하도록 disabled 처리
   * Select 컴포넌트는 최대 선택값 설정하는 max props가 없음
   */
  const endDaysData = useMemo(() => {
    if (tempStartDay === undefined) return data;
    return data.map(item => ({
      ...item,
      disabled: item.value > tempStartDay,
    }));
  }, [tempStartDay]);

  const maxEndTime = useMemo(() => {
    if (!tempStartDay || tempStartDay === endValues.day) return tempStartTime;
    return undefined;
  }, [endValues.day, tempStartDay, tempStartTime]);

  return (
    <Container isSingle={!startValues}>
      {startValues && (
        <div className="wrapper start">
          <Select {...commonProps} minWheelWidth={40} data={data} defaultSelection={startValues.day} onChange={changeStartDay} />
          <Datepicker
            {...commonProps}
            controls={['time']}
            timeFormat="HH:mm"
            minWheelWidth={32}
            value={startValues.time}
            onChange={changeStartTime}
          />
        </div>
      )}
      <div className="wrapper end">
        <Select {...commonProps} minWheelWidth={40} data={endDaysData} defaultSelection={endValues.day} onChange={changeEndDay} />
        <Datepicker
          {...commonProps}
          controls={['time']}
          max={maxEndTime}
          timeFormat="HH:mm"
          minWheelWidth={32}
          value={endValues.time}
          onChange={changeEndTime}
        />
      </div>
    </Container>
  );
};

export default DayTimePicker;

const Container = styled(CommonWheelStyles)<{ isSingle: boolean }>(
  ({ isSingle }) => css`
    ${theme.flex()};

    .wrapper {
      flex: 1;
      ${theme.flex()};

      &:first-of-type {
        border-right: 1px solid ${theme.color.gray8};
      }
    }

    .mbsc-scroller-wheel-line {
      margin: 0;
      border-radius: 0;
    }

    /** 일자(select) wrapper */
    .mbsc-select-scroller {
      flex: ${isSingle ? 0.4 : 1};

      .mbsc-scroller-wheel-group {
        padding-left: ${isSingle ? 86 : 16}px;
      }

      .mbsc-scroller-wheel-line {
        margin-left: 8px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .mbsc-scroller-wheel-wrapper {
        margin-left: 10px;
      }
    }

    /** 시간 wrapper */
    .mbsc-datepicker-control-time {
      flex: ${isSingle ? 0.6 : 2};

      .mbsc-scroller-wheel-group {
        padding-right: ${isSingle ? 84 : 16}px;
        padding-left: ${isSingle && 12}px;
      }

      .mbsc-scroller-wheel-line {
        margin-right: 8px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .mbsc-datetime-hour-wheel,
      .mbsc-datetime-minute-wheel {
        flex: 1;
      }

      /** 시간과 분 사이 콜론(:) */
      .mbsc-scroller-wheel-line::before {
        content: ':';
        color: #aaabaf;
        font-size: 1.9rem;
        line-height: 26px;
        font-weight: 500;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% - ${isSingle ? 30 : 3}px), calc(-50% - 1px));
      }
    }
  `,
);

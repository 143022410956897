import styled from '@emotion/styled';
import { queryClient } from 'api/queryClient';
import { theme } from 'assets/styles';
import IconButton from 'components/Button/IconButton';
import LinkButton from 'components/Button/LinkButton';
import Divider from 'components/Divider';
import { IHeaderProps } from 'components/Header';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import useGetUserTicket from 'hooks/service/queries/useGetUserTicket';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { useCallback, useMemo, useState } from 'react';
import MainLayout from 'sharedComponents/MainLayout';
import formatUserTicketCard from 'utils/formatUserTicketCard';

import ChangeProductStatusConfirmDialog from './components/ChangeProductStatusConfirmDialog';
import MainTopInfo from './components/MainTopInfo';
import TicketDetailAccordions from './components/TicketDetailAccordions';
import TicketDropDown from './components/TicketDropDown';
import { MainDialogStatus, TicketDropDownValueType } from './types';

type Props = {
  currentMemberId: number;
  currentTicketId: number;
};

const Main = ({ currentMemberId, currentTicketId }: Props) => {
  const { hasPermission } = usePermission();
  const canUpdate = hasPermission(MEMBER_PERMISSION.memberTicketEdit);
  const { data: userTicket } = useGetUserTicket({
    ticket_id: currentTicketId,
    user_id: currentMemberId,
  });

  const { ticketStatus } = formatUserTicketCard(userTicket);

  const { setSearchParams } = useQueryString();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [dialogStatus, setDialogStatus] = useState<MainDialogStatus>('delete');

  const showHoldingButton = useMemo(() => {
    if (userTicket.ticket.type === 'S' || userTicket.ticket.type === 'RT') return false;
    return true;
  }, [userTicket]);

  const clickHeaderDropDownValue = useCallback(
    (value: TicketDropDownValueType['value']) => {
      switch (value) {
        case 'upgrade':
        case 'transfer':
          queryClient.removeQueries({ queryKey: ['staff/me'] });
          setSearchParams({
            memberId: `${currentMemberId}`,
            type: value,
            step: '1',
          });
          break;
        case 'refund':
        case 'not_usable':
          queryClient.removeQueries({ queryKey: ['staff/me'] });
          setSearchParams({
            memberId: `${currentMemberId}`,
            type: value,
          });
          break;
        case 'reuse':
        case 'delete':
          setDialogStatus(value);
          setIsOpen(true);
          break;
      }
      setIsOpenDropDown(false);
    },
    [currentMemberId, setSearchParams],
  );

  const clickLinkButton = useCallback(
    (value: 'holding' | 'history' | 'memo') => {
      setSearchParams({
        memberId: `${currentMemberId}`,
        type: value,
      });
    },
    [currentMemberId, setSearchParams],
  );

  const headerOption: IHeaderProps = useMemo(() => {
    return {
      rightButton: canUpdate && ticketStatus !== 'expired' && (
        <TicketDropDown
          userTicket={userTicket}
          ticketStatus={ticketStatus}
          isOpen={isOpenDropDown}
          setIsOpen={setIsOpenDropDown}
          onClick={clickHeaderDropDownValue}>
          <IconButton onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
            <Icon name="headerMore" fillColor={theme.color.gray1} />
          </IconButton>
        </TicketDropDown>
      ),
    };
  }, [canUpdate, clickHeaderDropDownValue, isOpenDropDown, ticketStatus, userTicket]);

  return (
    <>
      <MainLayout header={headerOption}>
        <MainTopInfo userTicket={userTicket} goToHoldingPage={() => clickLinkButton('holding')} />
        <Divider />
        <TicketDetailAccordions canUpdate={canUpdate} userTicket={userTicket} currentMemberId={currentMemberId} />
        {showHoldingButton && (
          <LinkButton size="large60" onClick={() => clickLinkButton('holding')}>
            정지기간 정보
          </LinkButton>
        )}
        <LinkButton size="large60" onClick={() => clickLinkButton('history')}>
          변경이력
        </LinkButton>
        <LinkButton size="large60" showBorder={false} onClick={() => clickLinkButton('memo')}>
          <MemoButtonWrapper>
            <Typography span size={15} weight={600}>
              메모
            </Typography>
            {!!userTicket.memoCount && (
              <Typography span size={15} weight={600} textColor="primary">
                {userTicket.memoCount}
              </Typography>
            )}
          </MemoButtonWrapper>
        </LinkButton>
      </MainLayout>

      {/* 수강권 삭제, 사용재개 mutate */}
      {isOpen && (
        <ChangeProductStatusConfirmDialog userTicket={userTicket} dialogStatus={dialogStatus} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default Main;

const MemoButtonWrapper = styled.div`
  ${theme.flex('', 'center', '', 4)}
`;

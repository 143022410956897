import useInfinityUserTicketPrivate from 'hooks/service/queries/useInfinityUserTicketPrivate';
import { BookingPrivateFormType } from 'pages/Booking/Private/types';
import CommonList from 'pages/Booking/SelectMember/CommonList';
import { useFormContext, useWatch } from 'react-hook-form';

const List = () => {
  const { control, getValues } = useFormContext<BookingPrivateFormType>();
  const selectedUserTickets = useWatch({ control, name: 'selectedUserTickets' });
  const search = useWatch({ control, name: 'search' });
  const maxTrainee = Number(getValues('lectureSize') || 0);
  const disabledSelectMembers = selectedUserTickets.length === maxTrainee;

  const {
    data: userTickets,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfinityUserTicketPrivate({
    start_date: getValues('startDate'),
    end_date: getValues('endDate'),
    max_trainee: maxTrainee,
    search,
  });

  return (
    <CommonList
      search={search}
      userTickets={userTickets}
      selectedUserTickets={selectedUserTickets}
      disabledSelectMembers={disabledSelectMembers}
      hasMore={hasNextPage}
      isLoading={isFetchingNextPage}
      loadMore={fetchNextPage}
    />
  );
};

export default List;

import Radio from 'components/Radio';
import { Controller, useFormContext } from 'react-hook-form';
import { StaffProfileFormType } from 'sharedComponents/ProfileField/types';

import { PRIVATE_START_INTERVAL } from '../constants';
import IntervalSubTitle from './IntervalSubTitle';

const IntervalPmController = () => {
  const { control, getValues, setValue } = useFormContext<StaffProfileFormType>();

  return (
    <Controller
      name="profile.private_start_interval_pm"
      control={control}
      defaultValue={getValues('profile.private_start_interval_pm')}
      render={({ field: { value, onChange, ...field } }) => {
        return (
          <div className="private-start-interval">
            <IntervalSubTitle title={PRIVATE_START_INTERVAL.subTitle.pm} />
            <ul className="time-table-list">
              {PRIVATE_START_INTERVAL.radio.map(({ value: itemValue, label }) => {
                return (
                  <li key={`pm-${itemValue}`}>
                    <Radio
                      id={`pm-${itemValue}`}
                      label={label}
                      value={itemValue}
                      checked={itemValue === value}
                      onChange={e => {
                        setValue('profile.private_start_interval_pm', parseInt(e.target.value), { shouldDirty: true });
                      }}
                      {...field}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        );
      }}
    />
  );
};

export default IntervalPmController;

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * params /:id를 숫자로 가져오는 훅, 숫자가 아닐 경우 404 리다이렉트해줌
 */
const useParamsId = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const numberId = Number(id);
  useEffect(() => {
    if (isNaN(numberId)) {
      navigate('/404');
    }
  }, [numberId, navigate]);

  return numberId;
};

export default useParamsId;

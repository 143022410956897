import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { MemberResponse } from 'hooks/service/queries/useInfinityMember';
import { convertMembersToTargets } from 'pages/MoreDetails/Message/utils';
import { memberCardStyle } from 'sharedComponents/MemberCard';
import MemberInfo from 'sharedComponents/MemberCard/components/MemberInfo';
import MemberTicket from 'sharedComponents/MemberCard/components/MemberTicket';

import TargetCheckBox from './TargetCheckBox';
import TargetSelectable from './TargetSelectable';

type Props = {
  member: MemberResponse;
};
const MemberListItem = ({ member }: Props) => {
  return (
    <MemberCardSelectable target={convertMembersToTargets([member])[0]}>
      <Row>
        <TargetCheckBox id={member.id} />
        <MemberInfo member={member} showRegisteredAt={false} />
      </Row>
      {member.userTicket.map(userTicket => (
        <MemberTicket key={userTicket.id} userTicket={userTicket} />
      ))}
    </MemberCardSelectable>
  );
};

export default MemberListItem;

const Row = styled.div`
  ${theme.flex('', 'center', 'start', 16)}
`;

const MemberCardSelectable = styled(TargetSelectable)`
  ${memberCardStyle}
`;

import { useMutation } from '@tanstack/react-query';
import delay from 'utils/delay';

import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostMemberSend = () => {
  const { mutationFn } = useMutationFnWithCommonParams<MemberAppLinkParams>({
    method: 'post',
    url: `/api/member/send`,
  });

  return useMutation({
    mutationFn: async (param: MemberAppLinkParams) => {
      await delay(2000);
      return mutationFn(param);
    },
  });
};

export default usePostMemberSend;

export type MemberAppLinkParams = {
  member_id: number;
  message: string;
  title: string;
  send_on?: string;
};

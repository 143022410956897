import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityHoliday = () => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<HolidayResponse, HolidayParams>({
    url: '/api/holiday',
    params: {
      limit: 20,
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: ['holidays'],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => data.pages.flatMap(page => page.data.data),
  });
};

export default useInfinityHoliday;

export type HolidayResponse = {
  id: number;
  date: string;
  created_at: string;
  deleted_at: string | null;
  not_action_auto_balance: boolean;
};

type HolidayParams = {
  limit: number;
};

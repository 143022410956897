import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import { SUDAMATE_COMMENT_SORTING_FILTERS } from '../constant';

type SortType = {
  sort?: 'asc' | 'desc';
};

type Props<T extends SortType> = {
  sortOptions: Array<{ id: string; label: string; value: 'asc' | 'desc' }>;
  isShowTotal?: boolean;
  total?: number;
};

const Sorting = <T extends SortType>({ sortOptions }: Props<T>) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sort, setSort] = useState(SUDAMATE_COMMENT_SORTING_FILTERS[0]);

  const { control } = useForm<SortType>({
    defaultValues: {
      sort: 'asc',
    },
  });

  const changeSort = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as 'asc' | 'desc';
    const selectedSort = SUDAMATE_COMMENT_SORTING_FILTERS.filter(sort => sort.value === value);
    setSort(selectedSort[0]);
  };

  return (
    <Container>
      <Button
        fontWeight={400}
        textColor="gray2"
        rightIcon={<Icon name="arrowBottomFill" size={16} fillColor={theme.color.gray3} />}
        onClick={() => setIsFilterOpen(true)}>
        {sort.label}
      </Button>

      <CheckRadioDrawer
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        control={control}
        name="sort"
        data={sortOptions}
        onClick={changeSort}
      />
    </Container>
  );
};

export default Sorting;

const Container = styled.div`
  .sorting-wrapper {
    span:first-of-type {
      padding-right: 4px;
    }
    span:last-of-type {
      padding-right: 8px;
    }
  }
`;

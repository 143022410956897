import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Skeleton from 'components/Skeleton';
import Tabs from 'components/Tabs';

import { TAB_DATA } from '../constants';

const SkeletonStaffDetail = () => {
  return (
    <Container>
      <div className="profile">
        <Skeleton height={72} width={72} type="circle" />
        <div className="info">
          <Skeleton height={24} width={135} type="round" />
          <Skeleton height={20} width={201} type="round" />
          <Skeleton className="last" height={14} width={138} type="round" />
        </div>
      </div>

      <Divider thin />

      <div className="birthday">
        <Icon name="birthdayFill" size={16} fillColor={theme.color.gray4} />
        <Skeleton height={18} width={67} type="round" />
        <Skeleton height={10} width={1} type="box" />
        <Skeleton height={18} width={25} type="round" />
      </div>

      <Divider />

      <Tabs data={TAB_DATA} value={TAB_DATA[0].value} onChange={() => null} />

      <div className="basic-info">
        <div className="header">
          <Skeleton height={22} width={39} type="round" />
          <Skeleton className="custom-round" height={20} width={40} type="round" />
        </div>

        <div className="middle">
          <Skeleton height={24} width={78} type="round" />
          <Skeleton height={88} width={335} type="round" />
        </div>

        <Divider thin />

        <div className="bottom">
          <Skeleton height={24} width={78} type="round" />
          <Skeleton height={108} width={238} type="round" />
        </div>
      </div>
    </Container>
  );
};

export default SkeletonStaffDetail;

const Container = styled.div`
  [type='round'] {
    border-radius: 6px;

    &.custom-round {
      border-radius: 10px;
    }
  }

  .profile {
    ${theme.flex('row', 'center', 'flex-start', 16)};
    padding: 16px 20px 20px;

    .info {
      ${theme.flex('column', '', '', 4)};

      .last {
        margin-top: 2px;
      }
    }
  }

  .birthday,
  .basic-info {
    padding: 20px;
  }

  .birthday {
    ${theme.flex('row', 'center', 'flex-start', 8)};

    [type='box'] {
      background-color: ${theme.color.gray4};
    }
  }

  .basic-info {
    ${theme.flex('column', 'flex-start', 'center', 24)};

    hr {
      width: 100%;
    }

    .header {
      ${theme.flex('row', 'center', 'space-between')};
      width: 100%;
    }

    .middle,
    .bottom {
      div:first-of-type {
        margin-bottom: 8px;
      }
    }
  }
`;

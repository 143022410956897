import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import usePatchBookingGroupCancel from 'hooks/service/mutations/usePatchBookingGroupCancel';
import usePatchBookingGroupCancelWaiting from 'hooks/service/mutations/usePatchBookingGroupCancelWaiting';
import usePatchBookingPrivateCancel from 'hooks/service/mutations/usePatchBookingPrivateCancel';
import useToast from 'hooks/useToast';
import Josa from 'josa-js';
import { BOOKING_TYPE } from 'pages/MemberDetail/constants';
import { HistoryFilterFormType } from 'pages/MemberDetail/types';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  onClose: () => void;
};

const CancelBooking = ({ onClose }: Props) => {
  const { watch } = useFormContext<HistoryFilterFormType>();
  const {
    bookingInfo: { id, status, classType },
  } = watch();

  const { setToast } = useToast();

  const { mutate: cancelBookingGroupMutate } = usePatchBookingGroupCancel(id);
  const { mutate: cancelBookingGroupWaitingMutate } = usePatchBookingGroupCancelWaiting(id);
  const { mutate: cancelBookingPrivateMutate } = usePatchBookingPrivateCancel(id);

  const onSuccess = useCallback(() => {
    const message = `${Josa.r(BOOKING_TYPE[status], '이/가')} 취소되었습니다.`;

    setToast({ type: 'success', message });
    onClose();
  }, [onClose, setToast, status]);

  const clickCancel = useCallback(() => {
    if (status === 'booking_waiting') {
      cancelBookingGroupWaitingMutate(undefined, {
        onSuccess,
      });
    } else {
      if (classType === 'G') {
        cancelBookingGroupMutate(undefined, {
          onSuccess,
        });
      } else {
        cancelBookingPrivateMutate(undefined, {
          onSuccess,
        });
      }
    }
  }, [cancelBookingGroupMutate, cancelBookingGroupWaitingMutate, cancelBookingPrivateMutate, classType, onSuccess, status]);

  return (
    <Dialog
      onClose={onClose}
      negativeAction={{ text: '취소' }}
      positiveAction={{
        text: '확인',
        onClick: clickCancel,
      }}>
      <DialogDescription>{Josa.r(BOOKING_TYPE[status], '을/를')} 취소하시겠습니까?</DialogDescription>
    </Dialog>
  );
};

export default CancelBooking;

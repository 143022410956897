import CounselCustomerFilter from 'pages/Member/components/CounselCustomerFilter';
import Sorting from 'pages/Member/components/Sorting';
import { messageTargetCounselSelectedFilter } from 'recoil/message';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import Counts from '../../Member/components/Counts';
import { RightAlign, Row, StickyContainer } from '../../Member/components/StickyHeader';

const StickyHeader = () => {
  const filterAtom = messageTargetCounselSelectedFilter;

  return (
    <StickyContainer>
      <ApiBoundary>
        <CounselCustomerFilter filterAtom={filterAtom} hasRegisterFilter />
      </ApiBoundary>
      <Row>
        <Counts filterAtom={filterAtom} />
      </Row>
      <RightAlign>
        <Sorting filterAtom={filterAtom} />
      </RightAlign>
    </StickyContainer>
  );
};

export default StickyHeader;

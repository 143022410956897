import 'swiper/css';

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import { Z_INDEX } from 'constants/zIndex';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import TutorialFifth from './components/TutorialFifth';
import TutorialFirst from './components/TutorialFirst';
import TutorialFourth from './components/TutorialFourth';
import TutorialPortal from './components/TutorialPortal';
import TutorialSecond from './components/TutorialSecond';
import TutorialSixth from './components/TutorialSixth';
import TutorialThird from './components/TutorialThird';
import { TUTORIAL_PAGE } from './constant';

type Props = {
  onClose: () => void;
};

const Tutorial = ({ onClose }: Props) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (page === TUTORIAL_PAGE.length - 1) onClose();
  }, [page]);

  return (
    <TutorialPortal>
      <Container>
        <button className="close-button" onClick={onClose}>
          <Icon name="headerClose" color="white" size={24} />
        </button>
        <SwiperWrapper>
          <Swiper
            spaceBetween={0}
            onSlideChange={swiper => {
              setPage(swiper.activeIndex);
            }}>
            {TUTORIAL_PAGE.map(currentPage => (
              <SwiperSlide key={currentPage}>
                {currentPage === 0 && <TutorialFirst />}
                {currentPage === 1 && <TutorialSecond />}
                {currentPage === 2 && <TutorialThird />}
                {currentPage === 3 && <TutorialFourth />}
                {currentPage === 4 && <TutorialFifth />}
                {currentPage === 5 && <TutorialSixth />}
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperWrapper>
        <div className="pagination">
          <div className="dots">
            {TUTORIAL_PAGE.map(currentPage => {
              return (
                currentPage < TUTORIAL_PAGE.length - 1 && (
                  <div className={`dot ${page === currentPage ? 'active' : ''}`} key={currentPage} />
                )
              );
            })}
          </div>
        </div>
      </Container>
    </TutorialPortal>
  );
};

export default Tutorial;

const Container = styled.div`
  .close-button {
    position: fixed;
    top: 1.5rem;
    left: 2rem;
    z-index: ${Z_INDEX.toast};
  }

  .pagination {
    position: fixed;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${Z_INDEX.toast};
    bottom: 3rem;

    .dots {
      display: flex;
      gap: 7px;
      .dot {
        width: 5px;
        height: 5px;
        background-color: ${theme.color.white};
        border-radius: 50%;
        opacity: 0.4;
      }
      .active {
        background-color: ${theme.color.white};
        opacity: 1;
      }
    }
  }
`;

const SwiperWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.drawer};
  opacity: 1;
  visibility: visible;

  .swiper {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.72);
  }
`;

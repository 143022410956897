import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';

import DateTimePickerWithTodayText from './DateTimePickerWithTodayText';
import { Props as DateTimePickerWithTodayTextProps } from './DateTimePickerWithTodayText';

type Props = DateTimePickerWithTodayTextProps & {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  zIndex?: number;
};

const DateTimePickerDrawer = ({ isOpen, onClose, onSave, zIndex, ...props }: Props) => {
  return (
    <StyledDoubleButtonDrawer
      type={3}
      hideHandle
      zIndex={zIndex}
      isOpen={isOpen}
      onClose={onClose}
      leftButton={{
        text: '취소',
        onClick: onClose,
      }}
      rightButton={{
        text: '완료',
        onClick: onSave,
      }}
      header={
        <HeaderTitle>
          <Typography size={17} weight={600}>
            예약일시
          </Typography>
          <Typography weight={500} textColor="secondary3">
            10분 이후부터 예약 가능
          </Typography>
        </HeaderTitle>
      }>
      <DateTimePickerWithTodayText {...props} stepMinute={5} />
    </StyledDoubleButtonDrawer>
  );
};

export default DateTimePickerDrawer;

const StyledDoubleButtonDrawer = styled(DoubleButtonDrawer)`
  padding: 0 0 calc(40px + 76px);

  && .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab {
    padding-bottom: 0;
  }
`;

const HeaderTitle = styled.div`
  ${theme.flex('row', 'center', 'space-between')};
  padding: 24px 20px 16px;
  border-bottom: 1px solid ${theme.color.gray7};
`;

import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { TicketTransferFormType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';

type Props = {
  message: string;
  submit: (values: TicketTransferFormType, isForce: boolean) => void;
  onClose: () => void;
};

const AutoCancelDialog = ({ message, submit, onClose }: Props) => {
  const { handleSubmit } = useFormContext<TicketTransferFormType>();
  const isMutating = useIsMutating();

  return (
    <Dialog
      negativeAction={{ text: '취소', onClick: onClose, style: 'outlined' }}
      positiveAction={{ text: '계속', onClick: handleSubmit(values => submit(values, true)), disabled: !!isMutating }}
      onClose={onClose}>
      <StyledDialogDescription>{message}</StyledDialogDescription>
    </Dialog>
  );
};

export default AutoCancelDialog;

const StyledDialogDescription = styled(DialogDescription)`
  white-space: pre-line;
`;

import { useEffect } from 'react';
import { postMessageToApp } from 'utils/communicateWithNative';

const useStatusBarColor = (color: string) => {
  useEffect(() => {
    if (window.ReactNativeWebView && color) {
      postMessageToApp('CHANGE_STATUSBAR_COLOR', color);
    }
  }, [color]);
};

export default useStatusBarColor;

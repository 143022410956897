import Typography from 'components/Typography';
import dayjs from 'dayjs';
import DateTimePicker from 'designedComponents/Pickers/DateTimePicker';
import { BOOKING_AVAILABLE_TIME_SETTING_TEXT } from 'pages/Booking/constants';
import { BookingAvailableFormType, BookingCommonFormType } from 'pages/Booking/types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';
import filters from 'utils/filters';

import BookingResetConfirmDialog from '../BookingResetConfirmDialog';
import RuleTypeAccordion from './RuleType/RuleTypeAccordion';
import { RuleTypeContainer, StyledRuleTypeTimeTypography, UpdateDateTypography } from './StyledComponents';

const CheckInAvailableSetting = () => {
  const { getValues, setValue } = useFormContext<BookingCommonFormType & BookingAvailableFormType>();
  const { enter_start_at, enter_end_at, lectureDate, lectureEndAt, currentLecture } = getValues();

  const [isOpen, setIsOpen] = useState(false);
  const [tempStartAt, setTempStartAt] = useState(enter_start_at);
  const [tempEndAt, setTempEndAt] = useState(enter_end_at);
  const [expanded, setExpanded] = useState<string | false>('startAt');
  const checkInMinTime = dayjs(lectureDate).subtract(5, 'hour').toDate();
  const checkInMaxTime = dayjs(lectureEndAt).add(5, 'hour').toDate();
  const navigate = useNavigate();
  const [alreadyChecked, setAlreadyChecked] = useState(
    currentLecture && (currentLecture.enter_start_at !== enter_start_at || currentLecture.enter_end_at !== enter_end_at),
  );

  const changeAccordionOpen = (panel: string) => {
    setExpanded(prev => (prev !== panel ? panel : false));
  };

  const changeDate = (e: { value: Date }) => {
    if (!enter_start_at || !enter_end_at || !expanded || !currentLecture) return;
    const date = filters.dateDashTimeSecondZero(e.value);

    switch (expanded) {
      case 'startAt':
        setTempStartAt(date);
        break;
      case 'endAt':
        setTempEndAt(date);
        break;
    }

    // 미체크인 자동 결석 처리 사용할 때
    if (!alreadyChecked && currentLecture.policy.is_auto_absence_for_enter) {
      setIsOpen(true);
    }
  };

  const cancelChange = () => {
    setTempStartAt(enter_start_at);
    setTempEndAt(enter_end_at);
    setIsOpen(false);
  };

  const continueChange = () => {
    setAlreadyChecked(true);
    setIsOpen(false);
  };

  const saveDate = () => {
    setValue('enter_start_at', tempStartAt, { shouldDirty: true });
    setValue('enter_end_at', tempEndAt, { shouldDirty: true });
    navigate(-1);
  };

  return (
    <>
      <RuleTypeContainer className="checkIn update">
        <RuleTypeAccordion
          iconName="checkIn2"
          isOpen={expanded === 'startAt'}
          onChange={() => changeAccordionOpen('startAt')}
          header={
            <StyledRuleTypeTimeTypography>
              <UpdateDateTypography isOpen={expanded === 'startAt'} startAt={tempStartAt} />
              <Typography span size={15}>
                부터
              </Typography>
            </StyledRuleTypeTimeTypography>
          }>
          <DateTimePicker
            min={checkInMinTime}
            max={tempEndAt}
            defaultValue={enter_start_at}
            value={tempStartAt}
            onChange={changeDate}
          />
        </RuleTypeAccordion>
        <RuleTypeAccordion
          className="update-end-at"
          isLast
          isOpen={expanded === 'endAt'}
          onChange={() => changeAccordionOpen('endAt')}
          header={
            <StyledRuleTypeTimeTypography>
              <UpdateDateTypography isOpen={expanded === 'endAt'} endAt={tempEndAt} />
              <Typography span size={15}>
                {BOOKING_AVAILABLE_TIME_SETTING_TEXT['checkIn']}
              </Typography>
            </StyledRuleTypeTimeTypography>
          }>
          <DateTimePicker
            min={tempStartAt}
            max={checkInMaxTime}
            defaultValue={enter_end_at}
            value={tempEndAt}
            onChange={changeDate}
          />
        </RuleTypeAccordion>
        <FooterButton onClick={saveDate}>완료</FooterButton>
      </RuleTypeContainer>

      {isOpen && <BookingResetConfirmDialog target="체크인 시간" onCancel={cancelChange} onContinue={continueChange} />}
    </>
  );
};

export default CheckInAvailableSetting;

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const useDeleteMember = (memberId: number) => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: 'delete',
    url: `/api/member/${memberId}`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default useDeleteMember;

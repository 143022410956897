import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import getAllSearchParamsUtils from 'utils/getAllSearchParamsUtils';

const useQueryString = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getSearchParams = useCallback((value: string) => searchParams.get(value), [searchParams]);

  const getAllSearchParams = useCallback(() => getAllSearchParamsUtils(searchParams), [searchParams]);

  return {
    getSearchParams,
    getAllSearchParams,
    setSearchParams,
  };
};

export default useQueryString;

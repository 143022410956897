import Select from 'components/Select';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import { isEqual, sortBy } from 'lodash';
import { Dispatch, SetStateAction, useMemo } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import SelectCheckBoxList from '../popupPage/components/SelectCheckBoxList';
import { CommonProps } from '../popupPage/types';

type Props = {
  isOpen: boolean;
  staffs: StaffResponse[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
} & CommonProps;

const FilterStaffsDrawer = ({
  isOpen,
  staffs,
  tempApplyStaffs,
  tempCheckedStaffs,
  setIsOpen,
  setTempApplyStaffs,
  setTempCheckedStaffs,
}: Props) => {
  const currentSelected = useMemo(() => {
    const firstStaffName = staffs.filter(staff => staff.id === tempApplyStaffs[0])[0]?.name;
    const selectedStaffLength = tempApplyStaffs.length;

    if (tempApplyStaffs.includes('all')) {
      return '전체';
    }

    switch (selectedStaffLength) {
      case 1:
        return firstStaffName;
      default:
        return `${firstStaffName} 외 ${selectedStaffLength - 1}명`;
    }
  }, [staffs, tempApplyStaffs]);

  const closeDrawer = () => {
    setTempCheckedStaffs(tempApplyStaffs);
    setIsOpen(false);
  };

  return (
    <Select
      headerText="강사 선택"
      value={currentSelected}
      height="large"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={closeDrawer}
      option={
        <ApiBoundary>
          <SelectCheckBoxList
            staffs={staffs}
            tempCheckedStaffs={tempCheckedStaffs}
            setIsOpen={setIsOpen}
            setTempApplyStaffs={setTempApplyStaffs}
            setTempCheckedStaffs={setTempCheckedStaffs}
            isDirty={!!isEqual(sortBy(tempCheckedStaffs), sortBy(tempApplyStaffs))}
          />
        </ApiBoundary>
      }
    />
  );
};

export default FilterStaffsDrawer;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography, { TypographyProps } from 'components/Typography';
import { PropsWithChildren, useMemo } from 'react';
import { ColorType } from 'types/colorTypes';
import { hexToRgba } from 'utils/styles';

export interface ILabelProps {
  /** Label 사이즈 (높이) */
  size: 'small' | 'regular' | 'large';
  /** Label 텍스트 컬러 */
  textColor: TypographyProps['textColor'];
  /** Label 텍스트 컬러 */
  fontSize?: TypographyProps['size'];
  /** Label 색상 */
  color?: ColorType | 'transparent';
  /** Label border 컬러 */
  borderColor?: ColorType;
  /** Label 높이 지정 필요할 때 */
  height?: number;
  /** Label 최소 너비 */
  minWidth?: number;
  /** Label 투명도 */
  opacity?: number;
  /** Label 테두리 둥근 정도 (border-radius) */
  borderRadius?: number;
  /** 양옆 padding */
  padding?: number;
  /** 잎 형태의 모양 라벨(border-radius 조정) */
  shapeLeaf?: boolean;
  className?: string;
}

const Label = ({
  children,
  size = 'regular',
  fontSize = 12,
  height,
  textColor,
  opacity = 1,
  className,
  ...props
}: PropsWithChildren<ILabelProps>) => {
  const labelHeight = useMemo(() => {
    if (height) return height;
    switch (size) {
      case 'large':
        return 24;
      case 'regular':
        return 22;
      case 'small':
        return 18;
    }
  }, [height, size]);

  return (
    <Container className={`label ${className ?? ''}`} size={size} opacity={opacity} labelHeight={labelHeight} {...props}>
      <Typography span size={fontSize} weight={600} textColor={textColor}>
        {children}
      </Typography>
    </Container>
  );
};

const Container = styled.span<Omit<ILabelProps, 'textColor' | 'children' | 'fontSize'> & { labelHeight: number }>(
  ({ size, color = 'white', borderColor, minWidth, labelHeight, opacity, borderRadius, shapeLeaf }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${size === 'small' ? '0 3px' : '0 6px'};
    border-radius: ${shapeLeaf ? '12px 0' : borderRadius}px;

    background-color: ${color === 'transparent'
      ? 'transparent'
      : !color
        ? theme.color.white
        : hexToRgba(theme.color[color], opacity)};
    border: ${borderColor && `1px solid ${hexToRgba(theme.color[borderColor], opacity)}`};
    min-width: ${minWidth && `${minWidth}px`};
    height: ${labelHeight}px;
  `,

  ({ size }) => {
    switch (size) {
      case 'large':
        return css`
          border-radius: 4px;
        `;
      case 'regular':
        return css`
          border-radius: 3px;
        `;
      case 'small':
        return css`
          border-radius: 2px;
        `;
    }
  },

  ({ padding }) =>
    padding &&
    css`
      padding: 0;
      padding-left: ${padding}px;
      padding-right: ${padding}px;
    `,
);

export default Label;

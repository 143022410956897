import styled from '@emotion/styled';
import { LectureOperationFormType } from 'pages/Booking/types';
import { numToTimeKor } from 'pages/Booking/utils/convertTimes';
import { useFormContext } from 'react-hook-form';

import CheckInFieldButton from '../../AvailableTime/CheckInFieldButton';

const CheckInAvailableField = () => {
  const { getValues } = useFormContext<LectureOperationFormType>();

  return (
    <StyledCheckInButton>
      수업시작 {numToTimeKor(getValues('policy.enterStartline'))} 전부터 <br />
      수업종료 {numToTimeKor(getValues('policy.enterDeadline'))} 후까지
    </StyledCheckInButton>
  );
};

export default CheckInAvailableField;

const StyledCheckInButton = styled(CheckInFieldButton)`
  height: 99px;
`;

import IconButton from 'components/Button/IconButton';
import FloatingActionButton from 'components/FloatingActionButton';
import Icon from 'components/Icon';
import useQueryString from 'hooks/useQueryString';
import { useNavigate } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import SwiperBanner from './components/Banner/SwiperBanner';
import SudamateList from './components/SudamateList';
import SudamateSubHeader from './components/SudamateSubHeader';

const Lounge = () => {
  const navigate = useNavigate();
  const { getSearchParams } = useQueryString();
  const search = getSearchParams('search');
  return (
    <MainLayout
      header={{
        title: '라운지',
        leftAlign: true,
        noUnderline: true,
        rightButton: (
          <IconButton onClick={() => console.log('알림클릭')}>
            <Icon name="alert" />
          </IconButton>
        ),
      }}
      bottomNav
      childrenFloatingButton={<FloatingActionButton type="single" onClick={() => navigate('form?type=create')} />}>
      {!search && <SwiperBanner />}
      <SudamateSubHeader />
      <ApiBoundary>
        <SudamateList />
      </ApiBoundary>
    </MainLayout>
  );
};

export default Lounge;

import { useSuspenseQuery } from '@tanstack/react-query';
import { AppPushDetailType } from 'pages/MoreDetails/Message/Push/Detail/types';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetMessagePushDetail = (id: number) => {
  const { queryFn } = useQueryFnWithCommonParams<MessagePushDetailResponse>({
    url: `/api/message/push/${id}`,
  });

  return useSuspenseQuery({
    queryKey: [`message/push`, id],
    queryFn,
    select: data => data.data.data,
  });
};

export default useGetMessagePushDetail;

type MessagePushDetailResponse = {
  data: AppPushDetailType;
};

import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';

const DashBoardSkeleton = () => {
  return (
    <Container showBoxShadow={false} bgColor="primary">
      <TopWrapper>
        <Typography className="remaining-point-text" span size={13} weight={500} textColor="white" opacity={0.8}>
          잔여 포인트
        </Typography>
        <StyledSkeleton width={200} height={28} />
        <Typography className="edit-text" span size={13} weight={500} textColor="white">
          수정하기
        </Typography>
      </TopWrapper>

      <InfoFlexWrapper>
        <StyledFlexRow>
          <Typography size={15} textColor="white" opacity={0.88}>
            적립 포인트
          </Typography>
          <StyledSkeleton width={188} height={20} />
        </StyledFlexRow>
        <StyledFlexRow>
          <Typography size={15} textColor="white" opacity={0.88}>
            사용 포인트
          </Typography>
          <StyledSkeleton width={147} height={20} />
        </StyledFlexRow>
      </InfoFlexWrapper>
    </Container>
  );
};

export default DashBoardSkeleton;

const Container = styled(CommonStyleCard)`
  ${theme.flex('column', '', '', 20)};
  margin: 24px 20px;
  border-radius: 16px;
`;

const TopWrapper = styled.div`
  ${theme.flex('column', '', '', 6)};
  position: relative;

  .edit-text {
    position: absolute;
    right: 0;
    top: 15px;
    padding: 1px 7px;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: 100%;
      height: 100%;
      border: 1px solid ${theme.color.white};
      opacity: 0.16;
      border-radius: 999px;
    }
  }
`;

const StyledFlexRow = styled(InfoFlexRow)`
  .typography:last-of-type {
    text-align: left;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 3px;
  background-color: ${theme.color.white};
  opacity: 0.16;
`;

import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Tabs, { TabDataElementType } from 'components/Tabs';
import TabPanel from 'components/Tabs/TabPanel';
import { PERMISSION } from 'constants/permission';
import { Z_INDEX } from 'constants/zIndex';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useGetStaff from 'hooks/service/queries/useGetStaff';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { targetStaffAtom } from 'recoil/staff';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { TAB_DATA } from '../constants';
import StaffProfileCard from './ProfileCard/StaffProfileCard';
import SkeletonTimeSchedule from './SkeletonTimeSchedule';
import AssignedMember from './TabContent/AssignedMember';
import BasicInfo from './TabContent/BasicInfo';
import TimeSchedule from './TabContent/TimeSchedule';

const StaffDetailLayout = () => {
  const setStaffName = useSetRecoilState(targetStaffAtom);

  const { getSearchParams, setSearchParams, getAllSearchParams } = useQueryString();
  const targetStaffId = useParamsId();
  const { data: staff } = useGetStaff({ targetStaffId });
  const { checkPermission } = useGetPermissionDoHavePermission();

  const tabValue = getSearchParams('tab') as string;
  const currentTab = TAB_DATA.find(tabData => tabData.value === tabValue) || TAB_DATA[0];

  useEffect(() => {
    if (staff) {
      setStaffName({ id: staff.id, name: staff.name });
    }
  }, [staff, setStaffName]);

  const privateStartInterval = useMemo(() => {
    return {
      isEnabled: !!staff.profile?.private_start_interval_on_off,
      am: staff.profile?.private_start_interval_am,
      pm: staff.profile?.private_start_interval_pm,
    };
  }, [staff.profile]);

  const navigate = useNavigate();
  const changeTab = (tabData: TabDataElementType) => {
    checkPermission(PERMISSION.staff.view.id, {
      onSuccess: () => {
        setSearchParams({ ...getAllSearchParams(), tab: tabData.value }, { replace: true });
      },
      onError: () => {
        navigate(-1);
      },
    });
  };

  return (
    <>
      <StaffProfileCard staff={staff} />
      <Divider />
      <StyledTabs data={TAB_DATA} value={currentTab.value} onChange={changeTab} />
      <TabPanel>
        {currentTab.value === 'basicInfo' && (
          <BasicInfo selfIntroduction={staff.profile.self_introduction} careers={staff.careers} />
        )}
        {currentTab.value === 'schedule' && (
          <ApiBoundary fallback={<SkeletonTimeSchedule />}>
            <TimeSchedule privateStartInterval={privateStartInterval} />
          </ApiBoundary>
        )}
        {currentTab.value === 'AssignedMember' && <AssignedMember />}
      </TabPanel>
    </>
  );
};

export default StaffDetailLayout;

const StyledTabs = styled(Tabs)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: ${Z_INDEX.stickyTab};
`;

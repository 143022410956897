import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RecoilState, useRecoilState } from 'recoil';
import SortButton from 'sharedComponents/SortButton';

import { ATTENDANCE_SORT_DATA, DEFAULT_SORT_DATA } from '../constants';
import { MemberSelectedFilterType } from '../types';

type Props<T extends MemberSelectedFilterType> = {
  filterAtom: RecoilState<T>;
};

const Sorting = <T extends MemberSelectedFilterType>({ filterAtom }: Props<T>) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [selectedFilter, setSelectedFilter] = useRecoilState(filterAtom);

  // 누적 출석관련 정렬은 해당 필터 활성화되어 있을 때만 노출
  const isAttendanceSelected = !!selectedFilter.attendance_count;
  const sortData = isAttendanceSelected ? [...DEFAULT_SORT_DATA, ...ATTENDANCE_SORT_DATA] : DEFAULT_SORT_DATA;

  const currentSort =
    selectedFilter.sort_target &&
    selectedFilter.sort_type &&
    sortData.some(data => data.value === `${selectedFilter.sort_target},${selectedFilter.sort_type}`)
      ? `${selectedFilter.sort_target},${selectedFilter.sort_type}`
      : sortData[0].value;

  const sortLabel = sortData.find(({ value }) => value === currentSort)?.label;

  const { control, watch, setValue } = useForm({
    defaultValues: {
      sort: currentSort,
    },
  });

  const sort = watch('sort');

  useEffect(() => {
    setValue('sort', currentSort);
  }, [currentSort, setValue]);

  useEffect(() => {
    if (!sort) return;
    const [sort_target, sort_type] = sort.split(',');

    setSelectedFilter(selectedFilter => ({
      ...selectedFilter,
      sort_target,
      sort_type,
    }));
  }, [setSelectedFilter, sort]);

  return (
    <>
      <SortButton onClick={() => setIsDrawerOpened(true)}>{sortLabel}</SortButton>
      <CheckRadioDrawer
        name="sort"
        control={control}
        data={sortData}
        isOpen={isDrawerOpened}
        onClose={() => setIsDrawerOpened(false)}
      />
    </>
  );
};

export default Sorting;

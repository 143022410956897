import styled from '@emotion/styled';
import Typography from 'components/Typography';
import { useEffect } from 'react';
import { useSwiper } from 'swiper/react';

import { OnboardingContent } from '../type';

type Props = {
  content: OnboardingContent;
  changeSwiper: (swiper: any) => void;
};

const OnboardingItem = ({ content, changeSwiper }: Props) => {
  const swiper = useSwiper();

  useEffect(() => {
    changeSwiper(swiper);
  }, [swiper]);

  return (
    <Container>
      <HeaderSection>
        <Typography className="title" size={21} weight={700}>
          {content.title}
        </Typography>
        <Typography size={15} textColor="gray2">
          {content.subTitle1}
        </Typography>
        <Typography size={15} textColor="gray2">
          {content.subTitle2}
        </Typography>
      </HeaderSection>
      <BodySection>
        <div className="imgWrapper">
          <img src={content.img} alt="일정관리설명이미지" />
        </div>
      </BodySection>
    </Container>
  );
};

export default OnboardingItem;

const Container = styled.div``;

const HeaderSection = styled.section`
  padding: 64px 20px 44px 20px;
  text-align: center;
  .title {
    padding-bottom: 8px;
  }
`;

const BodySection = styled.section`
  .imgWrapper {
    padding: 0px 16px;
    img {
      width: 100%;
    }
  }
`;

import { PERMISSION } from 'constants/permission';
import useDeleteMessageMobile from 'hooks/service/mutations/useDeleteMessageMobile';
import usePostMessageMobileCancel from 'hooks/service/mutations/usePostMessageMobileCancel';
import MessageMenuButton from 'pages/MoreDetails/Message/components/MessageMenuButton';
import { useNavigate } from 'react-router-dom';

import { MobileDetailType } from '../type';
import { convertMobileFormData } from '../utils';

type Props = {
  message: MobileDetailType;
};
const MobileMenuButton = ({ message }: Props) => {
  /**
   * 즉시 발송, 예약취소문자 는 삭제만 가능
   * 예약문자는 수정, 취소 가능
   */
  const isDeletable = message.is_sent || !!message.canceled_at;
  const permissionNeeded = isDeletable ? PERMISSION.message.sms.delete : PERMISSION.message.sms.edit;

  const { mutate: deleteMutate } = useDeleteMessageMobile(message.id);
  const { mutate: cancelMutate } = usePostMessageMobileCancel(message.id);

  const navigate = useNavigate();
  const editMessage = () => {
    navigate(`/more-details/message/mobile/form`, { state: convertMobileFormData(message) });
  };

  return (
    <MessageMenuButton
      isDeletable={isDeletable}
      permissionNeeded={permissionNeeded}
      deleteMutate={deleteMutate}
      cancelMutate={cancelMutate}
      editMessage={editMessage}
      message={{ count: message.targets.length, sendOn: message.send_on, point: message.used_points }}
    />
  );
};

export default MobileMenuButton;

import styled from '@emotion/styled';
import { localeKo, Select } from '@mobiscroll/react';
import { MbscSelectOptions } from '@mobiscroll/react/dist/src/core/components/select/select';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';
import getNumberWheel from 'utils/getNumberWheel';

export interface INumberPicker extends MbscSelectOptions {
  /** 1 ~ 999까지 선택 가능 */
  defaultSelection?: number;
  /** 선택할 수 있는 최소값 */
  min?: number;
  /** 선택할 수 있는 최대값 */
  max?: number;
  /** wheel 내부 한자리 수 앞에 0 붙이는 유무 */
  hasZero?: boolean;
}

const NumberPicker = ({ min, max, hasZero, ...props }: INumberPicker) => {
  const data = getNumberWheel({ min: min ?? 1, max, hasZero });

  return (
    <Container className="number-picker">
      <Select
        theme="material"
        touchUi={true}
        locale={localeKo}
        display="inline"
        rows={3}
        itemHeight={32}
        data={data}
        {...props}
      />
    </Container>
  );
};

export default NumberPicker;

const Container = styled(CommonWheelStyles)`
  padding: 24px 0;
`;

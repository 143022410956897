import { useSuspenseQuery } from '@tanstack/react-query';
import { Meta } from 'api/modules/history';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetStaffSudamate = (params: StaffSudamateParams) => {
  const { queryFn } = useQueryFnWithCommonParams<StaffSudamateResponse, StaffSudamateParams>({
    url: `/api/staff-sudamate`,
    params,
  });

  return useSuspenseQuery({
    queryKey: [`staff-sudamate`, { ...params }],
    queryFn,
    select: data => {
      return {
        sudamatePosts: data.data.data,
        meta: data.data.meta,
      };
    },
  });
};

export default useGetStaffSudamate;

export type StaffSudamateParams = {
  limit: number;
  page: number;
  search?: string;
};

export type StaffSudamatePost = {
  id: number;
  staff_account: {
    id: number;
    name: string;
  };
  is_notice: boolean;
  title: string;
  is_hide: boolean;
  view_count: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  comment_count: number;
  attachment_count: number;
};

type StaffSudamateResponse = {
  data: StaffSudamatePost[];
  meta: Meta;
};

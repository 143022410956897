import styled from '@emotion/styled';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MainLayout from 'sharedComponents/MainLayout';

import HolidayHistoryList from './components/HolidayHistoryList';
import HolidaySettingForm from './components/HolidaySettingForm';
import { HOLIDAY_SETTING_TEXT } from './constants';

const HolidaySetting = () => {
  return (
    <MainLayout scrollable header={{ title: HOLIDAY_SETTING_TEXT.headerTitle }}>
      <Container>
        <HolidaySettingForm />

        <Divider />

        <section className="history-section">
          <Typography size={15} weight={600}>
            {HOLIDAY_SETTING_TEXT.historys.title}
          </Typography>

          <ApiBoundary>
            <HolidayHistoryList />
          </ApiBoundary>
        </section>
      </Container>
    </MainLayout>
  );
};

const Container = styled.div`
  .history-section {
    padding: 32px 20px;
  }
`;

export default HolidaySetting;
